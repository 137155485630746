import { AuthXsStoreState, getInitialAuthXsStoreState } from './auth';
import { getInitialPackagesXsStoreState, PackagesXsStoreState } from './packages';
import { CoreGuiState, getInitialCoreGuiState } from './gui';
import { generalTenantSettingsStore, GeneralTenantSettingsStoreState } from './general-tenant-settings';
import {
  generalTenantSettingsSubscriptionsStore,
  GeneralTenantSettingsSubscriptionsStoreState
} from "./general-tenant-settings-subscriptions";

export interface CoreState {
  gui: CoreGuiState;
  auth: AuthXsStoreState;
  packages: PackagesXsStoreState;
  generalTenantSettings: GeneralTenantSettingsStoreState;
  generalTenantSettingsSubscriptions: GeneralTenantSettingsSubscriptionsStoreState;
}

export const getInitialCoreState = (): CoreState => ({
  gui: getInitialCoreGuiState(),
  auth: getInitialAuthXsStoreState(),
  packages: getInitialPackagesXsStoreState(),
  generalTenantSettings: generalTenantSettingsStore.getInitialState(),
  generalTenantSettingsSubscriptions: generalTenantSettingsSubscriptionsStore.getInitialState()
});
