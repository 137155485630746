import { NgModule } from '@angular/core';
import { CoreSharedModule } from '@nexnox-web/core-shared';
import { CorePortalEntityOverviewComponent } from './components';
import { CorePortalCardboxModule } from '../../cardbox';
import { CorePortalEntityDatatableModule } from '../entity-datatable';
import { CorePortalEntityEditModule } from '../entity-edit';
import { CorePortalFormlyModule } from '../../formly';
import {TabsDeclarationsModule} from "@nexnox-web/libs/core-portal/src/lib/modules/tabs/tabs-declarations.module";

const exportedComponents = [
  CorePortalEntityOverviewComponent
];

const sharedModules = [
  CoreSharedModule,
  CorePortalCardboxModule,
  CorePortalEntityDatatableModule,
  CorePortalEntityEditModule,
  CorePortalFormlyModule
];

@NgModule({
  declarations: [
    ...exportedComponents
  ],
    imports: [
        ...sharedModules,
        TabsDeclarationsModule
    ],
  exports: [
    ...exportedComponents,
    ...sharedModules
  ]
})
export class CorePortalEntityOverviewModule {
}
