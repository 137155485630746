import {Injectable} from '@angular/core';
import {IssueListDto, IssueSimpleDto, IssueType} from "@nexnox-web/libs/core-shared/src";

@Injectable()
export class CoreSharedIssueLinkUtilityService {

  /*
   * Useful for link urls (formly links, detail links)
   * (needs module property: communication)
   */
  public getIssueLink(issue: IssueSimpleDto | IssueListDto | any): string {
    return `issues/${this._getTypeSegment(issue.issueType)}/${issue.issueId}`;
  }

  /*
   * Useful for router links or tenant router navigate fn
   * (needs module property: communication)
   */
  public getIssueSegments(issue: IssueSimpleDto | IssueListDto | any): Array<string | number> {
    return ['issues', this._getTypeSegment(issue.issueType), issue.issueId];
  }

  private _getTypeSegment(issueType: IssueType): string {
    let segment: string;
    switch (issueType) {
      case IssueType.Message:
        segment = 'message';
        break;
      case IssueType.Mission:
        segment = 'mission';
        break;
      case IssueType.Ticket:
        segment = 'ticket';
        break;
      case IssueType.WinterhalterServiceCall:
        segment = 'winterhalter-service-call';
        break;
      case IssueType.WinterhalterProductOrder:
        segment = 'winterhalter-product-order';
        break;
      default:
        segment = 'message';
        break;
    }
    return segment;
  }
}
