export interface Environment {
  production: boolean;
  title: string;
  version: string;
  apiUrl: {
    identity: string;
    orga: string;
    tech: string;
    data: string;
  };
  google: {
    apiKey: string;
  };
  email: {
    bugReport: string;
  };
  defaultPageSize: number;
  defaultLanguage: string;
  defaultTimeout: number;
  additionalTranslations?: string[];
}

export enum Product {
  IDENTITY = 'identity',
  ORGA = 'orga',
  TECH = 'tech',
  DATA = 'data'
}
