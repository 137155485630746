/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is regenerated.
 */

import * as msRest from "@azure/ms-rest-js";


export const FilterDto: msRest.CompositeMapper = {
  serializedName: "FilterDto",
  type: {
    name: "Composite",
    className: "FilterDto",
    modelProperties: {
      property: {
        serializedName: "property",
        type: {
          name: "String"
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      },
      kind: {
        serializedName: "kind",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      },
      operator: {
        serializedName: "operator",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "-1"
          ]
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      }
    }
  }
};

export const AcceptEditorRequestDto: msRest.CompositeMapper = {
  serializedName: "AcceptEditorRequestDto",
  type: {
    name: "Composite",
    className: "AcceptEditorRequestDto",
    modelProperties: {
      contactId: {
        serializedName: "contactId",
        type: {
          name: "Number"
        }
      },
      actualStart: {
        serializedName: "actualStart",
        type: {
          name: "String"
        }
      },
      actualEnd: {
        serializedName: "actualEnd",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const AcceptInvitationDto: msRest.CompositeMapper = {
  serializedName: "AcceptInvitationDto",
  type: {
    name: "Composite",
    className: "AcceptInvitationDto",
    modelProperties: {
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "1"
          ]
        }
      }
    }
  }
};

export const AcceptInvitationViaIdentityDto: msRest.CompositeMapper = {
  serializedName: "AcceptInvitationViaIdentityDto",
  type: {
    name: "Composite",
    className: "AcceptInvitationViaIdentityDto",
    modelProperties: {
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "1"
          ]
        }
      },
      pin: {
        serializedName: "pin",
        type: {
          name: "String"
        }
      },
      lastName: {
        serializedName: "lastName",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const AcceptedInvitationDto: msRest.CompositeMapper = {
  serializedName: "AcceptedInvitationDto",
  type: {
    name: "Composite",
    className: "AcceptedInvitationDto",
    modelProperties: {
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const AccessOfContactDto: msRest.CompositeMapper = {
  serializedName: "AccessOfContactDto",
  type: {
    name: "Composite",
    className: "AccessOfContactDto",
    modelProperties: {
      since: {
        serializedName: "since",
        type: {
          name: "String"
        }
      },
      isLockedOut: {
        serializedName: "isLockedOut",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const AddLabelDto: msRest.CompositeMapper = {
  serializedName: "AddLabelDto",
  type: {
    name: "Composite",
    className: "AddLabelDto",
    modelProperties: {
      entityId: {
        serializedName: "entityId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      }
    }
  }
};

export const PointDto: msRest.CompositeMapper = {
  serializedName: "PointDto",
  type: {
    name: "Composite",
    className: "PointDto",
    modelProperties: {
      lat: {
        serializedName: "lat",
        type: {
          name: "Number"
        }
      },
      lng: {
        serializedName: "lng",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const CustomValueDto: msRest.CompositeMapper = {
  serializedName: "CustomValueDto",
  type: {
    name: "Composite",
    className: "CustomValueDto",
    modelProperties: {
      customValueId: {
        serializedName: "customValueId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      isInherited: {
        serializedName: "isInherited",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const FilledCustomValueRelationDto: msRest.CompositeMapper = {
  serializedName: "FilledCustomValueRelationDto",
  type: {
    name: "Composite",
    className: "FilledCustomValueRelationDto",
    modelProperties: {
      customValueId: {
        serializedName: "customValueId",
        type: {
          name: "Number"
        }
      },
      customValue: {
        serializedName: "customValue",
        type: {
          name: "Composite",
          className: "CustomValueDto"
        }
      },
      filledCustomValueId: {
        serializedName: "filledCustomValueId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const FilledCustomValueDto: msRest.CompositeMapper = {
  serializedName: "FilledCustomValueDto",
  type: {
    name: "Composite",
    className: "FilledCustomValueDto",
    modelProperties: {
      filledCustomValueId: {
        serializedName: "filledCustomValueId",
        type: {
          name: "Number"
        }
      },
      propertyId: {
        serializedName: "propertyId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      customValues: {
        serializedName: "customValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueRelationDto"
            }
          }
        }
      }
    }
  }
};

export const AddressDto: msRest.CompositeMapper = {
  serializedName: "AddressDto",
  type: {
    name: "Composite",
    className: "AddressDto",
    modelProperties: {
      addressId: {
        serializedName: "addressId",
        type: {
          name: "Number"
        }
      },
      point: {
        serializedName: "point",
        type: {
          name: "Composite",
          className: "PointDto"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      street: {
        serializedName: "street",
        type: {
          name: "String"
        }
      },
      houseNumber: {
        serializedName: "houseNumber",
        type: {
          name: "String"
        }
      },
      city: {
        serializedName: "city",
        type: {
          name: "String"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "String"
        }
      },
      zipcode: {
        serializedName: "zipcode",
        type: {
          name: "String"
        }
      },
      countryCode: {
        serializedName: "countryCode",
        type: {
          name: "String"
        }
      },
      isPrimaryInvoice: {
        serializedName: "isPrimaryInvoice",
        type: {
          name: "Boolean"
        }
      },
      isPrimaryShipping: {
        serializedName: "isPrimaryShipping",
        type: {
          name: "Boolean"
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueDto"
            }
          }
        }
      }
    }
  }
};

export const StereotypeSimpleDto: msRest.CompositeMapper = {
  serializedName: "StereotypeSimpleDto",
  type: {
    name: "Composite",
    className: "StereotypeSimpleDto",
    modelProperties: {
      stereotypeId: {
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      },
      entityType: {
        serializedName: "entityType",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
            "31",
            "32",
            "33",
            "34",
            "35",
            "36",
            "37",
            "38",
            "39",
            "40",
            "41",
            "42",
            "43",
            "44",
            "45"
          ]
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const AddressInMissionDto: msRest.CompositeMapper = {
  serializedName: "AddressInMissionDto",
  type: {
    name: "Composite",
    className: "AddressInMissionDto",
    modelProperties: {
      addressId: {
        serializedName: "addressId",
        type: {
          name: "Number"
        }
      },
      point: {
        serializedName: "point",
        type: {
          name: "Composite",
          className: "PointDto"
        }
      },
      street: {
        serializedName: "street",
        type: {
          name: "String"
        }
      },
      houseNumber: {
        serializedName: "houseNumber",
        type: {
          name: "String"
        }
      },
      zipcode: {
        serializedName: "zipcode",
        type: {
          name: "String"
        }
      },
      city: {
        serializedName: "city",
        type: {
          name: "String"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "String"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleDto"
        }
      }
    }
  }
};

export const PointListDto: msRest.CompositeMapper = {
  serializedName: "PointListDto",
  type: {
    name: "Composite",
    className: "PointListDto",
    modelProperties: {
      lat: {
        serializedName: "lat",
        type: {
          name: "Number"
        }
      },
      lng: {
        serializedName: "lng",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const StereotypeSimpleListDto: msRest.CompositeMapper = {
  serializedName: "StereotypeSimpleListDto",
  type: {
    name: "Composite",
    className: "StereotypeSimpleListDto",
    modelProperties: {
      stereotypeId: {
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const AddressInMissionListDto: msRest.CompositeMapper = {
  serializedName: "AddressInMissionListDto",
  type: {
    name: "Composite",
    className: "AddressInMissionListDto",
    modelProperties: {
      addressId: {
        serializedName: "addressId",
        type: {
          name: "Number"
        }
      },
      point: {
        serializedName: "point",
        type: {
          name: "Composite",
          className: "PointListDto"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      street: {
        serializedName: "street",
        type: {
          name: "String"
        }
      },
      houseNumber: {
        serializedName: "houseNumber",
        type: {
          name: "String"
        }
      },
      city: {
        serializedName: "city",
        type: {
          name: "String"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "String"
        }
      },
      zipcode: {
        serializedName: "zipcode",
        type: {
          name: "String"
        }
      },
      countryCode: {
        serializedName: "countryCode",
        type: {
          name: "String"
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleListDto"
        }
      }
    }
  }
};

export const FileDto: msRest.CompositeMapper = {
  serializedName: "FileDto",
  type: {
    name: "Composite",
    className: "FileDto",
    modelProperties: {
      fileId: {
        serializedName: "fileId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      path: {
        serializedName: "path",
        type: {
          name: "String"
        }
      },
      hash: {
        serializedName: "hash",
        type: {
          name: "String"
        }
      },
      creationDateTime: {
        serializedName: "creationDateTime",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      mimeType: {
        serializedName: "mimeType",
        type: {
          name: "String"
        }
      },
      size: {
        serializedName: "size",
        type: {
          name: "Number"
        }
      },
      uri: {
        serializedName: "uri",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CustomValueCompleteDto: msRest.CompositeMapper = {
  serializedName: "CustomValueCompleteDto",
  type: {
    name: "Composite",
    className: "CustomValueCompleteDto",
    modelProperties: {
      customValueId: {
        serializedName: "customValueId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      isInherited: {
        serializedName: "isInherited",
        type: {
          name: "Boolean"
        }
      },
      ownValue: {
        serializedName: "ownValue",
        type: {
          name: "Object"
        }
      },
      ownFileValue: {
        serializedName: "ownFileValue",
        type: {
          name: "Composite",
          className: "FileDto"
        }
      },
      inheritedValue: {
        serializedName: "inheritedValue",
        type: {
          name: "Object"
        }
      },
      inheritedFileValue: {
        serializedName: "inheritedFileValue",
        type: {
          name: "Composite",
          className: "FileDto"
        }
      }
    }
  }
};

export const FilledCustomValueRelationListDto: msRest.CompositeMapper = {
  serializedName: "FilledCustomValueRelationListDto",
  type: {
    name: "Composite",
    className: "FilledCustomValueRelationListDto",
    modelProperties: {
      customValue: {
        serializedName: "customValue",
        type: {
          name: "Composite",
          className: "CustomValueCompleteDto"
        }
      }
    }
  }
};

export const FilledCustomValueListDto: msRest.CompositeMapper = {
  serializedName: "FilledCustomValueListDto",
  type: {
    name: "Composite",
    className: "FilledCustomValueListDto",
    modelProperties: {
      propertyId: {
        serializedName: "propertyId",
        type: {
          name: "Number"
        }
      },
      customValues: {
        serializedName: "customValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueRelationListDto"
            }
          }
        }
      }
    }
  }
};

export const AddressListDto: msRest.CompositeMapper = {
  serializedName: "AddressListDto",
  type: {
    name: "Composite",
    className: "AddressListDto",
    modelProperties: {
      addressId: {
        serializedName: "addressId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      point: {
        serializedName: "point",
        type: {
          name: "Composite",
          className: "PointListDto"
        }
      },
      street: {
        serializedName: "street",
        type: {
          name: "String"
        }
      },
      houseNumber: {
        serializedName: "houseNumber",
        type: {
          name: "String"
        }
      },
      city: {
        serializedName: "city",
        type: {
          name: "String"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "String"
        }
      },
      zipcode: {
        serializedName: "zipcode",
        type: {
          name: "String"
        }
      },
      countryCode: {
        serializedName: "countryCode",
        type: {
          name: "String"
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleListDto"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueListDto"
            }
          }
        }
      }
    }
  }
};

export const AddressListForCompanyDto: msRest.CompositeMapper = {
  serializedName: "AddressListForCompanyDto",
  type: {
    name: "Composite",
    className: "AddressListForCompanyDto",
    modelProperties: {
      addressId: {
        serializedName: "addressId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      point: {
        serializedName: "point",
        type: {
          name: "Composite",
          className: "PointListDto"
        }
      },
      street: {
        serializedName: "street",
        type: {
          name: "String"
        }
      },
      houseNumber: {
        serializedName: "houseNumber",
        type: {
          name: "String"
        }
      },
      city: {
        serializedName: "city",
        type: {
          name: "String"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "String"
        }
      },
      zipcode: {
        serializedName: "zipcode",
        type: {
          name: "String"
        }
      },
      countryCode: {
        serializedName: "countryCode",
        type: {
          name: "String"
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleListDto"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueListDto"
            }
          }
        }
      },
      isInRelation: {
        serializedName: "isInRelation",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const AddressListForLocationDto: msRest.CompositeMapper = {
  serializedName: "AddressListForLocationDto",
  type: {
    name: "Composite",
    className: "AddressListForLocationDto",
    modelProperties: {
      addressId: {
        serializedName: "addressId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      point: {
        serializedName: "point",
        type: {
          name: "Composite",
          className: "PointListDto"
        }
      },
      street: {
        serializedName: "street",
        type: {
          name: "String"
        }
      },
      houseNumber: {
        serializedName: "houseNumber",
        type: {
          name: "String"
        }
      },
      city: {
        serializedName: "city",
        type: {
          name: "String"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "String"
        }
      },
      zipcode: {
        serializedName: "zipcode",
        type: {
          name: "String"
        }
      },
      countryCode: {
        serializedName: "countryCode",
        type: {
          name: "String"
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleListDto"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueListDto"
            }
          }
        }
      },
      isInRelation: {
        serializedName: "isInRelation",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const HolidaySetSimpleDto: msRest.CompositeMapper = {
  serializedName: "HolidaySetSimpleDto",
  type: {
    name: "Composite",
    className: "HolidaySetSimpleDto",
    modelProperties: {
      holidaySetId: {
        serializedName: "holidaySetId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const AddressSimpleDto: msRest.CompositeMapper = {
  serializedName: "AddressSimpleDto",
  type: {
    name: "Composite",
    className: "AddressSimpleDto",
    modelProperties: {
      addressId: {
        serializedName: "addressId",
        type: {
          name: "Number"
        }
      },
      point: {
        serializedName: "point",
        type: {
          name: "Composite",
          className: "PointDto"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      street: {
        serializedName: "street",
        type: {
          name: "String"
        }
      },
      houseNumber: {
        serializedName: "houseNumber",
        type: {
          name: "String"
        }
      },
      city: {
        serializedName: "city",
        type: {
          name: "String"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "String"
        }
      },
      zipcode: {
        serializedName: "zipcode",
        type: {
          name: "String"
        }
      },
      countryCode: {
        serializedName: "countryCode",
        type: {
          name: "String"
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      }
    }
  }
};

export const AttachmentForTechDto: msRest.CompositeMapper = {
  serializedName: "AttachmentForTechDto",
  type: {
    name: "Composite",
    className: "AttachmentForTechDto",
    modelProperties: {
      attachmentId: {
        serializedName: "attachmentId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1"
          ]
        }
      }
    }
  }
};

export const LocationDto: msRest.CompositeMapper = {
  serializedName: "LocationDto",
  type: {
    name: "Composite",
    className: "LocationDto",
    modelProperties: {
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1"
          ]
        }
      },
      closedSince: {
        serializedName: "closedSince",
        type: {
          name: "String"
        }
      },
      monday: {
        serializedName: "monday",
        type: {
          name: "Boolean"
        }
      },
      tuesday: {
        serializedName: "tuesday",
        type: {
          name: "Boolean"
        }
      },
      wednesday: {
        serializedName: "wednesday",
        type: {
          name: "Boolean"
        }
      },
      thursday: {
        serializedName: "thursday",
        type: {
          name: "Boolean"
        }
      },
      friday: {
        serializedName: "friday",
        type: {
          name: "Boolean"
        }
      },
      saturday: {
        serializedName: "saturday",
        type: {
          name: "Boolean"
        }
      },
      sunday: {
        serializedName: "sunday",
        type: {
          name: "Boolean"
        }
      },
      isIncomingEmailEnabled: {
        serializedName: "isIncomingEmailEnabled",
        type: {
          name: "Boolean"
        }
      },
      emailAddress: {
        serializedName: "emailAddress",
        type: {
          name: "String"
        }
      },
      info: {
        serializedName: "info",
        type: {
          name: "String"
        }
      },
      locationNumber: {
        serializedName: "locationNumber",
        type: {
          name: "String"
        }
      },
      phoneNumber: {
        serializedName: "phoneNumber",
        type: {
          name: "String"
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      },
      holidaySetId: {
        nullable: true,
        serializedName: "holidaySetId",
        type: {
          name: "Number"
        }
      },
      holidaySet: {
        serializedName: "holidaySet",
        type: {
          name: "Composite",
          className: "HolidaySetSimpleDto"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueDto"
            }
          }
        }
      },
      shippingAddress: {
        serializedName: "shippingAddress",
        type: {
          name: "Composite",
          className: "AddressSimpleDto"
        }
      },
      invoiceAddress: {
        serializedName: "invoiceAddress",
        type: {
          name: "Composite",
          className: "AddressSimpleDto"
        }
      },
      attachments: {
        serializedName: "attachments",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AttachmentForTechDto"
            }
          }
        }
      },
      lastModified: {
        serializedName: "lastModified",
        type: {
          name: "String"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const AddressLocationDto: msRest.CompositeMapper = {
  serializedName: "AddressLocationDto",
  type: {
    name: "Composite",
    className: "AddressLocationDto",
    modelProperties: {
      addressId: {
        serializedName: "addressId",
        type: {
          name: "Number"
        }
      },
      address: {
        serializedName: "address",
        type: {
          name: "Composite",
          className: "AddressDto"
        }
      },
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Number"
        }
      },
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "LocationDto"
        }
      }
    }
  }
};

export const AddressSimpleListDto: msRest.CompositeMapper = {
  serializedName: "AddressSimpleListDto",
  type: {
    name: "Composite",
    className: "AddressSimpleListDto",
    modelProperties: {
      addressId: {
        serializedName: "addressId",
        type: {
          name: "Number"
        }
      },
      street: {
        serializedName: "street",
        type: {
          name: "String"
        }
      },
      houseNumber: {
        serializedName: "houseNumber",
        type: {
          name: "String"
        }
      },
      city: {
        serializedName: "city",
        type: {
          name: "String"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "String"
        }
      },
      zipcode: {
        serializedName: "zipcode",
        type: {
          name: "String"
        }
      },
      countryCode: {
        serializedName: "countryCode",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const AddressTtm: msRest.CompositeMapper = {
  serializedName: "AddressTtm",
  type: {
    name: "Composite",
    className: "AddressTtm",
    modelProperties: {
      addressId: {
        serializedName: "addressId",
        type: {
          name: "Number"
        }
      },
      street: {
        serializedName: "street",
        type: {
          name: "String"
        }
      },
      houseNumber: {
        serializedName: "houseNumber",
        type: {
          name: "String"
        }
      },
      city: {
        serializedName: "city",
        type: {
          name: "String"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "String"
        }
      },
      countryCode: {
        serializedName: "countryCode",
        type: {
          name: "String"
        }
      },
      zipcode: {
        serializedName: "zipcode",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const AlertImpactConsequenceDto: msRest.CompositeMapper = {
  serializedName: "AlertImpactConsequenceDto",
  type: {
    name: "Composite",
    className: "AlertImpactConsequenceDto",
    modelProperties: {
      impactId: {
        serializedName: "impactId",
        type: {
          name: "Number"
        }
      },
      jobId: {
        serializedName: "jobId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ConditionV1Dto: msRest.CompositeMapper = {
  serializedName: "ConditionV1Dto",
  type: {
    name: "Composite",
    className: "ConditionV1Dto",
    modelProperties: {
      conditionId: {
        serializedName: "conditionId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      }
    }
  }
};

export const AlertImpactCreateV1Dto: msRest.CompositeMapper = {
  serializedName: "AlertImpactCreateV1Dto",
  type: {
    name: "Composite",
    className: "AlertImpactCreateV1Dto",
    modelProperties: {
      impactId: {
        serializedName: "impactId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      condition: {
        serializedName: "condition",
        type: {
          name: "Composite",
          className: "ConditionV1Dto"
        }
      },
      taskId: {
        nullable: true,
        serializedName: "taskId",
        type: {
          name: "Number"
        }
      },
      functionId: {
        nullable: true,
        serializedName: "functionId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ConditionDto: msRest.CompositeMapper = {
  serializedName: "ConditionDto",
  type: {
    name: "Composite",
    className: "ConditionDto",
    modelProperties: {
      conditionId: {
        serializedName: "conditionId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      }
    }
  }
};

export const FunctionSimpleDto: msRest.CompositeMapper = {
  serializedName: "FunctionSimpleDto",
  type: {
    name: "Composite",
    className: "FunctionSimpleDto",
    modelProperties: {
      functionId: {
        serializedName: "functionId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const AlertImpactDto: msRest.CompositeMapper = {
  serializedName: "AlertImpactDto",
  type: {
    name: "Composite",
    className: "AlertImpactDto",
    modelProperties: {
      impactId: {
        serializedName: "impactId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      condition: {
        serializedName: "condition",
        type: {
          name: "Composite",
          className: "ConditionDto"
        }
      },
      functionProperty: {
        serializedName: "function",
        type: {
          name: "Composite",
          className: "FunctionSimpleDto"
        }
      }
    }
  }
};

export const AlertImpactV1Dto: msRest.CompositeMapper = {
  serializedName: "AlertImpactV1Dto",
  type: {
    name: "Composite",
    className: "AlertImpactV1Dto",
    modelProperties: {
      impactId: {
        serializedName: "impactId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      condition: {
        serializedName: "condition",
        type: {
          name: "Composite",
          className: "ConditionV1Dto"
        }
      },
      functionProperty: {
        serializedName: "function",
        type: {
          name: "Composite",
          className: "FunctionSimpleDto"
        }
      }
    }
  }
};

export const AllResourceTicketSkeletonSelectorDto: msRest.CompositeMapper = {
  serializedName: "AllResourceTicketSkeletonSelectorDto",
  type: {
    name: "Composite",
    className: "AllResourceTicketSkeletonSelectorDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4"
          ]
        }
      }
    }
  }
};

export const AllowedContextDto: msRest.CompositeMapper = {
  serializedName: "AllowedContextDto",
  type: {
    name: "Composite",
    className: "AllowedContextDto",
    modelProperties: {
      tnt: {
        serializedName: "tnt",
        type: {
          name: "Number"
        }
      },
      src: {
        serializedName: "src",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "8"
          ]
        }
      }
    }
  }
};

export const EditorSuggestionTargetDto: msRest.CompositeMapper = {
  serializedName: "EditorSuggestionTargetDto",
  type: {
    name: "Composite",
    className: "EditorSuggestionTargetDto",
    modelProperties: {
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      },
      processingTime: {
        serializedName: "processingTime",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const AlternativeEditorSuggestionDto: msRest.CompositeMapper = {
  serializedName: "AlternativeEditorSuggestionDto",
  type: {
    name: "Composite",
    className: "AlternativeEditorSuggestionDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      target: {
        serializedName: "target",
        type: {
          name: "Composite",
          className: "EditorSuggestionTargetDto"
        }
      }
    }
  }
};

export const AnalyticByTimePortalRequestDto: msRest.CompositeMapper = {
  serializedName: "AnalyticByTimePortalRequestDto",
  type: {
    name: "Composite",
    className: "AnalyticByTimePortalRequestDto",
    modelProperties: {
      start: {
        serializedName: "start",
        type: {
          name: "String"
        }
      },
      end: {
        serializedName: "end",
        type: {
          name: "String"
        }
      },
      graphKind: {
        serializedName: "graphKind",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4"
          ]
        }
      },
      locationGroupExternalId: {
        serializedName: "locationGroupExternalId",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const AnalyticParams: msRest.CompositeMapper = {
  serializedName: "AnalyticParams",
  type: {
    name: "Composite",
    className: "AnalyticParams",
    modelProperties: {
      year: {
        serializedName: "year",
        type: {
          name: "Number"
        }
      },
      locationGroupExternalId: {
        serializedName: "locationGroupExternalId",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const AnalyticPortalRequestDto: msRest.CompositeMapper = {
  serializedName: "AnalyticPortalRequestDto",
  type: {
    name: "Composite",
    className: "AnalyticPortalRequestDto",
    modelProperties: {
      start: {
        serializedName: "start",
        type: {
          name: "String"
        }
      },
      end: {
        serializedName: "end",
        type: {
          name: "String"
        }
      },
      locationGroupExternalId: {
        serializedName: "locationGroupExternalId",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const LocationSimpleDto: msRest.CompositeMapper = {
  serializedName: "LocationSimpleDto",
  type: {
    name: "Composite",
    className: "LocationSimpleDto",
    modelProperties: {
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      locationNumber: {
        serializedName: "locationNumber",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const LocationGroupSimpleDto: msRest.CompositeMapper = {
  serializedName: "LocationGroupSimpleDto",
  type: {
    name: "Composite",
    className: "LocationGroupSimpleDto",
    modelProperties: {
      locationGroupId: {
        serializedName: "locationGroupId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const AppShortcutValueDto: msRest.CompositeMapper = {
  serializedName: "AppShortcutValueDto",
  type: {
    name: "Composite",
    className: "AppShortcutValueDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      appShortcutValueId: {
        serializedName: "appShortcutValueId",
        type: {
          name: "Number"
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3"
          ]
        }
      },
      entityType: {
        serializedName: "entityType",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
            "31",
            "32",
            "33",
            "34",
            "35",
            "36",
            "37",
            "38",
            "39",
            "40",
            "41",
            "42",
            "43",
            "44",
            "45"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const AppShortcutParameterDto: msRest.CompositeMapper = {
  serializedName: "AppShortcutParameterDto",
  type: {
    name: "Composite",
    className: "AppShortcutParameterDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      appShortcutParameterId: {
        serializedName: "appShortcutParameterId",
        type: {
          name: "Number"
        }
      },
      property: {
        serializedName: "property",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      values: {
        serializedName: "values",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AppShortcutValueDto"
            }
          }
        }
      }
    }
  }
};

export const AppShortcutDto: msRest.CompositeMapper = {
  serializedName: "AppShortcutDto",
  type: {
    name: "Composite",
    className: "AppShortcutDto",
    modelProperties: {
      appShortcutId: {
        serializedName: "appShortcutId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      subTitle: {
        serializedName: "subTitle",
        type: {
          name: "String"
        }
      },
      icon: {
        serializedName: "icon",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      parameters: {
        serializedName: "parameters",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AppShortcutParameterDto"
            }
          }
        }
      }
    }
  }
};

export const AppOverviewDto: msRest.CompositeMapper = {
  serializedName: "AppOverviewDto",
  type: {
    name: "Composite",
    className: "AppOverviewDto",
    modelProperties: {
      appOverviewId: {
        serializedName: "appOverviewId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      locations: {
        serializedName: "locations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationSimpleDto"
            }
          }
        }
      },
      locationGroups: {
        serializedName: "locationGroups",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationGroupSimpleDto"
            }
          }
        }
      },
      shortcuts: {
        serializedName: "shortcuts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AppShortcutDto"
            }
          }
        }
      }
    }
  }
};

export const AppShortcutExportDto: msRest.CompositeMapper = {
  serializedName: "AppShortcutExportDto",
  type: {
    name: "Composite",
    className: "AppShortcutExportDto",
    modelProperties: {
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      subTitle: {
        serializedName: "subTitle",
        type: {
          name: "String"
        }
      },
      icon: {
        serializedName: "icon",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8"
          ]
        }
      },
      parameters: {
        serializedName: "parameters",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AppShortcutParameterDto"
            }
          }
        }
      }
    }
  }
};

export const AppOverviewExportDto: msRest.CompositeMapper = {
  serializedName: "AppOverviewExportDto",
  type: {
    name: "Composite",
    className: "AppOverviewExportDto",
    modelProperties: {
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      shortcuts: {
        serializedName: "shortcuts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AppShortcutExportDto"
            }
          }
        }
      }
    }
  }
};

export const AppOverviewListDto: msRest.CompositeMapper = {
  serializedName: "AppOverviewListDto",
  type: {
    name: "Composite",
    className: "AppOverviewListDto",
    modelProperties: {
      appOverviewId: {
        serializedName: "appOverviewId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const AppPermissionDto: msRest.CompositeMapper = {
  serializedName: "AppPermissionDto",
  type: {
    name: "Composite",
    className: "AppPermissionDto",
    modelProperties: {
      permission: {
        serializedName: "permission",
        type: {
          name: "Enum",
          allowedValues: [
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "20",
            "21",
            "22",
            "23",
            "40",
            "41",
            "42",
            "43",
            "50",
            "51",
            "52",
            "53",
            "60",
            "61",
            "62",
            "63",
            "70",
            "71",
            "80",
            "81",
            "82",
            "83",
            "90",
            "91",
            "92",
            "93",
            "100",
            "101",
            "102",
            "103",
            "104",
            "105",
            "106",
            "107",
            "110",
            "111",
            "112",
            "113",
            "120",
            "121",
            "123",
            "124",
            "130",
            "131",
            "132",
            "133",
            "140",
            "141",
            "142",
            "143",
            "150",
            "160",
            "161",
            "162",
            "163",
            "164",
            "170",
            "171",
            "172",
            "173",
            "190",
            "191",
            "192",
            "193",
            "200",
            "201",
            "202",
            "203",
            "204",
            "210",
            "211",
            "212",
            "213",
            "230",
            "231",
            "232",
            "233",
            "240",
            "241",
            "242",
            "243",
            "250",
            "251",
            "252",
            "253",
            "254",
            "260",
            "261",
            "262",
            "263",
            "270",
            "271",
            "272",
            "273",
            "274",
            "275",
            "280",
            "290",
            "291",
            "292",
            "293",
            "300",
            "301",
            "302",
            "303",
            "310",
            "311",
            "312",
            "313",
            "320",
            "321",
            "322",
            "323",
            "330",
            "331",
            "332",
            "333",
            "340",
            "341",
            "342",
            "343",
            "350",
            "351",
            "352",
            "353",
            "360",
            "361",
            "362",
            "363",
            "370",
            "371",
            "372",
            "373",
            "380",
            "381",
            "382",
            "383",
            "390",
            "391",
            "392",
            "393",
            "400",
            "401",
            "402",
            "403",
            "410",
            "411",
            "412",
            "413",
            "420",
            "421",
            "422",
            "423",
            "430",
            "431",
            "432",
            "433",
            "440",
            "441",
            "442",
            "443",
            "450",
            "451",
            "452",
            "453",
            "460",
            "461",
            "462",
            "463",
            "470",
            "471",
            "472",
            "473",
            "474",
            "480",
            "481",
            "482",
            "483",
            "490",
            "491",
            "492",
            "493",
            "500",
            "501",
            "502",
            "503",
            "520",
            "530",
            "531",
            "532",
            "533",
            "535",
            "536",
            "540",
            "550",
            "580",
            "581",
            "582",
            "583",
            "590",
            "591",
            "592",
            "593",
            "600",
            "601",
            "602",
            "603",
            "610",
            "611",
            "612",
            "613",
            "620",
            "621",
            "622",
            "623",
            "630",
            "631",
            "632",
            "633",
            "640",
            "641",
            "642",
            "643",
            "650",
            "651",
            "652",
            "653",
            "660",
            "661",
            "662",
            "663",
            "670",
            "671",
            "672",
            "673",
            "680",
            "681",
            "682",
            "683",
            "684",
            "690",
            "691",
            "692",
            "693",
            "700",
            "701",
            "702",
            "703",
            "704",
            "710",
            "711",
            "712",
            "713",
            "720",
            "721",
            "722",
            "723",
            "730",
            "731",
            "732",
            "733",
            "740",
            "741",
            "742",
            "743",
            "750",
            "751",
            "752",
            "753",
            "790",
            "791",
            "792",
            "793",
            "800",
            "801",
            "802",
            "803",
            "810",
            "811",
            "812",
            "813",
            "820",
            "830",
            "831",
            "832",
            "833",
            "834",
            "835",
            "836",
            "838",
            "839",
            "840",
            "841",
            "842",
            "850",
            "851",
            "852",
            "853",
            "860",
            "861",
            "862",
            "863",
            "870",
            "871",
            "872",
            "873",
            "880",
            "881",
            "882",
            "883",
            "890",
            "891",
            "892",
            "893",
            "900",
            "901",
            "902",
            "903",
            "904",
            "910",
            "911",
            "912",
            "913",
            "920",
            "921",
            "922",
            "923",
            "930",
            "931",
            "932",
            "933",
            "940",
            "941",
            "942",
            "943",
            "950",
            "951",
            "952",
            "953",
            "960",
            "961",
            "962",
            "963",
            "970",
            "971",
            "972",
            "973",
            "980",
            "981",
            "982",
            "983",
            "990",
            "991",
            "992",
            "993",
            "1000",
            "1001",
            "1002",
            "1003",
            "1010",
            "1011",
            "1012",
            "1013",
            "1020",
            "1021",
            "1022",
            "1023",
            "1030",
            "1031",
            "1032",
            "1033",
            "1040",
            "1041",
            "1042",
            "1043",
            "1050",
            "1051",
            "1052",
            "1053",
            "1060",
            "1061",
            "1062",
            "1063",
            "1070",
            "1071",
            "1072",
            "1073",
            "1080",
            "1081",
            "1082",
            "1083",
            "1090",
            "1091",
            "1092",
            "1093",
            "1100",
            "1101",
            "1102",
            "1103",
            "1110",
            "1111",
            "1112",
            "1113",
            "1114",
            "1120",
            "1121",
            "1122",
            "1123",
            "1130",
            "1131",
            "1132",
            "1133",
            "1134",
            "1135",
            "1136",
            "1137",
            "1140",
            "1141",
            "1142",
            "1143",
            "1150",
            "1160",
            "1170",
            "1171",
            "1172",
            "1173",
            "1180",
            "1181",
            "1182",
            "1183",
            "1190",
            "1191",
            "1192",
            "1193",
            "1200",
            "1201",
            "1202",
            "1203",
            "1211",
            "1212",
            "1213",
            "1214",
            "1221",
            "1222",
            "1223",
            "1224",
            "1225",
            "1226",
            "1227",
            "1228",
            "1229",
            "1230",
            "1231",
            "1232",
            "1233",
            "1234",
            "1235",
            "1236",
            "1251",
            "1252",
            "1253",
            "1254",
            "1261",
            "1262",
            "1263",
            "1264",
            "1271",
            "1272",
            "1273",
            "1274",
            "1311",
            "1312",
            "1313",
            "1314",
            "1321",
            "1322",
            "1323",
            "1324",
            "1331",
            "1332",
            "1333",
            "1334",
            "1341",
            "1342",
            "1343",
            "1344",
            "1351",
            "1352",
            "1353",
            "1354",
            "1361",
            "1362",
            "1363",
            "1364",
            "1371",
            "1372",
            "1373",
            "1374",
            "1381",
            "1382",
            "1383",
            "1384",
            "1391",
            "1392",
            "1393",
            "1394",
            "1400",
            "1410",
            "1412",
            "1413",
            "1414",
            "1420",
            "1422",
            "1423",
            "1424",
            "1430",
            "1431",
            "1432",
            "1433",
            "1440",
            "1441",
            "1442",
            "1443",
            "1450",
            "1451",
            "1452",
            "1453",
            "1460",
            "1461",
            "1462",
            "1463",
            "1470",
            "1471",
            "1472",
            "1473",
            "1474",
            "1480",
            "1481",
            "1482",
            "1483",
            "1490",
            "1491",
            "1492",
            "1493",
            "1500",
            "1501",
            "1502",
            "1503",
            "1510",
            "1511",
            "1512",
            "1513",
            "1520",
            "1521",
            "1531",
            "1532",
            "1533",
            "1534",
            "1535",
            "1541",
            "1542",
            "1543",
            "1544",
            "1545",
            "1546",
            "1550",
            "1551",
            "1552",
            "1553",
            "1554",
            "1560",
            "1561",
            "1562",
            "1563",
            "1564",
            "1565",
            "1566",
            "10000",
            "10100",
            "10101",
            "10102",
            "10103",
            "10104",
            "10105",
            "10106",
            "10107",
            "10108",
            "10109",
            "10110",
            "10111",
            "10112",
            "10113",
            "10114",
            "10115",
            "10116",
            "10117",
            "10118",
            "10119",
            "10120",
            "10121",
            "10122",
            "10123",
            "10124",
            "10125",
            "10126",
            "10128",
            "10129",
            "10130",
            "10131",
            "10132",
            "10133",
            "10134",
            "10135",
            "10136",
            "10137",
            "10138",
            "10139",
            "10140",
            "10141",
            "10142",
            "10143",
            "10144",
            "10201",
            "10202",
            "10203",
            "10204",
            "10205",
            "10206",
            "10207",
            "10301",
            "10302",
            "10303",
            "10304",
            "10311",
            "10312",
            "10313",
            "10314",
            "10510",
            "10511",
            "10512",
            "10513",
            "10520",
            "10521",
            "10522",
            "10523",
            "10530",
            "10531",
            "10532",
            "10533",
            "10540",
            "10541",
            "10542",
            "10543",
            "10550",
            "10551",
            "10552",
            "10553",
            "10554",
            "10555",
            "10561",
            "10562",
            "10563",
            "10564",
            "10570",
            "10571",
            "10572",
            "10573",
            "10574",
            "10580",
            "10590",
            "10591",
            "10601",
            "10602",
            "10603",
            "10604",
            "10605",
            "10606",
            "10607",
            "10608",
            "10610",
            "10611",
            "10612",
            "10613",
            "10620",
            "10621",
            "10622",
            "10623",
            "10624",
            "10630",
            "10631",
            "10632",
            "10633",
            "10634",
            "10640",
            "10641",
            "10642",
            "10643",
            "10644",
            "10646",
            "10647",
            "10648",
            "10649",
            "10650",
            "10651",
            "10652",
            "10653",
            "10660",
            "10661",
            "10662",
            "10663",
            "10664",
            "10665",
            "10666",
            "10667",
            "10668",
            "10669",
            "10670",
            "10671",
            "10672",
            "10673",
            "10680",
            "10681",
            "10682",
            "10683",
            "10710",
            "10711",
            "10720",
            "10721",
            "10723",
            "10724",
            "10730",
            "10731",
            "10733",
            "10734",
            "10740",
            "10741",
            "10742",
            "10743",
            "10750",
            "10751",
            "10752",
            "10753",
            "10760",
            "10761",
            "10762",
            "10763",
            "10770",
            "10771",
            "10772",
            "10773",
            "103321",
            "103322",
            "103323",
            "103324",
            "106245"
          ]
        }
      },
      group: {
        serializedName: "group",
        type: {
          name: "String"
        }
      },
      availableModes: {
        serializedName: "availableModes",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      }
    }
  }
};

export const PermissionFunctionDto: msRest.CompositeMapper = {
  serializedName: "PermissionFunctionDto",
  type: {
    name: "Composite",
    className: "PermissionFunctionDto",
    modelProperties: {
      packageProperty: {
        serializedName: "package",
        type: {
          name: "Enum",
          allowedValues: [
            "12",
            "13",
            "20",
            "21",
            "22",
            "23",
            "24",
            "28",
            "29",
            "30",
            "31",
            "32",
            "33",
            "34",
            "40",
            "41",
            "42",
            "43",
            "50",
            "51",
            "52",
            "53",
            "60",
            "61",
            "62",
            "63",
            "69",
            "70",
            "71",
            "80",
            "81",
            "82",
            "85",
            "90",
            "91",
            "101",
            "102",
            "103",
            "119",
            "120",
            "121",
            "122",
            "130",
            "131",
            "141",
            "142",
            "143",
            "144",
            "145",
            "150",
            "151",
            "161",
            "171",
            "180",
            "181",
            "182",
            "183",
            "184",
            "200",
            "201",
            "202",
            "203",
            "210",
            "211",
            "212",
            "213",
            "214",
            "215",
            "216",
            "217",
            "220",
            "221",
            "225",
            "226",
            "227",
            "230",
            "231",
            "232",
            "240",
            "241",
            "242",
            "243",
            "244",
            "245",
            "246",
            "250",
            "251",
            "252",
            "260",
            "261",
            "262",
            "263",
            "270",
            "271",
            "272",
            "273",
            "280",
            "281",
            "282",
            "300",
            "301",
            "302",
            "303",
            "310",
            "317",
            "318",
            "319",
            "320",
            "321",
            "322",
            "323",
            "324",
            "325",
            "327",
            "328",
            "329",
            "330",
            "331",
            "340",
            "341",
            "350",
            "351",
            "360",
            "361",
            "362",
            "370",
            "371",
            "372",
            "380",
            "381",
            "382",
            "383",
            "390",
            "391",
            "392",
            "393",
            "400",
            "401",
            "402",
            "403",
            "410",
            "411",
            "420",
            "421",
            "422",
            "423",
            "433",
            "434",
            "440",
            "441",
            "442",
            "443",
            "450",
            "451",
            "452",
            "453",
            "454",
            "460",
            "461",
            "462",
            "463",
            "470",
            "471",
            "472",
            "473",
            "474",
            "480",
            "481",
            "490",
            "491",
            "500",
            "501",
            "510",
            "511",
            "520",
            "521",
            "522",
            "530",
            "531",
            "532",
            "533",
            "540",
            "541",
            "550",
            "551",
            "552",
            "553",
            "560",
            "561",
            "563",
            "564",
            "570",
            "571",
            "573",
            "574",
            "580",
            "581",
            "590",
            "591",
            "600",
            "605",
            "606",
            "607",
            "608",
            "610",
            "611",
            "612",
            "613",
            "620",
            "621",
            "625",
            "626",
            "630",
            "640",
            "650",
            "651",
            "652",
            "653",
            "654",
            "655",
            "1000",
            "1001",
            "1008",
            "1009",
            "1010",
            "1011",
            "1012",
            "1016",
            "1022",
            "1023",
            "1024",
            "1025",
            "1026",
            "1027",
            "1028",
            "1029",
            "2002",
            "2011",
            "2012",
            "2013",
            "2014",
            "2015",
            "2016",
            "2017",
            "2018",
            "2020",
            "2021",
            "2022",
            "2023",
            "2024",
            "2025",
            "2026",
            "2027",
            "2028",
            "2029",
            "2030",
            "2031",
            "2040",
            "2050",
            "2052",
            "2053",
            "2060",
            "2061",
            "2062",
            "2063",
            "2070",
            "2071",
            "2072",
            "2073",
            "2074",
            "2075",
            "2080",
            "2081",
            "2082",
            "2083",
            "2084",
            "2085",
            "2086",
            "2087",
            "2088",
            "2089",
            "2090",
            "2091",
            "2092",
            "2093",
            "2094",
            "2100",
            "2101",
            "2102",
            "2103",
            "2104",
            "2105",
            "2106",
            "2107",
            "2108",
            "2109",
            "2111",
            "2113",
            "2114",
            "2115",
            "2116",
            "2120",
            "2131",
            "2140",
            "2141"
          ]
        }
      }
    }
  }
};

export const AppRoleDto: msRest.CompositeMapper = {
  serializedName: "AppRoleDto",
  type: {
    name: "Composite",
    className: "AppRoleDto",
    modelProperties: {
      appRoleId: {
        serializedName: "appRoleId",
        type: {
          name: "Number"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      isDefault: {
        serializedName: "isDefault",
        type: {
          name: "Boolean"
        }
      },
      term: {
        serializedName: "term",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "4",
            "5",
            "6",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      concurrencyStamp: {
        serializedName: "concurrencyStamp",
        type: {
          name: "String"
        }
      },
      isSystemRole: {
        serializedName: "isSystemRole",
        type: {
          name: "Boolean"
        }
      },
      packages: {
        serializedName: "packages",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PermissionFunctionDto"
            }
          }
        }
      }
    }
  }
};

export const AppRoleExportDto: msRest.CompositeMapper = {
  serializedName: "AppRoleExportDto",
  type: {
    name: "Composite",
    className: "AppRoleExportDto",
    modelProperties: {
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      isDefault: {
        serializedName: "isDefault",
        type: {
          name: "Boolean"
        }
      },
      packages: {
        serializedName: "packages",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PermissionFunctionDto"
            }
          }
        }
      }
    }
  }
};

export const LocationRelationDto: msRest.CompositeMapper = {
  serializedName: "LocationRelationDto",
  type: {
    name: "Composite",
    className: "LocationRelationDto",
    modelProperties: {
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Number"
        }
      },
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "LocationSimpleDto"
        }
      }
    }
  }
};

export const LocationGroupRelationDto: msRest.CompositeMapper = {
  serializedName: "LocationGroupRelationDto",
  type: {
    name: "Composite",
    className: "LocationGroupRelationDto",
    modelProperties: {
      locationGroupId: {
        serializedName: "locationGroupId",
        type: {
          name: "Number"
        }
      },
      locationGroup: {
        serializedName: "locationGroup",
        type: {
          name: "Composite",
          className: "LocationGroupSimpleDto"
        }
      }
    }
  }
};

export const ContactSimpleDto: msRest.CompositeMapper = {
  serializedName: "ContactSimpleDto",
  type: {
    name: "Composite",
    className: "ContactSimpleDto",
    modelProperties: {
      contactId: {
        serializedName: "contactId",
        type: {
          name: "Number"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      userId: {
        serializedName: "userId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ContactRelationDto: msRest.CompositeMapper = {
  serializedName: "ContactRelationDto",
  type: {
    name: "Composite",
    className: "ContactRelationDto",
    modelProperties: {
      contactId: {
        serializedName: "contactId",
        type: {
          name: "Number"
        }
      },
      contact: {
        serializedName: "contact",
        type: {
          name: "Composite",
          className: "ContactSimpleDto"
        }
      }
    }
  }
};

export const FunctionRelationDto: msRest.CompositeMapper = {
  serializedName: "FunctionRelationDto",
  type: {
    name: "Composite",
    className: "FunctionRelationDto",
    modelProperties: {
      functionId: {
        serializedName: "functionId",
        type: {
          name: "Number"
        }
      },
      functionProperty: {
        serializedName: "function",
        type: {
          name: "Composite",
          className: "FunctionSimpleDto"
        }
      }
    }
  }
};

export const AppRoleInfoDto: msRest.CompositeMapper = {
  serializedName: "AppRoleInfoDto",
  type: {
    name: "Composite",
    className: "AppRoleInfoDto",
    modelProperties: {
      roleId: {
        serializedName: "roleId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      term: {
        serializedName: "term",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "4",
            "5",
            "6",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19"
          ]
        }
      },
      locations: {
        serializedName: "locations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationRelationDto"
            }
          }
        }
      },
      locationGroup: {
        serializedName: "locationGroup",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationGroupRelationDto"
            }
          }
        }
      },
      contacts: {
        serializedName: "contacts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ContactRelationDto"
            }
          }
        }
      },
      functions: {
        serializedName: "functions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FunctionRelationDto"
            }
          }
        }
      }
    }
  }
};

export const AppRoleListDto: msRest.CompositeMapper = {
  serializedName: "AppRoleListDto",
  type: {
    name: "Composite",
    className: "AppRoleListDto",
    modelProperties: {
      appRoleId: {
        serializedName: "appRoleId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      concurrencyStamp: {
        serializedName: "concurrencyStamp",
        type: {
          name: "String"
        }
      },
      isDefault: {
        serializedName: "isDefault",
        type: {
          name: "Boolean"
        }
      },
      isSystemRole: {
        serializedName: "isSystemRole",
        type: {
          name: "Boolean"
        }
      },
      term: {
        serializedName: "term",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "4",
            "5",
            "6",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19"
          ]
        }
      }
    }
  }
};

export const AppRoleListForContactDto: msRest.CompositeMapper = {
  serializedName: "AppRoleListForContactDto",
  type: {
    name: "Composite",
    className: "AppRoleListForContactDto",
    modelProperties: {
      appRoleId: {
        serializedName: "appRoleId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      concurrencyStamp: {
        serializedName: "concurrencyStamp",
        type: {
          name: "String"
        }
      },
      isDefault: {
        serializedName: "isDefault",
        type: {
          name: "Boolean"
        }
      },
      isSystemRole: {
        serializedName: "isSystemRole",
        type: {
          name: "Boolean"
        }
      },
      term: {
        serializedName: "term",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "4",
            "5",
            "6",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19"
          ]
        }
      },
      isInRelation: {
        serializedName: "isInRelation",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const AppRoleListForContactDtoPageOutputDto: msRest.CompositeMapper = {
  serializedName: "AppRoleListForContactDtoPageOutputDto",
  type: {
    name: "Composite",
    className: "AppRoleListForContactDtoPageOutputDto",
    modelProperties: {
      paging: {
        serializedName: "paging",
        type: {
          name: "Object"
        }
      },
      items: {
        serializedName: "items",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AppRoleListForContactDto"
            }
          }
        }
      }
    }
  }
};

export const AppRolePermissionDto: msRest.CompositeMapper = {
  serializedName: "AppRolePermissionDto",
  type: {
    name: "Composite",
    className: "AppRolePermissionDto",
    modelProperties: {
      permission: {
        serializedName: "permission",
        type: {
          name: "Enum",
          allowedValues: [
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "20",
            "21",
            "22",
            "23",
            "40",
            "41",
            "42",
            "43",
            "50",
            "51",
            "52",
            "53",
            "60",
            "61",
            "62",
            "63",
            "70",
            "71",
            "80",
            "81",
            "82",
            "83",
            "90",
            "91",
            "92",
            "93",
            "100",
            "101",
            "102",
            "103",
            "104",
            "105",
            "106",
            "107",
            "110",
            "111",
            "112",
            "113",
            "120",
            "121",
            "123",
            "124",
            "130",
            "131",
            "132",
            "133",
            "140",
            "141",
            "142",
            "143",
            "150",
            "160",
            "161",
            "162",
            "163",
            "164",
            "170",
            "171",
            "172",
            "173",
            "190",
            "191",
            "192",
            "193",
            "200",
            "201",
            "202",
            "203",
            "204",
            "210",
            "211",
            "212",
            "213",
            "230",
            "231",
            "232",
            "233",
            "240",
            "241",
            "242",
            "243",
            "250",
            "251",
            "252",
            "253",
            "254",
            "260",
            "261",
            "262",
            "263",
            "270",
            "271",
            "272",
            "273",
            "274",
            "275",
            "280",
            "290",
            "291",
            "292",
            "293",
            "300",
            "301",
            "302",
            "303",
            "310",
            "311",
            "312",
            "313",
            "320",
            "321",
            "322",
            "323",
            "330",
            "331",
            "332",
            "333",
            "340",
            "341",
            "342",
            "343",
            "350",
            "351",
            "352",
            "353",
            "360",
            "361",
            "362",
            "363",
            "370",
            "371",
            "372",
            "373",
            "380",
            "381",
            "382",
            "383",
            "390",
            "391",
            "392",
            "393",
            "400",
            "401",
            "402",
            "403",
            "410",
            "411",
            "412",
            "413",
            "420",
            "421",
            "422",
            "423",
            "430",
            "431",
            "432",
            "433",
            "440",
            "441",
            "442",
            "443",
            "450",
            "451",
            "452",
            "453",
            "460",
            "461",
            "462",
            "463",
            "470",
            "471",
            "472",
            "473",
            "474",
            "480",
            "481",
            "482",
            "483",
            "490",
            "491",
            "492",
            "493",
            "500",
            "501",
            "502",
            "503",
            "520",
            "530",
            "531",
            "532",
            "533",
            "535",
            "536",
            "540",
            "550",
            "580",
            "581",
            "582",
            "583",
            "590",
            "591",
            "592",
            "593",
            "600",
            "601",
            "602",
            "603",
            "610",
            "611",
            "612",
            "613",
            "620",
            "621",
            "622",
            "623",
            "630",
            "631",
            "632",
            "633",
            "640",
            "641",
            "642",
            "643",
            "650",
            "651",
            "652",
            "653",
            "660",
            "661",
            "662",
            "663",
            "670",
            "671",
            "672",
            "673",
            "680",
            "681",
            "682",
            "683",
            "684",
            "690",
            "691",
            "692",
            "693",
            "700",
            "701",
            "702",
            "703",
            "704",
            "710",
            "711",
            "712",
            "713",
            "720",
            "721",
            "722",
            "723",
            "730",
            "731",
            "732",
            "733",
            "740",
            "741",
            "742",
            "743",
            "750",
            "751",
            "752",
            "753",
            "790",
            "791",
            "792",
            "793",
            "800",
            "801",
            "802",
            "803",
            "810",
            "811",
            "812",
            "813",
            "820",
            "830",
            "831",
            "832",
            "833",
            "834",
            "835",
            "836",
            "838",
            "839",
            "840",
            "841",
            "842",
            "850",
            "851",
            "852",
            "853",
            "860",
            "861",
            "862",
            "863",
            "870",
            "871",
            "872",
            "873",
            "880",
            "881",
            "882",
            "883",
            "890",
            "891",
            "892",
            "893",
            "900",
            "901",
            "902",
            "903",
            "904",
            "910",
            "911",
            "912",
            "913",
            "920",
            "921",
            "922",
            "923",
            "930",
            "931",
            "932",
            "933",
            "940",
            "941",
            "942",
            "943",
            "950",
            "951",
            "952",
            "953",
            "960",
            "961",
            "962",
            "963",
            "970",
            "971",
            "972",
            "973",
            "980",
            "981",
            "982",
            "983",
            "990",
            "991",
            "992",
            "993",
            "1000",
            "1001",
            "1002",
            "1003",
            "1010",
            "1011",
            "1012",
            "1013",
            "1020",
            "1021",
            "1022",
            "1023",
            "1030",
            "1031",
            "1032",
            "1033",
            "1040",
            "1041",
            "1042",
            "1043",
            "1050",
            "1051",
            "1052",
            "1053",
            "1060",
            "1061",
            "1062",
            "1063",
            "1070",
            "1071",
            "1072",
            "1073",
            "1080",
            "1081",
            "1082",
            "1083",
            "1090",
            "1091",
            "1092",
            "1093",
            "1100",
            "1101",
            "1102",
            "1103",
            "1110",
            "1111",
            "1112",
            "1113",
            "1114",
            "1120",
            "1121",
            "1122",
            "1123",
            "1130",
            "1131",
            "1132",
            "1133",
            "1134",
            "1135",
            "1136",
            "1137",
            "1140",
            "1141",
            "1142",
            "1143",
            "1150",
            "1160",
            "1170",
            "1171",
            "1172",
            "1173",
            "1180",
            "1181",
            "1182",
            "1183",
            "1190",
            "1191",
            "1192",
            "1193",
            "1200",
            "1201",
            "1202",
            "1203",
            "1211",
            "1212",
            "1213",
            "1214",
            "1221",
            "1222",
            "1223",
            "1224",
            "1225",
            "1226",
            "1227",
            "1228",
            "1229",
            "1230",
            "1231",
            "1232",
            "1233",
            "1234",
            "1235",
            "1236",
            "1251",
            "1252",
            "1253",
            "1254",
            "1261",
            "1262",
            "1263",
            "1264",
            "1271",
            "1272",
            "1273",
            "1274",
            "1311",
            "1312",
            "1313",
            "1314",
            "1321",
            "1322",
            "1323",
            "1324",
            "1331",
            "1332",
            "1333",
            "1334",
            "1341",
            "1342",
            "1343",
            "1344",
            "1351",
            "1352",
            "1353",
            "1354",
            "1361",
            "1362",
            "1363",
            "1364",
            "1371",
            "1372",
            "1373",
            "1374",
            "1381",
            "1382",
            "1383",
            "1384",
            "1391",
            "1392",
            "1393",
            "1394",
            "1400",
            "1410",
            "1412",
            "1413",
            "1414",
            "1420",
            "1422",
            "1423",
            "1424",
            "1430",
            "1431",
            "1432",
            "1433",
            "1440",
            "1441",
            "1442",
            "1443",
            "1450",
            "1451",
            "1452",
            "1453",
            "1460",
            "1461",
            "1462",
            "1463",
            "1470",
            "1471",
            "1472",
            "1473",
            "1474",
            "1480",
            "1481",
            "1482",
            "1483",
            "1490",
            "1491",
            "1492",
            "1493",
            "1500",
            "1501",
            "1502",
            "1503",
            "1510",
            "1511",
            "1512",
            "1513",
            "1520",
            "1521",
            "1531",
            "1532",
            "1533",
            "1534",
            "1535",
            "1541",
            "1542",
            "1543",
            "1544",
            "1545",
            "1546",
            "1550",
            "1551",
            "1552",
            "1553",
            "1554",
            "1560",
            "1561",
            "1562",
            "1563",
            "1564",
            "1565",
            "1566",
            "10000",
            "10100",
            "10101",
            "10102",
            "10103",
            "10104",
            "10105",
            "10106",
            "10107",
            "10108",
            "10109",
            "10110",
            "10111",
            "10112",
            "10113",
            "10114",
            "10115",
            "10116",
            "10117",
            "10118",
            "10119",
            "10120",
            "10121",
            "10122",
            "10123",
            "10124",
            "10125",
            "10126",
            "10128",
            "10129",
            "10130",
            "10131",
            "10132",
            "10133",
            "10134",
            "10135",
            "10136",
            "10137",
            "10138",
            "10139",
            "10140",
            "10141",
            "10142",
            "10143",
            "10144",
            "10201",
            "10202",
            "10203",
            "10204",
            "10205",
            "10206",
            "10207",
            "10301",
            "10302",
            "10303",
            "10304",
            "10311",
            "10312",
            "10313",
            "10314",
            "10510",
            "10511",
            "10512",
            "10513",
            "10520",
            "10521",
            "10522",
            "10523",
            "10530",
            "10531",
            "10532",
            "10533",
            "10540",
            "10541",
            "10542",
            "10543",
            "10550",
            "10551",
            "10552",
            "10553",
            "10554",
            "10555",
            "10561",
            "10562",
            "10563",
            "10564",
            "10570",
            "10571",
            "10572",
            "10573",
            "10574",
            "10580",
            "10590",
            "10591",
            "10601",
            "10602",
            "10603",
            "10604",
            "10605",
            "10606",
            "10607",
            "10608",
            "10610",
            "10611",
            "10612",
            "10613",
            "10620",
            "10621",
            "10622",
            "10623",
            "10624",
            "10630",
            "10631",
            "10632",
            "10633",
            "10634",
            "10640",
            "10641",
            "10642",
            "10643",
            "10644",
            "10646",
            "10647",
            "10648",
            "10649",
            "10650",
            "10651",
            "10652",
            "10653",
            "10660",
            "10661",
            "10662",
            "10663",
            "10664",
            "10665",
            "10666",
            "10667",
            "10668",
            "10669",
            "10670",
            "10671",
            "10672",
            "10673",
            "10680",
            "10681",
            "10682",
            "10683",
            "10710",
            "10711",
            "10720",
            "10721",
            "10723",
            "10724",
            "10730",
            "10731",
            "10733",
            "10734",
            "10740",
            "10741",
            "10742",
            "10743",
            "10750",
            "10751",
            "10752",
            "10753",
            "10760",
            "10761",
            "10762",
            "10763",
            "10770",
            "10771",
            "10772",
            "10773",
            "103321",
            "103322",
            "103323",
            "103324",
            "106245"
          ]
        }
      }
    }
  }
};

export const ChatByFormDefinitionSimpleDto: msRest.CompositeMapper = {
  serializedName: "ChatByFormDefinitionSimpleDto",
  type: {
    name: "Composite",
    className: "ChatByFormDefinitionSimpleDto",
    modelProperties: {
      chatByFormDefinitionId: {
        serializedName: "chatByFormDefinitionId",
        type: {
          name: "Number"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      requiresAuthentication: {
        serializedName: "requiresAuthentication",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const AppShortcutCreateChatByDefinitionDto: msRest.CompositeMapper = {
  serializedName: "AppShortcutCreateChatByDefinitionDto",
  type: {
    name: "Composite",
    className: "AppShortcutCreateChatByDefinitionDto",
    modelProperties: {
      appShortcutId: {
        serializedName: "appShortcutId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      subTitle: {
        serializedName: "subTitle",
        type: {
          name: "String"
        }
      },
      icon: {
        serializedName: "icon",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      parameters: {
        serializedName: "parameters",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AppShortcutParameterDto"
            }
          }
        }
      },
      chatByFormDefinition: {
        serializedName: "chatByFormDefinition",
        type: {
          name: "Composite",
          className: "ChatByFormDefinitionSimpleDto"
        }
      }
    }
  }
};

export const ChatByFormDefinitionSimpleExportDto: msRest.CompositeMapper = {
  serializedName: "ChatByFormDefinitionSimpleExportDto",
  type: {
    name: "Composite",
    className: "ChatByFormDefinitionSimpleExportDto",
    modelProperties: {
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      requiresAuthentication: {
        serializedName: "requiresAuthentication",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const AppShortcutCreateChatByFormDefinitionExportDto: msRest.CompositeMapper = {
  serializedName: "AppShortcutCreateChatByFormDefinitionExportDto",
  type: {
    name: "Composite",
    className: "AppShortcutCreateChatByFormDefinitionExportDto",
    modelProperties: {
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      subTitle: {
        serializedName: "subTitle",
        type: {
          name: "String"
        }
      },
      icon: {
        serializedName: "icon",
        type: {
          name: "String"
        }
      },
      parameters: {
        serializedName: "parameters",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AppShortcutParameterDto"
            }
          }
        }
      },
      chatByFormDefinition: {
        serializedName: "chatByFormDefinition",
        type: {
          name: "Composite",
          className: "ChatByFormDefinitionSimpleExportDto"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8"
          ]
        }
      }
    }
  }
};

export const TicketByProcessDefinitionSimpleDto: msRest.CompositeMapper = {
  serializedName: "TicketByProcessDefinitionSimpleDto",
  type: {
    name: "Composite",
    className: "TicketByProcessDefinitionSimpleDto",
    modelProperties: {
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      ticketByProcessDefinitionId: {
        serializedName: "ticketByProcessDefinitionId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      requiresAuthentication: {
        serializedName: "requiresAuthentication",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const AppShortcutCreateChatByProcessDto: msRest.CompositeMapper = {
  serializedName: "AppShortcutCreateChatByProcessDto",
  type: {
    name: "Composite",
    className: "AppShortcutCreateChatByProcessDto",
    modelProperties: {
      appShortcutId: {
        serializedName: "appShortcutId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      subTitle: {
        serializedName: "subTitle",
        type: {
          name: "String"
        }
      },
      icon: {
        serializedName: "icon",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      parameters: {
        serializedName: "parameters",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AppShortcutParameterDto"
            }
          }
        }
      },
      chatByProcessDefinition: {
        serializedName: "chatByProcessDefinition",
        type: {
          name: "Composite",
          className: "TicketByProcessDefinitionSimpleDto"
        }
      }
    }
  }
};

export const TicketByProcessDefinitionSimpleExportDto: msRest.CompositeMapper = {
  serializedName: "TicketByProcessDefinitionSimpleExportDto",
  type: {
    name: "Composite",
    className: "TicketByProcessDefinitionSimpleExportDto",
    modelProperties: {
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      requiresAuthentication: {
        serializedName: "requiresAuthentication",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const AppShortcutCreateChatByProcessExportDto: msRest.CompositeMapper = {
  serializedName: "AppShortcutCreateChatByProcessExportDto",
  type: {
    name: "Composite",
    className: "AppShortcutCreateChatByProcessExportDto",
    modelProperties: {
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      subTitle: {
        serializedName: "subTitle",
        type: {
          name: "String"
        }
      },
      icon: {
        serializedName: "icon",
        type: {
          name: "String"
        }
      },
      parameters: {
        serializedName: "parameters",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AppShortcutParameterDto"
            }
          }
        }
      },
      chatByProcessDefinition: {
        serializedName: "chatByProcessDefinition",
        type: {
          name: "Composite",
          className: "TicketByProcessDefinitionSimpleExportDto"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8"
          ]
        }
      }
    }
  }
};

export const AppShortcutCreateChatDto: msRest.CompositeMapper = {
  serializedName: "AppShortcutCreateChatDto",
  type: {
    name: "Composite",
    className: "AppShortcutCreateChatDto",
    modelProperties: {
      appShortcutId: {
        serializedName: "appShortcutId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      subTitle: {
        serializedName: "subTitle",
        type: {
          name: "String"
        }
      },
      icon: {
        serializedName: "icon",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      parameters: {
        serializedName: "parameters",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AppShortcutParameterDto"
            }
          }
        }
      }
    }
  }
};

export const AppShortcutCreateChatExportDto: msRest.CompositeMapper = {
  serializedName: "AppShortcutCreateChatExportDto",
  type: {
    name: "Composite",
    className: "AppShortcutCreateChatExportDto",
    modelProperties: {
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      subTitle: {
        serializedName: "subTitle",
        type: {
          name: "String"
        }
      },
      icon: {
        serializedName: "icon",
        type: {
          name: "String"
        }
      },
      parameters: {
        serializedName: "parameters",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AppShortcutParameterDto"
            }
          }
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8"
          ]
        }
      }
    }
  }
};

export const KnowledgeArticleSimpleDto: msRest.CompositeMapper = {
  serializedName: "KnowledgeArticleSimpleDto",
  type: {
    name: "Composite",
    className: "KnowledgeArticleSimpleDto",
    modelProperties: {
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      knowledgeArticleId: {
        serializedName: "knowledgeArticleId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const AppShortcutOpenKnowledgeArticleDto: msRest.CompositeMapper = {
  serializedName: "AppShortcutOpenKnowledgeArticleDto",
  type: {
    name: "Composite",
    className: "AppShortcutOpenKnowledgeArticleDto",
    modelProperties: {
      appShortcutId: {
        serializedName: "appShortcutId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      subTitle: {
        serializedName: "subTitle",
        type: {
          name: "String"
        }
      },
      icon: {
        serializedName: "icon",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      parameters: {
        serializedName: "parameters",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AppShortcutParameterDto"
            }
          }
        }
      },
      knowledgeArticle: {
        serializedName: "knowledgeArticle",
        type: {
          name: "Composite",
          className: "KnowledgeArticleSimpleDto"
        }
      }
    }
  }
};

export const KnowledgeArticleSimpleExportDto: msRest.CompositeMapper = {
  serializedName: "KnowledgeArticleSimpleExportDto",
  type: {
    name: "Composite",
    className: "KnowledgeArticleSimpleExportDto",
    modelProperties: {
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const AppShortcutOpenKnowledgeArticleExportDto: msRest.CompositeMapper = {
  serializedName: "AppShortcutOpenKnowledgeArticleExportDto",
  type: {
    name: "Composite",
    className: "AppShortcutOpenKnowledgeArticleExportDto",
    modelProperties: {
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      subTitle: {
        serializedName: "subTitle",
        type: {
          name: "String"
        }
      },
      icon: {
        serializedName: "icon",
        type: {
          name: "String"
        }
      },
      parameters: {
        serializedName: "parameters",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AppShortcutParameterDto"
            }
          }
        }
      },
      knowledgeArticle: {
        serializedName: "knowledgeArticle",
        type: {
          name: "Composite",
          className: "KnowledgeArticleSimpleExportDto"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8"
          ]
        }
      }
    }
  }
};

export const AppShortcutRouteDto: msRest.CompositeMapper = {
  serializedName: "AppShortcutRouteDto",
  type: {
    name: "Composite",
    className: "AppShortcutRouteDto",
    modelProperties: {
      appShortcutId: {
        serializedName: "appShortcutId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      subTitle: {
        serializedName: "subTitle",
        type: {
          name: "String"
        }
      },
      icon: {
        serializedName: "icon",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      parameters: {
        serializedName: "parameters",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AppShortcutParameterDto"
            }
          }
        }
      },
      route: {
        serializedName: "route",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const AppShortcutRouteExportDto: msRest.CompositeMapper = {
  serializedName: "AppShortcutRouteExportDto",
  type: {
    name: "Composite",
    className: "AppShortcutRouteExportDto",
    modelProperties: {
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      subTitle: {
        serializedName: "subTitle",
        type: {
          name: "String"
        }
      },
      icon: {
        serializedName: "icon",
        type: {
          name: "String"
        }
      },
      parameters: {
        serializedName: "parameters",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AppShortcutParameterDto"
            }
          }
        }
      },
      route: {
        serializedName: "route",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8"
          ]
        }
      }
    }
  }
};

export const AppShortcutShowDocumentFolderDto: msRest.CompositeMapper = {
  serializedName: "AppShortcutShowDocumentFolderDto",
  type: {
    name: "Composite",
    className: "AppShortcutShowDocumentFolderDto",
    modelProperties: {
      appShortcutId: {
        serializedName: "appShortcutId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      subTitle: {
        serializedName: "subTitle",
        type: {
          name: "String"
        }
      },
      icon: {
        serializedName: "icon",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      parameters: {
        serializedName: "parameters",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AppShortcutParameterDto"
            }
          }
        }
      },
      path: {
        serializedName: "path",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const AppShortcutShowDocumentFolderExportDto: msRest.CompositeMapper = {
  serializedName: "AppShortcutShowDocumentFolderExportDto",
  type: {
    name: "Composite",
    className: "AppShortcutShowDocumentFolderExportDto",
    modelProperties: {
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      subTitle: {
        serializedName: "subTitle",
        type: {
          name: "String"
        }
      },
      icon: {
        serializedName: "icon",
        type: {
          name: "String"
        }
      },
      parameters: {
        serializedName: "parameters",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AppShortcutParameterDto"
            }
          }
        }
      },
      path: {
        serializedName: "path",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8"
          ]
        }
      }
    }
  }
};

export const TaskSimpleDto: msRest.CompositeMapper = {
  serializedName: "TaskSimpleDto",
  type: {
    name: "Composite",
    className: "TaskSimpleDto",
    modelProperties: {
      taskId: {
        serializedName: "taskId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      requiresAuthentication: {
        serializedName: "requiresAuthentication",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const AppShortcutShowTaskArchiveDto: msRest.CompositeMapper = {
  serializedName: "AppShortcutShowTaskArchiveDto",
  type: {
    name: "Composite",
    className: "AppShortcutShowTaskArchiveDto",
    modelProperties: {
      appShortcutId: {
        serializedName: "appShortcutId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      subTitle: {
        serializedName: "subTitle",
        type: {
          name: "String"
        }
      },
      icon: {
        serializedName: "icon",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      parameters: {
        serializedName: "parameters",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AppShortcutParameterDto"
            }
          }
        }
      },
      startOffset: {
        serializedName: "startOffset",
        type: {
          name: "String"
        }
      },
      references: {
        serializedName: "references",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TaskSimpleDto"
            }
          }
        }
      }
    }
  }
};

export const TaskSimpleExportDto: msRest.CompositeMapper = {
  serializedName: "TaskSimpleExportDto",
  type: {
    name: "Composite",
    className: "TaskSimpleExportDto",
    modelProperties: {
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const AppShortcutShowTaskArchiveExportDto: msRest.CompositeMapper = {
  serializedName: "AppShortcutShowTaskArchiveExportDto",
  type: {
    name: "Composite",
    className: "AppShortcutShowTaskArchiveExportDto",
    modelProperties: {
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      subTitle: {
        serializedName: "subTitle",
        type: {
          name: "String"
        }
      },
      icon: {
        serializedName: "icon",
        type: {
          name: "String"
        }
      },
      parameters: {
        serializedName: "parameters",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AppShortcutParameterDto"
            }
          }
        }
      },
      startOffset: {
        serializedName: "startOffset",
        type: {
          name: "String"
        }
      },
      references: {
        serializedName: "references",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TaskSimpleExportDto"
            }
          }
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8"
          ]
        }
      }
    }
  }
};

export const AppShortcutUrlDto: msRest.CompositeMapper = {
  serializedName: "AppShortcutUrlDto",
  type: {
    name: "Composite",
    className: "AppShortcutUrlDto",
    modelProperties: {
      appShortcutId: {
        serializedName: "appShortcutId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      subTitle: {
        serializedName: "subTitle",
        type: {
          name: "String"
        }
      },
      icon: {
        serializedName: "icon",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      parameters: {
        serializedName: "parameters",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AppShortcutParameterDto"
            }
          }
        }
      },
      url: {
        serializedName: "url",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const AppShortcutUrlExportDto: msRest.CompositeMapper = {
  serializedName: "AppShortcutUrlExportDto",
  type: {
    name: "Composite",
    className: "AppShortcutUrlExportDto",
    modelProperties: {
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      subTitle: {
        serializedName: "subTitle",
        type: {
          name: "String"
        }
      },
      icon: {
        serializedName: "icon",
        type: {
          name: "String"
        }
      },
      parameters: {
        serializedName: "parameters",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AppShortcutParameterDto"
            }
          }
        }
      },
      url: {
        serializedName: "url",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8"
          ]
        }
      }
    }
  }
};

export const AppTenantDto: msRest.CompositeMapper = {
  serializedName: "AppTenantDto",
  type: {
    name: "Composite",
    className: "AppTenantDto",
    modelProperties: {
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      domain: {
        serializedName: "domain",
        type: {
          name: "String"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      internalCustomNumber: {
        serializedName: "internalCustomNumber",
        type: {
          name: "String"
        }
      },
      primaryBillingSource: {
        serializedName: "primaryBillingSource",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      },
      seed: {
        serializedName: "seed",
        type: {
          name: "Enum",
          allowedValues: [
            "0"
          ]
        }
      },
      brand: {
        serializedName: "brand",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      isActive: {
        serializedName: "isActive",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const AppTenantListDto: msRest.CompositeMapper = {
  serializedName: "AppTenantListDto",
  type: {
    name: "Composite",
    className: "AppTenantListDto",
    modelProperties: {
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      domain: {
        serializedName: "domain",
        type: {
          name: "String"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      isActive: {
        serializedName: "isActive",
        type: {
          name: "Boolean"
        }
      },
      internalCustomNumber: {
        serializedName: "internalCustomNumber",
        type: {
          name: "String"
        }
      },
      brand: {
        serializedName: "brand",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      nextDemoExpirationDate: {
        serializedName: "nextDemoExpirationDate",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const AppTenantTokenDto: msRest.CompositeMapper = {
  serializedName: "AppTenantTokenDto",
  type: {
    name: "Composite",
    className: "AppTenantTokenDto",
    modelProperties: {
      tenantName: {
        serializedName: "tenantName",
        type: {
          name: "String"
        }
      },
      accessToken: {
        serializedName: "accessToken",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const TimelineTaskDto: msRest.CompositeMapper = {
  serializedName: "TimelineTaskDto",
  type: {
    name: "Composite",
    className: "TimelineTaskDto",
    modelProperties: {
      taskId: {
        serializedName: "taskId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      reasonSetId: {
        nullable: true,
        serializedName: "reasonSetId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const TimelineEntryTaskJobDto: msRest.CompositeMapper = {
  serializedName: "TimelineEntryTaskJobDto",
  type: {
    name: "Composite",
    className: "TimelineEntryTaskJobDto",
    modelProperties: {
      taskJobId: {
        serializedName: "taskJobId",
        type: {
          name: "Number"
        }
      },
      executionDate: {
        serializedName: "executionDate",
        type: {
          name: "String"
        }
      },
      currentStatus: {
        serializedName: "currentStatus",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7"
          ]
        }
      },
      children: {
        serializedName: "children",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TimelineEntryTaskJobDto"
            }
          }
        }
      },
      parentId: {
        nullable: true,
        serializedName: "parentId",
        type: {
          name: "Number"
        }
      },
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Number"
        }
      },
      task: {
        serializedName: "task",
        type: {
          name: "Composite",
          className: "TimelineTaskDto"
        }
      }
    }
  }
};

export const TimelineResourceDto: msRest.CompositeMapper = {
  serializedName: "TimelineResourceDto",
  type: {
    name: "Composite",
    className: "TimelineResourceDto",
    modelProperties: {
      resourceId: {
        serializedName: "resourceId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      inventoryNumber: {
        serializedName: "inventoryNumber",
        type: {
          name: "String"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleDto"
        }
      }
    }
  }
};

export const TimelineEntryMissionDto: msRest.CompositeMapper = {
  serializedName: "TimelineEntryMissionDto",
  type: {
    name: "Composite",
    className: "TimelineEntryMissionDto",
    modelProperties: {
      missionId: {
        serializedName: "missionId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      plannedStart: {
        serializedName: "plannedStart",
        type: {
          name: "String"
        }
      },
      plannedEnd: {
        serializedName: "plannedEnd",
        type: {
          name: "String"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4"
          ]
        }
      },
      lastEmailSend: {
        serializedName: "lastEmailSend",
        type: {
          name: "String"
        }
      },
      solutionContact: {
        serializedName: "solutionContact",
        type: {
          name: "Composite",
          className: "ContactSimpleDto"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleDto"
        }
      },
      resource: {
        serializedName: "resource",
        type: {
          name: "Composite",
          className: "TimelineResourceDto"
        }
      }
    }
  }
};

export const AppTimelineListDto: msRest.CompositeMapper = {
  serializedName: "AppTimelineListDto",
  type: {
    name: "Composite",
    className: "AppTimelineListDto",
    modelProperties: {
      date: {
        serializedName: "date",
        type: {
          name: "String"
        }
      },
      importance: {
        serializedName: "importance",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1"
          ]
        }
      },
      taskJobId: {
        serializedName: "taskJobId",
        type: {
          name: "Number"
        }
      },
      taskJob: {
        serializedName: "taskJob",
        type: {
          name: "Composite",
          className: "TimelineEntryTaskJobDto"
        }
      },
      missionId: {
        serializedName: "missionId",
        type: {
          name: "Number"
        }
      },
      mission: {
        serializedName: "mission",
        type: {
          name: "Composite",
          className: "TimelineEntryMissionDto"
        }
      }
    }
  }
};

export const CategorySimpleDto: msRest.CompositeMapper = {
  serializedName: "CategorySimpleDto",
  type: {
    name: "Composite",
    className: "CategorySimpleDto",
    modelProperties: {
      categoryId: {
        serializedName: "categoryId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      color: {
        serializedName: "color",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const AppTimelineSettingDto: msRest.CompositeMapper = {
  serializedName: "AppTimelineSettingDto",
  type: {
    name: "Composite",
    className: "AppTimelineSettingDto",
    modelProperties: {
      categories: {
        serializedName: "categories",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CategorySimpleDto"
            }
          }
        }
      },
      stereotypes: {
        serializedName: "stereotypes",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "StereotypeSimpleDto"
            }
          }
        }
      },
      isActiveMissionOnly: {
        serializedName: "isActiveMissionOnly",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const AppTokenDto: msRest.CompositeMapper = {
  serializedName: "AppTokenDto",
  type: {
    name: "Composite",
    className: "AppTokenDto",
    modelProperties: {
      accessTokens: {
        readOnly: true,
        serializedName: "accessTokens",
        type: {
          name: "Dictionary",
          value: {
            type: {
              name: "String"
            }
          }
        }
      },
      tokens: {
        serializedName: "tokens",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AppTenantTokenDto"
            }
          }
        }
      },
      refreshToken: {
        serializedName: "refreshToken",
        type: {
          name: "String"
        }
      },
      token: {
        serializedName: "token",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const AppliedTemplateDto: msRest.CompositeMapper = {
  serializedName: "AppliedTemplateDto",
  type: {
    name: "Composite",
    className: "AppliedTemplateDto",
    modelProperties: {
      uri: {
        serializedName: "uri",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ResourceSimpleDto: msRest.CompositeMapper = {
  serializedName: "ResourceSimpleDto",
  type: {
    name: "Composite",
    className: "ResourceSimpleDto",
    modelProperties: {
      resourceId: {
        serializedName: "resourceId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      inventoryNumber: {
        serializedName: "inventoryNumber",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ApplyFromResourceByLocationDto: msRest.CompositeMapper = {
  serializedName: "ApplyFromResourceByLocationDto",
  type: {
    name: "Composite",
    className: "ApplyFromResourceByLocationDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      parent: {
        serializedName: "parent",
        type: {
          name: "Composite",
          className: "ResourceSimpleDto"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueDto"
            }
          }
        }
      },
      installationDate: {
        serializedName: "installationDate",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ApplyFromResourcesByLocationDto: msRest.CompositeMapper = {
  serializedName: "ApplyFromResourcesByLocationDto",
  type: {
    name: "Composite",
    className: "ApplyFromResourcesByLocationDto",
    modelProperties: {
      resources: {
        serializedName: "resources",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ApplyFromResourceByLocationDto"
            }
          }
        }
      }
    }
  }
};

export const StateSimpleDto: msRest.CompositeMapper = {
  serializedName: "StateSimpleDto",
  type: {
    name: "Composite",
    className: "StateSimpleDto",
    modelProperties: {
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      stateId: {
        serializedName: "stateId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      color: {
        serializedName: "color",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const PrioritySimpleDto: msRest.CompositeMapper = {
  serializedName: "PrioritySimpleDto",
  type: {
    name: "Composite",
    className: "PrioritySimpleDto",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      priorityId: {
        serializedName: "priorityId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ApplyFromTicketByLocationDto: msRest.CompositeMapper = {
  serializedName: "ApplyFromTicketByLocationDto",
  type: {
    name: "Composite",
    className: "ApplyFromTicketByLocationDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      currentState: {
        serializedName: "currentState",
        type: {
          name: "Composite",
          className: "StateSimpleDto"
        }
      },
      priority: {
        serializedName: "priority",
        type: {
          name: "Composite",
          className: "PrioritySimpleDto"
        }
      },
      resource: {
        serializedName: "resource",
        type: {
          name: "Composite",
          className: "ResourceSimpleDto"
        }
      },
      editor: {
        serializedName: "editor",
        type: {
          name: "Composite",
          className: "ContactSimpleDto"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueDto"
            }
          }
        }
      }
    }
  }
};

export const ApplyFromTicketsByLocationDto: msRest.CompositeMapper = {
  serializedName: "ApplyFromTicketsByLocationDto",
  type: {
    name: "Composite",
    className: "ApplyFromTicketsByLocationDto",
    modelProperties: {
      tickets: {
        serializedName: "tickets",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ApplyFromTicketByLocationDto"
            }
          }
        }
      }
    }
  }
};

export const ApplyLocationOntoResourceQueueDto: msRest.CompositeMapper = {
  serializedName: "ApplyLocationOntoResourceQueueDto",
  type: {
    name: "Composite",
    className: "ApplyLocationOntoResourceQueueDto",
    modelProperties: {
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ArchiveDto: msRest.CompositeMapper = {
  serializedName: "ArchiveDto",
  type: {
    name: "Composite",
    className: "ArchiveDto",
    modelProperties: {
      entityId: {
        serializedName: "entityId",
        type: {
          name: "Number"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const ArchivedEntityQueueDto: msRest.CompositeMapper = {
  serializedName: "ArchivedEntityQueueDto",
  type: {
    name: "Composite",
    className: "ArchivedEntityQueueDto",
    modelProperties: {
      entityId: {
        serializedName: "entityId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ArchivedTaskReasonQueueDto: msRest.CompositeMapper = {
  serializedName: "ArchivedTaskReasonQueueDto",
  type: {
    name: "Composite",
    className: "ArchivedTaskReasonQueueDto",
    modelProperties: {
      taskId: {
        serializedName: "taskId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const CommonColumnDto: msRest.CompositeMapper = {
  serializedName: "CommonColumnDto",
  type: {
    name: "Composite",
    className: "CommonColumnDto",
    modelProperties: {
      sortOrder: {
        serializedName: "sortOrder",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1"
          ]
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const CommonFilterDto: msRest.CompositeMapper = {
  serializedName: "CommonFilterDto",
  type: {
    name: "Composite",
    className: "CommonFilterDto",
    modelProperties: {
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "-1"
          ]
        }
      },
      operator: {
        serializedName: "operator",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "-1"
          ]
        }
      },
      kind: {
        serializedName: "kind",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const RoleSimpleDto: msRest.CompositeMapper = {
  serializedName: "RoleSimpleDto",
  type: {
    name: "Composite",
    className: "RoleSimpleDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const AreaAssignmentDto: msRest.CompositeMapper = {
  serializedName: "AreaAssignmentDto",
  type: {
    name: "Composite",
    className: "AreaAssignmentDto",
    modelProperties: {
      areaAssignmentId: {
        serializedName: "areaAssignmentId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      reach: {
        serializedName: "reach",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      },
      locations: {
        serializedName: "locations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationSimpleDto"
            }
          }
        }
      },
      locationGroups: {
        serializedName: "locationGroups",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationGroupSimpleDto"
            }
          }
        }
      },
      roles: {
        serializedName: "roles",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RoleSimpleDto"
            }
          }
        }
      },
      functions: {
        serializedName: "functions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FunctionSimpleDto"
            }
          }
        }
      }
    }
  }
};

export const AreaAssignmentBulkDto: msRest.CompositeMapper = {
  serializedName: "AreaAssignmentBulkDto",
  type: {
    name: "Composite",
    className: "AreaAssignmentBulkDto",
    modelProperties: {
      totalItems: {
        nullable: true,
        serializedName: "totalItems",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      columns: {
        serializedName: "columns",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CommonColumnDto"
            }
          }
        }
      },
      filters: {
        serializedName: "filters",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CommonFilterDto"
            }
          }
        }
      },
      areaAssignment: {
        serializedName: "areaAssignment",
        type: {
          name: "Composite",
          className: "AreaAssignmentDto"
        }
      }
    }
  }
};

export const AreaAssignmentInContactDto: msRest.CompositeMapper = {
  serializedName: "AreaAssignmentInContactDto",
  type: {
    name: "Composite",
    className: "AreaAssignmentInContactDto",
    modelProperties: {
      areaAssignmentId: {
        serializedName: "areaAssignmentId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      reach: {
        serializedName: "reach",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      },
      locations: {
        serializedName: "locations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationSimpleDto"
            }
          }
        }
      },
      locationGroups: {
        serializedName: "locationGroups",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationGroupSimpleDto"
            }
          }
        }
      },
      roles: {
        serializedName: "roles",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RoleSimpleDto"
            }
          }
        }
      },
      functions: {
        serializedName: "functions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FunctionSimpleDto"
            }
          }
        }
      }
    }
  }
};

export const ContactSimpleListDto: msRest.CompositeMapper = {
  serializedName: "ContactSimpleListDto",
  type: {
    name: "Composite",
    className: "ContactSimpleListDto",
    modelProperties: {
      contactId: {
        serializedName: "contactId",
        type: {
          name: "Number"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const LocationSimpleListDto: msRest.CompositeMapper = {
  serializedName: "LocationSimpleListDto",
  type: {
    name: "Composite",
    className: "LocationSimpleListDto",
    modelProperties: {
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      locationNumber: {
        serializedName: "locationNumber",
        type: {
          name: "String"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const LocationRelationListDto: msRest.CompositeMapper = {
  serializedName: "LocationRelationListDto",
  type: {
    name: "Composite",
    className: "LocationRelationListDto",
    modelProperties: {
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Number"
        }
      },
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "LocationSimpleListDto"
        }
      }
    }
  }
};

export const LocationGroupSimpleListDto: msRest.CompositeMapper = {
  serializedName: "LocationGroupSimpleListDto",
  type: {
    name: "Composite",
    className: "LocationGroupSimpleListDto",
    modelProperties: {
      locationGroupId: {
        serializedName: "locationGroupId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const LocationGroupRelationListDto: msRest.CompositeMapper = {
  serializedName: "LocationGroupRelationListDto",
  type: {
    name: "Composite",
    className: "LocationGroupRelationListDto",
    modelProperties: {
      locationGroupId: {
        serializedName: "locationGroupId",
        type: {
          name: "Number"
        }
      },
      locationGroup: {
        serializedName: "locationGroup",
        type: {
          name: "Composite",
          className: "LocationGroupSimpleListDto"
        }
      }
    }
  }
};

export const FunctionListSimpleDto: msRest.CompositeMapper = {
  serializedName: "FunctionListSimpleDto",
  type: {
    name: "Composite",
    className: "FunctionListSimpleDto",
    modelProperties: {
      functionId: {
        serializedName: "functionId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      }
    }
  }
};

export const FunctionInAreaListDto: msRest.CompositeMapper = {
  serializedName: "FunctionInAreaListDto",
  type: {
    name: "Composite",
    className: "FunctionInAreaListDto",
    modelProperties: {
      functionId: {
        serializedName: "functionId",
        type: {
          name: "Number"
        }
      },
      functionProperty: {
        serializedName: "function",
        type: {
          name: "Composite",
          className: "FunctionListSimpleDto"
        }
      }
    }
  }
};

export const RoleSimpleListDto: msRest.CompositeMapper = {
  serializedName: "RoleSimpleListDto",
  type: {
    name: "Composite",
    className: "RoleSimpleListDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      appRoleId: {
        serializedName: "appRoleId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const RoleInAreaListDto: msRest.CompositeMapper = {
  serializedName: "RoleInAreaListDto",
  type: {
    name: "Composite",
    className: "RoleInAreaListDto",
    modelProperties: {
      roleId: {
        serializedName: "roleId",
        type: {
          name: "Number"
        }
      },
      role: {
        serializedName: "role",
        type: {
          name: "Composite",
          className: "RoleSimpleListDto"
        }
      }
    }
  }
};

export const AreaAssignmentListDto: msRest.CompositeMapper = {
  serializedName: "AreaAssignmentListDto",
  type: {
    name: "Composite",
    className: "AreaAssignmentListDto",
    modelProperties: {
      areaAssignmentId: {
        serializedName: "areaAssignmentId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      reach: {
        serializedName: "reach",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      },
      contact: {
        serializedName: "contact",
        type: {
          name: "Composite",
          className: "ContactSimpleListDto"
        }
      },
      locations: {
        serializedName: "locations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationRelationListDto"
            }
          }
        }
      },
      locationGroups: {
        serializedName: "locationGroups",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationGroupRelationListDto"
            }
          }
        }
      },
      functions: {
        serializedName: "functions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FunctionInAreaListDto"
            }
          }
        }
      },
      roles: {
        serializedName: "roles",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RoleInAreaListDto"
            }
          }
        }
      }
    }
  }
};

export const ArticleKindSimpleDto: msRest.CompositeMapper = {
  serializedName: "ArticleKindSimpleDto",
  type: {
    name: "Composite",
    className: "ArticleKindSimpleDto",
    modelProperties: {
      articleKindId: {
        serializedName: "articleKindId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ArticleDto: msRest.CompositeMapper = {
  serializedName: "ArticleDto",
  type: {
    name: "Composite",
    className: "ArticleDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      articleId: {
        serializedName: "articleId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      number: {
        serializedName: "number",
        type: {
          name: "String"
        }
      },
      unit: {
        serializedName: "unit",
        type: {
          name: "String"
        }
      },
      purchasingPrice: {
        serializedName: "purchasingPrice",
        type: {
          name: "Number"
        }
      },
      sellingPrice: {
        serializedName: "sellingPrice",
        type: {
          name: "Number"
        }
      },
      kind: {
        serializedName: "kind",
        type: {
          name: "Composite",
          className: "ArticleKindSimpleDto"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueDto"
            }
          }
        }
      }
    }
  }
};

export const ArticleInUsageDto: msRest.CompositeMapper = {
  serializedName: "ArticleInUsageDto",
  type: {
    name: "Composite",
    className: "ArticleInUsageDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      articleId: {
        serializedName: "articleId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const ArticleInUsageSkeletonDto: msRest.CompositeMapper = {
  serializedName: "ArticleInUsageSkeletonDto",
  type: {
    name: "Composite",
    className: "ArticleInUsageSkeletonDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      articleId: {
        serializedName: "articleId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      unit: {
        serializedName: "unit",
        type: {
          name: "String"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const ArticleKindDto: msRest.CompositeMapper = {
  serializedName: "ArticleKindDto",
  type: {
    name: "Composite",
    className: "ArticleKindDto",
    modelProperties: {
      articleKindId: {
        serializedName: "articleKindId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ArticleKindListDto: msRest.CompositeMapper = {
  serializedName: "ArticleKindListDto",
  type: {
    name: "Composite",
    className: "ArticleKindListDto",
    modelProperties: {
      articleKindId: {
        serializedName: "articleKindId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ArticleKindSimpleListDto: msRest.CompositeMapper = {
  serializedName: "ArticleKindSimpleListDto",
  type: {
    name: "Composite",
    className: "ArticleKindSimpleListDto",
    modelProperties: {
      articleKindId: {
        serializedName: "articleKindId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ArticleListDto: msRest.CompositeMapper = {
  serializedName: "ArticleListDto",
  type: {
    name: "Composite",
    className: "ArticleListDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      articleId: {
        serializedName: "articleId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      number: {
        serializedName: "number",
        type: {
          name: "String"
        }
      },
      unit: {
        serializedName: "unit",
        type: {
          name: "String"
        }
      },
      purchasingPrice: {
        serializedName: "purchasingPrice",
        type: {
          name: "Number"
        }
      },
      sellingPrice: {
        serializedName: "sellingPrice",
        type: {
          name: "Number"
        }
      },
      kind: {
        serializedName: "kind",
        type: {
          name: "Composite",
          className: "ArticleKindSimpleListDto"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueListDto"
            }
          }
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleListDto"
        }
      }
    }
  }
};

export const ArticleUsageDto: msRest.CompositeMapper = {
  serializedName: "ArticleUsageDto",
  type: {
    name: "Composite",
    className: "ArticleUsageDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      articleUsageId: {
        serializedName: "articleUsageId",
        type: {
          name: "Number"
        }
      },
      count: {
        serializedName: "count",
        type: {
          name: "Number"
        }
      },
      price: {
        serializedName: "price",
        type: {
          name: "Number"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      unit: {
        serializedName: "unit",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      total: {
        serializedName: "total",
        type: {
          name: "Number"
        }
      },
      note: {
        serializedName: "note",
        type: {
          name: "String"
        }
      },
      article: {
        serializedName: "article",
        type: {
          name: "Composite",
          className: "ArticleInUsageDto"
        }
      }
    }
  }
};

export const LocationInArticleUsageListDto: msRest.CompositeMapper = {
  serializedName: "LocationInArticleUsageListDto",
  type: {
    name: "Composite",
    className: "LocationInArticleUsageListDto",
    modelProperties: {
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      locationNumber: {
        serializedName: "locationNumber",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ResourceInArticleUsageListDto: msRest.CompositeMapper = {
  serializedName: "ResourceInArticleUsageListDto",
  type: {
    name: "Composite",
    className: "ResourceInArticleUsageListDto",
    modelProperties: {
      resourceId: {
        serializedName: "resourceId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      inventoryNumber: {
        serializedName: "inventoryNumber",
        type: {
          name: "String"
        }
      },
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "LocationInArticleUsageListDto"
        }
      }
    }
  }
};

export const MissionInArticleListDto: msRest.CompositeMapper = {
  serializedName: "MissionInArticleListDto",
  type: {
    name: "Composite",
    className: "MissionInArticleListDto",
    modelProperties: {
      missionId: {
        serializedName: "missionId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      plannedStart: {
        serializedName: "plannedStart",
        type: {
          name: "String"
        }
      },
      plannedEnd: {
        serializedName: "plannedEnd",
        type: {
          name: "String"
        }
      },
      actualStart: {
        serializedName: "actualStart",
        type: {
          name: "String"
        }
      },
      actualEnd: {
        serializedName: "actualEnd",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4"
          ]
        }
      },
      resource: {
        serializedName: "resource",
        type: {
          name: "Composite",
          className: "ResourceInArticleUsageListDto"
        }
      }
    }
  }
};

export const ArticleUsageListDto: msRest.CompositeMapper = {
  serializedName: "ArticleUsageListDto",
  type: {
    name: "Composite",
    className: "ArticleUsageListDto",
    modelProperties: {
      articleUsageId: {
        serializedName: "articleUsageId",
        type: {
          name: "Number"
        }
      },
      count: {
        serializedName: "count",
        type: {
          name: "Number"
        }
      },
      price: {
        serializedName: "price",
        type: {
          name: "Number"
        }
      },
      total: {
        serializedName: "total",
        type: {
          name: "Number"
        }
      },
      note: {
        serializedName: "note",
        type: {
          name: "String"
        }
      },
      mission: {
        serializedName: "mission",
        type: {
          name: "Composite",
          className: "MissionInArticleListDto"
        }
      }
    }
  }
};

export const ArticleUsageSkeletonDto: msRest.CompositeMapper = {
  serializedName: "ArticleUsageSkeletonDto",
  type: {
    name: "Composite",
    className: "ArticleUsageSkeletonDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      articleUsageSkeletonId: {
        serializedName: "articleUsageSkeletonId",
        type: {
          name: "Number"
        }
      },
      count: {
        nullable: true,
        serializedName: "count",
        type: {
          name: "Number"
        }
      },
      price: {
        nullable: true,
        serializedName: "price",
        type: {
          name: "Number"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      article: {
        serializedName: "article",
        type: {
          name: "Composite",
          className: "ArticleInUsageSkeletonDto"
        }
      }
    }
  }
};

export const ArticleUsageTtm: msRest.CompositeMapper = {
  serializedName: "ArticleUsageTtm",
  type: {
    name: "Composite",
    className: "ArticleUsageTtm",
    modelProperties: {
      articleUsageId: {
        serializedName: "articleUsageId",
        type: {
          name: "Number"
        }
      },
      count: {
        serializedName: "count",
        type: {
          name: "Number"
        }
      },
      price: {
        serializedName: "price",
        type: {
          name: "Number"
        }
      },
      unit: {
        serializedName: "unit",
        type: {
          name: "String"
        }
      },
      note: {
        serializedName: "note",
        type: {
          name: "String"
        }
      },
      total: {
        serializedName: "total",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const SolutionMemberDto: msRest.CompositeMapper = {
  serializedName: "SolutionMemberDto",
  type: {
    name: "Composite",
    className: "SolutionMemberDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2"
          ]
        }
      },
      missionType: {
        serializedName: "missionType",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4"
          ]
        }
      }
    }
  }
};

export const AssignAttachmentToSolutionDto: msRest.CompositeMapper = {
  serializedName: "AssignAttachmentToSolutionDto",
  type: {
    name: "Composite",
    className: "AssignAttachmentToSolutionDto",
    modelProperties: {
      member: {
        serializedName: "member",
        type: {
          name: "Composite",
          className: "SolutionMemberDto"
        }
      },
      attachment: {
        serializedName: "attachment",
        type: {
          name: "Composite",
          className: "AttachmentForTechDto"
        }
      }
    }
  }
};

export const AttachmentForTechCompleteDto: msRest.CompositeMapper = {
  serializedName: "AttachmentForTechCompleteDto",
  type: {
    name: "Composite",
    className: "AttachmentForTechCompleteDto",
    modelProperties: {
      attachmentId: {
        serializedName: "attachmentId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1"
          ]
        }
      },
      file: {
        serializedName: "file",
        type: {
          name: "Composite",
          className: "FileDto"
        }
      }
    }
  }
};

export const FormDefaultDto: msRest.CompositeMapper = {
  serializedName: "FormDefaultDto",
  type: {
    name: "Composite",
    className: "FormDefaultDto",
    modelProperties: {
      fieldValueId: {
        serializedName: "fieldValueId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12"
          ]
        }
      }
    }
  }
};

export const OrgaAttachmentDto: msRest.CompositeMapper = {
  serializedName: "OrgaAttachmentDto",
  type: {
    name: "Composite",
    className: "OrgaAttachmentDto",
    modelProperties: {
      attachmentId: {
        serializedName: "attachmentId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5"
          ]
        }
      }
    }
  }
};

export const AttachmentFormFieldDto: msRest.CompositeMapper = {
  serializedName: "AttachmentFormFieldDto",
  type: {
    name: "Composite",
    className: "AttachmentFormFieldDto",
    modelProperties: {
      formFieldId: {
        serializedName: "formFieldId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      technicalName: {
        readOnly: true,
        serializedName: "technicalName",
        type: {
          name: "String"
        }
      },
      width: {
        serializedName: "width",
        type: {
          name: "Number"
        }
      },
      isRequired: {
        serializedName: "isRequired",
        type: {
          name: "Boolean"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12"
          ]
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      defaultValues: {
        serializedName: "defaultValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FormDefaultDto"
            }
          }
        }
      },
      localFieldId: {
        serializedName: "localFieldId",
        type: {
          name: "String"
        }
      },
      attachmentId: {
        nullable: true,
        serializedName: "attachmentId",
        type: {
          name: "Number"
        }
      },
      attachment: {
        serializedName: "attachment",
        type: {
          name: "Composite",
          className: "OrgaAttachmentDto"
        }
      }
    }
  }
};

export const AuditOfPropertyDto: msRest.CompositeMapper = {
  serializedName: "AuditOfPropertyDto",
  type: {
    name: "Composite",
    className: "AuditOfPropertyDto",
    modelProperties: {
      auditPropertyId: {
        serializedName: "auditPropertyId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      oldValue: {
        serializedName: "oldValue",
        type: {
          name: "String"
        }
      },
      newValue: {
        serializedName: "newValue",
        type: {
          name: "String"
        }
      },
      typeOfProperty: {
        serializedName: "typeOfProperty",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      }
    }
  }
};

export const AuditOfCollectionDto: msRest.CompositeMapper = {
  serializedName: "AuditOfCollectionDto",
  type: {
    name: "Composite",
    className: "AuditOfCollectionDto",
    modelProperties: {
      auditCollectionId: {
        serializedName: "auditCollectionId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      count: {
        serializedName: "count",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const AuditDto: msRest.CompositeMapper = {
  serializedName: "AuditDto",
  type: {
    name: "Composite",
    className: "AuditDto",
    modelProperties: {
      auditId: {
        serializedName: "auditId",
        type: {
          name: "Number"
        }
      },
      kind: {
        serializedName: "kind",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "String"
        }
      },
      entityType: {
        serializedName: "entityType",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
            "31",
            "32",
            "33",
            "34",
            "35",
            "36",
            "37",
            "38",
            "39",
            "40",
            "41",
            "42",
            "43",
            "44",
            "45"
          ]
        }
      },
      entityId: {
        serializedName: "entityId",
        type: {
          name: "Number"
        }
      },
      createdByUserName: {
        serializedName: "createdByUserName",
        type: {
          name: "String"
        }
      },
      createdByUserId: {
        serializedName: "createdByUserId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      properties: {
        serializedName: "properties",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AuditOfPropertyDto"
            }
          }
        }
      },
      collections: {
        serializedName: "collections",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AuditOfCollectionDto"
            }
          }
        }
      }
    }
  }
};

export const AuditListDto: msRest.CompositeMapper = {
  serializedName: "AuditListDto",
  type: {
    name: "Composite",
    className: "AuditListDto",
    modelProperties: {
      auditId: {
        serializedName: "auditId",
        type: {
          name: "Number"
        }
      },
      kind: {
        serializedName: "kind",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "String"
        }
      },
      entityType: {
        serializedName: "entityType",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
            "31",
            "32",
            "33",
            "34",
            "35",
            "36",
            "37",
            "38",
            "39",
            "40",
            "41",
            "42",
            "43",
            "44",
            "45"
          ]
        }
      },
      entityId: {
        serializedName: "entityId",
        type: {
          name: "Number"
        }
      },
      createdByUserName: {
        serializedName: "createdByUserName",
        type: {
          name: "String"
        }
      },
      createdByUserId: {
        serializedName: "createdByUserId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const AzureLoginProviderDto: msRest.CompositeMapper = {
  serializedName: "AzureLoginProviderDto",
  type: {
    name: "Composite",
    className: "AzureLoginProviderDto",
    modelProperties: {
      loginProviderId: {
        serializedName: "loginProviderId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      defaultRoleId: {
        nullable: true,
        serializedName: "defaultRoleId",
        type: {
          name: "Number"
        }
      },
      defaultRole: {
        serializedName: "defaultRole",
        type: {
          name: "Composite",
          className: "AppRoleDto"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      azureTenantId: {
        serializedName: "azureTenantId",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const AzureLoginProviderListDto: msRest.CompositeMapper = {
  serializedName: "AzureLoginProviderListDto",
  type: {
    name: "Composite",
    className: "AzureLoginProviderListDto",
    modelProperties: {
      loginProviderId: {
        serializedName: "loginProviderId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      defaultRoleId: {
        nullable: true,
        serializedName: "defaultRoleId",
        type: {
          name: "Number"
        }
      },
      defaultRole: {
        serializedName: "defaultRole",
        type: {
          name: "Composite",
          className: "AppRoleDto"
        }
      },
      provider: {
        serializedName: "provider",
        type: {
          name: "Number"
        }
      },
      azureTenantId: {
        serializedName: "azureTenantId",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const SimpleLoginProviderDto: msRest.CompositeMapper = {
  serializedName: "SimpleLoginProviderDto",
  type: {
    name: "Composite",
    className: "SimpleLoginProviderDto",
    modelProperties: {
      loginProviderId: {
        serializedName: "loginProviderId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const AzureLoginProviderRoleDto: msRest.CompositeMapper = {
  serializedName: "AzureLoginProviderRoleDto",
  type: {
    name: "Composite",
    className: "AzureLoginProviderRoleDto",
    modelProperties: {
      loginProviderRoleId: {
        serializedName: "loginProviderRoleId",
        type: {
          name: "Number"
        }
      },
      roleId: {
        serializedName: "roleId",
        type: {
          name: "Number"
        }
      },
      role: {
        serializedName: "role",
        type: {
          name: "Composite",
          className: "AppRoleDto"
        }
      },
      loginProviderId: {
        serializedName: "loginProviderId",
        type: {
          name: "Number"
        }
      },
      loginProvider: {
        serializedName: "loginProvider",
        type: {
          name: "Composite",
          className: "SimpleLoginProviderDto"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      },
      azureGroupName: {
        serializedName: "azureGroupName",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const AzureLoginProviderRoleListDto: msRest.CompositeMapper = {
  serializedName: "AzureLoginProviderRoleListDto",
  type: {
    name: "Composite",
    className: "AzureLoginProviderRoleListDto",
    modelProperties: {
      loginProviderRoleId: {
        serializedName: "loginProviderRoleId",
        type: {
          name: "Number"
        }
      },
      roleId: {
        serializedName: "roleId",
        type: {
          name: "Number"
        }
      },
      role: {
        serializedName: "role",
        type: {
          name: "Composite",
          className: "AppRoleListDto"
        }
      },
      loginProviderId: {
        serializedName: "loginProviderId",
        type: {
          name: "Number"
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      },
      azureGroupName: {
        serializedName: "azureGroupName",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const DataTableColumnDto: msRest.CompositeMapper = {
  serializedName: "DataTableColumnDto",
  type: {
    name: "Composite",
    className: "DataTableColumnDto",
    modelProperties: {
      dataTableColumnId: {
        serializedName: "dataTableColumnId",
        type: {
          name: "Number"
        }
      },
      sortOrder: {
        serializedName: "sortOrder",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1"
          ]
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const DataTableFilterDto: msRest.CompositeMapper = {
  serializedName: "DataTableFilterDto",
  type: {
    name: "Composite",
    className: "DataTableFilterDto",
    modelProperties: {
      dataTableFilterId: {
        serializedName: "dataTableFilterId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "-1"
          ]
        }
      },
      operator: {
        serializedName: "operator",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "-1"
          ]
        }
      },
      kind: {
        serializedName: "kind",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      },
      label: {
        serializedName: "label",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const CalendarDataTableDto: msRest.CompositeMapper = {
  serializedName: "CalendarDataTableDto",
  type: {
    name: "Composite",
    className: "CalendarDataTableDto",
    modelProperties: {
      columns: {
        serializedName: "columns",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "DataTableColumnDto"
            }
          }
        }
      },
      filters: {
        serializedName: "filters",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "DataTableFilterDto"
            }
          }
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const CalendarConnectionDto: msRest.CompositeMapper = {
  serializedName: "CalendarConnectionDto",
  type: {
    name: "Composite",
    className: "CalendarConnectionDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      calendarDataTable: {
        serializedName: "calendarDataTable",
        type: {
          name: "Composite",
          className: "CalendarDataTableDto"
        }
      },
      key: {
        serializedName: "key",
        type: {
          name: "String"
        }
      },
      url: {
        serializedName: "url",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CalendarEventDto: msRest.CompositeMapper = {
  serializedName: "CalendarEventDto",
  type: {
    name: "Composite",
    className: "CalendarEventDto",
    modelProperties: {
      uid: {
        serializedName: "uid",
        type: {
          name: "String"
        }
      },
      summary: {
        serializedName: "summary",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      location: {
        serializedName: "location",
        type: {
          name: "String"
        }
      },
      url: {
        serializedName: "url",
        type: {
          name: "String"
        }
      },
      start: {
        serializedName: "start",
        type: {
          name: "String"
        }
      },
      end: {
        serializedName: "end",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CalendarPropertyDto: msRest.CompositeMapper = {
  serializedName: "CalendarPropertyDto",
  type: {
    name: "Composite",
    className: "CalendarPropertyDto",
    modelProperties: {
      key: {
        serializedName: "key",
        type: {
          name: "String"
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CalendarListDto: msRest.CompositeMapper = {
  serializedName: "CalendarListDto",
  type: {
    name: "Composite",
    className: "CalendarListDto",
    modelProperties: {
      properties: {
        serializedName: "properties",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CalendarPropertyDto"
            }
          }
        }
      },
      events: {
        serializedName: "events",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CalendarEventDto"
            }
          }
        }
      }
    }
  }
};

export const CancelJobsQueueDto: msRest.CompositeMapper = {
  serializedName: "CancelJobsQueueDto",
  type: {
    name: "Composite",
    className: "CancelJobsQueueDto",
    modelProperties: {
      taskId: {
        serializedName: "taskId",
        type: {
          name: "Number"
        }
      },
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const CancelResourceTaskQueueDto: msRest.CompositeMapper = {
  serializedName: "CancelResourceTaskQueueDto",
  type: {
    name: "Composite",
    className: "CancelResourceTaskQueueDto",
    modelProperties: {
      taskId: {
        serializedName: "taskId",
        type: {
          name: "Number"
        }
      },
      resourceId: {
        serializedName: "resourceId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const CancelSearchingEditorQueueDto: msRest.CompositeMapper = {
  serializedName: "CancelSearchingEditorQueueDto",
  type: {
    name: "Composite",
    className: "CancelSearchingEditorQueueDto",
    modelProperties: {
      missionId: {
        serializedName: "missionId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const MailInQueueDto: msRest.CompositeMapper = {
  serializedName: "MailInQueueDto",
  type: {
    name: "Composite",
    className: "MailInQueueDto",
    modelProperties: {
      domain: {
        serializedName: "domain",
        type: {
          name: "String"
        }
      },
      subject: {
        serializedName: "subject",
        type: {
          name: "String"
        }
      },
      receivers: {
        serializedName: "receivers",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      fileId: {
        serializedName: "fileId",
        type: {
          name: "Number"
        }
      },
      filePath: {
        serializedName: "filePath",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CarrySolutionMailInQueueDto: msRest.CompositeMapper = {
  serializedName: "CarrySolutionMailInQueueDto",
  type: {
    name: "Composite",
    className: "CarrySolutionMailInQueueDto",
    modelProperties: {
      threadId: {
        serializedName: "threadId",
        type: {
          name: "String"
        }
      },
      isSuccess: {
        serializedName: "isSuccess",
        type: {
          name: "Boolean"
        }
      },
      triggeredNodeId: {
        serializedName: "triggeredNodeId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      mailId: {
        serializedName: "mailId",
        type: {
          name: "Number"
        }
      },
      mailReference: {
        serializedName: "mailReference",
        type: {
          name: "Composite",
          className: "MailInQueueDto"
        }
      }
    }
  }
};

export const CategoriesByTypeDto: msRest.CompositeMapper = {
  serializedName: "CategoriesByTypeDto",
  type: {
    name: "Composite",
    className: "CategoriesByTypeDto",
    modelProperties: {
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "4",
            "8"
          ]
        }
      },
      items: {
        serializedName: "items",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CategorySimpleDto"
            }
          }
        }
      }
    }
  }
};

export const CategoryInRelationListDto: msRest.CompositeMapper = {
  serializedName: "CategoryInRelationListDto",
  type: {
    name: "Composite",
    className: "CategoryInRelationListDto",
    modelProperties: {
      categoryId: {
        serializedName: "categoryId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      color: {
        serializedName: "color",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CategorizedRelationDto: msRest.CompositeMapper = {
  serializedName: "CategorizedRelationDto",
  type: {
    name: "Composite",
    className: "CategorizedRelationDto",
    modelProperties: {
      categoryId: {
        serializedName: "categoryId",
        type: {
          name: "Number"
        }
      },
      category: {
        serializedName: "category",
        type: {
          name: "Composite",
          className: "CategoryInRelationListDto"
        }
      }
    }
  }
};

export const CategoryDto: msRest.CompositeMapper = {
  serializedName: "CategoryDto",
  type: {
    name: "Composite",
    className: "CategoryDto",
    modelProperties: {
      categoryId: {
        serializedName: "categoryId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      color: {
        serializedName: "color",
        type: {
          name: "String"
        }
      },
      sector: {
        serializedName: "sector",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const CategoryListDto: msRest.CompositeMapper = {
  serializedName: "CategoryListDto",
  type: {
    name: "Composite",
    className: "CategoryListDto",
    modelProperties: {
      categoryId: {
        serializedName: "categoryId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      color: {
        serializedName: "color",
        type: {
          name: "String"
        }
      },
      sector: {
        serializedName: "sector",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3"
          ]
        }
      }
    }
  }
};

export const ChangeEmailDto: msRest.CompositeMapper = {
  serializedName: "ChangeEmailDto",
  type: {
    name: "Composite",
    className: "ChangeEmailDto",
    modelProperties: {
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ChangeResourceStateDto: msRest.CompositeMapper = {
  serializedName: "ChangeResourceStateDto",
  type: {
    name: "Composite",
    className: "ChangeResourceStateDto",
    modelProperties: {
      state: {
        serializedName: "state",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3"
          ]
        }
      },
      locationId: {
        nullable: true,
        serializedName: "locationId",
        type: {
          name: "Number"
        }
      },
      isKeepingLocation: {
        serializedName: "isKeepingLocation",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const ChangeStateOfMissionDto: msRest.CompositeMapper = {
  serializedName: "ChangeStateOfMissionDto",
  type: {
    name: "Composite",
    className: "ChangeStateOfMissionDto",
    modelProperties: {
      missionId: {
        serializedName: "missionId",
        type: {
          name: "Number"
        }
      },
      wantedState: {
        serializedName: "wantedState",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4"
          ]
        }
      },
      isSendingMail: {
        serializedName: "isSendingMail",
        type: {
          name: "Boolean"
        }
      },
      startDate: {
        serializedName: "startDate",
        type: {
          name: "String"
        }
      },
      endDate: {
        serializedName: "endDate",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ChangeTaskJobStatusDto: msRest.CompositeMapper = {
  serializedName: "ChangeTaskJobStatusDto",
  type: {
    name: "Composite",
    className: "ChangeTaskJobStatusDto",
    modelProperties: {
      taskJobId: {
        serializedName: "taskJobId",
        type: {
          name: "Number"
        }
      },
      targetState: {
        serializedName: "targetState",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      reasonId: {
        serializedName: "reasonId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ChangeTicketStateWhenMissionClosedQueueDto: msRest.CompositeMapper = {
  serializedName: "ChangeTicketStateWhenMissionClosedQueueDto",
  type: {
    name: "Composite",
    className: "ChangeTicketStateWhenMissionClosedQueueDto",
    modelProperties: {
      ticketId: {
        serializedName: "ticketId",
        type: {
          name: "Number"
        }
      },
      missionId: {
        serializedName: "missionId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ChangeTicketStateWhenMissionStateChangedQueueDto: msRest.CompositeMapper = {
  serializedName: "ChangeTicketStateWhenMissionStateChangedQueueDto",
  type: {
    name: "Composite",
    className: "ChangeTicketStateWhenMissionStateChangedQueueDto",
    modelProperties: {
      ticketId: {
        serializedName: "ticketId",
        type: {
          name: "Number"
        }
      },
      newState: {
        serializedName: "newState",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4"
          ]
        }
      },
      missionId: {
        serializedName: "missionId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ChangedAreaAssignmentQueueDto: msRest.CompositeMapper = {
  serializedName: "ChangedAreaAssignmentQueueDto",
  type: {
    name: "Composite",
    className: "ChangedAreaAssignmentQueueDto",
    modelProperties: {
      contactId: {
        serializedName: "contactId",
        type: {
          name: "Number"
        }
      },
      skipDelay: {
        serializedName: "skipDelay",
        type: {
          name: "Boolean"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ChangedEntityQueueDto: msRest.CompositeMapper = {
  serializedName: "ChangedEntityQueueDto",
  type: {
    name: "Composite",
    className: "ChangedEntityQueueDto",
    modelProperties: {
      entityId: {
        serializedName: "entityId",
        type: {
          name: "Number"
        }
      },
      skipDelay: {
        serializedName: "skipDelay",
        type: {
          name: "Boolean"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ChangedLocationAndLocationGroupConnectionQueueDto: msRest.CompositeMapper = {
  serializedName: "ChangedLocationAndLocationGroupConnectionQueueDto",
  type: {
    name: "Composite",
    className: "ChangedLocationAndLocationGroupConnectionQueueDto",
    modelProperties: {
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Number"
        }
      },
      locationGroupId: {
        serializedName: "locationGroupId",
        type: {
          name: "Number"
        }
      },
      isNew: {
        serializedName: "isNew",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const ChangedLocationGroupLocationQueueDto: msRest.CompositeMapper = {
  serializedName: "ChangedLocationGroupLocationQueueDto",
  type: {
    name: "Composite",
    className: "ChangedLocationGroupLocationQueueDto",
    modelProperties: {
      locationGroupId: {
        serializedName: "locationGroupId",
        type: {
          name: "Number"
        }
      },
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Number"
        }
      },
      skipDelay: {
        serializedName: "skipDelay",
        type: {
          name: "Boolean"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      scopeId: {
        readOnly: true,
        serializedName: "scopeId",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ChangedStateOfMissionDto: msRest.CompositeMapper = {
  serializedName: "ChangedStateOfMissionDto",
  type: {
    name: "Composite",
    className: "ChangedStateOfMissionDto",
    modelProperties: {
      state: {
        serializedName: "state",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4"
          ]
        }
      },
      actualStart: {
        serializedName: "actualStart",
        type: {
          name: "String"
        }
      },
      actualEnd: {
        serializedName: "actualEnd",
        type: {
          name: "String"
        }
      },
      plannedStart: {
        serializedName: "plannedStart",
        type: {
          name: "String"
        }
      },
      plannedEnd: {
        serializedName: "plannedEnd",
        type: {
          name: "String"
        }
      },
      lastEmailSend: {
        serializedName: "lastEmailSend",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ChangedStatusConditionDto: msRest.CompositeMapper = {
  serializedName: "ChangedStatusConditionDto",
  type: {
    name: "Composite",
    className: "ChangedStatusConditionDto",
    modelProperties: {
      conditionId: {
        serializedName: "conditionId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      targetState: {
        serializedName: "targetState",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7"
          ]
        }
      }
    }
  }
};

export const ChangedStatusConditionV1Dto: msRest.CompositeMapper = {
  serializedName: "ChangedStatusConditionV1Dto",
  type: {
    name: "Composite",
    className: "ChangedStatusConditionV1Dto",
    modelProperties: {
      conditionId: {
        serializedName: "conditionId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      targetState: {
        serializedName: "targetState",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7"
          ]
        }
      }
    }
  }
};

export const ChatByDefinitionGeneralDataProtectionRestrictionDto: msRest.CompositeMapper = {
  serializedName: "ChatByDefinitionGeneralDataProtectionRestrictionDto",
  type: {
    name: "Composite",
    className: "ChatByDefinitionGeneralDataProtectionRestrictionDto",
    modelProperties: {
      generalDataProtectionRestrictionId: {
        serializedName: "generalDataProtectionRestrictionId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      },
      definition: {
        serializedName: "definition",
        type: {
          name: "Composite",
          className: "ChatByFormDefinitionSimpleDto"
        }
      }
    }
  }
};

export const FormSimpleDto: msRest.CompositeMapper = {
  serializedName: "FormSimpleDto",
  type: {
    name: "Composite",
    className: "FormSimpleDto",
    modelProperties: {
      formId: {
        serializedName: "formId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ChatByFormMessagePatternDto: msRest.CompositeMapper = {
  serializedName: "ChatByFormMessagePatternDto",
  type: {
    name: "Composite",
    className: "ChatByFormMessagePatternDto",
    modelProperties: {
      chatByFormMessagePatternId: {
        serializedName: "chatByFormMessagePatternId",
        type: {
          name: "Number"
        }
      },
      content: {
        serializedName: "content",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const DocumentTemplateSimpleDto: msRest.CompositeMapper = {
  serializedName: "DocumentTemplateSimpleDto",
  type: {
    name: "Composite",
    className: "DocumentTemplateSimpleDto",
    modelProperties: {
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      documentTemplateId: {
        serializedName: "documentTemplateId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      contextType: {
        serializedName: "contextType",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ChatByFormLocationReceivingDto: msRest.CompositeMapper = {
  serializedName: "ChatByFormLocationReceivingDto",
  type: {
    name: "Composite",
    className: "ChatByFormLocationReceivingDto",
    modelProperties: {
      isOverridable: {
        serializedName: "isOverridable",
        type: {
          name: "Boolean"
        }
      },
      locations: {
        serializedName: "locations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationSimpleDto"
            }
          }
        }
      },
      locationGroups: {
        serializedName: "locationGroups",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationGroupSimpleDto"
            }
          }
        }
      }
    }
  }
};

export const ChatByFormFunctionReceivingDto: msRest.CompositeMapper = {
  serializedName: "ChatByFormFunctionReceivingDto",
  type: {
    name: "Composite",
    className: "ChatByFormFunctionReceivingDto",
    modelProperties: {
      functionProperty: {
        serializedName: "functionProperty",
        type: {
          name: "Composite",
          className: "FunctionSimpleDto"
        }
      }
    }
  }
};

export const ChatByFormReceivingDto: msRest.CompositeMapper = {
  serializedName: "ChatByFormReceivingDto",
  type: {
    name: "Composite",
    className: "ChatByFormReceivingDto",
    modelProperties: {
      kind: {
        serializedName: "kind",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2"
          ]
        }
      },
      locationReceivings: {
        serializedName: "locationReceivings",
        type: {
          name: "Composite",
          className: "ChatByFormLocationReceivingDto"
        }
      },
      functionReceiving: {
        serializedName: "functionReceiving",
        type: {
          name: "Composite",
          className: "ChatByFormFunctionReceivingDto"
        }
      }
    }
  }
};

export const ChatByFormDefinitionDto: msRest.CompositeMapper = {
  serializedName: "ChatByFormDefinitionDto",
  type: {
    name: "Composite",
    className: "ChatByFormDefinitionDto",
    modelProperties: {
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      chatByFormDefinitionId: {
        serializedName: "chatByFormDefinitionId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      form: {
        serializedName: "form",
        type: {
          name: "Composite",
          className: "FormSimpleDto"
        }
      },
      requiresAuthentication: {
        serializedName: "requiresAuthentication",
        type: {
          name: "Boolean"
        }
      },
      pattern: {
        serializedName: "pattern",
        type: {
          name: "Composite",
          className: "ChatByFormMessagePatternDto"
        }
      },
      documentTemplate: {
        serializedName: "documentTemplate",
        type: {
          name: "Composite",
          className: "DocumentTemplateSimpleDto"
        }
      },
      receiving: {
        serializedName: "receiving",
        type: {
          name: "Composite",
          className: "ChatByFormReceivingDto"
        }
      },
      locations: {
        serializedName: "locations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationSimpleDto"
            }
          }
        }
      },
      locationGroups: {
        serializedName: "locationGroups",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationGroupSimpleDto"
            }
          }
        }
      }
    }
  }
};

export const ChatByFormDefinitionListDto: msRest.CompositeMapper = {
  serializedName: "ChatByFormDefinitionListDto",
  type: {
    name: "Composite",
    className: "ChatByFormDefinitionListDto",
    modelProperties: {
      chatByFormDefinitionId: {
        serializedName: "chatByFormDefinitionId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ChatByFormReceiverDto: msRest.CompositeMapper = {
  serializedName: "ChatByFormReceiverDto",
  type: {
    name: "Composite",
    className: "ChatByFormReceiverDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2"
          ]
        }
      }
    }
  }
};

export const ChatByMailMessageDto: msRest.CompositeMapper = {
  serializedName: "ChatByMailMessageDto",
  type: {
    name: "Composite",
    className: "ChatByMailMessageDto",
    modelProperties: {
      html: {
        serializedName: "html",
        type: {
          name: "String"
        }
      },
      plainText: {
        serializedName: "plainText",
        type: {
          name: "String"
        }
      },
      attachments: {
        serializedName: "attachments",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FileDto"
            }
          }
        }
      }
    }
  }
};

export const ChatByMailOriginDto: msRest.CompositeMapper = {
  serializedName: "ChatByMailOriginDto",
  type: {
    name: "Composite",
    className: "ChatByMailOriginDto",
    modelProperties: {
      fileId: {
        serializedName: "fileId",
        type: {
          name: "Number"
        }
      },
      filePath: {
        serializedName: "filePath",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ChatByMailIdentificationDto: msRest.CompositeMapper = {
  serializedName: "ChatByMailIdentificationDto",
  type: {
    name: "Composite",
    className: "ChatByMailIdentificationDto",
    modelProperties: {
      thread: {
        serializedName: "thread",
        type: {
          name: "String"
        }
      },
      domain: {
        serializedName: "domain",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ChatByMailDto: msRest.CompositeMapper = {
  serializedName: "ChatByMailDto",
  type: {
    name: "Composite",
    className: "ChatByMailDto",
    modelProperties: {
      subject: {
        serializedName: "subject",
        type: {
          name: "String"
        }
      },
      sender: {
        serializedName: "sender",
        type: {
          name: "String"
        }
      },
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Number"
        }
      },
      message: {
        serializedName: "message",
        type: {
          name: "Composite",
          className: "ChatByMailMessageDto"
        }
      },
      origin: {
        serializedName: "origin",
        type: {
          name: "Composite",
          className: "ChatByMailOriginDto"
        }
      },
      identification: {
        serializedName: "identification",
        type: {
          name: "Composite",
          className: "ChatByMailIdentificationDto"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ChatMemberDto: msRest.CompositeMapper = {
  serializedName: "ChatMemberDto",
  type: {
    name: "Composite",
    className: "ChatMemberDto",
    modelProperties: {
      chatMemberId: {
        serializedName: "chatMemberId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5"
          ]
        }
      },
      concreteMemberId: {
        readOnly: true,
        serializedName: "concreteMemberId",
        type: {
          name: "Number"
        }
      },
      concreteMemberName: {
        readOnly: true,
        serializedName: "concreteMemberName",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ChatDto: msRest.CompositeMapper = {
  serializedName: "ChatDto",
  type: {
    name: "Composite",
    className: "ChatDto",
    modelProperties: {
      chatId: {
        serializedName: "chatId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      },
      chatMembers: {
        serializedName: "chatMembers",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ChatMemberDto"
            }
          }
        }
      }
    }
  }
};

export const ChatMemberListDto: msRest.CompositeMapper = {
  serializedName: "ChatMemberListDto",
  type: {
    name: "Composite",
    className: "ChatMemberListDto",
    modelProperties: {
      chatMemberId: {
        serializedName: "chatMemberId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5"
          ]
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      concreteId: {
        serializedName: "concreteId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const LocationChatMemberDto: msRest.CompositeMapper = {
  serializedName: "LocationChatMemberDto",
  type: {
    name: "Composite",
    className: "LocationChatMemberDto",
    modelProperties: {
      chatMemberId: {
        serializedName: "chatMemberId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5"
          ]
        }
      },
      concreteMemberId: {
        readOnly: true,
        serializedName: "concreteMemberId",
        type: {
          name: "Number"
        }
      },
      concreteMemberName: {
        readOnly: true,
        serializedName: "concreteMemberName",
        type: {
          name: "String"
        }
      },
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Number"
        }
      },
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "LocationSimpleDto"
        }
      }
    }
  }
};

export const ChatSourceCompleteListDto: msRest.CompositeMapper = {
  serializedName: "ChatSourceCompleteListDto",
  type: {
    name: "Composite",
    className: "ChatSourceCompleteListDto",
    modelProperties: {
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      definitionId: {
        nullable: true,
        serializedName: "definitionId",
        type: {
          name: "Number"
        }
      },
      fileId: {
        nullable: true,
        serializedName: "fileId",
        type: {
          name: "Number"
        }
      },
      processId: {
        nullable: true,
        serializedName: "processId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ChatListDto: msRest.CompositeMapper = {
  serializedName: "ChatListDto",
  type: {
    name: "Composite",
    className: "ChatListDto",
    modelProperties: {
      chatId: {
        serializedName: "chatId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      unreadCount: {
        serializedName: "unreadCount",
        type: {
          name: "Number"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      },
      lastMessageDateTime: {
        serializedName: "lastMessageDateTime",
        type: {
          name: "String"
        }
      },
      lastMessagePreview: {
        serializedName: "lastMessagePreview",
        type: {
          name: "String"
        }
      },
      chatMembers: {
        serializedName: "chatMembers",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ChatMemberListDto"
            }
          }
        }
      },
      locationChatMembers: {
        serializedName: "locationChatMembers",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationChatMemberDto"
            }
          }
        }
      },
      chatSource: {
        serializedName: "chatSource",
        type: {
          name: "Composite",
          className: "ChatSourceCompleteListDto"
        }
      }
    }
  }
};

export const ChatMemberMessageListDto: msRest.CompositeMapper = {
  serializedName: "ChatMemberMessageListDto",
  type: {
    name: "Composite",
    className: "ChatMemberMessageListDto",
    modelProperties: {
      time: {
        serializedName: "time",
        type: {
          name: "String"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1"
          ]
        }
      },
      member: {
        serializedName: "member",
        type: {
          name: "Composite",
          className: "ChatMemberListDto"
        }
      }
    }
  }
};

export const ChatMessageDto: msRest.CompositeMapper = {
  serializedName: "ChatMessageDto",
  type: {
    name: "Composite",
    className: "ChatMessageDto",
    modelProperties: {
      chatMessageId: {
        serializedName: "chatMessageId",
        type: {
          name: "Number"
        }
      },
      message: {
        serializedName: "message",
        type: {
          name: "String"
        }
      },
      sender: {
        serializedName: "sender",
        type: {
          name: "Composite",
          className: "ChatMemberDto"
        }
      },
      attachment: {
        serializedName: "attachment",
        type: {
          name: "Composite",
          className: "OrgaAttachmentDto"
        }
      },
      attachments: {
        serializedName: "attachments",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "OrgaAttachmentDto"
            }
          }
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ChatMessageInNoteListDto: msRest.CompositeMapper = {
  serializedName: "ChatMessageInNoteListDto",
  type: {
    name: "Composite",
    className: "ChatMessageInNoteListDto",
    modelProperties: {
      message: {
        serializedName: "message",
        type: {
          name: "String"
        }
      },
      time: {
        readOnly: true,
        serializedName: "time",
        type: {
          name: "String"
        }
      },
      sender: {
        serializedName: "sender",
        type: {
          name: "Composite",
          className: "ChatMemberListDto"
        }
      },
      members: {
        serializedName: "members",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ChatMemberMessageListDto"
            }
          }
        }
      }
    }
  }
};

export const OrgaAttachmentListDto: msRest.CompositeMapper = {
  serializedName: "OrgaAttachmentListDto",
  type: {
    name: "Composite",
    className: "OrgaAttachmentListDto",
    modelProperties: {
      attachmentId: {
        serializedName: "attachmentId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5"
          ]
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      referenceId: {
        serializedName: "referenceId",
        type: {
          name: "Number"
        }
      },
      url: {
        serializedName: "url",
        type: {
          name: "String"
        }
      },
      link: {
        serializedName: "link",
        type: {
          name: "String"
        }
      },
      path: {
        serializedName: "path",
        type: {
          name: "String"
        }
      },
      infoId: {
        nullable: true,
        serializedName: "infoId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const StateSimpleListDto: msRest.CompositeMapper = {
  serializedName: "StateSimpleListDto",
  type: {
    name: "Composite",
    className: "StateSimpleListDto",
    modelProperties: {
      stateId: {
        serializedName: "stateId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      color: {
        serializedName: "color",
        type: {
          name: "String"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ChatMessageListDto: msRest.CompositeMapper = {
  serializedName: "ChatMessageListDto",
  type: {
    name: "Composite",
    className: "ChatMessageListDto",
    modelProperties: {
      chatMessageId: {
        serializedName: "chatMessageId",
        type: {
          name: "Number"
        }
      },
      message: {
        serializedName: "message",
        type: {
          name: "String"
        }
      },
      chatId: {
        serializedName: "chatId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1"
          ]
        }
      },
      time: {
        readOnly: true,
        serializedName: "time",
        type: {
          name: "String"
        }
      },
      sender: {
        serializedName: "sender",
        type: {
          name: "Composite",
          className: "ChatMemberListDto"
        }
      },
      members: {
        serializedName: "members",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ChatMemberMessageListDto"
            }
          }
        }
      },
      attachment: {
        serializedName: "attachment",
        type: {
          name: "Composite",
          className: "OrgaAttachmentListDto"
        }
      },
      attachments: {
        serializedName: "attachments",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "OrgaAttachmentListDto"
            }
          }
        }
      },
      triggerState: {
        serializedName: "triggerState",
        type: {
          name: "Composite",
          className: "StateSimpleListDto"
        }
      }
    }
  }
};

export const ChatMessageNotificationDto: msRest.CompositeMapper = {
  serializedName: "ChatMessageNotificationDto",
  type: {
    name: "Composite",
    className: "ChatMessageNotificationDto",
    modelProperties: {
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      message: {
        serializedName: "message",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "5"
          ]
        }
      },
      chatId: {
        serializedName: "chatId",
        type: {
          name: "Number"
        }
      },
      chatMessageId: {
        serializedName: "chatMessageId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ChatSkeletonDto: msRest.CompositeMapper = {
  serializedName: "ChatSkeletonDto",
  type: {
    name: "Composite",
    className: "ChatSkeletonDto",
    modelProperties: {
      chatSkeletonId: {
        serializedName: "chatSkeletonId",
        type: {
          name: "Number"
        }
      },
      isCreating: {
        serializedName: "isCreating",
        type: {
          name: "Boolean"
        }
      },
      attachmentTemplate: {
        serializedName: "attachmentTemplate",
        type: {
          name: "Composite",
          className: "DocumentTemplateSimpleDto"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ChatSkeletonV1Dto: msRest.CompositeMapper = {
  serializedName: "ChatSkeletonV1Dto",
  type: {
    name: "Composite",
    className: "ChatSkeletonV1Dto",
    modelProperties: {
      chatSkeletonId: {
        serializedName: "chatSkeletonId",
        type: {
          name: "Number"
        }
      },
      attachmentTemplate: {
        serializedName: "attachmentTemplate",
        type: {
          name: "Composite",
          className: "DocumentTemplateSimpleDto"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ChatStarterByDefinitionDto: msRest.CompositeMapper = {
  serializedName: "ChatStarterByDefinitionDto",
  type: {
    name: "Composite",
    className: "ChatStarterByDefinitionDto",
    modelProperties: {
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3"
          ]
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      definitionId: {
        serializedName: "definitionId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ChatStarterByFormDto: msRest.CompositeMapper = {
  serializedName: "ChatStarterByFormDto",
  type: {
    name: "Composite",
    className: "ChatStarterByFormDto",
    modelProperties: {
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3"
          ]
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      formId: {
        serializedName: "formId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ChatStarterByProcessDto: msRest.CompositeMapper = {
  serializedName: "ChatStarterByProcessDto",
  type: {
    name: "Composite",
    className: "ChatStarterByProcessDto",
    modelProperties: {
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3"
          ]
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      processId: {
        serializedName: "processId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ChatStarterDto: msRest.CompositeMapper = {
  serializedName: "ChatStarterDto",
  type: {
    name: "Composite",
    className: "ChatStarterDto",
    modelProperties: {
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3"
          ]
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ChatStartersDto: msRest.CompositeMapper = {
  serializedName: "ChatStartersDto",
  type: {
    name: "Composite",
    className: "ChatStartersDto",
    modelProperties: {
      starters: {
        serializedName: "starters",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ChatStarterDto"
            }
          }
        }
      }
    }
  }
};

export const CheckTenantActivityQueueDto: msRest.CompositeMapper = {
  serializedName: "CheckTenantActivityQueueDto",
  type: {
    name: "Composite",
    className: "CheckTenantActivityQueueDto",
    modelProperties: {
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const CheckboxFormDefaultDto: msRest.CompositeMapper = {
  serializedName: "CheckboxFormDefaultDto",
  type: {
    name: "Composite",
    className: "CheckboxFormDefaultDto",
    modelProperties: {
      fieldValueId: {
        serializedName: "fieldValueId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12"
          ]
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const CheckboxFormFieldDto: msRest.CompositeMapper = {
  serializedName: "CheckboxFormFieldDto",
  type: {
    name: "Composite",
    className: "CheckboxFormFieldDto",
    modelProperties: {
      formFieldId: {
        serializedName: "formFieldId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      technicalName: {
        readOnly: true,
        serializedName: "technicalName",
        type: {
          name: "String"
        }
      },
      width: {
        serializedName: "width",
        type: {
          name: "Number"
        }
      },
      isRequired: {
        serializedName: "isRequired",
        type: {
          name: "Boolean"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12"
          ]
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      defaultValues: {
        serializedName: "defaultValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FormDefaultDto"
            }
          }
        }
      },
      localFieldId: {
        serializedName: "localFieldId",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CheckboxFormValueDto: msRest.CompositeMapper = {
  serializedName: "CheckboxFormValueDto",
  type: {
    name: "Composite",
    className: "CheckboxFormValueDto",
    modelProperties: {
      fieldValueId: {
        serializedName: "fieldValueId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12"
          ]
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const ChildTaskSimpleDto: msRest.CompositeMapper = {
  serializedName: "ChildTaskSimpleDto",
  type: {
    name: "Composite",
    className: "ChildTaskSimpleDto",
    modelProperties: {
      taskId: {
        serializedName: "taskId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4"
          ]
        }
      }
    }
  }
};

export const ClosedLocationQueueDto: msRest.CompositeMapper = {
  serializedName: "ClosedLocationQueueDto",
  type: {
    name: "Composite",
    className: "ClosedLocationQueueDto",
    modelProperties: {
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ClosedLocationReasonQueueDto: msRest.CompositeMapper = {
  serializedName: "ClosedLocationReasonQueueDto",
  type: {
    name: "Composite",
    className: "ClosedLocationReasonQueueDto",
    modelProperties: {
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ClosingLocationOptionsDto: msRest.CompositeMapper = {
  serializedName: "ClosingLocationOptionsDto",
  type: {
    name: "Composite",
    className: "ClosingLocationOptionsDto",
    modelProperties: {
      shouldCancelOpenMissions: {
        serializedName: "shouldCancelOpenMissions",
        type: {
          name: "Boolean"
        }
      },
      shouldMoveResources: {
        serializedName: "shouldMoveResources",
        type: {
          name: "Boolean"
        }
      },
      shouldMoveResourcesTarget: {
        serializedName: "shouldMoveResourcesTarget",
        type: {
          name: "Composite",
          className: "LocationSimpleDto"
        }
      }
    }
  }
};

export const ExecutionPlanV1Dto: msRest.CompositeMapper = {
  serializedName: "ExecutionPlanV1Dto",
  type: {
    name: "Composite",
    className: "ExecutionPlanV1Dto",
    modelProperties: {
      executionId: {
        serializedName: "executionId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "4"
          ]
        }
      }
    }
  }
};

export const LocationTaskV1Dto: msRest.CompositeMapper = {
  serializedName: "LocationTaskV1Dto",
  type: {
    name: "Composite",
    className: "LocationTaskV1Dto",
    modelProperties: {
      taskId: {
        serializedName: "taskId",
        type: {
          name: "Number"
        }
      },
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const LocationGroupTaskV1Dto: msRest.CompositeMapper = {
  serializedName: "LocationGroupTaskV1Dto",
  type: {
    name: "Composite",
    className: "LocationGroupTaskV1Dto",
    modelProperties: {
      taskId: {
        serializedName: "taskId",
        type: {
          name: "Number"
        }
      },
      locationGroupId: {
        serializedName: "locationGroupId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ImpactCreateV1Dto: msRest.CompositeMapper = {
  serializedName: "ImpactCreateV1Dto",
  type: {
    name: "Composite",
    className: "ImpactCreateV1Dto",
    modelProperties: {
      impactId: {
        serializedName: "impactId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      condition: {
        serializedName: "condition",
        type: {
          name: "Composite",
          className: "ConditionV1Dto"
        }
      },
      taskId: {
        nullable: true,
        serializedName: "taskId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const TaskCreateV1Dto: msRest.CompositeMapper = {
  serializedName: "TaskCreateV1Dto",
  type: {
    name: "Composite",
    className: "TaskCreateV1Dto",
    modelProperties: {
      taskId: {
        serializedName: "taskId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      requiresAuthentication: {
        serializedName: "requiresAuthentication",
        type: {
          name: "Boolean"
        }
      },
      generatePdf: {
        serializedName: "generatePdf",
        type: {
          name: "Boolean"
        }
      },
      generateCsv: {
        serializedName: "generateCsv",
        type: {
          name: "Boolean"
        }
      },
      isEnabled: {
        serializedName: "isEnabled",
        type: {
          name: "Boolean"
        }
      },
      reasonSetId: {
        nullable: true,
        serializedName: "reasonSetId",
        type: {
          name: "Number"
        }
      },
      executionPlan: {
        serializedName: "executionPlan",
        type: {
          name: "Composite",
          className: "ExecutionPlanV1Dto"
        }
      },
      locations: {
        serializedName: "locations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationTaskV1Dto"
            }
          }
        }
      },
      locationGroups: {
        serializedName: "locationGroups",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationGroupTaskV1Dto"
            }
          }
        }
      },
      impacts: {
        serializedName: "impacts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ImpactCreateV1Dto"
            }
          }
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const CollectionTaskCreateV1Dto: msRest.CompositeMapper = {
  serializedName: "CollectionTaskCreateV1Dto",
  type: {
    name: "Composite",
    className: "CollectionTaskCreateV1Dto",
    modelProperties: {
      taskId: {
        serializedName: "taskId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      requiresAuthentication: {
        serializedName: "requiresAuthentication",
        type: {
          name: "Boolean"
        }
      },
      generatePdf: {
        serializedName: "generatePdf",
        type: {
          name: "Boolean"
        }
      },
      generateCsv: {
        serializedName: "generateCsv",
        type: {
          name: "Boolean"
        }
      },
      isEnabled: {
        serializedName: "isEnabled",
        type: {
          name: "Boolean"
        }
      },
      reasonSetId: {
        nullable: true,
        serializedName: "reasonSetId",
        type: {
          name: "Number"
        }
      },
      executionPlan: {
        serializedName: "executionPlan",
        type: {
          name: "Composite",
          className: "ExecutionPlanV1Dto"
        }
      },
      locations: {
        serializedName: "locations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationTaskV1Dto"
            }
          }
        }
      },
      locationGroups: {
        serializedName: "locationGroups",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationGroupTaskV1Dto"
            }
          }
        }
      },
      impacts: {
        serializedName: "impacts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ImpactCreateV1Dto"
            }
          }
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      children: {
        serializedName: "children",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TaskCreateV1Dto"
            }
          }
        }
      },
      hasStrictOrder: {
        serializedName: "hasStrictOrder",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const ImpactDto: msRest.CompositeMapper = {
  serializedName: "ImpactDto",
  type: {
    name: "Composite",
    className: "ImpactDto",
    modelProperties: {
      impactId: {
        serializedName: "impactId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      condition: {
        serializedName: "condition",
        type: {
          name: "Composite",
          className: "ConditionDto"
        }
      }
    }
  }
};

export const TaskJobStateReasonSetSimpleDto: msRest.CompositeMapper = {
  serializedName: "TaskJobStateReasonSetSimpleDto",
  type: {
    name: "Composite",
    className: "TaskJobStateReasonSetSimpleDto",
    modelProperties: {
      taskJobStateReasonSetId: {
        serializedName: "taskJobStateReasonSetId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ExecutionPlanDto: msRest.CompositeMapper = {
  serializedName: "ExecutionPlanDto",
  type: {
    name: "Composite",
    className: "ExecutionPlanDto",
    modelProperties: {
      executionId: {
        serializedName: "executionId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "4"
          ]
        }
      }
    }
  }
};

export const CollectionTaskDto: msRest.CompositeMapper = {
  serializedName: "CollectionTaskDto",
  type: {
    name: "Composite",
    className: "CollectionTaskDto",
    modelProperties: {
      taskId: {
        serializedName: "taskId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4"
          ]
        }
      },
      impacts: {
        serializedName: "impacts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ImpactDto"
            }
          }
        }
      },
      documentTemplate: {
        serializedName: "documentTemplate",
        type: {
          name: "Composite",
          className: "DocumentTemplateSimpleDto"
        }
      },
      generatePdf: {
        serializedName: "generatePdf",
        type: {
          name: "Boolean"
        }
      },
      generateCsv: {
        serializedName: "generateCsv",
        type: {
          name: "Boolean"
        }
      },
      isActive: {
        serializedName: "isActive",
        type: {
          name: "Boolean"
        }
      },
      reasonSet: {
        serializedName: "reasonSet",
        type: {
          name: "Composite",
          className: "TaskJobStateReasonSetSimpleDto"
        }
      },
      executionPlan: {
        serializedName: "executionPlan",
        type: {
          name: "Composite",
          className: "ExecutionPlanDto"
        }
      },
      locations: {
        serializedName: "locations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationSimpleDto"
            }
          }
        }
      },
      locationGroups: {
        serializedName: "locationGroups",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationGroupSimpleDto"
            }
          }
        }
      },
      categories: {
        serializedName: "categories",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CategorySimpleDto"
            }
          }
        }
      },
      children: {
        serializedName: "children",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ChildTaskSimpleDto"
            }
          }
        }
      },
      hasStrictOrder: {
        serializedName: "hasStrictOrder",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const TaskSimpleV1Dto: msRest.CompositeMapper = {
  serializedName: "TaskSimpleV1Dto",
  type: {
    name: "Composite",
    className: "TaskSimpleV1Dto",
    modelProperties: {
      taskId: {
        serializedName: "taskId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      requiresAuthentication: {
        serializedName: "requiresAuthentication",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const CollectionTaskSimpleV1Dto: msRest.CompositeMapper = {
  serializedName: "CollectionTaskSimpleV1Dto",
  type: {
    name: "Composite",
    className: "CollectionTaskSimpleV1Dto",
    modelProperties: {
      taskId: {
        serializedName: "taskId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      requiresAuthentication: {
        serializedName: "requiresAuthentication",
        type: {
          name: "Boolean"
        }
      },
      children: {
        serializedName: "children",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TaskSimpleV1Dto"
            }
          }
        }
      }
    }
  }
};

export const ExecutionPlanViewV1Dto: msRest.CompositeMapper = {
  serializedName: "ExecutionPlanViewV1Dto",
  type: {
    name: "Composite",
    className: "ExecutionPlanViewV1Dto",
    modelProperties: {
      executionId: {
        serializedName: "executionId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "4"
          ]
        }
      }
    }
  }
};

export const ImpactV1Dto: msRest.CompositeMapper = {
  serializedName: "ImpactV1Dto",
  type: {
    name: "Composite",
    className: "ImpactV1Dto",
    modelProperties: {
      impactId: {
        serializedName: "impactId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      condition: {
        serializedName: "condition",
        type: {
          name: "Composite",
          className: "ConditionV1Dto"
        }
      }
    }
  }
};

export const TaskV1Dto: msRest.CompositeMapper = {
  serializedName: "TaskV1Dto",
  type: {
    name: "Composite",
    className: "TaskV1Dto",
    modelProperties: {
      parentId: {
        nullable: true,
        serializedName: "parentId",
        type: {
          name: "Number"
        }
      },
      taskId: {
        serializedName: "taskId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      requiresAuthentication: {
        serializedName: "requiresAuthentication",
        type: {
          name: "Boolean"
        }
      },
      reasonSet: {
        serializedName: "reasonSet",
        type: {
          name: "Composite",
          className: "TaskJobStateReasonSetSimpleDto"
        }
      },
      generatePdf: {
        serializedName: "generatePdf",
        type: {
          name: "Boolean"
        }
      },
      generateCsv: {
        serializedName: "generateCsv",
        type: {
          name: "Boolean"
        }
      },
      executionPlan: {
        serializedName: "executionPlan",
        type: {
          name: "Composite",
          className: "ExecutionPlanViewV1Dto"
        }
      },
      locations: {
        serializedName: "locations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationRelationDto"
            }
          }
        }
      },
      locationGroups: {
        serializedName: "locationGroups",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationGroupRelationDto"
            }
          }
        }
      },
      isEnabled: {
        readOnly: true,
        serializedName: "isEnabled",
        type: {
          name: "Boolean"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      impacts: {
        serializedName: "impacts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ImpactV1Dto"
            }
          }
        }
      }
    }
  }
};

export const CollectionTaskV1Dto: msRest.CompositeMapper = {
  serializedName: "CollectionTaskV1Dto",
  type: {
    name: "Composite",
    className: "CollectionTaskV1Dto",
    modelProperties: {
      parentId: {
        nullable: true,
        serializedName: "parentId",
        type: {
          name: "Number"
        }
      },
      taskId: {
        serializedName: "taskId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      requiresAuthentication: {
        serializedName: "requiresAuthentication",
        type: {
          name: "Boolean"
        }
      },
      reasonSet: {
        serializedName: "reasonSet",
        type: {
          name: "Composite",
          className: "TaskJobStateReasonSetSimpleDto"
        }
      },
      generatePdf: {
        serializedName: "generatePdf",
        type: {
          name: "Boolean"
        }
      },
      generateCsv: {
        serializedName: "generateCsv",
        type: {
          name: "Boolean"
        }
      },
      executionPlan: {
        serializedName: "executionPlan",
        type: {
          name: "Composite",
          className: "ExecutionPlanViewV1Dto"
        }
      },
      locations: {
        serializedName: "locations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationRelationDto"
            }
          }
        }
      },
      locationGroups: {
        serializedName: "locationGroups",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationGroupRelationDto"
            }
          }
        }
      },
      isEnabled: {
        readOnly: true,
        serializedName: "isEnabled",
        type: {
          name: "Boolean"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      impacts: {
        serializedName: "impacts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ImpactV1Dto"
            }
          }
        }
      },
      children: {
        serializedName: "children",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TaskV1Dto"
            }
          }
        }
      },
      hasStrictOrder: {
        serializedName: "hasStrictOrder",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const CommissionContactQueueDto: msRest.CompositeMapper = {
  serializedName: "CommissionContactQueueDto",
  type: {
    name: "Composite",
    className: "CommissionContactQueueDto",
    modelProperties: {
      missionId: {
        serializedName: "missionId",
        type: {
          name: "Number"
        }
      },
      contactId: {
        serializedName: "contactId",
        type: {
          name: "Number"
        }
      },
      isUnsolved: {
        serializedName: "isUnsolved",
        type: {
          name: "Boolean"
        }
      },
      actualStart: {
        serializedName: "actualStart",
        type: {
          name: "String"
        }
      },
      actualEnd: {
        serializedName: "actualEnd",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CommissionEditorDto: msRest.CompositeMapper = {
  serializedName: "CommissionEditorDto",
  type: {
    name: "Composite",
    className: "CommissionEditorDto",
    modelProperties: {
      contactId: {
        serializedName: "contactId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const CommissionEditorInternalDto: msRest.CompositeMapper = {
  serializedName: "CommissionEditorInternalDto",
  type: {
    name: "Composite",
    className: "CommissionEditorInternalDto",
    modelProperties: {
      contactId: {
        nullable: true,
        serializedName: "contactId",
        type: {
          name: "Number"
        }
      },
      isUnsolved: {
        serializedName: "isUnsolved",
        type: {
          name: "Boolean"
        }
      },
      actualStart: {
        serializedName: "actualStart",
        type: {
          name: "String"
        }
      },
      actualEnd: {
        serializedName: "actualEnd",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CommonCustomColumnDto: msRest.CompositeMapper = {
  serializedName: "CommonCustomColumnDto",
  type: {
    name: "Composite",
    className: "CommonCustomColumnDto",
    modelProperties: {
      sortOrder: {
        serializedName: "sortOrder",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1"
          ]
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      customPropertyId: {
        nullable: true,
        serializedName: "customPropertyId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const CommonCustomFilterDto: msRest.CompositeMapper = {
  serializedName: "CommonCustomFilterDto",
  type: {
    name: "Composite",
    className: "CommonCustomFilterDto",
    modelProperties: {
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "-1"
          ]
        }
      },
      operator: {
        serializedName: "operator",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "-1"
          ]
        }
      },
      kind: {
        serializedName: "kind",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      customPropertyId: {
        nullable: true,
        serializedName: "customPropertyId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const CommonDestinationDto: msRest.CompositeMapper = {
  serializedName: "CommonDestinationDto",
  type: {
    name: "Composite",
    className: "CommonDestinationDto",
    modelProperties: {
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "1"
          ]
        }
      }
    }
  }
};

export const CommonEmailDestinationDto: msRest.CompositeMapper = {
  serializedName: "CommonEmailDestinationDto",
  type: {
    name: "Composite",
    className: "CommonEmailDestinationDto",
    modelProperties: {
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "1"
          ]
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      address: {
        serializedName: "address",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CommonGroupedFilterDto: msRest.CompositeMapper = {
  serializedName: "CommonGroupedFilterDto",
  type: {
    name: "Composite",
    className: "CommonGroupedFilterDto",
    modelProperties: {
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "-1"
          ]
        }
      },
      operator: {
        serializedName: "operator",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "-1"
          ]
        }
      },
      kind: {
        serializedName: "kind",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      combinator: {
        serializedName: "combinator",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2"
          ]
        }
      },
      children: {
        serializedName: "children",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CommonFilterDto"
            }
          }
        }
      }
    }
  }
};

export const CommonTransferColumnDto: msRest.CompositeMapper = {
  serializedName: "CommonTransferColumnDto",
  type: {
    name: "Composite",
    className: "CommonTransferColumnDto",
    modelProperties: {
      sortOrder: {
        serializedName: "sortOrder",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1"
          ]
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      property: {
        serializedName: "property",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CommonTransferFilterDto: msRest.CompositeMapper = {
  serializedName: "CommonTransferFilterDto",
  type: {
    name: "Composite",
    className: "CommonTransferFilterDto",
    modelProperties: {
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "-1"
          ]
        }
      },
      operator: {
        serializedName: "operator",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "-1"
          ]
        }
      },
      kind: {
        serializedName: "kind",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      property: {
        serializedName: "property",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CompanyDto: msRest.CompositeMapper = {
  serializedName: "CompanyDto",
  type: {
    name: "Composite",
    className: "CompanyDto",
    modelProperties: {
      companyId: {
        serializedName: "companyId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      parentCompanyId: {
        nullable: true,
        serializedName: "parentCompanyId",
        type: {
          name: "Number"
        }
      },
      parentCompany: {
        serializedName: "parentCompany",
        type: {
          name: "Composite",
          className: "CompanyDto"
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueDto"
            }
          }
        }
      },
      shippingAddress: {
        serializedName: "shippingAddress",
        type: {
          name: "Composite",
          className: "AddressSimpleDto"
        }
      },
      invoiceAddress: {
        serializedName: "invoiceAddress",
        type: {
          name: "Composite",
          className: "AddressSimpleDto"
        }
      }
    }
  }
};

export const CompanyAddressDto: msRest.CompositeMapper = {
  serializedName: "CompanyAddressDto",
  type: {
    name: "Composite",
    className: "CompanyAddressDto",
    modelProperties: {
      companyId: {
        serializedName: "companyId",
        type: {
          name: "Number"
        }
      },
      company: {
        serializedName: "company",
        type: {
          name: "Composite",
          className: "CompanyDto"
        }
      },
      addressId: {
        serializedName: "addressId",
        type: {
          name: "Number"
        }
      },
      address: {
        serializedName: "address",
        type: {
          name: "Composite",
          className: "AddressDto"
        }
      }
    }
  }
};

export const CompanyListSimpleDto: msRest.CompositeMapper = {
  serializedName: "CompanyListSimpleDto",
  type: {
    name: "Composite",
    className: "CompanyListSimpleDto",
    modelProperties: {
      companyId: {
        serializedName: "companyId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      parentCompanyId: {
        nullable: true,
        serializedName: "parentCompanyId",
        type: {
          name: "Number"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      }
    }
  }
};

export const CompanyFunctionDto: msRest.CompositeMapper = {
  serializedName: "CompanyFunctionDto",
  type: {
    name: "Composite",
    className: "CompanyFunctionDto",
    modelProperties: {
      companyId: {
        serializedName: "companyId",
        type: {
          name: "Number"
        }
      },
      company: {
        serializedName: "company",
        type: {
          name: "Composite",
          className: "CompanyListSimpleDto"
        }
      },
      functionId: {
        serializedName: "functionId",
        type: {
          name: "Number"
        }
      },
      functionProperty: {
        serializedName: "function",
        type: {
          name: "Composite",
          className: "FunctionListSimpleDto"
        }
      }
    }
  }
};

export const CompanyListDto: msRest.CompositeMapper = {
  serializedName: "CompanyListDto",
  type: {
    name: "Composite",
    className: "CompanyListDto",
    modelProperties: {
      companyId: {
        serializedName: "companyId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      parentCompanyId: {
        nullable: true,
        serializedName: "parentCompanyId",
        type: {
          name: "Number"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueListDto"
            }
          }
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleListDto"
        }
      }
    }
  }
};

export const CompanyListForAddressDto: msRest.CompositeMapper = {
  serializedName: "CompanyListForAddressDto",
  type: {
    name: "Composite",
    className: "CompanyListForAddressDto",
    modelProperties: {
      companyId: {
        serializedName: "companyId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      parentCompanyId: {
        nullable: true,
        serializedName: "parentCompanyId",
        type: {
          name: "Number"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueListDto"
            }
          }
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleListDto"
        }
      },
      isInRelation: {
        serializedName: "isInRelation",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const CompanyListForContactDto: msRest.CompositeMapper = {
  serializedName: "CompanyListForContactDto",
  type: {
    name: "Composite",
    className: "CompanyListForContactDto",
    modelProperties: {
      companyId: {
        serializedName: "companyId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      parentCompanyId: {
        nullable: true,
        serializedName: "parentCompanyId",
        type: {
          name: "Number"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueListDto"
            }
          }
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleListDto"
        }
      },
      isInRelation: {
        serializedName: "isInRelation",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const CompanyListForFunctionDto: msRest.CompositeMapper = {
  serializedName: "CompanyListForFunctionDto",
  type: {
    name: "Composite",
    className: "CompanyListForFunctionDto",
    modelProperties: {
      companyId: {
        serializedName: "companyId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      parentCompanyId: {
        nullable: true,
        serializedName: "parentCompanyId",
        type: {
          name: "Number"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueListDto"
            }
          }
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleListDto"
        }
      },
      isInRelation: {
        serializedName: "isInRelation",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const CompanySimpleDto: msRest.CompositeMapper = {
  serializedName: "CompanySimpleDto",
  type: {
    name: "Composite",
    className: "CompanySimpleDto",
    modelProperties: {
      companyId: {
        serializedName: "companyId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CompanyTtm: msRest.CompositeMapper = {
  serializedName: "CompanyTtm",
  type: {
    name: "Composite",
    className: "CompanyTtm",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      invoiceAddress: {
        serializedName: "invoiceAddress",
        type: {
          name: "Composite",
          className: "AddressTtm"
        }
      },
      shippingAddress: {
        serializedName: "shippingAddress",
        type: {
          name: "Composite",
          className: "AddressTtm"
        }
      },
      properties: {
        serializedName: "properties",
        type: {
          name: "Object"
        }
      }
    }
  }
};

export const ConfirmationTaskCreateV1Dto: msRest.CompositeMapper = {
  serializedName: "ConfirmationTaskCreateV1Dto",
  type: {
    name: "Composite",
    className: "ConfirmationTaskCreateV1Dto",
    modelProperties: {
      taskId: {
        serializedName: "taskId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      requiresAuthentication: {
        serializedName: "requiresAuthentication",
        type: {
          name: "Boolean"
        }
      },
      generatePdf: {
        serializedName: "generatePdf",
        type: {
          name: "Boolean"
        }
      },
      generateCsv: {
        serializedName: "generateCsv",
        type: {
          name: "Boolean"
        }
      },
      isEnabled: {
        serializedName: "isEnabled",
        type: {
          name: "Boolean"
        }
      },
      reasonSetId: {
        nullable: true,
        serializedName: "reasonSetId",
        type: {
          name: "Number"
        }
      },
      executionPlan: {
        serializedName: "executionPlan",
        type: {
          name: "Composite",
          className: "ExecutionPlanV1Dto"
        }
      },
      locations: {
        serializedName: "locations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationTaskV1Dto"
            }
          }
        }
      },
      locationGroups: {
        serializedName: "locationGroups",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationGroupTaskV1Dto"
            }
          }
        }
      },
      impacts: {
        serializedName: "impacts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ImpactCreateV1Dto"
            }
          }
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ConfirmationTaskDto: msRest.CompositeMapper = {
  serializedName: "ConfirmationTaskDto",
  type: {
    name: "Composite",
    className: "ConfirmationTaskDto",
    modelProperties: {
      taskId: {
        serializedName: "taskId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4"
          ]
        }
      },
      impacts: {
        serializedName: "impacts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ImpactDto"
            }
          }
        }
      },
      documentTemplate: {
        serializedName: "documentTemplate",
        type: {
          name: "Composite",
          className: "DocumentTemplateSimpleDto"
        }
      },
      generatePdf: {
        serializedName: "generatePdf",
        type: {
          name: "Boolean"
        }
      },
      generateCsv: {
        serializedName: "generateCsv",
        type: {
          name: "Boolean"
        }
      },
      isActive: {
        serializedName: "isActive",
        type: {
          name: "Boolean"
        }
      },
      reasonSet: {
        serializedName: "reasonSet",
        type: {
          name: "Composite",
          className: "TaskJobStateReasonSetSimpleDto"
        }
      },
      executionPlan: {
        serializedName: "executionPlan",
        type: {
          name: "Composite",
          className: "ExecutionPlanDto"
        }
      },
      locations: {
        serializedName: "locations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationSimpleDto"
            }
          }
        }
      },
      locationGroups: {
        serializedName: "locationGroups",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationGroupSimpleDto"
            }
          }
        }
      },
      categories: {
        serializedName: "categories",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CategorySimpleDto"
            }
          }
        }
      },
      requiresAuthentication: {
        serializedName: "requiresAuthentication",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const ConfirmationTaskListV1Dto: msRest.CompositeMapper = {
  serializedName: "ConfirmationTaskListV1Dto",
  type: {
    name: "Composite",
    className: "ConfirmationTaskListV1Dto",
    modelProperties: {
      taskId: {
        serializedName: "taskId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      isEnabled: {
        serializedName: "isEnabled",
        type: {
          name: "Boolean"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      parentId: {
        nullable: true,
        serializedName: "parentId",
        type: {
          name: "Number"
        }
      },
      executionPlan: {
        serializedName: "executionPlan",
        type: {
          name: "Composite",
          className: "ExecutionPlanViewV1Dto"
        }
      },
      executionPlanId: {
        nullable: true,
        serializedName: "executionPlanId",
        type: {
          name: "Number"
        }
      },
      requiresAuthentication: {
        serializedName: "requiresAuthentication",
        type: {
          name: "Boolean"
        }
      },
      reasonSetId: {
        nullable: true,
        serializedName: "reasonSetId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ConfirmationTaskSimpleV1Dto: msRest.CompositeMapper = {
  serializedName: "ConfirmationTaskSimpleV1Dto",
  type: {
    name: "Composite",
    className: "ConfirmationTaskSimpleV1Dto",
    modelProperties: {
      taskId: {
        serializedName: "taskId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      requiresAuthentication: {
        serializedName: "requiresAuthentication",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const ConfirmationTaskV1Dto: msRest.CompositeMapper = {
  serializedName: "ConfirmationTaskV1Dto",
  type: {
    name: "Composite",
    className: "ConfirmationTaskV1Dto",
    modelProperties: {
      parentId: {
        nullable: true,
        serializedName: "parentId",
        type: {
          name: "Number"
        }
      },
      taskId: {
        serializedName: "taskId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      requiresAuthentication: {
        serializedName: "requiresAuthentication",
        type: {
          name: "Boolean"
        }
      },
      reasonSet: {
        serializedName: "reasonSet",
        type: {
          name: "Composite",
          className: "TaskJobStateReasonSetSimpleDto"
        }
      },
      generatePdf: {
        serializedName: "generatePdf",
        type: {
          name: "Boolean"
        }
      },
      generateCsv: {
        serializedName: "generateCsv",
        type: {
          name: "Boolean"
        }
      },
      executionPlan: {
        serializedName: "executionPlan",
        type: {
          name: "Composite",
          className: "ExecutionPlanViewV1Dto"
        }
      },
      locations: {
        serializedName: "locations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationRelationDto"
            }
          }
        }
      },
      locationGroups: {
        serializedName: "locationGroups",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationGroupRelationDto"
            }
          }
        }
      },
      isEnabled: {
        readOnly: true,
        serializedName: "isEnabled",
        type: {
          name: "Boolean"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      impacts: {
        serializedName: "impacts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ImpactV1Dto"
            }
          }
        }
      }
    }
  }
};

export const ConnectedConsumptionDto: msRest.CompositeMapper = {
  serializedName: "ConnectedConsumptionDto",
  type: {
    name: "Composite",
    className: "ConnectedConsumptionDto",
    modelProperties: {
      eventId: {
        serializedName: "eventId",
        type: {
          name: "Number"
        }
      },
      occurredAt: {
        serializedName: "occurredAt",
        type: {
          name: "String"
        }
      },
      code: {
        serializedName: "code",
        type: {
          name: "String"
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ConsumptionCodeSimpleDto: msRest.CompositeMapper = {
  serializedName: "ConsumptionCodeSimpleDto",
  type: {
    name: "Composite",
    className: "ConsumptionCodeSimpleDto",
    modelProperties: {
      consumptionCodeId: {
        serializedName: "consumptionCodeId",
        type: {
          name: "Number"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ErrorCodeSimpleDto: msRest.CompositeMapper = {
  serializedName: "ErrorCodeSimpleDto",
  type: {
    name: "Composite",
    className: "ErrorCodeSimpleDto",
    modelProperties: {
      errorCodeId: {
        serializedName: "errorCodeId",
        type: {
          name: "Number"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const HintCodeSimpleDto: msRest.CompositeMapper = {
  serializedName: "HintCodeSimpleDto",
  type: {
    name: "Composite",
    className: "HintCodeSimpleDto",
    modelProperties: {
      hintCodeId: {
        serializedName: "hintCodeId",
        type: {
          name: "Number"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CustomPropertyInConnectedIdentificationDto: msRest.CompositeMapper = {
  serializedName: "CustomPropertyInConnectedIdentificationDto",
  type: {
    name: "Composite",
    className: "CustomPropertyInConnectedIdentificationDto",
    modelProperties: {
      customPropertyId: {
        serializedName: "customPropertyId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      }
    }
  }
};

export const CustomSetInConnectedIdentificationDto: msRest.CompositeMapper = {
  serializedName: "CustomSetInConnectedIdentificationDto",
  type: {
    name: "Composite",
    className: "CustomSetInConnectedIdentificationDto",
    modelProperties: {
      properties: {
        serializedName: "properties",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomPropertyInConnectedIdentificationDto"
            }
          }
        }
      }
    }
  }
};

export const CustomSetReferenceInConnectedIdentificationDto: msRest.CompositeMapper = {
  serializedName: "CustomSetReferenceInConnectedIdentificationDto",
  type: {
    name: "Composite",
    className: "CustomSetReferenceInConnectedIdentificationDto",
    modelProperties: {
      stereotypeId: {
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      customPropertySetId: {
        serializedName: "customPropertySetId",
        type: {
          name: "Number"
        }
      },
      customPropertySet: {
        serializedName: "customPropertySet",
        type: {
          name: "Composite",
          className: "CustomSetInConnectedIdentificationDto"
        }
      }
    }
  }
};

export const StereotypeInConnectedIdentificationDto: msRest.CompositeMapper = {
  serializedName: "StereotypeInConnectedIdentificationDto",
  type: {
    name: "Composite",
    className: "StereotypeInConnectedIdentificationDto",
    modelProperties: {
      stereotypeId: {
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      entityType: {
        serializedName: "entityType",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
            "31",
            "32",
            "33",
            "34",
            "35",
            "36",
            "37",
            "38",
            "39",
            "40",
            "41",
            "42",
            "43",
            "44",
            "45"
          ]
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      },
      customPropertySets: {
        serializedName: "customPropertySets",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomSetReferenceInConnectedIdentificationDto"
            }
          }
        }
      }
    }
  }
};

export const CustomPropertySimpleDto: msRest.CompositeMapper = {
  serializedName: "CustomPropertySimpleDto",
  type: {
    name: "Composite",
    className: "CustomPropertySimpleDto",
    modelProperties: {
      customPropertyId: {
        serializedName: "customPropertyId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ConnectedResourceIdentificationDto: msRest.CompositeMapper = {
  serializedName: "ConnectedResourceIdentificationDto",
  type: {
    name: "Composite",
    className: "ConnectedResourceIdentificationDto",
    modelProperties: {
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeInConnectedIdentificationDto"
        }
      },
      key: {
        serializedName: "key",
        type: {
          name: "Composite",
          className: "CustomPropertySimpleDto"
        }
      }
    }
  }
};

export const ConnectedDefinitionDto: msRest.CompositeMapper = {
  serializedName: "ConnectedDefinitionDto",
  type: {
    name: "Composite",
    className: "ConnectedDefinitionDto",
    modelProperties: {
      connectedDefinitionId: {
        serializedName: "connectedDefinitionId",
        type: {
          name: "Number"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      consumptionCodes: {
        serializedName: "consumptionCodes",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ConsumptionCodeSimpleDto"
            }
          }
        }
      },
      errorCodes: {
        serializedName: "errorCodes",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ErrorCodeSimpleDto"
            }
          }
        }
      },
      hintCodes: {
        serializedName: "hintCodes",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "HintCodeSimpleDto"
            }
          }
        }
      },
      identifications: {
        serializedName: "identifications",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ConnectedResourceIdentificationDto"
            }
          }
        }
      }
    }
  }
};

export const ConnectedDefinitionListDto: msRest.CompositeMapper = {
  serializedName: "ConnectedDefinitionListDto",
  type: {
    name: "Composite",
    className: "ConnectedDefinitionListDto",
    modelProperties: {
      connectedDefinitionId: {
        serializedName: "connectedDefinitionId",
        type: {
          name: "Number"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ConnectedDefinitionSimpleDto: msRest.CompositeMapper = {
  serializedName: "ConnectedDefinitionSimpleDto",
  type: {
    name: "Composite",
    className: "ConnectedDefinitionSimpleDto",
    modelProperties: {
      connectedDefinitionId: {
        serializedName: "connectedDefinitionId",
        type: {
          name: "Number"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ConnectedErrorDto: msRest.CompositeMapper = {
  serializedName: "ConnectedErrorDto",
  type: {
    name: "Composite",
    className: "ConnectedErrorDto",
    modelProperties: {
      eventId: {
        serializedName: "eventId",
        type: {
          name: "Number"
        }
      },
      occurredAt: {
        serializedName: "occurredAt",
        type: {
          name: "String"
        }
      },
      code: {
        serializedName: "code",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ConnectedIdentificationDto: msRest.CompositeMapper = {
  serializedName: "ConnectedIdentificationDto",
  type: {
    name: "Composite",
    className: "ConnectedIdentificationDto",
    modelProperties: {
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      code: {
        serializedName: "code",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2"
          ]
        }
      },
      scopeId: {
        readOnly: true,
        serializedName: "scopeId",
        type: {
          name: "String"
        }
      },
      resourceIdentifier: {
        readOnly: true,
        serializedName: "resourceIdentifier",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ConnectedHintDto: msRest.CompositeMapper = {
  serializedName: "ConnectedHintDto",
  type: {
    name: "Composite",
    className: "ConnectedHintDto",
    modelProperties: {
      eventId: {
        serializedName: "eventId",
        type: {
          name: "Number"
        }
      },
      occurredAt: {
        serializedName: "occurredAt",
        type: {
          name: "String"
        }
      },
      code: {
        serializedName: "code",
        type: {
          name: "String"
        }
      },
      level: {
        serializedName: "level",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      }
    }
  }
};

export const ConnectedEventsDto: msRest.CompositeMapper = {
  serializedName: "ConnectedEventsDto",
  type: {
    name: "Composite",
    className: "ConnectedEventsDto",
    modelProperties: {
      identification: {
        serializedName: "identification",
        type: {
          name: "Composite",
          className: "ConnectedIdentificationDto"
        }
      },
      errors: {
        serializedName: "errors",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ConnectedErrorDto"
            }
          }
        }
      },
      consumptions: {
        serializedName: "consumptions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ConnectedConsumptionDto"
            }
          }
        }
      },
      hints: {
        serializedName: "hints",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ConnectedHintDto"
            }
          }
        }
      }
    }
  }
};

export const ConnectedEventsResultDto: msRest.CompositeMapper = {
  serializedName: "ConnectedEventsResultDto",
  type: {
    name: "Composite",
    className: "ConnectedEventsResultDto",
    modelProperties: {
      definitionId: {
        serializedName: "definitionId",
        type: {
          name: "Number"
        }
      },
      definitionName: {
        serializedName: "definitionName",
        type: {
          name: "String"
        }
      },
      isUnidentified: {
        readOnly: true,
        serializedName: "isUnidentified",
        type: {
          name: "Boolean"
        }
      },
      resourceIdentifier: {
        serializedName: "resourceIdentifier",
        type: {
          name: "String"
        }
      },
      resourceId: {
        nullable: true,
        serializedName: "resourceId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ConnectedIdentificationByDirectIdDto: msRest.CompositeMapper = {
  serializedName: "ConnectedIdentificationByDirectIdDto",
  type: {
    name: "Composite",
    className: "ConnectedIdentificationByDirectIdDto",
    modelProperties: {
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      code: {
        serializedName: "code",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2"
          ]
        }
      },
      scopeId: {
        readOnly: true,
        serializedName: "scopeId",
        type: {
          name: "String"
        }
      },
      resourceId: {
        serializedName: "resourceId",
        type: {
          name: "Number"
        }
      },
      resourceIdentifier: {
        readOnly: true,
        serializedName: "resourceIdentifier",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ConnectedIdentificationBySerialNumberDto: msRest.CompositeMapper = {
  serializedName: "ConnectedIdentificationBySerialNumberDto",
  type: {
    name: "Composite",
    className: "ConnectedIdentificationBySerialNumberDto",
    modelProperties: {
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      code: {
        serializedName: "code",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2"
          ]
        }
      },
      scopeId: {
        readOnly: true,
        serializedName: "scopeId",
        type: {
          name: "String"
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      },
      resourceIdentifier: {
        readOnly: true,
        serializedName: "resourceIdentifier",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ConsumptionCodeDto: msRest.CompositeMapper = {
  serializedName: "ConsumptionCodeDto",
  type: {
    name: "Composite",
    className: "ConsumptionCodeDto",
    modelProperties: {
      consumptionCodeId: {
        serializedName: "consumptionCodeId",
        type: {
          name: "Number"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      unit: {
        serializedName: "unit",
        type: {
          name: "String"
        }
      },
      valueType: {
        serializedName: "valueType",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      isVisible: {
        serializedName: "isVisible",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const ConsumptionCodeListDto: msRest.CompositeMapper = {
  serializedName: "ConsumptionCodeListDto",
  type: {
    name: "Composite",
    className: "ConsumptionCodeListDto",
    modelProperties: {
      consumptionCodeId: {
        serializedName: "consumptionCodeId",
        type: {
          name: "Number"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      unit: {
        serializedName: "unit",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ConsumptionCodeSimpleListDto: msRest.CompositeMapper = {
  serializedName: "ConsumptionCodeSimpleListDto",
  type: {
    name: "Composite",
    className: "ConsumptionCodeSimpleListDto",
    modelProperties: {
      consumptionCodeId: {
        serializedName: "consumptionCodeId",
        type: {
          name: "Number"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      unit: {
        serializedName: "unit",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const LinkedElementDto: msRest.CompositeMapper = {
  serializedName: "LinkedElementDto",
  type: {
    name: "Composite",
    className: "LinkedElementDto",
    modelProperties: {
      linkedElementId: {
        serializedName: "linkedElementId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5"
          ]
        }
      }
    }
  }
};

export const LinkDto: msRest.CompositeMapper = {
  serializedName: "LinkDto",
  type: {
    name: "Composite",
    className: "LinkDto",
    modelProperties: {
      linkId: {
        serializedName: "linkId",
        type: {
          name: "Number"
        }
      },
      lastModified: {
        serializedName: "lastModified",
        type: {
          name: "String"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "String"
        }
      },
      comment: {
        serializedName: "comment",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      to: {
        serializedName: "to",
        type: {
          name: "Composite",
          className: "LinkedElementDto"
        }
      }
    }
  }
};

export const ContactDto: msRest.CompositeMapper = {
  serializedName: "ContactDto",
  type: {
    name: "Composite",
    className: "ContactDto",
    modelProperties: {
      contactId: {
        serializedName: "contactId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      firstName: {
        serializedName: "firstName",
        type: {
          name: "String"
        }
      },
      lastName: {
        serializedName: "lastName",
        type: {
          name: "String"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      lastLoggedIn: {
        serializedName: "lastLoggedIn",
        type: {
          name: "String"
        }
      },
      pin: {
        serializedName: "pin",
        type: {
          name: "String"
        }
      },
      notificationBehaviour: {
        serializedName: "notificationBehaviour",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "-1"
          ]
        }
      },
      badge: {
        serializedName: "badge",
        type: {
          name: "String"
        }
      },
      culture: {
        serializedName: "culture",
        type: {
          name: "String"
        }
      },
      timeZone: {
        serializedName: "timeZone",
        type: {
          name: "String"
        }
      },
      emailAddress: {
        serializedName: "emailAddress",
        type: {
          name: "String"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      phone: {
        serializedName: "phone",
        type: {
          name: "String"
        }
      },
      mobile: {
        serializedName: "mobile",
        type: {
          name: "String"
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      company: {
        serializedName: "company",
        type: {
          name: "Composite",
          className: "CompanySimpleDto"
        }
      },
      isLockedOut: {
        readOnly: true,
        serializedName: "isLockedOut",
        type: {
          name: "Boolean"
        }
      },
      access: {
        serializedName: "access",
        type: {
          name: "Composite",
          className: "AccessOfContactDto"
        }
      },
      areas: {
        serializedName: "areas",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AreaAssignmentInContactDto"
            }
          }
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueDto"
            }
          }
        }
      },
      links: {
        serializedName: "links",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LinkDto"
            }
          }
        }
      }
    }
  }
};

export const ContactChatMemberDto: msRest.CompositeMapper = {
  serializedName: "ContactChatMemberDto",
  type: {
    name: "Composite",
    className: "ContactChatMemberDto",
    modelProperties: {
      chatMemberId: {
        serializedName: "chatMemberId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5"
          ]
        }
      },
      concreteMemberId: {
        readOnly: true,
        serializedName: "concreteMemberId",
        type: {
          name: "Number"
        }
      },
      concreteMemberName: {
        readOnly: true,
        serializedName: "concreteMemberName",
        type: {
          name: "String"
        }
      },
      contactId: {
        serializedName: "contactId",
        type: {
          name: "Number"
        }
      },
      contact: {
        serializedName: "contact",
        type: {
          name: "Composite",
          className: "ContactDto"
        }
      }
    }
  }
};

export const ContactGroupSimpleDto: msRest.CompositeMapper = {
  serializedName: "ContactGroupSimpleDto",
  type: {
    name: "Composite",
    className: "ContactGroupSimpleDto",
    modelProperties: {
      contactGroupId: {
        serializedName: "contactGroupId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const UserSimpleListDto: msRest.CompositeMapper = {
  serializedName: "UserSimpleListDto",
  type: {
    name: "Composite",
    className: "UserSimpleListDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ContactInMissionListDto: msRest.CompositeMapper = {
  serializedName: "ContactInMissionListDto",
  type: {
    name: "Composite",
    className: "ContactInMissionListDto",
    modelProperties: {
      contactId: {
        serializedName: "contactId",
        type: {
          name: "Number"
        }
      },
      firstName: {
        serializedName: "firstName",
        type: {
          name: "String"
        }
      },
      lastName: {
        serializedName: "lastName",
        type: {
          name: "String"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      emailAddress: {
        serializedName: "emailAddress",
        type: {
          name: "String"
        }
      },
      user: {
        serializedName: "user",
        type: {
          name: "Composite",
          className: "UserSimpleListDto"
        }
      },
      phone: {
        serializedName: "phone",
        type: {
          name: "String"
        }
      },
      mobile: {
        serializedName: "mobile",
        type: {
          name: "String"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleListDto"
        }
      }
    }
  }
};

export const ContactInTicketListDto: msRest.CompositeMapper = {
  serializedName: "ContactInTicketListDto",
  type: {
    name: "Composite",
    className: "ContactInTicketListDto",
    modelProperties: {
      contactId: {
        serializedName: "contactId",
        type: {
          name: "Number"
        }
      },
      firstName: {
        serializedName: "firstName",
        type: {
          name: "String"
        }
      },
      lastName: {
        serializedName: "lastName",
        type: {
          name: "String"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      emailAddress: {
        serializedName: "emailAddress",
        type: {
          name: "String"
        }
      },
      user: {
        serializedName: "user",
        type: {
          name: "Composite",
          className: "UserSimpleListDto"
        }
      },
      phone: {
        serializedName: "phone",
        type: {
          name: "String"
        }
      },
      mobile: {
        serializedName: "mobile",
        type: {
          name: "String"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleListDto"
        }
      }
    }
  }
};

export const ContactIssueReceiverDto: msRest.CompositeMapper = {
  serializedName: "ContactIssueReceiverDto",
  type: {
    name: "Composite",
    className: "ContactIssueReceiverDto",
    modelProperties: {
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      contact: {
        serializedName: "contact",
        type: {
          name: "Composite",
          className: "ContactSimpleDto"
        }
      }
    }
  }
};

export const UserInContactListDto: msRest.CompositeMapper = {
  serializedName: "UserInContactListDto",
  type: {
    name: "Composite",
    className: "UserInContactListDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ContactListDto: msRest.CompositeMapper = {
  serializedName: "ContactListDto",
  type: {
    name: "Composite",
    className: "ContactListDto",
    modelProperties: {
      contactId: {
        serializedName: "contactId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      firstName: {
        serializedName: "firstName",
        type: {
          name: "String"
        }
      },
      lastName: {
        serializedName: "lastName",
        type: {
          name: "String"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      phone: {
        serializedName: "phone",
        type: {
          name: "String"
        }
      },
      mobile: {
        serializedName: "mobile",
        type: {
          name: "String"
        }
      },
      pin: {
        serializedName: "pin",
        type: {
          name: "String"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleListDto"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueListDto"
            }
          }
        }
      },
      loginExpiration: {
        serializedName: "loginExpiration",
        type: {
          name: "String"
        }
      },
      isFree: {
        serializedName: "isFree",
        type: {
          name: "Boolean"
        }
      },
      emailAddress: {
        serializedName: "emailAddress",
        type: {
          name: "String"
        }
      },
      user: {
        serializedName: "user",
        type: {
          name: "Composite",
          className: "UserInContactListDto"
        }
      },
      locations: {
        serializedName: "locations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationSimpleListDto"
            }
          }
        }
      },
      locationGroups: {
        serializedName: "locationGroups",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationGroupSimpleListDto"
            }
          }
        }
      },
      functions: {
        serializedName: "functions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FunctionListSimpleDto"
            }
          }
        }
      },
      roles: {
        serializedName: "roles",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RoleSimpleListDto"
            }
          }
        }
      }
    }
  }
};

export const ContactListForCompanyDto: msRest.CompositeMapper = {
  serializedName: "ContactListForCompanyDto",
  type: {
    name: "Composite",
    className: "ContactListForCompanyDto",
    modelProperties: {
      contactId: {
        serializedName: "contactId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      firstName: {
        serializedName: "firstName",
        type: {
          name: "String"
        }
      },
      lastName: {
        serializedName: "lastName",
        type: {
          name: "String"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      phone: {
        serializedName: "phone",
        type: {
          name: "String"
        }
      },
      mobile: {
        serializedName: "mobile",
        type: {
          name: "String"
        }
      },
      pin: {
        serializedName: "pin",
        type: {
          name: "String"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleListDto"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueListDto"
            }
          }
        }
      },
      loginExpiration: {
        serializedName: "loginExpiration",
        type: {
          name: "String"
        }
      },
      isFree: {
        serializedName: "isFree",
        type: {
          name: "Boolean"
        }
      },
      emailAddress: {
        serializedName: "emailAddress",
        type: {
          name: "String"
        }
      },
      user: {
        serializedName: "user",
        type: {
          name: "Composite",
          className: "UserInContactListDto"
        }
      },
      locations: {
        serializedName: "locations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationSimpleListDto"
            }
          }
        }
      },
      locationGroups: {
        serializedName: "locationGroups",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationGroupSimpleListDto"
            }
          }
        }
      },
      functions: {
        serializedName: "functions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FunctionListSimpleDto"
            }
          }
        }
      },
      roles: {
        serializedName: "roles",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RoleSimpleListDto"
            }
          }
        }
      },
      isInRelation: {
        serializedName: "isInRelation",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const ContactListForFunctionDto: msRest.CompositeMapper = {
  serializedName: "ContactListForFunctionDto",
  type: {
    name: "Composite",
    className: "ContactListForFunctionDto",
    modelProperties: {
      contactId: {
        serializedName: "contactId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      firstName: {
        serializedName: "firstName",
        type: {
          name: "String"
        }
      },
      lastName: {
        serializedName: "lastName",
        type: {
          name: "String"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      phone: {
        serializedName: "phone",
        type: {
          name: "String"
        }
      },
      mobile: {
        serializedName: "mobile",
        type: {
          name: "String"
        }
      },
      pin: {
        serializedName: "pin",
        type: {
          name: "String"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleListDto"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueListDto"
            }
          }
        }
      },
      loginExpiration: {
        serializedName: "loginExpiration",
        type: {
          name: "String"
        }
      },
      isFree: {
        serializedName: "isFree",
        type: {
          name: "Boolean"
        }
      },
      emailAddress: {
        serializedName: "emailAddress",
        type: {
          name: "String"
        }
      },
      user: {
        serializedName: "user",
        type: {
          name: "Composite",
          className: "UserInContactListDto"
        }
      },
      locations: {
        serializedName: "locations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationSimpleListDto"
            }
          }
        }
      },
      locationGroups: {
        serializedName: "locationGroups",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationGroupSimpleListDto"
            }
          }
        }
      },
      functions: {
        serializedName: "functions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FunctionListSimpleDto"
            }
          }
        }
      },
      roles: {
        serializedName: "roles",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RoleSimpleListDto"
            }
          }
        }
      },
      isInRelation: {
        serializedName: "isInRelation",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const ContactListForFunctionDtoPageOutputDto: msRest.CompositeMapper = {
  serializedName: "ContactListForFunctionDtoPageOutputDto",
  type: {
    name: "Composite",
    className: "ContactListForFunctionDtoPageOutputDto",
    modelProperties: {
      paging: {
        serializedName: "paging",
        type: {
          name: "Object"
        }
      },
      items: {
        serializedName: "items",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ContactListForFunctionDto"
            }
          }
        }
      }
    }
  }
};

export const ContactListForInfoDto: msRest.CompositeMapper = {
  serializedName: "ContactListForInfoDto",
  type: {
    name: "Composite",
    className: "ContactListForInfoDto",
    modelProperties: {
      contactId: {
        serializedName: "contactId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      firstName: {
        serializedName: "firstName",
        type: {
          name: "String"
        }
      },
      lastName: {
        serializedName: "lastName",
        type: {
          name: "String"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      phone: {
        serializedName: "phone",
        type: {
          name: "String"
        }
      },
      mobile: {
        serializedName: "mobile",
        type: {
          name: "String"
        }
      },
      pin: {
        serializedName: "pin",
        type: {
          name: "String"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleListDto"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueListDto"
            }
          }
        }
      },
      loginExpiration: {
        serializedName: "loginExpiration",
        type: {
          name: "String"
        }
      },
      isFree: {
        serializedName: "isFree",
        type: {
          name: "Boolean"
        }
      },
      emailAddress: {
        serializedName: "emailAddress",
        type: {
          name: "String"
        }
      },
      user: {
        serializedName: "user",
        type: {
          name: "Composite",
          className: "UserInContactListDto"
        }
      },
      locations: {
        serializedName: "locations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationSimpleListDto"
            }
          }
        }
      },
      locationGroups: {
        serializedName: "locationGroups",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationGroupSimpleListDto"
            }
          }
        }
      },
      functions: {
        serializedName: "functions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FunctionListSimpleDto"
            }
          }
        }
      },
      roles: {
        serializedName: "roles",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RoleSimpleListDto"
            }
          }
        }
      },
      isInRelation: {
        serializedName: "isInRelation",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const ContactListForLocationDto: msRest.CompositeMapper = {
  serializedName: "ContactListForLocationDto",
  type: {
    name: "Composite",
    className: "ContactListForLocationDto",
    modelProperties: {
      contactId: {
        serializedName: "contactId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      firstName: {
        serializedName: "firstName",
        type: {
          name: "String"
        }
      },
      lastName: {
        serializedName: "lastName",
        type: {
          name: "String"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      phone: {
        serializedName: "phone",
        type: {
          name: "String"
        }
      },
      mobile: {
        serializedName: "mobile",
        type: {
          name: "String"
        }
      },
      pin: {
        serializedName: "pin",
        type: {
          name: "String"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleListDto"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueListDto"
            }
          }
        }
      },
      loginExpiration: {
        serializedName: "loginExpiration",
        type: {
          name: "String"
        }
      },
      isFree: {
        serializedName: "isFree",
        type: {
          name: "Boolean"
        }
      },
      emailAddress: {
        serializedName: "emailAddress",
        type: {
          name: "String"
        }
      },
      user: {
        serializedName: "user",
        type: {
          name: "Composite",
          className: "UserInContactListDto"
        }
      },
      locations: {
        serializedName: "locations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationSimpleListDto"
            }
          }
        }
      },
      locationGroups: {
        serializedName: "locationGroups",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationGroupSimpleListDto"
            }
          }
        }
      },
      functions: {
        serializedName: "functions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FunctionListSimpleDto"
            }
          }
        }
      },
      roles: {
        serializedName: "roles",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RoleSimpleListDto"
            }
          }
        }
      },
      isInRelation: {
        serializedName: "isInRelation",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const ContactListForLocationGroupDto: msRest.CompositeMapper = {
  serializedName: "ContactListForLocationGroupDto",
  type: {
    name: "Composite",
    className: "ContactListForLocationGroupDto",
    modelProperties: {
      contactId: {
        serializedName: "contactId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      firstName: {
        serializedName: "firstName",
        type: {
          name: "String"
        }
      },
      lastName: {
        serializedName: "lastName",
        type: {
          name: "String"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      phone: {
        serializedName: "phone",
        type: {
          name: "String"
        }
      },
      mobile: {
        serializedName: "mobile",
        type: {
          name: "String"
        }
      },
      pin: {
        serializedName: "pin",
        type: {
          name: "String"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleListDto"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueListDto"
            }
          }
        }
      },
      loginExpiration: {
        serializedName: "loginExpiration",
        type: {
          name: "String"
        }
      },
      isFree: {
        serializedName: "isFree",
        type: {
          name: "Boolean"
        }
      },
      emailAddress: {
        serializedName: "emailAddress",
        type: {
          name: "String"
        }
      },
      user: {
        serializedName: "user",
        type: {
          name: "Composite",
          className: "UserInContactListDto"
        }
      },
      locations: {
        serializedName: "locations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationSimpleListDto"
            }
          }
        }
      },
      locationGroups: {
        serializedName: "locationGroups",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationGroupSimpleListDto"
            }
          }
        }
      },
      functions: {
        serializedName: "functions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FunctionListSimpleDto"
            }
          }
        }
      },
      roles: {
        serializedName: "roles",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RoleSimpleListDto"
            }
          }
        }
      },
      isInRelation: {
        serializedName: "isInRelation",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const ContactListForRoleDto: msRest.CompositeMapper = {
  serializedName: "ContactListForRoleDto",
  type: {
    name: "Composite",
    className: "ContactListForRoleDto",
    modelProperties: {
      contactId: {
        serializedName: "contactId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      firstName: {
        serializedName: "firstName",
        type: {
          name: "String"
        }
      },
      lastName: {
        serializedName: "lastName",
        type: {
          name: "String"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      phone: {
        serializedName: "phone",
        type: {
          name: "String"
        }
      },
      mobile: {
        serializedName: "mobile",
        type: {
          name: "String"
        }
      },
      pin: {
        serializedName: "pin",
        type: {
          name: "String"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleListDto"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueListDto"
            }
          }
        }
      },
      loginExpiration: {
        serializedName: "loginExpiration",
        type: {
          name: "String"
        }
      },
      isFree: {
        serializedName: "isFree",
        type: {
          name: "Boolean"
        }
      },
      emailAddress: {
        serializedName: "emailAddress",
        type: {
          name: "String"
        }
      },
      user: {
        serializedName: "user",
        type: {
          name: "Composite",
          className: "UserInContactListDto"
        }
      },
      locations: {
        serializedName: "locations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationSimpleListDto"
            }
          }
        }
      },
      locationGroups: {
        serializedName: "locationGroups",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationGroupSimpleListDto"
            }
          }
        }
      },
      functions: {
        serializedName: "functions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FunctionListSimpleDto"
            }
          }
        }
      },
      roles: {
        serializedName: "roles",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RoleSimpleListDto"
            }
          }
        }
      },
      isInRelation: {
        serializedName: "isInRelation",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const ContactLoginMethodSettingDto: msRest.CompositeMapper = {
  serializedName: "ContactLoginMethodSettingDto",
  type: {
    name: "Composite",
    className: "ContactLoginMethodSettingDto",
    modelProperties: {
      settingValueId: {
        serializedName: "settingValueId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2"
          ]
        }
      }
    }
  }
};

export const ContactNotificationTargetDto: msRest.CompositeMapper = {
  serializedName: "ContactNotificationTargetDto",
  type: {
    name: "Composite",
    className: "ContactNotificationTargetDto",
    modelProperties: {
      notificationTargetId: {
        serializedName: "notificationTargetId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      contact: {
        serializedName: "contact",
        type: {
          name: "Composite",
          className: "ContactSimpleDto"
        }
      }
    }
  }
};

export const ContactRelationListDto: msRest.CompositeMapper = {
  serializedName: "ContactRelationListDto",
  type: {
    name: "Composite",
    className: "ContactRelationListDto",
    modelProperties: {
      contactId: {
        serializedName: "contactId",
        type: {
          name: "Number"
        }
      },
      contact: {
        serializedName: "contact",
        type: {
          name: "Composite",
          className: "ContactSimpleListDto"
        }
      }
    }
  }
};

export const ContactTtm: msRest.CompositeMapper = {
  serializedName: "ContactTtm",
  type: {
    name: "Composite",
    className: "ContactTtm",
    modelProperties: {
      contactId: {
        serializedName: "contactId",
        type: {
          name: "Number"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      phone: {
        serializedName: "phone",
        type: {
          name: "String"
        }
      },
      mobile: {
        serializedName: "mobile",
        type: {
          name: "String"
        }
      },
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      },
      company: {
        serializedName: "company",
        type: {
          name: "Composite",
          className: "CompanyTtm"
        }
      },
      properties: {
        serializedName: "properties",
        type: {
          name: "Object"
        }
      }
    }
  }
};

export const ContractDto: msRest.CompositeMapper = {
  serializedName: "ContractDto",
  type: {
    name: "Composite",
    className: "ContractDto",
    modelProperties: {
      contractId: {
        serializedName: "contractId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      },
      startDate: {
        serializedName: "startDate",
        type: {
          name: "String"
        }
      },
      endDate: {
        serializedName: "endDate",
        type: {
          name: "String"
        }
      },
      duePeriod: {
        serializedName: "duePeriod",
        type: {
          name: "String"
        }
      },
      dueDate: {
        serializedName: "dueDate",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueDto"
            }
          }
        }
      },
      attachments: {
        serializedName: "attachments",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AttachmentForTechDto"
            }
          }
        }
      },
      links: {
        serializedName: "links",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LinkDto"
            }
          }
        }
      },
      locations: {
        serializedName: "locations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationSimpleDto"
            }
          }
        }
      },
      locationGroups: {
        serializedName: "locationGroups",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationGroupSimpleDto"
            }
          }
        }
      },
      contacts: {
        serializedName: "contacts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ContactSimpleDto"
            }
          }
        }
      },
      functions: {
        serializedName: "functions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FunctionSimpleDto"
            }
          }
        }
      }
    }
  }
};

export const ContractListDto: msRest.CompositeMapper = {
  serializedName: "ContractListDto",
  type: {
    name: "Composite",
    className: "ContractListDto",
    modelProperties: {
      contractId: {
        serializedName: "contractId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      },
      startDate: {
        serializedName: "startDate",
        type: {
          name: "String"
        }
      },
      endDate: {
        serializedName: "endDate",
        type: {
          name: "String"
        }
      },
      duePeriod: {
        serializedName: "duePeriod",
        type: {
          name: "String"
        }
      },
      dueDate: {
        serializedName: "dueDate",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleListDto"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueListDto"
            }
          }
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      }
    }
  }
};

export const ContractSimpleDto: msRest.CompositeMapper = {
  serializedName: "ContractSimpleDto",
  type: {
    name: "Composite",
    className: "ContractSimpleDto",
    modelProperties: {
      contractId: {
        serializedName: "contractId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ContractSimpleListDto: msRest.CompositeMapper = {
  serializedName: "ContractSimpleListDto",
  type: {
    name: "Composite",
    className: "ContractSimpleListDto",
    modelProperties: {
      contractId: {
        serializedName: "contractId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ControlPointDto: msRest.CompositeMapper = {
  serializedName: "ControlPointDto",
  type: {
    name: "Composite",
    className: "ControlPointDto",
    modelProperties: {
      controlPointId: {
        serializedName: "controlPointId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      inspection: {
        serializedName: "inspection",
        type: {
          name: "Composite",
          className: "StereotypeSimpleDto"
        }
      }
    }
  }
};

export const ControlPointFilterDto: msRest.CompositeMapper = {
  serializedName: "ControlPointFilterDto",
  type: {
    name: "Composite",
    className: "ControlPointFilterDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      }
    }
  }
};

export const ControlTagInPointListDto: msRest.CompositeMapper = {
  serializedName: "ControlTagInPointListDto",
  type: {
    name: "Composite",
    className: "ControlTagInPointListDto",
    modelProperties: {
      controlTagId: {
        serializedName: "controlTagId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ControlTagReferenceListDto: msRest.CompositeMapper = {
  serializedName: "ControlTagReferenceListDto",
  type: {
    name: "Composite",
    className: "ControlTagReferenceListDto",
    modelProperties: {
      tagId: {
        serializedName: "tagId",
        type: {
          name: "Number"
        }
      },
      tag: {
        serializedName: "tag",
        type: {
          name: "Composite",
          className: "ControlTagInPointListDto"
        }
      }
    }
  }
};

export const ControlPointForResourceListDto: msRest.CompositeMapper = {
  serializedName: "ControlPointForResourceListDto",
  type: {
    name: "Composite",
    className: "ControlPointForResourceListDto",
    modelProperties: {
      controlPointId: {
        serializedName: "controlPointId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      inspection: {
        serializedName: "inspection",
        type: {
          name: "Composite",
          className: "StereotypeSimpleListDto"
        }
      },
      references: {
        serializedName: "references",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ControlTagReferenceListDto"
            }
          }
        }
      },
      isInRelation: {
        serializedName: "isInRelation",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const ControlPointInMissionDto: msRest.CompositeMapper = {
  serializedName: "ControlPointInMissionDto",
  type: {
    name: "Composite",
    className: "ControlPointInMissionDto",
    modelProperties: {
      controlPointId: {
        serializedName: "controlPointId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      inspection: {
        serializedName: "inspection",
        type: {
          name: "Composite",
          className: "StereotypeSimpleDto"
        }
      }
    }
  }
};

export const ControlPointInMissionListDto: msRest.CompositeMapper = {
  serializedName: "ControlPointInMissionListDto",
  type: {
    name: "Composite",
    className: "ControlPointInMissionListDto",
    modelProperties: {
      controlPointId: {
        serializedName: "controlPointId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      inspection: {
        serializedName: "inspection",
        type: {
          name: "Composite",
          className: "StereotypeSimpleListDto"
        }
      }
    }
  }
};

export const ControlPointInTagDto: msRest.CompositeMapper = {
  serializedName: "ControlPointInTagDto",
  type: {
    name: "Composite",
    className: "ControlPointInTagDto",
    modelProperties: {
      controlPointId: {
        serializedName: "controlPointId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ControlPointListDto: msRest.CompositeMapper = {
  serializedName: "ControlPointListDto",
  type: {
    name: "Composite",
    className: "ControlPointListDto",
    modelProperties: {
      controlPointId: {
        serializedName: "controlPointId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      inspection: {
        serializedName: "inspection",
        type: {
          name: "Composite",
          className: "StereotypeSimpleListDto"
        }
      },
      references: {
        serializedName: "references",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ControlTagReferenceListDto"
            }
          }
        }
      }
    }
  }
};

export const ControlPointSimpleDto: msRest.CompositeMapper = {
  serializedName: "ControlPointSimpleDto",
  type: {
    name: "Composite",
    className: "ControlPointSimpleDto",
    modelProperties: {
      controlPointId: {
        serializedName: "controlPointId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      inspection: {
        serializedName: "inspection",
        type: {
          name: "Composite",
          className: "StereotypeSimpleDto"
        }
      }
    }
  }
};

export const ControlPointTtm: msRest.CompositeMapper = {
  serializedName: "ControlPointTtm",
  type: {
    name: "Composite",
    className: "ControlPointTtm",
    modelProperties: {
      controlPointId: {
        serializedName: "controlPointId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ControlTagDto: msRest.CompositeMapper = {
  serializedName: "ControlTagDto",
  type: {
    name: "Composite",
    className: "ControlTagDto",
    modelProperties: {
      controlTagId: {
        serializedName: "controlTagId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      points: {
        serializedName: "points",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ControlPointInTagDto"
            }
          }
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueDto"
            }
          }
        }
      }
    }
  }
};

export const ControlTagListDto: msRest.CompositeMapper = {
  serializedName: "ControlTagListDto",
  type: {
    name: "Composite",
    className: "ControlTagListDto",
    modelProperties: {
      controlTagId: {
        serializedName: "controlTagId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleListDto"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueListDto"
            }
          }
        }
      }
    }
  }
};

export const ControlTagSimpleDto: msRest.CompositeMapper = {
  serializedName: "ControlTagSimpleDto",
  type: {
    name: "Composite",
    className: "ControlTagSimpleDto",
    modelProperties: {
      controlTagId: {
        serializedName: "controlTagId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CoverGeneralDataProtectionRegulatedEntityQueueDto: msRest.CompositeMapper = {
  serializedName: "CoverGeneralDataProtectionRegulatedEntityQueueDto",
  type: {
    name: "Composite",
    className: "CoverGeneralDataProtectionRegulatedEntityQueueDto",
    modelProperties: {
      regulationId: {
        serializedName: "regulationId",
        type: {
          name: "Number"
        }
      },
      entityId: {
        serializedName: "entityId",
        type: {
          name: "Number"
        }
      },
      entityType: {
        serializedName: "entityType",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2"
          ]
        }
      }
    }
  }
};

export const FormValueDto: msRest.CompositeMapper = {
  serializedName: "FormValueDto",
  type: {
    name: "Composite",
    className: "FormValueDto",
    modelProperties: {
      fieldValueId: {
        serializedName: "fieldValueId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12"
          ]
        }
      }
    }
  }
};

export const FillAFormFieldDto: msRest.CompositeMapper = {
  serializedName: "FillAFormFieldDto",
  type: {
    name: "Composite",
    className: "FillAFormFieldDto",
    modelProperties: {
      fieldId: {
        serializedName: "fieldId",
        type: {
          name: "Number"
        }
      },
      rowIndex: {
        nullable: true,
        serializedName: "rowIndex",
        type: {
          name: "Number"
        }
      },
      values: {
        serializedName: "values",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FormValueDto"
            }
          }
        }
      }
    }
  }
};

export const FillAFormDto: msRest.CompositeMapper = {
  serializedName: "FillAFormDto",
  type: {
    name: "Composite",
    className: "FillAFormDto",
    modelProperties: {
      fields: {
        serializedName: "fields",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FillAFormFieldDto"
            }
          }
        }
      }
    }
  }
};

export const CreateChatByFormDto: msRest.CompositeMapper = {
  serializedName: "CreateChatByFormDto",
  type: {
    name: "Composite",
    className: "CreateChatByFormDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      processId: {
        serializedName: "processId",
        type: {
          name: "Number"
        }
      },
      receivers: {
        serializedName: "receivers",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ChatByFormReceiverDto"
            }
          }
        }
      },
      filledForm: {
        serializedName: "filledForm",
        type: {
          name: "Composite",
          className: "FillAFormDto"
        }
      }
    }
  }
};

export const CreateFileDto: msRest.CompositeMapper = {
  serializedName: "CreateFileDto",
  type: {
    name: "Composite",
    className: "CreateFileDto",
    modelProperties: {
      file: {
        serializedName: "file",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CreateFolderDto: msRest.CompositeMapper = {
  serializedName: "CreateFolderDto",
  type: {
    name: "Composite",
    className: "CreateFolderDto",
    modelProperties: {
      folderName: {
        serializedName: "folderName",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CreateManualTaskJobDto: msRest.CompositeMapper = {
  serializedName: "CreateManualTaskJobDto",
  type: {
    name: "Composite",
    className: "CreateManualTaskJobDto",
    modelProperties: {
      taskId: {
        serializedName: "taskId",
        type: {
          name: "Number"
        }
      },
      explicitState: {
        serializedName: "explicitState",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7"
          ]
        }
      }
    }
  }
};

export const TicketByProcessUsageDto: msRest.CompositeMapper = {
  serializedName: "TicketByProcessUsageDto",
  type: {
    name: "Composite",
    className: "TicketByProcessUsageDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      ticketId: {
        serializedName: "ticketId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      currentState: {
        serializedName: "currentState",
        type: {
          name: "Composite",
          className: "StateSimpleDto"
        }
      },
      resource: {
        serializedName: "resource",
        type: {
          name: "Composite",
          className: "ResourceSimpleDto"
        }
      },
      priority: {
        serializedName: "priority",
        type: {
          name: "Composite",
          className: "PrioritySimpleDto"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueDto"
            }
          }
        }
      }
    }
  }
};

export const CreateTicketByProcessDto: msRest.CompositeMapper = {
  serializedName: "CreateTicketByProcessDto",
  type: {
    name: "Composite",
    className: "CreateTicketByProcessDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      processId: {
        serializedName: "processId",
        type: {
          name: "Number"
        }
      },
      ticket: {
        serializedName: "ticket",
        type: {
          name: "Composite",
          className: "TicketByProcessUsageDto"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const InitializedChatDto: msRest.CompositeMapper = {
  serializedName: "InitializedChatDto",
  type: {
    name: "Composite",
    className: "InitializedChatDto",
    modelProperties: {
      chatId: {
        serializedName: "chatId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CreateTicketByProcessResponseDto: msRest.CompositeMapper = {
  serializedName: "CreateTicketByProcessResponseDto",
  type: {
    name: "Composite",
    className: "CreateTicketByProcessResponseDto",
    modelProperties: {
      chat: {
        serializedName: "chat",
        type: {
          name: "Composite",
          className: "InitializedChatDto"
        }
      },
      missionId: {
        nullable: true,
        serializedName: "missionId",
        type: {
          name: "Number"
        }
      },
      ticketId: {
        nullable: true,
        serializedName: "ticketId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const CreatedByDto: msRest.CompositeMapper = {
  serializedName: "CreatedByDto",
  type: {
    name: "Composite",
    className: "CreatedByDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CultureSettingDto: msRest.CompositeMapper = {
  serializedName: "CultureSettingDto",
  type: {
    name: "Composite",
    className: "CultureSettingDto",
    modelProperties: {
      settingValueId: {
        serializedName: "settingValueId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CurrentAppOverviewDto: msRest.CompositeMapper = {
  serializedName: "CurrentAppOverviewDto",
  type: {
    name: "Composite",
    className: "CurrentAppOverviewDto",
    modelProperties: {
      shortcuts: {
        serializedName: "shortcuts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AppShortcutDto"
            }
          }
        }
      }
    }
  }
};

export const CustomCheckboxDefaultDto: msRest.CompositeMapper = {
  serializedName: "CustomCheckboxDefaultDto",
  type: {
    name: "Composite",
    className: "CustomCheckboxDefaultDto",
    modelProperties: {
      customValueId: {
        serializedName: "customValueId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      customPropertyId: {
        nullable: true,
        serializedName: "customPropertyId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      value: {
        nullable: true,
        serializedName: "value",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const CustomCheckboxDefaultExportDto: msRest.CompositeMapper = {
  serializedName: "CustomCheckboxDefaultExportDto",
  type: {
    name: "Composite",
    className: "CustomCheckboxDefaultExportDto",
    modelProperties: {
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      value: {
        nullable: true,
        serializedName: "value",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const CustomDefaultDto: msRest.CompositeMapper = {
  serializedName: "CustomDefaultDto",
  type: {
    name: "Composite",
    className: "CustomDefaultDto",
    modelProperties: {
      customValueId: {
        serializedName: "customValueId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      customPropertyId: {
        nullable: true,
        serializedName: "customPropertyId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const CustomCheckboxDto: msRest.CompositeMapper = {
  serializedName: "CustomCheckboxDto",
  type: {
    name: "Composite",
    className: "CustomCheckboxDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      customPropertyId: {
        serializedName: "customPropertyId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      technicalName: {
        readOnly: true,
        serializedName: "technicalName",
        type: {
          name: "String"
        }
      },
      isRequired: {
        serializedName: "isRequired",
        type: {
          name: "Boolean"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      defaultValues: {
        serializedName: "defaultValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomDefaultDto"
            }
          }
        }
      },
      isRateable: {
        serializedName: "isRateable",
        type: {
          name: "Boolean"
        }
      },
      setPoint: {
        nullable: true,
        serializedName: "setPoint",
        type: {
          name: "Boolean"
        }
      },
      weight: {
        serializedName: "weight",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const CustomDefaultExportDto: msRest.CompositeMapper = {
  serializedName: "CustomDefaultExportDto",
  type: {
    name: "Composite",
    className: "CustomDefaultExportDto",
    modelProperties: {
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      }
    }
  }
};

export const CustomCheckboxExportDto: msRest.CompositeMapper = {
  serializedName: "CustomCheckboxExportDto",
  type: {
    name: "Composite",
    className: "CustomCheckboxExportDto",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      isRequired: {
        serializedName: "isRequired",
        type: {
          name: "Boolean"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      defaultValues: {
        serializedName: "defaultValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomDefaultExportDto"
            }
          }
        }
      },
      isRateable: {
        serializedName: "isRateable",
        type: {
          name: "Boolean"
        }
      },
      setPoint: {
        nullable: true,
        serializedName: "setPoint",
        type: {
          name: "Boolean"
        }
      },
      weight: {
        serializedName: "weight",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const CustomCheckboxValueDto: msRest.CompositeMapper = {
  serializedName: "CustomCheckboxValueDto",
  type: {
    name: "Composite",
    className: "CustomCheckboxValueDto",
    modelProperties: {
      customValueId: {
        serializedName: "customValueId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      isInherited: {
        serializedName: "isInherited",
        type: {
          name: "Boolean"
        }
      },
      ownValue: {
        nullable: true,
        serializedName: "ownValue",
        type: {
          name: "Boolean"
        }
      },
      inheritedValue: {
        nullable: true,
        serializedName: "inheritedValue",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const CustomDateDefaultDto: msRest.CompositeMapper = {
  serializedName: "CustomDateDefaultDto",
  type: {
    name: "Composite",
    className: "CustomDateDefaultDto",
    modelProperties: {
      customValueId: {
        serializedName: "customValueId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      customPropertyId: {
        nullable: true,
        serializedName: "customPropertyId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CustomDateDefaultExportDto: msRest.CompositeMapper = {
  serializedName: "CustomDateDefaultExportDto",
  type: {
    name: "Composite",
    className: "CustomDateDefaultExportDto",
    modelProperties: {
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CustomDatePropertyDto: msRest.CompositeMapper = {
  serializedName: "CustomDatePropertyDto",
  type: {
    name: "Composite",
    className: "CustomDatePropertyDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      customPropertyId: {
        serializedName: "customPropertyId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      technicalName: {
        readOnly: true,
        serializedName: "technicalName",
        type: {
          name: "String"
        }
      },
      isRequired: {
        serializedName: "isRequired",
        type: {
          name: "Boolean"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      defaultValues: {
        serializedName: "defaultValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomDefaultDto"
            }
          }
        }
      },
      maxTime: {
        serializedName: "maxTime",
        type: {
          name: "String"
        }
      },
      minTime: {
        serializedName: "minTime",
        type: {
          name: "String"
        }
      },
      setMaxTime: {
        serializedName: "setMaxTime",
        type: {
          name: "String"
        }
      },
      setMinTime: {
        serializedName: "setMinTime",
        type: {
          name: "String"
        }
      },
      isRateable: {
        serializedName: "isRateable",
        type: {
          name: "Boolean"
        }
      },
      weight: {
        serializedName: "weight",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const CustomDatePropertyExportDto: msRest.CompositeMapper = {
  serializedName: "CustomDatePropertyExportDto",
  type: {
    name: "Composite",
    className: "CustomDatePropertyExportDto",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      isRequired: {
        serializedName: "isRequired",
        type: {
          name: "Boolean"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      defaultValues: {
        serializedName: "defaultValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomDefaultExportDto"
            }
          }
        }
      },
      maxTime: {
        serializedName: "maxTime",
        type: {
          name: "String"
        }
      },
      minTime: {
        serializedName: "minTime",
        type: {
          name: "String"
        }
      },
      setMaxTime: {
        serializedName: "setMaxTime",
        type: {
          name: "String"
        }
      },
      setMinTime: {
        serializedName: "setMinTime",
        type: {
          name: "String"
        }
      },
      isRateable: {
        serializedName: "isRateable",
        type: {
          name: "Boolean"
        }
      },
      weight: {
        serializedName: "weight",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const CustomDateValueDto: msRest.CompositeMapper = {
  serializedName: "CustomDateValueDto",
  type: {
    name: "Composite",
    className: "CustomDateValueDto",
    modelProperties: {
      customValueId: {
        serializedName: "customValueId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      isInherited: {
        serializedName: "isInherited",
        type: {
          name: "Boolean"
        }
      },
      ownValue: {
        serializedName: "ownValue",
        type: {
          name: "String"
        }
      },
      inheritedValue: {
        serializedName: "inheritedValue",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CustomDefaultCompleteDto: msRest.CompositeMapper = {
  serializedName: "CustomDefaultCompleteDto",
  type: {
    name: "Composite",
    className: "CustomDefaultCompleteDto",
    modelProperties: {
      customValueId: {
        serializedName: "customValueId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      customPropertyId: {
        nullable: true,
        serializedName: "customPropertyId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      isSelected: {
        nullable: true,
        serializedName: "isSelected",
        type: {
          name: "Boolean"
        }
      },
      position: {
        nullable: true,
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      content: {
        serializedName: "content",
        type: {
          name: "String"
        }
      },
      rating: {
        serializedName: "rating",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1"
          ]
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "Object"
        }
      }
    }
  }
};

export const CustomDropDownDefaultExportDto: msRest.CompositeMapper = {
  serializedName: "CustomDropDownDefaultExportDto",
  type: {
    name: "Composite",
    className: "CustomDropDownDefaultExportDto",
    modelProperties: {
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      },
      isSelected: {
        nullable: true,
        serializedName: "isSelected",
        type: {
          name: "Boolean"
        }
      },
      rating: {
        serializedName: "rating",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1"
          ]
        }
      },
      position: {
        nullable: true,
        serializedName: "position",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const CustomDropDownDefaultValueDto: msRest.CompositeMapper = {
  serializedName: "CustomDropDownDefaultValueDto",
  type: {
    name: "Composite",
    className: "CustomDropDownDefaultValueDto",
    modelProperties: {
      customValueId: {
        serializedName: "customValueId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      customPropertyId: {
        nullable: true,
        serializedName: "customPropertyId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      },
      rating: {
        serializedName: "rating",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1"
          ]
        }
      },
      isSelected: {
        nullable: true,
        serializedName: "isSelected",
        type: {
          name: "Boolean"
        }
      },
      position: {
        nullable: true,
        serializedName: "position",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const CustomDropDownDto: msRest.CompositeMapper = {
  serializedName: "CustomDropDownDto",
  type: {
    name: "Composite",
    className: "CustomDropDownDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      customPropertyId: {
        serializedName: "customPropertyId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      technicalName: {
        readOnly: true,
        serializedName: "technicalName",
        type: {
          name: "String"
        }
      },
      isRequired: {
        serializedName: "isRequired",
        type: {
          name: "Boolean"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      defaultValues: {
        serializedName: "defaultValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomDefaultDto"
            }
          }
        }
      },
      isRateable: {
        serializedName: "isRateable",
        type: {
          name: "Boolean"
        }
      },
      weight: {
        serializedName: "weight",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const CustomDropDownExportDto: msRest.CompositeMapper = {
  serializedName: "CustomDropDownExportDto",
  type: {
    name: "Composite",
    className: "CustomDropDownExportDto",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      isRequired: {
        serializedName: "isRequired",
        type: {
          name: "Boolean"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      defaultValues: {
        serializedName: "defaultValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomDefaultExportDto"
            }
          }
        }
      },
      isRateable: {
        serializedName: "isRateable",
        type: {
          name: "Boolean"
        }
      },
      weight: {
        serializedName: "weight",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const CustomDropDownValueDto: msRest.CompositeMapper = {
  serializedName: "CustomDropDownValueDto",
  type: {
    name: "Composite",
    className: "CustomDropDownValueDto",
    modelProperties: {
      customValueId: {
        serializedName: "customValueId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      isInherited: {
        serializedName: "isInherited",
        type: {
          name: "Boolean"
        }
      },
      ownValue: {
        nullable: true,
        serializedName: "ownValue",
        type: {
          name: "Number"
        }
      },
      inheritedValue: {
        nullable: true,
        serializedName: "inheritedValue",
        type: {
          name: "Number"
        }
      },
      isSelected: {
        nullable: true,
        serializedName: "isSelected",
        type: {
          name: "Boolean"
        }
      },
      position: {
        nullable: true,
        serializedName: "position",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const CustomEmailDefaultDto: msRest.CompositeMapper = {
  serializedName: "CustomEmailDefaultDto",
  type: {
    name: "Composite",
    className: "CustomEmailDefaultDto",
    modelProperties: {
      customValueId: {
        serializedName: "customValueId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      customPropertyId: {
        nullable: true,
        serializedName: "customPropertyId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CustomEmailDefaultExportDto: msRest.CompositeMapper = {
  serializedName: "CustomEmailDefaultExportDto",
  type: {
    name: "Composite",
    className: "CustomEmailDefaultExportDto",
    modelProperties: {
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CustomEmailPropertyDto: msRest.CompositeMapper = {
  serializedName: "CustomEmailPropertyDto",
  type: {
    name: "Composite",
    className: "CustomEmailPropertyDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      customPropertyId: {
        serializedName: "customPropertyId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      technicalName: {
        readOnly: true,
        serializedName: "technicalName",
        type: {
          name: "String"
        }
      },
      isRequired: {
        serializedName: "isRequired",
        type: {
          name: "Boolean"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      defaultValues: {
        serializedName: "defaultValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomDefaultDto"
            }
          }
        }
      }
    }
  }
};

export const CustomEmailPropertyExportDto: msRest.CompositeMapper = {
  serializedName: "CustomEmailPropertyExportDto",
  type: {
    name: "Composite",
    className: "CustomEmailPropertyExportDto",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      isRequired: {
        serializedName: "isRequired",
        type: {
          name: "Boolean"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      defaultValues: {
        serializedName: "defaultValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomDefaultExportDto"
            }
          }
        }
      }
    }
  }
};

export const CustomEmailValueDto: msRest.CompositeMapper = {
  serializedName: "CustomEmailValueDto",
  type: {
    name: "Composite",
    className: "CustomEmailValueDto",
    modelProperties: {
      customValueId: {
        serializedName: "customValueId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      isInherited: {
        serializedName: "isInherited",
        type: {
          name: "Boolean"
        }
      },
      ownValue: {
        serializedName: "ownValue",
        type: {
          name: "String"
        }
      },
      inheritedValue: {
        serializedName: "inheritedValue",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CustomFilterDto: msRest.CompositeMapper = {
  serializedName: "CustomFilterDto",
  type: {
    name: "Composite",
    className: "CustomFilterDto",
    modelProperties: {
      property: {
        serializedName: "property",
        type: {
          name: "String"
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      },
      kind: {
        serializedName: "kind",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      },
      operator: {
        serializedName: "operator",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "-1"
          ]
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      }
    }
  }
};

export const CustomInfoDto: msRest.CompositeMapper = {
  serializedName: "CustomInfoDto",
  type: {
    name: "Composite",
    className: "CustomInfoDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      customPropertyId: {
        serializedName: "customPropertyId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      technicalName: {
        readOnly: true,
        serializedName: "technicalName",
        type: {
          name: "String"
        }
      },
      isRequired: {
        serializedName: "isRequired",
        type: {
          name: "Boolean"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      defaultValues: {
        serializedName: "defaultValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomDefaultDto"
            }
          }
        }
      },
      infoKind: {
        serializedName: "infoKind",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2"
          ]
        }
      },
      content: {
        serializedName: "content",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CustomInfoExportDto: msRest.CompositeMapper = {
  serializedName: "CustomInfoExportDto",
  type: {
    name: "Composite",
    className: "CustomInfoExportDto",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      isRequired: {
        serializedName: "isRequired",
        type: {
          name: "Boolean"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      defaultValues: {
        serializedName: "defaultValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomDefaultExportDto"
            }
          }
        }
      },
      infoKind: {
        serializedName: "infoKind",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2"
          ]
        }
      },
      content: {
        serializedName: "content",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CustomInfoValueDto: msRest.CompositeMapper = {
  serializedName: "CustomInfoValueDto",
  type: {
    name: "Composite",
    className: "CustomInfoValueDto",
    modelProperties: {
      customValueId: {
        serializedName: "customValueId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      isInherited: {
        serializedName: "isInherited",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const CustomMultilineDefaultDto: msRest.CompositeMapper = {
  serializedName: "CustomMultilineDefaultDto",
  type: {
    name: "Composite",
    className: "CustomMultilineDefaultDto",
    modelProperties: {
      customValueId: {
        serializedName: "customValueId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      customPropertyId: {
        nullable: true,
        serializedName: "customPropertyId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CustomMultilineDefaultExportDto: msRest.CompositeMapper = {
  serializedName: "CustomMultilineDefaultExportDto",
  type: {
    name: "Composite",
    className: "CustomMultilineDefaultExportDto",
    modelProperties: {
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CustomMultilinePropertyDto: msRest.CompositeMapper = {
  serializedName: "CustomMultilinePropertyDto",
  type: {
    name: "Composite",
    className: "CustomMultilinePropertyDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      customPropertyId: {
        serializedName: "customPropertyId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      technicalName: {
        readOnly: true,
        serializedName: "technicalName",
        type: {
          name: "String"
        }
      },
      isRequired: {
        serializedName: "isRequired",
        type: {
          name: "Boolean"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      defaultValues: {
        serializedName: "defaultValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomDefaultDto"
            }
          }
        }
      },
      maxLength: {
        nullable: true,
        serializedName: "maxLength",
        constraints: {
          InclusiveMaximum: 2147483647,
          InclusiveMinimum: 0
        },
        type: {
          name: "Number"
        }
      },
      minLength: {
        nullable: true,
        serializedName: "minLength",
        constraints: {
          InclusiveMaximum: 2147483647,
          InclusiveMinimum: 0
        },
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const CustomMultilinePropertyExportDto: msRest.CompositeMapper = {
  serializedName: "CustomMultilinePropertyExportDto",
  type: {
    name: "Composite",
    className: "CustomMultilinePropertyExportDto",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      isRequired: {
        serializedName: "isRequired",
        type: {
          name: "Boolean"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      defaultValues: {
        serializedName: "defaultValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomDefaultExportDto"
            }
          }
        }
      },
      maxLength: {
        nullable: true,
        serializedName: "maxLength",
        constraints: {
          InclusiveMaximum: 2147483647,
          InclusiveMinimum: 0
        },
        type: {
          name: "Number"
        }
      },
      minLength: {
        nullable: true,
        serializedName: "minLength",
        constraints: {
          InclusiveMaximum: 2147483647,
          InclusiveMinimum: 0
        },
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const CustomMultilineValueDto: msRest.CompositeMapper = {
  serializedName: "CustomMultilineValueDto",
  type: {
    name: "Composite",
    className: "CustomMultilineValueDto",
    modelProperties: {
      customValueId: {
        serializedName: "customValueId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      isInherited: {
        serializedName: "isInherited",
        type: {
          name: "Boolean"
        }
      },
      ownValue: {
        serializedName: "ownValue",
        type: {
          name: "String"
        }
      },
      inheritedValue: {
        serializedName: "inheritedValue",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CustomMultipleChoiceDefaultDto: msRest.CompositeMapper = {
  serializedName: "CustomMultipleChoiceDefaultDto",
  type: {
    name: "Composite",
    className: "CustomMultipleChoiceDefaultDto",
    modelProperties: {
      customValueId: {
        serializedName: "customValueId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      customPropertyId: {
        nullable: true,
        serializedName: "customPropertyId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      },
      rating: {
        serializedName: "rating",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1"
          ]
        }
      },
      isSelected: {
        nullable: true,
        serializedName: "isSelected",
        type: {
          name: "Boolean"
        }
      },
      position: {
        nullable: true,
        serializedName: "position",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const CustomMultipleChoiceDefaultExportDto: msRest.CompositeMapper = {
  serializedName: "CustomMultipleChoiceDefaultExportDto",
  type: {
    name: "Composite",
    className: "CustomMultipleChoiceDefaultExportDto",
    modelProperties: {
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      },
      isSelected: {
        nullable: true,
        serializedName: "isSelected",
        type: {
          name: "Boolean"
        }
      },
      rating: {
        serializedName: "rating",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1"
          ]
        }
      },
      position: {
        nullable: true,
        serializedName: "position",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const CustomMultipleChoiceDto: msRest.CompositeMapper = {
  serializedName: "CustomMultipleChoiceDto",
  type: {
    name: "Composite",
    className: "CustomMultipleChoiceDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      customPropertyId: {
        serializedName: "customPropertyId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      technicalName: {
        readOnly: true,
        serializedName: "technicalName",
        type: {
          name: "String"
        }
      },
      isRequired: {
        serializedName: "isRequired",
        type: {
          name: "Boolean"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      defaultValues: {
        serializedName: "defaultValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomDefaultDto"
            }
          }
        }
      },
      isRateable: {
        serializedName: "isRateable",
        type: {
          name: "Boolean"
        }
      },
      weight: {
        serializedName: "weight",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const CustomMultipleChoiceExportDto: msRest.CompositeMapper = {
  serializedName: "CustomMultipleChoiceExportDto",
  type: {
    name: "Composite",
    className: "CustomMultipleChoiceExportDto",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      isRequired: {
        serializedName: "isRequired",
        type: {
          name: "Boolean"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      defaultValues: {
        serializedName: "defaultValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomDefaultExportDto"
            }
          }
        }
      },
      isRateable: {
        serializedName: "isRateable",
        type: {
          name: "Boolean"
        }
      },
      weight: {
        serializedName: "weight",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const CustomMultipleChoiceValueDto: msRest.CompositeMapper = {
  serializedName: "CustomMultipleChoiceValueDto",
  type: {
    name: "Composite",
    className: "CustomMultipleChoiceValueDto",
    modelProperties: {
      customValueId: {
        serializedName: "customValueId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      isInherited: {
        serializedName: "isInherited",
        type: {
          name: "Boolean"
        }
      },
      ownValue: {
        nullable: true,
        serializedName: "ownValue",
        type: {
          name: "Number"
        }
      },
      inheritedValue: {
        nullable: true,
        serializedName: "inheritedValue",
        type: {
          name: "Number"
        }
      },
      isSelected: {
        nullable: true,
        serializedName: "isSelected",
        type: {
          name: "Boolean"
        }
      },
      position: {
        nullable: true,
        serializedName: "position",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const CustomNumericDefaultDto: msRest.CompositeMapper = {
  serializedName: "CustomNumericDefaultDto",
  type: {
    name: "Composite",
    className: "CustomNumericDefaultDto",
    modelProperties: {
      customValueId: {
        serializedName: "customValueId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      customPropertyId: {
        nullable: true,
        serializedName: "customPropertyId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      value: {
        nullable: true,
        serializedName: "value",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const CustomNumericDefaultExportDto: msRest.CompositeMapper = {
  serializedName: "CustomNumericDefaultExportDto",
  type: {
    name: "Composite",
    className: "CustomNumericDefaultExportDto",
    modelProperties: {
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      value: {
        nullable: true,
        serializedName: "value",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const CustomNumericPropertyDto: msRest.CompositeMapper = {
  serializedName: "CustomNumericPropertyDto",
  type: {
    name: "Composite",
    className: "CustomNumericPropertyDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      customPropertyId: {
        serializedName: "customPropertyId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      technicalName: {
        readOnly: true,
        serializedName: "technicalName",
        type: {
          name: "String"
        }
      },
      isRequired: {
        serializedName: "isRequired",
        type: {
          name: "Boolean"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      defaultValues: {
        serializedName: "defaultValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomDefaultDto"
            }
          }
        }
      },
      maxValue: {
        nullable: true,
        serializedName: "maxValue",
        type: {
          name: "Number"
        }
      },
      minValue: {
        nullable: true,
        serializedName: "minValue",
        type: {
          name: "Number"
        }
      },
      setPointMin: {
        nullable: true,
        serializedName: "setPointMin",
        type: {
          name: "Number"
        }
      },
      setPointMax: {
        nullable: true,
        serializedName: "setPointMax",
        type: {
          name: "Number"
        }
      },
      isRateable: {
        serializedName: "isRateable",
        type: {
          name: "Boolean"
        }
      },
      weight: {
        serializedName: "weight",
        type: {
          name: "Number"
        }
      },
      isNumberFormatActive: {
        serializedName: "isNumberFormatActive",
        type: {
          name: "Boolean"
        }
      },
      unit: {
        serializedName: "unit",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CustomNumericPropertyExportDto: msRest.CompositeMapper = {
  serializedName: "CustomNumericPropertyExportDto",
  type: {
    name: "Composite",
    className: "CustomNumericPropertyExportDto",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      isRequired: {
        serializedName: "isRequired",
        type: {
          name: "Boolean"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      defaultValues: {
        serializedName: "defaultValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomDefaultExportDto"
            }
          }
        }
      },
      maxValue: {
        nullable: true,
        serializedName: "maxValue",
        type: {
          name: "Number"
        }
      },
      minValue: {
        nullable: true,
        serializedName: "minValue",
        type: {
          name: "Number"
        }
      },
      setPointMin: {
        nullable: true,
        serializedName: "setPointMin",
        type: {
          name: "Number"
        }
      },
      setPointMax: {
        nullable: true,
        serializedName: "setPointMax",
        type: {
          name: "Number"
        }
      },
      isRateable: {
        serializedName: "isRateable",
        type: {
          name: "Boolean"
        }
      },
      weight: {
        serializedName: "weight",
        type: {
          name: "Number"
        }
      },
      isNumberFormatActive: {
        serializedName: "isNumberFormatActive",
        type: {
          name: "Boolean"
        }
      },
      unit: {
        serializedName: "unit",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CustomNumericValueDto: msRest.CompositeMapper = {
  serializedName: "CustomNumericValueDto",
  type: {
    name: "Composite",
    className: "CustomNumericValueDto",
    modelProperties: {
      customValueId: {
        serializedName: "customValueId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      isInherited: {
        serializedName: "isInherited",
        type: {
          name: "Boolean"
        }
      },
      ownValue: {
        nullable: true,
        serializedName: "ownValue",
        type: {
          name: "Number"
        }
      },
      inheritedValue: {
        nullable: true,
        serializedName: "inheritedValue",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const CustomPhoneDefaultDto: msRest.CompositeMapper = {
  serializedName: "CustomPhoneDefaultDto",
  type: {
    name: "Composite",
    className: "CustomPhoneDefaultDto",
    modelProperties: {
      customValueId: {
        serializedName: "customValueId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      customPropertyId: {
        nullable: true,
        serializedName: "customPropertyId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CustomPhoneDefaultExportDto: msRest.CompositeMapper = {
  serializedName: "CustomPhoneDefaultExportDto",
  type: {
    name: "Composite",
    className: "CustomPhoneDefaultExportDto",
    modelProperties: {
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CustomPhoneDto: msRest.CompositeMapper = {
  serializedName: "CustomPhoneDto",
  type: {
    name: "Composite",
    className: "CustomPhoneDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      customPropertyId: {
        serializedName: "customPropertyId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      technicalName: {
        readOnly: true,
        serializedName: "technicalName",
        type: {
          name: "String"
        }
      },
      isRequired: {
        serializedName: "isRequired",
        type: {
          name: "Boolean"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      defaultValues: {
        serializedName: "defaultValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomDefaultDto"
            }
          }
        }
      }
    }
  }
};

export const CustomPhoneExportDto: msRest.CompositeMapper = {
  serializedName: "CustomPhoneExportDto",
  type: {
    name: "Composite",
    className: "CustomPhoneExportDto",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      isRequired: {
        serializedName: "isRequired",
        type: {
          name: "Boolean"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      defaultValues: {
        serializedName: "defaultValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomDefaultExportDto"
            }
          }
        }
      }
    }
  }
};

export const CustomPhoneValueDto: msRest.CompositeMapper = {
  serializedName: "CustomPhoneValueDto",
  type: {
    name: "Composite",
    className: "CustomPhoneValueDto",
    modelProperties: {
      customValueId: {
        serializedName: "customValueId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      isInherited: {
        serializedName: "isInherited",
        type: {
          name: "Boolean"
        }
      },
      ownValue: {
        serializedName: "ownValue",
        type: {
          name: "String"
        }
      },
      inheritedValue: {
        serializedName: "inheritedValue",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CustomPictureDto: msRest.CompositeMapper = {
  serializedName: "CustomPictureDto",
  type: {
    name: "Composite",
    className: "CustomPictureDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      customPropertyId: {
        serializedName: "customPropertyId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      technicalName: {
        readOnly: true,
        serializedName: "technicalName",
        type: {
          name: "String"
        }
      },
      isRequired: {
        serializedName: "isRequired",
        type: {
          name: "Boolean"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      defaultValues: {
        serializedName: "defaultValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomDefaultDto"
            }
          }
        }
      }
    }
  }
};

export const CustomPictureExportDto: msRest.CompositeMapper = {
  serializedName: "CustomPictureExportDto",
  type: {
    name: "Composite",
    className: "CustomPictureExportDto",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      isRequired: {
        serializedName: "isRequired",
        type: {
          name: "Boolean"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      defaultValues: {
        serializedName: "defaultValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomDefaultExportDto"
            }
          }
        }
      }
    }
  }
};

export const CustomPictureValueDto: msRest.CompositeMapper = {
  serializedName: "CustomPictureValueDto",
  type: {
    name: "Composite",
    className: "CustomPictureValueDto",
    modelProperties: {
      customValueId: {
        serializedName: "customValueId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      isInherited: {
        serializedName: "isInherited",
        type: {
          name: "Boolean"
        }
      },
      ownValue: {
        nullable: true,
        serializedName: "ownValue",
        type: {
          name: "Number"
        }
      },
      ownValueFile: {
        serializedName: "ownValueFile",
        type: {
          name: "Composite",
          className: "FileDto"
        }
      },
      inheritedValue: {
        nullable: true,
        serializedName: "inheritedValue",
        type: {
          name: "Number"
        }
      },
      inheritedValueFile: {
        serializedName: "inheritedValueFile",
        type: {
          name: "Composite",
          className: "FileDto"
        }
      }
    }
  }
};

export const CustomPropertyCompleteDto: msRest.CompositeMapper = {
  serializedName: "CustomPropertyCompleteDto",
  type: {
    name: "Composite",
    className: "CustomPropertyCompleteDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      customPropertyId: {
        serializedName: "customPropertyId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      isRequired: {
        serializedName: "isRequired",
        type: {
          name: "Boolean"
        }
      },
      isRateable: {
        nullable: true,
        serializedName: "isRateable",
        type: {
          name: "Boolean"
        }
      },
      weight: {
        nullable: true,
        serializedName: "weight",
        type: {
          name: "Number"
        }
      },
      maxLength: {
        nullable: true,
        serializedName: "maxLength",
        type: {
          name: "Number"
        }
      },
      minLength: {
        nullable: true,
        serializedName: "minLength",
        type: {
          name: "Number"
        }
      },
      minValue: {
        nullable: true,
        serializedName: "minValue",
        type: {
          name: "Number"
        }
      },
      maxValue: {
        nullable: true,
        serializedName: "maxValue",
        type: {
          name: "Number"
        }
      },
      setPointMin: {
        nullable: true,
        serializedName: "setPointMin",
        type: {
          name: "Number"
        }
      },
      setPointMax: {
        nullable: true,
        serializedName: "setPointMax",
        type: {
          name: "Number"
        }
      },
      unit: {
        serializedName: "unit",
        type: {
          name: "String"
        }
      },
      isNumberFormatActive: {
        nullable: true,
        serializedName: "isNumberFormatActive",
        type: {
          name: "Boolean"
        }
      },
      minMinutes: {
        nullable: true,
        serializedName: "minMinutes",
        type: {
          name: "Number"
        }
      },
      maxMinutes: {
        nullable: true,
        serializedName: "maxMinutes",
        type: {
          name: "Number"
        }
      },
      setMinMinutes: {
        nullable: true,
        serializedName: "setMinMinutes",
        type: {
          name: "Number"
        }
      },
      setMaxMinutes: {
        nullable: true,
        serializedName: "setMaxMinutes",
        type: {
          name: "Number"
        }
      },
      maxTime: {
        serializedName: "maxTime",
        type: {
          name: "String"
        }
      },
      minTime: {
        serializedName: "minTime",
        type: {
          name: "String"
        }
      },
      setMaxTime: {
        serializedName: "setMaxTime",
        type: {
          name: "String"
        }
      },
      setMinTime: {
        serializedName: "setMinTime",
        type: {
          name: "String"
        }
      },
      content: {
        serializedName: "content",
        type: {
          name: "String"
        }
      },
      infoKind: {
        serializedName: "infoKind",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2"
          ]
        }
      },
      setPoint: {
        nullable: true,
        serializedName: "setPoint",
        type: {
          name: "Boolean"
        }
      },
      defaultValues: {
        serializedName: "defaultValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomDefaultCompleteDto"
            }
          }
        }
      }
    }
  }
};

export const CustomPropertyDto: msRest.CompositeMapper = {
  serializedName: "CustomPropertyDto",
  type: {
    name: "Composite",
    className: "CustomPropertyDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      customPropertyId: {
        serializedName: "customPropertyId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      technicalName: {
        readOnly: true,
        serializedName: "technicalName",
        type: {
          name: "String"
        }
      },
      isRequired: {
        serializedName: "isRequired",
        type: {
          name: "Boolean"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      defaultValues: {
        serializedName: "defaultValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomDefaultDto"
            }
          }
        }
      }
    }
  }
};

export const CustomPropertyExportDto: msRest.CompositeMapper = {
  serializedName: "CustomPropertyExportDto",
  type: {
    name: "Composite",
    className: "CustomPropertyExportDto",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      isRequired: {
        serializedName: "isRequired",
        type: {
          name: "Boolean"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      defaultValues: {
        serializedName: "defaultValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomDefaultExportDto"
            }
          }
        }
      }
    }
  }
};

export const CustomPropertySetDto: msRest.CompositeMapper = {
  serializedName: "CustomPropertySetDto",
  type: {
    name: "Composite",
    className: "CustomPropertySetDto",
    modelProperties: {
      customPropertySetId: {
        serializedName: "customPropertySetId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      properties: {
        serializedName: "properties",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomPropertyDto"
            }
          }
        }
      }
    }
  }
};

export const CustomPropertySetExportDto: msRest.CompositeMapper = {
  serializedName: "CustomPropertySetExportDto",
  type: {
    name: "Composite",
    className: "CustomPropertySetExportDto",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      properties: {
        serializedName: "properties",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomPropertyExportDto"
            }
          }
        }
      }
    }
  }
};

export const CustomPropertySetListDto: msRest.CompositeMapper = {
  serializedName: "CustomPropertySetListDto",
  type: {
    name: "Composite",
    className: "CustomPropertySetListDto",
    modelProperties: {
      customPropertySetId: {
        serializedName: "customPropertySetId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      properties: {
        serializedName: "properties",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomPropertyCompleteDto"
            }
          }
        }
      }
    }
  }
};

export const CustomPropertyTtm: msRest.CompositeMapper = {
  serializedName: "CustomPropertyTtm",
  type: {
    name: "Composite",
    className: "CustomPropertyTtm",
    modelProperties: {
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      unit: {
        serializedName: "unit",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CustomPropertyViewSetDto: msRest.CompositeMapper = {
  serializedName: "CustomPropertyViewSetDto",
  type: {
    name: "Composite",
    className: "CustomPropertyViewSetDto",
    modelProperties: {
      customPropertySetId: {
        serializedName: "customPropertySetId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      properties: {
        serializedName: "properties",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomPropertyDto"
            }
          }
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const CustomSetReferenceDto: msRest.CompositeMapper = {
  serializedName: "CustomSetReferenceDto",
  type: {
    name: "Composite",
    className: "CustomSetReferenceDto",
    modelProperties: {
      stereotypeId: {
        required: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      customPropertySetId: {
        serializedName: "customPropertySetId",
        type: {
          name: "Number"
        }
      },
      customPropertySet: {
        serializedName: "customPropertySet",
        type: {
          name: "Composite",
          className: "CustomPropertySetDto"
        }
      },
      isInherited: {
        serializedName: "isInherited",
        type: {
          name: "Boolean"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const CustomSetReferenceExportDto: msRest.CompositeMapper = {
  serializedName: "CustomSetReferenceExportDto",
  type: {
    name: "Composite",
    className: "CustomSetReferenceExportDto",
    modelProperties: {
      customPropertySet: {
        serializedName: "customPropertySet",
        type: {
          name: "Composite",
          className: "CustomPropertySetExportDto"
        }
      }
    }
  }
};

export const CustomSetReferenceListDto: msRest.CompositeMapper = {
  serializedName: "CustomSetReferenceListDto",
  type: {
    name: "Composite",
    className: "CustomSetReferenceListDto",
    modelProperties: {
      stereotypeId: {
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      customPropertySetId: {
        serializedName: "customPropertySetId",
        type: {
          name: "Number"
        }
      },
      customPropertySet: {
        serializedName: "customPropertySet",
        type: {
          name: "Composite",
          className: "CustomPropertySetListDto"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      isInherited: {
        serializedName: "isInherited",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const CustomSignatureDto: msRest.CompositeMapper = {
  serializedName: "CustomSignatureDto",
  type: {
    name: "Composite",
    className: "CustomSignatureDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      customPropertyId: {
        serializedName: "customPropertyId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      technicalName: {
        readOnly: true,
        serializedName: "technicalName",
        type: {
          name: "String"
        }
      },
      isRequired: {
        serializedName: "isRequired",
        type: {
          name: "Boolean"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      defaultValues: {
        serializedName: "defaultValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomDefaultDto"
            }
          }
        }
      }
    }
  }
};

export const CustomSignatureExportDto: msRest.CompositeMapper = {
  serializedName: "CustomSignatureExportDto",
  type: {
    name: "Composite",
    className: "CustomSignatureExportDto",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      isRequired: {
        serializedName: "isRequired",
        type: {
          name: "Boolean"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      defaultValues: {
        serializedName: "defaultValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomDefaultExportDto"
            }
          }
        }
      }
    }
  }
};

export const CustomSignatureValueDto: msRest.CompositeMapper = {
  serializedName: "CustomSignatureValueDto",
  type: {
    name: "Composite",
    className: "CustomSignatureValueDto",
    modelProperties: {
      customValueId: {
        serializedName: "customValueId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      isInherited: {
        serializedName: "isInherited",
        type: {
          name: "Boolean"
        }
      },
      ownValue: {
        nullable: true,
        serializedName: "ownValue",
        type: {
          name: "Number"
        }
      },
      ownValueFile: {
        serializedName: "ownValueFile",
        type: {
          name: "Composite",
          className: "FileDto"
        }
      },
      inheritedValue: {
        nullable: true,
        serializedName: "inheritedValue",
        type: {
          name: "Number"
        }
      },
      inheritedValueFile: {
        serializedName: "inheritedValueFile",
        type: {
          name: "Composite",
          className: "FileDto"
        }
      }
    }
  }
};

export const CustomTextDefaultDto: msRest.CompositeMapper = {
  serializedName: "CustomTextDefaultDto",
  type: {
    name: "Composite",
    className: "CustomTextDefaultDto",
    modelProperties: {
      customValueId: {
        serializedName: "customValueId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      customPropertyId: {
        nullable: true,
        serializedName: "customPropertyId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CustomTextDefaultExportDto: msRest.CompositeMapper = {
  serializedName: "CustomTextDefaultExportDto",
  type: {
    name: "Composite",
    className: "CustomTextDefaultExportDto",
    modelProperties: {
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CustomTextPropertyDto: msRest.CompositeMapper = {
  serializedName: "CustomTextPropertyDto",
  type: {
    name: "Composite",
    className: "CustomTextPropertyDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      customPropertyId: {
        serializedName: "customPropertyId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      technicalName: {
        readOnly: true,
        serializedName: "technicalName",
        type: {
          name: "String"
        }
      },
      isRequired: {
        serializedName: "isRequired",
        type: {
          name: "Boolean"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      defaultValues: {
        serializedName: "defaultValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomDefaultDto"
            }
          }
        }
      },
      maxLength: {
        nullable: true,
        serializedName: "maxLength",
        constraints: {
          InclusiveMaximum: 2147483647,
          InclusiveMinimum: 0
        },
        type: {
          name: "Number"
        }
      },
      minLength: {
        nullable: true,
        serializedName: "minLength",
        constraints: {
          InclusiveMaximum: 2147483647,
          InclusiveMinimum: 0
        },
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const CustomTextPropertyExportDto: msRest.CompositeMapper = {
  serializedName: "CustomTextPropertyExportDto",
  type: {
    name: "Composite",
    className: "CustomTextPropertyExportDto",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      isRequired: {
        serializedName: "isRequired",
        type: {
          name: "Boolean"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      defaultValues: {
        serializedName: "defaultValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomDefaultExportDto"
            }
          }
        }
      },
      maxLength: {
        nullable: true,
        serializedName: "maxLength",
        type: {
          name: "Number"
        }
      },
      minLength: {
        nullable: true,
        serializedName: "minLength",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const CustomTextValueDto: msRest.CompositeMapper = {
  serializedName: "CustomTextValueDto",
  type: {
    name: "Composite",
    className: "CustomTextValueDto",
    modelProperties: {
      customValueId: {
        serializedName: "customValueId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      isInherited: {
        serializedName: "isInherited",
        type: {
          name: "Boolean"
        }
      },
      ownValue: {
        serializedName: "ownValue",
        type: {
          name: "String"
        }
      },
      inheritedValue: {
        serializedName: "inheritedValue",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CustomTimeDefaultDto: msRest.CompositeMapper = {
  serializedName: "CustomTimeDefaultDto",
  type: {
    name: "Composite",
    className: "CustomTimeDefaultDto",
    modelProperties: {
      customValueId: {
        serializedName: "customValueId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      customPropertyId: {
        nullable: true,
        serializedName: "customPropertyId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      value: {
        nullable: true,
        serializedName: "value",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const CustomTimeDefaultExportDto: msRest.CompositeMapper = {
  serializedName: "CustomTimeDefaultExportDto",
  type: {
    name: "Composite",
    className: "CustomTimeDefaultExportDto",
    modelProperties: {
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      value: {
        nullable: true,
        serializedName: "value",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const CustomTimePropertyDto: msRest.CompositeMapper = {
  serializedName: "CustomTimePropertyDto",
  type: {
    name: "Composite",
    className: "CustomTimePropertyDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      customPropertyId: {
        serializedName: "customPropertyId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      technicalName: {
        readOnly: true,
        serializedName: "technicalName",
        type: {
          name: "String"
        }
      },
      isRequired: {
        serializedName: "isRequired",
        type: {
          name: "Boolean"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      defaultValues: {
        serializedName: "defaultValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomDefaultDto"
            }
          }
        }
      },
      maxMinutes: {
        nullable: true,
        serializedName: "maxMinutes",
        type: {
          name: "Number"
        }
      },
      minMinutes: {
        nullable: true,
        serializedName: "minMinutes",
        type: {
          name: "Number"
        }
      },
      setMaxMinutes: {
        nullable: true,
        serializedName: "setMaxMinutes",
        type: {
          name: "Number"
        }
      },
      setMinMinutes: {
        nullable: true,
        serializedName: "setMinMinutes",
        type: {
          name: "Number"
        }
      },
      isRateable: {
        serializedName: "isRateable",
        type: {
          name: "Boolean"
        }
      },
      weight: {
        serializedName: "weight",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const CustomTimePropertyExportDto: msRest.CompositeMapper = {
  serializedName: "CustomTimePropertyExportDto",
  type: {
    name: "Composite",
    className: "CustomTimePropertyExportDto",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      isRequired: {
        serializedName: "isRequired",
        type: {
          name: "Boolean"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      defaultValues: {
        serializedName: "defaultValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomDefaultExportDto"
            }
          }
        }
      },
      maxMinutes: {
        nullable: true,
        serializedName: "maxMinutes",
        type: {
          name: "Number"
        }
      },
      minMinutes: {
        nullable: true,
        serializedName: "minMinutes",
        type: {
          name: "Number"
        }
      },
      setMaxMinutes: {
        nullable: true,
        serializedName: "setMaxMinutes",
        type: {
          name: "Number"
        }
      },
      setMinMinutes: {
        nullable: true,
        serializedName: "setMinMinutes",
        type: {
          name: "Number"
        }
      },
      isRateable: {
        serializedName: "isRateable",
        type: {
          name: "Boolean"
        }
      },
      weight: {
        serializedName: "weight",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const CustomTimeValueDto: msRest.CompositeMapper = {
  serializedName: "CustomTimeValueDto",
  type: {
    name: "Composite",
    className: "CustomTimeValueDto",
    modelProperties: {
      customValueId: {
        serializedName: "customValueId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      isInherited: {
        serializedName: "isInherited",
        type: {
          name: "Boolean"
        }
      },
      ownValue: {
        nullable: true,
        serializedName: "ownValue",
        type: {
          name: "Number"
        }
      },
      inheritedValue: {
        nullable: true,
        serializedName: "inheritedValue",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const PlannedTimeDto: msRest.CompositeMapper = {
  serializedName: "PlannedTimeDto",
  type: {
    name: "Composite",
    className: "PlannedTimeDto",
    modelProperties: {
      plannedTimeId: {
        serializedName: "plannedTimeId",
        type: {
          name: "Number"
        }
      },
      time: {
        serializedName: "time",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const DailyDateTimePatternDto: msRest.CompositeMapper = {
  serializedName: "DailyDateTimePatternDto",
  type: {
    name: "Composite",
    className: "DailyDateTimePatternDto",
    modelProperties: {
      dateTimePatternId: {
        serializedName: "dateTimePatternId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5"
          ]
        }
      },
      useNextOpeningDay: {
        serializedName: "useNextOpeningDay",
        type: {
          name: "Boolean"
        }
      },
      lastModified: {
        serializedName: "lastModified",
        type: {
          name: "String"
        }
      },
      plannedTimes: {
        serializedName: "plannedTimes",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PlannedTimeDto"
            }
          }
        }
      },
      startDate: {
        serializedName: "startDate",
        type: {
          name: "String"
        }
      },
      endDate: {
        serializedName: "endDate",
        type: {
          name: "String"
        }
      },
      useOpeningDays: {
        serializedName: "useOpeningDays",
        type: {
          name: "Boolean"
        }
      },
      monday: {
        serializedName: "monday",
        type: {
          name: "Boolean"
        }
      },
      tuesday: {
        serializedName: "tuesday",
        type: {
          name: "Boolean"
        }
      },
      wednesday: {
        serializedName: "wednesday",
        type: {
          name: "Boolean"
        }
      },
      thursday: {
        serializedName: "thursday",
        type: {
          name: "Boolean"
        }
      },
      friday: {
        serializedName: "friday",
        type: {
          name: "Boolean"
        }
      },
      saturday: {
        serializedName: "saturday",
        type: {
          name: "Boolean"
        }
      },
      sunday: {
        serializedName: "sunday",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const PlannedTimeV1Dto: msRest.CompositeMapper = {
  serializedName: "PlannedTimeV1Dto",
  type: {
    name: "Composite",
    className: "PlannedTimeV1Dto",
    modelProperties: {
      plannedTimeId: {
        serializedName: "plannedTimeId",
        type: {
          name: "Number"
        }
      },
      time: {
        serializedName: "time",
        type: {
          name: "String"
        }
      },
      datetimePatternId: {
        nullable: true,
        serializedName: "datetimePatternId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const DailyDateTimePatternV1Dto: msRest.CompositeMapper = {
  serializedName: "DailyDateTimePatternV1Dto",
  type: {
    name: "Composite",
    className: "DailyDateTimePatternV1Dto",
    modelProperties: {
      dateTimePatternId: {
        serializedName: "dateTimePatternId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5"
          ]
        }
      },
      useNextOpeningDay: {
        serializedName: "useNextOpeningDay",
        type: {
          name: "Boolean"
        }
      },
      lastModified: {
        serializedName: "lastModified",
        type: {
          name: "String"
        }
      },
      plannedTimes: {
        serializedName: "plannedTimes",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PlannedTimeV1Dto"
            }
          }
        }
      },
      startDate: {
        serializedName: "startDate",
        type: {
          name: "String"
        }
      },
      endDate: {
        serializedName: "endDate",
        type: {
          name: "String"
        }
      },
      useOpeningDays: {
        serializedName: "useOpeningDays",
        type: {
          name: "Boolean"
        }
      },
      monday: {
        serializedName: "monday",
        type: {
          name: "Boolean"
        }
      },
      tuesday: {
        serializedName: "tuesday",
        type: {
          name: "Boolean"
        }
      },
      wednesday: {
        serializedName: "wednesday",
        type: {
          name: "Boolean"
        }
      },
      thursday: {
        serializedName: "thursday",
        type: {
          name: "Boolean"
        }
      },
      friday: {
        serializedName: "friday",
        type: {
          name: "Boolean"
        }
      },
      saturday: {
        serializedName: "saturday",
        type: {
          name: "Boolean"
        }
      },
      sunday: {
        serializedName: "sunday",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const DashboardItemDto: msRest.CompositeMapper = {
  serializedName: "DashboardItemDto",
  type: {
    name: "Composite",
    className: "DashboardItemDto",
    modelProperties: {
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1"
          ]
        }
      }
    }
  }
};

export const DashboardDto: msRest.CompositeMapper = {
  serializedName: "DashboardDto",
  type: {
    name: "Composite",
    className: "DashboardDto",
    modelProperties: {
      dashboardId: {
        serializedName: "dashboardId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      items: {
        serializedName: "items",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "DashboardItemDto"
            }
          }
        }
      },
      locations: {
        serializedName: "locations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationSimpleDto"
            }
          }
        }
      },
      locationGroups: {
        serializedName: "locationGroups",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationGroupSimpleDto"
            }
          }
        }
      },
      contacts: {
        serializedName: "contacts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ContactSimpleDto"
            }
          }
        }
      },
      functions: {
        serializedName: "functions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FunctionSimpleDto"
            }
          }
        }
      }
    }
  }
};

export const DashboardListDto: msRest.CompositeMapper = {
  serializedName: "DashboardListDto",
  type: {
    name: "Composite",
    className: "DashboardListDto",
    modelProperties: {
      dashboardId: {
        serializedName: "dashboardId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const DataTableDto: msRest.CompositeMapper = {
  serializedName: "DataTableDto",
  type: {
    name: "Composite",
    className: "DataTableDto",
    modelProperties: {
      dataTableId: {
        serializedName: "dataTableId",
        type: {
          name: "Number"
        }
      },
      usage: {
        serializedName: "usage",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4"
          ]
        }
      },
      pageOperation: {
        serializedName: "pageOperation",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
            "31",
            "32",
            "211",
            "212",
            "213",
            "214",
            "215"
          ]
        }
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      columns: {
        serializedName: "columns",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "DataTableColumnDto"
            }
          }
        }
      },
      filters: {
        serializedName: "filters",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "DataTableFilterDto"
            }
          }
        }
      }
    }
  }
};

export const DashboardTableReferenceDto: msRest.CompositeMapper = {
  serializedName: "DashboardTableReferenceDto",
  type: {
    name: "Composite",
    className: "DashboardTableReferenceDto",
    modelProperties: {
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1"
          ]
        }
      },
      dashboardId: {
        serializedName: "dashboardId",
        type: {
          name: "Number"
        }
      },
      dataTableId: {
        serializedName: "dataTableId",
        type: {
          name: "Number"
        }
      },
      dataTable: {
        serializedName: "dataTable",
        type: {
          name: "Composite",
          className: "DataTableDto"
        }
      }
    }
  }
};

export const DataTableCustomColumnDto: msRest.CompositeMapper = {
  serializedName: "DataTableCustomColumnDto",
  type: {
    name: "Composite",
    className: "DataTableCustomColumnDto",
    modelProperties: {
      dataTableColumnId: {
        serializedName: "dataTableColumnId",
        type: {
          name: "Number"
        }
      },
      sortOrder: {
        serializedName: "sortOrder",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1"
          ]
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      customPropertyId: {
        nullable: true,
        serializedName: "customPropertyId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const DataTableCustomFilterDto: msRest.CompositeMapper = {
  serializedName: "DataTableCustomFilterDto",
  type: {
    name: "Composite",
    className: "DataTableCustomFilterDto",
    modelProperties: {
      dataTableFilterId: {
        serializedName: "dataTableFilterId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "-1"
          ]
        }
      },
      operator: {
        serializedName: "operator",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "-1"
          ]
        }
      },
      kind: {
        serializedName: "kind",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      },
      label: {
        serializedName: "label",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      customPropertyId: {
        nullable: true,
        serializedName: "customPropertyId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const DataTableGroupedFilterDto: msRest.CompositeMapper = {
  serializedName: "DataTableGroupedFilterDto",
  type: {
    name: "Composite",
    className: "DataTableGroupedFilterDto",
    modelProperties: {
      dataTableFilterId: {
        serializedName: "dataTableFilterId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "-1"
          ]
        }
      },
      operator: {
        serializedName: "operator",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "-1"
          ]
        }
      },
      kind: {
        serializedName: "kind",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      },
      label: {
        serializedName: "label",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      combinator: {
        serializedName: "combinator",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2"
          ]
        }
      },
      children: {
        serializedName: "children",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "DataTableFilterDto"
            }
          }
        }
      }
    }
  }
};

export const DataTableListDto: msRest.CompositeMapper = {
  serializedName: "DataTableListDto",
  type: {
    name: "Composite",
    className: "DataTableListDto",
    modelProperties: {
      dataTableId: {
        serializedName: "dataTableId",
        type: {
          name: "Number"
        }
      },
      usage: {
        serializedName: "usage",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4"
          ]
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      pageOperation: {
        serializedName: "pageOperation",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
            "31",
            "32",
            "211",
            "212",
            "213",
            "214",
            "215"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const DataTableSimpleDto: msRest.CompositeMapper = {
  serializedName: "DataTableSimpleDto",
  type: {
    name: "Composite",
    className: "DataTableSimpleDto",
    modelProperties: {
      dataTableId: {
        serializedName: "dataTableId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const DataTableTransferColumnDto: msRest.CompositeMapper = {
  serializedName: "DataTableTransferColumnDto",
  type: {
    name: "Composite",
    className: "DataTableTransferColumnDto",
    modelProperties: {
      dataTableColumnId: {
        serializedName: "dataTableColumnId",
        type: {
          name: "Number"
        }
      },
      sortOrder: {
        serializedName: "sortOrder",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1"
          ]
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      property: {
        serializedName: "property",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const DataTableTransferFilterDto: msRest.CompositeMapper = {
  serializedName: "DataTableTransferFilterDto",
  type: {
    name: "Composite",
    className: "DataTableTransferFilterDto",
    modelProperties: {
      dataTableFilterId: {
        serializedName: "dataTableFilterId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "-1"
          ]
        }
      },
      operator: {
        serializedName: "operator",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "-1"
          ]
        }
      },
      kind: {
        serializedName: "kind",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      },
      label: {
        serializedName: "label",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      property: {
        serializedName: "property",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const DateFormDefaultDto: msRest.CompositeMapper = {
  serializedName: "DateFormDefaultDto",
  type: {
    name: "Composite",
    className: "DateFormDefaultDto",
    modelProperties: {
      fieldValueId: {
        serializedName: "fieldValueId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12"
          ]
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const DateFormFieldDto: msRest.CompositeMapper = {
  serializedName: "DateFormFieldDto",
  type: {
    name: "Composite",
    className: "DateFormFieldDto",
    modelProperties: {
      formFieldId: {
        serializedName: "formFieldId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      technicalName: {
        readOnly: true,
        serializedName: "technicalName",
        type: {
          name: "String"
        }
      },
      width: {
        serializedName: "width",
        type: {
          name: "Number"
        }
      },
      isRequired: {
        serializedName: "isRequired",
        type: {
          name: "Boolean"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12"
          ]
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      defaultValues: {
        serializedName: "defaultValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FormDefaultDto"
            }
          }
        }
      },
      localFieldId: {
        serializedName: "localFieldId",
        type: {
          name: "String"
        }
      },
      maxValue: {
        serializedName: "maxValue",
        type: {
          name: "String"
        }
      },
      minValue: {
        serializedName: "minValue",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const DateFormValueDto: msRest.CompositeMapper = {
  serializedName: "DateFormValueDto",
  type: {
    name: "Composite",
    className: "DateFormValueDto",
    modelProperties: {
      fieldValueId: {
        serializedName: "fieldValueId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12"
          ]
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const DateTimePatternDto: msRest.CompositeMapper = {
  serializedName: "DateTimePatternDto",
  type: {
    name: "Composite",
    className: "DateTimePatternDto",
    modelProperties: {
      dateTimePatternId: {
        serializedName: "dateTimePatternId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5"
          ]
        }
      },
      useNextOpeningDay: {
        serializedName: "useNextOpeningDay",
        type: {
          name: "Boolean"
        }
      },
      lastModified: {
        serializedName: "lastModified",
        type: {
          name: "String"
        }
      },
      plannedTimes: {
        serializedName: "plannedTimes",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PlannedTimeDto"
            }
          }
        }
      }
    }
  }
};

export const DateTimePatternV1Dto: msRest.CompositeMapper = {
  serializedName: "DateTimePatternV1Dto",
  type: {
    name: "Composite",
    className: "DateTimePatternV1Dto",
    modelProperties: {
      dateTimePatternId: {
        serializedName: "dateTimePatternId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5"
          ]
        }
      },
      useNextOpeningDay: {
        serializedName: "useNextOpeningDay",
        type: {
          name: "Boolean"
        }
      },
      lastModified: {
        serializedName: "lastModified",
        type: {
          name: "String"
        }
      },
      plannedTimes: {
        serializedName: "plannedTimes",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PlannedTimeV1Dto"
            }
          }
        }
      }
    }
  }
};

export const DeactivateResourceTaskQueueDto: msRest.CompositeMapper = {
  serializedName: "DeactivateResourceTaskQueueDto",
  type: {
    name: "Composite",
    className: "DeactivateResourceTaskQueueDto",
    modelProperties: {
      taskId: {
        serializedName: "taskId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const DeclineEditorRequestDto: msRest.CompositeMapper = {
  serializedName: "DeclineEditorRequestDto",
  type: {
    name: "Composite",
    className: "DeclineEditorRequestDto",
    modelProperties: {
      contactId: {
        serializedName: "contactId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const PermissionAreaDto: msRest.CompositeMapper = {
  serializedName: "PermissionAreaDto",
  type: {
    name: "Composite",
    className: "PermissionAreaDto",
    modelProperties: {
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "8"
          ]
        }
      },
      permissions: {
        serializedName: "permissions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Enum",
              allowedValues: [
                "10",
                "11",
                "12",
                "13",
                "14",
                "15",
                "16",
                "17",
                "20",
                "21",
                "22",
                "23",
                "40",
                "41",
                "42",
                "43",
                "50",
                "51",
                "52",
                "53",
                "60",
                "61",
                "62",
                "63",
                "70",
                "71",
                "80",
                "81",
                "82",
                "83",
                "90",
                "91",
                "92",
                "93",
                "100",
                "101",
                "102",
                "103",
                "104",
                "105",
                "106",
                "107",
                "110",
                "111",
                "112",
                "113",
                "120",
                "121",
                "123",
                "124",
                "130",
                "131",
                "132",
                "133",
                "140",
                "141",
                "142",
                "143",
                "150",
                "160",
                "161",
                "162",
                "163",
                "164",
                "170",
                "171",
                "172",
                "173",
                "190",
                "191",
                "192",
                "193",
                "200",
                "201",
                "202",
                "203",
                "204",
                "210",
                "211",
                "212",
                "213",
                "230",
                "231",
                "232",
                "233",
                "240",
                "241",
                "242",
                "243",
                "250",
                "251",
                "252",
                "253",
                "254",
                "260",
                "261",
                "262",
                "263",
                "270",
                "271",
                "272",
                "273",
                "274",
                "275",
                "280",
                "290",
                "291",
                "292",
                "293",
                "300",
                "301",
                "302",
                "303",
                "310",
                "311",
                "312",
                "313",
                "320",
                "321",
                "322",
                "323",
                "330",
                "331",
                "332",
                "333",
                "340",
                "341",
                "342",
                "343",
                "350",
                "351",
                "352",
                "353",
                "360",
                "361",
                "362",
                "363",
                "370",
                "371",
                "372",
                "373",
                "380",
                "381",
                "382",
                "383",
                "390",
                "391",
                "392",
                "393",
                "400",
                "401",
                "402",
                "403",
                "410",
                "411",
                "412",
                "413",
                "420",
                "421",
                "422",
                "423",
                "430",
                "431",
                "432",
                "433",
                "440",
                "441",
                "442",
                "443",
                "450",
                "451",
                "452",
                "453",
                "460",
                "461",
                "462",
                "463",
                "470",
                "471",
                "472",
                "473",
                "474",
                "480",
                "481",
                "482",
                "483",
                "490",
                "491",
                "492",
                "493",
                "500",
                "501",
                "502",
                "503",
                "520",
                "530",
                "531",
                "532",
                "533",
                "535",
                "536",
                "540",
                "550",
                "580",
                "581",
                "582",
                "583",
                "590",
                "591",
                "592",
                "593",
                "600",
                "601",
                "602",
                "603",
                "610",
                "611",
                "612",
                "613",
                "620",
                "621",
                "622",
                "623",
                "630",
                "631",
                "632",
                "633",
                "640",
                "641",
                "642",
                "643",
                "650",
                "651",
                "652",
                "653",
                "660",
                "661",
                "662",
                "663",
                "670",
                "671",
                "672",
                "673",
                "680",
                "681",
                "682",
                "683",
                "684",
                "690",
                "691",
                "692",
                "693",
                "700",
                "701",
                "702",
                "703",
                "704",
                "710",
                "711",
                "712",
                "713",
                "720",
                "721",
                "722",
                "723",
                "730",
                "731",
                "732",
                "733",
                "740",
                "741",
                "742",
                "743",
                "750",
                "751",
                "752",
                "753",
                "790",
                "791",
                "792",
                "793",
                "800",
                "801",
                "802",
                "803",
                "810",
                "811",
                "812",
                "813",
                "820",
                "830",
                "831",
                "832",
                "833",
                "834",
                "835",
                "836",
                "838",
                "839",
                "840",
                "841",
                "842",
                "850",
                "851",
                "852",
                "853",
                "860",
                "861",
                "862",
                "863",
                "870",
                "871",
                "872",
                "873",
                "880",
                "881",
                "882",
                "883",
                "890",
                "891",
                "892",
                "893",
                "900",
                "901",
                "902",
                "903",
                "904",
                "910",
                "911",
                "912",
                "913",
                "920",
                "921",
                "922",
                "923",
                "930",
                "931",
                "932",
                "933",
                "940",
                "941",
                "942",
                "943",
                "950",
                "951",
                "952",
                "953",
                "960",
                "961",
                "962",
                "963",
                "970",
                "971",
                "972",
                "973",
                "980",
                "981",
                "982",
                "983",
                "990",
                "991",
                "992",
                "993",
                "1000",
                "1001",
                "1002",
                "1003",
                "1010",
                "1011",
                "1012",
                "1013",
                "1020",
                "1021",
                "1022",
                "1023",
                "1030",
                "1031",
                "1032",
                "1033",
                "1040",
                "1041",
                "1042",
                "1043",
                "1050",
                "1051",
                "1052",
                "1053",
                "1060",
                "1061",
                "1062",
                "1063",
                "1070",
                "1071",
                "1072",
                "1073",
                "1080",
                "1081",
                "1082",
                "1083",
                "1090",
                "1091",
                "1092",
                "1093",
                "1100",
                "1101",
                "1102",
                "1103",
                "1110",
                "1111",
                "1112",
                "1113",
                "1114",
                "1120",
                "1121",
                "1122",
                "1123",
                "1130",
                "1131",
                "1132",
                "1133",
                "1134",
                "1135",
                "1136",
                "1137",
                "1140",
                "1141",
                "1142",
                "1143",
                "1150",
                "1160",
                "1170",
                "1171",
                "1172",
                "1173",
                "1180",
                "1181",
                "1182",
                "1183",
                "1190",
                "1191",
                "1192",
                "1193",
                "1200",
                "1201",
                "1202",
                "1203",
                "1211",
                "1212",
                "1213",
                "1214",
                "1221",
                "1222",
                "1223",
                "1224",
                "1225",
                "1226",
                "1227",
                "1228",
                "1229",
                "1230",
                "1231",
                "1232",
                "1233",
                "1234",
                "1235",
                "1236",
                "1251",
                "1252",
                "1253",
                "1254",
                "1261",
                "1262",
                "1263",
                "1264",
                "1271",
                "1272",
                "1273",
                "1274",
                "1311",
                "1312",
                "1313",
                "1314",
                "1321",
                "1322",
                "1323",
                "1324",
                "1331",
                "1332",
                "1333",
                "1334",
                "1341",
                "1342",
                "1343",
                "1344",
                "1351",
                "1352",
                "1353",
                "1354",
                "1361",
                "1362",
                "1363",
                "1364",
                "1371",
                "1372",
                "1373",
                "1374",
                "1381",
                "1382",
                "1383",
                "1384",
                "1391",
                "1392",
                "1393",
                "1394",
                "1400",
                "1410",
                "1412",
                "1413",
                "1414",
                "1420",
                "1422",
                "1423",
                "1424",
                "1430",
                "1431",
                "1432",
                "1433",
                "1440",
                "1441",
                "1442",
                "1443",
                "1450",
                "1451",
                "1452",
                "1453",
                "1460",
                "1461",
                "1462",
                "1463",
                "1470",
                "1471",
                "1472",
                "1473",
                "1474",
                "1480",
                "1481",
                "1482",
                "1483",
                "1490",
                "1491",
                "1492",
                "1493",
                "1500",
                "1501",
                "1502",
                "1503",
                "1510",
                "1511",
                "1512",
                "1513",
                "1520",
                "1521",
                "1531",
                "1532",
                "1533",
                "1534",
                "1535",
                "1541",
                "1542",
                "1543",
                "1544",
                "1545",
                "1546",
                "1550",
                "1551",
                "1552",
                "1553",
                "1554",
                "1560",
                "1561",
                "1562",
                "1563",
                "1564",
                "1565",
                "1566",
                "10000",
                "10100",
                "10101",
                "10102",
                "10103",
                "10104",
                "10105",
                "10106",
                "10107",
                "10108",
                "10109",
                "10110",
                "10111",
                "10112",
                "10113",
                "10114",
                "10115",
                "10116",
                "10117",
                "10118",
                "10119",
                "10120",
                "10121",
                "10122",
                "10123",
                "10124",
                "10125",
                "10126",
                "10128",
                "10129",
                "10130",
                "10131",
                "10132",
                "10133",
                "10134",
                "10135",
                "10136",
                "10137",
                "10138",
                "10139",
                "10140",
                "10141",
                "10142",
                "10143",
                "10144",
                "10201",
                "10202",
                "10203",
                "10204",
                "10205",
                "10206",
                "10207",
                "10301",
                "10302",
                "10303",
                "10304",
                "10311",
                "10312",
                "10313",
                "10314",
                "10510",
                "10511",
                "10512",
                "10513",
                "10520",
                "10521",
                "10522",
                "10523",
                "10530",
                "10531",
                "10532",
                "10533",
                "10540",
                "10541",
                "10542",
                "10543",
                "10550",
                "10551",
                "10552",
                "10553",
                "10554",
                "10555",
                "10561",
                "10562",
                "10563",
                "10564",
                "10570",
                "10571",
                "10572",
                "10573",
                "10574",
                "10580",
                "10590",
                "10591",
                "10601",
                "10602",
                "10603",
                "10604",
                "10605",
                "10606",
                "10607",
                "10608",
                "10610",
                "10611",
                "10612",
                "10613",
                "10620",
                "10621",
                "10622",
                "10623",
                "10624",
                "10630",
                "10631",
                "10632",
                "10633",
                "10634",
                "10640",
                "10641",
                "10642",
                "10643",
                "10644",
                "10646",
                "10647",
                "10648",
                "10649",
                "10650",
                "10651",
                "10652",
                "10653",
                "10660",
                "10661",
                "10662",
                "10663",
                "10664",
                "10665",
                "10666",
                "10667",
                "10668",
                "10669",
                "10670",
                "10671",
                "10672",
                "10673",
                "10680",
                "10681",
                "10682",
                "10683",
                "10710",
                "10711",
                "10720",
                "10721",
                "10723",
                "10724",
                "10730",
                "10731",
                "10733",
                "10734",
                "10740",
                "10741",
                "10742",
                "10743",
                "10750",
                "10751",
                "10752",
                "10753",
                "10760",
                "10761",
                "10762",
                "10763",
                "10770",
                "10771",
                "10772",
                "10773",
                "103321",
                "103322",
                "103323",
                "103324",
                "106245"
              ]
            }
          }
        }
      },
      contexts: {
        serializedName: "contexts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AllowedContextDto"
            }
          }
        }
      }
    }
  }
};

export const DecodedTokenDto: msRest.CompositeMapper = {
  serializedName: "DecodedTokenDto",
  type: {
    name: "Composite",
    className: "DecodedTokenDto",
    modelProperties: {
      permissions: {
        serializedName: "permissions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PermissionAreaDto"
            }
          }
        }
      }
    }
  }
};

export const DeletedAreaAssignmentQueueDto: msRest.CompositeMapper = {
  serializedName: "DeletedAreaAssignmentQueueDto",
  type: {
    name: "Composite",
    className: "DeletedAreaAssignmentQueueDto",
    modelProperties: {
      contactId: {
        serializedName: "contactId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const DeletedEntityQueueDto: msRest.CompositeMapper = {
  serializedName: "DeletedEntityQueueDto",
  type: {
    name: "Composite",
    className: "DeletedEntityQueueDto",
    modelProperties: {
      entityId: {
        serializedName: "entityId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const DeletedLocationGroupLocationQueueDto: msRest.CompositeMapper = {
  serializedName: "DeletedLocationGroupLocationQueueDto",
  type: {
    name: "Composite",
    className: "DeletedLocationGroupLocationQueueDto",
    modelProperties: {
      locationGroupId: {
        serializedName: "locationGroupId",
        type: {
          name: "Number"
        }
      },
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      scopeId: {
        readOnly: true,
        serializedName: "scopeId",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const DeviceActionNotificationDto: msRest.CompositeMapper = {
  serializedName: "DeviceActionNotificationDto",
  type: {
    name: "Composite",
    className: "DeviceActionNotificationDto",
    modelProperties: {
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      message: {
        serializedName: "message",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "5"
          ]
        }
      },
      action: {
        serializedName: "action",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3"
          ]
        }
      }
    }
  }
};

export const DeviceCodeChallengeDto: msRest.CompositeMapper = {
  serializedName: "DeviceCodeChallengeDto",
  type: {
    name: "Composite",
    className: "DeviceCodeChallengeDto",
    modelProperties: {
      loginChallengeId: {
        serializedName: "loginChallengeId",
        type: {
          name: "Number"
        }
      },
      pin: {
        serializedName: "pin",
        type: {
          name: "String"
        }
      },
      expiryDate: {
        serializedName: "expiryDate",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const DeviceDto: msRest.CompositeMapper = {
  serializedName: "DeviceDto",
  type: {
    name: "Composite",
    className: "DeviceDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      isRegisteredForPush: {
        serializedName: "isRegisteredForPush",
        type: {
          name: "Boolean"
        }
      },
      platform: {
        serializedName: "platform",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2"
          ]
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      },
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Number"
        }
      },
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "LocationSimpleDto"
        }
      },
      challenges: {
        serializedName: "challenges",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "DeviceCodeChallengeDto"
            }
          }
        }
      }
    }
  }
};

export const DeviceListDto: msRest.CompositeMapper = {
  serializedName: "DeviceListDto",
  type: {
    name: "Composite",
    className: "DeviceListDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      platform: {
        serializedName: "platform",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2"
          ]
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      hardwareId: {
        serializedName: "hardwareId",
        type: {
          name: "String"
        }
      },
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Number"
        }
      },
      locationName: {
        readOnly: true,
        serializedName: "locationName",
        type: {
          name: "String"
        }
      },
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "LocationSimpleListDto"
        }
      }
    }
  }
};

export const DeviceMetaEntryDto: msRest.CompositeMapper = {
  serializedName: "DeviceMetaEntryDto",
  type: {
    name: "Composite",
    className: "DeviceMetaEntryDto",
    modelProperties: {
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "1"
          ]
        }
      },
      appVersion: {
        serializedName: "appVersion",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const DeviceMetaListDto: msRest.CompositeMapper = {
  serializedName: "DeviceMetaListDto",
  type: {
    name: "Composite",
    className: "DeviceMetaListDto",
    modelProperties: {
      deviceMetadataId: {
        serializedName: "deviceMetadataId",
        type: {
          name: "Number"
        }
      },
      version: {
        serializedName: "version",
        type: {
          name: "String"
        }
      },
      lastModified: {
        serializedName: "lastModified",
        type: {
          name: "String"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const FormInDocumentTaskDto: msRest.CompositeMapper = {
  serializedName: "FormInDocumentTaskDto",
  type: {
    name: "Composite",
    className: "FormInDocumentTaskDto",
    modelProperties: {
      formId: {
        serializedName: "formId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const DocumentChildTaskDto: msRest.CompositeMapper = {
  serializedName: "DocumentChildTaskDto",
  type: {
    name: "Composite",
    className: "DocumentChildTaskDto",
    modelProperties: {
      taskId: {
        serializedName: "taskId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4"
          ]
        }
      },
      impacts: {
        serializedName: "impacts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ImpactDto"
            }
          }
        }
      },
      documentTemplate: {
        serializedName: "documentTemplate",
        type: {
          name: "Composite",
          className: "DocumentTemplateSimpleDto"
        }
      },
      form: {
        serializedName: "form",
        type: {
          name: "Composite",
          className: "FormInDocumentTaskDto"
        }
      },
      requiresAuthentication: {
        serializedName: "requiresAuthentication",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const DocumentChildTaskSimpleDto: msRest.CompositeMapper = {
  serializedName: "DocumentChildTaskSimpleDto",
  type: {
    name: "Composite",
    className: "DocumentChildTaskSimpleDto",
    modelProperties: {
      taskId: {
        serializedName: "taskId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4"
          ]
        }
      },
      form: {
        serializedName: "form",
        type: {
          name: "Composite",
          className: "FormInDocumentTaskDto"
        }
      }
    }
  }
};

export const DocumentRequestDto: msRest.CompositeMapper = {
  serializedName: "DocumentRequestDto",
  type: {
    name: "Composite",
    className: "DocumentRequestDto",
    modelProperties: {
      path: {
        serializedName: "path",
        type: {
          name: "String"
        }
      },
      domain: {
        serializedName: "domain",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const DocumentSearchRequestDto: msRest.CompositeMapper = {
  serializedName: "DocumentSearchRequestDto",
  type: {
    name: "Composite",
    className: "DocumentSearchRequestDto",
    modelProperties: {
      path: {
        serializedName: "path",
        type: {
          name: "String"
        }
      },
      searchString: {
        serializedName: "searchString",
        type: {
          name: "String"
        }
      },
      domain: {
        serializedName: "domain",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const DocumentTaskCreateV1Dto: msRest.CompositeMapper = {
  serializedName: "DocumentTaskCreateV1Dto",
  type: {
    name: "Composite",
    className: "DocumentTaskCreateV1Dto",
    modelProperties: {
      taskId: {
        serializedName: "taskId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      requiresAuthentication: {
        serializedName: "requiresAuthentication",
        type: {
          name: "Boolean"
        }
      },
      generatePdf: {
        serializedName: "generatePdf",
        type: {
          name: "Boolean"
        }
      },
      generateCsv: {
        serializedName: "generateCsv",
        type: {
          name: "Boolean"
        }
      },
      isEnabled: {
        serializedName: "isEnabled",
        type: {
          name: "Boolean"
        }
      },
      reasonSetId: {
        nullable: true,
        serializedName: "reasonSetId",
        type: {
          name: "Number"
        }
      },
      executionPlan: {
        serializedName: "executionPlan",
        type: {
          name: "Composite",
          className: "ExecutionPlanV1Dto"
        }
      },
      locations: {
        serializedName: "locations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationTaskV1Dto"
            }
          }
        }
      },
      locationGroups: {
        serializedName: "locationGroups",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationGroupTaskV1Dto"
            }
          }
        }
      },
      impacts: {
        serializedName: "impacts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ImpactCreateV1Dto"
            }
          }
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      formId: {
        serializedName: "formId",
        type: {
          name: "Number"
        }
      },
      parentId: {
        nullable: true,
        serializedName: "parentId",
        type: {
          name: "Number"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const DocumentTaskDto: msRest.CompositeMapper = {
  serializedName: "DocumentTaskDto",
  type: {
    name: "Composite",
    className: "DocumentTaskDto",
    modelProperties: {
      taskId: {
        serializedName: "taskId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4"
          ]
        }
      },
      impacts: {
        serializedName: "impacts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ImpactDto"
            }
          }
        }
      },
      documentTemplate: {
        serializedName: "documentTemplate",
        type: {
          name: "Composite",
          className: "DocumentTemplateSimpleDto"
        }
      },
      generatePdf: {
        serializedName: "generatePdf",
        type: {
          name: "Boolean"
        }
      },
      generateCsv: {
        serializedName: "generateCsv",
        type: {
          name: "Boolean"
        }
      },
      isActive: {
        serializedName: "isActive",
        type: {
          name: "Boolean"
        }
      },
      reasonSet: {
        serializedName: "reasonSet",
        type: {
          name: "Composite",
          className: "TaskJobStateReasonSetSimpleDto"
        }
      },
      executionPlan: {
        serializedName: "executionPlan",
        type: {
          name: "Composite",
          className: "ExecutionPlanDto"
        }
      },
      locations: {
        serializedName: "locations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationSimpleDto"
            }
          }
        }
      },
      locationGroups: {
        serializedName: "locationGroups",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationGroupSimpleDto"
            }
          }
        }
      },
      categories: {
        serializedName: "categories",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CategorySimpleDto"
            }
          }
        }
      },
      form: {
        serializedName: "form",
        type: {
          name: "Composite",
          className: "FormInDocumentTaskDto"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      requiresAuthentication: {
        serializedName: "requiresAuthentication",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const DocumentTaskListV1Dto: msRest.CompositeMapper = {
  serializedName: "DocumentTaskListV1Dto",
  type: {
    name: "Composite",
    className: "DocumentTaskListV1Dto",
    modelProperties: {
      taskId: {
        serializedName: "taskId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      isEnabled: {
        serializedName: "isEnabled",
        type: {
          name: "Boolean"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      parentId: {
        nullable: true,
        serializedName: "parentId",
        type: {
          name: "Number"
        }
      },
      executionPlan: {
        serializedName: "executionPlan",
        type: {
          name: "Composite",
          className: "ExecutionPlanViewV1Dto"
        }
      },
      executionPlanId: {
        nullable: true,
        serializedName: "executionPlanId",
        type: {
          name: "Number"
        }
      },
      requiresAuthentication: {
        serializedName: "requiresAuthentication",
        type: {
          name: "Boolean"
        }
      },
      reasonSetId: {
        nullable: true,
        serializedName: "reasonSetId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const DocumentTaskSimpleV1Dto: msRest.CompositeMapper = {
  serializedName: "DocumentTaskSimpleV1Dto",
  type: {
    name: "Composite",
    className: "DocumentTaskSimpleV1Dto",
    modelProperties: {
      taskId: {
        serializedName: "taskId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      requiresAuthentication: {
        serializedName: "requiresAuthentication",
        type: {
          name: "Boolean"
        }
      },
      formId: {
        serializedName: "formId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const DocumentTaskV1Dto: msRest.CompositeMapper = {
  serializedName: "DocumentTaskV1Dto",
  type: {
    name: "Composite",
    className: "DocumentTaskV1Dto",
    modelProperties: {
      parentId: {
        nullable: true,
        serializedName: "parentId",
        type: {
          name: "Number"
        }
      },
      taskId: {
        serializedName: "taskId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      requiresAuthentication: {
        serializedName: "requiresAuthentication",
        type: {
          name: "Boolean"
        }
      },
      reasonSet: {
        serializedName: "reasonSet",
        type: {
          name: "Composite",
          className: "TaskJobStateReasonSetSimpleDto"
        }
      },
      generatePdf: {
        serializedName: "generatePdf",
        type: {
          name: "Boolean"
        }
      },
      generateCsv: {
        serializedName: "generateCsv",
        type: {
          name: "Boolean"
        }
      },
      executionPlan: {
        serializedName: "executionPlan",
        type: {
          name: "Composite",
          className: "ExecutionPlanViewV1Dto"
        }
      },
      locations: {
        serializedName: "locations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationRelationDto"
            }
          }
        }
      },
      locationGroups: {
        serializedName: "locationGroups",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationGroupRelationDto"
            }
          }
        }
      },
      isEnabled: {
        readOnly: true,
        serializedName: "isEnabled",
        type: {
          name: "Boolean"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      impacts: {
        serializedName: "impacts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ImpactV1Dto"
            }
          }
        }
      },
      form: {
        serializedName: "form",
        type: {
          name: "Composite",
          className: "FormSimpleDto"
        }
      },
      formId: {
        nullable: true,
        serializedName: "formId",
        type: {
          name: "Number"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const DocumentTemplateDto: msRest.CompositeMapper = {
  serializedName: "DocumentTemplateDto",
  type: {
    name: "Composite",
    className: "DocumentTemplateDto",
    modelProperties: {
      documentTemplateId: {
        serializedName: "documentTemplateId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      template: {
        serializedName: "template",
        type: {
          name: "Composite",
          className: "FileDto"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      isDefault: {
        serializedName: "isDefault",
        type: {
          name: "Boolean"
        }
      },
      culture: {
        serializedName: "culture",
        type: {
          name: "String"
        }
      },
      contextType: {
        serializedName: "contextType",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18"
          ]
        }
      }
    }
  }
};

export const EmbeddedFileExportDto: msRest.CompositeMapper = {
  serializedName: "EmbeddedFileExportDto",
  type: {
    name: "Composite",
    className: "EmbeddedFileExportDto",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      contentType: {
        serializedName: "contentType",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
            "31",
            "32",
            "33",
            "34",
            "35",
            "36",
            "37",
            "38",
            "39",
            "40",
            "41",
            "42",
            "43",
            "44",
            "45",
            "46",
            "47",
            "48",
            "49",
            "50",
            "51",
            "52",
            "53",
            "54",
            "55",
            "56",
            "57",
            "58",
            "59",
            "60",
            "61",
            "62",
            "63",
            "64",
            "65",
            "66",
            "67",
            "68",
            "69",
            "70",
            "71",
            "72",
            "73",
            "74",
            "75",
            "76",
            "77",
            "78",
            "79",
            "80",
            "81",
            "82",
            "83",
            "84",
            "85",
            "86",
            "87",
            "88",
            "89",
            "90",
            "91",
            "92",
            "93",
            "94",
            "95",
            "96",
            "97",
            "98",
            "99",
            "100",
            "101",
            "102",
            "103",
            "104",
            "105",
            "106",
            "107",
            "108",
            "109",
            "110",
            "111",
            "112",
            "113",
            "114",
            "115",
            "116",
            "117",
            "118",
            "119",
            "120",
            "121",
            "122",
            "123",
            "124",
            "125",
            "126",
            "127",
            "128",
            "129",
            "130",
            "131",
            "132",
            "133",
            "134",
            "135",
            "136",
            "137",
            "138",
            "139",
            "140",
            "141",
            "142",
            "143",
            "144",
            "145",
            "146",
            "147",
            "148",
            "149",
            "150",
            "151",
            "152",
            "153",
            "154",
            "155",
            "156",
            "157",
            "158",
            "159",
            "160",
            "161",
            "162",
            "163",
            "164",
            "165",
            "166",
            "167",
            "168",
            "169",
            "170",
            "171",
            "172",
            "173",
            "174",
            "175",
            "176",
            "177",
            "178",
            "179",
            "180",
            "181",
            "182",
            "183",
            "184",
            "185",
            "186",
            "187",
            "188",
            "189",
            "190",
            "191",
            "192",
            "193",
            "194",
            "195",
            "196",
            "197",
            "198",
            "199",
            "200",
            "201",
            "202",
            "203",
            "204",
            "205",
            "206",
            "207",
            "208",
            "209",
            "210",
            "211",
            "212",
            "213",
            "214",
            "215",
            "216",
            "217",
            "218",
            "219",
            "220",
            "221",
            "222",
            "223",
            "224",
            "225",
            "226",
            "227",
            "228",
            "229",
            "230",
            "231",
            "232",
            "233",
            "234",
            "235",
            "236",
            "237",
            "238",
            "239",
            "240",
            "241",
            "242",
            "243",
            "244",
            "245",
            "246",
            "247",
            "248",
            "249",
            "250",
            "251",
            "252",
            "253",
            "254",
            "255",
            "256",
            "257",
            "258",
            "259",
            "260",
            "261",
            "262",
            "263",
            "264",
            "265",
            "266",
            "267",
            "268",
            "269",
            "270",
            "271",
            "272",
            "273",
            "274",
            "275",
            "276",
            "277",
            "278",
            "279",
            "280",
            "281",
            "282",
            "283",
            "284",
            "285",
            "286",
            "287",
            "288",
            "289",
            "290",
            "291",
            "292",
            "293",
            "294",
            "295",
            "296",
            "297",
            "298",
            "299",
            "300",
            "301",
            "302",
            "303",
            "304",
            "305",
            "306",
            "307",
            "308",
            "309",
            "310",
            "311",
            "312",
            "313",
            "314",
            "315",
            "316",
            "317",
            "318",
            "319",
            "320",
            "321",
            "322",
            "323",
            "324",
            "325",
            "326",
            "327",
            "328",
            "329",
            "330",
            "331",
            "332",
            "333",
            "334",
            "335",
            "336",
            "337",
            "338",
            "339",
            "340",
            "341",
            "342",
            "343",
            "344",
            "345",
            "346",
            "347",
            "348",
            "349",
            "350",
            "351",
            "352",
            "353",
            "354",
            "355",
            "356",
            "357",
            "358",
            "359",
            "360",
            "361",
            "362",
            "363",
            "364",
            "365",
            "366",
            "367",
            "368",
            "369",
            "370",
            "371",
            "372",
            "373",
            "374",
            "375",
            "376",
            "377",
            "378",
            "379",
            "380",
            "381",
            "382",
            "383",
            "384",
            "385",
            "386",
            "387",
            "388",
            "389",
            "390",
            "391",
            "392",
            "393",
            "394",
            "395",
            "396",
            "397",
            "398",
            "399",
            "400",
            "401",
            "402",
            "403",
            "404",
            "405",
            "406",
            "407",
            "408",
            "409",
            "410",
            "411",
            "412",
            "413",
            "414",
            "415",
            "416",
            "417",
            "418",
            "419",
            "420",
            "421",
            "422",
            "423",
            "424",
            "425",
            "426",
            "427",
            "428",
            "429",
            "430",
            "431",
            "432",
            "433",
            "434",
            "435",
            "436",
            "437",
            "438",
            "439",
            "440",
            "441",
            "442",
            "443",
            "444",
            "445",
            "446",
            "447",
            "448",
            "449",
            "450",
            "451",
            "452",
            "453",
            "454",
            "455",
            "456",
            "457",
            "458",
            "459",
            "460",
            "461",
            "462",
            "463",
            "464",
            "465",
            "466",
            "467",
            "468",
            "469",
            "470",
            "471",
            "472",
            "473",
            "474",
            "475",
            "476",
            "477",
            "478",
            "479",
            "480",
            "481",
            "482",
            "483",
            "484",
            "485",
            "486",
            "487",
            "488",
            "489",
            "490",
            "491",
            "492",
            "493",
            "494",
            "495",
            "496",
            "497",
            "498",
            "499",
            "500",
            "501",
            "502",
            "503",
            "504",
            "505",
            "506",
            "507",
            "508",
            "509",
            "510",
            "511",
            "512",
            "513",
            "514",
            "515",
            "516",
            "517",
            "518",
            "519",
            "520",
            "521",
            "522",
            "523",
            "524",
            "525",
            "526",
            "527",
            "528",
            "529",
            "530",
            "531",
            "532",
            "533",
            "534",
            "535",
            "536",
            "537",
            "538",
            "539",
            "540",
            "541",
            "542",
            "543",
            "544",
            "545",
            "546",
            "547",
            "548",
            "549",
            "550",
            "551",
            "552",
            "553",
            "554",
            "555",
            "556",
            "557",
            "558",
            "559",
            "560",
            "561",
            "562",
            "563",
            "564",
            "565",
            "566",
            "567",
            "568",
            "569",
            "570",
            "571",
            "572",
            "573",
            "574",
            "575",
            "576",
            "577",
            "578",
            "579",
            "580",
            "581",
            "582",
            "583",
            "584",
            "585",
            "586",
            "587",
            "588",
            "589",
            "590",
            "591",
            "592",
            "593",
            "594",
            "595",
            "596",
            "597",
            "598",
            "599",
            "600",
            "601",
            "602",
            "603",
            "604",
            "605",
            "606",
            "607",
            "608",
            "609",
            "610",
            "611",
            "612",
            "613",
            "614",
            "615",
            "616",
            "617",
            "618",
            "619",
            "620",
            "621",
            "622",
            "623",
            "624",
            "625",
            "626",
            "627",
            "628",
            "629",
            "630",
            "631",
            "632",
            "633",
            "634",
            "635",
            "636",
            "637",
            "638",
            "639",
            "640",
            "641",
            "642",
            "643",
            "644",
            "645",
            "646",
            "647",
            "648",
            "649",
            "650",
            "651",
            "652",
            "653",
            "654",
            "655",
            "656",
            "657",
            "658",
            "659",
            "660",
            "661",
            "662",
            "663",
            "664",
            "665",
            "666",
            "667",
            "668",
            "669",
            "670",
            "671",
            "672",
            "673",
            "674",
            "675",
            "676",
            "677",
            "678",
            "679",
            "680",
            "681",
            "682",
            "683",
            "684",
            "685",
            "686",
            "687",
            "688",
            "689",
            "690",
            "691",
            "692",
            "693",
            "694",
            "695",
            "696",
            "697",
            "698",
            "699",
            "700",
            "701",
            "702",
            "703",
            "704",
            "705",
            "706",
            "707",
            "708",
            "709",
            "710",
            "711",
            "712",
            "713",
            "714",
            "715",
            "716",
            "717",
            "718",
            "719",
            "720",
            "721",
            "722",
            "723",
            "724",
            "725",
            "726",
            "727",
            "728",
            "729",
            "730",
            "731",
            "732",
            "733",
            "734",
            "735",
            "736",
            "737",
            "738",
            "739",
            "740",
            "741",
            "742",
            "743",
            "744",
            "745",
            "746",
            "747",
            "748",
            "749",
            "750",
            "751",
            "752",
            "753",
            "754",
            "755",
            "756",
            "757",
            "758",
            "759",
            "760",
            "761",
            "762",
            "763",
            "764",
            "765",
            "766",
            "767",
            "768",
            "769",
            "770",
            "771",
            "772",
            "773",
            "774",
            "775",
            "776",
            "777",
            "778",
            "779",
            "780",
            "781",
            "782",
            "783",
            "784",
            "785",
            "786",
            "787",
            "788",
            "789",
            "790",
            "791",
            "792",
            "793",
            "794",
            "795",
            "796",
            "797",
            "798",
            "799",
            "800",
            "801",
            "802",
            "803",
            "804",
            "805",
            "806",
            "807",
            "808",
            "809",
            "810",
            "811",
            "812",
            "813",
            "814",
            "815",
            "816",
            "817",
            "818",
            "819",
            "820",
            "821",
            "822",
            "823",
            "824",
            "825",
            "826",
            "827",
            "828",
            "829",
            "830",
            "831",
            "832",
            "833",
            "834",
            "835",
            "836",
            "837",
            "838",
            "839",
            "840",
            "841",
            "842",
            "843",
            "844",
            "845",
            "846",
            "847",
            "848",
            "849",
            "850",
            "851",
            "852",
            "853",
            "854",
            "855",
            "856",
            "857",
            "858",
            "859",
            "860",
            "861",
            "862",
            "863",
            "864",
            "865",
            "866",
            "867",
            "868",
            "869",
            "870",
            "871",
            "872",
            "873",
            "874",
            "875",
            "876",
            "877",
            "878",
            "879",
            "880",
            "881",
            "882",
            "883",
            "884",
            "885",
            "886",
            "887",
            "888",
            "889",
            "890",
            "891",
            "892",
            "893",
            "894",
            "895",
            "896",
            "897",
            "898",
            "899",
            "900",
            "901",
            "902",
            "903",
            "904",
            "905",
            "906",
            "907",
            "908",
            "909",
            "910",
            "911",
            "912",
            "913",
            "914",
            "915",
            "916",
            "917",
            "918",
            "919",
            "920",
            "921",
            "922",
            "923",
            "924",
            "925",
            "926",
            "927",
            "928",
            "929",
            "930",
            "931",
            "932",
            "933",
            "934",
            "935",
            "936",
            "937",
            "938",
            "939",
            "940",
            "941",
            "942",
            "943",
            "944",
            "945",
            "946",
            "947",
            "948",
            "949",
            "950",
            "951",
            "952",
            "953",
            "954",
            "955",
            "956",
            "957",
            "958",
            "959",
            "960",
            "961",
            "962",
            "963",
            "964",
            "965",
            "966",
            "967",
            "968",
            "969",
            "970",
            "971",
            "972",
            "973",
            "974",
            "975",
            "976",
            "977",
            "978",
            "979",
            "980",
            "981",
            "982",
            "983",
            "984",
            "985",
            "986",
            "987"
          ]
        }
      },
      content: {
        serializedName: "content",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const DocumentTemplateExportDto: msRest.CompositeMapper = {
  serializedName: "DocumentTemplateExportDto",
  type: {
    name: "Composite",
    className: "DocumentTemplateExportDto",
    modelProperties: {
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      isDefault: {
        serializedName: "isDefault",
        type: {
          name: "Boolean"
        }
      },
      contextType: {
        serializedName: "contextType",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18"
          ]
        }
      },
      culture: {
        serializedName: "culture",
        type: {
          name: "String"
        }
      },
      template: {
        serializedName: "template",
        type: {
          name: "Composite",
          className: "EmbeddedFileExportDto"
        }
      }
    }
  }
};

export const DocumentTemplateListDto: msRest.CompositeMapper = {
  serializedName: "DocumentTemplateListDto",
  type: {
    name: "Composite",
    className: "DocumentTemplateListDto",
    modelProperties: {
      documentTemplateId: {
        serializedName: "documentTemplateId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      contextType: {
        serializedName: "contextType",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18"
          ]
        }
      },
      isDefault: {
        serializedName: "isDefault",
        type: {
          name: "Boolean"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const DocumentTemplateSimpleExportDto: msRest.CompositeMapper = {
  serializedName: "DocumentTemplateSimpleExportDto",
  type: {
    name: "Composite",
    className: "DocumentTemplateSimpleExportDto",
    modelProperties: {
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      contextType: {
        serializedName: "contextType",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18"
          ]
        }
      }
    }
  }
};

export const DropDownFormDefaultDto: msRest.CompositeMapper = {
  serializedName: "DropDownFormDefaultDto",
  type: {
    name: "Composite",
    className: "DropDownFormDefaultDto",
    modelProperties: {
      fieldValueId: {
        serializedName: "fieldValueId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12"
          ]
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      },
      rating: {
        serializedName: "rating",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      isSelected: {
        nullable: true,
        serializedName: "isSelected",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const DropDownFormFieldDto: msRest.CompositeMapper = {
  serializedName: "DropDownFormFieldDto",
  type: {
    name: "Composite",
    className: "DropDownFormFieldDto",
    modelProperties: {
      formFieldId: {
        serializedName: "formFieldId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      technicalName: {
        readOnly: true,
        serializedName: "technicalName",
        type: {
          name: "String"
        }
      },
      width: {
        serializedName: "width",
        type: {
          name: "Number"
        }
      },
      isRequired: {
        serializedName: "isRequired",
        type: {
          name: "Boolean"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12"
          ]
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      defaultValues: {
        serializedName: "defaultValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FormDefaultDto"
            }
          }
        }
      },
      localFieldId: {
        serializedName: "localFieldId",
        type: {
          name: "String"
        }
      },
      isRateable: {
        serializedName: "isRateable",
        type: {
          name: "Boolean"
        }
      },
      style: {
        serializedName: "style",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1"
          ]
        }
      }
    }
  }
};

export const DropDownFormValueDto: msRest.CompositeMapper = {
  serializedName: "DropDownFormValueDto",
  type: {
    name: "Composite",
    className: "DropDownFormValueDto",
    modelProperties: {
      fieldValueId: {
        serializedName: "fieldValueId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12"
          ]
        }
      },
      value: {
        nullable: true,
        serializedName: "value",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const DropDownFormValueForCompleteDto: msRest.CompositeMapper = {
  serializedName: "DropDownFormValueForCompleteDto",
  type: {
    name: "Composite",
    className: "DropDownFormValueForCompleteDto",
    modelProperties: {
      fieldValueId: {
        serializedName: "fieldValueId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12"
          ]
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const DynamicFieldDto: msRest.CompositeMapper = {
  serializedName: "DynamicFieldDto",
  type: {
    name: "Composite",
    className: "DynamicFieldDto",
    modelProperties: {
      customPropertySets: {
        serializedName: "customPropertySets",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomSetReferenceDto"
            }
          }
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      }
    }
  }
};

export const EditorDto: msRest.CompositeMapper = {
  serializedName: "EditorDto",
  type: {
    name: "Composite",
    className: "EditorDto",
    modelProperties: {
      contactId: {
        serializedName: "contactId",
        type: {
          name: "Number"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      phone: {
        serializedName: "phone",
        type: {
          name: "String"
        }
      },
      mobile: {
        serializedName: "mobile",
        type: {
          name: "String"
        }
      },
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      },
      userId: {
        serializedName: "userId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const EditorRequestDto: msRest.CompositeMapper = {
  serializedName: "EditorRequestDto",
  type: {
    name: "Composite",
    className: "EditorRequestDto",
    modelProperties: {
      contact: {
        serializedName: "contact",
        type: {
          name: "Composite",
          className: "ContactSimpleDto"
        }
      },
      completeAt: {
        serializedName: "completeAt",
        type: {
          name: "String"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      }
    }
  }
};

export const EditorQuestDto: msRest.CompositeMapper = {
  serializedName: "EditorQuestDto",
  type: {
    name: "Composite",
    className: "EditorQuestDto",
    modelProperties: {
      functionProperty: {
        serializedName: "function",
        type: {
          name: "Composite",
          className: "FunctionSimpleDto"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "5"
          ]
        }
      },
      requests: {
        serializedName: "requests",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EditorRequestDto"
            }
          }
        }
      },
      lastModified: {
        serializedName: "lastModified",
        type: {
          name: "String"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "String"
        }
      },
      creator: {
        serializedName: "creator",
        type: {
          name: "Composite",
          className: "CreatedByDto"
        }
      }
    }
  }
};

export const EditorSuggestionBaseDto: msRest.CompositeMapper = {
  serializedName: "EditorSuggestionBaseDto",
  type: {
    name: "Composite",
    className: "EditorSuggestionBaseDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      target: {
        serializedName: "target",
        type: {
          name: "Composite",
          className: "EditorSuggestionTargetDto"
        }
      }
    }
  }
};

export const EditorSuggestionByContactDto: msRest.CompositeMapper = {
  serializedName: "EditorSuggestionByContactDto",
  type: {
    name: "Composite",
    className: "EditorSuggestionByContactDto",
    modelProperties: {
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      },
      processingTime: {
        serializedName: "processingTime",
        type: {
          name: "String"
        }
      },
      contact: {
        serializedName: "contact",
        type: {
          name: "Composite",
          className: "ContactSimpleDto"
        }
      }
    }
  }
};

export const EditorSuggestionByFunctionDto: msRest.CompositeMapper = {
  serializedName: "EditorSuggestionByFunctionDto",
  type: {
    name: "Composite",
    className: "EditorSuggestionByFunctionDto",
    modelProperties: {
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      },
      processingTime: {
        serializedName: "processingTime",
        type: {
          name: "String"
        }
      },
      functionProperty: {
        serializedName: "function",
        type: {
          name: "Composite",
          className: "FunctionSimpleDto"
        }
      }
    }
  }
};

export const EmailAddressDto: msRest.CompositeMapper = {
  serializedName: "EmailAddressDto",
  type: {
    name: "Composite",
    className: "EmailAddressDto",
    modelProperties: {
      address: {
        serializedName: "address",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const EmailButtonTemplateDto: msRest.CompositeMapper = {
  serializedName: "EmailButtonTemplateDto",
  type: {
    name: "Composite",
    className: "EmailButtonTemplateDto",
    modelProperties: {
      text: {
        serializedName: "text",
        type: {
          name: "String"
        }
      },
      url: {
        serializedName: "url",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const EmailChatMessageDto: msRest.CompositeMapper = {
  serializedName: "EmailChatMessageDto",
  type: {
    name: "Composite",
    className: "EmailChatMessageDto",
    modelProperties: {
      message: {
        serializedName: "message",
        type: {
          name: "String"
        }
      },
      tenantName: {
        serializedName: "tenantName",
        type: {
          name: "String"
        }
      },
      domain: {
        serializedName: "domain",
        type: {
          name: "String"
        }
      },
      senderName: {
        serializedName: "senderName",
        type: {
          name: "String"
        }
      },
      subject: {
        serializedName: "subject",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const EmailFailedMailDeliveryDto: msRest.CompositeMapper = {
  serializedName: "EmailFailedMailDeliveryDto",
  type: {
    name: "Composite",
    className: "EmailFailedMailDeliveryDto",
    modelProperties: {
      reason: {
        serializedName: "reason",
        type: {
          name: "String"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      receiver: {
        serializedName: "receiver",
        type: {
          name: "String"
        }
      },
      tenantName: {
        serializedName: "tenantName",
        type: {
          name: "String"
        }
      },
      domain: {
        serializedName: "domain",
        type: {
          name: "String"
        }
      },
      senderName: {
        serializedName: "senderName",
        type: {
          name: "String"
        }
      },
      subject: {
        serializedName: "subject",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const EmailHitNotificationRuleDto: msRest.CompositeMapper = {
  serializedName: "EmailHitNotificationRuleDto",
  type: {
    name: "Composite",
    className: "EmailHitNotificationRuleDto",
    modelProperties: {
      entityLink: {
        serializedName: "entityLink",
        type: {
          name: "String"
        }
      },
      entityName: {
        serializedName: "entityName",
        type: {
          name: "String"
        }
      },
      body: {
        serializedName: "body",
        type: {
          name: "String"
        }
      },
      tenantName: {
        serializedName: "tenantName",
        type: {
          name: "String"
        }
      },
      domain: {
        serializedName: "domain",
        type: {
          name: "String"
        }
      },
      senderName: {
        serializedName: "senderName",
        type: {
          name: "String"
        }
      },
      subject: {
        serializedName: "subject",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const EmailImportMessageDto: msRest.CompositeMapper = {
  serializedName: "EmailImportMessageDto",
  type: {
    name: "Composite",
    className: "EmailImportMessageDto",
    modelProperties: {
      message: {
        serializedName: "message",
        type: {
          name: "String"
        }
      },
      level: {
        serializedName: "level",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      }
    }
  }
};

export const EmailImportDto: msRest.CompositeMapper = {
  serializedName: "EmailImportDto",
  type: {
    name: "Composite",
    className: "EmailImportDto",
    modelProperties: {
      message: {
        serializedName: "message",
        type: {
          name: "String"
        }
      },
      tenantName: {
        serializedName: "tenantName",
        type: {
          name: "String"
        }
      },
      domain: {
        serializedName: "domain",
        type: {
          name: "String"
        }
      },
      senderName: {
        serializedName: "senderName",
        type: {
          name: "String"
        }
      },
      subject: {
        serializedName: "subject",
        type: {
          name: "String"
        }
      },
      messages: {
        serializedName: "messages",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EmailImportMessageDto"
            }
          }
        }
      },
      messagesAsTemplate: {
        serializedName: "messagesAsTemplate",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const IssueReceiverDto: msRest.CompositeMapper = {
  serializedName: "IssueReceiverDto",
  type: {
    name: "Composite",
    className: "IssueReceiverDto",
    modelProperties: {
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      }
    }
  }
};

export const TemplateSimpleDto: msRest.CompositeMapper = {
  serializedName: "TemplateSimpleDto",
  type: {
    name: "Composite",
    className: "TemplateSimpleDto",
    modelProperties: {
      templateId: {
        serializedName: "templateId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      context: {
        serializedName: "context",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "181",
            "182",
            "183",
            "184",
            "191",
            "192",
            "193",
            "194"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const EmailIssueNotificationDto: msRest.CompositeMapper = {
  serializedName: "EmailIssueNotificationDto",
  type: {
    name: "Composite",
    className: "EmailIssueNotificationDto",
    modelProperties: {
      issueNotificationId: {
        serializedName: "issueNotificationId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      receiver: {
        serializedName: "receiver",
        type: {
          name: "Composite",
          className: "IssueReceiverDto"
        }
      },
      emailSubjectTemplate: {
        serializedName: "emailSubjectTemplate",
        type: {
          name: "Composite",
          className: "TemplateSimpleDto"
        }
      },
      emailContentTemplate: {
        serializedName: "emailContentTemplate",
        type: {
          name: "Composite",
          className: "TemplateSimpleDto"
        }
      },
      attachments: {
        serializedName: "attachments",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "DocumentTemplateSimpleDto"
            }
          }
        }
      }
    }
  }
};

export const EmailIssueReceiverDto: msRest.CompositeMapper = {
  serializedName: "EmailIssueReceiverDto",
  type: {
    name: "Composite",
    className: "EmailIssueReceiverDto",
    modelProperties: {
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const EmailMissionActivationMailDto: msRest.CompositeMapper = {
  serializedName: "EmailMissionActivationMailDto",
  type: {
    name: "Composite",
    className: "EmailMissionActivationMailDto",
    modelProperties: {
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      tenantName: {
        serializedName: "tenantName",
        type: {
          name: "String"
        }
      },
      domain: {
        serializedName: "domain",
        type: {
          name: "String"
        }
      },
      senderName: {
        serializedName: "senderName",
        type: {
          name: "String"
        }
      },
      subject: {
        serializedName: "subject",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const EmailNotificationTargetDto: msRest.CompositeMapper = {
  serializedName: "EmailNotificationTargetDto",
  type: {
    name: "Composite",
    className: "EmailNotificationTargetDto",
    modelProperties: {
      notificationTargetId: {
        serializedName: "notificationTargetId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const EmailReportDataTableMailDto: msRest.CompositeMapper = {
  serializedName: "EmailReportDataTableMailDto",
  type: {
    name: "Composite",
    className: "EmailReportDataTableMailDto",
    modelProperties: {
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      tenantName: {
        serializedName: "tenantName",
        type: {
          name: "String"
        }
      },
      domain: {
        serializedName: "domain",
        type: {
          name: "String"
        }
      },
      senderName: {
        serializedName: "senderName",
        type: {
          name: "String"
        }
      },
      subject: {
        serializedName: "subject",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const EmailResetPasswordDto: msRest.CompositeMapper = {
  serializedName: "EmailResetPasswordDto",
  type: {
    name: "Composite",
    className: "EmailResetPasswordDto",
    modelProperties: {
      message: {
        serializedName: "message",
        type: {
          name: "String"
        }
      },
      tenantName: {
        serializedName: "tenantName",
        type: {
          name: "String"
        }
      },
      domain: {
        serializedName: "domain",
        type: {
          name: "String"
        }
      },
      senderName: {
        serializedName: "senderName",
        type: {
          name: "String"
        }
      },
      subject: {
        serializedName: "subject",
        type: {
          name: "String"
        }
      },
      resetButtonHtml: {
        serializedName: "resetButtonHtml",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const EmailSolutionNoteMailDto: msRest.CompositeMapper = {
  serializedName: "EmailSolutionNoteMailDto",
  type: {
    name: "Composite",
    className: "EmailSolutionNoteMailDto",
    modelProperties: {
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      tenantName: {
        serializedName: "tenantName",
        type: {
          name: "String"
        }
      },
      domain: {
        serializedName: "domain",
        type: {
          name: "String"
        }
      },
      senderName: {
        serializedName: "senderName",
        type: {
          name: "String"
        }
      },
      subject: {
        serializedName: "subject",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const EmailTaskJobChangedStatusAlertDto: msRest.CompositeMapper = {
  serializedName: "EmailTaskJobChangedStatusAlertDto",
  type: {
    name: "Composite",
    className: "EmailTaskJobChangedStatusAlertDto",
    modelProperties: {
      tenantName: {
        serializedName: "tenantName",
        type: {
          name: "String"
        }
      },
      domain: {
        serializedName: "domain",
        type: {
          name: "String"
        }
      },
      senderName: {
        serializedName: "senderName",
        type: {
          name: "String"
        }
      },
      subject: {
        serializedName: "subject",
        type: {
          name: "String"
        }
      },
      locationName: {
        serializedName: "locationName",
        type: {
          name: "String"
        }
      },
      taskName: {
        serializedName: "taskName",
        type: {
          name: "String"
        }
      },
      targetState: {
        serializedName: "targetState",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const EmailTaskJobPercentageDto: msRest.CompositeMapper = {
  serializedName: "EmailTaskJobPercentageDto",
  type: {
    name: "Composite",
    className: "EmailTaskJobPercentageDto",
    modelProperties: {
      tenantName: {
        serializedName: "tenantName",
        type: {
          name: "String"
        }
      },
      domain: {
        serializedName: "domain",
        type: {
          name: "String"
        }
      },
      senderName: {
        serializedName: "senderName",
        type: {
          name: "String"
        }
      },
      subject: {
        serializedName: "subject",
        type: {
          name: "String"
        }
      },
      locationName: {
        serializedName: "locationName",
        type: {
          name: "String"
        }
      },
      taskName: {
        serializedName: "taskName",
        type: {
          name: "String"
        }
      },
      percentage: {
        serializedName: "percentage",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const EmailTaskJobTimeAlertDto: msRest.CompositeMapper = {
  serializedName: "EmailTaskJobTimeAlertDto",
  type: {
    name: "Composite",
    className: "EmailTaskJobTimeAlertDto",
    modelProperties: {
      tenantName: {
        serializedName: "tenantName",
        type: {
          name: "String"
        }
      },
      domain: {
        serializedName: "domain",
        type: {
          name: "String"
        }
      },
      senderName: {
        serializedName: "senderName",
        type: {
          name: "String"
        }
      },
      subject: {
        serializedName: "subject",
        type: {
          name: "String"
        }
      },
      locationName: {
        serializedName: "locationName",
        type: {
          name: "String"
        }
      },
      taskName: {
        serializedName: "taskName",
        type: {
          name: "String"
        }
      },
      minutes: {
        serializedName: "minutes",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const EmailTemplateDto: msRest.CompositeMapper = {
  serializedName: "EmailTemplateDto",
  type: {
    name: "Composite",
    className: "EmailTemplateDto",
    modelProperties: {
      content: {
        serializedName: "content",
        type: {
          name: "String"
        }
      },
      tenantName: {
        serializedName: "tenantName",
        type: {
          name: "String"
        }
      },
      domain: {
        serializedName: "domain",
        type: {
          name: "String"
        }
      },
      senderName: {
        serializedName: "senderName",
        type: {
          name: "String"
        }
      },
      subject: {
        serializedName: "subject",
        type: {
          name: "String"
        }
      },
      preview: {
        serializedName: "preview",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const EmailTextLineTemplateDto: msRest.CompositeMapper = {
  serializedName: "EmailTextLineTemplateDto",
  type: {
    name: "Composite",
    className: "EmailTextLineTemplateDto",
    modelProperties: {
      text: {
        serializedName: "text",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const EmbeddedPowerBiDto: msRest.CompositeMapper = {
  serializedName: "EmbeddedPowerBiDto",
  type: {
    name: "Composite",
    className: "EmbeddedPowerBiDto",
    modelProperties: {
      reportId: {
        serializedName: "reportId",
        type: {
          name: "String"
        }
      },
      reportName: {
        serializedName: "reportName",
        type: {
          name: "String"
        }
      },
      url: {
        serializedName: "url",
        type: {
          name: "String"
        }
      },
      token: {
        serializedName: "token",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const EmptyContextDto: msRest.CompositeMapper = {
  serializedName: "EmptyContextDto",
  type: {
    name: "Composite",
    className: "EmptyContextDto",
    modelProperties: {
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "181",
            "182",
            "183",
            "184",
            "191",
            "192",
            "193",
            "194"
          ]
        }
      }
    }
  }
};

export const EntityIdContextDto: msRest.CompositeMapper = {
  serializedName: "EntityIdContextDto",
  type: {
    name: "Composite",
    className: "EntityIdContextDto",
    modelProperties: {
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "181",
            "182",
            "183",
            "184",
            "191",
            "192",
            "193",
            "194"
          ]
        }
      },
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ErrorCodeDto: msRest.CompositeMapper = {
  serializedName: "ErrorCodeDto",
  type: {
    name: "Composite",
    className: "ErrorCodeDto",
    modelProperties: {
      errorCodeId: {
        serializedName: "errorCodeId",
        type: {
          name: "Number"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      isVisible: {
        serializedName: "isVisible",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const ErrorCodeListDto: msRest.CompositeMapper = {
  serializedName: "ErrorCodeListDto",
  type: {
    name: "Composite",
    className: "ErrorCodeListDto",
    modelProperties: {
      errorCodeId: {
        serializedName: "errorCodeId",
        type: {
          name: "Number"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ErrorCodeSimpleListDto: msRest.CompositeMapper = {
  serializedName: "ErrorCodeSimpleListDto",
  type: {
    name: "Composite",
    className: "ErrorCodeSimpleListDto",
    modelProperties: {
      errorCodeId: {
        serializedName: "errorCodeId",
        type: {
          name: "Number"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ErrorDescriptionDto: msRest.CompositeMapper = {
  serializedName: "ErrorDescriptionDto",
  type: {
    name: "Composite",
    className: "ErrorDescriptionDto",
    modelProperties: {
      code: {
        serializedName: "code",
        type: {
          name: "String"
        }
      },
      member: {
        serializedName: "member",
        type: {
          name: "String"
        }
      },
      parameters: {
        serializedName: "parameters",
        type: {
          name: "Dictionary",
          value: {
            type: {
              name: "String"
            }
          }
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ErrorResultDto: msRest.CompositeMapper = {
  serializedName: "ErrorResultDto",
  type: {
    name: "Composite",
    className: "ErrorResultDto",
    modelProperties: {
      traceId: {
        serializedName: "traceId",
        type: {
          name: "String"
        }
      },
      errors: {
        serializedName: "errors",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ErrorDescriptionDto"
            }
          }
        }
      }
    }
  }
};

export const EscalationLevelDto: msRest.CompositeMapper = {
  serializedName: "EscalationLevelDto",
  type: {
    name: "Composite",
    className: "EscalationLevelDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      escalationLevelId: {
        serializedName: "escalationLevelId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const EscalationLevelListDto: msRest.CompositeMapper = {
  serializedName: "EscalationLevelListDto",
  type: {
    name: "Composite",
    className: "EscalationLevelListDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      escalationLevelId: {
        serializedName: "escalationLevelId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const EscalationLevelSimpleDto: msRest.CompositeMapper = {
  serializedName: "EscalationLevelSimpleDto",
  type: {
    name: "Composite",
    className: "EscalationLevelSimpleDto",
    modelProperties: {
      escalationLevelId: {
        serializedName: "escalationLevelId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const EscalationLevelSimpleListDto: msRest.CompositeMapper = {
  serializedName: "EscalationLevelSimpleListDto",
  type: {
    name: "Composite",
    className: "EscalationLevelSimpleListDto",
    modelProperties: {
      escalationLevelId: {
        serializedName: "escalationLevelId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const EscalationLevelTtm: msRest.CompositeMapper = {
  serializedName: "EscalationLevelTtm",
  type: {
    name: "Composite",
    className: "EscalationLevelTtm",
    modelProperties: {
      escalationLevelId: {
        serializedName: "escalationLevelId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const EscalationRuleDto: msRest.CompositeMapper = {
  serializedName: "EscalationRuleDto",
  type: {
    name: "Composite",
    className: "EscalationRuleDto",
    modelProperties: {
      escalationRuleId: {
        serializedName: "escalationRuleId",
        type: {
          name: "Number"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      },
      isInherited: {
        serializedName: "isInherited",
        type: {
          name: "Boolean"
        }
      },
      isOffsetInherited: {
        serializedName: "isOffsetInherited",
        type: {
          name: "Boolean"
        }
      },
      ownOffset: {
        serializedName: "ownOffset",
        type: {
          name: "String"
        }
      },
      inheritedOffset: {
        serializedName: "inheritedOffset",
        type: {
          name: "String"
        }
      },
      levelId: {
        nullable: true,
        serializedName: "levelId",
        type: {
          name: "Number"
        }
      },
      level: {
        serializedName: "level",
        type: {
          name: "Composite",
          className: "EscalationLevelSimpleDto"
        }
      },
      priorityId: {
        nullable: true,
        serializedName: "priorityId",
        type: {
          name: "Number"
        }
      },
      priority: {
        serializedName: "priority",
        type: {
          name: "Composite",
          className: "PrioritySimpleDto"
        }
      },
      stateId: {
        nullable: true,
        serializedName: "stateId",
        type: {
          name: "Number"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "Composite",
          className: "StateSimpleDto"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const PrioritySimpleListDto: msRest.CompositeMapper = {
  serializedName: "PrioritySimpleListDto",
  type: {
    name: "Composite",
    className: "PrioritySimpleListDto",
    modelProperties: {
      priorityId: {
        serializedName: "priorityId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const EscalationRulesInEventListDto: msRest.CompositeMapper = {
  serializedName: "EscalationRulesInEventListDto",
  type: {
    name: "Composite",
    className: "EscalationRulesInEventListDto",
    modelProperties: {
      escalationRuleId: {
        serializedName: "escalationRuleId",
        type: {
          name: "Number"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      },
      isOffsetInherited: {
        serializedName: "isOffsetInherited",
        type: {
          name: "Boolean"
        }
      },
      ownOffset: {
        serializedName: "ownOffset",
        type: {
          name: "String"
        }
      },
      inheritedOffset: {
        serializedName: "inheritedOffset",
        type: {
          name: "String"
        }
      },
      levelId: {
        nullable: true,
        serializedName: "levelId",
        type: {
          name: "Number"
        }
      },
      level: {
        serializedName: "level",
        type: {
          name: "Composite",
          className: "EscalationLevelSimpleListDto"
        }
      },
      priorityId: {
        nullable: true,
        serializedName: "priorityId",
        type: {
          name: "Number"
        }
      },
      priority: {
        serializedName: "priority",
        type: {
          name: "Composite",
          className: "PrioritySimpleListDto"
        }
      },
      stateId: {
        nullable: true,
        serializedName: "stateId",
        type: {
          name: "Number"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "Composite",
          className: "StateSimpleListDto"
        }
      }
    }
  }
};

export const ExecuteableTaskDto: msRest.CompositeMapper = {
  serializedName: "ExecuteableTaskDto",
  type: {
    name: "Composite",
    className: "ExecuteableTaskDto",
    modelProperties: {
      taskId: {
        serializedName: "taskId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4"
          ]
        }
      },
      impacts: {
        serializedName: "impacts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ImpactDto"
            }
          }
        }
      },
      documentTemplate: {
        serializedName: "documentTemplate",
        type: {
          name: "Composite",
          className: "DocumentTemplateSimpleDto"
        }
      },
      generatePdf: {
        serializedName: "generatePdf",
        type: {
          name: "Boolean"
        }
      },
      generateCsv: {
        serializedName: "generateCsv",
        type: {
          name: "Boolean"
        }
      },
      isActive: {
        serializedName: "isActive",
        type: {
          name: "Boolean"
        }
      },
      reasonSet: {
        serializedName: "reasonSet",
        type: {
          name: "Composite",
          className: "TaskJobStateReasonSetSimpleDto"
        }
      },
      executionPlan: {
        serializedName: "executionPlan",
        type: {
          name: "Composite",
          className: "ExecutionPlanDto"
        }
      },
      locations: {
        serializedName: "locations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationSimpleDto"
            }
          }
        }
      },
      locationGroups: {
        serializedName: "locationGroups",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationGroupSimpleDto"
            }
          }
        }
      },
      categories: {
        serializedName: "categories",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CategorySimpleDto"
            }
          }
        }
      }
    }
  }
};

export const ExecutionPlanCompleteListDto: msRest.CompositeMapper = {
  serializedName: "ExecutionPlanCompleteListDto",
  type: {
    name: "Composite",
    className: "ExecutionPlanCompleteListDto",
    modelProperties: {
      executionId: {
        serializedName: "executionId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "4"
          ]
        }
      }
    }
  }
};

export const ExportDashboardItemDto: msRest.CompositeMapper = {
  serializedName: "ExportDashboardItemDto",
  type: {
    name: "Composite",
    className: "ExportDashboardItemDto",
    modelProperties: {
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1"
          ]
        }
      }
    }
  }
};

export const LocationExportSimpleDto: msRest.CompositeMapper = {
  serializedName: "LocationExportSimpleDto",
  type: {
    name: "Composite",
    className: "LocationExportSimpleDto",
    modelProperties: {
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const LocationGroupExportSimpleDto: msRest.CompositeMapper = {
  serializedName: "LocationGroupExportSimpleDto",
  type: {
    name: "Composite",
    className: "LocationGroupExportSimpleDto",
    modelProperties: {
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const FunctionExportSimpleDto: msRest.CompositeMapper = {
  serializedName: "FunctionExportSimpleDto",
  type: {
    name: "Composite",
    className: "FunctionExportSimpleDto",
    modelProperties: {
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ExportDashboardDto: msRest.CompositeMapper = {
  serializedName: "ExportDashboardDto",
  type: {
    name: "Composite",
    className: "ExportDashboardDto",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      items: {
        serializedName: "items",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ExportDashboardItemDto"
            }
          }
        }
      },
      locations: {
        serializedName: "locations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationExportSimpleDto"
            }
          }
        }
      },
      locationGroups: {
        serializedName: "locationGroups",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationGroupExportSimpleDto"
            }
          }
        }
      },
      functions: {
        serializedName: "functions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FunctionExportSimpleDto"
            }
          }
        }
      }
    }
  }
};

export const ExportDataTableColumnDto: msRest.CompositeMapper = {
  serializedName: "ExportDataTableColumnDto",
  type: {
    name: "Composite",
    className: "ExportDataTableColumnDto",
    modelProperties: {
      sortOrder: {
        serializedName: "sortOrder",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1"
          ]
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      }
    }
  }
};

export const ExportDataTableCustomColumnDto: msRest.CompositeMapper = {
  serializedName: "ExportDataTableCustomColumnDto",
  type: {
    name: "Composite",
    className: "ExportDataTableCustomColumnDto",
    modelProperties: {
      sortOrder: {
        serializedName: "sortOrder",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1"
          ]
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      },
      customProperty: {
        serializedName: "customProperty",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ExportDataTableCustomFilterDto: msRest.CompositeMapper = {
  serializedName: "ExportDataTableCustomFilterDto",
  type: {
    name: "Composite",
    className: "ExportDataTableCustomFilterDto",
    modelProperties: {
      label: {
        serializedName: "label",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "-1"
          ]
        }
      },
      operator: {
        serializedName: "operator",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "-1"
          ]
        }
      },
      kind: {
        serializedName: "kind",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      },
      customProperty: {
        serializedName: "customProperty",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ExportDataTableFilterDto: msRest.CompositeMapper = {
  serializedName: "ExportDataTableFilterDto",
  type: {
    name: "Composite",
    className: "ExportDataTableFilterDto",
    modelProperties: {
      label: {
        serializedName: "label",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "-1"
          ]
        }
      },
      operator: {
        serializedName: "operator",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "-1"
          ]
        }
      },
      kind: {
        serializedName: "kind",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ExportDataTableDto: msRest.CompositeMapper = {
  serializedName: "ExportDataTableDto",
  type: {
    name: "Composite",
    className: "ExportDataTableDto",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      pageOperation: {
        serializedName: "pageOperation",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
            "31",
            "32",
            "211",
            "212",
            "213",
            "214",
            "215"
          ]
        }
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      columns: {
        serializedName: "columns",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ExportDataTableColumnDto"
            }
          }
        }
      },
      filters: {
        serializedName: "filters",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ExportDataTableFilterDto"
            }
          }
        }
      }
    }
  }
};

export const ExportDataTableDashboardReferenceDto: msRest.CompositeMapper = {
  serializedName: "ExportDataTableDashboardReferenceDto",
  type: {
    name: "Composite",
    className: "ExportDataTableDashboardReferenceDto",
    modelProperties: {
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1"
          ]
        }
      },
      dataTable: {
        serializedName: "dataTable",
        type: {
          name: "Composite",
          className: "ExportDataTableDto"
        }
      }
    }
  }
};

export const ExportDataTableGroupedFilterDto: msRest.CompositeMapper = {
  serializedName: "ExportDataTableGroupedFilterDto",
  type: {
    name: "Composite",
    className: "ExportDataTableGroupedFilterDto",
    modelProperties: {
      label: {
        serializedName: "label",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "-1"
          ]
        }
      },
      operator: {
        serializedName: "operator",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "-1"
          ]
        }
      },
      kind: {
        serializedName: "kind",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      },
      combinator: {
        serializedName: "combinator",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2"
          ]
        }
      },
      children: {
        serializedName: "children",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ExportDataTableFilterDto"
            }
          }
        }
      }
    }
  }
};

export const ExportDataTableTransferColumnDto: msRest.CompositeMapper = {
  serializedName: "ExportDataTableTransferColumnDto",
  type: {
    name: "Composite",
    className: "ExportDataTableTransferColumnDto",
    modelProperties: {
      sortOrder: {
        serializedName: "sortOrder",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1"
          ]
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      },
      property: {
        serializedName: "property",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ExportDataTableTransferFilterDto: msRest.CompositeMapper = {
  serializedName: "ExportDataTableTransferFilterDto",
  type: {
    name: "Composite",
    className: "ExportDataTableTransferFilterDto",
    modelProperties: {
      label: {
        serializedName: "label",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "-1"
          ]
        }
      },
      operator: {
        serializedName: "operator",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "-1"
          ]
        }
      },
      kind: {
        serializedName: "kind",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      },
      property: {
        serializedName: "property",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ExportMetadata: msRest.CompositeMapper = {
  serializedName: "ExportMetadata",
  type: {
    name: "Composite",
    className: "ExportMetadata",
    modelProperties: {
      version: {
        serializedName: "version",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
            "31",
            "32",
            "33",
            "34",
            "35",
            "36",
            "37",
            "38",
            "39",
            "40",
            "41",
            "42",
            "43",
            "44",
            "45"
          ]
        }
      },
      dateTime: {
        serializedName: "dateTime",
        type: {
          name: "String"
        }
      },
      defaultFileName: {
        serializedName: "defaultFileName",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ExportTaskJobCsvQueueDto: msRest.CompositeMapper = {
  serializedName: "ExportTaskJobCsvQueueDto",
  type: {
    name: "Composite",
    className: "ExportTaskJobCsvQueueDto",
    modelProperties: {
      filledFormId: {
        serializedName: "filledFormId",
        type: {
          name: "Number"
        }
      },
      fileName: {
        serializedName: "fileName",
        type: {
          name: "String"
        }
      },
      scopeId: {
        readOnly: true,
        serializedName: "scopeId",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ExternalAppLoginDto: msRest.CompositeMapper = {
  serializedName: "ExternalAppLoginDto",
  type: {
    name: "Composite",
    className: "ExternalAppLoginDto",
    modelProperties: {
      externalApp: {
        serializedName: "externalApp",
        type: {
          name: "Enum",
          allowedValues: [
            "1"
          ]
        }
      },
      redirectUrl: {
        serializedName: "redirectUrl",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ExternalChatMemberDto: msRest.CompositeMapper = {
  serializedName: "ExternalChatMemberDto",
  type: {
    name: "Composite",
    className: "ExternalChatMemberDto",
    modelProperties: {
      chatMemberId: {
        serializedName: "chatMemberId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5"
          ]
        }
      },
      concreteMemberId: {
        readOnly: true,
        serializedName: "concreteMemberId",
        type: {
          name: "Number"
        }
      },
      concreteMemberName: {
        readOnly: true,
        serializedName: "concreteMemberName",
        type: {
          name: "String"
        }
      },
      emailAddress: {
        serializedName: "emailAddress",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ExternalTokenDto: msRest.CompositeMapper = {
  serializedName: "ExternalTokenDto",
  type: {
    name: "Composite",
    className: "ExternalTokenDto",
    modelProperties: {
      token: {
        serializedName: "token",
        type: {
          name: "String"
        }
      },
      redirectUrl: {
        serializedName: "redirectUrl",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const FailedMailDeliveryQueueDto: msRest.CompositeMapper = {
  serializedName: "FailedMailDeliveryQueueDto",
  type: {
    name: "Composite",
    className: "FailedMailDeliveryQueueDto",
    modelProperties: {
      mailReceiverId: {
        serializedName: "mailReceiverId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const FileAttachmentForTechDto: msRest.CompositeMapper = {
  serializedName: "FileAttachmentForTechDto",
  type: {
    name: "Composite",
    className: "FileAttachmentForTechDto",
    modelProperties: {
      attachmentId: {
        serializedName: "attachmentId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1"
          ]
        }
      },
      file: {
        serializedName: "file",
        type: {
          name: "Composite",
          className: "FileDto"
        }
      }
    }
  }
};

export const FileOrgaAttachmentDto: msRest.CompositeMapper = {
  serializedName: "FileOrgaAttachmentDto",
  type: {
    name: "Composite",
    className: "FileOrgaAttachmentDto",
    modelProperties: {
      attachmentId: {
        serializedName: "attachmentId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5"
          ]
        }
      },
      fileId: {
        serializedName: "fileId",
        type: {
          name: "Number"
        }
      },
      file: {
        serializedName: "file",
        type: {
          name: "Composite",
          className: "FileDto"
        }
      }
    }
  }
};

export const FillLabelGapsQueueDto: msRest.CompositeMapper = {
  serializedName: "FillLabelGapsQueueDto",
  type: {
    name: "Composite",
    className: "FillLabelGapsQueueDto",
    modelProperties: {
      ticketStereotypeId: {
        nullable: true,
        serializedName: "ticketStereotypeId",
        type: {
          name: "Number"
        }
      },
      missionStereotypeId: {
        nullable: true,
        serializedName: "missionStereotypeId",
        type: {
          name: "Number"
        }
      },
      resourceStereotypeId: {
        nullable: true,
        serializedName: "resourceStereotypeId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const FilledFormFieldCreateDto: msRest.CompositeMapper = {
  serializedName: "FilledFormFieldCreateDto",
  type: {
    name: "Composite",
    className: "FilledFormFieldCreateDto",
    modelProperties: {
      filledFormFieldId: {
        serializedName: "filledFormFieldId",
        type: {
          name: "Number"
        }
      },
      fieldId: {
        serializedName: "fieldId",
        type: {
          name: "Number"
        }
      },
      rowIndex: {
        nullable: true,
        serializedName: "rowIndex",
        type: {
          name: "Number"
        }
      },
      values: {
        serializedName: "values",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FormValueDto"
            }
          }
        }
      }
    }
  }
};

export const FilledFormCreateDto: msRest.CompositeMapper = {
  serializedName: "FilledFormCreateDto",
  type: {
    name: "Composite",
    className: "FilledFormCreateDto",
    modelProperties: {
      filledFormId: {
        serializedName: "filledFormId",
        type: {
          name: "Number"
        }
      },
      formId: {
        serializedName: "formId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      fields: {
        serializedName: "fields",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledFormFieldCreateDto"
            }
          }
        }
      }
    }
  }
};

export const FormFieldCreateDto: msRest.CompositeMapper = {
  serializedName: "FormFieldCreateDto",
  type: {
    name: "Composite",
    className: "FormFieldCreateDto",
    modelProperties: {
      formFieldId: {
        serializedName: "formFieldId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      technicalName: {
        readOnly: true,
        serializedName: "technicalName",
        type: {
          name: "String"
        }
      },
      width: {
        serializedName: "width",
        type: {
          name: "Number"
        }
      },
      isRequired: {
        serializedName: "isRequired",
        type: {
          name: "Boolean"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12"
          ]
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      defaultValues: {
        serializedName: "defaultValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FormDefaultDto"
            }
          }
        }
      },
      localFieldId: {
        serializedName: "localFieldId",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const FormRowCreateDto: msRest.CompositeMapper = {
  serializedName: "FormRowCreateDto",
  type: {
    name: "Composite",
    className: "FormRowCreateDto",
    modelProperties: {
      formRowId: {
        serializedName: "formRowId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      },
      fields: {
        serializedName: "fields",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FormFieldCreateDto"
            }
          }
        }
      }
    }
  }
};

export const FormDto: msRest.CompositeMapper = {
  serializedName: "FormDto",
  type: {
    name: "Composite",
    className: "FormDto",
    modelProperties: {
      formId: {
        serializedName: "formId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      },
      activeFormId: {
        serializedName: "activeFormId",
        type: {
          name: "Number"
        }
      },
      locations: {
        serializedName: "locations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationRelationDto"
            }
          }
        }
      },
      locationGroups: {
        serializedName: "locationGroups",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationGroupRelationDto"
            }
          }
        }
      },
      rows: {
        serializedName: "rows",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FormRowCreateDto"
            }
          }
        }
      }
    }
  }
};

export const FilledFormDto: msRest.CompositeMapper = {
  serializedName: "FilledFormDto",
  type: {
    name: "Composite",
    className: "FilledFormDto",
    modelProperties: {
      filledFormId: {
        serializedName: "filledFormId",
        type: {
          name: "Number"
        }
      },
      form: {
        serializedName: "form",
        type: {
          name: "Composite",
          className: "FormDto"
        }
      },
      ratingInPercent: {
        serializedName: "ratingInPercent",
        type: {
          name: "Number"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      file: {
        serializedName: "file",
        type: {
          name: "Composite",
          className: "FileDto"
        }
      },
      fields: {
        serializedName: "fields",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledFormFieldCreateDto"
            }
          }
        }
      }
    }
  }
};

export const FilledFormOrgaAttachmentDto: msRest.CompositeMapper = {
  serializedName: "FilledFormOrgaAttachmentDto",
  type: {
    name: "Composite",
    className: "FilledFormOrgaAttachmentDto",
    modelProperties: {
      attachmentId: {
        serializedName: "attachmentId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5"
          ]
        }
      },
      filledFormId: {
        serializedName: "filledFormId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const FinishStatusTaskJobDto: msRest.CompositeMapper = {
  serializedName: "FinishStatusTaskJobDto",
  type: {
    name: "Composite",
    className: "FinishStatusTaskJobDto",
    modelProperties: {
      taskJobId: {
        serializedName: "taskJobId",
        type: {
          name: "Number"
        }
      },
      targetState: {
        serializedName: "targetState",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      reasonId: {
        serializedName: "reasonId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const FinishTaskJobQueueDto: msRest.CompositeMapper = {
  serializedName: "FinishTaskJobQueueDto",
  type: {
    name: "Composite",
    className: "FinishTaskJobQueueDto",
    modelProperties: {
      taskJobId: {
        serializedName: "taskJobId",
        type: {
          name: "Number"
        }
      },
      targetState: {
        serializedName: "targetState",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7"
          ]
        }
      }
    }
  }
};

export const SafeDto: msRest.CompositeMapper = {
  serializedName: "SafeDto",
  type: {
    name: "Composite",
    className: "SafeDto",
    modelProperties: {
      contacts: {
        serializedName: "contacts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ContactSimpleDto"
            }
          }
        }
      }
    }
  }
};

export const ResourceSafetySimpleDto: msRest.CompositeMapper = {
  serializedName: "ResourceSafetySimpleDto",
  type: {
    name: "Composite",
    className: "ResourceSafetySimpleDto",
    modelProperties: {
      resourceId: {
        serializedName: "resourceId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      inventoryNumber: {
        serializedName: "inventoryNumber",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      safe: {
        serializedName: "safe",
        type: {
          name: "Composite",
          className: "SafeDto"
        }
      }
    }
  }
};

export const FixedResourceTicketSkeletonSelectorDto: msRest.CompositeMapper = {
  serializedName: "FixedResourceTicketSkeletonSelectorDto",
  type: {
    name: "Composite",
    className: "FixedResourceTicketSkeletonSelectorDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4"
          ]
        }
      },
      resource: {
        serializedName: "resource",
        type: {
          name: "Composite",
          className: "ResourceSafetySimpleDto"
        }
      }
    }
  }
};

export const FolderAccessContactDto: msRest.CompositeMapper = {
  serializedName: "FolderAccessContactDto",
  type: {
    name: "Composite",
    className: "FolderAccessContactDto",
    modelProperties: {
      contactId: {
        serializedName: "contactId",
        type: {
          name: "Number"
        }
      },
      contact: {
        serializedName: "contact",
        type: {
          name: "Composite",
          className: "ContactSimpleDto"
        }
      }
    }
  }
};

export const FolderAccessContactListDto: msRest.CompositeMapper = {
  serializedName: "FolderAccessContactListDto",
  type: {
    name: "Composite",
    className: "FolderAccessContactListDto",
    modelProperties: {
      contactId: {
        serializedName: "contactId",
        type: {
          name: "Number"
        }
      },
      contact: {
        serializedName: "contact",
        type: {
          name: "Composite",
          className: "ContactSimpleListDto"
        }
      }
    }
  }
};

export const FolderAccessDto: msRest.CompositeMapper = {
  serializedName: "FolderAccessDto",
  type: {
    name: "Composite",
    className: "FolderAccessDto",
    modelProperties: {
      folderAccessId: {
        serializedName: "folderAccessId",
        type: {
          name: "Number"
        }
      },
      path: {
        serializedName: "path",
        type: {
          name: "String"
        }
      },
      isInherited: {
        serializedName: "isInherited",
        type: {
          name: "Boolean"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      locations: {
        serializedName: "locations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationSimpleDto"
            }
          }
        }
      },
      locationGroups: {
        serializedName: "locationGroups",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationGroupSimpleDto"
            }
          }
        }
      },
      contacts: {
        serializedName: "contacts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ContactSimpleDto"
            }
          }
        }
      },
      functions: {
        serializedName: "functions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FunctionSimpleDto"
            }
          }
        }
      }
    }
  }
};

export const FolderAccessFunctionDto: msRest.CompositeMapper = {
  serializedName: "FolderAccessFunctionDto",
  type: {
    name: "Composite",
    className: "FolderAccessFunctionDto",
    modelProperties: {
      functionId: {
        serializedName: "functionId",
        type: {
          name: "Number"
        }
      },
      functionProperty: {
        serializedName: "function",
        type: {
          name: "Composite",
          className: "FunctionSimpleDto"
        }
      }
    }
  }
};

export const FolderAccessFunctionListDto: msRest.CompositeMapper = {
  serializedName: "FolderAccessFunctionListDto",
  type: {
    name: "Composite",
    className: "FolderAccessFunctionListDto",
    modelProperties: {
      functionId: {
        serializedName: "functionId",
        type: {
          name: "Number"
        }
      },
      functionProperty: {
        serializedName: "function",
        type: {
          name: "Composite",
          className: "FunctionListSimpleDto"
        }
      }
    }
  }
};

export const FolderAccessLocationListDto: msRest.CompositeMapper = {
  serializedName: "FolderAccessLocationListDto",
  type: {
    name: "Composite",
    className: "FolderAccessLocationListDto",
    modelProperties: {
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Number"
        }
      },
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "LocationSimpleListDto"
        }
      }
    }
  }
};

export const FolderAccessLocationGroupListDto: msRest.CompositeMapper = {
  serializedName: "FolderAccessLocationGroupListDto",
  type: {
    name: "Composite",
    className: "FolderAccessLocationGroupListDto",
    modelProperties: {
      locationGroupId: {
        serializedName: "locationGroupId",
        type: {
          name: "Number"
        }
      },
      locationGroup: {
        serializedName: "locationGroup",
        type: {
          name: "Composite",
          className: "LocationGroupSimpleListDto"
        }
      }
    }
  }
};

export const FolderAccessListDto: msRest.CompositeMapper = {
  serializedName: "FolderAccessListDto",
  type: {
    name: "Composite",
    className: "FolderAccessListDto",
    modelProperties: {
      folderAccessId: {
        serializedName: "folderAccessId",
        type: {
          name: "Number"
        }
      },
      path: {
        serializedName: "path",
        type: {
          name: "String"
        }
      },
      isInherited: {
        serializedName: "isInherited",
        type: {
          name: "Boolean"
        }
      },
      fellows: {
        readOnly: true,
        serializedName: "fellows",
        type: {
          name: "String"
        }
      },
      locations: {
        serializedName: "locations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FolderAccessLocationListDto"
            }
          }
        }
      },
      locationGroups: {
        serializedName: "locationGroups",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FolderAccessLocationGroupListDto"
            }
          }
        }
      },
      contacts: {
        serializedName: "contacts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FolderAccessContactListDto"
            }
          }
        }
      },
      functions: {
        serializedName: "functions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FolderAccessFunctionListDto"
            }
          }
        }
      }
    }
  }
};

export const FolderAccessLocationDto: msRest.CompositeMapper = {
  serializedName: "FolderAccessLocationDto",
  type: {
    name: "Composite",
    className: "FolderAccessLocationDto",
    modelProperties: {
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Number"
        }
      },
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "LocationSimpleDto"
        }
      }
    }
  }
};

export const FolderAccessLocationGroupDto: msRest.CompositeMapper = {
  serializedName: "FolderAccessLocationGroupDto",
  type: {
    name: "Composite",
    className: "FolderAccessLocationGroupDto",
    modelProperties: {
      locationGroupId: {
        serializedName: "locationGroupId",
        type: {
          name: "Number"
        }
      },
      locationGroup: {
        serializedName: "locationGroup",
        type: {
          name: "Composite",
          className: "LocationGroupSimpleDto"
        }
      }
    }
  }
};

export const FolderOrgaAttachmentDto: msRest.CompositeMapper = {
  serializedName: "FolderOrgaAttachmentDto",
  type: {
    name: "Composite",
    className: "FolderOrgaAttachmentDto",
    modelProperties: {
      attachmentId: {
        serializedName: "attachmentId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5"
          ]
        }
      },
      path: {
        serializedName: "path",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const FollowUpMissionDto: msRest.CompositeMapper = {
  serializedName: "FollowUpMissionDto",
  type: {
    name: "Composite",
    className: "FollowUpMissionDto",
    modelProperties: {
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      plannedStart: {
        serializedName: "plannedStart",
        type: {
          name: "String"
        }
      },
      plannedEnd: {
        serializedName: "plannedEnd",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const FollowUpMissionsDto: msRest.CompositeMapper = {
  serializedName: "FollowUpMissionsDto",
  type: {
    name: "Composite",
    className: "FollowUpMissionsDto",
    modelProperties: {
      items: {
        serializedName: "items",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FollowUpMissionDto"
            }
          }
        }
      }
    }
  }
};

export const FollowUpTicketDto: msRest.CompositeMapper = {
  serializedName: "FollowUpTicketDto",
  type: {
    name: "Composite",
    className: "FollowUpTicketDto",
    modelProperties: {
      state: {
        serializedName: "state",
        type: {
          name: "Composite",
          className: "StateSimpleDto"
        }
      },
      triggersAt: {
        serializedName: "triggersAt",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const MissionSimpleDto: msRest.CompositeMapper = {
  serializedName: "MissionSimpleDto",
  type: {
    name: "Composite",
    className: "MissionSimpleDto",
    modelProperties: {
      missionId: {
        serializedName: "missionId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4"
          ]
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const FollowedUpMissionsDto: msRest.CompositeMapper = {
  serializedName: "FollowedUpMissionsDto",
  type: {
    name: "Composite",
    className: "FollowedUpMissionsDto",
    modelProperties: {
      items: {
        serializedName: "items",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "MissionSimpleDto"
            }
          }
        }
      }
    }
  }
};

export const ForcePasswordDto: msRest.CompositeMapper = {
  serializedName: "ForcePasswordDto",
  type: {
    name: "Composite",
    className: "ForcePasswordDto",
    modelProperties: {
      passwordBase64: {
        serializedName: "passwordBase64",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const FormLocationDto: msRest.CompositeMapper = {
  serializedName: "FormLocationDto",
  type: {
    name: "Composite",
    className: "FormLocationDto",
    modelProperties: {
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Number"
        }
      },
      formId: {
        serializedName: "formId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const FormLocationGroupDto: msRest.CompositeMapper = {
  serializedName: "FormLocationGroupDto",
  type: {
    name: "Composite",
    className: "FormLocationGroupDto",
    modelProperties: {
      locationGroupId: {
        serializedName: "locationGroupId",
        type: {
          name: "Number"
        }
      },
      formId: {
        serializedName: "formId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const FormCreateDto: msRest.CompositeMapper = {
  serializedName: "FormCreateDto",
  type: {
    name: "Composite",
    className: "FormCreateDto",
    modelProperties: {
      formId: {
        serializedName: "formId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      rows: {
        serializedName: "rows",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FormRowCreateDto"
            }
          }
        }
      },
      locations: {
        serializedName: "locations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FormLocationDto"
            }
          }
        }
      },
      locationGroups: {
        serializedName: "locationGroups",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FormLocationGroupDto"
            }
          }
        }
      }
    }
  }
};

export const FormCsvCreatedByContactDto: msRest.CompositeMapper = {
  serializedName: "FormCsvCreatedByContactDto",
  type: {
    name: "Composite",
    className: "FormCsvCreatedByContactDto",
    modelProperties: {
      contactId: {
        serializedName: "contactId",
        type: {
          name: "Number"
        }
      },
      pin: {
        serializedName: "pin",
        type: {
          name: "String"
        }
      },
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      },
      firstName: {
        serializedName: "firstName",
        type: {
          name: "String"
        }
      },
      lastName: {
        serializedName: "lastName",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ShareIdentifierDto: msRest.CompositeMapper = {
  serializedName: "ShareIdentifierDto",
  type: {
    name: "Composite",
    className: "ShareIdentifierDto",
    modelProperties: {
      identifier: {
        serializedName: "identifier",
        type: {
          name: "String"
        }
      },
      shareIdentifier: {
        readOnly: true,
        serializedName: "shareIdentifier",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const FormTextValueCsvDto: msRest.CompositeMapper = {
  serializedName: "FormTextValueCsvDto",
  type: {
    name: "Composite",
    className: "FormTextValueCsvDto",
    modelProperties: {
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const FormFieldCsvDto: msRest.CompositeMapper = {
  serializedName: "FormFieldCsvDto",
  type: {
    name: "Composite",
    className: "FormFieldCsvDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      value: {
        readOnly: true,
        serializedName: "value",
        type: {
          name: "String"
        }
      },
      valueToDisplay: {
        serializedName: "valueToDisplay",
        type: {
          name: "Composite",
          className: "FormTextValueCsvDto"
        }
      }
    }
  }
};

export const FormRowCsvDto: msRest.CompositeMapper = {
  serializedName: "FormRowCsvDto",
  type: {
    name: "Composite",
    className: "FormRowCsvDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      fields: {
        serializedName: "fields",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FormFieldCsvDto"
            }
          }
        }
      }
    }
  }
};

export const FormCsvDto: msRest.CompositeMapper = {
  serializedName: "FormCsvDto",
  type: {
    name: "Composite",
    className: "FormCsvDto",
    modelProperties: {
      filledFormId: {
        serializedName: "filledFormId",
        type: {
          name: "Number"
        }
      },
      fileName: {
        serializedName: "fileName",
        type: {
          name: "String"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      share: {
        serializedName: "share",
        type: {
          name: "Composite",
          className: "ShareIdentifierDto"
        }
      },
      createdByLocation: {
        serializedName: "createdByLocation",
        type: {
          name: "String"
        }
      },
      createdByUser: {
        serializedName: "createdByUser",
        type: {
          name: "String"
        }
      },
      createdByContact: {
        serializedName: "createdByContact",
        type: {
          name: "Composite",
          className: "FormCsvCreatedByContactDto"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "Object"
        }
      },
      isRated: {
        serializedName: "isRated",
        type: {
          name: "Boolean"
        }
      },
      ratingInPercent: {
        serializedName: "ratingInPercent",
        type: {
          name: "String"
        }
      },
      rows: {
        serializedName: "rows",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FormRowCsvDto"
            }
          }
        }
      }
    }
  }
};

export const FormFieldTtm: msRest.CompositeMapper = {
  serializedName: "FormFieldTtm",
  type: {
    name: "Composite",
    className: "FormFieldTtm",
    modelProperties: {
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      unit: {
        serializedName: "unit",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const FormListDto: msRest.CompositeMapper = {
  serializedName: "FormListDto",
  type: {
    name: "Composite",
    className: "FormListDto",
    modelProperties: {
      formId: {
        serializedName: "formId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const FormTtm: msRest.CompositeMapper = {
  serializedName: "FormTtm",
  type: {
    name: "Composite",
    className: "FormTtm",
    modelProperties: {
      formId: {
        serializedName: "formId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      fields: {
        serializedName: "fields",
        type: {
          name: "Object"
        }
      }
    }
  }
};

export const FunctionChatMemberDto: msRest.CompositeMapper = {
  serializedName: "FunctionChatMemberDto",
  type: {
    name: "Composite",
    className: "FunctionChatMemberDto",
    modelProperties: {
      chatMemberId: {
        serializedName: "chatMemberId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5"
          ]
        }
      },
      concreteMemberId: {
        readOnly: true,
        serializedName: "concreteMemberId",
        type: {
          name: "Number"
        }
      },
      concreteMemberName: {
        readOnly: true,
        serializedName: "concreteMemberName",
        type: {
          name: "String"
        }
      },
      functionId: {
        serializedName: "functionId",
        type: {
          name: "Number"
        }
      },
      functionProperty: {
        serializedName: "function",
        type: {
          name: "Composite",
          className: "FunctionSimpleDto"
        }
      },
      contactGroupId: {
        serializedName: "contactGroupId",
        type: {
          name: "Number"
        }
      },
      contactGroup: {
        serializedName: "contactGroup",
        type: {
          name: "Composite",
          className: "ContactGroupSimpleDto"
        }
      }
    }
  }
};

export const FunctionDto: msRest.CompositeMapper = {
  serializedName: "FunctionDto",
  type: {
    name: "Composite",
    className: "FunctionDto",
    modelProperties: {
      functionId: {
        serializedName: "functionId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueDto"
            }
          }
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleDto"
        }
      }
    }
  }
};

export const StereotypeSimpleExportDto: msRest.CompositeMapper = {
  serializedName: "StereotypeSimpleExportDto",
  type: {
    name: "Composite",
    className: "StereotypeSimpleExportDto",
    modelProperties: {
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const FunctionExportDto: msRest.CompositeMapper = {
  serializedName: "FunctionExportDto",
  type: {
    name: "Composite",
    className: "FunctionExportDto",
    modelProperties: {
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleExportDto"
        }
      }
    }
  }
};

export const FunctionIssueReceiverDto: msRest.CompositeMapper = {
  serializedName: "FunctionIssueReceiverDto",
  type: {
    name: "Composite",
    className: "FunctionIssueReceiverDto",
    modelProperties: {
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      functionProperty: {
        serializedName: "function",
        type: {
          name: "Composite",
          className: "FunctionSimpleDto"
        }
      }
    }
  }
};

export const FunctionListDto: msRest.CompositeMapper = {
  serializedName: "FunctionListDto",
  type: {
    name: "Composite",
    className: "FunctionListDto",
    modelProperties: {
      functionId: {
        serializedName: "functionId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueListDto"
            }
          }
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleListDto"
        }
      }
    }
  }
};

export const FunctionListForCompanyDto: msRest.CompositeMapper = {
  serializedName: "FunctionListForCompanyDto",
  type: {
    name: "Composite",
    className: "FunctionListForCompanyDto",
    modelProperties: {
      functionId: {
        serializedName: "functionId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueListDto"
            }
          }
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleListDto"
        }
      },
      isInRelation: {
        serializedName: "isInRelation",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const FunctionListForContactDto: msRest.CompositeMapper = {
  serializedName: "FunctionListForContactDto",
  type: {
    name: "Composite",
    className: "FunctionListForContactDto",
    modelProperties: {
      functionId: {
        serializedName: "functionId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueListDto"
            }
          }
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleListDto"
        }
      },
      isInRelation: {
        serializedName: "isInRelation",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const FunctionListForContactDtoPageOutputDto: msRest.CompositeMapper = {
  serializedName: "FunctionListForContactDtoPageOutputDto",
  type: {
    name: "Composite",
    className: "FunctionListForContactDtoPageOutputDto",
    modelProperties: {
      paging: {
        serializedName: "paging",
        type: {
          name: "Object"
        }
      },
      items: {
        serializedName: "items",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FunctionListForContactDto"
            }
          }
        }
      }
    }
  }
};

export const FunctionNotificationTargetDto: msRest.CompositeMapper = {
  serializedName: "FunctionNotificationTargetDto",
  type: {
    name: "Composite",
    className: "FunctionNotificationTargetDto",
    modelProperties: {
      notificationTargetId: {
        serializedName: "notificationTargetId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      functionProperty: {
        serializedName: "function",
        type: {
          name: "Composite",
          className: "FunctionSimpleDto"
        }
      }
    }
  }
};

export const FunctionRelationListDto: msRest.CompositeMapper = {
  serializedName: "FunctionRelationListDto",
  type: {
    name: "Composite",
    className: "FunctionRelationListDto",
    modelProperties: {
      functionId: {
        serializedName: "functionId",
        type: {
          name: "Number"
        }
      },
      functionProperty: {
        serializedName: "function",
        type: {
          name: "Composite",
          className: "FunctionListSimpleDto"
        }
      }
    }
  }
};

export const FunctionTtm: msRest.CompositeMapper = {
  serializedName: "FunctionTtm",
  type: {
    name: "Composite",
    className: "FunctionTtm",
    modelProperties: {
      functionId: {
        serializedName: "functionId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const GeneralDataProtectionRestrictionDto: msRest.CompositeMapper = {
  serializedName: "GeneralDataProtectionRestrictionDto",
  type: {
    name: "Composite",
    className: "GeneralDataProtectionRestrictionDto",
    modelProperties: {
      generalDataProtectionRestrictionId: {
        serializedName: "generalDataProtectionRestrictionId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      }
    }
  }
};

export const GeneralDataProtectionRegulationDto: msRest.CompositeMapper = {
  serializedName: "GeneralDataProtectionRegulationDto",
  type: {
    name: "Composite",
    className: "GeneralDataProtectionRegulationDto",
    modelProperties: {
      generalDataProtectionRegulationId: {
        serializedName: "generalDataProtectionRegulationId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      entity: {
        serializedName: "entity",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2"
          ]
        }
      },
      lifespan: {
        serializedName: "lifespan",
        type: {
          name: "String"
        }
      },
      restrictions: {
        serializedName: "restrictions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "GeneralDataProtectionRestrictionDto"
            }
          }
        }
      }
    }
  }
};

export const GeneralDataProtectionRegulationListDto: msRest.CompositeMapper = {
  serializedName: "GeneralDataProtectionRegulationListDto",
  type: {
    name: "Composite",
    className: "GeneralDataProtectionRegulationListDto",
    modelProperties: {
      generalDataProtectionRegulationId: {
        serializedName: "generalDataProtectionRegulationId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      entity: {
        serializedName: "entity",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2"
          ]
        }
      }
    }
  }
};

export const GeneralDataProtectionRegulationLogEntryListDto: msRest.CompositeMapper = {
  serializedName: "GeneralDataProtectionRegulationLogEntryListDto",
  type: {
    name: "Composite",
    className: "GeneralDataProtectionRegulationLogEntryListDto",
    modelProperties: {
      entityId: {
        serializedName: "entityId",
        type: {
          name: "Number"
        }
      },
      entityType: {
        serializedName: "entityType",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2"
          ]
        }
      },
      timestamp: {
        serializedName: "timestamp",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const PictureSettingDto: msRest.CompositeMapper = {
  serializedName: "PictureSettingDto",
  type: {
    name: "Composite",
    className: "PictureSettingDto",
    modelProperties: {
      settingValueId: {
        serializedName: "settingValueId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      value: {
        nullable: true,
        serializedName: "value",
        type: {
          name: "Number"
        }
      },
      file: {
        serializedName: "file",
        type: {
          name: "Composite",
          className: "FileDto"
        }
      }
    }
  }
};

export const HexColorSettingDto: msRest.CompositeMapper = {
  serializedName: "HexColorSettingDto",
  type: {
    name: "Composite",
    className: "HexColorSettingDto",
    modelProperties: {
      settingValueId: {
        serializedName: "settingValueId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const GeneralSettingDto: msRest.CompositeMapper = {
  serializedName: "GeneralSettingDto",
  type: {
    name: "Composite",
    className: "GeneralSettingDto",
    modelProperties: {
      settingId: {
        serializedName: "settingId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      isClockTileDisabled: {
        serializedName: "isClockTileDisabled",
        type: {
          name: "Boolean"
        }
      },
      pdfViewBehaviour: {
        serializedName: "pdfViewBehaviour",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1"
          ]
        }
      },
      logoId: {
        nullable: true,
        serializedName: "logoId",
        type: {
          name: "Number"
        }
      },
      logo: {
        serializedName: "logo",
        type: {
          name: "Composite",
          className: "PictureSettingDto"
        }
      },
      primaryColorId: {
        nullable: true,
        serializedName: "primaryColorId",
        type: {
          name: "Number"
        }
      },
      primaryColor: {
        serializedName: "primaryColor",
        type: {
          name: "Composite",
          className: "HexColorSettingDto"
        }
      },
      defaultLanguage: {
        serializedName: "defaultLanguage",
        type: {
          name: "Composite",
          className: "CultureSettingDto"
        }
      },
      defaultLoginMethod: {
        serializedName: "defaultLoginMethod",
        type: {
          name: "Composite",
          className: "ContactLoginMethodSettingDto"
        }
      },
      availableLoginMethod: {
        serializedName: "availableLoginMethod",
        type: {
          name: "Composite",
          className: "ContactLoginMethodSettingDto"
        }
      },
      adminEmailAddress: {
        serializedName: "adminEmailAddress",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const GenerateMissionByTaskQueueDto: msRest.CompositeMapper = {
  serializedName: "GenerateMissionByTaskQueueDto",
  type: {
    name: "Composite",
    className: "GenerateMissionByTaskQueueDto",
    modelProperties: {
      taskId: {
        serializedName: "taskId",
        type: {
          name: "Number"
        }
      },
      resourceId: {
        serializedName: "resourceId",
        type: {
          name: "Number"
        }
      },
      start: {
        serializedName: "start",
        type: {
          name: "String"
        }
      },
      end: {
        serializedName: "end",
        type: {
          name: "String"
        }
      },
      culture: {
        serializedName: "culture",
        type: {
          name: "String"
        }
      },
      senderOfQueue: {
        serializedName: "senderOfQueue",
        type: {
          name: "String"
        }
      },
      errorCodeId: {
        nullable: true,
        serializedName: "errorCodeId",
        type: {
          name: "Number"
        }
      },
      consumptionCodeId: {
        nullable: true,
        serializedName: "consumptionCodeId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const GroupedFilterDto: msRest.CompositeMapper = {
  serializedName: "GroupedFilterDto",
  type: {
    name: "Composite",
    className: "GroupedFilterDto",
    modelProperties: {
      property: {
        serializedName: "property",
        type: {
          name: "String"
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      },
      kind: {
        serializedName: "kind",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      },
      operator: {
        serializedName: "operator",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "-1"
          ]
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      },
      children: {
        serializedName: "children",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilterDto"
            }
          }
        }
      },
      combinedAs: {
        serializedName: "combinedAs",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2"
          ]
        }
      }
    }
  }
};

export const HintCodeDto: msRest.CompositeMapper = {
  serializedName: "HintCodeDto",
  type: {
    name: "Composite",
    className: "HintCodeDto",
    modelProperties: {
      hintCodeId: {
        serializedName: "hintCodeId",
        type: {
          name: "Number"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      isVisible: {
        serializedName: "isVisible",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const HintCodeListDto: msRest.CompositeMapper = {
  serializedName: "HintCodeListDto",
  type: {
    name: "Composite",
    className: "HintCodeListDto",
    modelProperties: {
      hintCodeId: {
        serializedName: "hintCodeId",
        type: {
          name: "Number"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const HintCodeSimpleListDto: msRest.CompositeMapper = {
  serializedName: "HintCodeSimpleListDto",
  type: {
    name: "Composite",
    className: "HintCodeSimpleListDto",
    modelProperties: {
      hintCodeId: {
        serializedName: "hintCodeId",
        type: {
          name: "Number"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const HitNotificationRuleQueueDto: msRest.CompositeMapper = {
  serializedName: "HitNotificationRuleQueueDto",
  type: {
    name: "Composite",
    className: "HitNotificationRuleQueueDto",
    modelProperties: {
      trigger: {
        serializedName: "trigger",
        type: {
          name: "Enum",
          allowedValues: [
            "10",
            "11",
            "12",
            "20",
            "21",
            "22",
            "23",
            "30",
            "40"
          ]
        }
      },
      subjectId: {
        serializedName: "subjectId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      timeOfOccurence: {
        serializedName: "timeOfOccurence",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const HolidayDto: msRest.CompositeMapper = {
  serializedName: "HolidayDto",
  type: {
    name: "Composite",
    className: "HolidayDto",
    modelProperties: {
      holidayId: {
        serializedName: "holidayId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      date: {
        serializedName: "date",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const HolidaySetDto: msRest.CompositeMapper = {
  serializedName: "HolidaySetDto",
  type: {
    name: "Composite",
    className: "HolidaySetDto",
    modelProperties: {
      holidaySetId: {
        serializedName: "holidaySetId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      holidays: {
        serializedName: "holidays",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "HolidayDto"
            }
          }
        }
      }
    }
  }
};

export const HolidaySetListDto: msRest.CompositeMapper = {
  serializedName: "HolidaySetListDto",
  type: {
    name: "Composite",
    className: "HolidaySetListDto",
    modelProperties: {
      holidaySetId: {
        serializedName: "holidaySetId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const INotificationDto: msRest.CompositeMapper = {
  serializedName: "INotificationDto",
  type: {
    name: "Composite",
    className: "INotificationDto",
    modelProperties: {
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      message: {
        serializedName: "message",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "5"
          ]
        }
      }
    }
  }
};

export const IStereotypedDto: msRest.CompositeMapper = {
  serializedName: "IStereotypedDto",
  type: {
    name: "Composite",
    className: "IStereotypedDto",
    modelProperties: {
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueDto"
            }
          }
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      }
    }
  }
};

export const ImportQueueDto: msRest.CompositeMapper = {
  serializedName: "ImportQueueDto",
  type: {
    name: "Composite",
    className: "ImportQueueDto",
    modelProperties: {
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      blobFileId: {
        serializedName: "blobFileId",
        type: {
          name: "String"
        }
      },
      importType: {
        serializedName: "importType",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      importBehavior: {
        serializedName: "importBehavior",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      }
    }
  }
};

export const IncomingMailDto: msRest.CompositeMapper = {
  serializedName: "IncomingMailDto",
  type: {
    name: "Composite",
    className: "IncomingMailDto",
    modelProperties: {
      from: {
        serializedName: "from",
        type: {
          name: "Composite",
          className: "EmailAddressDto"
        }
      },
      to: {
        serializedName: "to",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EmailAddressDto"
            }
          }
        }
      },
      cc: {
        serializedName: "cc",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EmailAddressDto"
            }
          }
        }
      },
      bcc: {
        serializedName: "bcc",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EmailAddressDto"
            }
          }
        }
      },
      subject: {
        serializedName: "subject",
        type: {
          name: "String"
        }
      },
      text: {
        serializedName: "text",
        type: {
          name: "String"
        }
      },
      html: {
        serializedName: "html",
        type: {
          name: "String"
        }
      },
      attachments: {
        serializedName: "attachments",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FileDto"
            }
          }
        }
      },
      suppressAutoRespond: {
        serializedName: "suppressAutoRespond",
        type: {
          name: "Boolean"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const IncomingEmailRequestDto: msRest.CompositeMapper = {
  serializedName: "IncomingEmailRequestDto",
  type: {
    name: "Composite",
    className: "IncomingEmailRequestDto",
    modelProperties: {
      domain: {
        serializedName: "domain",
        type: {
          name: "String"
        }
      },
      tenantId: {
        readOnly: true,
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      mail: {
        serializedName: "mail",
        type: {
          name: "Composite",
          className: "IncomingMailDto"
        }
      },
      originalMailId: {
        readOnly: true,
        serializedName: "originalMailId",
        type: {
          name: "Number"
        }
      },
      entry: {
        serializedName: "entry",
        type: {
          name: "Composite",
          className: "MailInQueueDto"
        }
      }
    }
  }
};

export const InfoContactDto: msRest.CompositeMapper = {
  serializedName: "InfoContactDto",
  type: {
    name: "Composite",
    className: "InfoContactDto",
    modelProperties: {
      contactId: {
        serializedName: "contactId",
        type: {
          name: "Number"
        }
      },
      infoId: {
        serializedName: "infoId",
        type: {
          name: "Number"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const InfoDto: msRest.CompositeMapper = {
  serializedName: "InfoDto",
  type: {
    name: "Composite",
    className: "InfoDto",
    modelProperties: {
      infoId: {
        serializedName: "infoId",
        type: {
          name: "Number"
        }
      },
      dateTime: {
        serializedName: "dateTime",
        type: {
          name: "String"
        }
      },
      expiryDate: {
        serializedName: "expiryDate",
        type: {
          name: "String"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3"
          ]
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      content: {
        serializedName: "content",
        type: {
          name: "String"
        }
      },
      answerEnabled: {
        serializedName: "answerEnabled",
        type: {
          name: "Boolean"
        }
      },
      requestRead: {
        serializedName: "requestRead",
        type: {
          name: "Boolean"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      sender: {
        serializedName: "sender",
        type: {
          name: "Composite",
          className: "FunctionSimpleDto"
        }
      },
      attachments: {
        serializedName: "attachments",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "OrgaAttachmentDto"
            }
          }
        }
      },
      categories: {
        serializedName: "categories",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CategorySimpleDto"
            }
          }
        }
      },
      locations: {
        serializedName: "locations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationSimpleDto"
            }
          }
        }
      },
      locationGroups: {
        serializedName: "locationGroups",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationGroupSimpleDto"
            }
          }
        }
      },
      contacts: {
        serializedName: "contacts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ContactSimpleDto"
            }
          }
        }
      },
      functions: {
        serializedName: "functions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FunctionSimpleDto"
            }
          }
        }
      }
    }
  }
};

export const InfoFormFieldDto: msRest.CompositeMapper = {
  serializedName: "InfoFormFieldDto",
  type: {
    name: "Composite",
    className: "InfoFormFieldDto",
    modelProperties: {
      formFieldId: {
        serializedName: "formFieldId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      technicalName: {
        readOnly: true,
        serializedName: "technicalName",
        type: {
          name: "String"
        }
      },
      width: {
        serializedName: "width",
        type: {
          name: "Number"
        }
      },
      isRequired: {
        serializedName: "isRequired",
        type: {
          name: "Boolean"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12"
          ]
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      defaultValues: {
        serializedName: "defaultValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FormDefaultDto"
            }
          }
        }
      },
      localFieldId: {
        serializedName: "localFieldId",
        type: {
          name: "String"
        }
      },
      content: {
        serializedName: "content",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const InfoFormValueDto: msRest.CompositeMapper = {
  serializedName: "InfoFormValueDto",
  type: {
    name: "Composite",
    className: "InfoFormValueDto",
    modelProperties: {
      fieldValueId: {
        serializedName: "fieldValueId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12"
          ]
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const InfoListDto: msRest.CompositeMapper = {
  serializedName: "InfoListDto",
  type: {
    name: "Composite",
    className: "InfoListDto",
    modelProperties: {
      infoId: {
        serializedName: "infoId",
        type: {
          name: "Number"
        }
      },
      dateTime: {
        serializedName: "dateTime",
        type: {
          name: "String"
        }
      },
      contentAsText: {
        serializedName: "contentAsText",
        type: {
          name: "String"
        }
      },
      content: {
        serializedName: "content",
        type: {
          name: "String"
        }
      },
      isActive: {
        serializedName: "isActive",
        type: {
          name: "Boolean"
        }
      },
      isExpired: {
        serializedName: "isExpired",
        type: {
          name: "Boolean"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3"
          ]
        }
      },
      isRead: {
        serializedName: "isRead",
        type: {
          name: "Boolean"
        }
      },
      readAt: {
        serializedName: "readAt",
        type: {
          name: "String"
        }
      },
      expiryDate: {
        serializedName: "expiryDate",
        type: {
          name: "String"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      answerEnabled: {
        serializedName: "answerEnabled",
        type: {
          name: "Boolean"
        }
      },
      requestRead: {
        serializedName: "requestRead",
        type: {
          name: "Boolean"
        }
      },
      sender: {
        serializedName: "sender",
        type: {
          name: "Composite",
          className: "FunctionListSimpleDto"
        }
      },
      attachments: {
        serializedName: "attachments",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "OrgaAttachmentListDto"
            }
          }
        }
      },
      categoryRelations: {
        serializedName: "categoryRelations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CategorizedRelationDto"
            }
          }
        }
      },
      locations: {
        serializedName: "locations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationRelationListDto"
            }
          }
        }
      },
      locationGroups: {
        serializedName: "locationGroups",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationGroupRelationListDto"
            }
          }
        }
      },
      functions: {
        serializedName: "functions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FunctionRelationListDto"
            }
          }
        }
      },
      contacts: {
        serializedName: "contacts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ContactRelationListDto"
            }
          }
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const InfoMembershipByLocationListDto: msRest.CompositeMapper = {
  serializedName: "InfoMembershipByLocationListDto",
  type: {
    name: "Composite",
    className: "InfoMembershipByLocationListDto",
    modelProperties: {
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "LocationSimpleListDto"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      contact: {
        serializedName: "contact",
        type: {
          name: "Composite",
          className: "ContactSimpleListDto"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const InfoMembershipListDto: msRest.CompositeMapper = {
  serializedName: "InfoMembershipListDto",
  type: {
    name: "Composite",
    className: "InfoMembershipListDto",
    modelProperties: {
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      contact: {
        serializedName: "contact",
        type: {
          name: "Composite",
          className: "ContactSimpleListDto"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const InfoNotificationRuleTtm: msRest.CompositeMapper = {
  serializedName: "InfoNotificationRuleTtm",
  type: {
    name: "Composite",
    className: "InfoNotificationRuleTtm",
    modelProperties: {
      infoId: {
        serializedName: "infoId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      content: {
        serializedName: "content",
        type: {
          name: "String"
        }
      },
      showTime: {
        serializedName: "showTime",
        type: {
          name: "String"
        }
      },
      expiryDate: {
        serializedName: "expiryDate",
        type: {
          name: "String"
        }
      },
      functionProperty: {
        serializedName: "function",
        type: {
          name: "Composite",
          className: "FunctionTtm"
        }
      },
      link: {
        serializedName: "link",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const InfoProgressContactListDto: msRest.CompositeMapper = {
  serializedName: "InfoProgressContactListDto",
  type: {
    name: "Composite",
    className: "InfoProgressContactListDto",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      contactId: {
        serializedName: "contactId",
        type: {
          name: "Number"
        }
      },
      contact: {
        serializedName: "contact",
        type: {
          name: "Composite",
          className: "ContactSimpleListDto"
        }
      },
      isExpected: {
        serializedName: "isExpected",
        type: {
          name: "Boolean"
        }
      },
      readWhen: {
        serializedName: "readWhen",
        type: {
          name: "String"
        }
      },
      readLocation: {
        serializedName: "readLocation",
        type: {
          name: "Composite",
          className: "LocationSimpleListDto"
        }
      },
      locations: {
        serializedName: "locations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationSimpleListDto"
            }
          }
        }
      },
      functions: {
        serializedName: "functions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FunctionSimpleDto"
            }
          }
        }
      }
    }
  }
};

export const InfoProgressDetailingFilterDto: msRest.CompositeMapper = {
  serializedName: "InfoProgressDetailingFilterDto",
  type: {
    name: "Composite",
    className: "InfoProgressDetailingFilterDto",
    modelProperties: {
      locationIds: {
        nullable: true,
        serializedName: "locationIds",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Number"
            }
          }
        }
      },
      functionIds: {
        nullable: true,
        serializedName: "functionIds",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Number"
            }
          }
        }
      }
    }
  }
};

export const InfoProgressFunctionListDto: msRest.CompositeMapper = {
  serializedName: "InfoProgressFunctionListDto",
  type: {
    name: "Composite",
    className: "InfoProgressFunctionListDto",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      functionId: {
        serializedName: "functionId",
        type: {
          name: "Number"
        }
      },
      functionProperty: {
        serializedName: "function",
        type: {
          name: "Composite",
          className: "FunctionListSimpleDto"
        }
      },
      currentReaderCount: {
        serializedName: "currentReaderCount",
        type: {
          name: "Number"
        }
      },
      expectedReaderCount: {
        serializedName: "expectedReaderCount",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const InfoProgressLocationListDto: msRest.CompositeMapper = {
  serializedName: "InfoProgressLocationListDto",
  type: {
    name: "Composite",
    className: "InfoProgressLocationListDto",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Number"
        }
      },
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "LocationSimpleListDto"
        }
      },
      currentReaderCount: {
        serializedName: "currentReaderCount",
        type: {
          name: "Number"
        }
      },
      expectedReaderCount: {
        serializedName: "expectedReaderCount",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const InfoTtm: msRest.CompositeMapper = {
  serializedName: "InfoTtm",
  type: {
    name: "Composite",
    className: "InfoTtm",
    modelProperties: {
      infoId: {
        serializedName: "infoId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      content: {
        serializedName: "content",
        type: {
          name: "String"
        }
      },
      showTime: {
        serializedName: "showTime",
        type: {
          name: "String"
        }
      },
      expiryDate: {
        serializedName: "expiryDate",
        type: {
          name: "String"
        }
      },
      functionProperty: {
        serializedName: "function",
        type: {
          name: "Composite",
          className: "FunctionTtm"
        }
      }
    }
  }
};

export const InheritResourceQueueDto: msRest.CompositeMapper = {
  serializedName: "InheritResourceQueueDto",
  type: {
    name: "Composite",
    className: "InheritResourceQueueDto",
    modelProperties: {
      resourceId: {
        serializedName: "resourceId",
        type: {
          name: "Number"
        }
      },
      newParentId: {
        nullable: true,
        serializedName: "newParentId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      scopeId: {
        readOnly: true,
        serializedName: "scopeId",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const InheritedResourceQueueDto: msRest.CompositeMapper = {
  serializedName: "InheritedResourceQueueDto",
  type: {
    name: "Composite",
    className: "InheritedResourceQueueDto",
    modelProperties: {
      resourceId: {
        serializedName: "resourceId",
        type: {
          name: "Number"
        }
      },
      newParentId: {
        nullable: true,
        serializedName: "newParentId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const InitChatDto: msRest.CompositeMapper = {
  serializedName: "InitChatDto",
  type: {
    name: "Composite",
    className: "InitChatDto",
    modelProperties: {
      chatId: {
        serializedName: "chatId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      initializer: {
        serializedName: "initializer",
        type: {
          name: "Composite",
          className: "ChatMemberDto"
        }
      },
      additionalChatMembers: {
        serializedName: "additionalChatMembers",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ChatMemberDto"
            }
          }
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const InitLabelsQueueDto: msRest.CompositeMapper = {
  serializedName: "InitLabelsQueueDto",
  type: {
    name: "Composite",
    className: "InitLabelsQueueDto",
    modelProperties: {
      entityId: {
        serializedName: "entityId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const InitLearningLabelsQueueDto: msRest.CompositeMapper = {
  serializedName: "InitLearningLabelsQueueDto",
  type: {
    name: "Composite",
    className: "InitLearningLabelsQueueDto",
    modelProperties: {
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const InitializeAccessesByTenantQueueDto: msRest.CompositeMapper = {
  serializedName: "InitializeAccessesByTenantQueueDto",
  type: {
    name: "Composite",
    className: "InitializeAccessesByTenantQueueDto",
    modelProperties: {
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      entityType: {
        serializedName: "entityType",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
            "31",
            "32",
            "33",
            "34",
            "35",
            "36",
            "37",
            "38",
            "39",
            "40",
            "41",
            "42",
            "43",
            "44",
            "45"
          ]
        }
      }
    }
  }
};

export const InitializeAccessesQueueDto: msRest.CompositeMapper = {
  serializedName: "InitializeAccessesQueueDto",
  type: {
    name: "Composite",
    className: "InitializeAccessesQueueDto",
    modelProperties: {
      features: {
        serializedName: "features",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Enum",
              allowedValues: [
                "0",
                "1",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
                "10",
                "11",
                "12",
                "14",
                "15",
                "16",
                "17",
                "18",
                "19",
                "20",
                "21",
                "22",
                "23",
                "24",
                "25",
                "26",
                "27",
                "28",
                "29",
                "30",
                "31",
                "32",
                "33",
                "34",
                "35",
                "36",
                "37",
                "38",
                "39",
                "40",
                "41",
                "42",
                "43",
                "44",
                "45"
              ]
            }
          }
        }
      }
    }
  }
};

export const InitializedChatResultDto: msRest.CompositeMapper = {
  serializedName: "InitializedChatResultDto",
  type: {
    name: "Composite",
    className: "InitializedChatResultDto",
    modelProperties: {
      chat: {
        serializedName: "chat",
        type: {
          name: "Composite",
          className: "InitializedChatDto"
        }
      }
    }
  }
};

export const InspectionPointDto: msRest.CompositeMapper = {
  serializedName: "InspectionPointDto",
  type: {
    name: "Composite",
    className: "InspectionPointDto",
    modelProperties: {
      rating: {
        serializedName: "rating",
        type: {
          name: "Number"
        }
      },
      passed: {
        serializedName: "passed",
        type: {
          name: "Number"
        }
      },
      total: {
        serializedName: "total",
        type: {
          name: "Number"
        }
      },
      identifier: {
        serializedName: "identifier",
        type: {
          name: "String"
        }
      },
      group: {
        serializedName: "group",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const InspectionReportFilterDto: msRest.CompositeMapper = {
  serializedName: "InspectionReportFilterDto",
  type: {
    name: "Composite",
    className: "InspectionReportFilterDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      }
    }
  }
};

export const InspectionRequestDto: msRest.CompositeMapper = {
  serializedName: "InspectionRequestDto",
  type: {
    name: "Composite",
    className: "InspectionRequestDto",
    modelProperties: {
      start: {
        serializedName: "start",
        type: {
          name: "String"
        }
      },
      end: {
        serializedName: "end",
        type: {
          name: "String"
        }
      },
      filter: {
        serializedName: "filter",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "InspectionReportFilterDto"
            }
          }
        }
      },
      graphKind: {
        serializedName: "graphKind",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4"
          ]
        }
      },
      group: {
        serializedName: "group",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      }
    }
  }
};

export const InternalErrorDto: msRest.CompositeMapper = {
  serializedName: "InternalErrorDto",
  type: {
    name: "Composite",
    className: "InternalErrorDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "String"
        }
      },
      stacktrace: {
        serializedName: "stacktrace",
        type: {
          name: "String"
        }
      },
      message: {
        serializedName: "message",
        type: {
          name: "String"
        }
      },
      body: {
        serializedName: "body",
        type: {
          name: "String"
        }
      },
      method: {
        serializedName: "method",
        type: {
          name: "String"
        }
      },
      query: {
        serializedName: "query",
        type: {
          name: "String"
        }
      },
      path: {
        serializedName: "path",
        type: {
          name: "String"
        }
      },
      header: {
        serializedName: "header",
        type: {
          name: "Dictionary",
          value: {
            type: {
              name: "String"
            }
          }
        }
      }
    }
  }
};

export const InterpretChangedAreaAssignmentQueueDto: msRest.CompositeMapper = {
  serializedName: "InterpretChangedAreaAssignmentQueueDto",
  type: {
    name: "Composite",
    className: "InterpretChangedAreaAssignmentQueueDto",
    modelProperties: {
      contactId: {
        serializedName: "contactId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const InventoryNumberListDto: msRest.CompositeMapper = {
  serializedName: "InventoryNumberListDto",
  type: {
    name: "Composite",
    className: "InventoryNumberListDto",
    modelProperties: {
      inventoryNumberId: {
        serializedName: "inventoryNumberId",
        type: {
          name: "Number"
        }
      },
      count: {
        serializedName: "count",
        type: {
          name: "Number"
        }
      },
      prefix: {
        serializedName: "prefix",
        type: {
          name: "String"
        }
      },
      suffix: {
        serializedName: "suffix",
        type: {
          name: "String"
        }
      },
      patternId: {
        serializedName: "patternId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const InventoryNumberPatternDto: msRest.CompositeMapper = {
  serializedName: "InventoryNumberPatternDto",
  type: {
    name: "Composite",
    className: "InventoryNumberPatternDto",
    modelProperties: {
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      inventoryNumberPatternId: {
        serializedName: "inventoryNumberPatternId",
        type: {
          name: "Number"
        }
      },
      prefix: {
        serializedName: "prefix",
        type: {
          name: "String"
        }
      },
      suffix: {
        serializedName: "suffix",
        type: {
          name: "String"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      length: {
        nullable: true,
        serializedName: "length",
        type: {
          name: "Number"
        }
      },
      minimum: {
        nullable: true,
        serializedName: "minimum",
        type: {
          name: "Number"
        }
      },
      maximum: {
        nullable: true,
        serializedName: "maximum",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      nextNumber: {
        serializedName: "nextNumber",
        type: {
          name: "String"
        }
      },
      nextNumberCount: {
        serializedName: "nextNumberCount",
        type: {
          name: "Number"
        }
      },
      isExhausted: {
        serializedName: "isExhausted",
        type: {
          name: "Boolean"
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      }
    }
  }
};

export const InventoryNumberPatternExportDto: msRest.CompositeMapper = {
  serializedName: "InventoryNumberPatternExportDto",
  type: {
    name: "Composite",
    className: "InventoryNumberPatternExportDto",
    modelProperties: {
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      prefix: {
        serializedName: "prefix",
        type: {
          name: "String"
        }
      },
      suffix: {
        serializedName: "suffix",
        type: {
          name: "String"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      length: {
        nullable: true,
        serializedName: "length",
        type: {
          name: "Number"
        }
      },
      minimum: {
        nullable: true,
        serializedName: "minimum",
        type: {
          name: "Number"
        }
      },
      maximum: {
        nullable: true,
        serializedName: "maximum",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const InventoryNumberPatternListDto: msRest.CompositeMapper = {
  serializedName: "InventoryNumberPatternListDto",
  type: {
    name: "Composite",
    className: "InventoryNumberPatternListDto",
    modelProperties: {
      inventoryNumberPatternId: {
        serializedName: "inventoryNumberPatternId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      prefix: {
        serializedName: "prefix",
        type: {
          name: "String"
        }
      },
      suffix: {
        serializedName: "suffix",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const InventoryNumberPatternSimpleDto: msRest.CompositeMapper = {
  serializedName: "InventoryNumberPatternSimpleDto",
  type: {
    name: "Composite",
    className: "InventoryNumberPatternSimpleDto",
    modelProperties: {
      inventoryNumberPatternId: {
        serializedName: "inventoryNumberPatternId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const InventoryNumberPatternSimpleExportDto: msRest.CompositeMapper = {
  serializedName: "InventoryNumberPatternSimpleExportDto",
  type: {
    name: "Composite",
    className: "InventoryNumberPatternSimpleExportDto",
    modelProperties: {
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const InventoryNumberPatternSimpleListDto: msRest.CompositeMapper = {
  serializedName: "InventoryNumberPatternSimpleListDto",
  type: {
    name: "Composite",
    className: "InventoryNumberPatternSimpleListDto",
    modelProperties: {
      inventoryNumberPatternId: {
        serializedName: "inventoryNumberPatternId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      prefix: {
        serializedName: "prefix",
        type: {
          name: "String"
        }
      },
      suffix: {
        serializedName: "suffix",
        type: {
          name: "String"
        }
      },
      length: {
        nullable: true,
        serializedName: "length",
        type: {
          name: "Number"
        }
      },
      minimum: {
        nullable: true,
        serializedName: "minimum",
        type: {
          name: "Number"
        }
      },
      maximum: {
        nullable: true,
        serializedName: "maximum",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const InvitationCheckDto: msRest.CompositeMapper = {
  serializedName: "InvitationCheckDto",
  type: {
    name: "Composite",
    className: "InvitationCheckDto",
    modelProperties: {
      invitationId: {
        serializedName: "invitationId",
        type: {
          name: "Number"
        }
      },
      code: {
        serializedName: "code",
        type: {
          name: "String"
        }
      },
      isRevoked: {
        serializedName: "isRevoked",
        type: {
          name: "Boolean"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "1"
          ]
        }
      }
    }
  }
};

export const InvitationDto: msRest.CompositeMapper = {
  serializedName: "InvitationDto",
  type: {
    name: "Composite",
    className: "InvitationDto",
    modelProperties: {
      invitationId: {
        serializedName: "invitationId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      code: {
        serializedName: "code",
        type: {
          name: "String"
        }
      },
      isRevoked: {
        serializedName: "isRevoked",
        type: {
          name: "Boolean"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "1"
          ]
        }
      },
      purpose: {
        serializedName: "purpose",
        type: {
          name: "Enum",
          allowedValues: [
            "1"
          ]
        }
      }
    }
  }
};

export const InvitationListDto: msRest.CompositeMapper = {
  serializedName: "InvitationListDto",
  type: {
    name: "Composite",
    className: "InvitationListDto",
    modelProperties: {
      invitationId: {
        serializedName: "invitationId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      code: {
        serializedName: "code",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      purpose: {
        serializedName: "purpose",
        type: {
          name: "Enum",
          allowedValues: [
            "1"
          ]
        }
      },
      isRevoked: {
        serializedName: "isRevoked",
        type: {
          name: "Boolean"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "String"
        }
      },
      lastModified: {
        serializedName: "lastModified",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ResourcePathPartSimpleListDto: msRest.CompositeMapper = {
  serializedName: "ResourcePathPartSimpleListDto",
  type: {
    name: "Composite",
    className: "ResourcePathPartSimpleListDto",
    modelProperties: {
      index: {
        serializedName: "index",
        type: {
          name: "Number"
        }
      },
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ResourceSimpleListDto: msRest.CompositeMapper = {
  serializedName: "ResourceSimpleListDto",
  type: {
    name: "Composite",
    className: "ResourceSimpleListDto",
    modelProperties: {
      resourceId: {
        serializedName: "resourceId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      inventoryNumberCount: {
        nullable: true,
        serializedName: "inventoryNumberCount",
        type: {
          name: "Number"
        }
      },
      inventoryNumber: {
        serializedName: "inventoryNumber",
        type: {
          name: "String"
        }
      },
      locationId: {
        nullable: true,
        serializedName: "locationId",
        type: {
          name: "Number"
        }
      },
      parentId: {
        nullable: true,
        serializedName: "parentId",
        type: {
          name: "Number"
        }
      },
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "LocationSimpleListDto"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleListDto"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      },
      path: {
        serializedName: "path",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ResourcePathPartSimpleListDto"
            }
          }
        }
      }
    }
  }
};

export const IssueDetailsListDto: msRest.CompositeMapper = {
  serializedName: "IssueDetailsListDto",
  type: {
    name: "Composite",
    className: "IssueDetailsListDto",
    modelProperties: {
      issueId: {
        serializedName: "issueId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      issueType: {
        serializedName: "issueType",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "5"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "String"
        }
      },
      lastModified: {
        serializedName: "lastModified",
        type: {
          name: "String"
        }
      },
      createdByName: {
        serializedName: "createdByName",
        type: {
          name: "String"
        }
      },
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "LocationSimpleListDto"
        }
      },
      resource: {
        serializedName: "resource",
        type: {
          name: "Composite",
          className: "ResourceSimpleListDto"
        }
      }
    }
  }
};

export const IssueListDto: msRest.CompositeMapper = {
  serializedName: "IssueListDto",
  type: {
    name: "Composite",
    className: "IssueListDto",
    modelProperties: {
      issueId: {
        serializedName: "issueId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      issueType: {
        serializedName: "issueType",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "5"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "String"
        }
      },
      lastModified: {
        serializedName: "lastModified",
        type: {
          name: "String"
        }
      },
      createdByName: {
        serializedName: "createdByName",
        type: {
          name: "String"
        }
      },
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "LocationSimpleListDto"
        }
      },
      resource: {
        serializedName: "resource",
        type: {
          name: "Composite",
          className: "ResourceSimpleListDto"
        }
      }
    }
  }
};

export const IssueNotificationDto: msRest.CompositeMapper = {
  serializedName: "IssueNotificationDto",
  type: {
    name: "Composite",
    className: "IssueNotificationDto",
    modelProperties: {
      issueNotificationId: {
        serializedName: "issueNotificationId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const IssueSimpleDto: msRest.CompositeMapper = {
  serializedName: "IssueSimpleDto",
  type: {
    name: "Composite",
    className: "IssueSimpleDto",
    modelProperties: {
      issueId: {
        serializedName: "issueId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      issueType: {
        serializedName: "issueType",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "5"
          ]
        }
      }
    }
  }
};

export const MessageIssueContentDto: msRest.CompositeMapper = {
  serializedName: "MessageIssueContentDto",
  type: {
    name: "Composite",
    className: "MessageIssueContentDto",
    modelProperties: {
      issueStereotype: {
        serializedName: "issueStereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleDto"
        }
      }
    }
  }
};

export const TicketIssueContentDto: msRest.CompositeMapper = {
  serializedName: "TicketIssueContentDto",
  type: {
    name: "Composite",
    className: "TicketIssueContentDto",
    modelProperties: {
      resourceSelectorKind: {
        serializedName: "resourceSelectorKind",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1"
          ]
        }
      },
      issueStereotype: {
        serializedName: "issueStereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleDto"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleDto"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "Composite",
          className: "StateSimpleDto"
        }
      },
      priority: {
        serializedName: "priority",
        type: {
          name: "Composite",
          className: "PrioritySimpleDto"
        }
      }
    }
  }
};

export const MissionIssueContentDto: msRest.CompositeMapper = {
  serializedName: "MissionIssueContentDto",
  type: {
    name: "Composite",
    className: "MissionIssueContentDto",
    modelProperties: {
      issueStereotype: {
        serializedName: "issueStereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleDto"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleDto"
        }
      },
      editorSelectorKind: {
        serializedName: "editorSelectorKind",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      },
      fixedEditor: {
        serializedName: "fixedEditor",
        type: {
          name: "Composite",
          className: "ContactSimpleDto"
        }
      },
      priorities: {
        serializedName: "priorities",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PrioritySimpleDto"
            }
          }
        }
      }
    }
  }
};

export const IssueTemplateDto: msRest.CompositeMapper = {
  serializedName: "IssueTemplateDto",
  type: {
    name: "Composite",
    className: "IssueTemplateDto",
    modelProperties: {
      issueTemplateId: {
        serializedName: "issueTemplateId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      publishedResources: {
        serializedName: "publishedResources",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ResourceSimpleDto"
            }
          }
        }
      },
      locations: {
        serializedName: "locations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationSimpleDto"
            }
          }
        }
      },
      locationGroups: {
        serializedName: "locationGroups",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationGroupSimpleDto"
            }
          }
        }
      },
      contacts: {
        serializedName: "contacts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ContactSimpleDto"
            }
          }
        }
      },
      functions: {
        serializedName: "functions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FunctionSimpleDto"
            }
          }
        }
      },
      notifications: {
        serializedName: "notifications",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "IssueNotificationDto"
            }
          }
        }
      },
      contentType: {
        serializedName: "contentType",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "5"
          ]
        }
      },
      messageContent: {
        serializedName: "messageContent",
        type: {
          name: "Composite",
          className: "MessageIssueContentDto"
        }
      },
      ticketContent: {
        serializedName: "ticketContent",
        type: {
          name: "Composite",
          className: "TicketIssueContentDto"
        }
      },
      missionContent: {
        serializedName: "missionContent",
        type: {
          name: "Composite",
          className: "MissionIssueContentDto"
        }
      },
      winterhalterServiceContent: {
        serializedName: "winterhalterServiceContent",
        type: {
          name: "Object"
        }
      },
      winterhalterOrderContent: {
        serializedName: "winterhalterOrderContent",
        type: {
          name: "Object"
        }
      }
    }
  }
};

export const IssueTemplateListDto: msRest.CompositeMapper = {
  serializedName: "IssueTemplateListDto",
  type: {
    name: "Composite",
    className: "IssueTemplateListDto",
    modelProperties: {
      issueTemplateId: {
        serializedName: "issueTemplateId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      contentType: {
        serializedName: "contentType",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "5"
          ]
        }
      }
    }
  }
};

export const IssueTemplateSimpleDto: msRest.CompositeMapper = {
  serializedName: "IssueTemplateSimpleDto",
  type: {
    name: "Composite",
    className: "IssueTemplateSimpleDto",
    modelProperties: {
      issueTemplateId: {
        serializedName: "issueTemplateId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      contentType: {
        serializedName: "contentType",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "5"
          ]
        }
      }
    }
  }
};

export const LocationTtm: msRest.CompositeMapper = {
  serializedName: "LocationTtm",
  type: {
    name: "Composite",
    className: "LocationTtm",
    modelProperties: {
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      locationNumber: {
        serializedName: "locationNumber",
        type: {
          name: "String"
        }
      },
      phoneNumber: {
        serializedName: "phoneNumber",
        type: {
          name: "String"
        }
      },
      invoiceAddress: {
        serializedName: "invoiceAddress",
        type: {
          name: "Composite",
          className: "AddressTtm"
        }
      },
      shippingAddress: {
        serializedName: "shippingAddress",
        type: {
          name: "Composite",
          className: "AddressTtm"
        }
      },
      properties: {
        serializedName: "properties",
        type: {
          name: "Object"
        }
      }
    }
  }
};

export const ResourceTtm: msRest.CompositeMapper = {
  serializedName: "ResourceTtm",
  type: {
    name: "Composite",
    className: "ResourceTtm",
    modelProperties: {
      resourceId: {
        serializedName: "resourceId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      installationDate: {
        serializedName: "installationDate",
        type: {
          name: "String"
        }
      },
      manufacturer: {
        serializedName: "manufacturer",
        type: {
          name: "String"
        }
      },
      model: {
        serializedName: "model",
        type: {
          name: "String"
        }
      },
      serialNumber: {
        serializedName: "serialNumber",
        type: {
          name: "String"
        }
      },
      currentState: {
        serializedName: "currentState",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3"
          ]
        }
      },
      currentStateName: {
        serializedName: "currentStateName",
        type: {
          name: "String"
        }
      },
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "LocationTtm"
        }
      },
      properties: {
        serializedName: "properties",
        type: {
          name: "Object"
        }
      }
    }
  }
};

export const IssueTtm: msRest.CompositeMapper = {
  serializedName: "IssueTtm",
  type: {
    name: "Composite",
    className: "IssueTtm",
    modelProperties: {
      issueId: {
        serializedName: "issueId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      link: {
        serializedName: "link",
        type: {
          name: "String"
        }
      },
      resource: {
        serializedName: "resource",
        type: {
          name: "Composite",
          className: "ResourceTtm"
        }
      },
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "LocationTtm"
        }
      }
    }
  }
};

export const KnowledgePathSimpleDto: msRest.CompositeMapper = {
  serializedName: "KnowledgePathSimpleDto",
  type: {
    name: "Composite",
    className: "KnowledgePathSimpleDto",
    modelProperties: {
      knowledgePathId: {
        serializedName: "knowledgePathId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const KnowledgeArticleDto: msRest.CompositeMapper = {
  serializedName: "KnowledgeArticleDto",
  type: {
    name: "Composite",
    className: "KnowledgeArticleDto",
    modelProperties: {
      knowledgeArticleId: {
        serializedName: "knowledgeArticleId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      tags: {
        serializedName: "tags",
        type: {
          name: "String"
        }
      },
      content: {
        serializedName: "content",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      attachments: {
        serializedName: "attachments",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "OrgaAttachmentDto"
            }
          }
        }
      },
      hasPaths: {
        serializedName: "hasPaths",
        type: {
          name: "Boolean"
        }
      },
      oldPath: {
        serializedName: "oldPath",
        type: {
          name: "Composite",
          className: "KnowledgePathSimpleDto"
        }
      }
    }
  }
};

export const KnowledgeArticleInPathDto: msRest.CompositeMapper = {
  serializedName: "KnowledgeArticleInPathDto",
  type: {
    name: "Composite",
    className: "KnowledgeArticleInPathDto",
    modelProperties: {
      knowledgeArticleId: {
        serializedName: "knowledgeArticleId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      oldKnowledgePathId: {
        nullable: true,
        serializedName: "oldKnowledgePathId",
        type: {
          name: "Number"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const KnowledgeArticleListDto: msRest.CompositeMapper = {
  serializedName: "KnowledgeArticleListDto",
  type: {
    name: "Composite",
    className: "KnowledgeArticleListDto",
    modelProperties: {
      knowledgeArticleId: {
        serializedName: "knowledgeArticleId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      oldKnowledgePathId: {
        nullable: true,
        serializedName: "oldKnowledgePathId",
        type: {
          name: "Number"
        }
      },
      tags: {
        serializedName: "tags",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const KnowledgeArticleOrgaAttachmentDto: msRest.CompositeMapper = {
  serializedName: "KnowledgeArticleOrgaAttachmentDto",
  type: {
    name: "Composite",
    className: "KnowledgeArticleOrgaAttachmentDto",
    modelProperties: {
      attachmentId: {
        serializedName: "attachmentId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5"
          ]
        }
      },
      knowledgeArticleId: {
        nullable: true,
        serializedName: "knowledgeArticleId",
        type: {
          name: "Number"
        }
      },
      knowledgeArticle: {
        serializedName: "knowledgeArticle",
        type: {
          name: "Composite",
          className: "KnowledgeArticleSimpleDto"
        }
      }
    }
  }
};

export const KnowledgeArticleStartDto: msRest.CompositeMapper = {
  serializedName: "KnowledgeArticleStartDto",
  type: {
    name: "Composite",
    className: "KnowledgeArticleStartDto",
    modelProperties: {
      knowledgeArticleId: {
        serializedName: "knowledgeArticleId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      content: {
        serializedName: "content",
        type: {
          name: "String"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      attachments: {
        serializedName: "attachments",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "OrgaAttachmentDto"
            }
          }
        }
      }
    }
  }
};

export const KnowledgeArticleV2Dto: msRest.CompositeMapper = {
  serializedName: "KnowledgeArticleV2Dto",
  type: {
    name: "Composite",
    className: "KnowledgeArticleV2Dto",
    modelProperties: {
      knowledgeArticleId: {
        serializedName: "knowledgeArticleId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      tags: {
        serializedName: "tags",
        type: {
          name: "String"
        }
      },
      content: {
        serializedName: "content",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      attachments: {
        serializedName: "attachments",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "OrgaAttachmentDto"
            }
          }
        }
      },
      locations: {
        serializedName: "locations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationSimpleDto"
            }
          }
        }
      },
      locationGroups: {
        serializedName: "locationGroups",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationGroupSimpleDto"
            }
          }
        }
      },
      categories: {
        serializedName: "categories",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CategorySimpleDto"
            }
          }
        }
      }
    }
  }
};

export const KnowledgeArticleV2ListDto: msRest.CompositeMapper = {
  serializedName: "KnowledgeArticleV2ListDto",
  type: {
    name: "Composite",
    className: "KnowledgeArticleV2ListDto",
    modelProperties: {
      knowledgeArticleId: {
        serializedName: "knowledgeArticleId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      categoryRelations: {
        serializedName: "categoryRelations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CategorizedRelationDto"
            }
          }
        }
      }
    }
  }
};

export const KnowledgeExamDto: msRest.CompositeMapper = {
  serializedName: "KnowledgeExamDto",
  type: {
    name: "Composite",
    className: "KnowledgeExamDto",
    modelProperties: {
      knowledgeExamId: {
        serializedName: "knowledgeExamId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      customPropertySets: {
        serializedName: "customPropertySets",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomSetReferenceDto"
            }
          }
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      }
    }
  }
};

export const KnowledgeExamAnswerDto: msRest.CompositeMapper = {
  serializedName: "KnowledgeExamAnswerDto",
  type: {
    name: "Composite",
    className: "KnowledgeExamAnswerDto",
    modelProperties: {
      knowledgeExamAnswerId: {
        serializedName: "knowledgeExamAnswerId",
        type: {
          name: "Number"
        }
      },
      learnPath: {
        serializedName: "learnPath",
        type: {
          name: "Composite",
          className: "KnowledgePathSimpleDto"
        }
      },
      exam: {
        serializedName: "exam",
        type: {
          name: "Composite",
          className: "KnowledgeExamDto"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueDto"
            }
          }
        }
      },
      submitDate: {
        serializedName: "submitDate",
        type: {
          name: "String"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      },
      score: {
        serializedName: "score",
        type: {
          name: "Number"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      }
    }
  }
};

export const KnowledgeExamInPathDto: msRest.CompositeMapper = {
  serializedName: "KnowledgeExamInPathDto",
  type: {
    name: "Composite",
    className: "KnowledgeExamInPathDto",
    modelProperties: {
      knowledgeExamId: {
        serializedName: "knowledgeExamId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const KnowledgeExamListDto: msRest.CompositeMapper = {
  serializedName: "KnowledgeExamListDto",
  type: {
    name: "Composite",
    className: "KnowledgeExamListDto",
    modelProperties: {
      knowledgeExamId: {
        serializedName: "knowledgeExamId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const KnowledgeExamStartDto: msRest.CompositeMapper = {
  serializedName: "KnowledgeExamStartDto",
  type: {
    name: "Composite",
    className: "KnowledgeExamStartDto",
    modelProperties: {
      knowledgeExamId: {
        serializedName: "knowledgeExamId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      submitDate: {
        serializedName: "submitDate",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const KnowledgePathDto: msRest.CompositeMapper = {
  serializedName: "KnowledgePathDto",
  type: {
    name: "Composite",
    className: "KnowledgePathDto",
    modelProperties: {
      knowledgePathId: {
        serializedName: "knowledgePathId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      articles: {
        serializedName: "articles",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "KnowledgeArticleInPathDto"
            }
          }
        }
      },
      exams: {
        serializedName: "exams",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "KnowledgeExamInPathDto"
            }
          }
        }
      },
      categories: {
        serializedName: "categories",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CategorySimpleDto"
            }
          }
        }
      },
      locations: {
        serializedName: "locations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationSimpleDto"
            }
          }
        }
      },
      locationGroups: {
        serializedName: "locationGroups",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationGroupSimpleDto"
            }
          }
        }
      },
      contacts: {
        serializedName: "contacts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ContactSimpleDto"
            }
          }
        }
      },
      functions: {
        serializedName: "functions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FunctionSimpleDto"
            }
          }
        }
      }
    }
  }
};

export const KnowledgePathListDto: msRest.CompositeMapper = {
  serializedName: "KnowledgePathListDto",
  type: {
    name: "Composite",
    className: "KnowledgePathListDto",
    modelProperties: {
      knowledgePathId: {
        serializedName: "knowledgePathId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      locations: {
        serializedName: "locations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationRelationListDto"
            }
          }
        }
      },
      locationGroups: {
        serializedName: "locationGroups",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationGroupRelationListDto"
            }
          }
        }
      },
      functions: {
        serializedName: "functions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FunctionRelationListDto"
            }
          }
        }
      },
      contacts: {
        serializedName: "contacts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ContactRelationListDto"
            }
          }
        }
      },
      categoryRelations: {
        serializedName: "categoryRelations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CategorizedRelationDto"
            }
          }
        }
      }
    }
  }
};

export const KnowledgePathListForKnowledgeArticleDto: msRest.CompositeMapper = {
  serializedName: "KnowledgePathListForKnowledgeArticleDto",
  type: {
    name: "Composite",
    className: "KnowledgePathListForKnowledgeArticleDto",
    modelProperties: {
      knowledgePathId: {
        serializedName: "knowledgePathId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      locations: {
        serializedName: "locations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationRelationListDto"
            }
          }
        }
      },
      locationGroups: {
        serializedName: "locationGroups",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationGroupRelationListDto"
            }
          }
        }
      },
      functions: {
        serializedName: "functions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FunctionRelationListDto"
            }
          }
        }
      },
      contacts: {
        serializedName: "contacts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ContactRelationListDto"
            }
          }
        }
      },
      categoryRelations: {
        serializedName: "categoryRelations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CategorizedRelationDto"
            }
          }
        }
      },
      isInRelation: {
        serializedName: "isInRelation",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const KnowledgePathProgressContactListDto: msRest.CompositeMapper = {
  serializedName: "KnowledgePathProgressContactListDto",
  type: {
    name: "Composite",
    className: "KnowledgePathProgressContactListDto",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      contactId: {
        serializedName: "contactId",
        type: {
          name: "Number"
        }
      },
      contact: {
        serializedName: "contact",
        type: {
          name: "Composite",
          className: "ContactSimpleListDto"
        }
      },
      isExpected: {
        serializedName: "isExpected",
        type: {
          name: "Boolean"
        }
      },
      isCompleted: {
        serializedName: "isCompleted",
        type: {
          name: "Boolean"
        }
      },
      completedWhen: {
        serializedName: "completedWhen",
        type: {
          name: "String"
        }
      },
      locations: {
        serializedName: "locations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationSimpleListDto"
            }
          }
        }
      },
      functions: {
        serializedName: "functions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FunctionSimpleDto"
            }
          }
        }
      }
    }
  }
};

export const KnowledgePathProgressDetailingFilterDto: msRest.CompositeMapper = {
  serializedName: "KnowledgePathProgressDetailingFilterDto",
  type: {
    name: "Composite",
    className: "KnowledgePathProgressDetailingFilterDto",
    modelProperties: {
      locationIds: {
        nullable: true,
        serializedName: "locationIds",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Number"
            }
          }
        }
      },
      functionIds: {
        nullable: true,
        serializedName: "functionIds",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Number"
            }
          }
        }
      }
    }
  }
};

export const KnowledgePathProgressFunctionListDto: msRest.CompositeMapper = {
  serializedName: "KnowledgePathProgressFunctionListDto",
  type: {
    name: "Composite",
    className: "KnowledgePathProgressFunctionListDto",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      functionId: {
        serializedName: "functionId",
        type: {
          name: "Number"
        }
      },
      functionProperty: {
        serializedName: "function",
        type: {
          name: "Composite",
          className: "FunctionListSimpleDto"
        }
      },
      currentReaderCount: {
        serializedName: "currentReaderCount",
        type: {
          name: "Number"
        }
      },
      expectedReaderCount: {
        serializedName: "expectedReaderCount",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const KnowledgePathProgressLocationListDto: msRest.CompositeMapper = {
  serializedName: "KnowledgePathProgressLocationListDto",
  type: {
    name: "Composite",
    className: "KnowledgePathProgressLocationListDto",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Number"
        }
      },
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "LocationSimpleListDto"
        }
      },
      currentReaderCount: {
        serializedName: "currentReaderCount",
        type: {
          name: "Number"
        }
      },
      expectedReaderCount: {
        serializedName: "expectedReaderCount",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const KnowledgePathStartDto: msRest.CompositeMapper = {
  serializedName: "KnowledgePathStartDto",
  type: {
    name: "Composite",
    className: "KnowledgePathStartDto",
    modelProperties: {
      knowledgePathId: {
        serializedName: "knowledgePathId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      articles: {
        serializedName: "articles",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "KnowledgeArticleStartDto"
            }
          }
        }
      },
      exams: {
        serializedName: "exams",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "KnowledgeExamStartDto"
            }
          }
        }
      }
    }
  }
};

export const LabelDto: msRest.CompositeMapper = {
  serializedName: "LabelDto",
  type: {
    name: "Composite",
    className: "LabelDto",
    modelProperties: {
      labelId: {
        serializedName: "labelId",
        type: {
          name: "Number"
        }
      },
      content: {
        serializedName: "content",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const LabelListDto: msRest.CompositeMapper = {
  serializedName: "LabelListDto",
  type: {
    name: "Composite",
    className: "LabelListDto",
    modelProperties: {
      labelId: {
        serializedName: "labelId",
        type: {
          name: "Number"
        }
      },
      content: {
        serializedName: "content",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const LabelSimpleListDto: msRest.CompositeMapper = {
  serializedName: "LabelSimpleListDto",
  type: {
    name: "Composite",
    className: "LabelSimpleListDto",
    modelProperties: {
      labelId: {
        serializedName: "labelId",
        type: {
          name: "Number"
        }
      },
      content: {
        serializedName: "content",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const LabelRelationListDto: msRest.CompositeMapper = {
  serializedName: "LabelRelationListDto",
  type: {
    name: "Composite",
    className: "LabelRelationListDto",
    modelProperties: {
      labelId: {
        serializedName: "labelId",
        type: {
          name: "Number"
        }
      },
      label: {
        serializedName: "label",
        type: {
          name: "Composite",
          className: "LabelSimpleListDto"
        }
      }
    }
  }
};

export const LabelSimpleDto: msRest.CompositeMapper = {
  serializedName: "LabelSimpleDto",
  type: {
    name: "Composite",
    className: "LabelSimpleDto",
    modelProperties: {
      labelId: {
        serializedName: "labelId",
        type: {
          name: "Number"
        }
      },
      content: {
        serializedName: "content",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const LabeledEntityDto: msRest.CompositeMapper = {
  serializedName: "LabeledEntityDto",
  type: {
    name: "Composite",
    className: "LabeledEntityDto",
    modelProperties: {
      entityId: {
        serializedName: "entityId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const LabelSummaryDto: msRest.CompositeMapper = {
  serializedName: "LabelSummaryDto",
  type: {
    name: "Composite",
    className: "LabelSummaryDto",
    modelProperties: {
      labels: {
        serializedName: "labels",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LabelSimpleDto"
            }
          }
        }
      },
      similarEntities: {
        serializedName: "similarEntities",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LabeledEntityDto"
            }
          }
        }
      }
    }
  }
};

export const LearnLabelsQueueDto: msRest.CompositeMapper = {
  serializedName: "LearnLabelsQueueDto",
  type: {
    name: "Composite",
    className: "LearnLabelsQueueDto",
    modelProperties: {
      ticketStereotypeId: {
        nullable: true,
        serializedName: "ticketStereotypeId",
        type: {
          name: "Number"
        }
      },
      missionStereotypeId: {
        nullable: true,
        serializedName: "missionStereotypeId",
        type: {
          name: "Number"
        }
      },
      resourceStereotypeId: {
        nullable: true,
        serializedName: "resourceStereotypeId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const LinkOrgaAttachmentDto: msRest.CompositeMapper = {
  serializedName: "LinkOrgaAttachmentDto",
  type: {
    name: "Composite",
    className: "LinkOrgaAttachmentDto",
    modelProperties: {
      attachmentId: {
        serializedName: "attachmentId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5"
          ]
        }
      },
      link: {
        serializedName: "link",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const LinkToContactDto: msRest.CompositeMapper = {
  serializedName: "LinkToContactDto",
  type: {
    name: "Composite",
    className: "LinkToContactDto",
    modelProperties: {
      linkedElementId: {
        serializedName: "linkedElementId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5"
          ]
        }
      },
      element: {
        serializedName: "element",
        type: {
          name: "Composite",
          className: "ContactSimpleDto"
        }
      }
    }
  }
};

export const LinkToContractDto: msRest.CompositeMapper = {
  serializedName: "LinkToContractDto",
  type: {
    name: "Composite",
    className: "LinkToContractDto",
    modelProperties: {
      linkedElementId: {
        serializedName: "linkedElementId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5"
          ]
        }
      },
      element: {
        serializedName: "element",
        type: {
          name: "Composite",
          className: "ContractSimpleDto"
        }
      }
    }
  }
};

export const LinkToMissionDto: msRest.CompositeMapper = {
  serializedName: "LinkToMissionDto",
  type: {
    name: "Composite",
    className: "LinkToMissionDto",
    modelProperties: {
      linkedElementId: {
        serializedName: "linkedElementId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5"
          ]
        }
      },
      element: {
        serializedName: "element",
        type: {
          name: "Composite",
          className: "MissionSimpleDto"
        }
      }
    }
  }
};

export const LinkToResourceDto: msRest.CompositeMapper = {
  serializedName: "LinkToResourceDto",
  type: {
    name: "Composite",
    className: "LinkToResourceDto",
    modelProperties: {
      linkedElementId: {
        serializedName: "linkedElementId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5"
          ]
        }
      },
      element: {
        serializedName: "element",
        type: {
          name: "Composite",
          className: "ResourceSimpleDto"
        }
      }
    }
  }
};

export const TicketSimpleDto: msRest.CompositeMapper = {
  serializedName: "TicketSimpleDto",
  type: {
    name: "Composite",
    className: "TicketSimpleDto",
    modelProperties: {
      ticketId: {
        serializedName: "ticketId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const LinkToTicketDto: msRest.CompositeMapper = {
  serializedName: "LinkToTicketDto",
  type: {
    name: "Composite",
    className: "LinkToTicketDto",
    modelProperties: {
      linkedElementId: {
        serializedName: "linkedElementId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5"
          ]
        }
      },
      element: {
        serializedName: "element",
        type: {
          name: "Composite",
          className: "TicketSimpleDto"
        }
      }
    }
  }
};

export const LocationGroupDto: msRest.CompositeMapper = {
  serializedName: "LocationGroupDto",
  type: {
    name: "Composite",
    className: "LocationGroupDto",
    modelProperties: {
      locationGroupId: {
        serializedName: "locationGroupId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      isSystem: {
        serializedName: "isSystem",
        type: {
          name: "Boolean"
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueDto"
            }
          }
        }
      }
    }
  }
};

export const LocationGroupInfoDto: msRest.CompositeMapper = {
  serializedName: "LocationGroupInfoDto",
  type: {
    name: "Composite",
    className: "LocationGroupInfoDto",
    modelProperties: {
      infoId: {
        serializedName: "infoId",
        type: {
          name: "Number"
        }
      },
      locationGroupId: {
        serializedName: "locationGroupId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const LocationGroupListDto: msRest.CompositeMapper = {
  serializedName: "LocationGroupListDto",
  type: {
    name: "Composite",
    className: "LocationGroupListDto",
    modelProperties: {
      locationGroupId: {
        serializedName: "locationGroupId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      isSystem: {
        serializedName: "isSystem",
        type: {
          name: "Boolean"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleListDto"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueListDto"
            }
          }
        }
      }
    }
  }
};

export const LocationGroupListForContactDto: msRest.CompositeMapper = {
  serializedName: "LocationGroupListForContactDto",
  type: {
    name: "Composite",
    className: "LocationGroupListForContactDto",
    modelProperties: {
      locationGroupId: {
        serializedName: "locationGroupId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      isSystem: {
        serializedName: "isSystem",
        type: {
          name: "Boolean"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleListDto"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueListDto"
            }
          }
        }
      },
      isInRelation: {
        serializedName: "isInRelation",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const LocationGroupListForLocationDto: msRest.CompositeMapper = {
  serializedName: "LocationGroupListForLocationDto",
  type: {
    name: "Composite",
    className: "LocationGroupListForLocationDto",
    modelProperties: {
      locationGroupId: {
        serializedName: "locationGroupId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      isSystem: {
        serializedName: "isSystem",
        type: {
          name: "Boolean"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleListDto"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueListDto"
            }
          }
        }
      },
      isInRelation: {
        serializedName: "isInRelation",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const LocationGroupLocationDto: msRest.CompositeMapper = {
  serializedName: "LocationGroupLocationDto",
  type: {
    name: "Composite",
    className: "LocationGroupLocationDto",
    modelProperties: {
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Number"
        }
      },
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "LocationDto"
        }
      },
      locationGroupId: {
        serializedName: "locationGroupId",
        type: {
          name: "Number"
        }
      },
      locationGroup: {
        serializedName: "locationGroup",
        type: {
          name: "Composite",
          className: "LocationGroupDto"
        }
      }
    }
  }
};

export const LocationInMissionDto: msRest.CompositeMapper = {
  serializedName: "LocationInMissionDto",
  type: {
    name: "Composite",
    className: "LocationInMissionDto",
    modelProperties: {
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      locationNumber: {
        serializedName: "locationNumber",
        type: {
          name: "String"
        }
      },
      phoneNumber: {
        serializedName: "phoneNumber",
        type: {
          name: "String"
        }
      },
      info: {
        serializedName: "info",
        type: {
          name: "String"
        }
      },
      invoiceAddress: {
        serializedName: "invoiceAddress",
        type: {
          name: "Composite",
          className: "AddressSimpleDto"
        }
      },
      shippingAddress: {
        serializedName: "shippingAddress",
        type: {
          name: "Composite",
          className: "AddressSimpleDto"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleDto"
        }
      },
      addresses: {
        serializedName: "addresses",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AddressInMissionDto"
            }
          }
        }
      }
    }
  }
};

export const LocationInfoDto: msRest.CompositeMapper = {
  serializedName: "LocationInfoDto",
  type: {
    name: "Composite",
    className: "LocationInfoDto",
    modelProperties: {
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Number"
        }
      },
      infoId: {
        serializedName: "infoId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const LocationListDto: msRest.CompositeMapper = {
  serializedName: "LocationListDto",
  type: {
    name: "Composite",
    className: "LocationListDto",
    modelProperties: {
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1"
          ]
        }
      },
      phoneNumber: {
        serializedName: "phoneNumber",
        type: {
          name: "String"
        }
      },
      locationNumber: {
        serializedName: "locationNumber",
        type: {
          name: "String"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleListDto"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueListDto"
            }
          }
        }
      },
      shippingAddress: {
        serializedName: "shippingAddress",
        type: {
          name: "Composite",
          className: "AddressSimpleListDto"
        }
      },
      invoiceAddress: {
        serializedName: "invoiceAddress",
        type: {
          name: "Composite",
          className: "AddressSimpleListDto"
        }
      }
    }
  }
};

export const LocationListForAddressDto: msRest.CompositeMapper = {
  serializedName: "LocationListForAddressDto",
  type: {
    name: "Composite",
    className: "LocationListForAddressDto",
    modelProperties: {
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1"
          ]
        }
      },
      phoneNumber: {
        serializedName: "phoneNumber",
        type: {
          name: "String"
        }
      },
      locationNumber: {
        serializedName: "locationNumber",
        type: {
          name: "String"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleListDto"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueListDto"
            }
          }
        }
      },
      shippingAddress: {
        serializedName: "shippingAddress",
        type: {
          name: "Composite",
          className: "AddressSimpleListDto"
        }
      },
      invoiceAddress: {
        serializedName: "invoiceAddress",
        type: {
          name: "Composite",
          className: "AddressSimpleListDto"
        }
      },
      isInRelation: {
        serializedName: "isInRelation",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const LocationListForContactDto: msRest.CompositeMapper = {
  serializedName: "LocationListForContactDto",
  type: {
    name: "Composite",
    className: "LocationListForContactDto",
    modelProperties: {
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1"
          ]
        }
      },
      phoneNumber: {
        serializedName: "phoneNumber",
        type: {
          name: "String"
        }
      },
      locationNumber: {
        serializedName: "locationNumber",
        type: {
          name: "String"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleListDto"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueListDto"
            }
          }
        }
      },
      shippingAddress: {
        serializedName: "shippingAddress",
        type: {
          name: "Composite",
          className: "AddressSimpleListDto"
        }
      },
      invoiceAddress: {
        serializedName: "invoiceAddress",
        type: {
          name: "Composite",
          className: "AddressSimpleListDto"
        }
      },
      isInRelation: {
        serializedName: "isInRelation",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const LocationListForContactDtoPageOutputDto: msRest.CompositeMapper = {
  serializedName: "LocationListForContactDtoPageOutputDto",
  type: {
    name: "Composite",
    className: "LocationListForContactDtoPageOutputDto",
    modelProperties: {
      paging: {
        serializedName: "paging",
        type: {
          name: "Object"
        }
      },
      items: {
        serializedName: "items",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationListForContactDto"
            }
          }
        }
      }
    }
  }
};

export const LocationListForLocationGroupDto: msRest.CompositeMapper = {
  serializedName: "LocationListForLocationGroupDto",
  type: {
    name: "Composite",
    className: "LocationListForLocationGroupDto",
    modelProperties: {
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1"
          ]
        }
      },
      phoneNumber: {
        serializedName: "phoneNumber",
        type: {
          name: "String"
        }
      },
      locationNumber: {
        serializedName: "locationNumber",
        type: {
          name: "String"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleListDto"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueListDto"
            }
          }
        }
      },
      shippingAddress: {
        serializedName: "shippingAddress",
        type: {
          name: "Composite",
          className: "AddressSimpleListDto"
        }
      },
      invoiceAddress: {
        serializedName: "invoiceAddress",
        type: {
          name: "Composite",
          className: "AddressSimpleListDto"
        }
      },
      isInRelation: {
        serializedName: "isInRelation",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const LocationListForResourcesDto: msRest.CompositeMapper = {
  serializedName: "LocationListForResourcesDto",
  type: {
    name: "Composite",
    className: "LocationListForResourcesDto",
    modelProperties: {
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleListDto"
        }
      },
      addresses: {
        serializedName: "addresses",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AddressSimpleListDto"
            }
          }
        }
      }
    }
  }
};

export const LoginByBadgeDto: msRest.CompositeMapper = {
  serializedName: "LoginByBadgeDto",
  type: {
    name: "Composite",
    className: "LoginByBadgeDto",
    modelProperties: {
      badge: {
        serializedName: "badge",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const LoginByPinDto: msRest.CompositeMapper = {
  serializedName: "LoginByPinDto",
  type: {
    name: "Composite",
    className: "LoginByPinDto",
    modelProperties: {
      pin: {
        serializedName: "pin",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const LoginByQuickAccessDto: msRest.CompositeMapper = {
  serializedName: "LoginByQuickAccessDto",
  type: {
    name: "Composite",
    className: "LoginByQuickAccessDto",
    modelProperties: {
      key: {
        serializedName: "key",
        type: {
          name: "String"
        }
      },
      resourceId: {
        serializedName: "resourceId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const LoginChallengeDto: msRest.CompositeMapper = {
  serializedName: "LoginChallengeDto",
  type: {
    name: "Composite",
    className: "LoginChallengeDto",
    modelProperties: {
      loginChallengeId: {
        serializedName: "loginChallengeId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const LoginProviderDto: msRest.CompositeMapper = {
  serializedName: "LoginProviderDto",
  type: {
    name: "Composite",
    className: "LoginProviderDto",
    modelProperties: {
      loginProviderId: {
        serializedName: "loginProviderId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      defaultRoleId: {
        nullable: true,
        serializedName: "defaultRoleId",
        type: {
          name: "Number"
        }
      },
      defaultRole: {
        serializedName: "defaultRole",
        type: {
          name: "Composite",
          className: "AppRoleDto"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const LoginProviderListDto: msRest.CompositeMapper = {
  serializedName: "LoginProviderListDto",
  type: {
    name: "Composite",
    className: "LoginProviderListDto",
    modelProperties: {
      loginProviderId: {
        serializedName: "loginProviderId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      defaultRoleId: {
        nullable: true,
        serializedName: "defaultRoleId",
        type: {
          name: "Number"
        }
      },
      defaultRole: {
        serializedName: "defaultRole",
        type: {
          name: "Composite",
          className: "AppRoleDto"
        }
      },
      provider: {
        serializedName: "provider",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const LoginProviderRoleDto: msRest.CompositeMapper = {
  serializedName: "LoginProviderRoleDto",
  type: {
    name: "Composite",
    className: "LoginProviderRoleDto",
    modelProperties: {
      loginProviderRoleId: {
        serializedName: "loginProviderRoleId",
        type: {
          name: "Number"
        }
      },
      roleId: {
        serializedName: "roleId",
        type: {
          name: "Number"
        }
      },
      role: {
        serializedName: "role",
        type: {
          name: "Composite",
          className: "AppRoleDto"
        }
      },
      loginProviderId: {
        serializedName: "loginProviderId",
        type: {
          name: "Number"
        }
      },
      loginProvider: {
        serializedName: "loginProvider",
        type: {
          name: "Composite",
          className: "SimpleLoginProviderDto"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      }
    }
  }
};

export const LoginProviderRoleListDto: msRest.CompositeMapper = {
  serializedName: "LoginProviderRoleListDto",
  type: {
    name: "Composite",
    className: "LoginProviderRoleListDto",
    modelProperties: {
      loginProviderRoleId: {
        serializedName: "loginProviderRoleId",
        type: {
          name: "Number"
        }
      },
      roleId: {
        serializedName: "roleId",
        type: {
          name: "Number"
        }
      },
      role: {
        serializedName: "role",
        type: {
          name: "Composite",
          className: "AppRoleListDto"
        }
      },
      loginProviderId: {
        serializedName: "loginProviderId",
        type: {
          name: "Number"
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      }
    }
  }
};

export const LookBackOnResourceConsumptionQueueDto: msRest.CompositeMapper = {
  serializedName: "LookBackOnResourceConsumptionQueueDto",
  type: {
    name: "Composite",
    className: "LookBackOnResourceConsumptionQueueDto",
    modelProperties: {
      resourceTaskId: {
        serializedName: "resourceTaskId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const LookBackResourceTaskConsumptionTriggerDto: msRest.CompositeMapper = {
  serializedName: "LookBackResourceTaskConsumptionTriggerDto",
  type: {
    name: "Composite",
    className: "LookBackResourceTaskConsumptionTriggerDto",
    modelProperties: {
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "2",
            "3",
            "4"
          ]
        }
      },
      upperLimit: {
        serializedName: "upperLimit",
        type: {
          name: "Number"
        }
      },
      lowerLimit: {
        serializedName: "lowerLimit",
        type: {
          name: "Number"
        }
      },
      interval: {
        serializedName: "interval",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const MailBoxDto: msRest.CompositeMapper = {
  serializedName: "MailBoxDto",
  type: {
    name: "Composite",
    className: "MailBoxDto",
    modelProperties: {
      mailBoxId: {
        serializedName: "mailBoxId",
        type: {
          name: "Number"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      address: {
        serializedName: "address",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const MailBoxListDto: msRest.CompositeMapper = {
  serializedName: "MailBoxListDto",
  type: {
    name: "Composite",
    className: "MailBoxListDto",
    modelProperties: {
      mailBoxId: {
        serializedName: "mailBoxId",
        type: {
          name: "Number"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      address: {
        serializedName: "address",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const MailDto: msRest.CompositeMapper = {
  serializedName: "MailDto",
  type: {
    name: "Composite",
    className: "MailDto",
    modelProperties: {
      from: {
        serializedName: "from",
        type: {
          name: "Composite",
          className: "EmailAddressDto"
        }
      },
      to: {
        serializedName: "to",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EmailAddressDto"
            }
          }
        }
      },
      cc: {
        serializedName: "cc",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EmailAddressDto"
            }
          }
        }
      },
      bcc: {
        serializedName: "bcc",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EmailAddressDto"
            }
          }
        }
      },
      subject: {
        serializedName: "subject",
        type: {
          name: "String"
        }
      },
      text: {
        serializedName: "text",
        type: {
          name: "String"
        }
      },
      html: {
        serializedName: "html",
        type: {
          name: "String"
        }
      },
      attachments: {
        serializedName: "attachments",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FileDto"
            }
          }
        }
      },
      suppressAutoRespond: {
        serializedName: "suppressAutoRespond",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const MailReceiverListDto: msRest.CompositeMapper = {
  serializedName: "MailReceiverListDto",
  type: {
    name: "Composite",
    className: "MailReceiverListDto",
    modelProperties: {
      address: {
        serializedName: "address",
        type: {
          name: "String"
        }
      },
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3"
          ]
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "2",
            "3",
            "4",
            "5"
          ]
        }
      },
      statusReason: {
        serializedName: "statusReason",
        type: {
          name: "String"
        }
      },
      statusCreatedAt: {
        serializedName: "statusCreatedAt",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const MailLogListDto: msRest.CompositeMapper = {
  serializedName: "MailLogListDto",
  type: {
    name: "Composite",
    className: "MailLogListDto",
    modelProperties: {
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      subject: {
        serializedName: "subject",
        type: {
          name: "String"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "String"
        }
      },
      receivers: {
        serializedName: "receivers",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "MailReceiverListDto"
            }
          }
        }
      }
    }
  }
};

export const TicketByMailDefinitionDto: msRest.CompositeMapper = {
  serializedName: "TicketByMailDefinitionDto",
  type: {
    name: "Composite",
    className: "TicketByMailDefinitionDto",
    modelProperties: {
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleDto"
        }
      },
      priority: {
        serializedName: "priority",
        type: {
          name: "Composite",
          className: "PrioritySimpleDto"
        }
      },
      resource: {
        serializedName: "resource",
        type: {
          name: "Composite",
          className: "ResourceSimpleDto"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "Composite",
          className: "StateSimpleDto"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const MailNodeDto: msRest.CompositeMapper = {
  serializedName: "MailNodeDto",
  type: {
    name: "Composite",
    className: "MailNodeDto",
    modelProperties: {
      mailNodeId: {
        serializedName: "mailNodeId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      isDefault: {
        serializedName: "isDefault",
        type: {
          name: "Boolean"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      emailAddress: {
        serializedName: "emailAddress",
        type: {
          name: "String"
        }
      },
      newTicketSettings: {
        serializedName: "newTicketSettings",
        type: {
          name: "Composite",
          className: "TicketByMailDefinitionDto"
        }
      }
    }
  }
};

export const MailNodeListDto: msRest.CompositeMapper = {
  serializedName: "MailNodeListDto",
  type: {
    name: "Composite",
    className: "MailNodeListDto",
    modelProperties: {
      mailNodeId: {
        serializedName: "mailNodeId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const MailNodeSimpleDto: msRest.CompositeMapper = {
  serializedName: "MailNodeSimpleDto",
  type: {
    name: "Composite",
    className: "MailNodeSimpleDto",
    modelProperties: {
      mailNodeId: {
        serializedName: "mailNodeId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const MailOutQueueDto: msRest.CompositeMapper = {
  serializedName: "MailOutQueueDto",
  type: {
    name: "Composite",
    className: "MailOutQueueDto",
    modelProperties: {
      tenantId: {
        nullable: true,
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      subject: {
        serializedName: "subject",
        type: {
          name: "String"
        }
      },
      from: {
        serializedName: "from",
        type: {
          name: "Composite",
          className: "EmailAddressDto"
        }
      },
      to: {
        serializedName: "to",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EmailAddressDto"
            }
          }
        }
      },
      cc: {
        serializedName: "cc",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EmailAddressDto"
            }
          }
        }
      },
      templateName: {
        serializedName: "templateName",
        type: {
          name: "String"
        }
      },
      templateContext: {
        serializedName: "templateContext",
        type: {
          name: "Object"
        }
      },
      attachments: {
        serializedName: "attachments",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FileDto"
            }
          }
        }
      },
      suppressAutoRespond: {
        serializedName: "suppressAutoRespond",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const MailStatusDto: msRest.CompositeMapper = {
  serializedName: "MailStatusDto",
  type: {
    name: "Composite",
    className: "MailStatusDto",
    modelProperties: {
      smtpId: {
        serializedName: "smtpId",
        type: {
          name: "String"
        }
      },
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "2",
            "3",
            "4",
            "5"
          ]
        }
      },
      reason: {
        serializedName: "reason",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ManualExecutionDto: msRest.CompositeMapper = {
  serializedName: "ManualExecutionDto",
  type: {
    name: "Composite",
    className: "ManualExecutionDto",
    modelProperties: {
      executionId: {
        serializedName: "executionId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "4"
          ]
        }
      }
    }
  }
};

export const ManualExecutionPlanViewV1Dto: msRest.CompositeMapper = {
  serializedName: "ManualExecutionPlanViewV1Dto",
  type: {
    name: "Composite",
    className: "ManualExecutionPlanViewV1Dto",
    modelProperties: {
      executionId: {
        serializedName: "executionId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "4"
          ]
        }
      }
    }
  }
};

export const ManualExecutionV1Dto: msRest.CompositeMapper = {
  serializedName: "ManualExecutionV1Dto",
  type: {
    name: "Composite",
    className: "ManualExecutionV1Dto",
    modelProperties: {
      executionId: {
        serializedName: "executionId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "4"
          ]
        }
      }
    }
  }
};

export const MarkItemAsSolutionDto: msRest.CompositeMapper = {
  serializedName: "MarkItemAsSolutionDto",
  type: {
    name: "Composite",
    className: "MarkItemAsSolutionDto",
    modelProperties: {
      itemId: {
        serializedName: "itemId",
        type: {
          name: "Number"
        }
      },
      isSolution: {
        serializedName: "isSolution",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const MassImportDto: msRest.CompositeMapper = {
  serializedName: "MassImportDto",
  type: {
    name: "Composite",
    className: "MassImportDto",
    modelProperties: {
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      importType: {
        serializedName: "importType",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      csvContent: {
        serializedName: "csvContent",
        type: {
          name: "String"
        }
      },
      importBehavior: {
        serializedName: "importBehavior",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      }
    }
  }
};

export const ProductSubscriptionSimpleDto: msRest.CompositeMapper = {
  serializedName: "ProductSubscriptionSimpleDto",
  type: {
    name: "Composite",
    className: "ProductSubscriptionSimpleDto",
    modelProperties: {
      expiresIn: {
        serializedName: "expiresIn",
        type: {
          name: "String"
        }
      },
      isActive: {
        serializedName: "isActive",
        type: {
          name: "Boolean"
        }
      },
      edition: {
        serializedName: "edition",
        type: {
          name: "Enum",
          allowedValues: [
            "2",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "31",
            "32",
            "33",
            "34",
            "35",
            "36",
            "37",
            "38",
            "39",
            "40",
            "41",
            "42",
            "43",
            "44",
            "45",
            "46",
            "47"
          ]
        }
      }
    }
  }
};

export const TenantInfoDto: msRest.CompositeMapper = {
  serializedName: "TenantInfoDto",
  type: {
    name: "Composite",
    className: "TenantInfoDto",
    modelProperties: {
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      domain: {
        serializedName: "domain",
        type: {
          name: "String"
        }
      },
      displayName: {
        readOnly: true,
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      subscriptions: {
        serializedName: "subscriptions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ProductSubscriptionSimpleDto"
            }
          }
        }
      },
      roles: {
        serializedName: "roles",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AppRoleInfoDto"
            }
          }
        }
      },
      names: {
        serializedName: "names",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ContactSimpleDto"
            }
          }
        }
      }
    }
  }
};

export const MeDto: msRest.CompositeMapper = {
  serializedName: "MeDto",
  type: {
    name: "Composite",
    className: "MeDto",
    modelProperties: {
      userId: {
        serializedName: "userId",
        type: {
          name: "Number"
        }
      },
      isGuest: {
        serializedName: "isGuest",
        type: {
          name: "Boolean"
        }
      },
      userTimeZone: {
        serializedName: "userTimeZone",
        type: {
          name: "String"
        }
      },
      userCulture: {
        serializedName: "userCulture",
        type: {
          name: "String"
        }
      },
      tenants: {
        serializedName: "tenants",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TenantInfoDto"
            }
          }
        }
      }
    }
  }
};

export const MessageIssueDto: msRest.CompositeMapper = {
  serializedName: "MessageIssueDto",
  type: {
    name: "Composite",
    className: "MessageIssueDto",
    modelProperties: {
      issueId: {
        serializedName: "issueId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueDto"
            }
          }
        }
      },
      resource: {
        serializedName: "resource",
        type: {
          name: "Composite",
          className: "ResourceSimpleDto"
        }
      },
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "LocationSimpleDto"
        }
      }
    }
  }
};

export const MessageIssueListDto: msRest.CompositeMapper = {
  serializedName: "MessageIssueListDto",
  type: {
    name: "Composite",
    className: "MessageIssueListDto",
    modelProperties: {
      issueId: {
        serializedName: "issueId",
        type: {
          name: "Number"
        }
      },
      issueType: {
        serializedName: "issueType",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "5"
          ]
        }
      },
      issue: {
        serializedName: "issue",
        type: {
          name: "Composite",
          className: "IssueDetailsListDto"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleListDto"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueListDto"
            }
          }
        }
      }
    }
  }
};

export const StereotypeUsageDto: msRest.CompositeMapper = {
  serializedName: "StereotypeUsageDto",
  type: {
    name: "Composite",
    className: "StereotypeUsageDto",
    modelProperties: {
      stereotypeId: {
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      isDefault: {
        serializedName: "isDefault",
        type: {
          name: "Boolean"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      },
      entityType: {
        serializedName: "entityType",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
            "31",
            "32",
            "33",
            "34",
            "35",
            "36",
            "37",
            "38",
            "39",
            "40",
            "41",
            "42",
            "43",
            "44",
            "45"
          ]
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      },
      customPropertySets: {
        serializedName: "customPropertySets",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomSetReferenceDto"
            }
          }
        }
      }
    }
  }
};

export const MessageIssuePreviewDto: msRest.CompositeMapper = {
  serializedName: "MessageIssuePreviewDto",
  type: {
    name: "Composite",
    className: "MessageIssuePreviewDto",
    modelProperties: {
      issueTemplateId: {
        serializedName: "issueTemplateId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      issueStereotype: {
        serializedName: "issueStereotype",
        type: {
          name: "Composite",
          className: "StereotypeUsageDto"
        }
      },
      contentType: {
        serializedName: "contentType",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "5"
          ]
        }
      }
    }
  }
};

export const MetaEntryDto: msRest.CompositeMapper = {
  serializedName: "MetaEntryDto",
  type: {
    name: "Composite",
    className: "MetaEntryDto",
    modelProperties: {
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "1"
          ]
        }
      }
    }
  }
};

export const MissionStateTransitionDto: msRest.CompositeMapper = {
  serializedName: "MissionStateTransitionDto",
  type: {
    name: "Composite",
    className: "MissionStateTransitionDto",
    modelProperties: {
      occuredAt: {
        serializedName: "occuredAt",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4"
          ]
        }
      }
    }
  }
};

export const MissionAppDto: msRest.CompositeMapper = {
  serializedName: "MissionAppDto",
  type: {
    name: "Composite",
    className: "MissionAppDto",
    modelProperties: {
      missionId: {
        serializedName: "missionId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      actualStart: {
        serializedName: "actualStart",
        type: {
          name: "String"
        }
      },
      actualEnd: {
        serializedName: "actualEnd",
        type: {
          name: "String"
        }
      },
      plannedStart: {
        serializedName: "plannedStart",
        type: {
          name: "String"
        }
      },
      plannedEnd: {
        serializedName: "plannedEnd",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      },
      solutionContact: {
        serializedName: "solutionContact",
        type: {
          name: "Composite",
          className: "EditorDto"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeUsageDto"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueDto"
            }
          }
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4"
          ]
        }
      },
      stateTransitions: {
        serializedName: "stateTransitions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "MissionStateTransitionDto"
            }
          }
        }
      }
    }
  }
};

export const StereotypeTtm: msRest.CompositeMapper = {
  serializedName: "StereotypeTtm",
  type: {
    name: "Composite",
    className: "StereotypeTtm",
    modelProperties: {
      stereotypeId: {
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const MissionReportGroupTtm: msRest.CompositeMapper = {
  serializedName: "MissionReportGroupTtm",
  type: {
    name: "Composite",
    className: "MissionReportGroupTtm",
    modelProperties: {
      missionReportGroupId: {
        serializedName: "missionReportGroupId",
        type: {
          name: "Number"
        }
      },
      total: {
        serializedName: "total",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      articles: {
        serializedName: "articles",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ArticleUsageTtm"
            }
          }
        }
      }
    }
  }
};

export const MissionReportTtm: msRest.CompositeMapper = {
  serializedName: "MissionReportTtm",
  type: {
    name: "Composite",
    className: "MissionReportTtm",
    modelProperties: {
      missionReportId: {
        serializedName: "missionReportId",
        type: {
          name: "Number"
        }
      },
      total: {
        serializedName: "total",
        type: {
          name: "Number"
        }
      },
      properties: {
        serializedName: "properties",
        type: {
          name: "Object"
        }
      },
      groups: {
        serializedName: "groups",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "MissionReportGroupTtm"
            }
          }
        }
      }
    }
  }
};

export const MissionInspectionReportTtm: msRest.CompositeMapper = {
  serializedName: "MissionInspectionReportTtm",
  type: {
    name: "Composite",
    className: "MissionInspectionReportTtm",
    modelProperties: {
      isOk: {
        serializedName: "isOk",
        type: {
          name: "Boolean"
        }
      },
      total: {
        serializedName: "total",
        type: {
          name: "Number"
        }
      },
      passedCount: {
        serializedName: "passedCount",
        type: {
          name: "Number"
        }
      },
      passedPercentage: {
        serializedName: "passedPercentage",
        type: {
          name: "Number"
        }
      },
      properties: {
        serializedName: "properties",
        type: {
          name: "Object"
        }
      }
    }
  }
};

export const MissionByInspectionDescriptionTtm: msRest.CompositeMapper = {
  serializedName: "MissionByInspectionDescriptionTtm",
  type: {
    name: "Composite",
    className: "MissionByInspectionDescriptionTtm",
    modelProperties: {
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      link: {
        serializedName: "link",
        type: {
          name: "String"
        }
      },
      actualStart: {
        serializedName: "actualStart",
        type: {
          name: "String"
        }
      },
      actualEnd: {
        serializedName: "actualEnd",
        type: {
          name: "String"
        }
      },
      plannedEnd: {
        serializedName: "plannedEnd",
        type: {
          name: "String"
        }
      },
      plannedStart: {
        serializedName: "plannedStart",
        type: {
          name: "String"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeTtm"
        }
      },
      solutionContact: {
        serializedName: "solutionContact",
        type: {
          name: "Composite",
          className: "ContactTtm"
        }
      },
      stateName: {
        serializedName: "stateName",
        type: {
          name: "String"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4"
          ]
        }
      },
      report: {
        serializedName: "report",
        type: {
          name: "Composite",
          className: "MissionReportTtm"
        }
      },
      resource: {
        serializedName: "resource",
        type: {
          name: "Composite",
          className: "ResourceTtm"
        }
      },
      invoiceAddress: {
        serializedName: "invoiceAddress",
        type: {
          name: "Composite",
          className: "AddressTtm"
        }
      },
      shippingAddress: {
        serializedName: "shippingAddress",
        type: {
          name: "Composite",
          className: "AddressTtm"
        }
      },
      currentUser: {
        serializedName: "currentUser",
        type: {
          name: "Composite",
          className: "ContactTtm"
        }
      },
      controlPoint: {
        serializedName: "controlPoint",
        type: {
          name: "Composite",
          className: "ControlPointTtm"
        }
      },
      inspection: {
        serializedName: "inspection",
        type: {
          name: "Composite",
          className: "MissionInspectionReportTtm"
        }
      }
    }
  }
};

export const MissionSolutionDto: msRest.CompositeMapper = {
  serializedName: "MissionSolutionDto",
  type: {
    name: "Composite",
    className: "MissionSolutionDto",
    modelProperties: {
      solutionId: {
        serializedName: "solutionId",
        type: {
          name: "Number"
        }
      },
      members: {
        serializedName: "members",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "SolutionMemberDto"
            }
          }
        }
      },
      attachments: {
        serializedName: "attachments",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AttachmentForTechDto"
            }
          }
        }
      },
      threadId: {
        serializedName: "threadId",
        type: {
          name: "String"
        }
      },
      threadMailAddress: {
        serializedName: "threadMailAddress",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ResourcePathPartDto: msRest.CompositeMapper = {
  serializedName: "ResourcePathPartDto",
  type: {
    name: "Composite",
    className: "ResourcePathPartDto",
    modelProperties: {
      index: {
        serializedName: "index",
        type: {
          name: "Number"
        }
      },
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ResourceInMissionDto: msRest.CompositeMapper = {
  serializedName: "ResourceInMissionDto",
  type: {
    name: "Composite",
    className: "ResourceInMissionDto",
    modelProperties: {
      resourceId: {
        serializedName: "resourceId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      inventoryNumber: {
        serializedName: "inventoryNumber",
        type: {
          name: "String"
        }
      },
      path: {
        serializedName: "path",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ResourcePathPartDto"
            }
          }
        }
      },
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "LocationInMissionDto"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleDto"
        }
      }
    }
  }
};

export const QuestInMissionDto: msRest.CompositeMapper = {
  serializedName: "QuestInMissionDto",
  type: {
    name: "Composite",
    className: "QuestInMissionDto",
    modelProperties: {
      functionProperty: {
        serializedName: "function",
        type: {
          name: "Composite",
          className: "FunctionSimpleDto"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "5"
          ]
        }
      }
    }
  }
};

export const MissionSourceDto: msRest.CompositeMapper = {
  serializedName: "MissionSourceDto",
  type: {
    name: "Composite",
    className: "MissionSourceDto",
    modelProperties: {
      primaryIssue: {
        serializedName: "primaryIssue",
        type: {
          name: "Composite",
          className: "IssueSimpleDto"
        }
      }
    }
  }
};

export const MissionByInspectionDto: msRest.CompositeMapper = {
  serializedName: "MissionByInspectionDto",
  type: {
    name: "Composite",
    className: "MissionByInspectionDto",
    modelProperties: {
      missionId: {
        serializedName: "missionId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      },
      isSendingMail: {
        serializedName: "isSendingMail",
        type: {
          name: "Boolean"
        }
      },
      lastEmailSend: {
        serializedName: "lastEmailSend",
        type: {
          name: "String"
        }
      },
      actualStart: {
        serializedName: "actualStart",
        type: {
          name: "String"
        }
      },
      actualEnd: {
        serializedName: "actualEnd",
        type: {
          name: "String"
        }
      },
      plannedStart: {
        serializedName: "plannedStart",
        type: {
          name: "String"
        }
      },
      plannedEnd: {
        serializedName: "plannedEnd",
        type: {
          name: "String"
        }
      },
      isCompleted: {
        serializedName: "isCompleted",
        type: {
          name: "Boolean"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4"
          ]
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4"
          ]
        }
      },
      lastModified: {
        serializedName: "lastModified",
        type: {
          name: "String"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "String"
        }
      },
      creator: {
        serializedName: "creator",
        type: {
          name: "Composite",
          className: "CreatedByDto"
        }
      },
      invoiceAddress: {
        serializedName: "invoiceAddress",
        type: {
          name: "Composite",
          className: "AddressSimpleDto"
        }
      },
      shippingAddress: {
        serializedName: "shippingAddress",
        type: {
          name: "Composite",
          className: "AddressSimpleDto"
        }
      },
      solutionId: {
        serializedName: "solutionId",
        type: {
          name: "Number"
        }
      },
      solution: {
        serializedName: "solution",
        type: {
          name: "Composite",
          className: "MissionSolutionDto"
        }
      },
      reportId: {
        nullable: true,
        serializedName: "reportId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "LocationInMissionDto"
        }
      },
      resource: {
        serializedName: "resource",
        type: {
          name: "Composite",
          className: "ResourceInMissionDto"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueDto"
            }
          }
        }
      },
      links: {
        serializedName: "links",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LinkDto"
            }
          }
        }
      },
      attachments: {
        serializedName: "attachments",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AttachmentForTechDto"
            }
          }
        }
      },
      isLabelingSince: {
        serializedName: "isLabelingSince",
        type: {
          name: "String"
        }
      },
      labels: {
        serializedName: "labels",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LabelSimpleDto"
            }
          }
        }
      },
      similarEntities: {
        serializedName: "similarEntities",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LabeledEntityDto"
            }
          }
        }
      },
      solutionContact: {
        serializedName: "solutionContact",
        type: {
          name: "Composite",
          className: "EditorDto"
        }
      },
      quest: {
        serializedName: "quest",
        type: {
          name: "Composite",
          className: "QuestInMissionDto"
        }
      },
      precursor: {
        serializedName: "precursor",
        type: {
          name: "Composite",
          className: "MissionSimpleDto"
        }
      },
      successors: {
        serializedName: "successors",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "MissionSimpleDto"
            }
          }
        }
      },
      origin: {
        serializedName: "origin",
        type: {
          name: "Composite",
          className: "MissionSourceDto"
        }
      },
      controlPoint: {
        serializedName: "controlPoint",
        type: {
          name: "Composite",
          className: "ControlPointInMissionDto"
        }
      },
      inspectionId: {
        nullable: true,
        serializedName: "inspectionId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const MissionByManualDto: msRest.CompositeMapper = {
  serializedName: "MissionByManualDto",
  type: {
    name: "Composite",
    className: "MissionByManualDto",
    modelProperties: {
      missionId: {
        serializedName: "missionId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      },
      isSendingMail: {
        serializedName: "isSendingMail",
        type: {
          name: "Boolean"
        }
      },
      lastEmailSend: {
        serializedName: "lastEmailSend",
        type: {
          name: "String"
        }
      },
      actualStart: {
        serializedName: "actualStart",
        type: {
          name: "String"
        }
      },
      actualEnd: {
        serializedName: "actualEnd",
        type: {
          name: "String"
        }
      },
      plannedStart: {
        serializedName: "plannedStart",
        type: {
          name: "String"
        }
      },
      plannedEnd: {
        serializedName: "plannedEnd",
        type: {
          name: "String"
        }
      },
      isCompleted: {
        serializedName: "isCompleted",
        type: {
          name: "Boolean"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4"
          ]
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4"
          ]
        }
      },
      lastModified: {
        serializedName: "lastModified",
        type: {
          name: "String"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "String"
        }
      },
      creator: {
        serializedName: "creator",
        type: {
          name: "Composite",
          className: "CreatedByDto"
        }
      },
      invoiceAddress: {
        serializedName: "invoiceAddress",
        type: {
          name: "Composite",
          className: "AddressSimpleDto"
        }
      },
      shippingAddress: {
        serializedName: "shippingAddress",
        type: {
          name: "Composite",
          className: "AddressSimpleDto"
        }
      },
      solutionId: {
        serializedName: "solutionId",
        type: {
          name: "Number"
        }
      },
      solution: {
        serializedName: "solution",
        type: {
          name: "Composite",
          className: "MissionSolutionDto"
        }
      },
      reportId: {
        nullable: true,
        serializedName: "reportId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "LocationInMissionDto"
        }
      },
      resource: {
        serializedName: "resource",
        type: {
          name: "Composite",
          className: "ResourceInMissionDto"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueDto"
            }
          }
        }
      },
      links: {
        serializedName: "links",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LinkDto"
            }
          }
        }
      },
      attachments: {
        serializedName: "attachments",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AttachmentForTechDto"
            }
          }
        }
      },
      isLabelingSince: {
        serializedName: "isLabelingSince",
        type: {
          name: "String"
        }
      },
      labels: {
        serializedName: "labels",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LabelSimpleDto"
            }
          }
        }
      },
      similarEntities: {
        serializedName: "similarEntities",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LabeledEntityDto"
            }
          }
        }
      },
      solutionContact: {
        serializedName: "solutionContact",
        type: {
          name: "Composite",
          className: "EditorDto"
        }
      },
      quest: {
        serializedName: "quest",
        type: {
          name: "Composite",
          className: "QuestInMissionDto"
        }
      },
      precursor: {
        serializedName: "precursor",
        type: {
          name: "Composite",
          className: "MissionSimpleDto"
        }
      },
      successors: {
        serializedName: "successors",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "MissionSimpleDto"
            }
          }
        }
      },
      origin: {
        serializedName: "origin",
        type: {
          name: "Composite",
          className: "MissionSourceDto"
        }
      }
    }
  }
};

export const ResourceTaskModelDto: msRest.CompositeMapper = {
  serializedName: "ResourceTaskModelDto",
  type: {
    name: "Composite",
    className: "ResourceTaskModelDto",
    modelProperties: {
      resourceTaskId: {
        serializedName: "resourceTaskId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const MissionByTaskDescriptionTtm: msRest.CompositeMapper = {
  serializedName: "MissionByTaskDescriptionTtm",
  type: {
    name: "Composite",
    className: "MissionByTaskDescriptionTtm",
    modelProperties: {
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      link: {
        serializedName: "link",
        type: {
          name: "String"
        }
      },
      actualStart: {
        serializedName: "actualStart",
        type: {
          name: "String"
        }
      },
      actualEnd: {
        serializedName: "actualEnd",
        type: {
          name: "String"
        }
      },
      plannedEnd: {
        serializedName: "plannedEnd",
        type: {
          name: "String"
        }
      },
      plannedStart: {
        serializedName: "plannedStart",
        type: {
          name: "String"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeTtm"
        }
      },
      solutionContact: {
        serializedName: "solutionContact",
        type: {
          name: "Composite",
          className: "ContactTtm"
        }
      },
      stateName: {
        serializedName: "stateName",
        type: {
          name: "String"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4"
          ]
        }
      },
      report: {
        serializedName: "report",
        type: {
          name: "Composite",
          className: "MissionReportTtm"
        }
      },
      resource: {
        serializedName: "resource",
        type: {
          name: "Composite",
          className: "ResourceTtm"
        }
      },
      invoiceAddress: {
        serializedName: "invoiceAddress",
        type: {
          name: "Composite",
          className: "AddressTtm"
        }
      },
      shippingAddress: {
        serializedName: "shippingAddress",
        type: {
          name: "Composite",
          className: "AddressTtm"
        }
      },
      currentUser: {
        serializedName: "currentUser",
        type: {
          name: "Composite",
          className: "ContactTtm"
        }
      },
      task: {
        serializedName: "task",
        type: {
          name: "Composite",
          className: "ResourceTaskModelDto"
        }
      }
    }
  }
};

export const ResourceTaskSimpleDto: msRest.CompositeMapper = {
  serializedName: "ResourceTaskSimpleDto",
  type: {
    name: "Composite",
    className: "ResourceTaskSimpleDto",
    modelProperties: {
      resourceTaskId: {
        serializedName: "resourceTaskId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      isActivated: {
        serializedName: "isActivated",
        type: {
          name: "Boolean"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const MissionByTaskDto: msRest.CompositeMapper = {
  serializedName: "MissionByTaskDto",
  type: {
    name: "Composite",
    className: "MissionByTaskDto",
    modelProperties: {
      missionId: {
        serializedName: "missionId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      },
      isSendingMail: {
        serializedName: "isSendingMail",
        type: {
          name: "Boolean"
        }
      },
      lastEmailSend: {
        serializedName: "lastEmailSend",
        type: {
          name: "String"
        }
      },
      actualStart: {
        serializedName: "actualStart",
        type: {
          name: "String"
        }
      },
      actualEnd: {
        serializedName: "actualEnd",
        type: {
          name: "String"
        }
      },
      plannedStart: {
        serializedName: "plannedStart",
        type: {
          name: "String"
        }
      },
      plannedEnd: {
        serializedName: "plannedEnd",
        type: {
          name: "String"
        }
      },
      isCompleted: {
        serializedName: "isCompleted",
        type: {
          name: "Boolean"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4"
          ]
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4"
          ]
        }
      },
      lastModified: {
        serializedName: "lastModified",
        type: {
          name: "String"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "String"
        }
      },
      creator: {
        serializedName: "creator",
        type: {
          name: "Composite",
          className: "CreatedByDto"
        }
      },
      invoiceAddress: {
        serializedName: "invoiceAddress",
        type: {
          name: "Composite",
          className: "AddressSimpleDto"
        }
      },
      shippingAddress: {
        serializedName: "shippingAddress",
        type: {
          name: "Composite",
          className: "AddressSimpleDto"
        }
      },
      solutionId: {
        serializedName: "solutionId",
        type: {
          name: "Number"
        }
      },
      solution: {
        serializedName: "solution",
        type: {
          name: "Composite",
          className: "MissionSolutionDto"
        }
      },
      reportId: {
        nullable: true,
        serializedName: "reportId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "LocationInMissionDto"
        }
      },
      resource: {
        serializedName: "resource",
        type: {
          name: "Composite",
          className: "ResourceInMissionDto"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueDto"
            }
          }
        }
      },
      links: {
        serializedName: "links",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LinkDto"
            }
          }
        }
      },
      attachments: {
        serializedName: "attachments",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AttachmentForTechDto"
            }
          }
        }
      },
      isLabelingSince: {
        serializedName: "isLabelingSince",
        type: {
          name: "String"
        }
      },
      labels: {
        serializedName: "labels",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LabelSimpleDto"
            }
          }
        }
      },
      similarEntities: {
        serializedName: "similarEntities",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LabeledEntityDto"
            }
          }
        }
      },
      solutionContact: {
        serializedName: "solutionContact",
        type: {
          name: "Composite",
          className: "EditorDto"
        }
      },
      quest: {
        serializedName: "quest",
        type: {
          name: "Composite",
          className: "QuestInMissionDto"
        }
      },
      precursor: {
        serializedName: "precursor",
        type: {
          name: "Composite",
          className: "MissionSimpleDto"
        }
      },
      successors: {
        serializedName: "successors",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "MissionSimpleDto"
            }
          }
        }
      },
      origin: {
        serializedName: "origin",
        type: {
          name: "Composite",
          className: "MissionSourceDto"
        }
      },
      task: {
        serializedName: "task",
        type: {
          name: "Composite",
          className: "ResourceTaskSimpleDto"
        }
      }
    }
  }
};

export const PriorityModelTtm: msRest.CompositeMapper = {
  serializedName: "PriorityModelTtm",
  type: {
    name: "Composite",
    className: "PriorityModelTtm",
    modelProperties: {
      priorityId: {
        serializedName: "priorityId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const TicketTtm: msRest.CompositeMapper = {
  serializedName: "TicketTtm",
  type: {
    name: "Composite",
    className: "TicketTtm",
    modelProperties: {
      ticketId: {
        serializedName: "ticketId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      link: {
        serializedName: "link",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      resource: {
        serializedName: "resource",
        type: {
          name: "Composite",
          className: "ResourceTtm"
        }
      },
      priority: {
        serializedName: "priority",
        type: {
          name: "Composite",
          className: "PriorityModelTtm"
        }
      },
      escalationLevel: {
        serializedName: "escalationLevel",
        type: {
          name: "Composite",
          className: "EscalationLevelTtm"
        }
      },
      editor: {
        serializedName: "editor",
        type: {
          name: "Composite",
          className: "ContactTtm"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeTtm"
        }
      },
      properties: {
        serializedName: "properties",
        type: {
          name: "Object"
        }
      }
    }
  }
};

export const MissionByTicketDescriptionTtm: msRest.CompositeMapper = {
  serializedName: "MissionByTicketDescriptionTtm",
  type: {
    name: "Composite",
    className: "MissionByTicketDescriptionTtm",
    modelProperties: {
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      link: {
        serializedName: "link",
        type: {
          name: "String"
        }
      },
      actualStart: {
        serializedName: "actualStart",
        type: {
          name: "String"
        }
      },
      actualEnd: {
        serializedName: "actualEnd",
        type: {
          name: "String"
        }
      },
      plannedEnd: {
        serializedName: "plannedEnd",
        type: {
          name: "String"
        }
      },
      plannedStart: {
        serializedName: "plannedStart",
        type: {
          name: "String"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeTtm"
        }
      },
      solutionContact: {
        serializedName: "solutionContact",
        type: {
          name: "Composite",
          className: "ContactTtm"
        }
      },
      stateName: {
        serializedName: "stateName",
        type: {
          name: "String"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4"
          ]
        }
      },
      report: {
        serializedName: "report",
        type: {
          name: "Composite",
          className: "MissionReportTtm"
        }
      },
      resource: {
        serializedName: "resource",
        type: {
          name: "Composite",
          className: "ResourceTtm"
        }
      },
      invoiceAddress: {
        serializedName: "invoiceAddress",
        type: {
          name: "Composite",
          className: "AddressTtm"
        }
      },
      shippingAddress: {
        serializedName: "shippingAddress",
        type: {
          name: "Composite",
          className: "AddressTtm"
        }
      },
      currentUser: {
        serializedName: "currentUser",
        type: {
          name: "Composite",
          className: "ContactTtm"
        }
      },
      ticket: {
        serializedName: "ticket",
        type: {
          name: "Composite",
          className: "TicketTtm"
        }
      }
    }
  }
};

export const TicketInMissionDto: msRest.CompositeMapper = {
  serializedName: "TicketInMissionDto",
  type: {
    name: "Composite",
    className: "TicketInMissionDto",
    modelProperties: {
      ticketId: {
        serializedName: "ticketId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const MissionByTicketDto: msRest.CompositeMapper = {
  serializedName: "MissionByTicketDto",
  type: {
    name: "Composite",
    className: "MissionByTicketDto",
    modelProperties: {
      missionId: {
        serializedName: "missionId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      },
      isSendingMail: {
        serializedName: "isSendingMail",
        type: {
          name: "Boolean"
        }
      },
      lastEmailSend: {
        serializedName: "lastEmailSend",
        type: {
          name: "String"
        }
      },
      actualStart: {
        serializedName: "actualStart",
        type: {
          name: "String"
        }
      },
      actualEnd: {
        serializedName: "actualEnd",
        type: {
          name: "String"
        }
      },
      plannedStart: {
        serializedName: "plannedStart",
        type: {
          name: "String"
        }
      },
      plannedEnd: {
        serializedName: "plannedEnd",
        type: {
          name: "String"
        }
      },
      isCompleted: {
        serializedName: "isCompleted",
        type: {
          name: "Boolean"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4"
          ]
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4"
          ]
        }
      },
      lastModified: {
        serializedName: "lastModified",
        type: {
          name: "String"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "String"
        }
      },
      creator: {
        serializedName: "creator",
        type: {
          name: "Composite",
          className: "CreatedByDto"
        }
      },
      invoiceAddress: {
        serializedName: "invoiceAddress",
        type: {
          name: "Composite",
          className: "AddressSimpleDto"
        }
      },
      shippingAddress: {
        serializedName: "shippingAddress",
        type: {
          name: "Composite",
          className: "AddressSimpleDto"
        }
      },
      solutionId: {
        serializedName: "solutionId",
        type: {
          name: "Number"
        }
      },
      solution: {
        serializedName: "solution",
        type: {
          name: "Composite",
          className: "MissionSolutionDto"
        }
      },
      reportId: {
        nullable: true,
        serializedName: "reportId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "LocationInMissionDto"
        }
      },
      resource: {
        serializedName: "resource",
        type: {
          name: "Composite",
          className: "ResourceInMissionDto"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueDto"
            }
          }
        }
      },
      links: {
        serializedName: "links",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LinkDto"
            }
          }
        }
      },
      attachments: {
        serializedName: "attachments",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AttachmentForTechDto"
            }
          }
        }
      },
      isLabelingSince: {
        serializedName: "isLabelingSince",
        type: {
          name: "String"
        }
      },
      labels: {
        serializedName: "labels",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LabelSimpleDto"
            }
          }
        }
      },
      similarEntities: {
        serializedName: "similarEntities",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LabeledEntityDto"
            }
          }
        }
      },
      solutionContact: {
        serializedName: "solutionContact",
        type: {
          name: "Composite",
          className: "EditorDto"
        }
      },
      quest: {
        serializedName: "quest",
        type: {
          name: "Composite",
          className: "QuestInMissionDto"
        }
      },
      precursor: {
        serializedName: "precursor",
        type: {
          name: "Composite",
          className: "MissionSimpleDto"
        }
      },
      successors: {
        serializedName: "successors",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "MissionSimpleDto"
            }
          }
        }
      },
      origin: {
        serializedName: "origin",
        type: {
          name: "Composite",
          className: "MissionSourceDto"
        }
      },
      ticket: {
        serializedName: "ticket",
        type: {
          name: "Composite",
          className: "TicketInMissionDto"
        }
      }
    }
  }
};

export const QuestInMissionListDto: msRest.CompositeMapper = {
  serializedName: "QuestInMissionListDto",
  type: {
    name: "Composite",
    className: "QuestInMissionListDto",
    modelProperties: {
      functionProperty: {
        serializedName: "function",
        type: {
          name: "Composite",
          className: "FunctionListSimpleDto"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "5"
          ]
        }
      }
    }
  }
};

export const ResourceManufacturerSimpleListDto: msRest.CompositeMapper = {
  serializedName: "ResourceManufacturerSimpleListDto",
  type: {
    name: "Composite",
    className: "ResourceManufacturerSimpleListDto",
    modelProperties: {
      resourceManufacturerId: {
        serializedName: "resourceManufacturerId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ResourceModelSimpleListDto: msRest.CompositeMapper = {
  serializedName: "ResourceModelSimpleListDto",
  type: {
    name: "Composite",
    className: "ResourceModelSimpleListDto",
    modelProperties: {
      resourceModelId: {
        serializedName: "resourceModelId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      resourceManufacturerId: {
        serializedName: "resourceManufacturerId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ResourceInMissionListDto: msRest.CompositeMapper = {
  serializedName: "ResourceInMissionListDto",
  type: {
    name: "Composite",
    className: "ResourceInMissionListDto",
    modelProperties: {
      resourceId: {
        serializedName: "resourceId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      },
      inventoryNumber: {
        serializedName: "inventoryNumber",
        type: {
          name: "String"
        }
      },
      currentState: {
        serializedName: "currentState",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3"
          ]
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      manufacturer: {
        serializedName: "manufacturer",
        type: {
          name: "Composite",
          className: "ResourceManufacturerSimpleListDto"
        }
      },
      model: {
        serializedName: "model",
        type: {
          name: "Composite",
          className: "ResourceModelSimpleListDto"
        }
      },
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "LocationListDto"
        }
      },
      path: {
        serializedName: "path",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ResourcePathPartSimpleListDto"
            }
          }
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleListDto"
        }
      }
    }
  }
};

export const TicketInMissionListDto: msRest.CompositeMapper = {
  serializedName: "TicketInMissionListDto",
  type: {
    name: "Composite",
    className: "TicketInMissionListDto",
    modelProperties: {
      ticketId: {
        serializedName: "ticketId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      },
      currentState: {
        serializedName: "currentState",
        type: {
          name: "Composite",
          className: "StateSimpleListDto"
        }
      },
      editor: {
        serializedName: "editor",
        type: {
          name: "Composite",
          className: "ContactInMissionListDto"
        }
      },
      priority: {
        serializedName: "priority",
        type: {
          name: "Composite",
          className: "PrioritySimpleListDto"
        }
      },
      resource: {
        serializedName: "resource",
        type: {
          name: "Composite",
          className: "ResourceInMissionListDto"
        }
      },
      escalationLevel: {
        serializedName: "escalationLevel",
        type: {
          name: "Composite",
          className: "EscalationLevelSimpleListDto"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleListDto"
        }
      }
    }
  }
};

export const MissionInspectionReportInMissionDto: msRest.CompositeMapper = {
  serializedName: "MissionInspectionReportInMissionDto",
  type: {
    name: "Composite",
    className: "MissionInspectionReportInMissionDto",
    modelProperties: {
      isOk: {
        serializedName: "isOk",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const ResourceTaskSimpleListDto: msRest.CompositeMapper = {
  serializedName: "ResourceTaskSimpleListDto",
  type: {
    name: "Composite",
    className: "ResourceTaskSimpleListDto",
    modelProperties: {
      resourceTaskId: {
        serializedName: "resourceTaskId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      endOffset: {
        serializedName: "endOffset",
        type: {
          name: "String"
        }
      },
      endDate: {
        serializedName: "endDate",
        type: {
          name: "String"
        }
      },
      startOffSet: {
        serializedName: "startOffSet",
        type: {
          name: "String"
        }
      },
      startDate: {
        serializedName: "startDate",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      isActivated: {
        serializedName: "isActivated",
        type: {
          name: "Boolean"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      },
      isCancelingObsoleteMissions: {
        serializedName: "isCancelingObsoleteMissions",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const MissionCompleteDto: msRest.CompositeMapper = {
  serializedName: "MissionCompleteDto",
  type: {
    name: "Composite",
    className: "MissionCompleteDto",
    modelProperties: {
      missionId: {
        serializedName: "missionId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      cost: {
        serializedName: "cost",
        type: {
          name: "Number"
        }
      },
      actualStart: {
        serializedName: "actualStart",
        type: {
          name: "String"
        }
      },
      actualEnd: {
        serializedName: "actualEnd",
        type: {
          name: "String"
        }
      },
      plannedStart: {
        serializedName: "plannedStart",
        type: {
          name: "String"
        }
      },
      plannedEnd: {
        serializedName: "plannedEnd",
        type: {
          name: "String"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "String"
        }
      },
      lastModified: {
        serializedName: "lastModified",
        type: {
          name: "String"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4"
          ]
        }
      },
      isCompleted: {
        serializedName: "isCompleted",
        type: {
          name: "Boolean"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4"
          ]
        }
      },
      solutionId: {
        serializedName: "solutionId",
        type: {
          name: "Number"
        }
      },
      ticketId: {
        nullable: true,
        serializedName: "ticketId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      labelRelations: {
        serializedName: "labelRelations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LabelRelationListDto"
            }
          }
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleListDto"
        }
      },
      solutionContact: {
        serializedName: "solutionContact",
        type: {
          name: "Composite",
          className: "ContactInMissionListDto"
        }
      },
      quest: {
        serializedName: "quest",
        type: {
          name: "Composite",
          className: "QuestInMissionListDto"
        }
      },
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "LocationListDto"
        }
      },
      sourceTicket: {
        serializedName: "sourceTicket",
        type: {
          name: "Composite",
          className: "TicketInMissionListDto"
        }
      },
      sourceControlPoint: {
        serializedName: "sourceControlPoint",
        type: {
          name: "Composite",
          className: "ControlPointInMissionListDto"
        }
      },
      inspection: {
        serializedName: "inspection",
        type: {
          name: "Composite",
          className: "MissionInspectionReportInMissionDto"
        }
      },
      sourceTask: {
        serializedName: "sourceTask",
        type: {
          name: "Composite",
          className: "ResourceTaskSimpleListDto"
        }
      },
      locationNumber: {
        serializedName: "locationNumber",
        type: {
          name: "String"
        }
      },
      sourceResource: {
        serializedName: "sourceResource",
        type: {
          name: "Composite",
          className: "ResourceInMissionListDto"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueListDto"
            }
          }
        }
      },
      invoiceAddress: {
        serializedName: "invoiceAddress",
        type: {
          name: "Composite",
          className: "AddressInMissionListDto"
        }
      },
      shippingAddress: {
        serializedName: "shippingAddress",
        type: {
          name: "Composite",
          className: "AddressInMissionListDto"
        }
      }
    }
  }
};

export const MissionDescriptionContextDto: msRest.CompositeMapper = {
  serializedName: "MissionDescriptionContextDto",
  type: {
    name: "Composite",
    className: "MissionDescriptionContextDto",
    modelProperties: {
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "181",
            "182",
            "183",
            "184",
            "191",
            "192",
            "193",
            "194"
          ]
        }
      },
      missionId: {
        serializedName: "missionId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      actualStart: {
        serializedName: "actualStart",
        type: {
          name: "String"
        }
      },
      actualEnd: {
        serializedName: "actualEnd",
        type: {
          name: "String"
        }
      },
      plannedEnd: {
        serializedName: "plannedEnd",
        type: {
          name: "String"
        }
      },
      plannedStart: {
        serializedName: "plannedStart",
        type: {
          name: "String"
        }
      },
      reportId: {
        nullable: true,
        serializedName: "reportId",
        type: {
          name: "Number"
        }
      },
      resourceId: {
        nullable: true,
        serializedName: "resourceId",
        type: {
          name: "Number"
        }
      },
      invoiceAddressId: {
        nullable: true,
        serializedName: "invoiceAddressId",
        type: {
          name: "Number"
        }
      },
      shippingAddressId: {
        nullable: true,
        serializedName: "shippingAddressId",
        type: {
          name: "Number"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      solutionContactId: {
        nullable: true,
        serializedName: "solutionContactId",
        type: {
          name: "Number"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4"
          ]
        }
      },
      taskId: {
        nullable: true,
        serializedName: "taskId",
        type: {
          name: "Number"
        }
      },
      ticketId: {
        nullable: true,
        serializedName: "ticketId",
        type: {
          name: "Number"
        }
      },
      controlPointId: {
        nullable: true,
        serializedName: "controlPointId",
        type: {
          name: "Number"
        }
      },
      inspectionId: {
        nullable: true,
        serializedName: "inspectionId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const MissionDescriptionTtm: msRest.CompositeMapper = {
  serializedName: "MissionDescriptionTtm",
  type: {
    name: "Composite",
    className: "MissionDescriptionTtm",
    modelProperties: {
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      link: {
        serializedName: "link",
        type: {
          name: "String"
        }
      },
      actualStart: {
        serializedName: "actualStart",
        type: {
          name: "String"
        }
      },
      actualEnd: {
        serializedName: "actualEnd",
        type: {
          name: "String"
        }
      },
      plannedEnd: {
        serializedName: "plannedEnd",
        type: {
          name: "String"
        }
      },
      plannedStart: {
        serializedName: "plannedStart",
        type: {
          name: "String"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeTtm"
        }
      },
      solutionContact: {
        serializedName: "solutionContact",
        type: {
          name: "Composite",
          className: "ContactTtm"
        }
      },
      stateName: {
        serializedName: "stateName",
        type: {
          name: "String"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4"
          ]
        }
      },
      report: {
        serializedName: "report",
        type: {
          name: "Composite",
          className: "MissionReportTtm"
        }
      },
      resource: {
        serializedName: "resource",
        type: {
          name: "Composite",
          className: "ResourceTtm"
        }
      },
      invoiceAddress: {
        serializedName: "invoiceAddress",
        type: {
          name: "Composite",
          className: "AddressTtm"
        }
      },
      shippingAddress: {
        serializedName: "shippingAddress",
        type: {
          name: "Composite",
          className: "AddressTtm"
        }
      },
      currentUser: {
        serializedName: "currentUser",
        type: {
          name: "Composite",
          className: "ContactTtm"
        }
      }
    }
  }
};

export const MissionDistanceListDto: msRest.CompositeMapper = {
  serializedName: "MissionDistanceListDto",
  type: {
    name: "Composite",
    className: "MissionDistanceListDto",
    modelProperties: {
      missionId: {
        serializedName: "missionId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      },
      actualStart: {
        serializedName: "actualStart",
        type: {
          name: "String"
        }
      },
      actualEnd: {
        serializedName: "actualEnd",
        type: {
          name: "String"
        }
      },
      plannedStart: {
        serializedName: "plannedStart",
        type: {
          name: "String"
        }
      },
      plannedEnd: {
        serializedName: "plannedEnd",
        type: {
          name: "String"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4"
          ]
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4"
          ]
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleListDto"
        }
      },
      solutionContact: {
        serializedName: "solutionContact",
        type: {
          name: "Composite",
          className: "ContactInMissionListDto"
        }
      },
      sourceResource: {
        serializedName: "sourceResource",
        type: {
          name: "Composite",
          className: "ResourceInMissionListDto"
        }
      },
      shippingAddress: {
        serializedName: "shippingAddress",
        type: {
          name: "Composite",
          className: "AddressInMissionListDto"
        }
      }
    }
  }
};

export const MissionDto: msRest.CompositeMapper = {
  serializedName: "MissionDto",
  type: {
    name: "Composite",
    className: "MissionDto",
    modelProperties: {
      missionId: {
        serializedName: "missionId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      },
      isSendingMail: {
        serializedName: "isSendingMail",
        type: {
          name: "Boolean"
        }
      },
      lastEmailSend: {
        serializedName: "lastEmailSend",
        type: {
          name: "String"
        }
      },
      actualStart: {
        serializedName: "actualStart",
        type: {
          name: "String"
        }
      },
      actualEnd: {
        serializedName: "actualEnd",
        type: {
          name: "String"
        }
      },
      plannedStart: {
        serializedName: "plannedStart",
        type: {
          name: "String"
        }
      },
      plannedEnd: {
        serializedName: "plannedEnd",
        type: {
          name: "String"
        }
      },
      isCompleted: {
        serializedName: "isCompleted",
        type: {
          name: "Boolean"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4"
          ]
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4"
          ]
        }
      },
      lastModified: {
        serializedName: "lastModified",
        type: {
          name: "String"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "String"
        }
      },
      creator: {
        serializedName: "creator",
        type: {
          name: "Composite",
          className: "CreatedByDto"
        }
      },
      invoiceAddress: {
        serializedName: "invoiceAddress",
        type: {
          name: "Composite",
          className: "AddressSimpleDto"
        }
      },
      shippingAddress: {
        serializedName: "shippingAddress",
        type: {
          name: "Composite",
          className: "AddressSimpleDto"
        }
      },
      solutionId: {
        serializedName: "solutionId",
        type: {
          name: "Number"
        }
      },
      solution: {
        serializedName: "solution",
        type: {
          name: "Composite",
          className: "MissionSolutionDto"
        }
      },
      reportId: {
        nullable: true,
        serializedName: "reportId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "LocationInMissionDto"
        }
      },
      resource: {
        serializedName: "resource",
        type: {
          name: "Composite",
          className: "ResourceInMissionDto"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueDto"
            }
          }
        }
      },
      links: {
        serializedName: "links",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LinkDto"
            }
          }
        }
      },
      attachments: {
        serializedName: "attachments",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AttachmentForTechDto"
            }
          }
        }
      },
      isLabelingSince: {
        serializedName: "isLabelingSince",
        type: {
          name: "String"
        }
      },
      labels: {
        serializedName: "labels",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LabelSimpleDto"
            }
          }
        }
      },
      similarEntities: {
        serializedName: "similarEntities",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LabeledEntityDto"
            }
          }
        }
      },
      solutionContact: {
        serializedName: "solutionContact",
        type: {
          name: "Composite",
          className: "EditorDto"
        }
      },
      quest: {
        serializedName: "quest",
        type: {
          name: "Composite",
          className: "QuestInMissionDto"
        }
      },
      precursor: {
        serializedName: "precursor",
        type: {
          name: "Composite",
          className: "MissionSimpleDto"
        }
      },
      successors: {
        serializedName: "successors",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "MissionSimpleDto"
            }
          }
        }
      },
      origin: {
        serializedName: "origin",
        type: {
          name: "Composite",
          className: "MissionSourceDto"
        }
      }
    }
  }
};

export const MissionInspectionReportDto: msRest.CompositeMapper = {
  serializedName: "MissionInspectionReportDto",
  type: {
    name: "Composite",
    className: "MissionInspectionReportDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      missionInspectionReportId: {
        serializedName: "missionInspectionReportId",
        type: {
          name: "Number"
        }
      },
      isOk: {
        serializedName: "isOk",
        type: {
          name: "Boolean"
        }
      },
      passedPercentage: {
        serializedName: "passedPercentage",
        type: {
          name: "Number"
        }
      },
      isOutdated: {
        serializedName: "isOutdated",
        type: {
          name: "Boolean"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      missionId: {
        serializedName: "missionId",
        type: {
          name: "Number"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueDto"
            }
          }
        }
      },
      lastModified: {
        serializedName: "lastModified",
        type: {
          name: "String"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "String"
        }
      },
      creator: {
        serializedName: "creator",
        type: {
          name: "Composite",
          className: "CreatedByDto"
        }
      }
    }
  }
};

export const MissionIssueDetailsDto: msRest.CompositeMapper = {
  serializedName: "MissionIssueDetailsDto",
  type: {
    name: "Composite",
    className: "MissionIssueDetailsDto",
    modelProperties: {
      missionId: {
        serializedName: "missionId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4"
          ]
        }
      }
    }
  }
};

export const MissionIssueDto: msRest.CompositeMapper = {
  serializedName: "MissionIssueDto",
  type: {
    name: "Composite",
    className: "MissionIssueDto",
    modelProperties: {
      issueId: {
        serializedName: "issueId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueDto"
            }
          }
        }
      },
      mission: {
        serializedName: "mission",
        type: {
          name: "Composite",
          className: "MissionIssueDetailsDto"
        }
      },
      resource: {
        serializedName: "resource",
        type: {
          name: "Composite",
          className: "ResourceSimpleDto"
        }
      },
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "LocationSimpleDto"
        }
      },
      priority: {
        serializedName: "priority",
        type: {
          name: "Composite",
          className: "PrioritySimpleDto"
        }
      }
    }
  }
};

export const MissionSimpleListDto: msRest.CompositeMapper = {
  serializedName: "MissionSimpleListDto",
  type: {
    name: "Composite",
    className: "MissionSimpleListDto",
    modelProperties: {
      missionId: {
        serializedName: "missionId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4"
          ]
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const MissionIssueListDto: msRest.CompositeMapper = {
  serializedName: "MissionIssueListDto",
  type: {
    name: "Composite",
    className: "MissionIssueListDto",
    modelProperties: {
      issueId: {
        serializedName: "issueId",
        type: {
          name: "Number"
        }
      },
      issueType: {
        serializedName: "issueType",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "5"
          ]
        }
      },
      issue: {
        serializedName: "issue",
        type: {
          name: "Composite",
          className: "IssueDetailsListDto"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleListDto"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueListDto"
            }
          }
        }
      },
      mission: {
        serializedName: "mission",
        type: {
          name: "Composite",
          className: "MissionSimpleListDto"
        }
      }
    }
  }
};

export const MissionIssuePreviewDto: msRest.CompositeMapper = {
  serializedName: "MissionIssuePreviewDto",
  type: {
    name: "Composite",
    className: "MissionIssuePreviewDto",
    modelProperties: {
      issueTemplateId: {
        serializedName: "issueTemplateId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      issueStereotype: {
        serializedName: "issueStereotype",
        type: {
          name: "Composite",
          className: "StereotypeUsageDto"
        }
      },
      contentType: {
        serializedName: "contentType",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "5"
          ]
        }
      },
      editorSelectorKind: {
        serializedName: "editorSelectorKind",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      },
      priorities: {
        serializedName: "priorities",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PrioritySimpleDto"
            }
          }
        }
      }
    }
  }
};

export const MissionNoteTtm: msRest.CompositeMapper = {
  serializedName: "MissionNoteTtm",
  type: {
    name: "Composite",
    className: "MissionNoteTtm",
    modelProperties: {
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      link: {
        serializedName: "link",
        type: {
          name: "String"
        }
      },
      actualStart: {
        serializedName: "actualStart",
        type: {
          name: "String"
        }
      },
      actualEnd: {
        serializedName: "actualEnd",
        type: {
          name: "String"
        }
      },
      plannedEnd: {
        serializedName: "plannedEnd",
        type: {
          name: "String"
        }
      },
      plannedStart: {
        serializedName: "plannedStart",
        type: {
          name: "String"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeTtm"
        }
      },
      solutionContact: {
        serializedName: "solutionContact",
        type: {
          name: "Composite",
          className: "ContactTtm"
        }
      },
      stateName: {
        serializedName: "stateName",
        type: {
          name: "String"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4"
          ]
        }
      },
      report: {
        serializedName: "report",
        type: {
          name: "Composite",
          className: "MissionReportTtm"
        }
      },
      resource: {
        serializedName: "resource",
        type: {
          name: "Composite",
          className: "ResourceTtm"
        }
      },
      invoiceAddress: {
        serializedName: "invoiceAddress",
        type: {
          name: "Composite",
          className: "AddressTtm"
        }
      },
      shippingAddress: {
        serializedName: "shippingAddress",
        type: {
          name: "Composite",
          className: "AddressTtm"
        }
      },
      currentUser: {
        serializedName: "currentUser",
        type: {
          name: "Composite",
          className: "ContactTtm"
        }
      }
    }
  }
};

export const MissionNotificationRuleTtm: msRest.CompositeMapper = {
  serializedName: "MissionNotificationRuleTtm",
  type: {
    name: "Composite",
    className: "MissionNotificationRuleTtm",
    modelProperties: {
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      link: {
        serializedName: "link",
        type: {
          name: "String"
        }
      },
      actualStart: {
        serializedName: "actualStart",
        type: {
          name: "String"
        }
      },
      actualEnd: {
        serializedName: "actualEnd",
        type: {
          name: "String"
        }
      },
      plannedEnd: {
        serializedName: "plannedEnd",
        type: {
          name: "String"
        }
      },
      plannedStart: {
        serializedName: "plannedStart",
        type: {
          name: "String"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeTtm"
        }
      },
      solutionContact: {
        serializedName: "solutionContact",
        type: {
          name: "Composite",
          className: "ContactTtm"
        }
      },
      stateName: {
        serializedName: "stateName",
        type: {
          name: "String"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4"
          ]
        }
      },
      report: {
        serializedName: "report",
        type: {
          name: "Composite",
          className: "MissionReportTtm"
        }
      },
      resource: {
        serializedName: "resource",
        type: {
          name: "Composite",
          className: "ResourceTtm"
        }
      },
      invoiceAddress: {
        serializedName: "invoiceAddress",
        type: {
          name: "Composite",
          className: "AddressTtm"
        }
      },
      shippingAddress: {
        serializedName: "shippingAddress",
        type: {
          name: "Composite",
          className: "AddressTtm"
        }
      }
    }
  }
};

export const MissionReceiptDto: msRest.CompositeMapper = {
  serializedName: "MissionReceiptDto",
  type: {
    name: "Composite",
    className: "MissionReceiptDto",
    modelProperties: {
      missionReceiptId: {
        serializedName: "missionReceiptId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      bookedAt: {
        serializedName: "bookedAt",
        type: {
          name: "String"
        }
      },
      debit: {
        serializedName: "debit",
        type: {
          name: "Number"
        }
      },
      credit: {
        serializedName: "credit",
        type: {
          name: "Number"
        }
      },
      taxInPercent: {
        serializedName: "taxInPercent",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const MissionReportGroupDto: msRest.CompositeMapper = {
  serializedName: "MissionReportGroupDto",
  type: {
    name: "Composite",
    className: "MissionReportGroupDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      missionReportGroupId: {
        serializedName: "missionReportGroupId",
        type: {
          name: "Number"
        }
      },
      total: {
        serializedName: "total",
        type: {
          name: "Number"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      usedArticles: {
        serializedName: "usedArticles",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ArticleUsageDto"
            }
          }
        }
      }
    }
  }
};

export const MissionReportDto: msRest.CompositeMapper = {
  serializedName: "MissionReportDto",
  type: {
    name: "Composite",
    className: "MissionReportDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      missionReportId: {
        serializedName: "missionReportId",
        type: {
          name: "Number"
        }
      },
      total: {
        serializedName: "total",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      travelDurationInHours: {
        serializedName: "travelDurationInHours",
        type: {
          name: "Number"
        }
      },
      travelDurationCost: {
        serializedName: "travelDurationCost",
        type: {
          name: "Number"
        }
      },
      workDurationInHours: {
        serializedName: "workDurationInHours",
        type: {
          name: "Number"
        }
      },
      workDurationCost: {
        serializedName: "workDurationCost",
        type: {
          name: "Number"
        }
      },
      missionId: {
        serializedName: "missionId",
        type: {
          name: "Number"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueDto"
            }
          }
        }
      },
      groups: {
        serializedName: "groups",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "MissionReportGroupDto"
            }
          }
        }
      },
      usedArticles: {
        serializedName: "usedArticles",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ArticleUsageDto"
            }
          }
        }
      }
    }
  }
};

export const MissionReportGroupSkeletonDto: msRest.CompositeMapper = {
  serializedName: "MissionReportGroupSkeletonDto",
  type: {
    name: "Composite",
    className: "MissionReportGroupSkeletonDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      missionReportGroupSkeletonId: {
        serializedName: "missionReportGroupSkeletonId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      usedArticles: {
        serializedName: "usedArticles",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ArticleUsageSkeletonDto"
            }
          }
        }
      }
    }
  }
};

export const MissionReportPreviewDto: msRest.CompositeMapper = {
  serializedName: "MissionReportPreviewDto",
  type: {
    name: "Composite",
    className: "MissionReportPreviewDto",
    modelProperties: {
      defaultTravelDurationCost: {
        serializedName: "defaultTravelDurationCost",
        type: {
          name: "Number"
        }
      },
      defaultWorkDurationCost: {
        serializedName: "defaultWorkDurationCost",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const MissionReportReceiptDto: msRest.CompositeMapper = {
  serializedName: "MissionReportReceiptDto",
  type: {
    name: "Composite",
    className: "MissionReportReceiptDto",
    modelProperties: {
      afterTax: {
        serializedName: "afterTax",
        type: {
          name: "Number"
        }
      },
      beforeTax: {
        serializedName: "beforeTax",
        type: {
          name: "Number"
        }
      },
      items: {
        serializedName: "items",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "MissionReceiptDto"
            }
          }
        }
      }
    }
  }
};

export const MissionReportSkeletonDto: msRest.CompositeMapper = {
  serializedName: "MissionReportSkeletonDto",
  type: {
    name: "Composite",
    className: "MissionReportSkeletonDto",
    modelProperties: {
      skeletonId: {
        serializedName: "skeletonId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
            "31",
            "32",
            "33",
            "34",
            "35",
            "36",
            "37",
            "38",
            "39",
            "40",
            "41",
            "42",
            "43",
            "44",
            "45"
          ]
        }
      },
      groups: {
        serializedName: "groups",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "MissionReportGroupSkeletonDto"
            }
          }
        }
      },
      usedArticles: {
        readOnly: true,
        serializedName: "usedArticles",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ArticleUsageSkeletonDto"
            }
          }
        }
      }
    }
  }
};

export const MissionSettingDto: msRest.CompositeMapper = {
  serializedName: "MissionSettingDto",
  type: {
    name: "Composite",
    className: "MissionSettingDto",
    modelProperties: {
      settingId: {
        serializedName: "settingId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      defaultTravelDurationCost: {
        serializedName: "defaultTravelDurationCost",
        type: {
          name: "Number"
        }
      },
      defaultWorkDurationCost: {
        serializedName: "defaultWorkDurationCost",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const MissionSkeletonDto: msRest.CompositeMapper = {
  serializedName: "MissionSkeletonDto",
  type: {
    name: "Composite",
    className: "MissionSkeletonDto",
    modelProperties: {
      missionSkeletonId: {
        serializedName: "missionSkeletonId",
        type: {
          name: "Number"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleDto"
        }
      },
      isCreating: {
        serializedName: "isCreating",
        type: {
          name: "Boolean"
        }
      },
      isActivating: {
        serializedName: "isActivating",
        type: {
          name: "Boolean"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const MissionSkeletonV1Dto: msRest.CompositeMapper = {
  serializedName: "MissionSkeletonV1Dto",
  type: {
    name: "Composite",
    className: "MissionSkeletonV1Dto",
    modelProperties: {
      missionSkeletonId: {
        serializedName: "missionSkeletonId",
        type: {
          name: "Number"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleDto"
        }
      },
      isCreating: {
        serializedName: "isCreating",
        type: {
          name: "Boolean"
        }
      },
      isActivating: {
        serializedName: "isActivating",
        type: {
          name: "Boolean"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const MissionTtm: msRest.CompositeMapper = {
  serializedName: "MissionTtm",
  type: {
    name: "Composite",
    className: "MissionTtm",
    modelProperties: {
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      link: {
        serializedName: "link",
        type: {
          name: "String"
        }
      },
      actualStart: {
        serializedName: "actualStart",
        type: {
          name: "String"
        }
      },
      actualEnd: {
        serializedName: "actualEnd",
        type: {
          name: "String"
        }
      },
      plannedEnd: {
        serializedName: "plannedEnd",
        type: {
          name: "String"
        }
      },
      plannedStart: {
        serializedName: "plannedStart",
        type: {
          name: "String"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeTtm"
        }
      },
      solutionContact: {
        serializedName: "solutionContact",
        type: {
          name: "Composite",
          className: "ContactTtm"
        }
      },
      stateName: {
        serializedName: "stateName",
        type: {
          name: "String"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4"
          ]
        }
      },
      report: {
        serializedName: "report",
        type: {
          name: "Composite",
          className: "MissionReportTtm"
        }
      },
      resource: {
        serializedName: "resource",
        type: {
          name: "Composite",
          className: "ResourceTtm"
        }
      },
      invoiceAddress: {
        serializedName: "invoiceAddress",
        type: {
          name: "Composite",
          className: "AddressTtm"
        }
      },
      shippingAddress: {
        serializedName: "shippingAddress",
        type: {
          name: "Composite",
          className: "AddressTtm"
        }
      }
    }
  }
};

export const MissionsPerLocationByMonthDto: msRest.CompositeMapper = {
  serializedName: "MissionsPerLocationByMonthDto",
  type: {
    name: "Composite",
    className: "MissionsPerLocationByMonthDto",
    modelProperties: {
      month: {
        serializedName: "month",
        type: {
          name: "Number"
        }
      },
      monthName: {
        serializedName: "monthName",
        type: {
          name: "String"
        }
      },
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Number"
        }
      },
      locationName: {
        serializedName: "locationName",
        type: {
          name: "String"
        }
      },
      missionCost: {
        serializedName: "missionCost",
        type: {
          name: "Number"
        }
      },
      missionCount: {
        serializedName: "missionCount",
        type: {
          name: "Number"
        }
      },
      missionWithCostCount: {
        serializedName: "missionWithCostCount",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const MissionsPerLocationByTimeDto: msRest.CompositeMapper = {
  serializedName: "MissionsPerLocationByTimeDto",
  type: {
    name: "Composite",
    className: "MissionsPerLocationByTimeDto",
    modelProperties: {
      datePoint: {
        serializedName: "datePoint",
        type: {
          name: "String"
        }
      },
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Number"
        }
      },
      locationName: {
        serializedName: "locationName",
        type: {
          name: "String"
        }
      },
      missionCost: {
        serializedName: "missionCost",
        type: {
          name: "Number"
        }
      },
      missionCount: {
        serializedName: "missionCount",
        type: {
          name: "Number"
        }
      },
      missionWithCostCount: {
        serializedName: "missionWithCostCount",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const MissionsPerLocationPerTypeByMonthDto: msRest.CompositeMapper = {
  serializedName: "MissionsPerLocationPerTypeByMonthDto",
  type: {
    name: "Composite",
    className: "MissionsPerLocationPerTypeByMonthDto",
    modelProperties: {
      month: {
        serializedName: "month",
        type: {
          name: "Number"
        }
      },
      monthName: {
        serializedName: "monthName",
        type: {
          name: "String"
        }
      },
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Number"
        }
      },
      locationName: {
        serializedName: "locationName",
        type: {
          name: "String"
        }
      },
      missionCost: {
        serializedName: "missionCost",
        type: {
          name: "Number"
        }
      },
      missionCount: {
        serializedName: "missionCount",
        type: {
          name: "Number"
        }
      },
      missionWithCostCount: {
        serializedName: "missionWithCostCount",
        type: {
          name: "Number"
        }
      },
      stereotypeName: {
        serializedName: "stereotypeName",
        type: {
          name: "String"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const MissionsPerLocationPerTypeByTimeDto: msRest.CompositeMapper = {
  serializedName: "MissionsPerLocationPerTypeByTimeDto",
  type: {
    name: "Composite",
    className: "MissionsPerLocationPerTypeByTimeDto",
    modelProperties: {
      datePoint: {
        serializedName: "datePoint",
        type: {
          name: "String"
        }
      },
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Number"
        }
      },
      locationName: {
        serializedName: "locationName",
        type: {
          name: "String"
        }
      },
      missionCost: {
        serializedName: "missionCost",
        type: {
          name: "Number"
        }
      },
      missionCount: {
        serializedName: "missionCount",
        type: {
          name: "Number"
        }
      },
      missionWithCostCount: {
        serializedName: "missionWithCostCount",
        type: {
          name: "Number"
        }
      },
      stereotypeName: {
        serializedName: "stereotypeName",
        type: {
          name: "String"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const MissionsPerResourceDto: msRest.CompositeMapper = {
  serializedName: "MissionsPerResourceDto",
  type: {
    name: "Composite",
    className: "MissionsPerResourceDto",
    modelProperties: {
      missionCount: {
        serializedName: "missionCount",
        type: {
          name: "Number"
        }
      },
      missionWithCostCount: {
        serializedName: "missionWithCostCount",
        type: {
          name: "Number"
        }
      },
      missionCost: {
        serializedName: "missionCost",
        type: {
          name: "Number"
        }
      },
      resourceStereotypeName: {
        serializedName: "resourceStereotypeName",
        type: {
          name: "String"
        }
      },
      resourceStereotypeId: {
        serializedName: "resourceStereotypeId",
        type: {
          name: "Number"
        }
      },
      manufacturer: {
        serializedName: "manufacturer",
        type: {
          name: "String"
        }
      },
      constructionDate: {
        serializedName: "constructionDate",
        type: {
          name: "String"
        }
      },
      model: {
        serializedName: "model",
        type: {
          name: "String"
        }
      },
      resourceName: {
        serializedName: "resourceName",
        type: {
          name: "String"
        }
      },
      inventoryNumber: {
        serializedName: "inventoryNumber",
        type: {
          name: "String"
        }
      },
      installationDateSystem: {
        serializedName: "installationDateSystem",
        type: {
          name: "String"
        }
      },
      locationId: {
        nullable: true,
        serializedName: "locationId",
        type: {
          name: "Number"
        }
      },
      locationNumber: {
        serializedName: "locationNumber",
        type: {
          name: "String"
        }
      },
      locationName: {
        serializedName: "locationName",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const MissionsPerResourceManufacturerDto: msRest.CompositeMapper = {
  serializedName: "MissionsPerResourceManufacturerDto",
  type: {
    name: "Composite",
    className: "MissionsPerResourceManufacturerDto",
    modelProperties: {
      resourceCount: {
        serializedName: "resourceCount",
        type: {
          name: "Number"
        }
      },
      missionCount: {
        serializedName: "missionCount",
        type: {
          name: "Number"
        }
      },
      missionWithCostCount: {
        serializedName: "missionWithCostCount",
        type: {
          name: "Number"
        }
      },
      missionCost: {
        serializedName: "missionCost",
        type: {
          name: "Number"
        }
      },
      manufacturer: {
        serializedName: "manufacturer",
        type: {
          name: "String"
        }
      },
      resourceStereotypeName: {
        serializedName: "resourceStereotypeName",
        type: {
          name: "String"
        }
      },
      resourceStereotypeId: {
        serializedName: "resourceStereotypeId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const MissionsPerSolutionContactByMonthDto: msRest.CompositeMapper = {
  serializedName: "MissionsPerSolutionContactByMonthDto",
  type: {
    name: "Composite",
    className: "MissionsPerSolutionContactByMonthDto",
    modelProperties: {
      month: {
        serializedName: "month",
        type: {
          name: "Number"
        }
      },
      monthName: {
        serializedName: "monthName",
        type: {
          name: "String"
        }
      },
      solutionContactId: {
        serializedName: "solutionContactId",
        type: {
          name: "Number"
        }
      },
      solutionContactName: {
        serializedName: "solutionContactName",
        type: {
          name: "String"
        }
      },
      solutionContactStereotypeName: {
        serializedName: "solutionContactStereotypeName",
        type: {
          name: "String"
        }
      },
      missionCost: {
        serializedName: "missionCost",
        type: {
          name: "Number"
        }
      },
      missionCount: {
        serializedName: "missionCount",
        type: {
          name: "Number"
        }
      },
      missionWithCostCount: {
        serializedName: "missionWithCostCount",
        type: {
          name: "Number"
        }
      },
      missionInTimeCount: {
        serializedName: "missionInTimeCount",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const MissionsPerSolutionContactByTimeDto: msRest.CompositeMapper = {
  serializedName: "MissionsPerSolutionContactByTimeDto",
  type: {
    name: "Composite",
    className: "MissionsPerSolutionContactByTimeDto",
    modelProperties: {
      datePoint: {
        serializedName: "datePoint",
        type: {
          name: "String"
        }
      },
      solutionContactId: {
        serializedName: "solutionContactId",
        type: {
          name: "Number"
        }
      },
      solutionContactName: {
        serializedName: "solutionContactName",
        type: {
          name: "String"
        }
      },
      solutionContactStereotypeName: {
        serializedName: "solutionContactStereotypeName",
        type: {
          name: "String"
        }
      },
      missionCost: {
        serializedName: "missionCost",
        type: {
          name: "Number"
        }
      },
      missionCount: {
        serializedName: "missionCount",
        type: {
          name: "Number"
        }
      },
      missionWithCostCount: {
        serializedName: "missionWithCostCount",
        type: {
          name: "Number"
        }
      },
      missionInTimeCount: {
        serializedName: "missionInTimeCount",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const MissionsPerSolutionContactDto: msRest.CompositeMapper = {
  serializedName: "MissionsPerSolutionContactDto",
  type: {
    name: "Composite",
    className: "MissionsPerSolutionContactDto",
    modelProperties: {
      missionCount: {
        serializedName: "missionCount",
        type: {
          name: "Number"
        }
      },
      missionWithCostCount: {
        serializedName: "missionWithCostCount",
        type: {
          name: "Number"
        }
      },
      missionInTimeCount: {
        serializedName: "missionInTimeCount",
        type: {
          name: "Number"
        }
      },
      missionCost: {
        serializedName: "missionCost",
        type: {
          name: "Number"
        }
      },
      solutionContactName: {
        serializedName: "solutionContactName",
        type: {
          name: "String"
        }
      },
      solutionContactStereotypeName: {
        serializedName: "solutionContactStereotypeName",
        type: {
          name: "String"
        }
      },
      solutionContactId: {
        serializedName: "solutionContactId",
        type: {
          name: "Number"
        }
      },
      timeWorking: {
        serializedName: "timeWorking",
        type: {
          name: "Number"
        }
      },
      timeTravel: {
        serializedName: "timeTravel",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const MissionsPerTypeDto: msRest.CompositeMapper = {
  serializedName: "MissionsPerTypeDto",
  type: {
    name: "Composite",
    className: "MissionsPerTypeDto",
    modelProperties: {
      resourceCount: {
        serializedName: "resourceCount",
        type: {
          name: "Number"
        }
      },
      missionCount: {
        serializedName: "missionCount",
        type: {
          name: "Number"
        }
      },
      missionFinishCount: {
        serializedName: "missionFinishCount",
        type: {
          name: "Number"
        }
      },
      missionWithCostCount: {
        serializedName: "missionWithCostCount",
        type: {
          name: "Number"
        }
      },
      missionCost: {
        serializedName: "missionCost",
        type: {
          name: "Number"
        }
      },
      stereotypeName: {
        serializedName: "stereotypeName",
        type: {
          name: "String"
        }
      },
      stereotypeId: {
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const MonthlyDateTimePatternDto: msRest.CompositeMapper = {
  serializedName: "MonthlyDateTimePatternDto",
  type: {
    name: "Composite",
    className: "MonthlyDateTimePatternDto",
    modelProperties: {
      dateTimePatternId: {
        serializedName: "dateTimePatternId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5"
          ]
        }
      },
      useNextOpeningDay: {
        serializedName: "useNextOpeningDay",
        type: {
          name: "Boolean"
        }
      },
      lastModified: {
        serializedName: "lastModified",
        type: {
          name: "String"
        }
      },
      plannedTimes: {
        serializedName: "plannedTimes",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PlannedTimeDto"
            }
          }
        }
      },
      startDate: {
        serializedName: "startDate",
        type: {
          name: "String"
        }
      },
      endDate: {
        serializedName: "endDate",
        type: {
          name: "String"
        }
      },
      day: {
        serializedName: "day",
        type: {
          name: "Number"
        }
      },
      repeat: {
        serializedName: "repeat",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const MonthlyDateTimePatternV1Dto: msRest.CompositeMapper = {
  serializedName: "MonthlyDateTimePatternV1Dto",
  type: {
    name: "Composite",
    className: "MonthlyDateTimePatternV1Dto",
    modelProperties: {
      dateTimePatternId: {
        serializedName: "dateTimePatternId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5"
          ]
        }
      },
      useNextOpeningDay: {
        serializedName: "useNextOpeningDay",
        type: {
          name: "Boolean"
        }
      },
      lastModified: {
        serializedName: "lastModified",
        type: {
          name: "String"
        }
      },
      plannedTimes: {
        serializedName: "plannedTimes",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PlannedTimeV1Dto"
            }
          }
        }
      },
      startDate: {
        serializedName: "startDate",
        type: {
          name: "String"
        }
      },
      endDate: {
        serializedName: "endDate",
        type: {
          name: "String"
        }
      },
      day: {
        serializedName: "day",
        type: {
          name: "Number"
        }
      },
      repeat: {
        serializedName: "repeat",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const MonthlyWeekdayDateTimePatternDto: msRest.CompositeMapper = {
  serializedName: "MonthlyWeekdayDateTimePatternDto",
  type: {
    name: "Composite",
    className: "MonthlyWeekdayDateTimePatternDto",
    modelProperties: {
      dateTimePatternId: {
        serializedName: "dateTimePatternId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5"
          ]
        }
      },
      useNextOpeningDay: {
        serializedName: "useNextOpeningDay",
        type: {
          name: "Boolean"
        }
      },
      lastModified: {
        serializedName: "lastModified",
        type: {
          name: "String"
        }
      },
      plannedTimes: {
        serializedName: "plannedTimes",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PlannedTimeDto"
            }
          }
        }
      },
      startDate: {
        serializedName: "startDate",
        type: {
          name: "String"
        }
      },
      endDate: {
        serializedName: "endDate",
        type: {
          name: "String"
        }
      },
      weekday: {
        serializedName: "weekday",
        type: {
          name: "Number"
        }
      },
      weekdayCount: {
        serializedName: "weekdayCount",
        type: {
          name: "Number"
        }
      },
      repeat: {
        serializedName: "repeat",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const MonthlyWeekdayDateTimePatternV1Dto: msRest.CompositeMapper = {
  serializedName: "MonthlyWeekdayDateTimePatternV1Dto",
  type: {
    name: "Composite",
    className: "MonthlyWeekdayDateTimePatternV1Dto",
    modelProperties: {
      dateTimePatternId: {
        serializedName: "dateTimePatternId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5"
          ]
        }
      },
      useNextOpeningDay: {
        serializedName: "useNextOpeningDay",
        type: {
          name: "Boolean"
        }
      },
      lastModified: {
        serializedName: "lastModified",
        type: {
          name: "String"
        }
      },
      plannedTimes: {
        serializedName: "plannedTimes",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PlannedTimeV1Dto"
            }
          }
        }
      },
      startDate: {
        serializedName: "startDate",
        type: {
          name: "String"
        }
      },
      endDate: {
        serializedName: "endDate",
        type: {
          name: "String"
        }
      },
      weekday: {
        serializedName: "weekday",
        type: {
          name: "Number"
        }
      },
      weekdayCount: {
        serializedName: "weekdayCount",
        type: {
          name: "Number"
        }
      },
      repeat: {
        serializedName: "repeat",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const MoveResourceQueueDto: msRest.CompositeMapper = {
  serializedName: "MoveResourceQueueDto",
  type: {
    name: "Composite",
    className: "MoveResourceQueueDto",
    modelProperties: {
      oldLocationId: {
        serializedName: "oldLocationId",
        type: {
          name: "Number"
        }
      },
      newLocationId: {
        serializedName: "newLocationId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const MultilineFormDefaultDto: msRest.CompositeMapper = {
  serializedName: "MultilineFormDefaultDto",
  type: {
    name: "Composite",
    className: "MultilineFormDefaultDto",
    modelProperties: {
      fieldValueId: {
        serializedName: "fieldValueId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12"
          ]
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const MultilineFormFieldDto: msRest.CompositeMapper = {
  serializedName: "MultilineFormFieldDto",
  type: {
    name: "Composite",
    className: "MultilineFormFieldDto",
    modelProperties: {
      formFieldId: {
        serializedName: "formFieldId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      technicalName: {
        readOnly: true,
        serializedName: "technicalName",
        type: {
          name: "String"
        }
      },
      width: {
        serializedName: "width",
        type: {
          name: "Number"
        }
      },
      isRequired: {
        serializedName: "isRequired",
        type: {
          name: "Boolean"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12"
          ]
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      defaultValues: {
        serializedName: "defaultValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FormDefaultDto"
            }
          }
        }
      },
      localFieldId: {
        serializedName: "localFieldId",
        type: {
          name: "String"
        }
      },
      minLength: {
        nullable: true,
        serializedName: "minLength",
        type: {
          name: "Number"
        }
      },
      maxLength: {
        nullable: true,
        serializedName: "maxLength",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const MultilineFormValueDto: msRest.CompositeMapper = {
  serializedName: "MultilineFormValueDto",
  type: {
    name: "Composite",
    className: "MultilineFormValueDto",
    modelProperties: {
      fieldValueId: {
        serializedName: "fieldValueId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12"
          ]
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const MuteHintDto: msRest.CompositeMapper = {
  serializedName: "MuteHintDto",
  type: {
    name: "Composite",
    className: "MuteHintDto",
    modelProperties: {
      muteUntil: {
        serializedName: "muteUntil",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const NoteCallerDto: msRest.CompositeMapper = {
  serializedName: "NoteCallerDto",
  type: {
    name: "Composite",
    className: "NoteCallerDto",
    modelProperties: {
      noteId: {
        serializedName: "noteId",
        type: {
          name: "Number"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "-1"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      },
      attachments: {
        serializedName: "attachments",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AttachmentForTechDto"
            }
          }
        }
      },
      phoneNumber: {
        serializedName: "phoneNumber",
        type: {
          name: "String"
        }
      },
      direction: {
        serializedName: "direction",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      }
    }
  }
};

export const NoteChatMessageDto: msRest.CompositeMapper = {
  serializedName: "NoteChatMessageDto",
  type: {
    name: "Composite",
    className: "NoteChatMessageDto",
    modelProperties: {
      noteId: {
        serializedName: "noteId",
        type: {
          name: "Number"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "-1"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      },
      attachments: {
        serializedName: "attachments",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AttachmentForTechDto"
            }
          }
        }
      }
    }
  }
};

export const NoteMailBoxListDto: msRest.CompositeMapper = {
  serializedName: "NoteMailBoxListDto",
  type: {
    name: "Composite",
    className: "NoteMailBoxListDto",
    modelProperties: {
      mailBox: {
        serializedName: "mailBox",
        type: {
          name: "Composite",
          className: "MailBoxListDto"
        }
      },
      relation: {
        serializedName: "relation",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4"
          ]
        }
      },
      mailBoxId: {
        serializedName: "mailBoxId",
        type: {
          name: "Number"
        }
      },
      noteMailId: {
        serializedName: "noteMailId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ResourceInNoteListDto: msRest.CompositeMapper = {
  serializedName: "ResourceInNoteListDto",
  type: {
    name: "Composite",
    className: "ResourceInNoteListDto",
    modelProperties: {
      resourceId: {
        serializedName: "resourceId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const StateListDto: msRest.CompositeMapper = {
  serializedName: "StateListDto",
  type: {
    name: "Composite",
    className: "StateListDto",
    modelProperties: {
      stateId: {
        serializedName: "stateId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      color: {
        serializedName: "color",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const NoteCompleteDto: msRest.CompositeMapper = {
  serializedName: "NoteCompleteDto",
  type: {
    name: "Composite",
    className: "NoteCompleteDto",
    modelProperties: {
      noteId: {
        serializedName: "noteId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "-1"
          ]
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      attachments: {
        serializedName: "attachments",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AttachmentForTechCompleteDto"
            }
          }
        }
      },
      chatMessage: {
        serializedName: "chatMessage",
        type: {
          name: "Composite",
          className: "ChatMessageInNoteListDto"
        }
      },
      phoneNumber: {
        serializedName: "phoneNumber",
        type: {
          name: "String"
        }
      },
      direction: {
        serializedName: "direction",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      },
      subject: {
        serializedName: "subject",
        type: {
          name: "String"
        }
      },
      to: {
        serializedName: "to",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "NoteMailBoxListDto"
            }
          }
        }
      },
      cc: {
        serializedName: "cc",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "NoteMailBoxListDto"
            }
          }
        }
      },
      bcc: {
        serializedName: "bcc",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "NoteMailBoxListDto"
            }
          }
        }
      },
      from: {
        serializedName: "from",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "NoteMailBoxListDto"
            }
          }
        }
      },
      isMissionCompleted: {
        nullable: true,
        serializedName: "isMissionCompleted",
        type: {
          name: "Boolean"
        }
      },
      missionState: {
        serializedName: "missionState",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4"
          ]
        }
      },
      previousResource: {
        serializedName: "previousResource",
        type: {
          name: "Composite",
          className: "ResourceInNoteListDto"
        }
      },
      currentResource: {
        serializedName: "currentResource",
        type: {
          name: "Composite",
          className: "ResourceInNoteListDto"
        }
      },
      stateId: {
        nullable: true,
        serializedName: "stateId",
        type: {
          name: "Number"
        }
      },
      changedToState: {
        serializedName: "changedToState",
        type: {
          name: "Composite",
          className: "StateListDto"
        }
      },
      isChangedByEmail: {
        nullable: true,
        serializedName: "isChangedByEmail",
        type: {
          name: "Boolean"
        }
      },
      causedMissionId: {
        nullable: true,
        serializedName: "causedMissionId",
        type: {
          name: "Number"
        }
      },
      causedMission: {
        serializedName: "causedMission",
        type: {
          name: "Composite",
          className: "MissionSimpleListDto"
        }
      },
      stateOfCausedMission: {
        serializedName: "stateOfCausedMission",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4"
          ]
        }
      },
      causedContract: {
        serializedName: "causedContract",
        type: {
          name: "Composite",
          className: "ContractSimpleListDto"
        }
      },
      cancelLevelId: {
        nullable: true,
        serializedName: "cancelLevelId",
        type: {
          name: "Number"
        }
      },
      cancelLevel: {
        serializedName: "cancelLevel",
        type: {
          name: "Composite",
          className: "EscalationLevelListDto"
        }
      },
      offsetForCanceledLevel: {
        serializedName: "offsetForCanceledLevel",
        type: {
          name: "String"
        }
      },
      offsetForEscalatedByEvent: {
        serializedName: "offsetForEscalatedByEvent",
        type: {
          name: "String"
        }
      },
      escalatedLevelIdByEvent: {
        nullable: true,
        serializedName: "escalatedLevelIdByEvent",
        type: {
          name: "Number"
        }
      },
      escalatedLevelByEvent: {
        serializedName: "escalatedLevelByEvent",
        type: {
          name: "Composite",
          className: "EscalationLevelListDto"
        }
      },
      isChangedByUser: {
        nullable: true,
        serializedName: "isChangedByUser",
        type: {
          name: "Boolean"
        }
      },
      escalatedLevelIdByUser: {
        nullable: true,
        serializedName: "escalatedLevelIdByUser",
        type: {
          name: "Number"
        }
      },
      escalatedLevelByUser: {
        serializedName: "escalatedLevelByUser",
        type: {
          name: "Composite",
          className: "EscalationLevelListDto"
        }
      }
    }
  }
};

export const NoteDto: msRest.CompositeMapper = {
  serializedName: "NoteDto",
  type: {
    name: "Composite",
    className: "NoteDto",
    modelProperties: {
      noteId: {
        serializedName: "noteId",
        type: {
          name: "Number"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "-1"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      },
      attachments: {
        serializedName: "attachments",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AttachmentForTechDto"
            }
          }
        }
      }
    }
  }
};

export const NoteForMissionByInspectionTtm: msRest.CompositeMapper = {
  serializedName: "NoteForMissionByInspectionTtm",
  type: {
    name: "Composite",
    className: "NoteForMissionByInspectionTtm",
    modelProperties: {
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      link: {
        serializedName: "link",
        type: {
          name: "String"
        }
      },
      actualStart: {
        serializedName: "actualStart",
        type: {
          name: "String"
        }
      },
      actualEnd: {
        serializedName: "actualEnd",
        type: {
          name: "String"
        }
      },
      plannedEnd: {
        serializedName: "plannedEnd",
        type: {
          name: "String"
        }
      },
      plannedStart: {
        serializedName: "plannedStart",
        type: {
          name: "String"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeTtm"
        }
      },
      solutionContact: {
        serializedName: "solutionContact",
        type: {
          name: "Composite",
          className: "ContactTtm"
        }
      },
      stateName: {
        serializedName: "stateName",
        type: {
          name: "String"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4"
          ]
        }
      },
      report: {
        serializedName: "report",
        type: {
          name: "Composite",
          className: "MissionReportTtm"
        }
      },
      resource: {
        serializedName: "resource",
        type: {
          name: "Composite",
          className: "ResourceTtm"
        }
      },
      invoiceAddress: {
        serializedName: "invoiceAddress",
        type: {
          name: "Composite",
          className: "AddressTtm"
        }
      },
      shippingAddress: {
        serializedName: "shippingAddress",
        type: {
          name: "Composite",
          className: "AddressTtm"
        }
      },
      currentUser: {
        serializedName: "currentUser",
        type: {
          name: "Composite",
          className: "ContactTtm"
        }
      },
      controlPoint: {
        serializedName: "controlPoint",
        type: {
          name: "Composite",
          className: "ControlPointTtm"
        }
      },
      inspection: {
        serializedName: "inspection",
        type: {
          name: "Composite",
          className: "MissionInspectionReportTtm"
        }
      }
    }
  }
};

export const NoteForMissionByTaskTtm: msRest.CompositeMapper = {
  serializedName: "NoteForMissionByTaskTtm",
  type: {
    name: "Composite",
    className: "NoteForMissionByTaskTtm",
    modelProperties: {
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      link: {
        serializedName: "link",
        type: {
          name: "String"
        }
      },
      actualStart: {
        serializedName: "actualStart",
        type: {
          name: "String"
        }
      },
      actualEnd: {
        serializedName: "actualEnd",
        type: {
          name: "String"
        }
      },
      plannedEnd: {
        serializedName: "plannedEnd",
        type: {
          name: "String"
        }
      },
      plannedStart: {
        serializedName: "plannedStart",
        type: {
          name: "String"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeTtm"
        }
      },
      solutionContact: {
        serializedName: "solutionContact",
        type: {
          name: "Composite",
          className: "ContactTtm"
        }
      },
      stateName: {
        serializedName: "stateName",
        type: {
          name: "String"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4"
          ]
        }
      },
      report: {
        serializedName: "report",
        type: {
          name: "Composite",
          className: "MissionReportTtm"
        }
      },
      resource: {
        serializedName: "resource",
        type: {
          name: "Composite",
          className: "ResourceTtm"
        }
      },
      invoiceAddress: {
        serializedName: "invoiceAddress",
        type: {
          name: "Composite",
          className: "AddressTtm"
        }
      },
      shippingAddress: {
        serializedName: "shippingAddress",
        type: {
          name: "Composite",
          className: "AddressTtm"
        }
      },
      currentUser: {
        serializedName: "currentUser",
        type: {
          name: "Composite",
          className: "ContactTtm"
        }
      },
      task: {
        serializedName: "task",
        type: {
          name: "Composite",
          className: "ResourceTaskModelDto"
        }
      }
    }
  }
};

export const NoteForMissionByTicketTtm: msRest.CompositeMapper = {
  serializedName: "NoteForMissionByTicketTtm",
  type: {
    name: "Composite",
    className: "NoteForMissionByTicketTtm",
    modelProperties: {
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      link: {
        serializedName: "link",
        type: {
          name: "String"
        }
      },
      actualStart: {
        serializedName: "actualStart",
        type: {
          name: "String"
        }
      },
      actualEnd: {
        serializedName: "actualEnd",
        type: {
          name: "String"
        }
      },
      plannedEnd: {
        serializedName: "plannedEnd",
        type: {
          name: "String"
        }
      },
      plannedStart: {
        serializedName: "plannedStart",
        type: {
          name: "String"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeTtm"
        }
      },
      solutionContact: {
        serializedName: "solutionContact",
        type: {
          name: "Composite",
          className: "ContactTtm"
        }
      },
      stateName: {
        serializedName: "stateName",
        type: {
          name: "String"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4"
          ]
        }
      },
      report: {
        serializedName: "report",
        type: {
          name: "Composite",
          className: "MissionReportTtm"
        }
      },
      resource: {
        serializedName: "resource",
        type: {
          name: "Composite",
          className: "ResourceTtm"
        }
      },
      invoiceAddress: {
        serializedName: "invoiceAddress",
        type: {
          name: "Composite",
          className: "AddressTtm"
        }
      },
      shippingAddress: {
        serializedName: "shippingAddress",
        type: {
          name: "Composite",
          className: "AddressTtm"
        }
      },
      currentUser: {
        serializedName: "currentUser",
        type: {
          name: "Composite",
          className: "ContactTtm"
        }
      },
      ticket: {
        serializedName: "ticket",
        type: {
          name: "Composite",
          className: "TicketTtm"
        }
      }
    }
  }
};

export const NoteForTicketTtm: msRest.CompositeMapper = {
  serializedName: "NoteForTicketTtm",
  type: {
    name: "Composite",
    className: "NoteForTicketTtm",
    modelProperties: {
      ticketId: {
        serializedName: "ticketId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      link: {
        serializedName: "link",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      resource: {
        serializedName: "resource",
        type: {
          name: "Composite",
          className: "ResourceTtm"
        }
      },
      priority: {
        serializedName: "priority",
        type: {
          name: "Composite",
          className: "PriorityModelTtm"
        }
      },
      escalationLevel: {
        serializedName: "escalationLevel",
        type: {
          name: "Composite",
          className: "EscalationLevelTtm"
        }
      },
      editor: {
        serializedName: "editor",
        type: {
          name: "Composite",
          className: "ContactTtm"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeTtm"
        }
      },
      properties: {
        serializedName: "properties",
        type: {
          name: "Object"
        }
      },
      currentUser: {
        serializedName: "currentUser",
        type: {
          name: "Composite",
          className: "ContactTtm"
        }
      }
    }
  }
};

export const NoteMailBoxDto: msRest.CompositeMapper = {
  serializedName: "NoteMailBoxDto",
  type: {
    name: "Composite",
    className: "NoteMailBoxDto",
    modelProperties: {
      mailBox: {
        serializedName: "mailBox",
        type: {
          name: "Composite",
          className: "MailBoxDto"
        }
      },
      relation: {
        serializedName: "relation",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4"
          ]
        }
      },
      mailBoxId: {
        serializedName: "mailBoxId",
        type: {
          name: "Number"
        }
      },
      noteMailId: {
        serializedName: "noteMailId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const NoteMailDto: msRest.CompositeMapper = {
  serializedName: "NoteMailDto",
  type: {
    name: "Composite",
    className: "NoteMailDto",
    modelProperties: {
      noteId: {
        serializedName: "noteId",
        type: {
          name: "Number"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "-1"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      },
      attachments: {
        serializedName: "attachments",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AttachmentForTechDto"
            }
          }
        }
      },
      direction: {
        serializedName: "direction",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      },
      to: {
        serializedName: "to",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "NoteMailBoxDto"
            }
          }
        }
      },
      from: {
        serializedName: "from",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "NoteMailBoxDto"
            }
          }
        }
      },
      cc: {
        serializedName: "cc",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "NoteMailBoxDto"
            }
          }
        }
      }
    }
  }
};

export const NoteTextDto: msRest.CompositeMapper = {
  serializedName: "NoteTextDto",
  type: {
    name: "Composite",
    className: "NoteTextDto",
    modelProperties: {
      noteId: {
        serializedName: "noteId",
        type: {
          name: "Number"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "-1"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      },
      attachments: {
        serializedName: "attachments",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AttachmentForTechDto"
            }
          }
        }
      }
    }
  }
};

export const NotificationTargetDto: msRest.CompositeMapper = {
  serializedName: "NotificationTargetDto",
  type: {
    name: "Composite",
    className: "NotificationTargetDto",
    modelProperties: {
      notificationTargetId: {
        serializedName: "notificationTargetId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const NotificationRuleDto: msRest.CompositeMapper = {
  serializedName: "NotificationRuleDto",
  type: {
    name: "Composite",
    className: "NotificationRuleDto",
    modelProperties: {
      notificationRuleId: {
        serializedName: "notificationRuleId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      trigger: {
        serializedName: "trigger",
        type: {
          name: "Enum",
          allowedValues: [
            "10",
            "11",
            "12",
            "20",
            "21",
            "22",
            "23",
            "30",
            "40"
          ]
        }
      },
      restriction: {
        serializedName: "restriction",
        type: {
          name: "Composite",
          className: "DataTableDto"
        }
      },
      targets: {
        serializedName: "targets",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "NotificationTargetDto"
            }
          }
        }
      },
      emailSubjectTemplate: {
        serializedName: "emailSubjectTemplate",
        type: {
          name: "Composite",
          className: "TemplateSimpleDto"
        }
      },
      emailContentTemplate: {
        serializedName: "emailContentTemplate",
        type: {
          name: "Composite",
          className: "TemplateSimpleDto"
        }
      },
      attachments: {
        serializedName: "attachments",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "DocumentTemplateSimpleDto"
            }
          }
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const NotificationRuleListDto: msRest.CompositeMapper = {
  serializedName: "NotificationRuleListDto",
  type: {
    name: "Composite",
    className: "NotificationRuleListDto",
    modelProperties: {
      notificationRuleId: {
        serializedName: "notificationRuleId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const NumberFormDefaultDto: msRest.CompositeMapper = {
  serializedName: "NumberFormDefaultDto",
  type: {
    name: "Composite",
    className: "NumberFormDefaultDto",
    modelProperties: {
      fieldValueId: {
        serializedName: "fieldValueId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12"
          ]
        }
      },
      value: {
        nullable: true,
        serializedName: "value",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const NumberFormFieldDto: msRest.CompositeMapper = {
  serializedName: "NumberFormFieldDto",
  type: {
    name: "Composite",
    className: "NumberFormFieldDto",
    modelProperties: {
      formFieldId: {
        serializedName: "formFieldId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      technicalName: {
        readOnly: true,
        serializedName: "technicalName",
        type: {
          name: "String"
        }
      },
      width: {
        serializedName: "width",
        type: {
          name: "Number"
        }
      },
      isRequired: {
        serializedName: "isRequired",
        type: {
          name: "Boolean"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12"
          ]
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      defaultValues: {
        serializedName: "defaultValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FormDefaultDto"
            }
          }
        }
      },
      localFieldId: {
        serializedName: "localFieldId",
        type: {
          name: "String"
        }
      },
      maxValue: {
        nullable: true,
        serializedName: "maxValue",
        type: {
          name: "Number"
        }
      },
      minValue: {
        nullable: true,
        serializedName: "minValue",
        type: {
          name: "Number"
        }
      },
      positiveTolerance: {
        nullable: true,
        serializedName: "positiveTolerance",
        type: {
          name: "Number"
        }
      },
      negativeTolerance: {
        nullable: true,
        serializedName: "negativeTolerance",
        type: {
          name: "Number"
        }
      },
      setPointMin: {
        nullable: true,
        serializedName: "setPointMin",
        type: {
          name: "Number"
        }
      },
      setPointMax: {
        nullable: true,
        serializedName: "setPointMax",
        type: {
          name: "Number"
        }
      },
      isRateable: {
        serializedName: "isRateable",
        type: {
          name: "Boolean"
        }
      },
      unit: {
        serializedName: "unit",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const NumberFormValueDto: msRest.CompositeMapper = {
  serializedName: "NumberFormValueDto",
  type: {
    name: "Composite",
    className: "NumberFormValueDto",
    modelProperties: {
      fieldValueId: {
        serializedName: "fieldValueId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12"
          ]
        }
      },
      value: {
        nullable: true,
        serializedName: "value",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const OccuredEventQueueDto: msRest.CompositeMapper = {
  serializedName: "OccuredEventQueueDto",
  type: {
    name: "Composite",
    className: "OccuredEventQueueDto",
    modelProperties: {
      eventId: {
        serializedName: "eventId",
        type: {
          name: "String"
        }
      },
      skipDelay: {
        serializedName: "skipDelay",
        type: {
          name: "Boolean"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const OccurredConsumptionHistoryListDto: msRest.CompositeMapper = {
  serializedName: "OccurredConsumptionHistoryListDto",
  type: {
    name: "Composite",
    className: "OccurredConsumptionHistoryListDto",
    modelProperties: {
      occurredConnectedConsumptionHistoryId: {
        serializedName: "occurredConnectedConsumptionHistoryId",
        type: {
          name: "Number"
        }
      },
      occuredAt: {
        serializedName: "occuredAt",
        type: {
          name: "String"
        }
      },
      code: {
        serializedName: "code",
        type: {
          name: "String"
        }
      },
      consumption: {
        serializedName: "consumption",
        type: {
          name: "Composite",
          className: "ConsumptionCodeSimpleListDto"
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      },
      resource: {
        serializedName: "resource",
        type: {
          name: "Composite",
          className: "ResourceSimpleListDto"
        }
      }
    }
  }
};

export const OccurredConsumptionListDto: msRest.CompositeMapper = {
  serializedName: "OccurredConsumptionListDto",
  type: {
    name: "Composite",
    className: "OccurredConsumptionListDto",
    modelProperties: {
      occurredConsumptionId: {
        serializedName: "occurredConsumptionId",
        type: {
          name: "Number"
        }
      },
      resource: {
        serializedName: "resource",
        type: {
          name: "Composite",
          className: "ResourceSimpleListDto"
        }
      },
      consumption: {
        serializedName: "consumption",
        type: {
          name: "Composite",
          className: "ConsumptionCodeSimpleListDto"
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      },
      lastModified: {
        serializedName: "lastModified",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const OccurredConsumptionQueueDto: msRest.CompositeMapper = {
  serializedName: "OccurredConsumptionQueueDto",
  type: {
    name: "Composite",
    className: "OccurredConsumptionQueueDto",
    modelProperties: {
      eventId: {
        serializedName: "eventId",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      eventTime: {
        serializedName: "eventTime",
        type: {
          name: "String"
        }
      },
      occuredAt: {
        serializedName: "occuredAt",
        type: {
          name: "String"
        }
      },
      lastOccuredAt: {
        serializedName: "lastOccuredAt",
        type: {
          name: "String"
        }
      },
      code: {
        serializedName: "code",
        type: {
          name: "String"
        }
      },
      codeId: {
        serializedName: "codeId",
        type: {
          name: "Number"
        }
      },
      resourceName: {
        serializedName: "resourceName",
        type: {
          name: "String"
        }
      },
      resourceId: {
        serializedName: "resourceId",
        type: {
          name: "Number"
        }
      },
      newValue: {
        serializedName: "newValue",
        type: {
          name: "String"
        }
      },
      oldValue: {
        serializedName: "oldValue",
        type: {
          name: "String"
        }
      },
      hasChanged: {
        serializedName: "hasChanged",
        type: {
          name: "Boolean"
        }
      },
      unit: {
        serializedName: "unit",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const OccurredErrorHistoryListDto: msRest.CompositeMapper = {
  serializedName: "OccurredErrorHistoryListDto",
  type: {
    name: "Composite",
    className: "OccurredErrorHistoryListDto",
    modelProperties: {
      occurredConnectedErrorHistoryId: {
        serializedName: "occurredConnectedErrorHistoryId",
        type: {
          name: "Number"
        }
      },
      occuredAt: {
        serializedName: "occuredAt",
        type: {
          name: "String"
        }
      },
      code: {
        serializedName: "code",
        type: {
          name: "String"
        }
      },
      error: {
        serializedName: "error",
        type: {
          name: "Composite",
          className: "ErrorCodeSimpleListDto"
        }
      },
      isActive: {
        serializedName: "isActive",
        type: {
          name: "Boolean"
        }
      },
      resource: {
        serializedName: "resource",
        type: {
          name: "Composite",
          className: "ResourceSimpleListDto"
        }
      }
    }
  }
};

export const OccurredErrorListDto: msRest.CompositeMapper = {
  serializedName: "OccurredErrorListDto",
  type: {
    name: "Composite",
    className: "OccurredErrorListDto",
    modelProperties: {
      occurredErrorId: {
        serializedName: "occurredErrorId",
        type: {
          name: "Number"
        }
      },
      resource: {
        serializedName: "resource",
        type: {
          name: "Composite",
          className: "ResourceSimpleListDto"
        }
      },
      error: {
        serializedName: "error",
        type: {
          name: "Composite",
          className: "ErrorCodeSimpleListDto"
        }
      },
      isActive: {
        serializedName: "isActive",
        type: {
          name: "Boolean"
        }
      },
      lastModified: {
        serializedName: "lastModified",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const OccurredErrorQueueDto: msRest.CompositeMapper = {
  serializedName: "OccurredErrorQueueDto",
  type: {
    name: "Composite",
    className: "OccurredErrorQueueDto",
    modelProperties: {
      eventId: {
        serializedName: "eventId",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      eventTime: {
        serializedName: "eventTime",
        type: {
          name: "String"
        }
      },
      occuredAt: {
        serializedName: "occuredAt",
        type: {
          name: "String"
        }
      },
      lastOccuredAt: {
        serializedName: "lastOccuredAt",
        type: {
          name: "String"
        }
      },
      code: {
        serializedName: "code",
        type: {
          name: "String"
        }
      },
      codeId: {
        serializedName: "codeId",
        type: {
          name: "Number"
        }
      },
      resourceName: {
        serializedName: "resourceName",
        type: {
          name: "String"
        }
      },
      resourceId: {
        serializedName: "resourceId",
        type: {
          name: "Number"
        }
      },
      newValue: {
        serializedName: "newValue",
        type: {
          name: "Boolean"
        }
      },
      oldValue: {
        nullable: true,
        serializedName: "oldValue",
        type: {
          name: "Boolean"
        }
      },
      hasChanged: {
        serializedName: "hasChanged",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const OccurredHintHistoryListDto: msRest.CompositeMapper = {
  serializedName: "OccurredHintHistoryListDto",
  type: {
    name: "Composite",
    className: "OccurredHintHistoryListDto",
    modelProperties: {
      occurredConnectedHintHistoryId: {
        serializedName: "occurredConnectedHintHistoryId",
        type: {
          name: "Number"
        }
      },
      occuredAt: {
        serializedName: "occuredAt",
        type: {
          name: "String"
        }
      },
      code: {
        serializedName: "code",
        type: {
          name: "String"
        }
      },
      hint: {
        serializedName: "hint",
        type: {
          name: "Composite",
          className: "HintCodeSimpleListDto"
        }
      },
      isActive: {
        serializedName: "isActive",
        type: {
          name: "Boolean"
        }
      },
      resource: {
        serializedName: "resource",
        type: {
          name: "Composite",
          className: "ResourceSimpleListDto"
        }
      }
    }
  }
};

export const OccurredHintListDto: msRest.CompositeMapper = {
  serializedName: "OccurredHintListDto",
  type: {
    name: "Composite",
    className: "OccurredHintListDto",
    modelProperties: {
      occurredHintId: {
        serializedName: "occurredHintId",
        type: {
          name: "Number"
        }
      },
      resource: {
        serializedName: "resource",
        type: {
          name: "Composite",
          className: "ResourceSimpleListDto"
        }
      },
      hint: {
        serializedName: "hint",
        type: {
          name: "Composite",
          className: "HintCodeSimpleListDto"
        }
      },
      isActive: {
        serializedName: "isActive",
        type: {
          name: "Boolean"
        }
      },
      lastModified: {
        serializedName: "lastModified",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const OccurredHintQueueDto: msRest.CompositeMapper = {
  serializedName: "OccurredHintQueueDto",
  type: {
    name: "Composite",
    className: "OccurredHintQueueDto",
    modelProperties: {
      eventId: {
        serializedName: "eventId",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      eventTime: {
        serializedName: "eventTime",
        type: {
          name: "String"
        }
      },
      occuredAt: {
        serializedName: "occuredAt",
        type: {
          name: "String"
        }
      },
      lastOccuredAt: {
        serializedName: "lastOccuredAt",
        type: {
          name: "String"
        }
      },
      code: {
        serializedName: "code",
        type: {
          name: "String"
        }
      },
      codeId: {
        serializedName: "codeId",
        type: {
          name: "Number"
        }
      },
      resourceName: {
        serializedName: "resourceName",
        type: {
          name: "String"
        }
      },
      resourceId: {
        serializedName: "resourceId",
        type: {
          name: "Number"
        }
      },
      newValue: {
        serializedName: "newValue",
        type: {
          name: "Boolean"
        }
      },
      oldValue: {
        nullable: true,
        serializedName: "oldValue",
        type: {
          name: "Boolean"
        }
      },
      newLevel: {
        serializedName: "newLevel",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      oldLevel: {
        serializedName: "oldLevel",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      hasChanged: {
        serializedName: "hasChanged",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const OpenEditorRequestOnContactListDto: msRest.CompositeMapper = {
  serializedName: "OpenEditorRequestOnContactListDto",
  type: {
    name: "Composite",
    className: "OpenEditorRequestOnContactListDto",
    modelProperties: {
      contactId: {
        serializedName: "contactId",
        type: {
          name: "Number"
        }
      },
      missionId: {
        serializedName: "missionId",
        type: {
          name: "Number"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "String"
        }
      },
      mission: {
        serializedName: "mission",
        type: {
          name: "Composite",
          className: "MissionSimpleListDto"
        }
      }
    }
  }
};

export const OrgaDocumentAccessDto: msRest.CompositeMapper = {
  serializedName: "OrgaDocumentAccessDto",
  type: {
    name: "Composite",
    className: "OrgaDocumentAccessDto",
    modelProperties: {
      canAccessPdfMail: {
        serializedName: "canAccessPdfMail",
        type: {
          name: "Boolean"
        }
      },
      canAccessPdfPrint: {
        serializedName: "canAccessPdfPrint",
        type: {
          name: "Boolean"
        }
      },
      canAccessPdfShare: {
        serializedName: "canAccessPdfShare",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const OrgaFolderItemDto: msRest.CompositeMapper = {
  serializedName: "OrgaFolderItemDto",
  type: {
    name: "Composite",
    className: "OrgaFolderItemDto",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      path: {
        serializedName: "path",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2"
          ]
        }
      },
      file: {
        serializedName: "file",
        type: {
          name: "Composite",
          className: "FileDto"
        }
      },
      access: {
        serializedName: "access",
        type: {
          name: "Composite",
          className: "OrgaDocumentAccessDto"
        }
      }
    }
  }
};

export const OutOfRangeResourceTaskConsumptionTriggerDto: msRest.CompositeMapper = {
  serializedName: "OutOfRangeResourceTaskConsumptionTriggerDto",
  type: {
    name: "Composite",
    className: "OutOfRangeResourceTaskConsumptionTriggerDto",
    modelProperties: {
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "2",
            "3",
            "4"
          ]
        }
      },
      upperLimit: {
        serializedName: "upperLimit",
        type: {
          name: "Number"
        }
      },
      lowerLimit: {
        serializedName: "lowerLimit",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const OutboxReferenceQueueDto: msRest.CompositeMapper = {
  serializedName: "OutboxReferenceQueueDto",
  type: {
    name: "Composite",
    className: "OutboxReferenceQueueDto",
    modelProperties: {
      path: {
        serializedName: "path",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const OverviewDto: msRest.CompositeMapper = {
  serializedName: "OverviewDto",
  type: {
    name: "Composite",
    className: "OverviewDto",
    modelProperties: {
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Number"
        }
      },
      missionsOpenCount: {
        serializedName: "missionsOpenCount",
        type: {
          name: "Number"
        }
      },
      infosTodayCount: {
        serializedName: "infosTodayCount",
        type: {
          name: "Number"
        }
      },
      infosWeekCount: {
        serializedName: "infosWeekCount",
        type: {
          name: "Number"
        }
      },
      tasksOpenCount: {
        serializedName: "tasksOpenCount",
        type: {
          name: "Number"
        }
      },
      tasksOverdueCount: {
        serializedName: "tasksOverdueCount",
        type: {
          name: "Number"
        }
      },
      tasksEscalatedCount: {
        serializedName: "tasksEscalatedCount",
        type: {
          name: "Number"
        }
      },
      chatUnreadCount: {
        serializedName: "chatUnreadCount",
        type: {
          name: "Number"
        }
      },
      chatsTodayCount: {
        serializedName: "chatsTodayCount",
        type: {
          name: "Number"
        }
      },
      locationName: {
        serializedName: "locationName",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const OverviewTaskDto: msRest.CompositeMapper = {
  serializedName: "OverviewTaskDto",
  type: {
    name: "Composite",
    className: "OverviewTaskDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      countPending: {
        serializedName: "countPending",
        type: {
          name: "Number"
        }
      },
      countOpen: {
        serializedName: "countOpen",
        type: {
          name: "Number"
        }
      },
      countOverdue: {
        serializedName: "countOverdue",
        type: {
          name: "Number"
        }
      },
      countEscalated: {
        serializedName: "countEscalated",
        type: {
          name: "Number"
        }
      },
      countDone: {
        serializedName: "countDone",
        type: {
          name: "Number"
        }
      },
      countCanceled: {
        serializedName: "countCanceled",
        type: {
          name: "Number"
        }
      },
      isAny: {
        serializedName: "isAny",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const OverviewTaskRequestDto: msRest.CompositeMapper = {
  serializedName: "OverviewTaskRequestDto",
  type: {
    name: "Composite",
    className: "OverviewTaskRequestDto",
    modelProperties: {
      filter: {
        serializedName: "filter",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilterDto"
            }
          }
        }
      },
      restrictedLocations: {
        nullable: true,
        serializedName: "restrictedLocations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Number"
            }
          }
        }
      },
      restrictedTasks: {
        nullable: true,
        serializedName: "restrictedTasks",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Number"
            }
          }
        }
      }
    }
  }
};

export const PasswordLoginDto: msRest.CompositeMapper = {
  serializedName: "PasswordLoginDto",
  type: {
    name: "Composite",
    className: "PasswordLoginDto",
    modelProperties: {
      password: {
        serializedName: "password",
        type: {
          name: "String"
        }
      },
      newPassword: {
        serializedName: "newPassword",
        type: {
          name: "String"
        }
      },
      passwordBase64: {
        serializedName: "passwordBase64",
        type: {
          name: "String"
        }
      },
      newPasswordBase64: {
        serializedName: "newPasswordBase64",
        type: {
          name: "String"
        }
      },
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const PercentageConditionDto: msRest.CompositeMapper = {
  serializedName: "PercentageConditionDto",
  type: {
    name: "Composite",
    className: "PercentageConditionDto",
    modelProperties: {
      conditionId: {
        serializedName: "conditionId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      percent: {
        serializedName: "percent",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const PercentageConditionV1Dto: msRest.CompositeMapper = {
  serializedName: "PercentageConditionV1Dto",
  type: {
    name: "Composite",
    className: "PercentageConditionV1Dto",
    modelProperties: {
      conditionId: {
        serializedName: "conditionId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      percent: {
        serializedName: "percent",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const PermissionPackageDto: msRest.CompositeMapper = {
  serializedName: "PermissionPackageDto",
  type: {
    name: "Composite",
    className: "PermissionPackageDto",
    modelProperties: {
      packageProperty: {
        serializedName: "package",
        type: {
          name: "Enum",
          allowedValues: [
            "12",
            "13",
            "20",
            "21",
            "22",
            "23",
            "24",
            "28",
            "29",
            "30",
            "31",
            "32",
            "33",
            "34",
            "40",
            "41",
            "42",
            "43",
            "50",
            "51",
            "52",
            "53",
            "60",
            "61",
            "62",
            "63",
            "69",
            "70",
            "71",
            "80",
            "81",
            "82",
            "85",
            "90",
            "91",
            "101",
            "102",
            "103",
            "119",
            "120",
            "121",
            "122",
            "130",
            "131",
            "141",
            "142",
            "143",
            "144",
            "145",
            "150",
            "151",
            "161",
            "171",
            "180",
            "181",
            "182",
            "183",
            "184",
            "200",
            "201",
            "202",
            "203",
            "210",
            "211",
            "212",
            "213",
            "214",
            "215",
            "216",
            "217",
            "220",
            "221",
            "225",
            "226",
            "227",
            "230",
            "231",
            "232",
            "240",
            "241",
            "242",
            "243",
            "244",
            "245",
            "246",
            "250",
            "251",
            "252",
            "260",
            "261",
            "262",
            "263",
            "270",
            "271",
            "272",
            "273",
            "280",
            "281",
            "282",
            "300",
            "301",
            "302",
            "303",
            "310",
            "317",
            "318",
            "319",
            "320",
            "321",
            "322",
            "323",
            "324",
            "325",
            "327",
            "328",
            "329",
            "330",
            "331",
            "340",
            "341",
            "350",
            "351",
            "360",
            "361",
            "362",
            "370",
            "371",
            "372",
            "380",
            "381",
            "382",
            "383",
            "390",
            "391",
            "392",
            "393",
            "400",
            "401",
            "402",
            "403",
            "410",
            "411",
            "420",
            "421",
            "422",
            "423",
            "433",
            "434",
            "440",
            "441",
            "442",
            "443",
            "450",
            "451",
            "452",
            "453",
            "454",
            "460",
            "461",
            "462",
            "463",
            "470",
            "471",
            "472",
            "473",
            "474",
            "480",
            "481",
            "490",
            "491",
            "500",
            "501",
            "510",
            "511",
            "520",
            "521",
            "522",
            "530",
            "531",
            "532",
            "533",
            "540",
            "541",
            "550",
            "551",
            "552",
            "553",
            "560",
            "561",
            "563",
            "564",
            "570",
            "571",
            "573",
            "574",
            "580",
            "581",
            "590",
            "591",
            "600",
            "605",
            "606",
            "607",
            "608",
            "610",
            "611",
            "612",
            "613",
            "620",
            "621",
            "625",
            "626",
            "630",
            "640",
            "650",
            "651",
            "652",
            "653",
            "654",
            "655",
            "1000",
            "1001",
            "1008",
            "1009",
            "1010",
            "1011",
            "1012",
            "1016",
            "1022",
            "1023",
            "1024",
            "1025",
            "1026",
            "1027",
            "1028",
            "1029",
            "2002",
            "2011",
            "2012",
            "2013",
            "2014",
            "2015",
            "2016",
            "2017",
            "2018",
            "2020",
            "2021",
            "2022",
            "2023",
            "2024",
            "2025",
            "2026",
            "2027",
            "2028",
            "2029",
            "2030",
            "2031",
            "2040",
            "2050",
            "2052",
            "2053",
            "2060",
            "2061",
            "2062",
            "2063",
            "2070",
            "2071",
            "2072",
            "2073",
            "2074",
            "2075",
            "2080",
            "2081",
            "2082",
            "2083",
            "2084",
            "2085",
            "2086",
            "2087",
            "2088",
            "2089",
            "2090",
            "2091",
            "2092",
            "2093",
            "2094",
            "2100",
            "2101",
            "2102",
            "2103",
            "2104",
            "2105",
            "2106",
            "2107",
            "2108",
            "2109",
            "2111",
            "2113",
            "2114",
            "2115",
            "2116",
            "2120",
            "2131",
            "2140",
            "2141"
          ]
        }
      },
      permissions: {
        serializedName: "permissions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Enum",
              allowedValues: [
                "10",
                "11",
                "12",
                "13",
                "14",
                "15",
                "16",
                "17",
                "20",
                "21",
                "22",
                "23",
                "40",
                "41",
                "42",
                "43",
                "50",
                "51",
                "52",
                "53",
                "60",
                "61",
                "62",
                "63",
                "70",
                "71",
                "80",
                "81",
                "82",
                "83",
                "90",
                "91",
                "92",
                "93",
                "100",
                "101",
                "102",
                "103",
                "104",
                "105",
                "106",
                "107",
                "110",
                "111",
                "112",
                "113",
                "120",
                "121",
                "123",
                "124",
                "130",
                "131",
                "132",
                "133",
                "140",
                "141",
                "142",
                "143",
                "150",
                "160",
                "161",
                "162",
                "163",
                "164",
                "170",
                "171",
                "172",
                "173",
                "190",
                "191",
                "192",
                "193",
                "200",
                "201",
                "202",
                "203",
                "204",
                "210",
                "211",
                "212",
                "213",
                "230",
                "231",
                "232",
                "233",
                "240",
                "241",
                "242",
                "243",
                "250",
                "251",
                "252",
                "253",
                "254",
                "260",
                "261",
                "262",
                "263",
                "270",
                "271",
                "272",
                "273",
                "274",
                "275",
                "280",
                "290",
                "291",
                "292",
                "293",
                "300",
                "301",
                "302",
                "303",
                "310",
                "311",
                "312",
                "313",
                "320",
                "321",
                "322",
                "323",
                "330",
                "331",
                "332",
                "333",
                "340",
                "341",
                "342",
                "343",
                "350",
                "351",
                "352",
                "353",
                "360",
                "361",
                "362",
                "363",
                "370",
                "371",
                "372",
                "373",
                "380",
                "381",
                "382",
                "383",
                "390",
                "391",
                "392",
                "393",
                "400",
                "401",
                "402",
                "403",
                "410",
                "411",
                "412",
                "413",
                "420",
                "421",
                "422",
                "423",
                "430",
                "431",
                "432",
                "433",
                "440",
                "441",
                "442",
                "443",
                "450",
                "451",
                "452",
                "453",
                "460",
                "461",
                "462",
                "463",
                "470",
                "471",
                "472",
                "473",
                "474",
                "480",
                "481",
                "482",
                "483",
                "490",
                "491",
                "492",
                "493",
                "500",
                "501",
                "502",
                "503",
                "520",
                "530",
                "531",
                "532",
                "533",
                "535",
                "536",
                "540",
                "550",
                "580",
                "581",
                "582",
                "583",
                "590",
                "591",
                "592",
                "593",
                "600",
                "601",
                "602",
                "603",
                "610",
                "611",
                "612",
                "613",
                "620",
                "621",
                "622",
                "623",
                "630",
                "631",
                "632",
                "633",
                "640",
                "641",
                "642",
                "643",
                "650",
                "651",
                "652",
                "653",
                "660",
                "661",
                "662",
                "663",
                "670",
                "671",
                "672",
                "673",
                "680",
                "681",
                "682",
                "683",
                "684",
                "690",
                "691",
                "692",
                "693",
                "700",
                "701",
                "702",
                "703",
                "704",
                "710",
                "711",
                "712",
                "713",
                "720",
                "721",
                "722",
                "723",
                "730",
                "731",
                "732",
                "733",
                "740",
                "741",
                "742",
                "743",
                "750",
                "751",
                "752",
                "753",
                "790",
                "791",
                "792",
                "793",
                "800",
                "801",
                "802",
                "803",
                "810",
                "811",
                "812",
                "813",
                "820",
                "830",
                "831",
                "832",
                "833",
                "834",
                "835",
                "836",
                "838",
                "839",
                "840",
                "841",
                "842",
                "850",
                "851",
                "852",
                "853",
                "860",
                "861",
                "862",
                "863",
                "870",
                "871",
                "872",
                "873",
                "880",
                "881",
                "882",
                "883",
                "890",
                "891",
                "892",
                "893",
                "900",
                "901",
                "902",
                "903",
                "904",
                "910",
                "911",
                "912",
                "913",
                "920",
                "921",
                "922",
                "923",
                "930",
                "931",
                "932",
                "933",
                "940",
                "941",
                "942",
                "943",
                "950",
                "951",
                "952",
                "953",
                "960",
                "961",
                "962",
                "963",
                "970",
                "971",
                "972",
                "973",
                "980",
                "981",
                "982",
                "983",
                "990",
                "991",
                "992",
                "993",
                "1000",
                "1001",
                "1002",
                "1003",
                "1010",
                "1011",
                "1012",
                "1013",
                "1020",
                "1021",
                "1022",
                "1023",
                "1030",
                "1031",
                "1032",
                "1033",
                "1040",
                "1041",
                "1042",
                "1043",
                "1050",
                "1051",
                "1052",
                "1053",
                "1060",
                "1061",
                "1062",
                "1063",
                "1070",
                "1071",
                "1072",
                "1073",
                "1080",
                "1081",
                "1082",
                "1083",
                "1090",
                "1091",
                "1092",
                "1093",
                "1100",
                "1101",
                "1102",
                "1103",
                "1110",
                "1111",
                "1112",
                "1113",
                "1114",
                "1120",
                "1121",
                "1122",
                "1123",
                "1130",
                "1131",
                "1132",
                "1133",
                "1134",
                "1135",
                "1136",
                "1137",
                "1140",
                "1141",
                "1142",
                "1143",
                "1150",
                "1160",
                "1170",
                "1171",
                "1172",
                "1173",
                "1180",
                "1181",
                "1182",
                "1183",
                "1190",
                "1191",
                "1192",
                "1193",
                "1200",
                "1201",
                "1202",
                "1203",
                "1211",
                "1212",
                "1213",
                "1214",
                "1221",
                "1222",
                "1223",
                "1224",
                "1225",
                "1226",
                "1227",
                "1228",
                "1229",
                "1230",
                "1231",
                "1232",
                "1233",
                "1234",
                "1235",
                "1236",
                "1251",
                "1252",
                "1253",
                "1254",
                "1261",
                "1262",
                "1263",
                "1264",
                "1271",
                "1272",
                "1273",
                "1274",
                "1311",
                "1312",
                "1313",
                "1314",
                "1321",
                "1322",
                "1323",
                "1324",
                "1331",
                "1332",
                "1333",
                "1334",
                "1341",
                "1342",
                "1343",
                "1344",
                "1351",
                "1352",
                "1353",
                "1354",
                "1361",
                "1362",
                "1363",
                "1364",
                "1371",
                "1372",
                "1373",
                "1374",
                "1381",
                "1382",
                "1383",
                "1384",
                "1391",
                "1392",
                "1393",
                "1394",
                "1400",
                "1410",
                "1412",
                "1413",
                "1414",
                "1420",
                "1422",
                "1423",
                "1424",
                "1430",
                "1431",
                "1432",
                "1433",
                "1440",
                "1441",
                "1442",
                "1443",
                "1450",
                "1451",
                "1452",
                "1453",
                "1460",
                "1461",
                "1462",
                "1463",
                "1470",
                "1471",
                "1472",
                "1473",
                "1474",
                "1480",
                "1481",
                "1482",
                "1483",
                "1490",
                "1491",
                "1492",
                "1493",
                "1500",
                "1501",
                "1502",
                "1503",
                "1510",
                "1511",
                "1512",
                "1513",
                "1520",
                "1521",
                "1531",
                "1532",
                "1533",
                "1534",
                "1535",
                "1541",
                "1542",
                "1543",
                "1544",
                "1545",
                "1546",
                "1550",
                "1551",
                "1552",
                "1553",
                "1554",
                "1560",
                "1561",
                "1562",
                "1563",
                "1564",
                "1565",
                "1566",
                "10000",
                "10100",
                "10101",
                "10102",
                "10103",
                "10104",
                "10105",
                "10106",
                "10107",
                "10108",
                "10109",
                "10110",
                "10111",
                "10112",
                "10113",
                "10114",
                "10115",
                "10116",
                "10117",
                "10118",
                "10119",
                "10120",
                "10121",
                "10122",
                "10123",
                "10124",
                "10125",
                "10126",
                "10128",
                "10129",
                "10130",
                "10131",
                "10132",
                "10133",
                "10134",
                "10135",
                "10136",
                "10137",
                "10138",
                "10139",
                "10140",
                "10141",
                "10142",
                "10143",
                "10144",
                "10201",
                "10202",
                "10203",
                "10204",
                "10205",
                "10206",
                "10207",
                "10301",
                "10302",
                "10303",
                "10304",
                "10311",
                "10312",
                "10313",
                "10314",
                "10510",
                "10511",
                "10512",
                "10513",
                "10520",
                "10521",
                "10522",
                "10523",
                "10530",
                "10531",
                "10532",
                "10533",
                "10540",
                "10541",
                "10542",
                "10543",
                "10550",
                "10551",
                "10552",
                "10553",
                "10554",
                "10555",
                "10561",
                "10562",
                "10563",
                "10564",
                "10570",
                "10571",
                "10572",
                "10573",
                "10574",
                "10580",
                "10590",
                "10591",
                "10601",
                "10602",
                "10603",
                "10604",
                "10605",
                "10606",
                "10607",
                "10608",
                "10610",
                "10611",
                "10612",
                "10613",
                "10620",
                "10621",
                "10622",
                "10623",
                "10624",
                "10630",
                "10631",
                "10632",
                "10633",
                "10634",
                "10640",
                "10641",
                "10642",
                "10643",
                "10644",
                "10646",
                "10647",
                "10648",
                "10649",
                "10650",
                "10651",
                "10652",
                "10653",
                "10660",
                "10661",
                "10662",
                "10663",
                "10664",
                "10665",
                "10666",
                "10667",
                "10668",
                "10669",
                "10670",
                "10671",
                "10672",
                "10673",
                "10680",
                "10681",
                "10682",
                "10683",
                "10710",
                "10711",
                "10720",
                "10721",
                "10723",
                "10724",
                "10730",
                "10731",
                "10733",
                "10734",
                "10740",
                "10741",
                "10742",
                "10743",
                "10750",
                "10751",
                "10752",
                "10753",
                "10760",
                "10761",
                "10762",
                "10763",
                "10770",
                "10771",
                "10772",
                "10773",
                "103321",
                "103322",
                "103323",
                "103324",
                "106245"
              ]
            }
          }
        }
      }
    }
  }
};

export const PictureFormDefaultDto: msRest.CompositeMapper = {
  serializedName: "PictureFormDefaultDto",
  type: {
    name: "Composite",
    className: "PictureFormDefaultDto",
    modelProperties: {
      fieldValueId: {
        serializedName: "fieldValueId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12"
          ]
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "Number"
        }
      },
      file: {
        serializedName: "file",
        type: {
          name: "Composite",
          className: "FileDto"
        }
      }
    }
  }
};

export const PictureFormFieldDto: msRest.CompositeMapper = {
  serializedName: "PictureFormFieldDto",
  type: {
    name: "Composite",
    className: "PictureFormFieldDto",
    modelProperties: {
      formFieldId: {
        serializedName: "formFieldId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      technicalName: {
        readOnly: true,
        serializedName: "technicalName",
        type: {
          name: "String"
        }
      },
      width: {
        serializedName: "width",
        type: {
          name: "Number"
        }
      },
      isRequired: {
        serializedName: "isRequired",
        type: {
          name: "Boolean"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12"
          ]
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      defaultValues: {
        serializedName: "defaultValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FormDefaultDto"
            }
          }
        }
      },
      localFieldId: {
        serializedName: "localFieldId",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const PictureFormValueDto: msRest.CompositeMapper = {
  serializedName: "PictureFormValueDto",
  type: {
    name: "Composite",
    className: "PictureFormValueDto",
    modelProperties: {
      fieldValueId: {
        serializedName: "fieldValueId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12"
          ]
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "Number"
        }
      },
      file: {
        serializedName: "file",
        type: {
          name: "Composite",
          className: "FileDto"
        }
      }
    }
  }
};

export const PingDeviceActionNotificationDto: msRest.CompositeMapper = {
  serializedName: "PingDeviceActionNotificationDto",
  type: {
    name: "Composite",
    className: "PingDeviceActionNotificationDto",
    modelProperties: {
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      message: {
        serializedName: "message",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "5"
          ]
        }
      },
      action: {
        serializedName: "action",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3"
          ]
        }
      },
      pingId: {
        serializedName: "pingId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const PingDto: msRest.CompositeMapper = {
  serializedName: "PingDto",
  type: {
    name: "Composite",
    className: "PingDto",
    modelProperties: {
      pingId: {
        serializedName: "pingId",
        type: {
          name: "Number"
        }
      },
      appVersion: {
        serializedName: "appVersion",
        type: {
          name: "String"
        }
      },
      deviceId: {
        serializedName: "deviceId",
        type: {
          name: "Number"
        }
      },
      hardwareId: {
        serializedName: "hardwareId",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      sendAt: {
        serializedName: "sendAt",
        type: {
          name: "String"
        }
      },
      arrivedAt: {
        serializedName: "arrivedAt",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const PingListDto: msRest.CompositeMapper = {
  serializedName: "PingListDto",
  type: {
    name: "Composite",
    className: "PingListDto",
    modelProperties: {
      pingId: {
        serializedName: "pingId",
        type: {
          name: "Number"
        }
      },
      deviceId: {
        serializedName: "deviceId",
        type: {
          name: "Number"
        }
      },
      sendAt: {
        serializedName: "sendAt",
        type: {
          name: "String"
        }
      },
      arrivedAt: {
        serializedName: "arrivedAt",
        type: {
          name: "String"
        }
      },
      appVersion: {
        serializedName: "appVersion",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const PlannedExecutionDto: msRest.CompositeMapper = {
  serializedName: "PlannedExecutionDto",
  type: {
    name: "Composite",
    className: "PlannedExecutionDto",
    modelProperties: {
      executionId: {
        serializedName: "executionId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "4"
          ]
        }
      },
      startMinuteOffset: {
        serializedName: "startMinuteOffset",
        type: {
          name: "String"
        }
      },
      patterns: {
        serializedName: "patterns",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "DateTimePatternDto"
            }
          }
        }
      }
    }
  }
};

export const PlannedExecutionPlanViewV1Dto: msRest.CompositeMapper = {
  serializedName: "PlannedExecutionPlanViewV1Dto",
  type: {
    name: "Composite",
    className: "PlannedExecutionPlanViewV1Dto",
    modelProperties: {
      executionId: {
        serializedName: "executionId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "4"
          ]
        }
      },
      startMinuteOffset: {
        serializedName: "startMinuteOffset",
        type: {
          name: "String"
        }
      },
      patterns: {
        serializedName: "patterns",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "DateTimePatternV1Dto"
            }
          }
        }
      }
    }
  }
};

export const PlannedExecutionV1Dto: msRest.CompositeMapper = {
  serializedName: "PlannedExecutionV1Dto",
  type: {
    name: "Composite",
    className: "PlannedExecutionV1Dto",
    modelProperties: {
      executionId: {
        serializedName: "executionId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "4"
          ]
        }
      },
      startMinuteOffset: {
        serializedName: "startMinuteOffset",
        type: {
          name: "String"
        }
      },
      patterns: {
        serializedName: "patterns",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "DateTimePatternV1Dto"
            }
          }
        }
      }
    }
  }
};

export const PreviewDocumentTemplateDto: msRest.CompositeMapper = {
  serializedName: "PreviewDocumentTemplateDto",
  type: {
    name: "Composite",
    className: "PreviewDocumentTemplateDto",
    modelProperties: {
      contextType: {
        serializedName: "contextType",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18"
          ]
        }
      },
      fileId: {
        serializedName: "fileId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const PreviewMissionInputDto: msRest.CompositeMapper = {
  serializedName: "PreviewMissionInputDto",
  type: {
    name: "Composite",
    className: "PreviewMissionInputDto",
    modelProperties: {
      resourceId: {
        nullable: true,
        serializedName: "resourceId",
        type: {
          name: "Number"
        }
      },
      ticketId: {
        nullable: true,
        serializedName: "ticketId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4"
          ]
        }
      }
    }
  }
};

export const PreviewRequestDto: msRest.CompositeMapper = {
  serializedName: "PreviewRequestDto",
  type: {
    name: "Composite",
    className: "PreviewRequestDto",
    modelProperties: {
      parentId: {
        nullable: true,
        serializedName: "parentId",
        type: {
          name: "Number"
        }
      },
      stereotypeId: {
        required: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      entity: {
        required: true,
        serializedName: "entity",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
            "31",
            "32",
            "33",
            "34",
            "35",
            "36",
            "37",
            "38",
            "39",
            "40",
            "41",
            "42",
            "43",
            "44",
            "45"
          ]
        }
      }
    }
  }
};

export const PreviewResourceByLocationDto: msRest.CompositeMapper = {
  serializedName: "PreviewResourceByLocationDto",
  type: {
    name: "Composite",
    className: "PreviewResourceByLocationDto",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      parent: {
        serializedName: "parent",
        type: {
          name: "Composite",
          className: "ResourceSimpleDto"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleDto"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const PreviewResourcesByLocationDefinitionDto: msRest.CompositeMapper = {
  serializedName: "PreviewResourcesByLocationDefinitionDto",
  type: {
    name: "Composite",
    className: "PreviewResourcesByLocationDefinitionDto",
    modelProperties: {
      resourcesByLocationDefinitionId: {
        serializedName: "resourcesByLocationDefinitionId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      resources: {
        serializedName: "resources",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PreviewResourceByLocationDto"
            }
          }
        }
      }
    }
  }
};

export const PreviewResourcesByLocationDto: msRest.CompositeMapper = {
  serializedName: "PreviewResourcesByLocationDto",
  type: {
    name: "Composite",
    className: "PreviewResourcesByLocationDto",
    modelProperties: {
      definitions: {
        serializedName: "definitions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PreviewResourcesByLocationDefinitionDto"
            }
          }
        }
      },
      resources: {
        readOnly: true,
        serializedName: "resources",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PreviewResourceByLocationDto"
            }
          }
        }
      }
    }
  }
};

export const PreviewTemplateDto: msRest.CompositeMapper = {
  serializedName: "PreviewTemplateDto",
  type: {
    name: "Composite",
    className: "PreviewTemplateDto",
    modelProperties: {
      template: {
        serializedName: "template",
        type: {
          name: "String"
        }
      },
      context: {
        serializedName: "context",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "181",
            "182",
            "183",
            "184",
            "191",
            "192",
            "193",
            "194"
          ]
        }
      }
    }
  }
};

export const PreviewTicketByLocationDto: msRest.CompositeMapper = {
  serializedName: "PreviewTicketByLocationDto",
  type: {
    name: "Composite",
    className: "PreviewTicketByLocationDto",
    modelProperties: {
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      currentState: {
        serializedName: "currentState",
        type: {
          name: "Composite",
          className: "StateSimpleDto"
        }
      },
      priority: {
        serializedName: "priority",
        type: {
          name: "Composite",
          className: "PrioritySimpleDto"
        }
      },
      resource: {
        serializedName: "resource",
        type: {
          name: "Composite",
          className: "ResourceSimpleDto"
        }
      },
      editor: {
        serializedName: "editor",
        type: {
          name: "Composite",
          className: "ContactSimpleDto"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleDto"
        }
      }
    }
  }
};

export const PreviewTicketsByLocationDefinitionDto: msRest.CompositeMapper = {
  serializedName: "PreviewTicketsByLocationDefinitionDto",
  type: {
    name: "Composite",
    className: "PreviewTicketsByLocationDefinitionDto",
    modelProperties: {
      ticketsByLocationDefinitionId: {
        serializedName: "ticketsByLocationDefinitionId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      tickets: {
        serializedName: "tickets",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PreviewTicketByLocationDto"
            }
          }
        }
      }
    }
  }
};

export const PreviewTicketsByLocationDto: msRest.CompositeMapper = {
  serializedName: "PreviewTicketsByLocationDto",
  type: {
    name: "Composite",
    className: "PreviewTicketsByLocationDto",
    modelProperties: {
      definitions: {
        serializedName: "definitions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PreviewTicketsByLocationDefinitionDto"
            }
          }
        }
      },
      tickets: {
        readOnly: true,
        serializedName: "tickets",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PreviewTicketByLocationDto"
            }
          }
        }
      }
    }
  }
};

export const PreviewedChatByFormLocationReceivingDto: msRest.CompositeMapper = {
  serializedName: "PreviewedChatByFormLocationReceivingDto",
  type: {
    name: "Composite",
    className: "PreviewedChatByFormLocationReceivingDto",
    modelProperties: {
      isOverridable: {
        serializedName: "isOverridable",
        type: {
          name: "Boolean"
        }
      },
      locations: {
        serializedName: "locations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationSimpleDto"
            }
          }
        }
      },
      locationGroups: {
        serializedName: "locationGroups",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationGroupSimpleDto"
            }
          }
        }
      }
    }
  }
};

export const PreviewedChatByFormFunctionReceivingDto: msRest.CompositeMapper = {
  serializedName: "PreviewedChatByFormFunctionReceivingDto",
  type: {
    name: "Composite",
    className: "PreviewedChatByFormFunctionReceivingDto",
    modelProperties: {
      functionProperty: {
        serializedName: "functionProperty",
        type: {
          name: "Composite",
          className: "FunctionSimpleDto"
        }
      }
    }
  }
};

export const PreviewedChatByFormReceivingDto: msRest.CompositeMapper = {
  serializedName: "PreviewedChatByFormReceivingDto",
  type: {
    name: "Composite",
    className: "PreviewedChatByFormReceivingDto",
    modelProperties: {
      kind: {
        serializedName: "kind",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2"
          ]
        }
      },
      locationReceivings: {
        serializedName: "locationReceivings",
        type: {
          name: "Composite",
          className: "PreviewedChatByFormLocationReceivingDto"
        }
      },
      functionReceiving: {
        serializedName: "functionReceiving",
        type: {
          name: "Composite",
          className: "PreviewedChatByFormFunctionReceivingDto"
        }
      }
    }
  }
};

export const PreviewedChatByFormDto: msRest.CompositeMapper = {
  serializedName: "PreviewedChatByFormDto",
  type: {
    name: "Composite",
    className: "PreviewedChatByFormDto",
    modelProperties: {
      chatByFormDefinitionId: {
        serializedName: "chatByFormDefinitionId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      form: {
        serializedName: "form",
        type: {
          name: "Composite",
          className: "FormSimpleDto"
        }
      },
      requiresAuthentication: {
        serializedName: "requiresAuthentication",
        type: {
          name: "Boolean"
        }
      },
      receiving: {
        serializedName: "receiving",
        type: {
          name: "Composite",
          className: "PreviewedChatByFormReceivingDto"
        }
      }
    }
  }
};

export const PrimaryEditorSuggestionDto: msRest.CompositeMapper = {
  serializedName: "PrimaryEditorSuggestionDto",
  type: {
    name: "Composite",
    className: "PrimaryEditorSuggestionDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      target: {
        serializedName: "target",
        type: {
          name: "Composite",
          className: "EditorSuggestionTargetDto"
        }
      },
      priority: {
        serializedName: "priority",
        type: {
          name: "Composite",
          className: "PrioritySimpleDto"
        }
      }
    }
  }
};

export const PriorityDto: msRest.CompositeMapper = {
  serializedName: "PriorityDto",
  type: {
    name: "Composite",
    className: "PriorityDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      priorityId: {
        serializedName: "priorityId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const PriorityExportDto: msRest.CompositeMapper = {
  serializedName: "PriorityExportDto",
  type: {
    name: "Composite",
    className: "PriorityExportDto",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const PriorityListDto: msRest.CompositeMapper = {
  serializedName: "PriorityListDto",
  type: {
    name: "Composite",
    className: "PriorityListDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      priorityId: {
        serializedName: "priorityId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      prioritySetId: {
        serializedName: "prioritySetId",
        type: {
          name: "Number"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const PrioritySetDto: msRest.CompositeMapper = {
  serializedName: "PrioritySetDto",
  type: {
    name: "Composite",
    className: "PrioritySetDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      prioritySetId: {
        serializedName: "prioritySetId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      priorities: {
        serializedName: "priorities",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PriorityDto"
            }
          }
        }
      }
    }
  }
};

export const PrioritySetExportDto: msRest.CompositeMapper = {
  serializedName: "PrioritySetExportDto",
  type: {
    name: "Composite",
    className: "PrioritySetExportDto",
    modelProperties: {
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      priorities: {
        serializedName: "priorities",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PriorityExportDto"
            }
          }
        }
      }
    }
  }
};

export const PrioritySetListDto: msRest.CompositeMapper = {
  serializedName: "PrioritySetListDto",
  type: {
    name: "Composite",
    className: "PrioritySetListDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      prioritySetId: {
        serializedName: "prioritySetId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const PrioritySetSimpleDto: msRest.CompositeMapper = {
  serializedName: "PrioritySetSimpleDto",
  type: {
    name: "Composite",
    className: "PrioritySetSimpleDto",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      prioritySetId: {
        serializedName: "prioritySetId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const PrioritySetSimpleExportDto: msRest.CompositeMapper = {
  serializedName: "PrioritySetSimpleExportDto",
  type: {
    name: "Composite",
    className: "PrioritySetSimpleExportDto",
    modelProperties: {
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const PrioritySettingDto: msRest.CompositeMapper = {
  serializedName: "PrioritySettingDto",
  type: {
    name: "Composite",
    className: "PrioritySettingDto",
    modelProperties: {
      settingValueId: {
        serializedName: "settingValueId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      value: {
        nullable: true,
        serializedName: "value",
        type: {
          name: "Number"
        }
      },
      priority: {
        serializedName: "priority",
        type: {
          name: "Composite",
          className: "PrioritySimpleDto"
        }
      }
    }
  }
};

export const PrioritySimpleDtoPreSelectedReferenceDto: msRest.CompositeMapper = {
  serializedName: "PrioritySimpleDtoPreSelectedReferenceDto",
  type: {
    name: "Composite",
    className: "PrioritySimpleDtoPreSelectedReferenceDto",
    modelProperties: {
      isOverridable: {
        serializedName: "isOverridable",
        type: {
          name: "Boolean"
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "Composite",
          className: "PrioritySimpleDto"
        }
      }
    }
  }
};

export const ProductSubscriptionDto: msRest.CompositeMapper = {
  serializedName: "ProductSubscriptionDto",
  type: {
    name: "Composite",
    className: "ProductSubscriptionDto",
    modelProperties: {
      productSubscriptionId: {
        serializedName: "productSubscriptionId",
        type: {
          name: "Number"
        }
      },
      expiresIn: {
        serializedName: "expiresIn",
        type: {
          name: "String"
        }
      },
      kind: {
        serializedName: "kind",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      },
      dealCode: {
        serializedName: "dealCode",
        type: {
          name: "String"
        }
      },
      amount: {
        serializedName: "amount",
        type: {
          name: "Number"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "String"
        }
      },
      lastModified: {
        serializedName: "lastModified",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      edition: {
        serializedName: "edition",
        type: {
          name: "Enum",
          allowedValues: [
            "2",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "31",
            "32",
            "33",
            "34",
            "35",
            "36",
            "37",
            "38",
            "39",
            "40",
            "41",
            "42",
            "43",
            "44",
            "45",
            "46",
            "47"
          ]
        }
      }
    }
  }
};

export const ProductSubscriptionListDto: msRest.CompositeMapper = {
  serializedName: "ProductSubscriptionListDto",
  type: {
    name: "Composite",
    className: "ProductSubscriptionListDto",
    modelProperties: {
      productSubscriptionId: {
        serializedName: "productSubscriptionId",
        type: {
          name: "Number"
        }
      },
      expiresIn: {
        serializedName: "expiresIn",
        type: {
          name: "String"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "String"
        }
      },
      lastModified: {
        serializedName: "lastModified",
        type: {
          name: "String"
        }
      },
      dealCode: {
        serializedName: "dealCode",
        type: {
          name: "String"
        }
      },
      amount: {
        serializedName: "amount",
        type: {
          name: "Number"
        }
      },
      edition: {
        serializedName: "edition",
        type: {
          name: "Enum",
          allowedValues: [
            "2",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "31",
            "32",
            "33",
            "34",
            "35",
            "36",
            "37",
            "38",
            "39",
            "40",
            "41",
            "42",
            "43",
            "44",
            "45",
            "46",
            "47"
          ]
        }
      },
      kind: {
        serializedName: "kind",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const PublishIssueQueueDto: msRest.CompositeMapper = {
  serializedName: "PublishIssueQueueDto",
  type: {
    name: "Composite",
    className: "PublishIssueQueueDto",
    modelProperties: {
      issueId: {
        serializedName: "issueId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const PublishIssuesEmailDto: msRest.CompositeMapper = {
  serializedName: "PublishIssuesEmailDto",
  type: {
    name: "Composite",
    className: "PublishIssuesEmailDto",
    modelProperties: {
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      tenantName: {
        serializedName: "tenantName",
        type: {
          name: "String"
        }
      },
      domain: {
        serializedName: "domain",
        type: {
          name: "String"
        }
      },
      senderName: {
        serializedName: "senderName",
        type: {
          name: "String"
        }
      },
      subject: {
        serializedName: "subject",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const PurgedInventoryNumberPatternDto: msRest.CompositeMapper = {
  serializedName: "PurgedInventoryNumberPatternDto",
  type: {
    name: "Composite",
    className: "PurgedInventoryNumberPatternDto",
    modelProperties: {
      count: {
        serializedName: "count",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const PushNotificationQueueDto: msRest.CompositeMapper = {
  serializedName: "PushNotificationQueueDto",
  type: {
    name: "Composite",
    className: "PushNotificationQueueDto",
    modelProperties: {
      receiverIdentifier: {
        serializedName: "receiverIdentifier",
        type: {
          name: "String"
        }
      },
      platform: {
        serializedName: "platform",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2"
          ]
        }
      },
      notification: {
        serializedName: "notification",
        type: {
          name: "Composite",
          className: "INotificationDto"
        }
      }
    }
  }
};

export const PushRegisterDto: msRest.CompositeMapper = {
  serializedName: "PushRegisterDto",
  type: {
    name: "Composite",
    className: "PushRegisterDto",
    modelProperties: {
      token: {
        serializedName: "token",
        type: {
          name: "String"
        }
      },
      hardwareId: {
        serializedName: "hardwareId",
        type: {
          name: "String"
        }
      },
      platform: {
        serializedName: "platform",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2"
          ]
        }
      }
    }
  }
};

export const PushStartedMissionQueueDto: msRest.CompositeMapper = {
  serializedName: "PushStartedMissionQueueDto",
  type: {
    name: "Composite",
    className: "PushStartedMissionQueueDto",
    modelProperties: {
      missionId: {
        serializedName: "missionId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const PushedInfoNotificationDto: msRest.CompositeMapper = {
  serializedName: "PushedInfoNotificationDto",
  type: {
    name: "Composite",
    className: "PushedInfoNotificationDto",
    modelProperties: {
      infoId: {
        serializedName: "infoId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      message: {
        serializedName: "message",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "5"
          ]
        }
      }
    }
  }
};

export const PushedInfoQueueDto: msRest.CompositeMapper = {
  serializedName: "PushedInfoQueueDto",
  type: {
    name: "Composite",
    className: "PushedInfoQueueDto",
    modelProperties: {
      infoId: {
        serializedName: "infoId",
        type: {
          name: "Number"
        }
      },
      culture: {
        serializedName: "culture",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const PushedStartedMissionNotificationDto: msRest.CompositeMapper = {
  serializedName: "PushedStartedMissionNotificationDto",
  type: {
    name: "Composite",
    className: "PushedStartedMissionNotificationDto",
    modelProperties: {
      missionId: {
        serializedName: "missionId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      message: {
        serializedName: "message",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "5"
          ]
        }
      }
    }
  }
};

export const QuickAccessKnowledgeConfigurationDto: msRest.CompositeMapper = {
  serializedName: "QuickAccessKnowledgeConfigurationDto",
  type: {
    name: "Composite",
    className: "QuickAccessKnowledgeConfigurationDto",
    modelProperties: {
      quickAccessKnowledgeConfigurationId: {
        serializedName: "quickAccessKnowledgeConfigurationId",
        type: {
          name: "Number"
        }
      },
      visibility: {
        serializedName: "visibility",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3"
          ]
        }
      },
      knowledgeArticles: {
        serializedName: "knowledgeArticles",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "KnowledgeArticleSimpleDto"
            }
          }
        }
      }
    }
  }
};

export const QuickAccessIssueTemplateConfigurationDto: msRest.CompositeMapper = {
  serializedName: "QuickAccessIssueTemplateConfigurationDto",
  type: {
    name: "Composite",
    className: "QuickAccessIssueTemplateConfigurationDto",
    modelProperties: {
      quickAccessIssueTemplateConfigurationId: {
        serializedName: "quickAccessIssueTemplateConfigurationId",
        type: {
          name: "Number"
        }
      },
      visibility: {
        serializedName: "visibility",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3"
          ]
        }
      },
      historyVisibility: {
        serializedName: "historyVisibility",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1"
          ]
        }
      },
      issueTemplates: {
        serializedName: "issueTemplates",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "IssueTemplateSimpleDto"
            }
          }
        }
      }
    }
  }
};

export const QuickAccessDashboardConfigurationDto: msRest.CompositeMapper = {
  serializedName: "QuickAccessDashboardConfigurationDto",
  type: {
    name: "Composite",
    className: "QuickAccessDashboardConfigurationDto",
    modelProperties: {
      quickAccessDashboardConfigurationId: {
        serializedName: "quickAccessDashboardConfigurationId",
        type: {
          name: "Number"
        }
      },
      visibility: {
        serializedName: "visibility",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2"
          ]
        }
      }
    }
  }
};

export const QuickAccessConfigurationDto: msRest.CompositeMapper = {
  serializedName: "QuickAccessConfigurationDto",
  type: {
    name: "Composite",
    className: "QuickAccessConfigurationDto",
    modelProperties: {
      quickAccessConfigurationId: {
        serializedName: "quickAccessConfigurationId",
        type: {
          name: "Number"
        }
      },
      isActivated: {
        serializedName: "isActivated",
        type: {
          name: "Boolean"
        }
      },
      isShowingProperties: {
        nullable: true,
        serializedName: "isShowingProperties",
        type: {
          name: "Boolean"
        }
      },
      knowledgeConfiguration: {
        serializedName: "knowledgeConfiguration",
        type: {
          name: "Composite",
          className: "QuickAccessKnowledgeConfigurationDto"
        }
      },
      issueTemplateConfiguration: {
        serializedName: "issueTemplateConfiguration",
        type: {
          name: "Composite",
          className: "QuickAccessIssueTemplateConfigurationDto"
        }
      },
      dashboardConfiguration: {
        serializedName: "dashboardConfiguration",
        type: {
          name: "Composite",
          className: "QuickAccessDashboardConfigurationDto"
        }
      }
    }
  }
};

export const QuickAccessConfigurationSimpleListDto: msRest.CompositeMapper = {
  serializedName: "QuickAccessConfigurationSimpleListDto",
  type: {
    name: "Composite",
    className: "QuickAccessConfigurationSimpleListDto",
    modelProperties: {
      quickAccessConfigurationId: {
        serializedName: "quickAccessConfigurationId",
        type: {
          name: "Number"
        }
      },
      isActivated: {
        serializedName: "isActivated",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const QuickAccessDto: msRest.CompositeMapper = {
  serializedName: "QuickAccessDto",
  type: {
    name: "Composite",
    className: "QuickAccessDto",
    modelProperties: {
      link: {
        serializedName: "link",
        type: {
          name: "String"
        }
      },
      key: {
        serializedName: "key",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ReceiptImportDto: msRest.CompositeMapper = {
  serializedName: "ReceiptImportDto",
  type: {
    name: "Composite",
    className: "ReceiptImportDto",
    modelProperties: {
      index: {
        serializedName: "index",
        type: {
          name: "Number"
        }
      },
      missionId: {
        serializedName: "missionId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      bookedAt: {
        serializedName: "bookedAt",
        type: {
          name: "String"
        }
      },
      debit: {
        nullable: true,
        serializedName: "debit",
        type: {
          name: "Number"
        }
      },
      credit: {
        nullable: true,
        serializedName: "credit",
        type: {
          name: "Number"
        }
      },
      taxInPercent: {
        nullable: true,
        serializedName: "taxInPercent",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ReceivedIssueQueueDto: msRest.CompositeMapper = {
  serializedName: "ReceivedIssueQueueDto",
  type: {
    name: "Composite",
    className: "ReceivedIssueQueueDto",
    modelProperties: {
      issueId: {
        serializedName: "issueId",
        type: {
          name: "Number"
        }
      },
      issueType: {
        serializedName: "issueType",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "5"
          ]
        }
      }
    }
  }
};

export const ReevaluateAccessQueueDto: msRest.CompositeMapper = {
  serializedName: "ReevaluateAccessQueueDto",
  type: {
    name: "Composite",
    className: "ReevaluateAccessQueueDto",
    modelProperties: {
      entityId: {
        serializedName: "entityId",
        type: {
          name: "Number"
        }
      },
      entityType: {
        serializedName: "entityType",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
            "31",
            "32",
            "33",
            "34",
            "35",
            "36",
            "37",
            "38",
            "39",
            "40",
            "41",
            "42",
            "43",
            "44",
            "45"
          ]
        }
      }
    }
  }
};

export const ReevaluateAccessChunkQueueDto: msRest.CompositeMapper = {
  serializedName: "ReevaluateAccessChunkQueueDto",
  type: {
    name: "Composite",
    className: "ReevaluateAccessChunkQueueDto",
    modelProperties: {
      items: {
        serializedName: "items",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ReevaluateAccessQueueDto"
            }
          }
        }
      }
    }
  }
};

export const RefreshTokenDto: msRest.CompositeMapper = {
  serializedName: "RefreshTokenDto",
  type: {
    name: "Composite",
    className: "RefreshTokenDto",
    modelProperties: {
      meta: {
        serializedName: "meta",
        type: {
          name: "Composite",
          className: "MetaEntryDto"
        }
      },
      refreshToken: {
        serializedName: "refreshToken",
        type: {
          name: "String"
        }
      },
      roleIds: {
        nullable: true,
        serializedName: "roleIds",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Number"
            }
          }
        }
      },
      locationIds: {
        nullable: true,
        serializedName: "locationIds",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Number"
            }
          }
        }
      }
    }
  }
};

export const RefreshTokenV3Dto: msRest.CompositeMapper = {
  serializedName: "RefreshTokenV3Dto",
  type: {
    name: "Composite",
    className: "RefreshTokenV3Dto",
    modelProperties: {
      meta: {
        serializedName: "meta",
        type: {
          name: "Composite",
          className: "MetaEntryDto"
        }
      },
      refreshToken: {
        serializedName: "refreshToken",
        type: {
          name: "String"
        }
      },
      tenantId: {
        nullable: true,
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      client: {
        serializedName: "client",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4"
          ]
        }
      }
    }
  }
};

export const RefreshWinterhalterProductOrderQueueDto: msRest.CompositeMapper = {
  serializedName: "RefreshWinterhalterProductOrderQueueDto",
  type: {
    name: "Composite",
    className: "RefreshWinterhalterProductOrderQueueDto",
    modelProperties: {
      issueId: {
        serializedName: "issueId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const RefreshWinterhalterServiceCallQueueDto: msRest.CompositeMapper = {
  serializedName: "RefreshWinterhalterServiceCallQueueDto",
  type: {
    name: "Composite",
    className: "RefreshWinterhalterServiceCallQueueDto",
    modelProperties: {
      issueId: {
        serializedName: "issueId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const RegisterDeviceByLocationDto: msRest.CompositeMapper = {
  serializedName: "RegisterDeviceByLocationDto",
  type: {
    name: "Composite",
    className: "RegisterDeviceByLocationDto",
    modelProperties: {
      hardwareId: {
        serializedName: "hardwareId",
        type: {
          name: "String"
        }
      },
      deviceName: {
        serializedName: "deviceName",
        type: {
          name: "String"
        }
      },
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const RegisterDeviceDto: msRest.CompositeMapper = {
  serializedName: "RegisterDeviceDto",
  type: {
    name: "Composite",
    className: "RegisterDeviceDto",
    modelProperties: {
      hardwareId: {
        serializedName: "hardwareId",
        type: {
          name: "String"
        }
      },
      code: {
        serializedName: "code",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const RegisterUserDto: msRest.CompositeMapper = {
  serializedName: "RegisterUserDto",
  type: {
    name: "Composite",
    className: "RegisterUserDto",
    modelProperties: {
      pinFromEmail: {
        serializedName: "pinFromEmail",
        type: {
          name: "String"
        }
      },
      passwordBase64: {
        serializedName: "passwordBase64",
        type: {
          name: "String"
        }
      },
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const RegistrationEmailDto: msRest.CompositeMapper = {
  serializedName: "RegistrationEmailDto",
  type: {
    name: "Composite",
    className: "RegistrationEmailDto",
    modelProperties: {
      code: {
        serializedName: "code",
        type: {
          name: "String"
        }
      },
      tenantName: {
        serializedName: "tenantName",
        type: {
          name: "String"
        }
      },
      domain: {
        serializedName: "domain",
        type: {
          name: "String"
        }
      },
      senderName: {
        serializedName: "senderName",
        type: {
          name: "String"
        }
      },
      brandName: {
        serializedName: "brandName",
        type: {
          name: "String"
        }
      },
      subject: {
        serializedName: "subject",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const RemoveLabelDto: msRest.CompositeMapper = {
  serializedName: "RemoveLabelDto",
  type: {
    name: "Composite",
    className: "RemoveLabelDto",
    modelProperties: {
      entityId: {
        serializedName: "entityId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      }
    }
  }
};

export const RenderDocumentTemplateDto: msRest.CompositeMapper = {
  serializedName: "RenderDocumentTemplateDto",
  type: {
    name: "Composite",
    className: "RenderDocumentTemplateDto",
    modelProperties: {
      templateId: {
        nullable: true,
        serializedName: "templateId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const TextTemplateContextDto: msRest.CompositeMapper = {
  serializedName: "TextTemplateContextDto",
  type: {
    name: "Composite",
    className: "TextTemplateContextDto",
    modelProperties: {
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "181",
            "182",
            "183",
            "184",
            "191",
            "192",
            "193",
            "194"
          ]
        }
      }
    }
  }
};

export const RenderTemplateDto: msRest.CompositeMapper = {
  serializedName: "RenderTemplateDto",
  type: {
    name: "Composite",
    className: "RenderTemplateDto",
    modelProperties: {
      context: {
        serializedName: "context",
        type: {
          name: "Composite",
          className: "TextTemplateContextDto"
        }
      }
    }
  }
};

export const RenderedTemplateDto: msRest.CompositeMapper = {
  serializedName: "RenderedTemplateDto",
  type: {
    name: "Composite",
    className: "RenderedTemplateDto",
    modelProperties: {
      content: {
        serializedName: "content",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const RepairCacheFeatureQueueDto: msRest.CompositeMapper = {
  serializedName: "RepairCacheFeatureQueueDto",
  type: {
    name: "Composite",
    className: "RepairCacheFeatureQueueDto",
    modelProperties: {
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      feature: {
        serializedName: "feature",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const RepeatableFormRowCreateDto: msRest.CompositeMapper = {
  serializedName: "RepeatableFormRowCreateDto",
  type: {
    name: "Composite",
    className: "RepeatableFormRowCreateDto",
    modelProperties: {
      formRowId: {
        serializedName: "formRowId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      },
      fields: {
        serializedName: "fields",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FormFieldCreateDto"
            }
          }
        }
      },
      maxRepeat: {
        nullable: true,
        serializedName: "maxRepeat",
        type: {
          name: "Number"
        }
      },
      minRepeat: {
        nullable: true,
        serializedName: "minRepeat",
        type: {
          name: "Number"
        }
      },
      repeatLabel: {
        serializedName: "repeatLabel",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ReportDataTableDto: msRest.CompositeMapper = {
  serializedName: "ReportDataTableDto",
  type: {
    name: "Composite",
    className: "ReportDataTableDto",
    modelProperties: {
      totalItems: {
        nullable: true,
        serializedName: "totalItems",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      timezone: {
        serializedName: "timezone",
        type: {
          name: "String"
        }
      },
      culture: {
        serializedName: "culture",
        type: {
          name: "String"
        }
      },
      pageOperation: {
        serializedName: "pageOperation",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
            "31",
            "32",
            "211",
            "212",
            "213",
            "214",
            "215"
          ]
        }
      },
      descendantId: {
        nullable: true,
        serializedName: "descendantId",
        type: {
          name: "Number"
        }
      },
      fileFormat: {
        serializedName: "fileFormat",
        type: {
          name: "Enum",
          allowedValues: [
            "1"
          ]
        }
      },
      destination: {
        serializedName: "destination",
        type: {
          name: "Composite",
          className: "CommonDestinationDto"
        }
      },
      columns: {
        serializedName: "columns",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CommonColumnDto"
            }
          }
        }
      },
      filters: {
        serializedName: "filters",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CommonFilterDto"
            }
          }
        }
      }
    }
  }
};

export const ReportDataTableQueueDto: msRest.CompositeMapper = {
  serializedName: "ReportDataTableQueueDto",
  type: {
    name: "Composite",
    className: "ReportDataTableQueueDto",
    modelProperties: {
      content: {
        serializedName: "content",
        type: {
          name: "Composite",
          className: "ReportDataTableDto"
        }
      },
      token: {
        serializedName: "token",
        type: {
          name: "Object"
        }
      }
    }
  }
};

export const RequestPasswordResetEmailDto: msRest.CompositeMapper = {
  serializedName: "RequestPasswordResetEmailDto",
  type: {
    name: "Composite",
    className: "RequestPasswordResetEmailDto",
    modelProperties: {
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      },
      brand: {
        serializedName: "brand",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      }
    }
  }
};

export const RequestUserRegistrationDto: msRest.CompositeMapper = {
  serializedName: "RequestUserRegistrationDto",
  type: {
    name: "Composite",
    className: "RequestUserRegistrationDto",
    modelProperties: {
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      },
      brand: {
        serializedName: "brand",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      culture: {
        serializedName: "culture",
        type: {
          name: "String"
        }
      },
      timeZone: {
        serializedName: "timeZone",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ResetPasswordDto: msRest.CompositeMapper = {
  serializedName: "ResetPasswordDto",
  type: {
    name: "Composite",
    className: "ResetPasswordDto",
    modelProperties: {
      token: {
        serializedName: "token",
        type: {
          name: "String"
        }
      },
      newPassword: {
        serializedName: "newPassword",
        type: {
          name: "String"
        }
      },
      newPasswordBase64: {
        serializedName: "newPasswordBase64",
        type: {
          name: "String"
        }
      },
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ResourceManufacturerSimpleDto: msRest.CompositeMapper = {
  serializedName: "ResourceManufacturerSimpleDto",
  type: {
    name: "Composite",
    className: "ResourceManufacturerSimpleDto",
    modelProperties: {
      resourceManufacturerId: {
        serializedName: "resourceManufacturerId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ResourceModelSimpleDto: msRest.CompositeMapper = {
  serializedName: "ResourceModelSimpleDto",
  type: {
    name: "Composite",
    className: "ResourceModelSimpleDto",
    modelProperties: {
      resourceModelId: {
        serializedName: "resourceModelId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ResourceDto: msRest.CompositeMapper = {
  serializedName: "ResourceDto",
  type: {
    name: "Composite",
    className: "ResourceDto",
    modelProperties: {
      resourceId: {
        serializedName: "resourceId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      parent: {
        serializedName: "parent",
        type: {
          name: "Composite",
          className: "ResourceSimpleDto"
        }
      },
      currentState: {
        serializedName: "currentState",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3"
          ]
        }
      },
      serialNumber: {
        serializedName: "serialNumber",
        type: {
          name: "String"
        }
      },
      installationDate: {
        serializedName: "installationDate",
        type: {
          name: "String"
        }
      },
      inventoryNumber: {
        serializedName: "inventoryNumber",
        type: {
          name: "String"
        }
      },
      inventoryNumberCount: {
        nullable: true,
        serializedName: "inventoryNumberCount",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "LocationSimpleDto"
        }
      },
      locationGroup: {
        serializedName: "locationGroup",
        type: {
          name: "Composite",
          className: "LocationGroupSimpleDto"
        }
      },
      inheritsManufacturer: {
        serializedName: "inheritsManufacturer",
        type: {
          name: "Boolean"
        }
      },
      manufacturer: {
        serializedName: "manufacturer",
        type: {
          name: "Composite",
          className: "ResourceManufacturerSimpleDto"
        }
      },
      inheritsModel: {
        serializedName: "inheritsModel",
        type: {
          name: "Boolean"
        }
      },
      model: {
        serializedName: "model",
        type: {
          name: "Composite",
          className: "ResourceModelSimpleDto"
        }
      },
      isInProgressSince: {
        serializedName: "isInProgressSince",
        type: {
          name: "String"
        }
      },
      hasChildren: {
        serializedName: "hasChildren",
        type: {
          name: "Boolean"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      path: {
        serializedName: "path",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ResourcePathPartDto"
            }
          }
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueDto"
            }
          }
        }
      },
      inheritsQuickAccessConfiguration: {
        serializedName: "inheritsQuickAccessConfiguration",
        type: {
          name: "Boolean"
        }
      },
      quickAccessConfiguration: {
        serializedName: "quickAccessConfiguration",
        type: {
          name: "Composite",
          className: "QuickAccessConfigurationDto"
        }
      },
      quickAccess: {
        serializedName: "quickAccess",
        type: {
          name: "Composite",
          className: "QuickAccessDto"
        }
      },
      inheritsSuggestions: {
        serializedName: "inheritsSuggestions",
        type: {
          name: "Boolean"
        }
      },
      primarySuggestions: {
        serializedName: "primarySuggestions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PrimaryEditorSuggestionDto"
            }
          }
        }
      },
      alternativeSuggestions: {
        serializedName: "alternativeSuggestions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AlternativeEditorSuggestionDto"
            }
          }
        }
      },
      inheritsKnowledge: {
        serializedName: "inheritsKnowledge",
        type: {
          name: "Boolean"
        }
      },
      knowledgeArticles: {
        serializedName: "knowledgeArticles",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "KnowledgeArticleSimpleDto"
            }
          }
        }
      },
      inheritsIssueTemplate: {
        serializedName: "inheritsIssueTemplate",
        type: {
          name: "Boolean"
        }
      },
      issueTemplates: {
        serializedName: "issueTemplates",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "IssueTemplateSimpleDto"
            }
          }
        }
      },
      rules: {
        serializedName: "rules",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EscalationRuleDto"
            }
          }
        }
      },
      attachments: {
        serializedName: "attachments",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AttachmentForTechDto"
            }
          }
        }
      },
      links: {
        serializedName: "links",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LinkDto"
            }
          }
        }
      },
      safe: {
        serializedName: "safe",
        type: {
          name: "Composite",
          className: "SafeDto"
        }
      },
      lastModified: {
        serializedName: "lastModified",
        type: {
          name: "String"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ResourceEventListDto: msRest.CompositeMapper = {
  serializedName: "ResourceEventListDto",
  type: {
    name: "Composite",
    className: "ResourceEventListDto",
    modelProperties: {
      resourceEventId: {
        serializedName: "resourceEventId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      lastModified: {
        serializedName: "lastModified",
        type: {
          name: "String"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "String"
        }
      },
      oldState: {
        serializedName: "oldState",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3"
          ]
        }
      },
      newState: {
        serializedName: "newState",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3"
          ]
        }
      },
      oldLocation: {
        serializedName: "oldLocation",
        type: {
          name: "Composite",
          className: "LocationSimpleDto"
        }
      },
      newLocation: {
        serializedName: "newLocation",
        type: {
          name: "Composite",
          className: "LocationSimpleDto"
        }
      }
    }
  }
};

export const ResourceFilterDto: msRest.CompositeMapper = {
  serializedName: "ResourceFilterDto",
  type: {
    name: "Composite",
    className: "ResourceFilterDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      }
    }
  }
};

export const ResourceImportDto: msRest.CompositeMapper = {
  serializedName: "ResourceImportDto",
  type: {
    name: "Composite",
    className: "ResourceImportDto",
    modelProperties: {
      index: {
        serializedName: "index",
        type: {
          name: "Number"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "Object"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      serialNumber: {
        serializedName: "serialNumber",
        type: {
          name: "String"
        }
      },
      inventoryNumber: {
        serializedName: "inventoryNumber",
        type: {
          name: "String"
        }
      },
      inheritsSuggestions: {
        nullable: true,
        serializedName: "inheritsSuggestions",
        type: {
          name: "Boolean"
        }
      },
      inheritsQuickAccess: {
        nullable: true,
        serializedName: "inheritsQuickAccess",
        type: {
          name: "Boolean"
        }
      },
      inheritsKnowledge: {
        nullable: true,
        serializedName: "inheritsKnowledge",
        type: {
          name: "Boolean"
        }
      },
      inheritsIssueTemplate: {
        nullable: true,
        serializedName: "inheritsIssueTemplate",
        type: {
          name: "Boolean"
        }
      },
      inheritsManufacturer: {
        nullable: true,
        serializedName: "inheritsManufacturer",
        type: {
          name: "Boolean"
        }
      },
      manufacturer: {
        serializedName: "manufacturer",
        type: {
          name: "String"
        }
      },
      inheritsModel: {
        nullable: true,
        serializedName: "inheritsModel",
        type: {
          name: "Boolean"
        }
      },
      model: {
        serializedName: "model",
        type: {
          name: "String"
        }
      },
      installationDate: {
        serializedName: "installationDate",
        type: {
          name: "String"
        }
      },
      stereotypeId: {
        serializedName: "stereotypeId",
        type: {
          name: "Object"
        }
      },
      parentId: {
        serializedName: "parentId",
        type: {
          name: "Object"
        }
      },
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Object"
        }
      },
      locationGroupId: {
        serializedName: "locationGroupId",
        type: {
          name: "Object"
        }
      },
      customValues: {
        serializedName: "customValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Object"
            }
          }
        }
      }
    }
  }
};

export const ResourceInTicketDto: msRest.CompositeMapper = {
  serializedName: "ResourceInTicketDto",
  type: {
    name: "Composite",
    className: "ResourceInTicketDto",
    modelProperties: {
      resourceId: {
        serializedName: "resourceId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      locationId: {
        nullable: true,
        serializedName: "locationId",
        type: {
          name: "Number"
        }
      },
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "LocationSimpleDto"
        }
      },
      path: {
        serializedName: "path",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ResourcePathPartDto"
            }
          }
        }
      },
      inventoryNumber: {
        serializedName: "inventoryNumber",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      hasChildren: {
        serializedName: "hasChildren",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const ResourceInTicketListDto: msRest.CompositeMapper = {
  serializedName: "ResourceInTicketListDto",
  type: {
    name: "Composite",
    className: "ResourceInTicketListDto",
    modelProperties: {
      resourceId: {
        serializedName: "resourceId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "LocationListDto"
        }
      },
      currentState: {
        serializedName: "currentState",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3"
          ]
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      path: {
        serializedName: "path",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ResourcePathPartSimpleListDto"
            }
          }
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleListDto"
        }
      }
    }
  }
};

export const ResourceQuickAccessListDto: msRest.CompositeMapper = {
  serializedName: "ResourceQuickAccessListDto",
  type: {
    name: "Composite",
    className: "ResourceQuickAccessListDto",
    modelProperties: {
      link: {
        serializedName: "link",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ResourceListDto: msRest.CompositeMapper = {
  serializedName: "ResourceListDto",
  type: {
    name: "Composite",
    className: "ResourceListDto",
    modelProperties: {
      resourceId: {
        serializedName: "resourceId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      installationDate: {
        serializedName: "installationDate",
        type: {
          name: "String"
        }
      },
      currentState: {
        serializedName: "currentState",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3"
          ]
        }
      },
      parentId: {
        nullable: true,
        serializedName: "parentId",
        type: {
          name: "Number"
        }
      },
      locationId: {
        nullable: true,
        serializedName: "locationId",
        type: {
          name: "Number"
        }
      },
      inventoryNumberCount: {
        nullable: true,
        serializedName: "inventoryNumberCount",
        type: {
          name: "Number"
        }
      },
      inventoryNumber: {
        serializedName: "inventoryNumber",
        type: {
          name: "String"
        }
      },
      serialNumber: {
        serializedName: "serialNumber",
        type: {
          name: "String"
        }
      },
      isInProgressSince: {
        serializedName: "isInProgressSince",
        type: {
          name: "String"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      manufacturer: {
        serializedName: "manufacturer",
        type: {
          name: "Composite",
          className: "ResourceManufacturerSimpleListDto"
        }
      },
      model: {
        serializedName: "model",
        type: {
          name: "Composite",
          className: "ResourceModelSimpleListDto"
        }
      },
      quickAccess: {
        serializedName: "quickAccess",
        type: {
          name: "Composite",
          className: "ResourceQuickAccessListDto"
        }
      },
      quickAccessConfiguration: {
        serializedName: "quickAccessConfiguration",
        type: {
          name: "Composite",
          className: "QuickAccessConfigurationSimpleListDto"
        }
      },
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "LocationListForResourcesDto"
        }
      },
      locationGroup: {
        serializedName: "locationGroup",
        type: {
          name: "Composite",
          className: "LocationGroupSimpleListDto"
        }
      },
      path: {
        serializedName: "path",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ResourcePathPartSimpleListDto"
            }
          }
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleListDto"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueListDto"
            }
          }
        }
      },
      hasChildren: {
        serializedName: "hasChildren",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const ResourceLocationProfileDto: msRest.CompositeMapper = {
  serializedName: "ResourceLocationProfileDto",
  type: {
    name: "Composite",
    className: "ResourceLocationProfileDto",
    modelProperties: {
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      locationNumber: {
        serializedName: "locationNumber",
        type: {
          name: "String"
        }
      },
      invoiceAddress: {
        serializedName: "invoiceAddress",
        type: {
          name: "Composite",
          className: "AddressSimpleDto"
        }
      },
      shippingAddress: {
        serializedName: "shippingAddress",
        type: {
          name: "Composite",
          className: "AddressSimpleDto"
        }
      }
    }
  }
};

export const ResourceManufacturerDto: msRest.CompositeMapper = {
  serializedName: "ResourceManufacturerDto",
  type: {
    name: "Composite",
    className: "ResourceManufacturerDto",
    modelProperties: {
      resourceManufacturerId: {
        serializedName: "resourceManufacturerId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ResourceManufacturerImportDto: msRest.CompositeMapper = {
  serializedName: "ResourceManufacturerImportDto",
  type: {
    name: "Composite",
    className: "ResourceManufacturerImportDto",
    modelProperties: {
      index: {
        serializedName: "index",
        type: {
          name: "Number"
        }
      },
      manufacturer: {
        serializedName: "manufacturer",
        type: {
          name: "String"
        }
      },
      model: {
        serializedName: "model",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ResourceManufacturerListDto: msRest.CompositeMapper = {
  serializedName: "ResourceManufacturerListDto",
  type: {
    name: "Composite",
    className: "ResourceManufacturerListDto",
    modelProperties: {
      resourceManufacturerId: {
        serializedName: "resourceManufacturerId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ResourceManufacturerMergeDto: msRest.CompositeMapper = {
  serializedName: "ResourceManufacturerMergeDto",
  type: {
    name: "Composite",
    className: "ResourceManufacturerMergeDto",
    modelProperties: {
      newManufacturerId: {
        serializedName: "newManufacturerId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ResourceModelDto: msRest.CompositeMapper = {
  serializedName: "ResourceModelDto",
  type: {
    name: "Composite",
    className: "ResourceModelDto",
    modelProperties: {
      resourceModelId: {
        serializedName: "resourceModelId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ResourceModelListDto: msRest.CompositeMapper = {
  serializedName: "ResourceModelListDto",
  type: {
    name: "Composite",
    className: "ResourceModelListDto",
    modelProperties: {
      resourceModelId: {
        serializedName: "resourceModelId",
        type: {
          name: "Number"
        }
      },
      resourceManufacturer: {
        serializedName: "resourceManufacturer",
        type: {
          name: "Composite",
          className: "ResourceManufacturerSimpleListDto"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ResourceModelMergeDto: msRest.CompositeMapper = {
  serializedName: "ResourceModelMergeDto",
  type: {
    name: "Composite",
    className: "ResourceModelMergeDto",
    modelProperties: {
      newModelId: {
        serializedName: "newModelId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ResourceModelMoveDto: msRest.CompositeMapper = {
  serializedName: "ResourceModelMoveDto",
  type: {
    name: "Composite",
    className: "ResourceModelMoveDto",
    modelProperties: {
      newManufacturerId: {
        serializedName: "newManufacturerId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ResourceProfileConsumptionDto: msRest.CompositeMapper = {
  serializedName: "ResourceProfileConsumptionDto",
  type: {
    name: "Composite",
    className: "ResourceProfileConsumptionDto",
    modelProperties: {
      consumptionId: {
        serializedName: "consumptionId",
        type: {
          name: "Number"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      code: {
        serializedName: "code",
        type: {
          name: "String"
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      },
      unit: {
        serializedName: "unit",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ResourceProfileHintDto: msRest.CompositeMapper = {
  serializedName: "ResourceProfileHintDto",
  type: {
    name: "Composite",
    className: "ResourceProfileHintDto",
    modelProperties: {
      hintId: {
        serializedName: "hintId",
        type: {
          name: "Number"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      code: {
        serializedName: "code",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      level: {
        serializedName: "level",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      }
    }
  }
};

export const ResourceProfileErrorDto: msRest.CompositeMapper = {
  serializedName: "ResourceProfileErrorDto",
  type: {
    name: "Composite",
    className: "ResourceProfileErrorDto",
    modelProperties: {
      errorId: {
        serializedName: "errorId",
        type: {
          name: "Number"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      code: {
        serializedName: "code",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ResourceProfileStatisticsDto: msRest.CompositeMapper = {
  serializedName: "ResourceProfileStatisticsDto",
  type: {
    name: "Composite",
    className: "ResourceProfileStatisticsDto",
    modelProperties: {
      lifeTime: {
        serializedName: "lifeTime",
        type: {
          name: "String"
        }
      },
      lifeTimeMonths: {
        serializedName: "lifeTimeMonths",
        type: {
          name: "Number"
        }
      },
      totalMissions: {
        serializedName: "totalMissions",
        type: {
          name: "Number"
        }
      },
      missionPerMonth: {
        serializedName: "missionPerMonth",
        type: {
          name: "Number"
        }
      },
      totalCost: {
        serializedName: "totalCost",
        type: {
          name: "Number"
        }
      },
      averageCost: {
        serializedName: "averageCost",
        type: {
          name: "Number"
        }
      },
      costsPerMonth: {
        serializedName: "costsPerMonth",
        type: {
          name: "Number"
        }
      },
      totalWorkHours: {
        serializedName: "totalWorkHours",
        type: {
          name: "Number"
        }
      },
      workPerMission: {
        serializedName: "workPerMission",
        type: {
          name: "Number"
        }
      },
      workPerMonth: {
        serializedName: "workPerMonth",
        type: {
          name: "Number"
        }
      },
      totalFaultyHours: {
        serializedName: "totalFaultyHours",
        type: {
          name: "Number"
        }
      },
      availabilityInPercent: {
        serializedName: "availabilityInPercent",
        type: {
          name: "Number"
        }
      },
      faultyHoursPerMission: {
        serializedName: "faultyHoursPerMission",
        type: {
          name: "Number"
        }
      },
      faultyHoursPerMonth: {
        serializedName: "faultyHoursPerMonth",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ResourceProfileDashboardDto: msRest.CompositeMapper = {
  serializedName: "ResourceProfileDashboardDto",
  type: {
    name: "Composite",
    className: "ResourceProfileDashboardDto",
    modelProperties: {
      consumptions: {
        serializedName: "consumptions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ResourceProfileConsumptionDto"
            }
          }
        }
      },
      hints: {
        serializedName: "hints",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ResourceProfileHintDto"
            }
          }
        }
      },
      errors: {
        serializedName: "errors",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ResourceProfileErrorDto"
            }
          }
        }
      },
      statistics: {
        serializedName: "statistics",
        type: {
          name: "Composite",
          className: "ResourceProfileStatisticsDto"
        }
      }
    }
  }
};

export const ResourceProfileDto: msRest.CompositeMapper = {
  serializedName: "ResourceProfileDto",
  type: {
    name: "Composite",
    className: "ResourceProfileDto",
    modelProperties: {
      resourceId: {
        serializedName: "resourceId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      inventoryNumber: {
        serializedName: "inventoryNumber",
        type: {
          name: "String"
        }
      },
      installationDate: {
        serializedName: "installationDate",
        type: {
          name: "String"
        }
      },
      manufacturer: {
        serializedName: "manufacturer",
        type: {
          name: "Composite",
          className: "ResourceManufacturerSimpleDto"
        }
      },
      model: {
        serializedName: "model",
        type: {
          name: "Composite",
          className: "ResourceModelSimpleDto"
        }
      },
      parent: {
        serializedName: "parent",
        type: {
          name: "Composite",
          className: "ResourceSimpleDto"
        }
      },
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "ResourceLocationProfileDto"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeUsageDto"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueDto"
            }
          }
        }
      }
    }
  }
};

export const ResourceProfileIssueTemplateShortCutDto: msRest.CompositeMapper = {
  serializedName: "ResourceProfileIssueTemplateShortCutDto",
  type: {
    name: "Composite",
    className: "ResourceProfileIssueTemplateShortCutDto",
    modelProperties: {
      issueTemplateId: {
        serializedName: "issueTemplateId",
        type: {
          name: "Number"
        }
      },
      contentType: {
        serializedName: "contentType",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "5"
          ]
        }
      }
    }
  }
};

export const ResourceProfileFeatureDto: msRest.CompositeMapper = {
  serializedName: "ResourceProfileFeatureDto",
  type: {
    name: "Composite",
    className: "ResourceProfileFeatureDto",
    modelProperties: {
      isShowingProperties: {
        serializedName: "isShowingProperties",
        type: {
          name: "Boolean"
        }
      },
      isIssueTemplateVisible: {
        serializedName: "isIssueTemplateVisible",
        type: {
          name: "Boolean"
        }
      },
      isIssueHistoryVisible: {
        serializedName: "isIssueHistoryVisible",
        type: {
          name: "Boolean"
        }
      },
      issueTemplateShortcutId: {
        nullable: true,
        serializedName: "issueTemplateShortcutId",
        type: {
          name: "Number"
        }
      },
      issueTemplateShortcut: {
        serializedName: "issueTemplateShortcut",
        type: {
          name: "Composite",
          className: "ResourceProfileIssueTemplateShortCutDto"
        }
      },
      isKnowledgeVisible: {
        serializedName: "isKnowledgeVisible",
        type: {
          name: "Boolean"
        }
      },
      knowledgeShortcutId: {
        nullable: true,
        serializedName: "knowledgeShortcutId",
        type: {
          name: "Number"
        }
      },
      isDashboardVisible: {
        serializedName: "isDashboardVisible",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const ResourceProfileKnowledgeDto: msRest.CompositeMapper = {
  serializedName: "ResourceProfileKnowledgeDto",
  type: {
    name: "Composite",
    className: "ResourceProfileKnowledgeDto",
    modelProperties: {
      knowledgeArticles: {
        serializedName: "knowledgeArticles",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "KnowledgeArticleSimpleDto"
            }
          }
        }
      }
    }
  }
};

export const ResourceProfileOverviewDto: msRest.CompositeMapper = {
  serializedName: "ResourceProfileOverviewDto",
  type: {
    name: "Composite",
    className: "ResourceProfileOverviewDto",
    modelProperties: {
      resource: {
        serializedName: "resource",
        type: {
          name: "Composite",
          className: "ResourceProfileDto"
        }
      },
      features: {
        serializedName: "features",
        type: {
          name: "Composite",
          className: "ResourceProfileFeatureDto"
        }
      }
    }
  }
};

export const ResourceSafeRequestDto: msRest.CompositeMapper = {
  serializedName: "ResourceSafeRequestDto",
  type: {
    name: "Composite",
    className: "ResourceSafeRequestDto",
    modelProperties: {
      contacts: {
        serializedName: "contacts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ContactSimpleDto"
            }
          }
        }
      }
    }
  }
};

export const ResourceSettingDto: msRest.CompositeMapper = {
  serializedName: "ResourceSettingDto",
  type: {
    name: "Composite",
    className: "ResourceSettingDto",
    modelProperties: {
      settingValueId: {
        serializedName: "settingValueId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      value: {
        nullable: true,
        serializedName: "value",
        type: {
          name: "Number"
        }
      },
      resource: {
        serializedName: "resource",
        type: {
          name: "Composite",
          className: "ResourceSimpleDto"
        }
      }
    }
  }
};

export const ResourceSkeletonDto: msRest.CompositeMapper = {
  serializedName: "ResourceSkeletonDto",
  type: {
    name: "Composite",
    className: "ResourceSkeletonDto",
    modelProperties: {
      resourceSkeletonId: {
        serializedName: "resourceSkeletonId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      stereotype: {
        required: true,
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleDto"
        }
      },
      parent: {
        serializedName: "parent",
        type: {
          name: "Composite",
          className: "ResourceSimpleDto"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ResourceStereotypeUsageDto: msRest.CompositeMapper = {
  serializedName: "ResourceStereotypeUsageDto",
  type: {
    name: "Composite",
    className: "ResourceStereotypeUsageDto",
    modelProperties: {
      stereotypeId: {
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      isDefault: {
        serializedName: "isDefault",
        type: {
          name: "Boolean"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      },
      entityType: {
        serializedName: "entityType",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
            "31",
            "32",
            "33",
            "34",
            "35",
            "36",
            "37",
            "38",
            "39",
            "40",
            "41",
            "42",
            "43",
            "44",
            "45"
          ]
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      },
      customPropertySets: {
        serializedName: "customPropertySets",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomSetReferenceDto"
            }
          }
        }
      }
    }
  }
};

export const ResourceTaskConsumptionTriggerDto: msRest.CompositeMapper = {
  serializedName: "ResourceTaskConsumptionTriggerDto",
  type: {
    name: "Composite",
    className: "ResourceTaskConsumptionTriggerDto",
    modelProperties: {
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "2",
            "3",
            "4"
          ]
        }
      }
    }
  }
};

export const ResourceTaskConsumptionScheduleDto: msRest.CompositeMapper = {
  serializedName: "ResourceTaskConsumptionScheduleDto",
  type: {
    name: "Composite",
    className: "ResourceTaskConsumptionScheduleDto",
    modelProperties: {
      resourceTaskConditionId: {
        serializedName: "resourceTaskConditionId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "5"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      consumption: {
        serializedName: "consumption",
        type: {
          name: "Composite",
          className: "ConsumptionCodeSimpleDto"
        }
      },
      trigger: {
        serializedName: "trigger",
        type: {
          name: "Composite",
          className: "ResourceTaskConsumptionTriggerDto"
        }
      }
    }
  }
};

export const ResourceTaskDtm: msRest.CompositeMapper = {
  serializedName: "ResourceTaskDtm",
  type: {
    name: "Composite",
    className: "ResourceTaskDtm",
    modelProperties: {
      resourceTaskId: {
        serializedName: "resourceTaskId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ResourceTaskSelectorDto: msRest.CompositeMapper = {
  serializedName: "ResourceTaskSelectorDto",
  type: {
    name: "Composite",
    className: "ResourceTaskSelectorDto",
    modelProperties: {
      resourceTaskSelectorId: {
        serializedName: "resourceTaskSelectorId",
        type: {
          name: "Number"
        }
      },
      view: {
        serializedName: "view",
        type: {
          name: "Composite",
          className: "DataTableDto"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ResourceTaskPatternDto: msRest.CompositeMapper = {
  serializedName: "ResourceTaskPatternDto",
  type: {
    name: "Composite",
    className: "ResourceTaskPatternDto",
    modelProperties: {
      resourceTaskPatternId: {
        serializedName: "resourceTaskPatternId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ResourceTaskScheduleDto: msRest.CompositeMapper = {
  serializedName: "ResourceTaskScheduleDto",
  type: {
    name: "Composite",
    className: "ResourceTaskScheduleDto",
    modelProperties: {
      resourceTaskConditionId: {
        serializedName: "resourceTaskConditionId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "5"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ResourceTaskDto: msRest.CompositeMapper = {
  serializedName: "ResourceTaskDto",
  type: {
    name: "Composite",
    className: "ResourceTaskDto",
    modelProperties: {
      resourceTaskId: {
        serializedName: "resourceTaskId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      endOffset: {
        serializedName: "endOffset",
        type: {
          name: "String"
        }
      },
      endDate: {
        serializedName: "endDate",
        type: {
          name: "String"
        }
      },
      startOffSet: {
        serializedName: "startOffSet",
        type: {
          name: "String"
        }
      },
      startDate: {
        serializedName: "startDate",
        type: {
          name: "String"
        }
      },
      isCancelingObsoleteMissions: {
        serializedName: "isCancelingObsoleteMissions",
        type: {
          name: "Boolean"
        }
      },
      isAutoInstructing: {
        serializedName: "isAutoInstructing",
        type: {
          name: "Boolean"
        }
      },
      isActivated: {
        serializedName: "isActivated",
        type: {
          name: "Boolean"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      missionDescription: {
        serializedName: "missionDescription",
        type: {
          name: "Composite",
          className: "TemplateSimpleDto"
        }
      },
      missionStereotype: {
        serializedName: "missionStereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleDto"
        }
      },
      priority: {
        serializedName: "priority",
        type: {
          name: "Composite",
          className: "PrioritySimpleDto"
        }
      },
      selector: {
        serializedName: "selector",
        type: {
          name: "Composite",
          className: "ResourceTaskSelectorDto"
        }
      },
      pattern: {
        serializedName: "pattern",
        type: {
          name: "Composite",
          className: "ResourceTaskPatternDto"
        }
      },
      schedule: {
        serializedName: "schedule",
        type: {
          name: "Composite",
          className: "ResourceTaskScheduleDto"
        }
      }
    }
  }
};

export const ResourceTaskErrorScheduleDto: msRest.CompositeMapper = {
  serializedName: "ResourceTaskErrorScheduleDto",
  type: {
    name: "Composite",
    className: "ResourceTaskErrorScheduleDto",
    modelProperties: {
      resourceTaskConditionId: {
        serializedName: "resourceTaskConditionId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "5"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      error: {
        serializedName: "error",
        type: {
          name: "Composite",
          className: "ErrorCodeSimpleDto"
        }
      }
    }
  }
};

export const ResourceTaskFixedScheduleDto: msRest.CompositeMapper = {
  serializedName: "ResourceTaskFixedScheduleDto",
  type: {
    name: "Composite",
    className: "ResourceTaskFixedScheduleDto",
    modelProperties: {
      resourceTaskConditionId: {
        serializedName: "resourceTaskConditionId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "5"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      nextOccurrence: {
        serializedName: "nextOccurrence",
        type: {
          name: "String"
        }
      },
      timeOfOccurrence: {
        serializedName: "timeOfOccurrence",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ResourceTaskListDto: msRest.CompositeMapper = {
  serializedName: "ResourceTaskListDto",
  type: {
    name: "Composite",
    className: "ResourceTaskListDto",
    modelProperties: {
      resourceTaskId: {
        serializedName: "resourceTaskId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      endOffset: {
        serializedName: "endOffset",
        type: {
          name: "String"
        }
      },
      endDate: {
        serializedName: "endDate",
        type: {
          name: "String"
        }
      },
      startOffSet: {
        serializedName: "startOffSet",
        type: {
          name: "String"
        }
      },
      startDate: {
        serializedName: "startDate",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      isActivated: {
        serializedName: "isActivated",
        type: {
          name: "Boolean"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      },
      isCancelingObsoleteMissions: {
        serializedName: "isCancelingObsoleteMissions",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const ResourceTaskOffsetPatternDto: msRest.CompositeMapper = {
  serializedName: "ResourceTaskOffsetPatternDto",
  type: {
    name: "Composite",
    className: "ResourceTaskOffsetPatternDto",
    modelProperties: {
      resourceTaskPatternId: {
        serializedName: "resourceTaskPatternId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      offset: {
        serializedName: "offset",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ResourceTaskRelativeScheduleDto: msRest.CompositeMapper = {
  serializedName: "ResourceTaskRelativeScheduleDto",
  type: {
    name: "Composite",
    className: "ResourceTaskRelativeScheduleDto",
    modelProperties: {
      resourceTaskConditionId: {
        serializedName: "resourceTaskConditionId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "5"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      timeOfOccurrence: {
        serializedName: "timeOfOccurrence",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ResourceTicketSkeletonSelectorDto: msRest.CompositeMapper = {
  serializedName: "ResourceTicketSkeletonSelectorDto",
  type: {
    name: "Composite",
    className: "ResourceTicketSkeletonSelectorDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4"
          ]
        }
      }
    }
  }
};

export const ResourceTreeListDto: msRest.CompositeMapper = {
  serializedName: "ResourceTreeListDto",
  type: {
    name: "Composite",
    className: "ResourceTreeListDto",
    modelProperties: {
      resourceId: {
        serializedName: "resourceId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      parentId: {
        nullable: true,
        serializedName: "parentId",
        type: {
          name: "Number"
        }
      },
      currentState: {
        serializedName: "currentState",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3"
          ]
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      isInProgressSince: {
        serializedName: "isInProgressSince",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      inventoryNumber: {
        serializedName: "inventoryNumber",
        type: {
          name: "String"
        }
      },
      path: {
        serializedName: "path",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ResourcePathPartSimpleListDto"
            }
          }
        }
      },
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "LocationSimpleListDto"
        }
      },
      hasChildren: {
        serializedName: "hasChildren",
        type: {
          name: "Boolean"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ResourcesByLocationDefinitionDto: msRest.CompositeMapper = {
  serializedName: "ResourcesByLocationDefinitionDto",
  type: {
    name: "Composite",
    className: "ResourcesByLocationDefinitionDto",
    modelProperties: {
      resourcesByLocationDefinitionId: {
        serializedName: "resourcesByLocationDefinitionId",
        type: {
          name: "Number"
        }
      },
      stereotypeId: {
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      resources: {
        serializedName: "resources",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ResourceSkeletonDto"
            }
          }
        }
      }
    }
  }
};

export const ResourcesByLocationDefinitionListDto: msRest.CompositeMapper = {
  serializedName: "ResourcesByLocationDefinitionListDto",
  type: {
    name: "Composite",
    className: "ResourcesByLocationDefinitionListDto",
    modelProperties: {
      resourcesByLocationDefinitionId: {
        serializedName: "resourcesByLocationDefinitionId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleListDto"
        }
      }
    }
  }
};

export const ResourcesByLocationDefinitionSimpleDto: msRest.CompositeMapper = {
  serializedName: "ResourcesByLocationDefinitionSimpleDto",
  type: {
    name: "Composite",
    className: "ResourcesByLocationDefinitionSimpleDto",
    modelProperties: {
      resourcesByLocationDefinitionId: {
        serializedName: "resourcesByLocationDefinitionId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const RestartConnectedMissionQueueDto: msRest.CompositeMapper = {
  serializedName: "RestartConnectedMissionQueueDto",
  type: {
    name: "Composite",
    className: "RestartConnectedMissionQueueDto",
    modelProperties: {
      missionId: {
        serializedName: "missionId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const RestartRelativeMissionByTaskQueueDto: msRest.CompositeMapper = {
  serializedName: "RestartRelativeMissionByTaskQueueDto",
  type: {
    name: "Composite",
    className: "RestartRelativeMissionByTaskQueueDto",
    modelProperties: {
      missionId: {
        serializedName: "missionId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const RestrictedByTypeResourceTicketSkeletonSelectorDto: msRest.CompositeMapper = {
  serializedName: "RestrictedByTypeResourceTicketSkeletonSelectorDto",
  type: {
    name: "Composite",
    className: "RestrictedByTypeResourceTicketSkeletonSelectorDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4"
          ]
        }
      },
      stereotypes: {
        serializedName: "stereotypes",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "StereotypeSimpleDto"
            }
          }
        }
      }
    }
  }
};

export const SanctionImpactCreateV1Dto: msRest.CompositeMapper = {
  serializedName: "SanctionImpactCreateV1Dto",
  type: {
    name: "Composite",
    className: "SanctionImpactCreateV1Dto",
    modelProperties: {
      impactId: {
        serializedName: "impactId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      condition: {
        serializedName: "condition",
        type: {
          name: "Composite",
          className: "ConditionV1Dto"
        }
      },
      taskId: {
        nullable: true,
        serializedName: "taskId",
        type: {
          name: "Number"
        }
      },
      sanctionId: {
        nullable: true,
        serializedName: "sanctionId",
        type: {
          name: "Number"
        }
      },
      sanctionOffset: {
        serializedName: "sanctionOffset",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const TaskAsSanctionDto: msRest.CompositeMapper = {
  serializedName: "TaskAsSanctionDto",
  type: {
    name: "Composite",
    className: "TaskAsSanctionDto",
    modelProperties: {
      taskId: {
        serializedName: "taskId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const SanctionImpactDto: msRest.CompositeMapper = {
  serializedName: "SanctionImpactDto",
  type: {
    name: "Composite",
    className: "SanctionImpactDto",
    modelProperties: {
      impactId: {
        serializedName: "impactId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      condition: {
        serializedName: "condition",
        type: {
          name: "Composite",
          className: "ConditionDto"
        }
      },
      sanction: {
        serializedName: "sanction",
        type: {
          name: "Composite",
          className: "TaskAsSanctionDto"
        }
      },
      sanctionOffset: {
        serializedName: "sanctionOffset",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const SanctionImpactV1Dto: msRest.CompositeMapper = {
  serializedName: "SanctionImpactV1Dto",
  type: {
    name: "Composite",
    className: "SanctionImpactV1Dto",
    modelProperties: {
      impactId: {
        serializedName: "impactId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      condition: {
        serializedName: "condition",
        type: {
          name: "Composite",
          className: "ConditionV1Dto"
        }
      },
      sanction: {
        serializedName: "sanction",
        type: {
          name: "Composite",
          className: "TaskSimpleV1Dto"
        }
      },
      sanctionId: {
        nullable: true,
        serializedName: "sanctionId",
        type: {
          name: "Number"
        }
      },
      sanctionOffset: {
        serializedName: "sanctionOffset",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const SearchListDto: msRest.CompositeMapper = {
  serializedName: "SearchListDto",
  type: {
    name: "Composite",
    className: "SearchListDto",
    modelProperties: {
      searchListId: {
        serializedName: "searchListId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      items: {
        serializedName: "items",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const SearchListFormDefaultDto: msRest.CompositeMapper = {
  serializedName: "SearchListFormDefaultDto",
  type: {
    name: "Composite",
    className: "SearchListFormDefaultDto",
    modelProperties: {
      fieldValueId: {
        serializedName: "fieldValueId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12"
          ]
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const SearchListSimpleDto: msRest.CompositeMapper = {
  serializedName: "SearchListSimpleDto",
  type: {
    name: "Composite",
    className: "SearchListSimpleDto",
    modelProperties: {
      searchListId: {
        serializedName: "searchListId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const SearchListFormFieldDto: msRest.CompositeMapper = {
  serializedName: "SearchListFormFieldDto",
  type: {
    name: "Composite",
    className: "SearchListFormFieldDto",
    modelProperties: {
      formFieldId: {
        serializedName: "formFieldId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      technicalName: {
        readOnly: true,
        serializedName: "technicalName",
        type: {
          name: "String"
        }
      },
      width: {
        serializedName: "width",
        type: {
          name: "Number"
        }
      },
      isRequired: {
        serializedName: "isRequired",
        type: {
          name: "Boolean"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12"
          ]
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      defaultValues: {
        serializedName: "defaultValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FormDefaultDto"
            }
          }
        }
      },
      localFieldId: {
        serializedName: "localFieldId",
        type: {
          name: "String"
        }
      },
      searchListId: {
        nullable: true,
        serializedName: "searchListId",
        type: {
          name: "Number"
        }
      },
      searchList: {
        serializedName: "searchList",
        type: {
          name: "Composite",
          className: "SearchListSimpleDto"
        }
      }
    }
  }
};

export const SearchListFormValueDto: msRest.CompositeMapper = {
  serializedName: "SearchListFormValueDto",
  type: {
    name: "Composite",
    className: "SearchListFormValueDto",
    modelProperties: {
      fieldValueId: {
        serializedName: "fieldValueId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12"
          ]
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const SearchListListDto: msRest.CompositeMapper = {
  serializedName: "SearchListListDto",
  type: {
    name: "Composite",
    className: "SearchListListDto",
    modelProperties: {
      searchListId: {
        serializedName: "searchListId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const SearchWeight: msRest.CompositeMapper = {
  serializedName: "SearchWeight",
  type: {
    name: "Composite",
    className: "SearchWeight",
    modelProperties: {
      weight: {
        serializedName: "weight",
        type: {
          name: "Number"
        }
      },
      property: {
        serializedName: "property",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const SecuredResourceQueueDto: msRest.CompositeMapper = {
  serializedName: "SecuredResourceQueueDto",
  type: {
    name: "Composite",
    className: "SecuredResourceQueueDto",
    modelProperties: {
      resourceId: {
        serializedName: "resourceId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const SelectResourcesByTaskQueueDto: msRest.CompositeMapper = {
  serializedName: "SelectResourcesByTaskQueueDto",
  type: {
    name: "Composite",
    className: "SelectResourcesByTaskQueueDto",
    modelProperties: {
      taskId: {
        serializedName: "taskId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const SendChatMessageNoteQueueDto: msRest.CompositeMapper = {
  serializedName: "SendChatMessageNoteQueueDto",
  type: {
    name: "Composite",
    className: "SendChatMessageNoteQueueDto",
    modelProperties: {
      noteId: {
        serializedName: "noteId",
        type: {
          name: "Number"
        }
      },
      culture: {
        serializedName: "culture",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const SendChatMessageToSolutionQueueDto: msRest.CompositeMapper = {
  serializedName: "SendChatMessageToSolutionQueueDto",
  type: {
    name: "Composite",
    className: "SendChatMessageToSolutionQueueDto",
    modelProperties: {
      chatMessageId: {
        serializedName: "chatMessageId",
        type: {
          name: "Number"
        }
      },
      isInitialMessage: {
        serializedName: "isInitialMessage",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const SendGridMailDto: msRest.CompositeMapper = {
  serializedName: "SendGridMailDto",
  type: {
    name: "Composite",
    className: "SendGridMailDto",
    modelProperties: {
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      },
      subject: {
        serializedName: "subject",
        type: {
          name: "String"
        }
      },
      envelope: {
        serializedName: "envelope",
        type: {
          name: "String"
        }
      },
      spf: {
        serializedName: "spf",
        type: {
          name: "String"
        }
      },
      senderIp: {
        serializedName: "sender_Ip",
        type: {
          name: "String"
        }
      },
      spamScore: {
        serializedName: "spam_Score",
        type: {
          name: "String"
        }
      },
      to: {
        serializedName: "to",
        type: {
          name: "String"
        }
      },
      from: {
        serializedName: "from",
        type: {
          name: "String"
        }
      },
      spamReport: {
        serializedName: "spam_Report",
        type: {
          name: "String"
        }
      },
      dkim: {
        serializedName: "dkim",
        type: {
          name: "String"
        }
      },
      charsets: {
        serializedName: "charsets",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const SendGridStatusDto: msRest.CompositeMapper = {
  serializedName: "SendGridStatusDto",
  type: {
    name: "Composite",
    className: "SendGridStatusDto",
    modelProperties: {
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      },
      event: {
        serializedName: "event",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "String"
        }
      },
      reason: {
        serializedName: "reason",
        type: {
          name: "String"
        }
      },
      smtpId: {
        serializedName: "smtpId",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const SendMailNoteQueueDto: msRest.CompositeMapper = {
  serializedName: "SendMailNoteQueueDto",
  type: {
    name: "Composite",
    className: "SendMailNoteQueueDto",
    modelProperties: {
      noteId: {
        serializedName: "noteId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const SendMissionActivationEmailQueueDto: msRest.CompositeMapper = {
  serializedName: "SendMissionActivationEmailQueueDto",
  type: {
    name: "Composite",
    className: "SendMissionActivationEmailQueueDto",
    modelProperties: {
      contactId: {
        serializedName: "contactId",
        type: {
          name: "Number"
        }
      },
      missionId: {
        serializedName: "missionId",
        type: {
          name: "Number"
        }
      },
      executedByUserId: {
        nullable: true,
        serializedName: "executedByUserId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const SendMissionMailDto: msRest.CompositeMapper = {
  serializedName: "SendMissionMailDto",
  type: {
    name: "Composite",
    className: "SendMissionMailDto",
    modelProperties: {
      lastEmailSend: {
        serializedName: "lastEmailSend",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const SendStateToChatQueueDto: msRest.CompositeMapper = {
  serializedName: "SendStateToChatQueueDto",
  type: {
    name: "Composite",
    className: "SendStateToChatQueueDto",
    modelProperties: {
      ticketId: {
        serializedName: "ticketId",
        type: {
          name: "Number"
        }
      },
      stateId: {
        serializedName: "stateId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const SettingValueDto: msRest.CompositeMapper = {
  serializedName: "SettingValueDto",
  type: {
    name: "Composite",
    className: "SettingValueDto",
    modelProperties: {
      settingValueId: {
        serializedName: "settingValueId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ShortContactDto: msRest.CompositeMapper = {
  serializedName: "ShortContactDto",
  type: {
    name: "Composite",
    className: "ShortContactDto",
    modelProperties: {
      contactId: {
        serializedName: "contactId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      firstName: {
        serializedName: "firstName",
        type: {
          name: "String"
        }
      },
      lastName: {
        serializedName: "lastName",
        type: {
          name: "String"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      emailAddress: {
        serializedName: "emailAddress",
        type: {
          name: "String"
        }
      },
      password: {
        serializedName: "password",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const SignatureFormDefaultDto: msRest.CompositeMapper = {
  serializedName: "SignatureFormDefaultDto",
  type: {
    name: "Composite",
    className: "SignatureFormDefaultDto",
    modelProperties: {
      fieldValueId: {
        serializedName: "fieldValueId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12"
          ]
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "Number"
        }
      },
      file: {
        serializedName: "file",
        type: {
          name: "Composite",
          className: "FileDto"
        }
      }
    }
  }
};

export const SignatureFormFieldDto: msRest.CompositeMapper = {
  serializedName: "SignatureFormFieldDto",
  type: {
    name: "Composite",
    className: "SignatureFormFieldDto",
    modelProperties: {
      formFieldId: {
        serializedName: "formFieldId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      technicalName: {
        readOnly: true,
        serializedName: "technicalName",
        type: {
          name: "String"
        }
      },
      width: {
        serializedName: "width",
        type: {
          name: "Number"
        }
      },
      isRequired: {
        serializedName: "isRequired",
        type: {
          name: "Boolean"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12"
          ]
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      defaultValues: {
        serializedName: "defaultValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FormDefaultDto"
            }
          }
        }
      },
      localFieldId: {
        serializedName: "localFieldId",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const SignatureFormValueDto: msRest.CompositeMapper = {
  serializedName: "SignatureFormValueDto",
  type: {
    name: "Composite",
    className: "SignatureFormValueDto",
    modelProperties: {
      fieldValueId: {
        serializedName: "fieldValueId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12"
          ]
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "Number"
        }
      },
      file: {
        serializedName: "file",
        type: {
          name: "Composite",
          className: "FileDto"
        }
      }
    }
  }
};

export const SimpleChatDto: msRest.CompositeMapper = {
  serializedName: "SimpleChatDto",
  type: {
    name: "Composite",
    className: "SimpleChatDto",
    modelProperties: {
      chatId: {
        serializedName: "chatId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const SimpleIdDto: msRest.CompositeMapper = {
  serializedName: "SimpleIdDto",
  type: {
    name: "Composite",
    className: "SimpleIdDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const SingleDateTimePatternDto: msRest.CompositeMapper = {
  serializedName: "SingleDateTimePatternDto",
  type: {
    name: "Composite",
    className: "SingleDateTimePatternDto",
    modelProperties: {
      dateTimePatternId: {
        serializedName: "dateTimePatternId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5"
          ]
        }
      },
      useNextOpeningDay: {
        serializedName: "useNextOpeningDay",
        type: {
          name: "Boolean"
        }
      },
      lastModified: {
        serializedName: "lastModified",
        type: {
          name: "String"
        }
      },
      plannedTimes: {
        serializedName: "plannedTimes",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PlannedTimeDto"
            }
          }
        }
      },
      date: {
        serializedName: "date",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const SingleDateTimePatternV1Dto: msRest.CompositeMapper = {
  serializedName: "SingleDateTimePatternV1Dto",
  type: {
    name: "Composite",
    className: "SingleDateTimePatternV1Dto",
    modelProperties: {
      dateTimePatternId: {
        serializedName: "dateTimePatternId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5"
          ]
        }
      },
      useNextOpeningDay: {
        serializedName: "useNextOpeningDay",
        type: {
          name: "Boolean"
        }
      },
      lastModified: {
        serializedName: "lastModified",
        type: {
          name: "String"
        }
      },
      plannedTimes: {
        serializedName: "plannedTimes",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PlannedTimeV1Dto"
            }
          }
        }
      },
      date: {
        serializedName: "date",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const SingleFormRowCreateDto: msRest.CompositeMapper = {
  serializedName: "SingleFormRowCreateDto",
  type: {
    name: "Composite",
    className: "SingleFormRowCreateDto",
    modelProperties: {
      formRowId: {
        serializedName: "formRowId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      },
      fields: {
        serializedName: "fields",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FormFieldCreateDto"
            }
          }
        }
      }
    }
  }
};

export const SkeletonDto: msRest.CompositeMapper = {
  serializedName: "SkeletonDto",
  type: {
    name: "Composite",
    className: "SkeletonDto",
    modelProperties: {
      skeletonId: {
        serializedName: "skeletonId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
            "31",
            "32",
            "33",
            "34",
            "35",
            "36",
            "37",
            "38",
            "39",
            "40",
            "41",
            "42",
            "43",
            "44",
            "45"
          ]
        }
      }
    }
  }
};

export const SkeletonRootDto: msRest.CompositeMapper = {
  serializedName: "SkeletonRootDto",
  type: {
    name: "Composite",
    className: "SkeletonRootDto",
    modelProperties: {
      skeletonRootId: {
        serializedName: "skeletonRootId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      stereotypeId: {
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      entity: {
        serializedName: "entity",
        type: {
          name: "Composite",
          className: "SkeletonDto"
        }
      }
    }
  }
};

export const SkeletonRootSimpleDto: msRest.CompositeMapper = {
  serializedName: "SkeletonRootSimpleDto",
  type: {
    name: "Composite",
    className: "SkeletonRootSimpleDto",
    modelProperties: {
      skeletonRootId: {
        serializedName: "skeletonRootId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const SkeletonRootSimpleListDto: msRest.CompositeMapper = {
  serializedName: "SkeletonRootSimpleListDto",
  type: {
    name: "Composite",
    className: "SkeletonRootSimpleListDto",
    modelProperties: {
      skeletonRootId: {
        serializedName: "skeletonRootId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const SolutionAttachmentDto: msRest.CompositeMapper = {
  serializedName: "SolutionAttachmentDto",
  type: {
    name: "Composite",
    className: "SolutionAttachmentDto",
    modelProperties: {
      solutionItemId: {
        nullable: true,
        serializedName: "solutionItemId",
        type: {
          name: "Number"
        }
      },
      member: {
        serializedName: "member",
        type: {
          name: "Composite",
          className: "SolutionMemberDto"
        }
      },
      attachment: {
        serializedName: "attachment",
        type: {
          name: "Composite",
          className: "AttachmentForTechDto"
        }
      }
    }
  }
};

export const SolutionAttachmentsDto: msRest.CompositeMapper = {
  serializedName: "SolutionAttachmentsDto",
  type: {
    name: "Composite",
    className: "SolutionAttachmentsDto",
    modelProperties: {
      attachments: {
        serializedName: "attachments",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "SolutionAttachmentDto"
            }
          }
        }
      }
    }
  }
};

export const SolutionDetailsDto: msRest.CompositeMapper = {
  serializedName: "SolutionDetailsDto",
  type: {
    name: "Composite",
    className: "SolutionDetailsDto",
    modelProperties: {
      solutionId: {
        serializedName: "solutionId",
        type: {
          name: "Number"
        }
      },
      members: {
        serializedName: "members",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "SolutionMemberDto"
            }
          }
        }
      },
      attachments: {
        serializedName: "attachments",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AttachmentForTechDto"
            }
          }
        }
      },
      threadId: {
        serializedName: "threadId",
        type: {
          name: "String"
        }
      },
      threadMailAddress: {
        serializedName: "threadMailAddress",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const SolutionDto: msRest.CompositeMapper = {
  serializedName: "SolutionDto",
  type: {
    name: "Composite",
    className: "SolutionDto",
    modelProperties: {
      solutionId: {
        serializedName: "solutionId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      members: {
        serializedName: "members",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "SolutionMemberDto"
            }
          }
        }
      },
      attachments: {
        serializedName: "attachments",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "SolutionAttachmentDto"
            }
          }
        }
      }
    }
  }
};

export const UserSimpleDto: msRest.CompositeMapper = {
  serializedName: "UserSimpleDto",
  type: {
    name: "Composite",
    className: "UserSimpleDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const SolutionItemDto: msRest.CompositeMapper = {
  serializedName: "SolutionItemDto",
  type: {
    name: "Composite",
    className: "SolutionItemDto",
    modelProperties: {
      solutionItemId: {
        serializedName: "solutionItemId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      solutionId: {
        serializedName: "solutionId",
        type: {
          name: "Number"
        }
      },
      lastModified: {
        serializedName: "lastModified",
        type: {
          name: "String"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "String"
        }
      },
      ticketId: {
        nullable: true,
        serializedName: "ticketId",
        type: {
          name: "Number"
        }
      },
      missionId: {
        nullable: true,
        serializedName: "missionId",
        type: {
          name: "Number"
        }
      },
      isSolution: {
        serializedName: "isSolution",
        type: {
          name: "Boolean"
        }
      },
      createdByUser: {
        serializedName: "createdByUser",
        type: {
          name: "Composite",
          className: "UserSimpleDto"
        }
      },
      note: {
        serializedName: "note",
        type: {
          name: "Composite",
          className: "NoteDto"
        }
      }
    }
  }
};

export const TicketSimpleListDto: msRest.CompositeMapper = {
  serializedName: "TicketSimpleListDto",
  type: {
    name: "Composite",
    className: "TicketSimpleListDto",
    modelProperties: {
      ticketId: {
        serializedName: "ticketId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const SolutionItemListDto: msRest.CompositeMapper = {
  serializedName: "SolutionItemListDto",
  type: {
    name: "Composite",
    className: "SolutionItemListDto",
    modelProperties: {
      solutionItemId: {
        serializedName: "solutionItemId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      solutionId: {
        serializedName: "solutionId",
        type: {
          name: "Number"
        }
      },
      lastModified: {
        serializedName: "lastModified",
        type: {
          name: "String"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "String"
        }
      },
      isSolution: {
        serializedName: "isSolution",
        type: {
          name: "Boolean"
        }
      },
      ticketId: {
        nullable: true,
        serializedName: "ticketId",
        type: {
          name: "Number"
        }
      },
      ticket: {
        serializedName: "ticket",
        type: {
          name: "Composite",
          className: "TicketSimpleListDto"
        }
      },
      missionId: {
        nullable: true,
        serializedName: "missionId",
        type: {
          name: "Number"
        }
      },
      mission: {
        serializedName: "mission",
        type: {
          name: "Composite",
          className: "MissionSimpleListDto"
        }
      },
      createdByName: {
        serializedName: "createdByName",
        type: {
          name: "String"
        }
      },
      createdByUser: {
        serializedName: "createdByUser",
        type: {
          name: "Composite",
          className: "UserSimpleListDto"
        }
      },
      note: {
        serializedName: "note",
        type: {
          name: "Composite",
          className: "NoteCompleteDto"
        }
      }
    }
  }
};

export const SolutionMailInQueueDto: msRest.CompositeMapper = {
  serializedName: "SolutionMailInQueueDto",
  type: {
    name: "Composite",
    className: "SolutionMailInQueueDto",
    modelProperties: {
      threadId: {
        serializedName: "threadId",
        type: {
          name: "String"
        }
      },
      isSuccess: {
        serializedName: "isSuccess",
        type: {
          name: "Boolean"
        }
      },
      triggeredNodeId: {
        serializedName: "triggeredNodeId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      mailId: {
        serializedName: "mailId",
        type: {
          name: "Number"
        }
      },
      mail: {
        serializedName: "mail",
        type: {
          name: "Composite",
          className: "IncomingMailDto"
        }
      }
    }
  }
};

export const StartingMissionEditorDto: msRest.CompositeMapper = {
  serializedName: "StartingMissionEditorDto",
  type: {
    name: "Composite",
    className: "StartingMissionEditorDto",
    modelProperties: {
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2"
          ]
        }
      }
    }
  }
};

export const StartInspectionDto: msRest.CompositeMapper = {
  serializedName: "StartInspectionDto",
  type: {
    name: "Composite",
    className: "StartInspectionDto",
    modelProperties: {
      missionId: {
        serializedName: "missionId",
        type: {
          name: "Number"
        }
      },
      plannedStart: {
        serializedName: "plannedStart",
        type: {
          name: "String"
        }
      },
      plannedEnd: {
        serializedName: "plannedEnd",
        type: {
          name: "String"
        }
      },
      editor: {
        serializedName: "editor",
        type: {
          name: "Composite",
          className: "StartingMissionEditorDto"
        }
      },
      resource: {
        serializedName: "resource",
        type: {
          name: "Composite",
          className: "ResourceInMissionDto"
        }
      },
      links: {
        serializedName: "links",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LinkDto"
            }
          }
        }
      },
      attachments: {
        serializedName: "attachments",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AttachmentForTechDto"
            }
          }
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueDto"
            }
          }
        }
      },
      controlPoint: {
        serializedName: "controlPoint",
        type: {
          name: "Composite",
          className: "ControlPointInMissionDto"
        }
      }
    }
  }
};

export const StartSearchingEditorDto: msRest.CompositeMapper = {
  serializedName: "StartSearchingEditorDto",
  type: {
    name: "Composite",
    className: "StartSearchingEditorDto",
    modelProperties: {
      functionId: {
        serializedName: "functionId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const StartSearchingEditorQueueDto: msRest.CompositeMapper = {
  serializedName: "StartSearchingEditorQueueDto",
  type: {
    name: "Composite",
    className: "StartSearchingEditorQueueDto",
    modelProperties: {
      missionId: {
        serializedName: "missionId",
        type: {
          name: "Number"
        }
      },
      functionId: {
        serializedName: "functionId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const StartingMissionByManualDto: msRest.CompositeMapper = {
  serializedName: "StartingMissionByManualDto",
  type: {
    name: "Composite",
    className: "StartingMissionByManualDto",
    modelProperties: {
      missionId: {
        serializedName: "missionId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      actualStart: {
        serializedName: "actualStart",
        type: {
          name: "String"
        }
      },
      actualEnd: {
        serializedName: "actualEnd",
        type: {
          name: "String"
        }
      },
      plannedStart: {
        serializedName: "plannedStart",
        type: {
          name: "String"
        }
      },
      plannedEnd: {
        serializedName: "plannedEnd",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4"
          ]
        }
      },
      lastModified: {
        serializedName: "lastModified",
        type: {
          name: "String"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "String"
        }
      },
      creator: {
        serializedName: "creator",
        type: {
          name: "Composite",
          className: "CreatedByDto"
        }
      },
      invoiceAddress: {
        serializedName: "invoiceAddress",
        type: {
          name: "Composite",
          className: "AddressSimpleDto"
        }
      },
      shippingAddress: {
        serializedName: "shippingAddress",
        type: {
          name: "Composite",
          className: "AddressSimpleDto"
        }
      },
      solutionId: {
        serializedName: "solutionId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "LocationInMissionDto"
        }
      },
      resource: {
        serializedName: "resource",
        type: {
          name: "Composite",
          className: "ResourceInMissionDto"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueDto"
            }
          }
        }
      },
      links: {
        serializedName: "links",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LinkDto"
            }
          }
        }
      },
      attachments: {
        serializedName: "attachments",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AttachmentForTechDto"
            }
          }
        }
      },
      offsetByTemplate: {
        serializedName: "offsetByTemplate",
        type: {
          name: "String"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1"
          ]
        }
      },
      editor: {
        serializedName: "editor",
        type: {
          name: "Composite",
          className: "StartingMissionEditorDto"
        }
      }
    }
  }
};

export const StartingMissionByTicketDto: msRest.CompositeMapper = {
  serializedName: "StartingMissionByTicketDto",
  type: {
    name: "Composite",
    className: "StartingMissionByTicketDto",
    modelProperties: {
      missionId: {
        serializedName: "missionId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      actualStart: {
        serializedName: "actualStart",
        type: {
          name: "String"
        }
      },
      actualEnd: {
        serializedName: "actualEnd",
        type: {
          name: "String"
        }
      },
      plannedStart: {
        serializedName: "plannedStart",
        type: {
          name: "String"
        }
      },
      plannedEnd: {
        serializedName: "plannedEnd",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4"
          ]
        }
      },
      lastModified: {
        serializedName: "lastModified",
        type: {
          name: "String"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "String"
        }
      },
      creator: {
        serializedName: "creator",
        type: {
          name: "Composite",
          className: "CreatedByDto"
        }
      },
      invoiceAddress: {
        serializedName: "invoiceAddress",
        type: {
          name: "Composite",
          className: "AddressSimpleDto"
        }
      },
      shippingAddress: {
        serializedName: "shippingAddress",
        type: {
          name: "Composite",
          className: "AddressSimpleDto"
        }
      },
      solutionId: {
        serializedName: "solutionId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "LocationInMissionDto"
        }
      },
      resource: {
        serializedName: "resource",
        type: {
          name: "Composite",
          className: "ResourceInMissionDto"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueDto"
            }
          }
        }
      },
      links: {
        serializedName: "links",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LinkDto"
            }
          }
        }
      },
      attachments: {
        serializedName: "attachments",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AttachmentForTechDto"
            }
          }
        }
      },
      offsetByTemplate: {
        serializedName: "offsetByTemplate",
        type: {
          name: "String"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1"
          ]
        }
      },
      editor: {
        serializedName: "editor",
        type: {
          name: "Composite",
          className: "StartingMissionEditorDto"
        }
      },
      ticket: {
        serializedName: "ticket",
        type: {
          name: "Composite",
          className: "TicketInMissionDto"
        }
      }
    }
  }
};

export const StartingMissionContactEditorDto: msRest.CompositeMapper = {
  serializedName: "StartingMissionContactEditorDto",
  type: {
    name: "Composite",
    className: "StartingMissionContactEditorDto",
    modelProperties: {
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2"
          ]
        }
      },
      contact: {
        serializedName: "contact",
        type: {
          name: "Composite",
          className: "ContactSimpleDto"
        }
      }
    }
  }
};

export const StartingMissionDto: msRest.CompositeMapper = {
  serializedName: "StartingMissionDto",
  type: {
    name: "Composite",
    className: "StartingMissionDto",
    modelProperties: {
      missionId: {
        serializedName: "missionId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      actualStart: {
        serializedName: "actualStart",
        type: {
          name: "String"
        }
      },
      actualEnd: {
        serializedName: "actualEnd",
        type: {
          name: "String"
        }
      },
      plannedStart: {
        serializedName: "plannedStart",
        type: {
          name: "String"
        }
      },
      plannedEnd: {
        serializedName: "plannedEnd",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4"
          ]
        }
      },
      lastModified: {
        serializedName: "lastModified",
        type: {
          name: "String"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "String"
        }
      },
      creator: {
        serializedName: "creator",
        type: {
          name: "Composite",
          className: "CreatedByDto"
        }
      },
      invoiceAddress: {
        serializedName: "invoiceAddress",
        type: {
          name: "Composite",
          className: "AddressSimpleDto"
        }
      },
      shippingAddress: {
        serializedName: "shippingAddress",
        type: {
          name: "Composite",
          className: "AddressSimpleDto"
        }
      },
      solutionId: {
        serializedName: "solutionId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "LocationInMissionDto"
        }
      },
      resource: {
        serializedName: "resource",
        type: {
          name: "Composite",
          className: "ResourceInMissionDto"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueDto"
            }
          }
        }
      },
      links: {
        serializedName: "links",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LinkDto"
            }
          }
        }
      },
      attachments: {
        serializedName: "attachments",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AttachmentForTechDto"
            }
          }
        }
      },
      offsetByTemplate: {
        serializedName: "offsetByTemplate",
        type: {
          name: "String"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1"
          ]
        }
      },
      editor: {
        serializedName: "editor",
        type: {
          name: "Composite",
          className: "StartingMissionEditorDto"
        }
      }
    }
  }
};

export const StartingMissionFunctionEditorDto: msRest.CompositeMapper = {
  serializedName: "StartingMissionFunctionEditorDto",
  type: {
    name: "Composite",
    className: "StartingMissionFunctionEditorDto",
    modelProperties: {
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2"
          ]
        }
      },
      functionProperty: {
        serializedName: "function",
        type: {
          name: "Composite",
          className: "FunctionSimpleDto"
        }
      }
    }
  }
};

export const StateChangeRequestDto: msRest.CompositeMapper = {
  serializedName: "StateChangeRequestDto",
  type: {
    name: "Composite",
    className: "StateChangeRequestDto",
    modelProperties: {
      ticketId: {
        serializedName: "ticketId",
        type: {
          name: "Number"
        }
      },
      stateId: {
        serializedName: "stateId",
        type: {
          name: "Number"
        }
      },
      reason: {
        serializedName: "reason",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const StateDto: msRest.CompositeMapper = {
  serializedName: "StateDto",
  type: {
    name: "Composite",
    className: "StateDto",
    modelProperties: {
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      stateId: {
        serializedName: "stateId",
        type: {
          name: "Number"
        }
      },
      color: {
        serializedName: "color",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const StateExportDto: msRest.CompositeMapper = {
  serializedName: "StateExportDto",
  type: {
    name: "Composite",
    className: "StateExportDto",
    modelProperties: {
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      color: {
        serializedName: "color",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const StateTransitionDto: msRest.CompositeMapper = {
  serializedName: "StateTransitionDto",
  type: {
    name: "Composite",
    className: "StateTransitionDto",
    modelProperties: {
      stateTransitionId: {
        serializedName: "stateTransitionId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      inStateId: {
        nullable: true,
        serializedName: "inStateId",
        type: {
          name: "Number"
        }
      },
      inState: {
        serializedName: "inState",
        type: {
          name: "Composite",
          className: "StateDto"
        }
      },
      outStateId: {
        nullable: true,
        serializedName: "outStateId",
        type: {
          name: "Number"
        }
      },
      outState: {
        serializedName: "outState",
        type: {
          name: "Composite",
          className: "StateDto"
        }
      }
    }
  }
};

export const StateMachineDto: msRest.CompositeMapper = {
  serializedName: "StateMachineDto",
  type: {
    name: "Composite",
    className: "StateMachineDto",
    modelProperties: {
      stateMachineId: {
        serializedName: "stateMachineId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      stateAfterReceivedChat: {
        serializedName: "stateAfterReceivedChat",
        type: {
          name: "Composite",
          className: "StateSimpleDto"
        }
      },
      stateAfterRejectedMission: {
        serializedName: "stateAfterRejectedMission",
        type: {
          name: "Composite",
          className: "StateSimpleDto"
        }
      },
      stateAfterCanceledMission: {
        serializedName: "stateAfterCanceledMission",
        type: {
          name: "Composite",
          className: "StateSimpleDto"
        }
      },
      stateAfterLastMissionDone: {
        serializedName: "stateAfterLastMissionDone",
        type: {
          name: "Composite",
          className: "StateSimpleDto"
        }
      },
      stateAfterClosedMission: {
        serializedName: "stateAfterClosedMission",
        type: {
          name: "Composite",
          className: "StateSimpleDto"
        }
      },
      stateAfterReceivedEmail: {
        serializedName: "stateAfterReceivedEmail",
        type: {
          name: "Composite",
          className: "StateSimpleDto"
        }
      },
      transitions: {
        serializedName: "transitions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "StateTransitionDto"
            }
          }
        }
      }
    }
  }
};

export const StateSimpleExportDto: msRest.CompositeMapper = {
  serializedName: "StateSimpleExportDto",
  type: {
    name: "Composite",
    className: "StateSimpleExportDto",
    modelProperties: {
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const StateTransitionExportDto: msRest.CompositeMapper = {
  serializedName: "StateTransitionExportDto",
  type: {
    name: "Composite",
    className: "StateTransitionExportDto",
    modelProperties: {
      inState: {
        serializedName: "inState",
        type: {
          name: "Composite",
          className: "StateExportDto"
        }
      },
      outState: {
        serializedName: "outState",
        type: {
          name: "Composite",
          className: "StateExportDto"
        }
      }
    }
  }
};

export const StateMachineExportDto: msRest.CompositeMapper = {
  serializedName: "StateMachineExportDto",
  type: {
    name: "Composite",
    className: "StateMachineExportDto",
    modelProperties: {
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      stateAfterReceivedChat: {
        serializedName: "stateAfterReceivedChat",
        type: {
          name: "Composite",
          className: "StateSimpleExportDto"
        }
      },
      stateAfterRejectedMission: {
        serializedName: "stateAfterRejectedMission",
        type: {
          name: "Composite",
          className: "StateSimpleExportDto"
        }
      },
      stateAfterCanceledMission: {
        serializedName: "stateAfterCanceledMission",
        type: {
          name: "Composite",
          className: "StateSimpleExportDto"
        }
      },
      stateAfterLastMissionDone: {
        serializedName: "stateAfterLastMissionDone",
        type: {
          name: "Composite",
          className: "StateSimpleExportDto"
        }
      },
      stateAfterClosedMission: {
        serializedName: "stateAfterClosedMission",
        type: {
          name: "Composite",
          className: "StateSimpleExportDto"
        }
      },
      stateAfterReceivedEmail: {
        serializedName: "stateAfterReceivedEmail",
        type: {
          name: "Composite",
          className: "StateSimpleExportDto"
        }
      },
      transitions: {
        serializedName: "transitions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "StateTransitionExportDto"
            }
          }
        }
      }
    }
  }
};

export const StateMachineListDto: msRest.CompositeMapper = {
  serializedName: "StateMachineListDto",
  type: {
    name: "Composite",
    className: "StateMachineListDto",
    modelProperties: {
      stateMachineId: {
        serializedName: "stateMachineId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const StateMachineSimpleDto: msRest.CompositeMapper = {
  serializedName: "StateMachineSimpleDto",
  type: {
    name: "Composite",
    className: "StateMachineSimpleDto",
    modelProperties: {
      stateMachineId: {
        serializedName: "stateMachineId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const StateMachineSimpleExportDto: msRest.CompositeMapper = {
  serializedName: "StateMachineSimpleExportDto",
  type: {
    name: "Composite",
    className: "StateMachineSimpleExportDto",
    modelProperties: {
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const StateMachineSimpleListDto: msRest.CompositeMapper = {
  serializedName: "StateMachineSimpleListDto",
  type: {
    name: "Composite",
    className: "StateMachineSimpleListDto",
    modelProperties: {
      stateMachineId: {
        serializedName: "stateMachineId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const StatePreviewRequestDto: msRest.CompositeMapper = {
  serializedName: "StatePreviewRequestDto",
  type: {
    name: "Composite",
    className: "StatePreviewRequestDto",
    modelProperties: {
      stateMachineId: {
        serializedName: "stateMachineId",
        type: {
          name: "Number"
        }
      },
      stateId: {
        nullable: true,
        serializedName: "stateId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const StatePreviewResponseDto: msRest.CompositeMapper = {
  serializedName: "StatePreviewResponseDto",
  type: {
    name: "Composite",
    className: "StatePreviewResponseDto",
    modelProperties: {
      nextStates: {
        serializedName: "nextStates",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "StateSimpleDto"
            }
          }
        }
      }
    }
  }
};

export const StateSimpleDtoPreSelectedReferenceDto: msRest.CompositeMapper = {
  serializedName: "StateSimpleDtoPreSelectedReferenceDto",
  type: {
    name: "Composite",
    className: "StateSimpleDtoPreSelectedReferenceDto",
    modelProperties: {
      isOverridable: {
        serializedName: "isOverridable",
        type: {
          name: "Boolean"
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "Composite",
          className: "StateSimpleDto"
        }
      }
    }
  }
};

export const StatusImpactCreateV1Dto: msRest.CompositeMapper = {
  serializedName: "StatusImpactCreateV1Dto",
  type: {
    name: "Composite",
    className: "StatusImpactCreateV1Dto",
    modelProperties: {
      impactId: {
        serializedName: "impactId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      condition: {
        serializedName: "condition",
        type: {
          name: "Composite",
          className: "ConditionV1Dto"
        }
      },
      taskId: {
        nullable: true,
        serializedName: "taskId",
        type: {
          name: "Number"
        }
      },
      targetState: {
        serializedName: "targetState",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7"
          ]
        }
      }
    }
  }
};

export const StatusImpactDto: msRest.CompositeMapper = {
  serializedName: "StatusImpactDto",
  type: {
    name: "Composite",
    className: "StatusImpactDto",
    modelProperties: {
      impactId: {
        serializedName: "impactId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      condition: {
        serializedName: "condition",
        type: {
          name: "Composite",
          className: "ConditionDto"
        }
      },
      targetState: {
        serializedName: "targetState",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7"
          ]
        }
      }
    }
  }
};

export const StatusImpactV1Dto: msRest.CompositeMapper = {
  serializedName: "StatusImpactV1Dto",
  type: {
    name: "Composite",
    className: "StatusImpactV1Dto",
    modelProperties: {
      impactId: {
        serializedName: "impactId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      condition: {
        serializedName: "condition",
        type: {
          name: "Composite",
          className: "ConditionV1Dto"
        }
      },
      targetState: {
        serializedName: "targetState",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7"
          ]
        }
      }
    }
  }
};

export const SteppedResourceTaskConsumptionTriggerDto: msRest.CompositeMapper = {
  serializedName: "SteppedResourceTaskConsumptionTriggerDto",
  type: {
    name: "Composite",
    className: "SteppedResourceTaskConsumptionTriggerDto",
    modelProperties: {
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "2",
            "3",
            "4"
          ]
        }
      },
      limit: {
        serializedName: "limit",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const TicketByContractDefinitionSimpleDto: msRest.CompositeMapper = {
  serializedName: "TicketByContractDefinitionSimpleDto",
  type: {
    name: "Composite",
    className: "TicketByContractDefinitionSimpleDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      definitionId: {
        serializedName: "definitionId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const TicketsByLocationDefinitionSimpleDto: msRest.CompositeMapper = {
  serializedName: "TicketsByLocationDefinitionSimpleDto",
  type: {
    name: "Composite",
    className: "TicketsByLocationDefinitionSimpleDto",
    modelProperties: {
      ticketsByLocationDefinitionId: {
        serializedName: "ticketsByLocationDefinitionId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const StereotypeDto: msRest.CompositeMapper = {
  serializedName: "StereotypeDto",
  type: {
    name: "Composite",
    className: "StereotypeDto",
    modelProperties: {
      stereotypeId: {
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      isDefault: {
        serializedName: "isDefault",
        type: {
          name: "Boolean"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      },
      entityType: {
        serializedName: "entityType",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
            "31",
            "32",
            "33",
            "34",
            "35",
            "36",
            "37",
            "38",
            "39",
            "40",
            "41",
            "42",
            "43",
            "44",
            "45"
          ]
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      },
      parentId: {
        nullable: true,
        serializedName: "parentId",
        type: {
          name: "Number"
        }
      },
      parent: {
        serializedName: "parent",
        type: {
          name: "Composite",
          className: "StereotypeSimpleDto"
        }
      },
      hasChildren: {
        serializedName: "hasChildren",
        type: {
          name: "Boolean"
        }
      },
      defaultSkeleton: {
        serializedName: "defaultSkeleton",
        type: {
          name: "Composite",
          className: "SkeletonRootSimpleDto"
        }
      },
      ticketByContractDefinition: {
        serializedName: "ticketByContractDefinition",
        type: {
          name: "Composite",
          className: "TicketByContractDefinitionSimpleDto"
        }
      },
      inventoryNumberPattern: {
        serializedName: "inventoryNumberPattern",
        type: {
          name: "Composite",
          className: "InventoryNumberPatternSimpleDto"
        }
      },
      prioritySet: {
        serializedName: "prioritySet",
        type: {
          name: "Composite",
          className: "PrioritySetSimpleDto"
        }
      },
      stateMachine: {
        serializedName: "stateMachine",
        type: {
          name: "Composite",
          className: "StateMachineSimpleDto"
        }
      },
      missionByTaskActivationTemplate: {
        serializedName: "missionByTaskActivationTemplate",
        type: {
          name: "Composite",
          className: "DocumentTemplateSimpleDto"
        }
      },
      missionByTicketActivationTemplate: {
        serializedName: "missionByTicketActivationTemplate",
        type: {
          name: "Composite",
          className: "DocumentTemplateSimpleDto"
        }
      },
      missionByInspectionActivationTemplate: {
        serializedName: "missionByInspectionActivationTemplate",
        type: {
          name: "Composite",
          className: "DocumentTemplateSimpleDto"
        }
      },
      missionByManualActivationTemplate: {
        serializedName: "missionByManualActivationTemplate",
        type: {
          name: "Composite",
          className: "DocumentTemplateSimpleDto"
        }
      },
      ticketRestrictions: {
        serializedName: "ticketRestrictions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "StereotypeSimpleDto"
            }
          }
        }
      },
      resourcesByLocationDefinitions: {
        serializedName: "resourcesByLocationDefinitions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ResourcesByLocationDefinitionSimpleDto"
            }
          }
        }
      },
      ticketsByLocationDefinitions: {
        serializedName: "ticketsByLocationDefinitions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TicketsByLocationDefinitionSimpleDto"
            }
          }
        }
      },
      customPropertySets: {
        serializedName: "customPropertySets",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomSetReferenceDto"
            }
          }
        }
      }
    }
  }
};

export const StereotypeExportDto: msRest.CompositeMapper = {
  serializedName: "StereotypeExportDto",
  type: {
    name: "Composite",
    className: "StereotypeExportDto",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      entityType: {
        serializedName: "entityType",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
            "31",
            "32",
            "33",
            "34",
            "35",
            "36",
            "37",
            "38",
            "39",
            "40",
            "41",
            "42",
            "43",
            "44",
            "45"
          ]
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      parent: {
        serializedName: "parent",
        type: {
          name: "Composite",
          className: "StereotypeSimpleExportDto"
        }
      },
      prioritySet: {
        serializedName: "prioritySet",
        type: {
          name: "Composite",
          className: "PrioritySetSimpleExportDto"
        }
      },
      inventoryNumberPattern: {
        serializedName: "inventoryNumberPattern",
        type: {
          name: "Composite",
          className: "InventoryNumberPatternSimpleExportDto"
        }
      },
      missionByTaskActivationTemplate: {
        serializedName: "missionByTaskActivationTemplate",
        type: {
          name: "Composite",
          className: "DocumentTemplateSimpleExportDto"
        }
      },
      missionByTicketActivationTemplate: {
        serializedName: "missionByTicketActivationTemplate",
        type: {
          name: "Composite",
          className: "DocumentTemplateSimpleExportDto"
        }
      },
      missionByInspectionActivationTemplate: {
        serializedName: "missionByInspectionActivationTemplate",
        type: {
          name: "Composite",
          className: "DocumentTemplateSimpleExportDto"
        }
      },
      missionByManualActivationTemplate: {
        serializedName: "missionByManualActivationTemplate",
        type: {
          name: "Composite",
          className: "DocumentTemplateSimpleExportDto"
        }
      },
      ticketRestrictions: {
        serializedName: "ticketRestrictions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "StereotypeSimpleExportDto"
            }
          }
        }
      },
      customPropertySets: {
        serializedName: "customPropertySets",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomSetReferenceExportDto"
            }
          }
        }
      },
      isDefault: {
        serializedName: "isDefault",
        type: {
          name: "Boolean"
        }
      },
      stateMachine: {
        serializedName: "stateMachine",
        type: {
          name: "Composite",
          className: "StateMachineSimpleExportDto"
        }
      }
    }
  }
};

export const StereotypeListDto: msRest.CompositeMapper = {
  serializedName: "StereotypeListDto",
  type: {
    name: "Composite",
    className: "StereotypeListDto",
    modelProperties: {
      stereotypeId: {
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      entityType: {
        serializedName: "entityType",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
            "31",
            "32",
            "33",
            "34",
            "35",
            "36",
            "37",
            "38",
            "39",
            "40",
            "41",
            "42",
            "43",
            "44",
            "45"
          ]
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      },
      hasChildren: {
        serializedName: "hasChildren",
        type: {
          name: "Boolean"
        }
      },
      isDefault: {
        serializedName: "isDefault",
        type: {
          name: "Boolean"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      inventoryNumberPattern: {
        serializedName: "inventoryNumberPattern",
        type: {
          name: "Composite",
          className: "InventoryNumberPatternSimpleListDto"
        }
      },
      defaultSkeleton: {
        serializedName: "defaultSkeleton",
        type: {
          name: "Composite",
          className: "SkeletonRootSimpleListDto"
        }
      },
      stateMachineId: {
        nullable: true,
        serializedName: "stateMachineId",
        type: {
          name: "Number"
        }
      },
      stateMachine: {
        serializedName: "stateMachine",
        type: {
          name: "Composite",
          className: "StateMachineListDto"
        }
      },
      prioritySetId: {
        nullable: true,
        serializedName: "prioritySetId",
        type: {
          name: "Number"
        }
      },
      prioritySet: {
        serializedName: "prioritySet",
        type: {
          name: "Composite",
          className: "PrioritySetListDto"
        }
      },
      missionRestrictions: {
        serializedName: "missionRestrictions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "StereotypeSimpleListDto"
            }
          }
        }
      },
      customPropertySets: {
        serializedName: "customPropertySets",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomSetReferenceListDto"
            }
          }
        }
      }
    }
  }
};

export const StereotypeSettingDto: msRest.CompositeMapper = {
  serializedName: "StereotypeSettingDto",
  type: {
    name: "Composite",
    className: "StereotypeSettingDto",
    modelProperties: {
      settingValueId: {
        serializedName: "settingValueId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      value: {
        nullable: true,
        serializedName: "value",
        type: {
          name: "Number"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleDto"
        }
      }
    }
  }
};

export const StereotypeSimpleDtoPreSelectedReferenceDto: msRest.CompositeMapper = {
  serializedName: "StereotypeSimpleDtoPreSelectedReferenceDto",
  type: {
    name: "Composite",
    className: "StereotypeSimpleDtoPreSelectedReferenceDto",
    modelProperties: {
      isOverridable: {
        serializedName: "isOverridable",
        type: {
          name: "Boolean"
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "Composite",
          className: "StereotypeSimpleDto"
        }
      }
    }
  }
};

export const StereotypedShadowDto: msRest.CompositeMapper = {
  serializedName: "StereotypedShadowDto",
  type: {
    name: "Composite",
    className: "StereotypedShadowDto",
    modelProperties: {
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueDto"
            }
          }
        }
      }
    }
  }
};

export const StringPreSelectedDto: msRest.CompositeMapper = {
  serializedName: "StringPreSelectedDto",
  type: {
    name: "Composite",
    className: "StringPreSelectedDto",
    modelProperties: {
      isOverridable: {
        serializedName: "isOverridable",
        type: {
          name: "Boolean"
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const SubmitMessageIssueDto: msRest.CompositeMapper = {
  serializedName: "SubmitMessageIssueDto",
  type: {
    name: "Composite",
    className: "SubmitMessageIssueDto",
    modelProperties: {
      issueId: {
        serializedName: "issueId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueDto"
            }
          }
        }
      }
    }
  }
};

export const SubmitMissionIssueDto: msRest.CompositeMapper = {
  serializedName: "SubmitMissionIssueDto",
  type: {
    name: "Composite",
    className: "SubmitMissionIssueDto",
    modelProperties: {
      issueId: {
        serializedName: "issueId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueDto"
            }
          }
        }
      },
      resource: {
        serializedName: "resource",
        type: {
          name: "Composite",
          className: "ResourceSimpleDto"
        }
      },
      priority: {
        serializedName: "priority",
        type: {
          name: "Composite",
          className: "PrioritySimpleDto"
        }
      }
    }
  }
};

export const SubmitTicketIssueDto: msRest.CompositeMapper = {
  serializedName: "SubmitTicketIssueDto",
  type: {
    name: "Composite",
    className: "SubmitTicketIssueDto",
    modelProperties: {
      issueId: {
        serializedName: "issueId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueDto"
            }
          }
        }
      },
      resource: {
        serializedName: "resource",
        type: {
          name: "Composite",
          className: "ResourceSimpleDto"
        }
      },
      priority: {
        serializedName: "priority",
        type: {
          name: "Composite",
          className: "PrioritySimpleDto"
        }
      }
    }
  }
};

export const SubmitUserIssueDto: msRest.CompositeMapper = {
  serializedName: "SubmitUserIssueDto",
  type: {
    name: "Composite",
    className: "SubmitUserIssueDto",
    modelProperties: {
      issueId: {
        serializedName: "issueId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueDto"
            }
          }
        }
      }
    }
  }
};

export const SubmitWinterhalterProductDto: msRest.CompositeMapper = {
  serializedName: "SubmitWinterhalterProductDto",
  type: {
    name: "Composite",
    className: "SubmitWinterhalterProductDto",
    modelProperties: {
      productId: {
        serializedName: "productId",
        type: {
          name: "Number"
        }
      },
      quantity: {
        serializedName: "quantity",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const SubmitWinterhalterProductOrderIssueDto: msRest.CompositeMapper = {
  serializedName: "SubmitWinterhalterProductOrderIssueDto",
  type: {
    name: "Composite",
    className: "SubmitWinterhalterProductOrderIssueDto",
    modelProperties: {
      issueId: {
        serializedName: "issueId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      resource: {
        serializedName: "resource",
        type: {
          name: "Composite",
          className: "ResourceSimpleDto"
        }
      },
      products: {
        serializedName: "products",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "SubmitWinterhalterProductDto"
            }
          }
        }
      }
    }
  }
};

export const SubmitWinterhalterServiceCallIssueDto: msRest.CompositeMapper = {
  serializedName: "SubmitWinterhalterServiceCallIssueDto",
  type: {
    name: "Composite",
    className: "SubmitWinterhalterServiceCallIssueDto",
    modelProperties: {
      issueId: {
        serializedName: "issueId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      resource: {
        serializedName: "resource",
        type: {
          name: "Composite",
          className: "ResourceSimpleDto"
        }
      },
      problem: {
        serializedName: "problem",
        type: {
          name: "String"
        }
      },
      category: {
        serializedName: "category",
        type: {
          name: "String"
        }
      },
      message: {
        serializedName: "message",
        type: {
          name: "String"
        }
      },
      contactFirstName: {
        serializedName: "contactFirstName",
        type: {
          name: "String"
        }
      },
      contactLastName: {
        serializedName: "contactLastName",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const SuggestedContactDto: msRest.CompositeMapper = {
  serializedName: "SuggestedContactDto",
  type: {
    name: "Composite",
    className: "SuggestedContactDto",
    modelProperties: {
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      contactId: {
        serializedName: "contactId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const SuggestedEditorDto: msRest.CompositeMapper = {
  serializedName: "SuggestedEditorDto",
  type: {
    name: "Composite",
    className: "SuggestedEditorDto",
    modelProperties: {
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const SuggestedEditorsDto: msRest.CompositeMapper = {
  serializedName: "SuggestedEditorsDto",
  type: {
    name: "Composite",
    className: "SuggestedEditorsDto",
    modelProperties: {
      editors: {
        serializedName: "editors",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "SuggestedEditorDto"
            }
          }
        }
      }
    }
  }
};

export const SuggestedFunctionDto: msRest.CompositeMapper = {
  serializedName: "SuggestedFunctionDto",
  type: {
    name: "Composite",
    className: "SuggestedFunctionDto",
    modelProperties: {
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      functionId: {
        serializedName: "functionId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const SynchronizeAddressInLocationDto: msRest.CompositeMapper = {
  serializedName: "SynchronizeAddressInLocationDto",
  type: {
    name: "Composite",
    className: "SynchronizeAddressInLocationDto",
    modelProperties: {
      street: {
        serializedName: "street",
        type: {
          name: "String"
        }
      },
      houseNumber: {
        serializedName: "houseNumber",
        type: {
          name: "String"
        }
      },
      city: {
        serializedName: "city",
        type: {
          name: "String"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "String"
        }
      },
      zipcode: {
        serializedName: "zipcode",
        type: {
          name: "String"
        }
      },
      countryCode: {
        serializedName: "countryCode",
        type: {
          name: "String"
        }
      },
      stereotypeId: {
        serializedName: "stereotypeId",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const SynchronizeContactInLocationDto: msRest.CompositeMapper = {
  serializedName: "SynchronizeContactInLocationDto",
  type: {
    name: "Composite",
    className: "SynchronizeContactInLocationDto",
    modelProperties: {
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      firstName: {
        serializedName: "firstName",
        type: {
          name: "String"
        }
      },
      lastName: {
        serializedName: "lastName",
        type: {
          name: "String"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      emailAddress: {
        serializedName: "emailAddress",
        type: {
          name: "String"
        }
      },
      phone: {
        serializedName: "phone",
        type: {
          name: "String"
        }
      },
      mobile: {
        serializedName: "mobile",
        type: {
          name: "String"
        }
      },
      stereotypeId: {
        serializedName: "stereotypeId",
        type: {
          name: "String"
        }
      },
      functions: {
        serializedName: "functions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      }
    }
  }
};

export const SynchronizeLocationDto: msRest.CompositeMapper = {
  serializedName: "SynchronizeLocationDto",
  type: {
    name: "Composite",
    className: "SynchronizeLocationDto",
    modelProperties: {
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      locationNumber: {
        serializedName: "locationNumber",
        type: {
          name: "String"
        }
      },
      stereotypeId: {
        serializedName: "stereotypeId",
        type: {
          name: "String"
        }
      },
      addresses: {
        serializedName: "addresses",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "SynchronizeAddressInLocationDto"
            }
          }
        }
      },
      contacts: {
        serializedName: "contacts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "SynchronizeContactInLocationDto"
            }
          }
        }
      }
    }
  }
};

export const SynchronizedLocationDto: msRest.CompositeMapper = {
  serializedName: "SynchronizedLocationDto",
  type: {
    name: "Composite",
    className: "SynchronizedLocationDto",
    modelProperties: {
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Number"
        }
      },
      locationName: {
        serializedName: "locationName",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const SystemContactDto: msRest.CompositeMapper = {
  serializedName: "SystemContactDto",
  type: {
    name: "Composite",
    className: "SystemContactDto",
    modelProperties: {
      contactId: {
        serializedName: "contactId",
        type: {
          name: "Number"
        }
      },
      firstName: {
        serializedName: "firstName",
        type: {
          name: "String"
        }
      },
      lastName: {
        serializedName: "lastName",
        type: {
          name: "String"
        }
      },
      culture: {
        serializedName: "culture",
        type: {
          name: "String"
        }
      },
      timeZone: {
        serializedName: "timeZone",
        type: {
          name: "String"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      emailAddress: {
        serializedName: "emailAddress",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const TenantInContactListDto: msRest.CompositeMapper = {
  serializedName: "TenantInContactListDto",
  type: {
    name: "Composite",
    className: "TenantInContactListDto",
    modelProperties: {
      domain: {
        serializedName: "domain",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const SystemContactListDto: msRest.CompositeMapper = {
  serializedName: "SystemContactListDto",
  type: {
    name: "Composite",
    className: "SystemContactListDto",
    modelProperties: {
      contactId: {
        serializedName: "contactId",
        type: {
          name: "Number"
        }
      },
      firstName: {
        serializedName: "firstName",
        type: {
          name: "String"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      emailAddress: {
        serializedName: "emailAddress",
        type: {
          name: "String"
        }
      },
      lastName: {
        serializedName: "lastName",
        type: {
          name: "String"
        }
      },
      tenant: {
        serializedName: "tenant",
        type: {
          name: "Composite",
          className: "TenantInContactListDto"
        }
      }
    }
  }
};

export const TagFilterDto: msRest.CompositeMapper = {
  serializedName: "TagFilterDto",
  type: {
    name: "Composite",
    className: "TagFilterDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      }
    }
  }
};

export const TaskDto: msRest.CompositeMapper = {
  serializedName: "TaskDto",
  type: {
    name: "Composite",
    className: "TaskDto",
    modelProperties: {
      taskId: {
        serializedName: "taskId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4"
          ]
        }
      },
      impacts: {
        serializedName: "impacts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ImpactDto"
            }
          }
        }
      },
      documentTemplate: {
        serializedName: "documentTemplate",
        type: {
          name: "Composite",
          className: "DocumentTemplateSimpleDto"
        }
      }
    }
  }
};

export const TaskFormFilledFormCreateDto: msRest.CompositeMapper = {
  serializedName: "TaskFormFilledFormCreateDto",
  type: {
    name: "Composite",
    className: "TaskFormFilledFormCreateDto",
    modelProperties: {
      filledFormId: {
        serializedName: "filledFormId",
        type: {
          name: "Number"
        }
      },
      formId: {
        serializedName: "formId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      fields: {
        serializedName: "fields",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledFormFieldCreateDto"
            }
          }
        }
      },
      taskJobId: {
        serializedName: "taskJobId",
        type: {
          name: "Number"
        }
      },
      taskId: {
        serializedName: "taskId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const TaskGeneralDataProtectionRestrictionDto: msRest.CompositeMapper = {
  serializedName: "TaskGeneralDataProtectionRestrictionDto",
  type: {
    name: "Composite",
    className: "TaskGeneralDataProtectionRestrictionDto",
    modelProperties: {
      generalDataProtectionRestrictionId: {
        serializedName: "generalDataProtectionRestrictionId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      },
      task: {
        serializedName: "task",
        type: {
          name: "Composite",
          className: "TaskSimpleDto"
        }
      }
    }
  }
};

export const TaskGenerationQueueDto: msRest.CompositeMapper = {
  serializedName: "TaskGenerationQueueDto",
  type: {
    name: "Composite",
    className: "TaskGenerationQueueDto",
    modelProperties: {
      taskId: {
        serializedName: "taskId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const TaskSimpleListDto: msRest.CompositeMapper = {
  serializedName: "TaskSimpleListDto",
  type: {
    name: "Composite",
    className: "TaskSimpleListDto",
    modelProperties: {
      taskId: {
        serializedName: "taskId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      children: {
        serializedName: "children",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TaskSimpleListDto"
            }
          }
        }
      },
      formId: {
        nullable: true,
        serializedName: "formId",
        type: {
          name: "Number"
        }
      },
      requiresAuthentication: {
        nullable: true,
        serializedName: "requiresAuthentication",
        type: {
          name: "Boolean"
        }
      },
      hasStrictOrder: {
        nullable: true,
        serializedName: "hasStrictOrder",
        type: {
          name: "Boolean"
        }
      },
      position: {
        nullable: true,
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      reasonSetId: {
        nullable: true,
        serializedName: "reasonSetId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const TaskInJobListDto: msRest.CompositeMapper = {
  serializedName: "TaskInJobListDto",
  type: {
    name: "Composite",
    className: "TaskInJobListDto",
    modelProperties: {
      taskId: {
        serializedName: "taskId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      children: {
        serializedName: "children",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TaskSimpleListDto"
            }
          }
        }
      },
      formId: {
        nullable: true,
        serializedName: "formId",
        type: {
          name: "Number"
        }
      },
      requiresAuthentication: {
        nullable: true,
        serializedName: "requiresAuthentication",
        type: {
          name: "Boolean"
        }
      },
      hasStrictOrder: {
        nullable: true,
        serializedName: "hasStrictOrder",
        type: {
          name: "Boolean"
        }
      },
      position: {
        nullable: true,
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      reasonSetId: {
        nullable: true,
        serializedName: "reasonSetId",
        type: {
          name: "Number"
        }
      },
      categoryRelations: {
        serializedName: "categoryRelations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CategorizedRelationDto"
            }
          }
        }
      }
    }
  }
};

export const TaskJobCountListDto: msRest.CompositeMapper = {
  serializedName: "TaskJobCountListDto",
  type: {
    name: "Composite",
    className: "TaskJobCountListDto",
    modelProperties: {
      taskId: {
        serializedName: "taskId",
        type: {
          name: "Number"
        }
      },
      taskTitle: {
        serializedName: "taskTitle",
        type: {
          name: "String"
        }
      },
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Number"
        }
      },
      locationName: {
        serializedName: "locationName",
        type: {
          name: "String"
        }
      },
      countStart: {
        serializedName: "countStart",
        type: {
          name: "Number"
        }
      },
      countOverdue: {
        serializedName: "countOverdue",
        type: {
          name: "Number"
        }
      },
      countEscalated: {
        serializedName: "countEscalated",
        type: {
          name: "Number"
        }
      },
      countDone: {
        serializedName: "countDone",
        type: {
          name: "Number"
        }
      },
      countCanceled: {
        serializedName: "countCanceled",
        type: {
          name: "Number"
        }
      },
      identifier: {
        serializedName: "identifier",
        type: {
          name: "String"
        }
      },
      date: {
        serializedName: "date",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const TaskJobDataRequestDto: msRest.CompositeMapper = {
  serializedName: "TaskJobDataRequestDto",
  type: {
    name: "Composite",
    className: "TaskJobDataRequestDto",
    modelProperties: {
      start: {
        serializedName: "start",
        type: {
          name: "String"
        }
      },
      end: {
        serializedName: "end",
        type: {
          name: "String"
        }
      },
      locationId: {
        nullable: true,
        serializedName: "locationId",
        type: {
          name: "Number"
        }
      },
      filter: {
        serializedName: "filter",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilterDto"
            }
          }
        }
      },
      graphKind: {
        serializedName: "graphKind",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4"
          ]
        }
      }
    }
  }
};

export const TaskJobStateReasonDto: msRest.CompositeMapper = {
  serializedName: "TaskJobStateReasonDto",
  type: {
    name: "Composite",
    className: "TaskJobStateReasonDto",
    modelProperties: {
      taskJobStateReasonId: {
        serializedName: "taskJobStateReasonId",
        type: {
          name: "Number"
        }
      },
      content: {
        serializedName: "content",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const TaskJobStateDto: msRest.CompositeMapper = {
  serializedName: "TaskJobStateDto",
  type: {
    name: "Composite",
    className: "TaskJobStateDto",
    modelProperties: {
      taskJobStatusId: {
        serializedName: "taskJobStatusId",
        type: {
          name: "Number"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7"
          ]
        }
      },
      reason: {
        serializedName: "reason",
        type: {
          name: "Composite",
          className: "TaskJobStateReasonDto"
        }
      },
      createdByContactName: {
        serializedName: "createdByContactName",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const TaskJobDto: msRest.CompositeMapper = {
  serializedName: "TaskJobDto",
  type: {
    name: "Composite",
    className: "TaskJobDto",
    modelProperties: {
      taskJobId: {
        serializedName: "taskJobId",
        type: {
          name: "Number"
        }
      },
      executionDate: {
        serializedName: "executionDate",
        type: {
          name: "String"
        }
      },
      parentId: {
        nullable: true,
        serializedName: "parentId",
        type: {
          name: "Number"
        }
      },
      task: {
        serializedName: "task",
        type: {
          name: "Composite",
          className: "TaskV1Dto"
        }
      },
      attachment: {
        serializedName: "attachment",
        type: {
          name: "Composite",
          className: "OrgaAttachmentListDto"
        }
      },
      jobStatus: {
        serializedName: "jobStatus",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TaskJobStateDto"
            }
          }
        }
      },
      children: {
        serializedName: "children",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TaskJobDto"
            }
          }
        }
      }
    }
  }
};

export const TaskJobGraphDto: msRest.CompositeMapper = {
  serializedName: "TaskJobGraphDto",
  type: {
    name: "Composite",
    className: "TaskJobGraphDto",
    modelProperties: {
      dataPoints: {
        serializedName: "dataPoints",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TaskJobCountListDto"
            }
          }
        }
      }
    }
  }
};

export const TaskJobStateListDto: msRest.CompositeMapper = {
  serializedName: "TaskJobStateListDto",
  type: {
    name: "Composite",
    className: "TaskJobStateListDto",
    modelProperties: {
      taskJobStatusId: {
        serializedName: "taskJobStatusId",
        type: {
          name: "Number"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7"
          ]
        }
      },
      reason: {
        serializedName: "reason",
        type: {
          name: "Composite",
          className: "TaskJobStateReasonDto"
        }
      },
      createdByContactName: {
        serializedName: "createdByContactName",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const TaskJobListDto: msRest.CompositeMapper = {
  serializedName: "TaskJobListDto",
  type: {
    name: "Composite",
    className: "TaskJobListDto",
    modelProperties: {
      taskJobId: {
        serializedName: "taskJobId",
        type: {
          name: "Number"
        }
      },
      executionDate: {
        serializedName: "executionDate",
        type: {
          name: "String"
        }
      },
      taskId: {
        nullable: true,
        serializedName: "taskId",
        type: {
          name: "Number"
        }
      },
      task: {
        serializedName: "task",
        type: {
          name: "Composite",
          className: "TaskInJobListDto"
        }
      },
      jobStatus: {
        serializedName: "jobStatus",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TaskJobStateListDto"
            }
          }
        }
      },
      currentStatus: {
        serializedName: "currentStatus",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7"
          ]
        }
      },
      editorName: {
        serializedName: "editorName",
        type: {
          name: "String"
        }
      },
      currentJobStatus: {
        serializedName: "currentJobStatus",
        type: {
          name: "Composite",
          className: "TaskJobStateListDto"
        }
      },
      children: {
        serializedName: "children",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TaskJobListDto"
            }
          }
        }
      },
      parentId: {
        nullable: true,
        serializedName: "parentId",
        type: {
          name: "Number"
        }
      },
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Number"
        }
      },
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "LocationSimpleListDto"
        }
      },
      locationName: {
        readOnly: true,
        serializedName: "locationName",
        type: {
          name: "String"
        }
      },
      filledFormId: {
        nullable: true,
        readOnly: true,
        serializedName: "filledFormId",
        type: {
          name: "Number"
        }
      },
      attachment: {
        serializedName: "attachment",
        type: {
          name: "Composite",
          className: "OrgaAttachmentListDto"
        }
      }
    }
  }
};

export const TaskJobLocationRequestDto: msRest.CompositeMapper = {
  serializedName: "TaskJobLocationRequestDto",
  type: {
    name: "Composite",
    className: "TaskJobLocationRequestDto",
    modelProperties: {
      start: {
        serializedName: "start",
        type: {
          name: "String"
        }
      },
      end: {
        serializedName: "end",
        type: {
          name: "String"
        }
      },
      filter: {
        serializedName: "filter",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilterDto"
            }
          }
        }
      }
    }
  }
};

export const TaskJobStateReasonSetDto: msRest.CompositeMapper = {
  serializedName: "TaskJobStateReasonSetDto",
  type: {
    name: "Composite",
    className: "TaskJobStateReasonSetDto",
    modelProperties: {
      taskJobStateReasonSetId: {
        serializedName: "taskJobStateReasonSetId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      reasons: {
        serializedName: "reasons",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TaskJobStateReasonDto"
            }
          }
        }
      }
    }
  }
};

export const TaskJobStateReasonSetListDto: msRest.CompositeMapper = {
  serializedName: "TaskJobStateReasonSetListDto",
  type: {
    name: "Composite",
    className: "TaskJobStateReasonSetListDto",
    modelProperties: {
      taskJobStateReasonSetId: {
        serializedName: "taskJobStateReasonSetId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const TaskListDto: msRest.CompositeMapper = {
  serializedName: "TaskListDto",
  type: {
    name: "Composite",
    className: "TaskListDto",
    modelProperties: {
      taskId: {
        serializedName: "taskId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      isEnabled: {
        serializedName: "isEnabled",
        type: {
          name: "Boolean"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      parentId: {
        nullable: true,
        serializedName: "parentId",
        type: {
          name: "Number"
        }
      },
      executionPlanId: {
        nullable: true,
        serializedName: "executionPlanId",
        type: {
          name: "Number"
        }
      },
      requiresAuthentication: {
        serializedName: "requiresAuthentication",
        type: {
          name: "Boolean"
        }
      },
      reasonSetId: {
        nullable: true,
        serializedName: "reasonSetId",
        type: {
          name: "Number"
        }
      },
      executionPlan: {
        serializedName: "executionPlan",
        type: {
          name: "Composite",
          className: "ExecutionPlanCompleteListDto"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      categoryRelations: {
        serializedName: "categoryRelations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CategorizedRelationDto"
            }
          }
        }
      }
    }
  }
};

export const TaskListV1Dto: msRest.CompositeMapper = {
  serializedName: "TaskListV1Dto",
  type: {
    name: "Composite",
    className: "TaskListV1Dto",
    modelProperties: {
      taskId: {
        serializedName: "taskId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      isEnabled: {
        serializedName: "isEnabled",
        type: {
          name: "Boolean"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      parentId: {
        nullable: true,
        serializedName: "parentId",
        type: {
          name: "Number"
        }
      },
      executionPlan: {
        serializedName: "executionPlan",
        type: {
          name: "Composite",
          className: "ExecutionPlanViewV1Dto"
        }
      },
      executionPlanId: {
        nullable: true,
        serializedName: "executionPlanId",
        type: {
          name: "Number"
        }
      },
      requiresAuthentication: {
        serializedName: "requiresAuthentication",
        type: {
          name: "Boolean"
        }
      },
      reasonSetId: {
        nullable: true,
        serializedName: "reasonSetId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const TaskStateNotificationDto: msRest.CompositeMapper = {
  serializedName: "TaskStateNotificationDto",
  type: {
    name: "Composite",
    className: "TaskStateNotificationDto",
    modelProperties: {
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      message: {
        serializedName: "message",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "5"
          ]
        }
      },
      taskId: {
        serializedName: "taskId",
        type: {
          name: "Number"
        }
      },
      taskJobId: {
        serializedName: "taskJobId",
        type: {
          name: "Number"
        }
      },
      taskJobState: {
        serializedName: "taskJobState",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7"
          ]
        }
      }
    }
  }
};

export const TemplateDto: msRest.CompositeMapper = {
  serializedName: "TemplateDto",
  type: {
    name: "Composite",
    className: "TemplateDto",
    modelProperties: {
      templateId: {
        serializedName: "templateId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      content: {
        serializedName: "content",
        type: {
          name: "String"
        }
      },
      context: {
        serializedName: "context",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "181",
            "182",
            "183",
            "184",
            "191",
            "192",
            "193",
            "194"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const TemplateExportDto: msRest.CompositeMapper = {
  serializedName: "TemplateExportDto",
  type: {
    name: "Composite",
    className: "TemplateExportDto",
    modelProperties: {
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      content: {
        serializedName: "content",
        type: {
          name: "String"
        }
      },
      context: {
        serializedName: "context",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "181",
            "182",
            "183",
            "184",
            "191",
            "192",
            "193",
            "194"
          ]
        }
      }
    }
  }
};

export const TemplateListDto: msRest.CompositeMapper = {
  serializedName: "TemplateListDto",
  type: {
    name: "Composite",
    className: "TemplateListDto",
    modelProperties: {
      templateId: {
        serializedName: "templateId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      context: {
        serializedName: "context",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "181",
            "182",
            "183",
            "184",
            "191",
            "192",
            "193",
            "194"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const TenantSettingDto: msRest.CompositeMapper = {
  serializedName: "TenantSettingDto",
  type: {
    name: "Composite",
    className: "TenantSettingDto",
    modelProperties: {
      settingId: {
        serializedName: "settingId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      }
    }
  }
};

export const TenantUsageBookingDto: msRest.CompositeMapper = {
  serializedName: "TenantUsageBookingDto",
  type: {
    name: "Composite",
    className: "TenantUsageBookingDto",
    modelProperties: {
      edition: {
        serializedName: "edition",
        type: {
          name: "Enum",
          allowedValues: [
            "2",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "31",
            "32",
            "33",
            "34",
            "35",
            "36",
            "37",
            "38",
            "39",
            "40",
            "41",
            "42",
            "43",
            "44",
            "45",
            "46",
            "47"
          ]
        }
      },
      amount: {
        serializedName: "amount",
        type: {
          name: "Number"
        }
      },
      usage: {
        serializedName: "usage",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const TenantUsageDto: msRest.CompositeMapper = {
  serializedName: "TenantUsageDto",
  type: {
    name: "Composite",
    className: "TenantUsageDto",
    modelProperties: {
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      usages: {
        serializedName: "usages",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TenantUsageBookingDto"
            }
          }
        }
      }
    }
  }
};

export const TextFormDefaultDto: msRest.CompositeMapper = {
  serializedName: "TextFormDefaultDto",
  type: {
    name: "Composite",
    className: "TextFormDefaultDto",
    modelProperties: {
      fieldValueId: {
        serializedName: "fieldValueId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12"
          ]
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const TextFormFieldDto: msRest.CompositeMapper = {
  serializedName: "TextFormFieldDto",
  type: {
    name: "Composite",
    className: "TextFormFieldDto",
    modelProperties: {
      formFieldId: {
        serializedName: "formFieldId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      technicalName: {
        readOnly: true,
        serializedName: "technicalName",
        type: {
          name: "String"
        }
      },
      width: {
        serializedName: "width",
        type: {
          name: "Number"
        }
      },
      isRequired: {
        serializedName: "isRequired",
        type: {
          name: "Boolean"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12"
          ]
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      defaultValues: {
        serializedName: "defaultValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FormDefaultDto"
            }
          }
        }
      },
      localFieldId: {
        serializedName: "localFieldId",
        type: {
          name: "String"
        }
      },
      maxLength: {
        nullable: true,
        serializedName: "maxLength",
        type: {
          name: "Number"
        }
      },
      minLength: {
        nullable: true,
        serializedName: "minLength",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const TextFormValueDto: msRest.CompositeMapper = {
  serializedName: "TextFormValueDto",
  type: {
    name: "Composite",
    className: "TextFormValueDto",
    modelProperties: {
      fieldValueId: {
        serializedName: "fieldValueId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12"
          ]
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const TextSettingDto: msRest.CompositeMapper = {
  serializedName: "TextSettingDto",
  type: {
    name: "Composite",
    className: "TextSettingDto",
    modelProperties: {
      settingValueId: {
        serializedName: "settingValueId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const TextTemplateTtm: msRest.CompositeMapper = {
  serializedName: "TextTemplateTtm",
  type: {
    name: "Composite",
    className: "TextTemplateTtm",
    modelProperties: {
      currentUser: {
        serializedName: "currentUser",
        type: {
          name: "Composite",
          className: "ContactTtm"
        }
      }
    }
  }
};

export const TicketStateTransitionDto: msRest.CompositeMapper = {
  serializedName: "TicketStateTransitionDto",
  type: {
    name: "Composite",
    className: "TicketStateTransitionDto",
    modelProperties: {
      occuredAt: {
        serializedName: "occuredAt",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "Composite",
          className: "StateSimpleDto"
        }
      }
    }
  }
};

export const TicketAppDto: msRest.CompositeMapper = {
  serializedName: "TicketAppDto",
  type: {
    name: "Composite",
    className: "TicketAppDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      ticketId: {
        serializedName: "ticketId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      },
      priority: {
        serializedName: "priority",
        type: {
          name: "Composite",
          className: "PrioritySimpleDto"
        }
      },
      currentState: {
        serializedName: "currentState",
        type: {
          name: "Composite",
          className: "StateSimpleDto"
        }
      },
      stateTransitions: {
        serializedName: "stateTransitions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TicketStateTransitionDto"
            }
          }
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeUsageDto"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueDto"
            }
          }
        }
      }
    }
  }
};

export const TicketByContractDefinitionDto: msRest.CompositeMapper = {
  serializedName: "TicketByContractDefinitionDto",
  type: {
    name: "Composite",
    className: "TicketByContractDefinitionDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      definitionId: {
        serializedName: "definitionId",
        type: {
          name: "Number"
        }
      },
      ticketStereotype: {
        required: true,
        serializedName: "ticketStereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleDto"
        }
      },
      priority: {
        required: true,
        serializedName: "priority",
        type: {
          name: "Composite",
          className: "PrioritySimpleDto"
        }
      },
      state: {
        required: true,
        serializedName: "state",
        type: {
          name: "Composite",
          className: "StateSimpleDto"
        }
      },
      contractStereotypeId: {
        required: true,
        serializedName: "contractStereotypeId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const TicketByLocationSkeletonDto: msRest.CompositeMapper = {
  serializedName: "TicketByLocationSkeletonDto",
  type: {
    name: "Composite",
    className: "TicketByLocationSkeletonDto",
    modelProperties: {
      resourceSkeletonId: {
        serializedName: "resourceSkeletonId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      ticketStereotype: {
        required: true,
        serializedName: "ticketStereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleDto"
        }
      },
      priority: {
        required: true,
        serializedName: "priority",
        type: {
          name: "Composite",
          className: "PrioritySimpleDto"
        }
      },
      state: {
        required: true,
        serializedName: "state",
        type: {
          name: "Composite",
          className: "StateSimpleDto"
        }
      },
      editor: {
        serializedName: "editor",
        type: {
          name: "Composite",
          className: "ContactSimpleDto"
        }
      },
      resource: {
        serializedName: "resource",
        type: {
          name: "Composite",
          className: "ResourceSimpleDto"
        }
      }
    }
  }
};

export const TicketSkeletonDto: msRest.CompositeMapper = {
  serializedName: "TicketSkeletonDto",
  type: {
    name: "Composite",
    className: "TicketSkeletonDto",
    modelProperties: {
      ticketSkeletonId: {
        serializedName: "ticketSkeletonId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "Composite",
          className: "StringPreSelectedDto"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "Composite",
          className: "StringPreSelectedDto"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "Composite",
          className: "StateSimpleDtoPreSelectedReferenceDto"
        }
      },
      priority: {
        serializedName: "priority",
        type: {
          name: "Composite",
          className: "PrioritySimpleDtoPreSelectedReferenceDto"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleDtoPreSelectedReferenceDto"
        }
      },
      resourceSelector: {
        serializedName: "resourceSelector",
        type: {
          name: "Composite",
          className: "ResourceTicketSkeletonSelectorDto"
        }
      }
    }
  }
};

export const TicketByProcessDefinitionDto: msRest.CompositeMapper = {
  serializedName: "TicketByProcessDefinitionDto",
  type: {
    name: "Composite",
    className: "TicketByProcessDefinitionDto",
    modelProperties: {
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      ticketByProcessDefinitionId: {
        serializedName: "ticketByProcessDefinitionId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      kind: {
        serializedName: "kind",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1"
          ]
        }
      },
      requiresAuthentication: {
        serializedName: "requiresAuthentication",
        type: {
          name: "Boolean"
        }
      },
      ticket: {
        serializedName: "ticket",
        type: {
          name: "Composite",
          className: "TicketSkeletonDto"
        }
      },
      mission: {
        serializedName: "mission",
        type: {
          name: "Composite",
          className: "MissionSkeletonDto"
        }
      },
      chat: {
        serializedName: "chat",
        type: {
          name: "Composite",
          className: "ChatSkeletonDto"
        }
      },
      locations: {
        serializedName: "locations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationSimpleDto"
            }
          }
        }
      },
      locationGroups: {
        serializedName: "locationGroups",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationGroupSimpleDto"
            }
          }
        }
      }
    }
  }
};

export const TicketByProcessDefinitionListDto: msRest.CompositeMapper = {
  serializedName: "TicketByProcessDefinitionListDto",
  type: {
    name: "Composite",
    className: "TicketByProcessDefinitionListDto",
    modelProperties: {
      ticketByProcessDefinitionId: {
        serializedName: "ticketByProcessDefinitionId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      kind: {
        serializedName: "kind",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1"
          ]
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const TicketProcessPreviewDto: msRest.CompositeMapper = {
  serializedName: "TicketProcessPreviewDto",
  type: {
    name: "Composite",
    className: "TicketProcessPreviewDto",
    modelProperties: {
      ticketSkeletonId: {
        serializedName: "ticketSkeletonId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "Composite",
          className: "StringPreSelectedDto"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "Composite",
          className: "StringPreSelectedDto"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "Composite",
          className: "StateSimpleDtoPreSelectedReferenceDto"
        }
      },
      priority: {
        serializedName: "priority",
        type: {
          name: "Composite",
          className: "PrioritySimpleDtoPreSelectedReferenceDto"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleDtoPreSelectedReferenceDto"
        }
      },
      resourceSelector: {
        serializedName: "resourceSelector",
        type: {
          name: "Composite",
          className: "ResourceTicketSkeletonSelectorDto"
        }
      }
    }
  }
};

export const TicketStereotypeUsageDto: msRest.CompositeMapper = {
  serializedName: "TicketStereotypeUsageDto",
  type: {
    name: "Composite",
    className: "TicketStereotypeUsageDto",
    modelProperties: {
      stereotypeId: {
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      isDefault: {
        serializedName: "isDefault",
        type: {
          name: "Boolean"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      },
      entityType: {
        serializedName: "entityType",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
            "31",
            "32",
            "33",
            "34",
            "35",
            "36",
            "37",
            "38",
            "39",
            "40",
            "41",
            "42",
            "43",
            "44",
            "45"
          ]
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      },
      customPropertySets: {
        serializedName: "customPropertySets",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomSetReferenceDto"
            }
          }
        }
      },
      prioritySet: {
        serializedName: "prioritySet",
        type: {
          name: "Composite",
          className: "PrioritySetSimpleDto"
        }
      },
      stateMachine: {
        serializedName: "stateMachine",
        type: {
          name: "Composite",
          className: "StateMachineSimpleDto"
        }
      }
    }
  }
};

export const TicketByProcessDefinitionPreviewDto: msRest.CompositeMapper = {
  serializedName: "TicketByProcessDefinitionPreviewDto",
  type: {
    name: "Composite",
    className: "TicketByProcessDefinitionPreviewDto",
    modelProperties: {
      ticketByProcessDefinitionId: {
        serializedName: "ticketByProcessDefinitionId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      requiresAuthentication: {
        serializedName: "requiresAuthentication",
        type: {
          name: "Boolean"
        }
      },
      ticket: {
        serializedName: "ticket",
        type: {
          name: "Composite",
          className: "TicketProcessPreviewDto"
        }
      },
      ticketStereotype: {
        serializedName: "ticketStereotype",
        type: {
          name: "Composite",
          className: "TicketStereotypeUsageDto"
        }
      }
    }
  }
};

export const TicketSkeletonV1Dto: msRest.CompositeMapper = {
  serializedName: "TicketSkeletonV1Dto",
  type: {
    name: "Composite",
    className: "TicketSkeletonV1Dto",
    modelProperties: {
      ticketSkeletonId: {
        serializedName: "ticketSkeletonId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "Composite",
          className: "StringPreSelectedDto"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "Composite",
          className: "StringPreSelectedDto"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "Composite",
          className: "StateSimpleDtoPreSelectedReferenceDto"
        }
      },
      priority: {
        serializedName: "priority",
        type: {
          name: "Composite",
          className: "PrioritySimpleDtoPreSelectedReferenceDto"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleDtoPreSelectedReferenceDto"
        }
      }
    }
  }
};

export const TicketByProcessDefinitionV1Dto: msRest.CompositeMapper = {
  serializedName: "TicketByProcessDefinitionV1Dto",
  type: {
    name: "Composite",
    className: "TicketByProcessDefinitionV1Dto",
    modelProperties: {
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      ticketByProcessDefinitionId: {
        serializedName: "ticketByProcessDefinitionId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      requiresAuthentication: {
        serializedName: "requiresAuthentication",
        type: {
          name: "Boolean"
        }
      },
      ticket: {
        serializedName: "ticket",
        type: {
          name: "Composite",
          className: "TicketSkeletonV1Dto"
        }
      },
      mission: {
        serializedName: "mission",
        type: {
          name: "Composite",
          className: "MissionSkeletonV1Dto"
        }
      },
      chat: {
        serializedName: "chat",
        type: {
          name: "Composite",
          className: "ChatSkeletonV1Dto"
        }
      },
      locations: {
        serializedName: "locations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationSimpleDto"
            }
          }
        }
      },
      locationGroups: {
        serializedName: "locationGroups",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationGroupSimpleDto"
            }
          }
        }
      },
      resourceFilters: {
        serializedName: "resourceFilters",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "StereotypeSimpleDto"
            }
          }
        }
      }
    }
  }
};

export const TicketChatMemberDto: msRest.CompositeMapper = {
  serializedName: "TicketChatMemberDto",
  type: {
    name: "Composite",
    className: "TicketChatMemberDto",
    modelProperties: {
      chatMemberId: {
        serializedName: "chatMemberId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5"
          ]
        }
      },
      concreteMemberId: {
        readOnly: true,
        serializedName: "concreteMemberId",
        type: {
          name: "Number"
        }
      },
      concreteMemberName: {
        readOnly: true,
        serializedName: "concreteMemberName",
        type: {
          name: "String"
        }
      },
      ticketId: {
        serializedName: "ticketId",
        type: {
          name: "Number"
        }
      },
      ticket: {
        serializedName: "ticket",
        type: {
          name: "Composite",
          className: "TicketSimpleDto"
        }
      }
    }
  }
};

export const TicketSolutionDto: msRest.CompositeMapper = {
  serializedName: "TicketSolutionDto",
  type: {
    name: "Composite",
    className: "TicketSolutionDto",
    modelProperties: {
      solutionId: {
        serializedName: "solutionId",
        type: {
          name: "Number"
        }
      },
      members: {
        serializedName: "members",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "SolutionMemberDto"
            }
          }
        }
      },
      attachments: {
        serializedName: "attachments",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AttachmentForTechDto"
            }
          }
        }
      },
      threadId: {
        serializedName: "threadId",
        type: {
          name: "String"
        }
      },
      threadMailAddress: {
        serializedName: "threadMailAddress",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const TicketSourceDto: msRest.CompositeMapper = {
  serializedName: "TicketSourceDto",
  type: {
    name: "Composite",
    className: "TicketSourceDto",
    modelProperties: {
      primaryIssue: {
        serializedName: "primaryIssue",
        type: {
          name: "Composite",
          className: "IssueSimpleDto"
        }
      },
      primaryMission: {
        serializedName: "primaryMission",
        type: {
          name: "Composite",
          className: "MissionSimpleDto"
        }
      }
    }
  }
};

export const TicketDto: msRest.CompositeMapper = {
  serializedName: "TicketDto",
  type: {
    name: "Composite",
    className: "TicketDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      ticketId: {
        serializedName: "ticketId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      },
      lastModified: {
        serializedName: "lastModified",
        type: {
          name: "String"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "String"
        }
      },
      creator: {
        serializedName: "creator",
        type: {
          name: "Composite",
          className: "CreatedByDto"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      },
      hasChat: {
        serializedName: "hasChat",
        type: {
          name: "Boolean"
        }
      },
      solutionId: {
        serializedName: "solutionId",
        type: {
          name: "Number"
        }
      },
      solution: {
        serializedName: "solution",
        type: {
          name: "Composite",
          className: "TicketSolutionDto"
        }
      },
      currentState: {
        serializedName: "currentState",
        type: {
          name: "Composite",
          className: "StateSimpleDto"
        }
      },
      editor: {
        serializedName: "editor",
        type: {
          name: "Composite",
          className: "EditorDto"
        }
      },
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "LocationSimpleDto"
        }
      },
      resource: {
        serializedName: "resource",
        type: {
          name: "Composite",
          className: "ResourceInTicketDto"
        }
      },
      escalationLevel: {
        serializedName: "escalationLevel",
        type: {
          name: "Composite",
          className: "EscalationLevelSimpleDto"
        }
      },
      origin: {
        serializedName: "origin",
        type: {
          name: "Composite",
          className: "TicketSourceDto"
        }
      },
      priority: {
        serializedName: "priority",
        type: {
          name: "Composite",
          className: "PrioritySimpleDto"
        }
      },
      links: {
        serializedName: "links",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LinkDto"
            }
          }
        }
      },
      isLabelingSince: {
        serializedName: "isLabelingSince",
        type: {
          name: "String"
        }
      },
      labels: {
        serializedName: "labels",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LabelSimpleDto"
            }
          }
        }
      },
      similarEntities: {
        serializedName: "similarEntities",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LabeledEntityDto"
            }
          }
        }
      },
      attachments: {
        serializedName: "attachments",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AttachmentForTechDto"
            }
          }
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueDto"
            }
          }
        }
      }
    }
  }
};

export const TicketEventCompleteDto: msRest.CompositeMapper = {
  serializedName: "TicketEventCompleteDto",
  type: {
    name: "Composite",
    className: "TicketEventCompleteDto",
    modelProperties: {
      ticketEventId: {
        serializedName: "ticketEventId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      },
      ticketId: {
        serializedName: "ticketId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      triggersAt: {
        serializedName: "triggersAt",
        type: {
          name: "String"
        }
      },
      ruleId: {
        nullable: true,
        serializedName: "ruleId",
        type: {
          name: "Number"
        }
      },
      ruleToExpress: {
        serializedName: "ruleToExpress",
        type: {
          name: "Composite",
          className: "EscalationRulesInEventListDto"
        }
      },
      followUpState: {
        serializedName: "followUpState",
        type: {
          name: "Composite",
          className: "StateSimpleListDto"
        }
      }
    }
  }
};

export const TicketFromMailDto: msRest.CompositeMapper = {
  serializedName: "TicketFromMailDto",
  type: {
    name: "Composite",
    className: "TicketFromMailDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      ticketId: {
        serializedName: "ticketId",
        type: {
          name: "Number"
        }
      },
      mail: {
        serializedName: "mail",
        type: {
          name: "Composite",
          className: "MailDto"
        }
      },
      originalFileId: {
        serializedName: "originalFileId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      resourceId: {
        nullable: true,
        serializedName: "resourceId",
        type: {
          name: "Number"
        }
      },
      currentStateId: {
        serializedName: "currentStateId",
        type: {
          name: "Number"
        }
      },
      priorityId: {
        serializedName: "priorityId",
        type: {
          name: "Number"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      solutionId: {
        nullable: true,
        serializedName: "solutionId",
        type: {
          name: "Number"
        }
      },
      issuerNodeId: {
        serializedName: "issuerNodeId",
        type: {
          name: "Number"
        }
      },
      lastModified: {
        serializedName: "lastModified",
        type: {
          name: "String"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const TicketIssueDetailsDto: msRest.CompositeMapper = {
  serializedName: "TicketIssueDetailsDto",
  type: {
    name: "Composite",
    className: "TicketIssueDetailsDto",
    modelProperties: {
      ticketId: {
        serializedName: "ticketId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      currentState: {
        serializedName: "currentState",
        type: {
          name: "Composite",
          className: "StateSimpleDto"
        }
      }
    }
  }
};

export const TicketIssueDto: msRest.CompositeMapper = {
  serializedName: "TicketIssueDto",
  type: {
    name: "Composite",
    className: "TicketIssueDto",
    modelProperties: {
      issueId: {
        serializedName: "issueId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueDto"
            }
          }
        }
      },
      ticket: {
        serializedName: "ticket",
        type: {
          name: "Composite",
          className: "TicketIssueDetailsDto"
        }
      },
      resource: {
        serializedName: "resource",
        type: {
          name: "Composite",
          className: "ResourceSimpleDto"
        }
      },
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "LocationSimpleDto"
        }
      },
      priority: {
        serializedName: "priority",
        type: {
          name: "Composite",
          className: "PrioritySimpleDto"
        }
      }
    }
  }
};

export const TicketIssueListDto: msRest.CompositeMapper = {
  serializedName: "TicketIssueListDto",
  type: {
    name: "Composite",
    className: "TicketIssueListDto",
    modelProperties: {
      issueId: {
        serializedName: "issueId",
        type: {
          name: "Number"
        }
      },
      issueType: {
        serializedName: "issueType",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "5"
          ]
        }
      },
      issue: {
        serializedName: "issue",
        type: {
          name: "Composite",
          className: "IssueDetailsListDto"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleListDto"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueListDto"
            }
          }
        }
      },
      priority: {
        serializedName: "priority",
        type: {
          name: "Composite",
          className: "PrioritySimpleListDto"
        }
      },
      ticket: {
        serializedName: "ticket",
        type: {
          name: "Composite",
          className: "TicketSimpleListDto"
        }
      }
    }
  }
};

export const TicketIssuePreviewDto: msRest.CompositeMapper = {
  serializedName: "TicketIssuePreviewDto",
  type: {
    name: "Composite",
    className: "TicketIssuePreviewDto",
    modelProperties: {
      issueTemplateId: {
        serializedName: "issueTemplateId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      issueStereotype: {
        serializedName: "issueStereotype",
        type: {
          name: "Composite",
          className: "StereotypeUsageDto"
        }
      },
      contentType: {
        serializedName: "contentType",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "5"
          ]
        }
      },
      resourceSelectorKind: {
        serializedName: "resourceSelectorKind",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1"
          ]
        }
      },
      priorities: {
        serializedName: "priorities",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PrioritySimpleDto"
            }
          }
        }
      }
    }
  }
};

export const TicketListDto: msRest.CompositeMapper = {
  serializedName: "TicketListDto",
  type: {
    name: "Composite",
    className: "TicketListDto",
    modelProperties: {
      ticketId: {
        serializedName: "ticketId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      stereotypeId: {
        nullable: true,
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      stereotypeRowVersion: {
        serializedName: "stereotypeRowVersion",
        type: {
          name: "ByteArray"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      resourceId: {
        serializedName: "resourceId",
        type: {
          name: "Number"
        }
      },
      currentStateId: {
        serializedName: "currentStateId",
        type: {
          name: "Number"
        }
      },
      currentState: {
        serializedName: "currentState",
        type: {
          name: "Composite",
          className: "StateSimpleListDto"
        }
      },
      editor: {
        serializedName: "editor",
        type: {
          name: "Composite",
          className: "ContactInTicketListDto"
        }
      },
      priority: {
        serializedName: "priority",
        type: {
          name: "Composite",
          className: "PrioritySimpleListDto"
        }
      },
      locationNumber: {
        serializedName: "locationNumber",
        type: {
          name: "String"
        }
      },
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "LocationListDto"
        }
      },
      resource: {
        serializedName: "resource",
        type: {
          name: "Composite",
          className: "ResourceInTicketListDto"
        }
      },
      escalationLevelId: {
        nullable: true,
        serializedName: "escalationLevelId",
        type: {
          name: "Number"
        }
      },
      escalationLevel: {
        serializedName: "escalationLevel",
        type: {
          name: "Composite",
          className: "EscalationLevelSimpleListDto"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleListDto"
        }
      },
      labelRelations: {
        serializedName: "labelRelations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LabelRelationListDto"
            }
          }
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "String"
        }
      },
      lastModified: {
        serializedName: "lastModified",
        type: {
          name: "String"
        }
      },
      customPropertyValues: {
        serializedName: "customPropertyValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FilledCustomValueListDto"
            }
          }
        }
      }
    }
  }
};

export const TicketNotificationRuleTtm: msRest.CompositeMapper = {
  serializedName: "TicketNotificationRuleTtm",
  type: {
    name: "Composite",
    className: "TicketNotificationRuleTtm",
    modelProperties: {
      ticketId: {
        serializedName: "ticketId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      link: {
        serializedName: "link",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      resource: {
        serializedName: "resource",
        type: {
          name: "Composite",
          className: "ResourceTtm"
        }
      },
      priority: {
        serializedName: "priority",
        type: {
          name: "Composite",
          className: "PriorityModelTtm"
        }
      },
      escalationLevel: {
        serializedName: "escalationLevel",
        type: {
          name: "Composite",
          className: "EscalationLevelTtm"
        }
      },
      editor: {
        serializedName: "editor",
        type: {
          name: "Composite",
          className: "ContactTtm"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeTtm"
        }
      },
      properties: {
        serializedName: "properties",
        type: {
          name: "Object"
        }
      }
    }
  }
};

export const TicketStateSettingDto: msRest.CompositeMapper = {
  serializedName: "TicketStateSettingDto",
  type: {
    name: "Composite",
    className: "TicketStateSettingDto",
    modelProperties: {
      settingValueId: {
        serializedName: "settingValueId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      value: {
        nullable: true,
        serializedName: "value",
        type: {
          name: "Number"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "Composite",
          className: "StateSimpleDto"
        }
      }
    }
  }
};

export const TicketSettingDto: msRest.CompositeMapper = {
  serializedName: "TicketSettingDto",
  type: {
    name: "Composite",
    className: "TicketSettingDto",
    modelProperties: {
      settingId: {
        serializedName: "settingId",
        type: {
          name: "Number"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      newMailStereotype: {
        serializedName: "newMailStereotype",
        type: {
          name: "Composite",
          className: "StereotypeSettingDto"
        }
      },
      newMailPriority: {
        serializedName: "newMailPriority",
        type: {
          name: "Composite",
          className: "PrioritySettingDto"
        }
      },
      newMailResource: {
        serializedName: "newMailResource",
        type: {
          name: "Composite",
          className: "ResourceSettingDto"
        }
      },
      newMailTicketState: {
        serializedName: "newMailTicketState",
        type: {
          name: "Composite",
          className: "TicketStateSettingDto"
        }
      }
    }
  }
};

export const TicketStateChangeByChangedMissionStateRequestDto: msRest.CompositeMapper = {
  serializedName: "TicketStateChangeByChangedMissionStateRequestDto",
  type: {
    name: "Composite",
    className: "TicketStateChangeByChangedMissionStateRequestDto",
    modelProperties: {
      ticketId: {
        serializedName: "ticketId",
        type: {
          name: "Number"
        }
      },
      missionState: {
        serializedName: "missionState",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4"
          ]
        }
      },
      missionId: {
        serializedName: "missionId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const TicketStateChangeByClosedMissionRequestDto: msRest.CompositeMapper = {
  serializedName: "TicketStateChangeByClosedMissionRequestDto",
  type: {
    name: "Composite",
    className: "TicketStateChangeByClosedMissionRequestDto",
    modelProperties: {
      ticketId: {
        serializedName: "ticketId",
        type: {
          name: "Number"
        }
      },
      missionId: {
        serializedName: "missionId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const TicketStateChangeRequestDto: msRest.CompositeMapper = {
  serializedName: "TicketStateChangeRequestDto",
  type: {
    name: "Composite",
    className: "TicketStateChangeRequestDto",
    modelProperties: {
      ticketId: {
        serializedName: "ticketId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const TicketsByLocationDefinitionDto: msRest.CompositeMapper = {
  serializedName: "TicketsByLocationDefinitionDto",
  type: {
    name: "Composite",
    className: "TicketsByLocationDefinitionDto",
    modelProperties: {
      ticketsByLocationDefinitionId: {
        serializedName: "ticketsByLocationDefinitionId",
        type: {
          name: "Number"
        }
      },
      stereotypeId: {
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      tickets: {
        serializedName: "tickets",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TicketByLocationSkeletonDto"
            }
          }
        }
      }
    }
  }
};

export const TicketsByLocationDefinitionListDto: msRest.CompositeMapper = {
  serializedName: "TicketsByLocationDefinitionListDto",
  type: {
    name: "Composite",
    className: "TicketsByLocationDefinitionListDto",
    modelProperties: {
      ticketsByLocationDefinitionId: {
        serializedName: "ticketsByLocationDefinitionId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeSimpleListDto"
        }
      }
    }
  }
};

export const TicketsPerLocationByMonthDto: msRest.CompositeMapper = {
  serializedName: "TicketsPerLocationByMonthDto",
  type: {
    name: "Composite",
    className: "TicketsPerLocationByMonthDto",
    modelProperties: {
      month: {
        serializedName: "month",
        type: {
          name: "Number"
        }
      },
      monthName: {
        serializedName: "monthName",
        type: {
          name: "String"
        }
      },
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Number"
        }
      },
      locationName: {
        serializedName: "locationName",
        type: {
          name: "String"
        }
      },
      ticketCount: {
        serializedName: "ticketCount",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const TicketsPerLocationByTimeDto: msRest.CompositeMapper = {
  serializedName: "TicketsPerLocationByTimeDto",
  type: {
    name: "Composite",
    className: "TicketsPerLocationByTimeDto",
    modelProperties: {
      datePoint: {
        serializedName: "datePoint",
        type: {
          name: "String"
        }
      },
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Number"
        }
      },
      locationName: {
        serializedName: "locationName",
        type: {
          name: "String"
        }
      },
      ticketCount: {
        serializedName: "ticketCount",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const TicketsPerLocationPerTypeByMonthDto: msRest.CompositeMapper = {
  serializedName: "TicketsPerLocationPerTypeByMonthDto",
  type: {
    name: "Composite",
    className: "TicketsPerLocationPerTypeByMonthDto",
    modelProperties: {
      month: {
        serializedName: "month",
        type: {
          name: "Number"
        }
      },
      monthName: {
        serializedName: "monthName",
        type: {
          name: "String"
        }
      },
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Number"
        }
      },
      locationName: {
        serializedName: "locationName",
        type: {
          name: "String"
        }
      },
      ticketCount: {
        serializedName: "ticketCount",
        type: {
          name: "Number"
        }
      },
      stereotypeName: {
        serializedName: "stereotypeName",
        type: {
          name: "String"
        }
      },
      stereotypeId: {
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const TicketsPerLocationPerTypeByTimeDto: msRest.CompositeMapper = {
  serializedName: "TicketsPerLocationPerTypeByTimeDto",
  type: {
    name: "Composite",
    className: "TicketsPerLocationPerTypeByTimeDto",
    modelProperties: {
      datePoint: {
        serializedName: "datePoint",
        type: {
          name: "String"
        }
      },
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Number"
        }
      },
      locationName: {
        serializedName: "locationName",
        type: {
          name: "String"
        }
      },
      ticketCount: {
        serializedName: "ticketCount",
        type: {
          name: "Number"
        }
      },
      stereotypeName: {
        serializedName: "stereotypeName",
        type: {
          name: "String"
        }
      },
      stereotypeId: {
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const TicketsPerResourceManufacturerDto: msRest.CompositeMapper = {
  serializedName: "TicketsPerResourceManufacturerDto",
  type: {
    name: "Composite",
    className: "TicketsPerResourceManufacturerDto",
    modelProperties: {
      resourceCount: {
        serializedName: "resourceCount",
        type: {
          name: "Number"
        }
      },
      ticketCount: {
        serializedName: "ticketCount",
        type: {
          name: "Number"
        }
      },
      manufacturer: {
        serializedName: "manufacturer",
        type: {
          name: "String"
        }
      },
      resourceStereotypeName: {
        serializedName: "resourceStereotypeName",
        type: {
          name: "String"
        }
      },
      resourceStereotypeId: {
        serializedName: "resourceStereotypeId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const TicketsPerTypeDto: msRest.CompositeMapper = {
  serializedName: "TicketsPerTypeDto",
  type: {
    name: "Composite",
    className: "TicketsPerTypeDto",
    modelProperties: {
      resourceCount: {
        serializedName: "resourceCount",
        type: {
          name: "Number"
        }
      },
      ticketCount: {
        serializedName: "ticketCount",
        type: {
          name: "Number"
        }
      },
      stereotypeName: {
        serializedName: "stereotypeName",
        type: {
          name: "String"
        }
      },
      stereotypeId: {
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      withChat: {
        serializedName: "withChat",
        type: {
          name: "Number"
        }
      },
      withChatProcess: {
        serializedName: "withChatProcess",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const TimeConditionDto: msRest.CompositeMapper = {
  serializedName: "TimeConditionDto",
  type: {
    name: "Composite",
    className: "TimeConditionDto",
    modelProperties: {
      conditionId: {
        serializedName: "conditionId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      conditionOffset: {
        serializedName: "conditionOffset",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const TimeConditionV1Dto: msRest.CompositeMapper = {
  serializedName: "TimeConditionV1Dto",
  type: {
    name: "Composite",
    className: "TimeConditionV1Dto",
    modelProperties: {
      conditionId: {
        serializedName: "conditionId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      conditionOffset: {
        serializedName: "conditionOffset",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const TimeFormDefaultDto: msRest.CompositeMapper = {
  serializedName: "TimeFormDefaultDto",
  type: {
    name: "Composite",
    className: "TimeFormDefaultDto",
    modelProperties: {
      fieldValueId: {
        serializedName: "fieldValueId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12"
          ]
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const TimeFormFieldDto: msRest.CompositeMapper = {
  serializedName: "TimeFormFieldDto",
  type: {
    name: "Composite",
    className: "TimeFormFieldDto",
    modelProperties: {
      formFieldId: {
        serializedName: "formFieldId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      technicalName: {
        readOnly: true,
        serializedName: "technicalName",
        type: {
          name: "String"
        }
      },
      width: {
        serializedName: "width",
        type: {
          name: "Number"
        }
      },
      isRequired: {
        serializedName: "isRequired",
        type: {
          name: "Boolean"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12"
          ]
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      defaultValues: {
        serializedName: "defaultValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FormDefaultDto"
            }
          }
        }
      },
      localFieldId: {
        serializedName: "localFieldId",
        type: {
          name: "String"
        }
      },
      minValue: {
        serializedName: "minValue",
        type: {
          name: "String"
        }
      },
      maxValue: {
        serializedName: "maxValue",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const TimeFormValueDto: msRest.CompositeMapper = {
  serializedName: "TimeFormValueDto",
  type: {
    name: "Composite",
    className: "TimeFormValueDto",
    modelProperties: {
      fieldValueId: {
        serializedName: "fieldValueId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12"
          ]
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const TimelineCollectionTaskDto: msRest.CompositeMapper = {
  serializedName: "TimelineCollectionTaskDto",
  type: {
    name: "Composite",
    className: "TimelineCollectionTaskDto",
    modelProperties: {
      taskId: {
        serializedName: "taskId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      reasonSetId: {
        nullable: true,
        serializedName: "reasonSetId",
        type: {
          name: "Number"
        }
      },
      children: {
        serializedName: "children",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TimelineTaskDto"
            }
          }
        }
      },
      hasStrictOrder: {
        serializedName: "hasStrictOrder",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const TimelineConfirmationTaskDto: msRest.CompositeMapper = {
  serializedName: "TimelineConfirmationTaskDto",
  type: {
    name: "Composite",
    className: "TimelineConfirmationTaskDto",
    modelProperties: {
      taskId: {
        serializedName: "taskId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      reasonSetId: {
        nullable: true,
        serializedName: "reasonSetId",
        type: {
          name: "Number"
        }
      },
      requiresAuthentication: {
        serializedName: "requiresAuthentication",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const TimelineDocumentTaskDto: msRest.CompositeMapper = {
  serializedName: "TimelineDocumentTaskDto",
  type: {
    name: "Composite",
    className: "TimelineDocumentTaskDto",
    modelProperties: {
      taskId: {
        serializedName: "taskId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3"
          ]
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      reasonSetId: {
        nullable: true,
        serializedName: "reasonSetId",
        type: {
          name: "Number"
        }
      },
      formId: {
        nullable: true,
        serializedName: "formId",
        type: {
          name: "Number"
        }
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number"
        }
      },
      requiresAuthentication: {
        serializedName: "requiresAuthentication",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const TimelineSettingStereotypeDto: msRest.CompositeMapper = {
  serializedName: "TimelineSettingStereotypeDto",
  type: {
    name: "Composite",
    className: "TimelineSettingStereotypeDto",
    modelProperties: {
      stereotypeId: {
        serializedName: "stereotypeId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      },
      entityType: {
        serializedName: "entityType",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
            "31",
            "32",
            "33",
            "34",
            "35",
            "36",
            "37",
            "38",
            "39",
            "40",
            "41",
            "42",
            "43",
            "44",
            "45"
          ]
        }
      },
      rowVersion: {
        serializedName: "rowVersion",
        type: {
          name: "ByteArray"
        }
      },
      externalId: {
        serializedName: "externalId",
        type: {
          name: "String"
        }
      },
      sector: {
        serializedName: "sector",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3"
          ]
        }
      }
    }
  }
};

export const TimelineSettingDto: msRest.CompositeMapper = {
  serializedName: "TimelineSettingDto",
  type: {
    name: "Composite",
    className: "TimelineSettingDto",
    modelProperties: {
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      timelineSettingId: {
        serializedName: "timelineSettingId",
        type: {
          name: "Number"
        }
      },
      isInstructedMissionsOnly: {
        serializedName: "isInstructedMissionsOnly",
        type: {
          name: "Boolean"
        }
      },
      isActiveMissionOnly: {
        serializedName: "isActiveMissionOnly",
        type: {
          name: "Boolean"
        }
      },
      locations: {
        serializedName: "locations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationSimpleDto"
            }
          }
        }
      },
      locationGroups: {
        serializedName: "locationGroups",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationGroupSimpleDto"
            }
          }
        }
      },
      stereotypes: {
        serializedName: "stereotypes",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TimelineSettingStereotypeDto"
            }
          }
        }
      }
    }
  }
};

export const TimelineSettingListDto: msRest.CompositeMapper = {
  serializedName: "TimelineSettingListDto",
  type: {
    name: "Composite",
    className: "TimelineSettingListDto",
    modelProperties: {
      timelineSettingId: {
        serializedName: "timelineSettingId",
        type: {
          name: "Number"
        }
      },
      isInstructedMissionsOnly: {
        serializedName: "isInstructedMissionsOnly",
        type: {
          name: "Boolean"
        }
      },
      locations: {
        serializedName: "locations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationSimpleListDto"
            }
          }
        }
      },
      locationGroups: {
        serializedName: "locationGroups",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationGroupSimpleListDto"
            }
          }
        }
      },
      stereotypes: {
        serializedName: "stereotypes",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "StereotypeSimpleListDto"
            }
          }
        }
      }
    }
  }
};

export const TokenDto: msRest.CompositeMapper = {
  serializedName: "TokenDto",
  type: {
    name: "Composite",
    className: "TokenDto",
    modelProperties: {
      refreshToken: {
        serializedName: "refreshToken",
        type: {
          name: "String"
        }
      },
      token: {
        serializedName: "token",
        type: {
          name: "String"
        }
      },
      restrictedRoleIds: {
        nullable: true,
        serializedName: "restrictedRoleIds",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Number"
            }
          }
        }
      },
      restrictedLocationIds: {
        nullable: true,
        serializedName: "restrictedLocationIds",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Number"
            }
          }
        }
      },
      info: {
        serializedName: "info",
        type: {
          name: "Composite",
          className: "MeDto"
        }
      }
    }
  }
};

export const UninformedMemberListDto: msRest.CompositeMapper = {
  serializedName: "UninformedMemberListDto",
  type: {
    name: "Composite",
    className: "UninformedMemberListDto",
    modelProperties: {
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      infoId: {
        serializedName: "infoId",
        type: {
          name: "Number"
        }
      },
      contact: {
        serializedName: "contact",
        type: {
          name: "Composite",
          className: "ContactSimpleListDto"
        }
      }
    }
  }
};

export const UserImportDto: msRest.CompositeMapper = {
  serializedName: "UserImportDto",
  type: {
    name: "Composite",
    className: "UserImportDto",
    modelProperties: {
      pin: {
        serializedName: "pin",
        type: {
          name: "String"
        }
      },
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      },
      badge: {
        serializedName: "badge",
        type: {
          name: "String"
        }
      },
      firstName: {
        serializedName: "firstName",
        type: {
          name: "String"
        }
      },
      lastName: {
        serializedName: "lastName",
        type: {
          name: "String"
        }
      },
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      validUntil: {
        serializedName: "validUntil",
        type: {
          name: "String"
        }
      },
      index: {
        serializedName: "index",
        type: {
          name: "Number"
        }
      },
      locations: {
        serializedName: "locations",
        type: {
          name: "String"
        }
      },
      functions: {
        serializedName: "functions",
        type: {
          name: "String"
        }
      },
      locationsBehaviour: {
        serializedName: "locationsBehaviour",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      },
      locationGroups: {
        serializedName: "locationGroups",
        type: {
          name: "String"
        }
      },
      locationGroupsBehaviour: {
        serializedName: "locationGroupsBehaviour",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2"
          ]
        }
      }
    }
  }
};

export const UserIssueEmailTtm: msRest.CompositeMapper = {
  serializedName: "UserIssueEmailTtm",
  type: {
    name: "Composite",
    className: "UserIssueEmailTtm",
    modelProperties: {
      issueId: {
        serializedName: "issueId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      link: {
        serializedName: "link",
        type: {
          name: "String"
        }
      },
      resource: {
        serializedName: "resource",
        type: {
          name: "Composite",
          className: "ResourceTtm"
        }
      },
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "LocationTtm"
        }
      },
      stereotype: {
        serializedName: "stereotype",
        type: {
          name: "Composite",
          className: "StereotypeTtm"
        }
      },
      properties: {
        serializedName: "properties",
        type: {
          name: "Object"
        }
      },
      ticket: {
        serializedName: "ticket",
        type: {
          name: "Composite",
          className: "TicketTtm"
        }
      },
      mission: {
        serializedName: "mission",
        type: {
          name: "Composite",
          className: "MissionTtm"
        }
      }
    }
  }
};

export const UserRoleDto: msRest.CompositeMapper = {
  serializedName: "UserRoleDto",
  type: {
    name: "Composite",
    className: "UserRoleDto",
    modelProperties: {
      userId: {
        serializedName: "userId",
        type: {
          name: "Number"
        }
      },
      roleId: {
        serializedName: "roleId",
        type: {
          name: "Number"
        }
      },
      contactId: {
        serializedName: "contactId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const VirtualizeResourceQueueDto: msRest.CompositeMapper = {
  serializedName: "VirtualizeResourceQueueDto",
  type: {
    name: "Composite",
    className: "VirtualizeResourceQueueDto",
    modelProperties: {
      resourceId: {
        serializedName: "resourceId",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const WeeklyDateTimePatternDto: msRest.CompositeMapper = {
  serializedName: "WeeklyDateTimePatternDto",
  type: {
    name: "Composite",
    className: "WeeklyDateTimePatternDto",
    modelProperties: {
      dateTimePatternId: {
        serializedName: "dateTimePatternId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5"
          ]
        }
      },
      useNextOpeningDay: {
        serializedName: "useNextOpeningDay",
        type: {
          name: "Boolean"
        }
      },
      lastModified: {
        serializedName: "lastModified",
        type: {
          name: "String"
        }
      },
      plannedTimes: {
        serializedName: "plannedTimes",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PlannedTimeDto"
            }
          }
        }
      },
      startDate: {
        serializedName: "startDate",
        type: {
          name: "String"
        }
      },
      endDate: {
        serializedName: "endDate",
        type: {
          name: "String"
        }
      },
      repeat: {
        serializedName: "repeat",
        type: {
          name: "Number"
        }
      },
      useOpeningDays: {
        serializedName: "useOpeningDays",
        type: {
          name: "Boolean"
        }
      },
      monday: {
        serializedName: "monday",
        type: {
          name: "Boolean"
        }
      },
      tuesday: {
        serializedName: "tuesday",
        type: {
          name: "Boolean"
        }
      },
      wednesday: {
        serializedName: "wednesday",
        type: {
          name: "Boolean"
        }
      },
      thursday: {
        serializedName: "thursday",
        type: {
          name: "Boolean"
        }
      },
      friday: {
        serializedName: "friday",
        type: {
          name: "Boolean"
        }
      },
      saturday: {
        serializedName: "saturday",
        type: {
          name: "Boolean"
        }
      },
      sunday: {
        serializedName: "sunday",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const WeeklyDateTimePatternV1Dto: msRest.CompositeMapper = {
  serializedName: "WeeklyDateTimePatternV1Dto",
  type: {
    name: "Composite",
    className: "WeeklyDateTimePatternV1Dto",
    modelProperties: {
      dateTimePatternId: {
        serializedName: "dateTimePatternId",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5"
          ]
        }
      },
      useNextOpeningDay: {
        serializedName: "useNextOpeningDay",
        type: {
          name: "Boolean"
        }
      },
      lastModified: {
        serializedName: "lastModified",
        type: {
          name: "String"
        }
      },
      plannedTimes: {
        serializedName: "plannedTimes",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PlannedTimeV1Dto"
            }
          }
        }
      },
      startDate: {
        serializedName: "startDate",
        type: {
          name: "String"
        }
      },
      endDate: {
        serializedName: "endDate",
        type: {
          name: "String"
        }
      },
      repeat: {
        serializedName: "repeat",
        type: {
          name: "Number"
        }
      },
      useOpeningDays: {
        serializedName: "useOpeningDays",
        type: {
          name: "Boolean"
        }
      },
      monday: {
        serializedName: "monday",
        type: {
          name: "Boolean"
        }
      },
      tuesday: {
        serializedName: "tuesday",
        type: {
          name: "Boolean"
        }
      },
      wednesday: {
        serializedName: "wednesday",
        type: {
          name: "Boolean"
        }
      },
      thursday: {
        serializedName: "thursday",
        type: {
          name: "Boolean"
        }
      },
      friday: {
        serializedName: "friday",
        type: {
          name: "Boolean"
        }
      },
      saturday: {
        serializedName: "saturday",
        type: {
          name: "Boolean"
        }
      },
      sunday: {
        serializedName: "sunday",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const WhitelistedResourceTicketSkeletonSelectorDto: msRest.CompositeMapper = {
  serializedName: "WhitelistedResourceTicketSkeletonSelectorDto",
  type: {
    name: "Composite",
    className: "WhitelistedResourceTicketSkeletonSelectorDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Enum",
          allowedValues: [
            "0",
            "1",
            "2",
            "3",
            "4"
          ]
        }
      },
      whitelist: {
        serializedName: "whitelist",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ResourceSafetySimpleDto"
            }
          }
        }
      }
    }
  }
};

export const WinterhalterIssueEmailTtm: msRest.CompositeMapper = {
  serializedName: "WinterhalterIssueEmailTtm",
  type: {
    name: "Composite",
    className: "WinterhalterIssueEmailTtm",
    modelProperties: {
      issueId: {
        serializedName: "issueId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      link: {
        serializedName: "link",
        type: {
          name: "String"
        }
      },
      resource: {
        serializedName: "resource",
        type: {
          name: "Composite",
          className: "ResourceTtm"
        }
      },
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "LocationTtm"
        }
      }
    }
  }
};

export const WinterhalterProductDetailDto: msRest.CompositeMapper = {
  serializedName: "WinterhalterProductDetailDto",
  type: {
    name: "Composite",
    className: "WinterhalterProductDetailDto",
    modelProperties: {
      productId: {
        serializedName: "productId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      materialNumber: {
        serializedName: "materialNumber",
        type: {
          name: "String"
        }
      },
      productCode: {
        serializedName: "productCode",
        type: {
          name: "String"
        }
      },
      orderPriority: {
        serializedName: "orderPriority",
        type: {
          name: "Number"
        }
      },
      minimumQuantity: {
        serializedName: "minimumQuantity",
        type: {
          name: "Number"
        }
      },
      maxQuantity: {
        serializedName: "maxQuantity",
        type: {
          name: "Number"
        }
      },
      salesQuantity: {
        serializedName: "salesQuantity",
        type: {
          name: "Number"
        }
      },
      containerSize: {
        serializedName: "containerSize",
        type: {
          name: "String"
        }
      },
      urlSafetyFile: {
        serializedName: "urlSafetyFile",
        type: {
          name: "String"
        }
      },
      urlImage: {
        serializedName: "urlImage",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const WinterhalterProductCatalogDto: msRest.CompositeMapper = {
  serializedName: "WinterhalterProductCatalogDto",
  type: {
    name: "Composite",
    className: "WinterhalterProductCatalogDto",
    modelProperties: {
      products: {
        serializedName: "products",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "WinterhalterProductDetailDto"
            }
          }
        }
      }
    }
  }
};

export const WinterhalterProductOrderDto: msRest.CompositeMapper = {
  serializedName: "WinterhalterProductOrderDto",
  type: {
    name: "Composite",
    className: "WinterhalterProductOrderDto",
    modelProperties: {
      productId: {
        serializedName: "productId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      quantity: {
        serializedName: "quantity",
        type: {
          name: "Number"
        }
      },
      materialNumber: {
        serializedName: "materialNumber",
        type: {
          name: "String"
        }
      },
      productCode: {
        serializedName: "productCode",
        type: {
          name: "String"
        }
      },
      containerSize: {
        serializedName: "containerSize",
        type: {
          name: "String"
        }
      },
      urlImage: {
        serializedName: "urlImage",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const WinterhalterProductOrderIssueDto: msRest.CompositeMapper = {
  serializedName: "WinterhalterProductOrderIssueDto",
  type: {
    name: "Composite",
    className: "WinterhalterProductOrderIssueDto",
    modelProperties: {
      issueId: {
        serializedName: "issueId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      products: {
        serializedName: "products",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "WinterhalterProductOrderDto"
            }
          }
        }
      },
      orderId: {
        serializedName: "orderId",
        type: {
          name: "String"
        }
      },
      resource: {
        serializedName: "resource",
        type: {
          name: "Composite",
          className: "ResourceSimpleDto"
        }
      },
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "LocationSimpleDto"
        }
      }
    }
  }
};

export const WinterhalterProductOrderIssueListDto: msRest.CompositeMapper = {
  serializedName: "WinterhalterProductOrderIssueListDto",
  type: {
    name: "Composite",
    className: "WinterhalterProductOrderIssueListDto",
    modelProperties: {
      issueId: {
        serializedName: "issueId",
        type: {
          name: "Number"
        }
      },
      issueType: {
        serializedName: "issueType",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "5"
          ]
        }
      },
      issue: {
        serializedName: "issue",
        type: {
          name: "Composite",
          className: "IssueDetailsListDto"
        }
      },
      orderId: {
        serializedName: "orderId",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const WinterhalterProductOrderIssuePreviewDto: msRest.CompositeMapper = {
  serializedName: "WinterhalterProductOrderIssuePreviewDto",
  type: {
    name: "Composite",
    className: "WinterhalterProductOrderIssuePreviewDto",
    modelProperties: {
      issueTemplateId: {
        serializedName: "issueTemplateId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      contentType: {
        serializedName: "contentType",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "5"
          ]
        }
      }
    }
  }
};

export const WinterhalterServiceCallProblemPreviewDto: msRest.CompositeMapper = {
  serializedName: "WinterhalterServiceCallProblemPreviewDto",
  type: {
    name: "Composite",
    className: "WinterhalterServiceCallProblemPreviewDto",
    modelProperties: {
      key: {
        serializedName: "key",
        type: {
          name: "String"
        }
      },
      translation: {
        serializedName: "translation",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const WinterhalterServiceCallCategoryPreviewDto: msRest.CompositeMapper = {
  serializedName: "WinterhalterServiceCallCategoryPreviewDto",
  type: {
    name: "Composite",
    className: "WinterhalterServiceCallCategoryPreviewDto",
    modelProperties: {
      key: {
        serializedName: "key",
        type: {
          name: "String"
        }
      },
      translation: {
        serializedName: "translation",
        type: {
          name: "String"
        }
      },
      problems: {
        serializedName: "problems",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "WinterhalterServiceCallProblemPreviewDto"
            }
          }
        }
      }
    }
  }
};

export const WinterhalterServiceCallIssueDto: msRest.CompositeMapper = {
  serializedName: "WinterhalterServiceCallIssueDto",
  type: {
    name: "Composite",
    className: "WinterhalterServiceCallIssueDto",
    modelProperties: {
      issueId: {
        serializedName: "issueId",
        type: {
          name: "Number"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      tenantId: {
        serializedName: "tenantId",
        type: {
          name: "Number"
        }
      },
      problem: {
        serializedName: "problem",
        type: {
          name: "String"
        }
      },
      category: {
        serializedName: "category",
        type: {
          name: "String"
        }
      },
      message: {
        serializedName: "message",
        type: {
          name: "String"
        }
      },
      contactFirstName: {
        serializedName: "contactFirstName",
        type: {
          name: "String"
        }
      },
      contactLastName: {
        serializedName: "contactLastName",
        type: {
          name: "String"
        }
      },
      orderId: {
        serializedName: "orderId",
        type: {
          name: "String"
        }
      },
      resource: {
        serializedName: "resource",
        type: {
          name: "Composite",
          className: "ResourceSimpleDto"
        }
      },
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "LocationSimpleDto"
        }
      }
    }
  }
};

export const WinterhalterServiceCallIssueListDto: msRest.CompositeMapper = {
  serializedName: "WinterhalterServiceCallIssueListDto",
  type: {
    name: "Composite",
    className: "WinterhalterServiceCallIssueListDto",
    modelProperties: {
      issueId: {
        serializedName: "issueId",
        type: {
          name: "Number"
        }
      },
      issueType: {
        serializedName: "issueType",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "5"
          ]
        }
      },
      issue: {
        serializedName: "issue",
        type: {
          name: "Composite",
          className: "IssueDetailsListDto"
        }
      },
      orderId: {
        serializedName: "orderId",
        type: {
          name: "String"
        }
      },
      problem: {
        serializedName: "problem",
        type: {
          name: "String"
        }
      },
      category: {
        serializedName: "category",
        type: {
          name: "String"
        }
      },
      message: {
        serializedName: "message",
        type: {
          name: "String"
        }
      },
      contactFirstName: {
        serializedName: "contactFirstName",
        type: {
          name: "String"
        }
      },
      contactLastName: {
        serializedName: "contactLastName",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const WinterhalterServiceCallIssuePreviewDto: msRest.CompositeMapper = {
  serializedName: "WinterhalterServiceCallIssuePreviewDto",
  type: {
    name: "Composite",
    className: "WinterhalterServiceCallIssuePreviewDto",
    modelProperties: {
      issueTemplateId: {
        serializedName: "issueTemplateId",
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      contentType: {
        serializedName: "contentType",
        type: {
          name: "Enum",
          allowedValues: [
            "1",
            "2",
            "3",
            "4",
            "5"
          ]
        }
      },
      categories: {
        serializedName: "categories",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "WinterhalterServiceCallCategoryPreviewDto"
            }
          }
        }
      }
    }
  }
};
