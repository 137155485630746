import {Brands, Environment} from '@nexnox-web/core-shared';

export const environment: Environment = {
  production: false,
  title: 'semco ONE',
  version: '20250327.5',
  brand: Brands.Semco,
  apiUrl: {
    identity: 'http://localhost:64961',
    tech: 'http://localhost:64962',
    orga: 'http://localhost:64963',
    data: 'http://localhost:64964'
  },
  google: {
    apiKey: 'AIzaSyBINOesU6mmaz46mq5tDVKlfUBCfKXnEnA'
  },
  defaultPageSize: 15,
  defaultLanguage: 'de-DE',
  defaultTimeout: 120000
};
