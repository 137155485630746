import {Injectable} from '@angular/core';
import {
  AppEntityType,
  AppPermissions,
  ControllerOperationId,
  CustomDropDownDefaultValueDto,
  CustomPropertyType,
  DataTableColumnDto,
  DataTableColumnType,
  DataTableCustomColumnDto,
  DataTableDto,
  DataTableTransferColumnDto,
  LocalStorageKeys,
  Mappers,
  StereotypeDto,
  UnsubscribeHelper
} from '@nexnox-web/core-shared';
import {
  DatatablePreColumn,
  DatatableTableColumn,
  DatatableTableColumnOption,
  DatatableTableColumnType,
  DatatableTableColumnTyping,
  getAllNestedProperties
} from '../../models';
import {cloneDeep, isUndefined, sortBy} from 'lodash';
import {SimpleDatatableFilterField} from '../datatable-filter/datatable-filter.service';
import {CompositeMapper} from '@azure/ms-rest-js';
import mapperMetadata from '@nexnox-web/core-shared/models/metadata';
import {select, Store} from '@ngrx/store';
import {authStore} from '../../../../../store/core/auth';
import {DatatableViewStorage} from "@nexnox-web/core-store";
import {CorePortalTenantLocalStorageService} from '../../../../../services';

@Injectable()
export class CorePortalDatatableColumnService extends UnsubscribeHelper {
  private permissions: AppPermissions[];

  constructor(
    private store: Store<any>,
    private tenantLocalStorageService: CorePortalTenantLocalStorageService
  ) {
    super();

    this.subscribe(this.store.pipe(select(authStore.selectors.selectPermissions)), permissions => {
      this.permissions = permissions;
    });
  }


  public async getDatatableConfig(
    pageOperationOrEntityType: ControllerOperationId | AppEntityType,
    componentId: string,
    datatableConfig: DataTableDto,
    isEntityType: boolean = false
  ): Promise<DataTableDto> {
    const datatableViewStorage: DatatableViewStorage = await this.tenantLocalStorageService.get(LocalStorageKeys.DATATABLE_VIEWS);
    const datatableViewsForPageOperation =
      datatableViewStorage ? datatableViewStorage[isEntityType ? `entity_${pageOperationOrEntityType}` : pageOperationOrEntityType] : null;
    const datatableView: DataTableDto = datatableViewsForPageOperation ? datatableViewsForPageOperation[componentId] : null;

    if ((datatableConfig && !datatableView) || (!datatableConfig && datatableView)) {
      return Promise.resolve(datatableConfig ? datatableConfig : datatableView);
    } else {
      return Promise.resolve(datatableConfig);
    }
  }

  public async saveDatatableConfig(
    pageOperationOrEntityType: ControllerOperationId | AppEntityType,
    componentId: string,
    datatableConfig: DataTableDto,
    isEntityType: boolean = false
  ): Promise<void> {
    const datatableViews = (await this.tenantLocalStorageService.get(LocalStorageKeys.DATATABLE_VIEWS)) ?? {};
    const identifier = isEntityType ? `entity_${pageOperationOrEntityType}` : pageOperationOrEntityType;
    await this.tenantLocalStorageService.set(LocalStorageKeys.DATATABLE_VIEWS, {
      ...datatableViews,
      [identifier]: {
        ...(datatableViews[identifier] ?? {}),
        [componentId]: datatableConfig
      }
    });
    return Promise.resolve();
  }

  public async modifyDatatableConfig(
    changes: Partial<DataTableDto>,
    pageOperationOrEntityType: ControllerOperationId | AppEntityType,
    componentId: string,
    datatableConfig: DataTableDto,
    isEntityType: boolean = false
  ): Promise<void> {
    const config = await this.getDatatableConfig(
      pageOperationOrEntityType,
      componentId,
      datatableConfig,
      isEntityType
    );
    await this.saveDatatableConfig(
      pageOperationOrEntityType,
      componentId,
      {...config, ...changes},
      isEntityType
    );
  }

  // --- Data --- //

  public getDataColumns(mapperOrSerializedName: CompositeMapper | string, excludedColumns: string[] = []): DatatablePreColumn[] {
    const mapper = typeof mapperOrSerializedName === 'string' ? Mappers[mapperOrSerializedName] : mapperOrSerializedName;
    const columns: DatatablePreColumn[] = [];
    const mappedColumns = this.getColumns(mapper);

    for (const column of mappedColumns) {
      if (!excludedColumns.includes(column.name)) {
        columns.push(column);
      }
    }

    return columns;
  }

  public addDataColumns(
    preColumns: DatatablePreColumn[] = [],
    columnTypings: DatatableTableColumnTyping[] = [],
    filterList: SimpleDatatableFilterField[] = [],
    displayProp: string
  ): DatatableTableColumn[] {
    return this.addColumns(preColumns, columnTypings, filterList, DatatableTableColumnOption.DATA, false, displayProp);
  }

  public async getActiveDataColumns(
    dataColumns: DatatableTableColumn[],
    entityType: AppEntityType,
    config: DataTableDto,
    defaultActiveColumns: string[] = []
  ): Promise<DatatableTableColumn[]> {
    let activeDataColumns: DatatableTableColumn[] = [];

    if (config?.columns) {
      activeDataColumns = dataColumns;
      const dataColumnsFromConfig = config.columns
        .filter(x => this.isDataColumn(x))
        .map(x => x as DataTableTransferColumnDto);
      activeDataColumns = activeDataColumns
        .filter(x => dataColumnsFromConfig.find(y => y.property === x.prop))
        .map(x => ({
          ...x,
          position: dataColumnsFromConfig.find(y => y.property === x.prop)?.position
        }));
    } else {
      activeDataColumns.push(...sortBy(dataColumns.filter(x => defaultActiveColumns.includes(x.prop.toString())), item => {
        return defaultActiveColumns.findIndex(x => x === item.prop.toString());
      }));
    }

    return activeDataColumns;
  }

  // --- Stereotypes --- //

  public getStereotypeColumns(stereotypes: StereotypeDto[]): DatatablePreColumn[] {
    const stereotypeColumns: DatatablePreColumn[] = [];

    for (const stereotype of stereotypes) {
      for (const customPropertySet of stereotype.customPropertySets?.filter(cps => cps.isInherited !== true) ?? []) {
        for (const customProperty of customPropertySet.customPropertySet?.properties ?? []) {
          stereotypeColumns.push({
            customPropertyId: customProperty.customPropertyId,
            name: customProperty.name,
            customPropertyType: customProperty.type,
            defaultValues: customProperty.defaultValues,
            isOfArchivedStereotype: stereotype.isArchived
          });
        }
      }
    }

    return stereotypeColumns;
  }

  public addStereotypeColumns(
    preColumns: DatatablePreColumn[],
    filterList: SimpleDatatableFilterField[]
  ): DatatableTableColumn[] {
    return preColumns.map(preColumn => {
      let type: DatatableTableColumnType;

      switch (preColumn.customPropertyType) {
        case CustomPropertyType.Numeric:
          type = DatatableTableColumnType.NUMBER;
          break;
        case CustomPropertyType.Checkbox:
          type = DatatableTableColumnType.BOOLEAN;
          break;
        case CustomPropertyType.Dropdown:
          type = DatatableTableColumnType.ENUM;
          break;
        case CustomPropertyType.Date:
          type = DatatableTableColumnType.DATE;
          break;
        case CustomPropertyType.TimeOfDay:
          type = DatatableTableColumnType.TIME;
          break;
        case CustomPropertyType.Phone:
          type = DatatableTableColumnType.PHONE;
          break;
        default:
          type = DatatableTableColumnType.TEXT;
          break;
      }

      const column: DatatableTableColumn = {
        name: preColumn.name,
        prop: 'customPropertyValues',
        customPropertyId: preColumn.customPropertyId,
        sortable: false,
        option: DatatableTableColumnOption.STEREOTYPE,
        hasFilter: filterList.findIndex(
          x => x.property.toLowerCase() === preColumn.customPropertyId?.toString().toLowerCase()
        ) > -1,
        isOfArchivedStereotype: preColumn.isOfArchivedStereotype,
        type
      };

      if (type === DatatableTableColumnType.NUMBER) {
        column.width = 150;
        column.minWidth = 150;
        column.maxWidth = 150;
      }

      if (type === DatatableTableColumnType.DATE) {
        column.width = 175;
        column.minWidth = 175;
        column.maxWidth = 175;
      }

      if (type === DatatableTableColumnType.ENUM) {
        column.enumOptions = (preColumn.defaultValues as CustomDropDownDefaultValueDto[])?.map(defaultValue => ({
          label: defaultValue.value,
          value: defaultValue.customValueId.toString()
        })) ?? [];
      }

      return column;
    });
  }

  public async getActiveStereotypeColumns(
    stereotypeColumns: DatatableTableColumn[],
    entityType: AppEntityType,
    config: DataTableDto
  ): Promise<DatatableTableColumn[]> {
    let activeStereotypeColumns: DatatableTableColumn[] = [];

    if (config?.columns) {
      activeStereotypeColumns = stereotypeColumns;
      const stereotypeColumnsFromConfig = (config.columns)
        .filter(x => this.isStereotypeColumn(x))
        .map(x => x as DataTableCustomColumnDto);
      activeStereotypeColumns = activeStereotypeColumns
        .filter(x => stereotypeColumnsFromConfig.find(y => y.customPropertyId === x.customPropertyId))
        .map(x => ({
          ...x,
          position: stereotypeColumnsFromConfig.find(y => y.customPropertyId === x.customPropertyId)?.position
        }));
    }

    return activeStereotypeColumns;
  }

  // --- Optional --- //

  public addOptionalColumns(
    preColumns: DatatablePreColumn[],
    columnTypings: DatatableTableColumnTyping[],
    filterList: SimpleDatatableFilterField[],
    disableSorting: boolean
  ): DatatableTableColumn[] {
    return this.addColumns(
      preColumns,
      columnTypings,
      filterList,
      DatatableTableColumnOption.OPTIONAL,
      disableSorting
    );
  }

  public async getActiveOptionalColumns(
    optionalColumns: DatatableTableColumn[],
    entityType: AppEntityType,
    config: DataTableDto,
    defaultActiveColumns: string[] = []
  ): Promise<DatatableTableColumn[]> {
    let activeOptionalColumns: DatatableTableColumn[] = [];

    if (config?.columns) {
      activeOptionalColumns = optionalColumns;
      const optionalColumnsFromConfig = (config?.columns ?? [])
        .filter(x => this.isOptionalColumn(x))
        .map(x => x as DataTableTransferColumnDto);
      activeOptionalColumns = activeOptionalColumns
        .filter(x => optionalColumnsFromConfig.find(y => y.property === x.prop))
        .map(x => ({...x, position: optionalColumnsFromConfig.find(y => y.property === x.prop)?.position}));
    } else {
      activeOptionalColumns.push(...sortBy(optionalColumns.filter(x => defaultActiveColumns.includes(x.prop.toString())), item => {
        return defaultActiveColumns.findIndex(x => x === item.prop.toString());
      }));
    }

    return activeOptionalColumns;
  }

  // --- Helpers --- //

  public isDataColumn(column: DataTableColumnDto): column is DataTableTransferColumnDto {
    return column.type === DataTableColumnType.ByTransfer;
  }

  public isStereotypeColumn(column: DataTableColumnDto): column is DataTableCustomColumnDto {
    return column.type === DataTableColumnType.ByCustomProperty;
  }

  public isOptionalColumn(column: DataTableColumnDto): column is DataTableTransferColumnDto {
    return column.type === DataTableColumnType.ByTransfer;
  }

  private addColumns(
    preColumns: DatatablePreColumn[],
    columnTypings: DatatableTableColumnTyping[],
    filterList: SimpleDatatableFilterField[],
    option: DatatableTableColumnOption,
    disableSorting: boolean = false,
    displayProp?: string
  ): DatatableTableColumn[] {
    const newPreColumns = cloneDeep(preColumns);

    if (option === DatatableTableColumnOption.DATA) {
      for (const columnTyping of columnTypings) {
        if (columnTyping.addIfNotAvailable && !newPreColumns.find(x => x.name === columnTyping.key)) {
          newPreColumns.push({name: columnTyping.key, filterable: false, sortable: false});
        }
      }
    }

    return newPreColumns.map(preColumn => {
      const columnTyping = columnTypings.find(x => x.key === preColumn.name);
      const column: DatatableTableColumn = {
        name: preColumn.name,
        prop: preColumn.name,
        sortable: option !== DatatableTableColumnOption.STEREOTYPE &&
          !disableSorting &&
          (!isUndefined(preColumn.sortable) ? preColumn.sortable : true) &&
          !columnTyping?.disableSorting,
        option,
        hasFilter: filterList.findIndex(x => x.property.toLowerCase() === preColumn.name.toLowerCase()) > -1,
        optional: Boolean(preColumn.optional),
      };

      if (column.prop.toString().toLowerCase().endsWith('id')) {
        column.width = 150;
        column.minWidth = 150;
        column.maxWidth = 150;
      }

      if (column.prop === displayProp) {
        column.minWidth = 300;
      }

      if (columnTyping) {
        column.type = columnTyping.type;
        column.clickable = columnTyping.clickable;

        if (columnTyping.name) {
          column.name = columnTyping.name;
        }

        if (columnTyping.prependIcon) {
          column.prependIcon = columnTyping.prependIcon;
        }

        if (columnTyping.disableFilter) {
          column.disableFilter = true;
        }

        switch (columnTyping.type) {
          case DatatableTableColumnType.BOOLEAN:
            column.trueLabel = columnTyping.trueLabel;
            column.falseLabel = columnTyping.falseLabel;
            break;
          case DatatableTableColumnType.ENUM:
            column.enumOptions = columnTyping.enumOptions;
            break;
          case DatatableTableColumnType.PROGRESSBAR:
            column.totalKey = columnTyping.totalKey;
            break;
          case DatatableTableColumnType.DATE:
            column.utc = columnTyping.utc;
            column.width = 175;
            column.minWidth = 175;
            column.maxWidth = 175;

            if (columnTyping.format) {
              column.format = columnTyping.format;
            }

            break;
          case DatatableTableColumnType.REFERENCE:
            column.idKey = columnTyping.idKey;
            column.displayKey = columnTyping.displayKey;
            column.multiple = columnTyping.multiple;
            column.separator = columnTyping.separator;
            column.mapValues = columnTyping.mapValues;
            column.additionalSearchProperties = columnTyping.additionalSearchProperties;
            column.service = columnTyping.service;
            column.filterProp = columnTyping.filterProp;
            column.template = columnTyping.template;
            column.filters$ = columnTyping.filters$;
            column.link = columnTyping.link;
            column.withoutModule = columnTyping.withoutModule;
            column.module = columnTyping.module;
            column.fragment = columnTyping.fragment;
            column.sortable = false;
            column.alternateFilter = columnTyping.alternate;
            column.labelTitleTemplateKey = columnTyping.labelTitleTemplateKey;
            column.optionTitleTemplateKey = columnTyping.optionTitleTemplateKey;

            if (columnTyping.permissions?.length) {
              column.disableFilter = !columnTyping.permissions.every(permission => (this.permissions ?? []).includes(permission));
            }

            if (columnTyping.linkPermissions?.length) {
              column.link = columnTyping.linkPermissions.every(permission => (this.permissions ?? []).includes(permission)) ?
                column.link : null;
            }

            break;
          case DatatableTableColumnType.ARRAY:
            column.displayKey = columnTyping.displayKey;
            column.joinSymbol = columnTyping.joinSymbol;
            break;

          case DatatableTableColumnType.HTML:
            column.filterProp = columnTyping.filterProp;
            break;
        }

        if (columnTyping.fixedWidth) {
          column.width = columnTyping.fixedWidth;
          column.minWidth = columnTyping.fixedWidth;
          column.maxWidth = columnTyping.fixedWidth;
        }

        if (columnTyping.maxHeight) {
          column.maxHeight = columnTyping.maxHeight;
        }

        if (columnTyping.hideByDefault) column.hideByDefault = columnTyping.hideByDefault;
      }

      return column;
    });
  }

  private getColumns(mapper: CompositeMapper): { name: string, optional: boolean, entityType: AppEntityType }[] {
    return getAllNestedProperties(mapper, mapperMetadata);
  }
}
