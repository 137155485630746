import {
  issueListStore,
  IssueListStoreState,
  messageIssueDetailStore,
  MessageIssueDetailStoreState,
  messageIssueListStore,
  MessageIssueListStoreState,
  missionIssueDetailStore,
  MissionIssueDetailStoreState,
  missionIssueListStore,
  MissionIssueListStoreState,
  ticketIssueDetailStore,
  TicketIssueDetailStoreState,
  ticketIssueListStore,
  TicketIssueListStoreState,
  winterhalterProductOrderIssueDetailStore,
  WinterhalterProductOrderIssueDetailStoreState,
  winterhalterProductOrderIssueListStore,
  WinterhalterProductOrderIssueListStoreState,
  winterhalterServiceCallIssueDetailStore,
  WinterhalterServiceCallIssueDetailStoreState,
  winterhalterServiceCallIssueListStore,
  WinterhalterServiceCallIssueListStoreState
} from './stores';

export interface IssuesState {
  issueList: IssueListStoreState;
  messageIssueList: MessageIssueListStoreState;
  missionIssueList: MissionIssueListStoreState;
  ticketIssueList: TicketIssueListStoreState;
  winterhalterServiceCallIssueList: WinterhalterServiceCallIssueListStoreState;
  winterhalterProductOrderIssueList: WinterhalterProductOrderIssueListStoreState;

  messageIssueDetail: MessageIssueDetailStoreState;
  missionIssueDetail: MissionIssueDetailStoreState;
  ticketIssueDetail: TicketIssueDetailStoreState;
  winterhalterServiceCallIssueDetail: WinterhalterServiceCallIssueDetailStoreState;
  winterhalterProductOrderIssueDetail: WinterhalterProductOrderIssueDetailStoreState;
}

export const getInitialIssuesState = (): IssuesState => ({
  issueList: issueListStore.getInitialState(),
  messageIssueList: messageIssueListStore.getInitialState(),
  missionIssueList: missionIssueListStore.getInitialState(),
  ticketIssueList: ticketIssueListStore.getInitialState(),
  winterhalterServiceCallIssueList: winterhalterServiceCallIssueListStore.getInitialState(),
  winterhalterProductOrderIssueList: winterhalterProductOrderIssueListStore.getInitialState(),

  messageIssueDetail: messageIssueDetailStore.getInitialState(),
  missionIssueDetail: missionIssueDetailStore.getInitialState(),
  ticketIssueDetail: ticketIssueDetailStore.getInitialState(),
  winterhalterServiceCallIssueDetail: winterhalterServiceCallIssueDetailStore.getInitialState(),
  winterhalterProductOrderIssueDetail: winterhalterProductOrderIssueDetailStore.getInitialState()
});
