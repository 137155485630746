<div [ngSwitch]="filterType?.type"
     class="d-flex">
  <ng-container *ngSwitchCase="columnType.TEXT">
    <div class="input-group flex-nowrap mt-0">
      <ng-container *ngTemplateOutlet="textInput"></ng-container>

      <div class="input-group-append mt-0">
        <ng-container *ngTemplateOutlet="textFilter"></ng-container>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="columnType.NUMBER">
    <div class="input-group flex-nowrap mt-0">
      <ng-container *ngTemplateOutlet="numberInput"></ng-container>

      <div class="input-group-append mt-0">
        <ng-container *ngTemplateOutlet="directionFilter"></ng-container>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="columnType.ARRAY">
    <ng-container *ngTemplateOutlet="textInput"></ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="columnType.BOOLEAN">
    <ng-container *ngTemplateOutlet="booleanInput"></ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="columnType.DATE">
    <ng-container *ngTemplateOutlet="dateInput"></ng-container>

    <div class="input-group-append mt-0">
      <ng-container *ngTemplateOutlet="directionFilter"></ng-container>
    </div>

    <div *ngIf="allowRelative && !isRelative" class="input-group-append mt-0">
      <ng-container *ngTemplateOutlet="relativeFilterButton"></ng-container>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="columnType.ENUM">
    <ng-container *ngTemplateOutlet="enumInput"></ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="columnType.REFERENCE">
    <ng-container *ngTemplateOutlet="referenceInput"></ng-container>

    <div *ngIf="allowRelative && !isRelative" class="input-group-append mt-0">
      <ng-container *ngTemplateOutlet="relativeFilterButton"></ng-container>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="columnType.PATH">
    <ng-container *ngTemplateOutlet="pathInput"></ng-container>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <input type="text" class="w-100 form-control" [disabled]="true"/>
  </ng-container>
</div>

<ng-template #textInput>
  <input type="text"
         class="w-100 form-control min-w-50"
         [class.is-invalid]="showError"
         [disabled]="disabled"
         [ngModel]="currentValue$ | async"
         (ngModelChange)="onChangeFilter($event)"/>
</ng-template>

<ng-template #numberInput>
  <input type="number"
         class="w-100 form-control min-w-50"
         [class.is-invalid]="showError"
         [disabled]="disabled"
         [ngModel]="currentValue$ | async"
         (ngModelChange)="onChangeFilter($event)"/>
</ng-template>

<ng-template #booleanInput>
  <ng-select class="ng-form-select block"
             bindValue="value"
             appendTo="body"
             [class.is-invalid]="showError"
             [items]="booleanItems"
             [clearable]="clearable"
             [searchable]="false"
             [disabled]="disabled"
             [clearAllText]="'core-shared.shared.select.clear' | translate"
             [ngModel]="currentValue$ | async"
             (ngModelChange)="onChangeFilter($event)">
    <ng-template ng-label-tmp let-item="item">
      <ng-container [ngTemplateOutlet]="valueTemplate" [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
    </ng-template>

    <ng-template ng-option-tmp let-item="item">
      <span [title]="item.label | translate">
        <span><ng-container [ngTemplateOutlet]="valueTemplate"
                            [ngTemplateOutletContext]="{ $implicit: item }">
        </ng-container></span>
      </span>
    </ng-template>
  </ng-select>

  <ng-template #valueTemplate let-item>
    <ng-container *ngIf="item.value === 'true'; else falseTemplate">
      <ng-container *ngIf="column.trueLabel; else defaultTemplate">{{ column.trueLabel | translate }}</ng-container>
    </ng-container>

    <ng-template #falseTemplate>
      <ng-container *ngIf="column.falseLabel; else defaultTemplate">{{ column.falseLabel | translate }}</ng-container>
    </ng-template>

    <ng-template #defaultTemplate>
      {{ item.label | translate }}
    </ng-template>
  </ng-template>
</ng-template>

<ng-template #dateInput>
  <input *ngIf="!isRelative; else relativeDateInput"
         bsDatepicker
         type="text"
         class="form-control min-w-50"
         autocomplete="false"
         [class.is-invalid]="showError"
         [disabled]="disabled"
         [bsConfig]="bsConfig"
         [ngModel]="currentValue$ | async"
         (ngModelChange)="onChangeDateFilter($event)">

  <ng-template #relativeDateInput>
    <nexnox-web-time-picker class="d-flex flex-grow-1"
                            mode="timespan"
                            [hasError]="showError"
                            [canClear]="clearable"
                            [allowNegative]="true"
                            [showYears]="true"
                            [showWeeks]="true"
                            [showDays]="true"
                            [showHours]="false"
                            [showMinutes]="false"
                            [ngModel]="currentValue$ | async"
                            (ngModelChange)="onChangeRelativeDateFilter($event)">
    </nexnox-web-time-picker>
  </ng-template>
</ng-template>

<ng-template #enumInput>
  <ng-select #selectComponent
             class="ng-form-select block"
             appendTo="body"
             [class.inline]="!isDatatableSettings"
             [class.is-invalid]="showError"
             [items]="filterType.values"
             [clearable]="clearable"
             [searchable]="true"
             [searchFn]="ngSelectSearchFn"
             [compareWith]="ngSelectCompareFn"
             [multiple]="true"
             [closeOnSelect]="false"
             [clearAllText]="'core-shared.shared.select.clear' | translate"
             [disabled]="disabled"
             [ngModel]="currentValue$ | async"
             (ngModelChange)="onChangeFilter($event)">
    <ng-template ng-header-tmp let-searchTerm="searchTerm">
      <div class="d-flex align-items-center">
        <input id="searchInput"
               nexnoxWebAutofocus
               type="text"
               class="form-control min-w-50"
               [placeholder]="'core-shared.shared.select.search' | translate"
               [ngModel]="searchTerm"
               (ngModelChange)="selectComponent.filter($event)">

        <button *ngIf="searchTerm"
                class="btn"
                [title]="'core-shared.shared.select.clear' | translate"
                (click)="selectComponent.filter(null)">
          <fa-icon [icon]="faTimes"></fa-icon>
        </button>
      </div>
    </ng-template>

    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
      <ng-container *ngIf="items.length < 2 || isDatatableSettings">
        <div *ngFor="let item of !isDatatableSettings ? (items | slice:0:1) : items"
             class="d-flex flex-row align-items-center multi-select-container px-2"
             [class.mr-2]="isDatatableSettings"
             [class.mb-1]="isDatatableSettings">
          <span class="multi-select-clear ng-clear mr-2"
                [title]="'core-shared.shared.select.clear' | translate"
                (click)="clear(item)">
            ×
          </span>

          <span *ngIf="item.label" [title]="item.label | translate"
                class="default-text-container">
            <span>{{ item.label | translate }}</span>
          </span>
        </div>
      </ng-container>

      <div *ngIf="items.length > 1 && !isDatatableSettings"
           class="d-flex flex-row align-items-center multi-select-container px-2">
        <span>{{ 'core-shared.shared.select.selected' | translate:{amount: items.length} }}</span>
      </div>
    </ng-template>

    <ng-template ng-option-tmp let-item="item" let-searchTerm="searchTerm">
      <span [title]="item.label | translate">
        <span [innerHTML]="item.label | translate | nexnoxWebHighlight:searchTerm"></span>
      </span>
    </ng-template>

    <ng-template ng-notfound-tmp>
      <div class="ng-option disabled">
        {{ 'core-shared.shared.table.empty' | translate }}
      </div>
    </ng-template>
  </ng-select>
</ng-template>

<ng-template #referenceInput>
  <nexnox-web-entity-select *ngIf="!isRelative && !useAlternate; else alternateInput"
                            class="w-100"
                            [options]="referenceEntitySelectOptions"
                            [disabled]="disabled"
                            [showError]="showError"
                            [model]="currentValue$ | async"
                            (modelChange)="onChangeFilter($event)">
  </nexnox-web-entity-select>

  <ng-template #alternateInput>
    <nexnox-web-entity-select *ngIf="useAlternate && !isRelative; else relativeContactCheckboxTemplate"
                              class="w-100"
                              [options]="alternateReferenceEntitySelectOptions"
                              [disabled]="disabled"
                              [showError]="showError"
                              [model]="currentValue$ | async"
                              (modelChange)="onChangeFilter($event)">
    </nexnox-web-entity-select>
  </ng-template>

  <ng-template #relativeContactCheckboxTemplate>
    <p-checkbox [(ngModel)]="isRelative"
                [styleClass]="'reference-relative-checkbox'"
                [disabled]="true"
                [binary]="true"
                [checkboxIcon]="faCheck | nexnoxWebFaIconStringPipe">
    </p-checkbox>
    <span>{{ 'core-portal.core.sidebar.menu.settings.relative-me' | translate }}</span>
  </ng-template>

  <div *ngIf="!isRelative && column.alternateFilter" class="d-flex align-items-center ml-2">
    <p-toggleButton styleClass="p-togglebutton-primary form-height"
                    [ngModel]="useAlternate"
                    [pTooltip]="'core-shared.shared.table.tooltip.use-group' | translate"
                    [onIcon]="faLayerGroup | nexnoxWebFaIconStringPipe"
                    [offIcon]="faLayerGroup | nexnoxWebFaIconStringPipe"
                    [disabled]="disabled"
                    (ngModelChange)="onChangeAlternate($event)">
    </p-toggleButton>
  </div>
</ng-template>

<ng-template #pathInput>
  <nexnox-web-entity-select class="w-100"
                            [options]="pathEntitySelectOptions"
                            [disabled]="disabled"
                            [showError]="showError"
                            [model]="currentPathValue$ | async"
                            (modelChange)="onChangePathFilter($event)">
  </nexnox-web-entity-select>
</ng-template>

<ng-template #directionFilter>
  <ng-select class="ng-form-select filter-operator"
             bindValue="value"
             appendTo="body"
             [items]="directions"
             [clearable]="false"
             [searchable]="false"
             [disabled]="disabled"
             [ngModel]="currentDirection"
             (ngModelChange)="onChangeDirection($event)">
    <ng-template ng-label-tmp let-item="item">
      <div *ngIf="item.icon as icon">
        <fa-icon [class.ml-2]="icon" [icon]="icon" size="xs"></fa-icon>
      </div>
    </ng-template>

    <ng-template ng-option-tmp let-item="item">
      <div *ngIf="item.icon as icon" [title]="item.title | translate">
        <fa-icon [icon]="icon" size="xs"></fa-icon>
      </div>
    </ng-template>
  </ng-select>
</ng-template>

<ng-template #textFilter>
  <ng-select class="ng-form-select filter-operator"
             bindValue="value"
             appendTo="body"
             [items]="textFilters"
             [clearable]="false"
             [searchable]="false"
             [disabled]="disabled"
             [dropdownPosition]="'auto'"
             [ngModel]="currentDirection"
             (ngModelChange)="onChangeDirection($event)">
    <ng-template ng-label-tmp let-item="item">
      <div *ngIf="item.icon as icon">
        <fa-icon [class.ml-2]="icon" [icon]="icon" size="xs"></fa-icon>
      </div>
    </ng-template>

    <ng-template ng-option-tmp let-item="item">
      <div *ngIf="item.icon as icon" [title]="item.title | translate">
        <fa-icon [icon]="icon" size="xs"></fa-icon>
      </div>
    </ng-template>
  </ng-select>
</ng-template>

<ng-template #relativeFilterButton>
  <div class="d-flex align-items-center ml-2">
    <p-toggleButton styleClass="p-togglebutton-primary form-height"
                    tooltipPosition="top"
                    [ngModel]="isRelative"
                    [disabled]="disabled"
                    [pTooltip]="'core-shared.shared.table.tooltip.is-relative' | translate"
                    [onIcon]="relativeFilterButtonIcon ? (relativeFilterButtonIcon | nexnoxWebFaIconStringPipe) : null"
                    [offIcon]="relativeFilterButtonIcon ? (relativeFilterButtonIcon | nexnoxWebFaIconStringPipe) : null"
                    (ngModelChange)="onChangeRelative($event)">
    </p-toggleButton>
  </div>
</ng-template>

