import { ResourceEntitiesXsStore, ResourceEntitiesXsStoreState } from './resource-entities.xs-store';
import { Action, ActionReducer, createSelector } from '@ngrx/store';
import { selectResourcesState } from '../../resources.selectors';
import { Injectable, Injector } from '@angular/core';

export interface ResourceEntitiesStoreState extends ResourceEntitiesXsStoreState {
}

export const resourceEntitiesStore = new ResourceEntitiesXsStore({
  actionLabel: 'Core Portal - Resources - Resource Entities',
  stateSelector: createSelector(selectResourcesState, state => state.resourceEntities)
});

export function resourceEntitiesStoreReducer(state: ResourceEntitiesStoreState, action: Action): any {
  return resourceEntitiesStore.reducer(state, action);
}

@Injectable()
export class ResourceEntitiesStoreEffects extends resourceEntitiesStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
