import {
  BaseXsStoreReducerTypes,
  PagedEntitiesXsStore,
  PagedEntitiesXsStoreActions,
  PagedEntitiesXsStoreEffects,
  PagedEntitiesXsStoreState
} from '@nexnox-web/core-store';
import {AppEntityType, WinterhalterProductOrderIssueListDto} from '@nexnox-web/core-shared';
import {Action} from '@ngrx/store';
import {Injectable, Injector, Type} from '@angular/core';
import {WinterhalterProductOrderIssueService} from "@nexnox-web/tech-portal/features/issues/src/lib/store";
// import {TechPortalIssueTemplateService} from '@nexnox-web/tech-portal-lib';

export interface WinterhalterProductOrderIssueListXsStoreActions extends PagedEntitiesXsStoreActions<WinterhalterProductOrderIssueListDto> {
}

export class WinterhalterProductOrderIssueListXsStore extends PagedEntitiesXsStore<WinterhalterProductOrderIssueListDto> {
  public actions: WinterhalterProductOrderIssueListXsStoreActions;

  protected createActions(label: string): WinterhalterProductOrderIssueListXsStoreActions {
    return {
      ...super.createActions(label)
    };
  }

  protected createReducerArray(
    initialState: PagedEntitiesXsStoreState<WinterhalterProductOrderIssueListDto>
  ): BaseXsStoreReducerTypes<PagedEntitiesXsStoreState<WinterhalterProductOrderIssueListDto>, WinterhalterProductOrderIssueListXsStoreActions>[] {
    return [
      ...super.createReducerArray(initialState)
    ];
  }

  protected createEffects(
    serviceType: Type<WinterhalterProductOrderIssueService>,
    entityType: AppEntityType,
    prepareEntity: (entity: WinterhalterProductOrderIssueListDto) => WinterhalterProductOrderIssueListDto,
    prepareModel: (entity: WinterhalterProductOrderIssueListDto, model: WinterhalterProductOrderIssueListDto) => WinterhalterProductOrderIssueListDto,
    sanitizeModel: (model: WinterhalterProductOrderIssueListDto, entity: WinterhalterProductOrderIssueListDto) => WinterhalterProductOrderIssueListDto,
    ...args
  ): Type<PagedEntitiesXsStoreEffects<WinterhalterProductOrderIssueListDto>> {
    const actions = this.actions;
    const selectors = this.selectors;

    @Injectable()
    class Effects extends PagedEntitiesXsStoreEffects<WinterhalterProductOrderIssueListDto> {
      // TODO: check if this service should be TechPortalIssueTemplateService
      protected service: WinterhalterProductOrderIssueService;

      constructor(
        protected injector: Injector
      ) {
        super(injector, actions, selectors, serviceType, entityType, prepareEntity, prepareModel, sanitizeModel, true);
      }

      protected createEffects(): void {
        super.createEffects();
      }

      protected actionCallback(action: Action, isError: boolean = false): void {
        super.actionCallback(action, isError);
      }
    }

    return Effects;
  }
}
