import { CorePortalDatatableConfig, DatatableTableColumnType } from '@nexnox-web/core-portal';
import { corePortalSettingsStereotypesEntityTypeOptions } from '@nexnox-web/core-portal/features/settings/features/stereotypes';
import { AppEntityType, AuditKind } from '@nexnox-web/core-shared';

export function corePortalGetDatatableConfigDefaults(allowedEntityTypes?: AppEntityType[]): CorePortalDatatableConfig {
  return {
    AuditDefault: {
      excludedColumns: [
        'tenantId',
        'entityType',
        'createdByUserId',
        'entityId'
      ],
      defaultColumns: [
        'auditId',
        'kind',
        'createdAt',
        'createdByUserName'
      ],
      columnTypings: [
        {
          key: 'kind',
          type: DatatableTableColumnType.ENUM,
          enumOptions: [
            { label: 'core-portal.audit.kind.0', value: AuditKind.Added },
            { label: 'core-portal.audit.kind.1', value: AuditKind.Modified },
            { label: 'core-portal.audit.kind.2', value: AuditKind.Deleted }
          ]
        },
        {
          key: 'createdAt',
          type: DatatableTableColumnType.DATE,
          utc: true
        }
      ]
    },
    SettingsRoleDefault: {
      excludedColumns: [
        'tenantId',
        'isSystemRole',
        'concurrencyStamp',
        'packages',
        'isDefault',
        'term',
        'isInRelation',
        'kind'
      ],
      defaultColumns: [
        'appRoleId',
        'title'
      ],
      columnTypings: []
    },
    SettingsStereotypeDefault: {
      excludedColumns: [
        'isInRelation',
        'tenantId',
        'customPropertySets',
        'rowVersion',
        'isArchived',
        'prioritySetId',
        'prioritySet',
        'prioritySet.id',
        'prioritySet.prioritySetId',
        'prioritySet.name',
        'prioritySet.tenantId',
        'stateMachineId',
        'stateMachine',
        'stateMachine.stateMachineId',
        'stateMachine.name',
        'stateMachine.tenantId',
        'inventoryNumberPattern',
        'inventoryNumberPattern.inventoryNumberPatternId',
        'inventoryNumberPattern.title',
        'inventoryNumberPattern.prefix',
        'inventoryNumberPattern.suffix',
        'inventoryNumberPattern.length',
        'inventoryNumberPattern.minimum',
        'inventoryNumberPattern.maximum',
        'inventoryNumberPattern.tenantId',
        'defaultSkeleton.skeletonRootId',
        'stateMachine.externalId',
        'prioritySet.externalId',
        'missionRestrictions',
        'defaultSkeleton'
      ],
      defaultColumns: [
        'stereotypeId',
        'name',
        'isDefault'
      ],
      columnTypings: [
        {
          key: 'entityType',
          type: DatatableTableColumnType.ENUM,
          enumOptions: corePortalSettingsStereotypesEntityTypeOptions
            .filter(x => !allowedEntityTypes ? true : allowedEntityTypes.includes(x.value))
        },
        {
          key: 'isDefault',
          type: DatatableTableColumnType.BOOLEAN
        },
        {
          key: 'externalId',
          type: DatatableTableColumnType.TEXT
        }
      ]
    }
  };
}
