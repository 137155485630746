import {
  BsDatePipe,
  CustomCheckboxDefaultDto,
  CustomCheckboxDto,
  CustomDateDefaultDto,
  CustomDatePropertyDto,
  CustomDropDownDefaultValueDto,
  CustomDropDownDto,
  CustomEmailDefaultDto,
  CustomEmailPropertyDto,
  CustomInfoDto,
  CustomInfoKinds,
  CustomMultilineDefaultDto,
  CustomMultilinePropertyDto,
  CustomNumericDefaultDto,
  CustomNumericPropertyDto,
  CustomPhoneDefaultDto,
  CustomPhoneDto,
  CustomPictureDto,
  CustomPropertyDto,
  CustomSignatureDto,
  CustomTextDefaultDto,
  CustomTextPropertyDto,
  CustomTimeDefaultDto,
  CustomTimePropertyDto,
  FileDto,
  FilledCustomValueDto,
  PropertyRating,
  UnsubscribeHelper
} from '@nexnox-web/core-shared';
import { FormlyFieldConfig, FormlyTemplateOptions } from '@ngx-formly/core';
import { Observable, of } from 'rxjs';
import { isFinite, isNull, isNumber, isString, isUndefined } from 'lodash';
import { Validators } from '@angular/forms';
import { distinctUntilChanged, startWith, switchMap } from 'rxjs/operators';
import dayjs from 'dayjs';
import { firstOrDefault, minutesTo } from '@nexnox-web/lodash';
import {
  CorePortalFormlyFileUploadTyping,
  CorePortalFormlyNgSelectTyping,
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping,
  CorePortalFormlyTranslatedSetPointMessage,
  CorePortalFormlyTranslatedTyping
} from '../../../../formly';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { Injector } from '@angular/core';

export interface ExpressionProperties {
  [property: string]: string | ((model: any, formState: any, field?: FormlyFieldConfig) => any) | Observable<any>;
}

export interface CustomPropertyOptions {
  formlyType?: string;
  inputType?: string;
  valuePath?: string;
  isInheritedPath?: string;
  typePath?: string;
  prependFields?: FormlyFieldConfig[];
  additionalFields?: FormlyFieldConfig[];
  templateOptions?: Partial<FormlyTemplateOptions>;
  validationMessages?: any;
  expressionProperties?: ExpressionProperties;
  validators?: any;
  parsers?: ((value: any) => any)[];
  defaultValue?: any;
  checkRequired?: boolean;
  onInitHook?: (field: FormlyFieldConfig) => void;
  setPointMessages?: CorePortalFormlyTranslatedSetPointMessage[];
}

export class CustomPropertyTemplates extends UnsubscribeHelper {
  private bsLocaleService: BsLocaleService;

  constructor(
    private injector: Injector,
    private creatingFn: () => boolean,
    private readonlyFn: () => boolean,
    private rateableFn: () => boolean,
    private inheritance: boolean
  ) {
    super();

    this.bsLocaleService = injector.get(BsLocaleService);
  }

  public default<T extends CustomPropertyDto>(
    property: T,
    options?: CustomPropertyOptions
  ): FormlyFieldConfig[] {
    const formlyType = options?.formlyType ?? 'input';
    const inputType = options?.inputType ?? 'text';
    const valuePath = options?.valuePath ?? 'customValues.0.customValue.value';
    const isInheritedPath = options?.isInheritedPath ?? 'customValues.0.customValue.isInherited';
    const typePath = options?.typePath ?? 'customValues.0.customValue.type';
    const prependFields = options?.prependFields ?? [];
    const additionalFields = options?.additionalFields ?? [];
    const templateOptions = options?.templateOptions ?? {};
    const validationMessages = options?.validationMessages ?? {};
    const expressionProperties = options?.expressionProperties ?? {};
    const validators = options?.validators ?? {};
    const parsers = options?.parsers ?? [];
    const defaultValue = options?.defaultValue ?? undefined;
    const checkRequired = !isUndefined(options?.checkRequired) ? options.checkRequired : true;
    const onInitHook = options?.onInitHook ?? undefined;
    const setPointMessages = options?.setPointMessages ?? undefined;

    return [
      ...prependFields,
      {
        key: `${property.customPropertyId.toString()}.${valuePath}`,
        type: formlyType,
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        defaultValue: this.creatingFn() ? defaultValue : undefined,
        className: this.inheritance ? 'col-md-10' : 'col-md-12',
        templateOptions: {
          label: property.name,
          corePortalTranslated: {
            validationMessages: {
              required: 'core-portal.core.validation.required',
              ...validationMessages
            },
            setPointMessages
          } as CorePortalFormlyTranslatedTyping,
          type: inputType,
          ...templateOptions
        },
        expressionProperties: {
          'templateOptions.required': () => checkRequired ? (!this.readonlyFn() && property.isRequired) : false,
          'templateOptions.disabled': model => this.readonlyFn() || this.isInherited(model, property),
          'templateOptions.readonly': () => this.readonlyFn(),
          'templateOptions.corePortalTranslated.rateable': () => this.rateableFn(),
          ...expressionProperties
        },
        parsers,
        validators,
        hooks: {
          onInit: field => {
            if (onInitHook) onInitHook(field);
          }
        }
      },
      {
        key: `${property.customPropertyId.toString()}.${isInheritedPath}`,
        type: 'core-portal-switch',
        wrappers: ['core-portal-translated'],
        className: 'col-auto',
        defaultValue: false,
        hideExpression: () => !this.inheritance,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.is-inherited'
          }
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonlyFn()
        }
      },
      {
        key: `${property.customPropertyId.toString()}.propertyId`,
        defaultValue: property.customPropertyId
      },
      {
        key: `${property.customPropertyId.toString()}.tenantId`,
        defaultValue: property.tenantId
      },
      {
        key: `${property.customPropertyId.toString()}.${typePath}`,
        defaultValue: property.type
      },
      ...additionalFields
    ];
  }

  public text(property: CustomTextPropertyDto): FormlyFieldConfig[] {
    return this.default(property, {
      inputType: 'text',
      defaultValue: (firstOrDefault(property.defaultValues) as CustomTextDefaultDto)?.value,
      templateOptions: {
        corePortalReadonly: {
          type: CorePortalFormlyReadonlyTypes.BASIC
        } as CorePortalFormlyReadonlyTyping
      },
      ...this.getTextValidators(property)
    });
  }

  public numeric(property: CustomNumericPropertyDto): FormlyFieldConfig[] {
    return this.default(property, {
      formlyType: 'core-portal-input-group-input',
      inputType: 'number',
      defaultValue: (firstOrDefault(property.defaultValues) as CustomNumericDefaultDto)?.value,
      templateOptions: {
        corePortalReadonly: {
          type: CorePortalFormlyReadonlyTypes.BASIC,
          template: (value: string) => value ? `${value} ${property.unit ? property.unit : ''}` : null,
          format: property.isNumberFormatActive
        } as CorePortalFormlyReadonlyTyping,
        corePortalInputGroupInput: {
          append: property.unit ? [property.unit] : []
        }
      },
      setPointMessages: [
        {
          label: 'core-portal.core.validation.set-point-min',
          value: property.setPointMin,
          args: {
            min: property.setPointMin,
            unit: property.unit ? ` ${property.unit}` : ''
          },
          valid: true
        },
        {
          label: 'core-portal.core.validation.set-point-max',
          value: property.setPointMax,
          args: {
            max: property.setPointMax,
            unit: property.unit ? ` ${property.unit}` : ''
          },
          valid: true
        }
      ],
      expressionProperties: {
        'templateOptions.corePortalTranslated.setPointMessages.0.valid': (_, __, field) =>
          isFinite(parseInt(field.formControl.value)) ? +field.formControl.value >= +property.setPointMin : false,
        'templateOptions.corePortalTranslated.setPointMessages.1.valid': (_, __, field) =>
          isFinite(parseInt(field.formControl.value)) ? +field.formControl.value <= +property.setPointMax : false
      },
      validationMessages: {
        min: {
          key: 'core-portal.core.validation.min',
          args: { min: property.minValue }
        },
        max: {
          key: 'core-portal.core.validation.max',
          args: { max: property.maxValue }
        }
      },
      validators: {
        min: ctrl => isFinite(parseInt(ctrl.value)) && !isNull(property.minValue) ? +ctrl.value >= +property.minValue : true,
        max: ctrl => isFinite(parseInt(ctrl.value)) && !isNull(property.maxValue) ? +ctrl.value <= +property.maxValue : true
      }
    });
  }

  public multilineText(property: CustomMultilinePropertyDto): FormlyFieldConfig[] {
    return this.default(property, {
      formlyType: 'textarea',
      defaultValue: (firstOrDefault(property.defaultValues) as CustomMultilineDefaultDto)?.value,
      templateOptions: {
        corePortalReadonly: {
          type: CorePortalFormlyReadonlyTypes.BASIC
        } as CorePortalFormlyReadonlyTyping,
        rows: 3
      },
      ...this.getTextValidators(property)
    });
  }

  public email(property: CustomEmailPropertyDto): FormlyFieldConfig[] {
    return this.default(property, {
      inputType: 'email',
      defaultValue: (firstOrDefault(property.defaultValues) as CustomEmailDefaultDto)?.value,
      templateOptions: {
        corePortalReadonly: {
          type: CorePortalFormlyReadonlyTypes.BASIC
        } as CorePortalFormlyReadonlyTyping
      },
      validationMessages: {
        email: 'core-portal.core.validation.email'
      },
      validators: {
        email: ctrl => ctrl.value?.length ? !Validators.email(ctrl) : true
      }
    });
  }

  public checkbox(property: CustomCheckboxDto): FormlyFieldConfig[] {
    return this.default(property, {
      formlyType: 'core-portal-switch',
      defaultValue: (firstOrDefault(property.defaultValues) as CustomCheckboxDefaultDto)?.value,
      templateOptions: {
        corePortalReadonly: {
          type: CorePortalFormlyReadonlyTypes.BOOLEAN
        } as CorePortalFormlyReadonlyTyping
      },
      setPointMessages: [
        {
          label: 'core-portal.core.validation.set-point',
          value: property.setPoint,
          valid: true
        }
      ],
      expressionProperties: {
        'templateOptions.corePortalTranslated.setPointMessages.0.valid': (_, __, field) => field.formControl.value === property.setPoint
      },
      validators: {
        required: ctrl => !this.readonlyFn() && property.isRequired ? ctrl.value : true
      }
    });
  }

  public date(property: CustomDatePropertyDto): FormlyFieldConfig[] {
    return this.default(property, {
      formlyType: 'core-portal-datepicker',
      defaultValue: (firstOrDefault(property.defaultValues) as CustomDateDefaultDto)?.value,
      validationMessages: {
        minDate: 'core-portal.core.validation.min-date',
        maxDate: 'core-portal.core.validation.max-date'
      },
      templateOptions: {
        corePortalReadonly: {
          type: CorePortalFormlyReadonlyTypes.DATE,
          format: 'LL'
        } as CorePortalFormlyReadonlyTyping,
        minDate: property.minTime ? new Date(property.minTime) : undefined,
        maxDate: property.maxTime ? new Date(property.maxTime) : undefined
      },
      setPointMessages: [
        {
          label: 'core-portal.core.validation.set-point-min',
          value: property.setMinTime,
          args: {
            min: null,
            unit: ''
          },
          valid: true
        },
        {
          label: 'core-portal.core.validation.set-point-max',
          value: property.setMaxTime,
          args: {
            max: null,
            unit: ''
          },
          valid: true
        }
      ],
      expressionProperties: {
        'templateOptions.corePortalTranslated.setPointMessages.0.args.min':
          BsDatePipe.transformDate(this.bsLocaleService, property.setMinTime, 'LL'),
        'templateOptions.corePortalTranslated.setPointMessages.0.valid': (_, __, field) => {
          const date = dayjs(field.formControl.value);
          const minDate = dayjs(property.setMinTime);
          if ((!field.formControl.value || !date.isValid()) || !minDate.isValid()) {
            return false;
          }

          return date.isAfter(minDate) || date.isSame(minDate);
        },

        'templateOptions.corePortalTranslated.setPointMessages.1.args.max':
          BsDatePipe.transformDate(this.bsLocaleService, property.setMaxTime, 'LL'),
        'templateOptions.corePortalTranslated.setPointMessages.1.valid': (_, __, field) => {
          const date = dayjs(field.formControl.value);
          const maxDate = dayjs(property.setMaxTime);
          if ((!field.formControl.value || !date.isValid()) || !maxDate.isValid()) {
            return false;
          }

          return date.isBefore(maxDate) || date.isSame(maxDate);
        }
      },
      validators: {
        minDate: ctrl => {
          const date = dayjs(ctrl.value);
          const minDate = dayjs(property.minTime);
          if ((!ctrl.value || !date.isValid()) || (!property.minTime || !minDate.isValid())) {
            return true;
          }

          return date.isAfter(minDate) || date.isSame(minDate);
        },
        maxDate: ctrl => {
          const date = dayjs(ctrl.value);
          const maxDate = dayjs(property.maxTime);
          if ((!ctrl.value || !date.isValid()) || (!property.maxTime || !maxDate.isValid())) {
            return true;
          }

          return date.isBefore(maxDate) || date.isSame(maxDate);
        }
      }
    });
  }

  public time(property: CustomTimePropertyDto): FormlyFieldConfig[] {
    return this.default(property, {
      formlyType: 'core-portal-timepicker',
      defaultValue: (firstOrDefault(property.defaultValues) as CustomTimeDefaultDto)?.value,
      validationMessages: {
        minTime: 'core-portal.core.validation.min-time',
        maxTime: 'core-portal.core.validation.max-time'
      },
      templateOptions: {
        corePortalReadonly: {
          type: CorePortalFormlyReadonlyTypes.TIME
        } as CorePortalFormlyReadonlyTyping,
      },
      setPointMessages: [
        {
          label: 'core-portal.core.validation.set-point-min',
          value: property.setMinMinutes,
          args: {
            min: null,
            unit: ''
          },
          valid: true
        },
        {
          label: 'core-portal.core.validation.set-point-max',
          value: property.setMaxMinutes,
          args: {
            max: null,
            unit: ''
          },
          valid: true
        }
      ],
      expressionProperties: {
        'templateOptions.corePortalTranslated.setPointMessages.0.args.min': of(property.setMinMinutes).pipe(
          switchMap(setMinMinutes => {
            const { hours, minutes } = minutesTo(setMinMinutes, ['years', 'weeks', 'days']);
            return BsDatePipe.transformDate(
              this.bsLocaleService,
              `${dayjs().format('YYYY-MM-DD')}T${hours}:${minutes}`,
              'LT'
            );
          })
        ),
        'templateOptions.corePortalTranslated.setPointMessages.0.valid': (_, __, field) => {
          if (
            (!isNumber(field.formControl.value) || field.formControl.value < 0) ||
            (!isNumber(property.setMinMinutes) || property.setMinMinutes < 0)
          ) {
            return false;
          }

          return field.formControl.value >= property.setMinMinutes;
        },

        'templateOptions.corePortalTranslated.setPointMessages.1.args.max': of(property.setMaxMinutes).pipe(
          switchMap(setMaxMinutes => {
            const { hours, minutes } = minutesTo(setMaxMinutes, ['years', 'weeks', 'days']);
            return BsDatePipe.transformDate(
              this.bsLocaleService,
              `${dayjs().format('YYYY-MM-DD')}T${hours}:${minutes}`,
              'LT'
            );
          })
        ),
        'templateOptions.corePortalTranslated.setPointMessages.1.valid': (_, __, field) => {
          if (
            (!isNumber(field.formControl.value) || field.formControl.value < 0) ||
            (!isNumber(property.setMaxMinutes) || property.setMaxMinutes < 0)
          ) {
            return false;
          }

          return field.formControl.value <= property.setMaxMinutes;
        }
      },
      validators: {
        minTime: ctrl => {
          if ((!isNumber(ctrl.value) || ctrl.value < 0) || (!isNumber(property.minMinutes) || property.minMinutes < 0)) {
            return true;
          }

          return ctrl.value >= property.minMinutes;
        },
        maxTime: ctrl => {
          if ((!isNumber(ctrl.value) || ctrl.value < 0) || (!isNumber(property.maxMinutes) || property.maxMinutes < 0)) {
            return true;
          }

          return ctrl.value <= property.maxMinutes;
        }
      }
    });
  }

  public dropdown(property: CustomDropDownDto): FormlyFieldConfig[] {
    const defaultValues = ((property.defaultValues ?? []) as CustomDropDownDefaultValueDto[]).sort((a,b) => a.position - b.position);
    const selectedValue = defaultValues.find(x => x.isSelected);

    return this.default(property, {
      formlyType: 'core-portal-ng-select',
      defaultValue: selectedValue?.customValueId ?? undefined,
      templateOptions: {
        corePortalReadonly: {
          type: CorePortalFormlyReadonlyTypes.ENUM,
          enumOptions: defaultValues.map(defaultValue => ({
            label: defaultValue.value,
            value: defaultValue.customValueId
          }))
        } as CorePortalFormlyReadonlyTyping,
        corePortalNgSelect: {
          items: defaultValues.map(defaultValue => ({
            label: defaultValue.value,
            value: defaultValue.customValueId,
            accepted: defaultValue.rating === PropertyRating.Ok
          })),
          rateable: true
        } as CorePortalFormlyNgSelectTyping
      },
      setPointMessages: [
        {
          label: 'core-portal.core.validation.set-point',
          value: true,
          valid: true
        }
      ],
      expressionProperties: {
        'templateOptions.corePortalTranslated.setPointMessages.0.valid': (_, __, field) => {
          if (!(property.defaultValues ?? []).length) return false;
          const foundDefaultValue = property.defaultValues.find(x => x.customValueId === field.formControl.value);
          if (!foundDefaultValue) return false;

          return (foundDefaultValue as CustomDropDownDefaultValueDto).rating === PropertyRating.Ok;
        }
      }
    });
  }

  public phone(property: CustomPhoneDto): FormlyFieldConfig[] {
    return this.default(property, {
      inputType: 'tel',
      defaultValue: (firstOrDefault(property.defaultValues) as CustomPhoneDefaultDto)?.value,
      templateOptions: {
        corePortalReadonly: {
          type: CorePortalFormlyReadonlyTypes.PHONE
        } as CorePortalFormlyReadonlyTyping
      },
      validationMessages: {
        phone: 'core-portal.core.validation.phone'
      },
      validators: {
        phone: ctrl => !isString(ctrl.value) || !Validators.pattern(/^[+]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/)(ctrl)
      }
    });
  }

  public signature(property: CustomSignatureDto): FormlyFieldConfig[] {
    return this.default(property, {
      formlyType: 'core-portal-signature',
      valuePath: 'customValues.0.customValue.ownValueFile',
      templateOptions: {
        corePortalReadonly: {
          type: CorePortalFormlyReadonlyTypes.IMAGE
        } as CorePortalFormlyReadonlyTyping
      },
      onInitHook: field => this.subscribe(field.formControl.valueChanges.pipe(
        startWith(field.formControl.value),
        distinctUntilChanged()
      ), (value: FileDto) => {
        if (field.form.get(`${property.customPropertyId.toString()}.customValues.0.customValue.isInherited`)?.value) return;
        field.form.get(`${property.customPropertyId.toString()}.customValues.0.customValue.value`)?.setValue(value?.fileId ?? null);
      }),
      expressionProperties: {
        hide: model => model[property.customPropertyId.toString()].customValues[0].customValue.isInherited
      },
      prependFields: [
        this.default(property, {
          formlyType: 'core-portal-signature',
          valuePath: 'customValues.0.customValue.inheritedValueFile',
          templateOptions: {
            corePortalReadonly: {
              type: CorePortalFormlyReadonlyTypes.IMAGE
            } as CorePortalFormlyReadonlyTyping
          },
          onInitHook: field => this.subscribe(field.formControl.valueChanges.pipe(
            startWith(field.formControl.value),
            distinctUntilChanged()
          ), (value: FileDto) => {
            if (!field.form.get(`${property.customPropertyId.toString()}.customValues.0.customValue.isInherited`)?.value) return;
            field.form
              .get(`${property.customPropertyId.toString()}.customValues.0.customValue.value`)?.setValue(value?.fileId ?? null);
          }),
          expressionProperties: {
            hide: model => !model[property.customPropertyId.toString()].customValues[0].customValue.isInherited
          },
        })[0]
      ],
      additionalFields: [
        { key: `${property.customPropertyId.toString()}.customValues.0.customValue.value` }
      ]
    });
  }

  public image(property: CustomPictureDto): FormlyFieldConfig[] {
    return this.default(property, {
      formlyType: 'core-portal-file-upload',
      valuePath: 'customValues.0.customValue.ownValueFile',
      templateOptions: {
        corePortalReadonly: {
          type: CorePortalFormlyReadonlyTypes.IMAGE
        } as CorePortalFormlyReadonlyTyping,
        corePortalFileUpload: {
          accept: '.jpg,.jpeg,.png',
          image: true
        } as CorePortalFormlyFileUploadTyping
      },
      onInitHook: field => this.subscribe(field.formControl.valueChanges.pipe(
        startWith(field.formControl.value),
        distinctUntilChanged()
      ), (value: FileDto) => {
        if (field.form.get(`${property.customPropertyId.toString()}.customValues.0.customValue.isInherited`)?.value) return;
        field.form.get(`${property.customPropertyId.toString()}.customValues.0.customValue.value`)?.setValue(value?.fileId ?? null);
      }),
      expressionProperties: {
        hide: model => model[property.customPropertyId.toString()].customValues[0].customValue.isInherited
      },
      prependFields: [
        this.default(property, {
          formlyType: 'core-portal-file-upload',
          valuePath: 'customValues.0.customValue.inheritedValueFile',
          templateOptions: {
            corePortalReadonly: {
              type: CorePortalFormlyReadonlyTypes.IMAGE
            } as CorePortalFormlyReadonlyTyping,
            corePortalFileUpload: {
              accept: '.jpg,.jpeg,.png',
              image: true
            } as CorePortalFormlyFileUploadTyping
          },
          onInitHook: field => this.subscribe(field.formControl.valueChanges.pipe(
            startWith(field.formControl.value),
            distinctUntilChanged()
          ), (value: FileDto) => {
            if (!field.form.get(`${property.customPropertyId.toString()}.customValues.0.customValue.isInherited`)?.value) return;
            field.form
              .get(`${property.customPropertyId.toString()}.customValues.0.customValue.value`)?.setValue(value?.fileId ?? null);
          }),
          expressionProperties: {
            hide: model => !model[property.customPropertyId.toString()].customValues[0].customValue.isInherited
          },
        })[0]
      ],
      additionalFields: [
        { key: `${property.customPropertyId.toString()}.customValues.0.customValue.value` }
      ]
    });
  }

  public info(property: CustomInfoDto): FormlyFieldConfig[] {
    let infoIcon: IconDefinition;
    let infoColor: string;

    switch (property.infoKind) {
      case CustomInfoKinds.Warning:
        infoIcon = faExclamationCircle;
        infoColor = 'var(--warning)';
        break;
      case CustomInfoKinds.Hint:
        infoIcon = faInfoCircle;
        infoColor = 'var(--info)';
        break;
    }

    return [{
      key: 'propertyId',
      type: 'core-portal-editor',
      wrappers: ['core-portal-translated', 'core-portal-readonly'],
      className: 'col-md-12',
      templateOptions: {
        label: property.name,
        corePortalReadonly: {
          type: CorePortalFormlyReadonlyTypes.HTML,
          isTrustedHTML: true,
          template: () => property.content,
          hasValue: true,
          icon: infoIcon,
          iconColor: infoColor
        } as CorePortalFormlyReadonlyTyping,
        required: false,
        readonly: true
      }
    }];
  }

  private getTextValidators(property: CustomTextPropertyDto | CustomMultilinePropertyDto): Partial<CustomPropertyOptions> {
    return {
      validationMessages: {
        minLength: {
          key: 'core-portal.core.validation.min-length',
          args: { minLength: property.minLength }
        },
        maxLength: {
          key: 'core-portal.core.validation.max-length',
          args: { maxLength: property.maxLength }
        }
      },
      validators: {
        minLength: ctrl => ctrl.value?.length && isNumber(property.minLength) ? ctrl.value?.length >= property.minLength : true,
        maxLength: ctrl => ctrl.value?.length && isNumber(property.maxLength) ? ctrl.value?.length <= property.maxLength : true
      }
    };
  }

  private isInherited(model: { [key: string]: FilledCustomValueDto }, property: CustomPropertyDto): boolean {
    return Boolean(model && model[property.customPropertyId?.toString()]?.customValues[0]?.customValue?.isInherited);
  }
}
