import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  Input,
  OnInit,
  Type,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {
  AppEntityType,
  CoreSharedApiBaseService, CoreSharedIssueLinkUtilityService,
  CoreSharedSidebarBaseComponent,
  IssueSimpleDto,
  IssueType
} from '@nexnox-web/core-shared';
import {CorePortalEntityEditBaseComponent} from "@nexnox-web/core-portal";
import {distinctUntilKeyChanged, filter, firstValueFrom, map, Observable, shareReplay, take} from "rxjs";
import {MessageIssueService, MissionIssueService, TicketIssueService, WinterhalterServiceCallIssueService, WinterhalterProductOrderIssueService} from "../../store";
import {
  MessageIssueEditComponent,
  MissionIssueEditComponent,
  TicketIssueEditComponent,
  WinterhalterServiceCallIssueEditComponent,
  WinterhalterProductOrderIssueEditComponent
} from "../../components";

@Component({
  selector: 'nexnox-web-issue-detail-sidebar',
  templateUrl: './issue-detail-sidebar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IssueDetailSidebarComponent extends CoreSharedSidebarBaseComponent implements OnInit {

  @ViewChild('editComponentContainer', {read: ViewContainerRef, static: true}) public editContainer!: ViewContainerRef;

  @Input() public issue$: Observable<IssueSimpleDto>;

  public editComponentType: Type<CorePortalEntityEditBaseComponent<any>>;

  public editComponent: CorePortalEntityEditBaseComponent<any>;

  public issueId: number;

  private issueService: CoreSharedApiBaseService;

  constructor(
    private issueLinkUtilityService: CoreSharedIssueLinkUtilityService,
    private injector: Injector
  ) {
    super();
  }

  public async ngOnInit(): Promise<void> {
    this.subscribe(this.issue$.pipe(filter(issue => Boolean(issue?.issueId)), distinctUntilKeyChanged('issueId')), (issue) => this._initSidebar(issue));
  }

  public restoreDefaults(): void {
    this.editContainer.clear();
  }

  public onHide(): void {
    super.onHide();
  }

  public onShow(): void {
    super.onShow();
  }

  public getIssueLink(issue: IssueSimpleDto): string {
    return this.issueLinkUtilityService.getIssueLink(issue);
  }

  private _initSidebar(issue: IssueSimpleDto): void {

    this.restoreDefaults();

    this.issueId = issue.issueId;

    switch (issue.issueType) {
      case IssueType.Message:
        this.issueService = this.injector.get(MessageIssueService);
        this.editComponentType = MessageIssueEditComponent;
        break;
      case IssueType.Mission:
        this.issueService = this.injector.get(MissionIssueService);
        this.editComponentType = MissionIssueEditComponent;
        break;
      case IssueType.Ticket:
        this.issueService = this.injector.get(TicketIssueService);
        this.editComponentType = TicketIssueEditComponent;
        break;
      case IssueType.WinterhalterServiceCall:
        this.issueService = this.injector.get(WinterhalterServiceCallIssueService);
        this.editComponentType = WinterhalterServiceCallIssueEditComponent;
        break;
      case IssueType.WinterhalterProductOrder:
        this.issueService = this.injector.get(WinterhalterProductOrderIssueService);
        this.editComponentType = WinterhalterProductOrderIssueEditComponent;
        break;
      default:
        this.issueService = this.injector.get(MessageIssueService);
        this.editComponentType = MessageIssueEditComponent;
        break;
    }

    this._createEditComponent().then();
  }

  private async _createEditComponent(): Promise<void> {

    // Pass config params
    this.editComponent = this.editContainer.createComponent(this.editComponentType).instance as unknown as CorePortalEntityEditBaseComponent<any>;

    // Configure edit
    this.editComponent.readonly = true;
    this.editComponent.loading = true;

    // Stereotypes
    // They get loaded once and then cached
    this.editComponent.stereotypes$ = this.issueService.getStereotypes(AppEntityType.Issue, false).pipe(
      take(1),
      // Validate to empty array
      map((stereotypes) => stereotypes ?? []),
      // Cache
      shareReplay({
        bufferSize: 1,
        refCount: false,
      })
    );
    // Start loading stereotypes in any case (even if not stereotyped) to ensure clean startup loading animation
    this.subscribe(this.editComponent.stereotypes$.pipe(take(1)), async () => {
      // Get model
      this.editComponent.model = await firstValueFrom(this.issueService.getOne(this.issueId).pipe(take(1)));
      // Stop loading
      this.editComponent.loading = false;
    });
  }

}
