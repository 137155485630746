import { DatatableTableColumnTyping } from '@nexnox-web/core-portal';
import { InjectionToken } from '@angular/core';

export interface CorePortalDatatableConfig {
  [name: string]: {
    excludedColumns: string[];
    defaultColumns: string[];
    columnTypings: DatatableTableColumnTyping[];
  }
}

export const CORE_PORTAL_DATATABLE_CONFIG = new InjectionToken<CorePortalDatatableConfig>(
  '@nexnox-web/core-portal: Datatable config'
);
