import { NgModule } from '@angular/core';
import { CorePortalAttachmentsModule, CorePortalContentContainerInfiniteScrollComponent, CorePortalEntityModule, CorePortalModule, CorePortalRouterOverridesModule } from '@nexnox-web/core-portal';
import { TechPortalModule } from '@nexnox-web/tech-portal-lib';
import { TechPortalFeatureTemplatesModule } from '@nexnox-web/tech-portal/features/templates';
import { CheckboxModule } from 'primeng/checkbox';
import { MultiSelectModule } from 'primeng/multiselect';
import { TagModule } from 'primeng/tag';
import { TimelineModule } from 'primeng/timeline';
import {
  TechPortalFeatureSolutionComponent, TechPortalFeatureSolutionItemComponent, TechPortalFeatureSolutionItemEditComponent, TechPortalFeatureSolutionItemTitleComponent
} from './components';
import { TechPortalFeatureSolutionItemService, TechPortalFeatureSolutionService } from './store';

const exportedComponents = [
  TechPortalFeatureSolutionItemEditComponent,
  TechPortalFeatureSolutionItemComponent,
  TechPortalFeatureSolutionItemTitleComponent,
  TechPortalFeatureSolutionComponent
];

@NgModule({
  declarations: [
    ...exportedComponents
  ],
  imports: [
    CorePortalAttachmentsModule,
    CorePortalModule,
    TechPortalModule,
    TimelineModule,
    CheckboxModule,
    TagModule,
    MultiSelectModule,

    TechPortalFeatureTemplatesModule.forFeature()
  ],
  exports: [
    ...exportedComponents
  ],
  providers: [
    TechPortalFeatureSolutionService,
    TechPortalFeatureSolutionItemService
  ]
})
export class TechPortalFeatureSolutionModule {
}
