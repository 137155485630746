import {HttpEvent, HttpEventType} from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';
import {faFile} from '@fortawesome/free-solid-svg-icons/faFile';
import {faFileImage} from '@fortawesome/free-solid-svg-icons/faFileImage';
import {faFilePdf} from '@fortawesome/free-solid-svg-icons/faFilePdf';
import {faSpinner} from '@fortawesome/free-solid-svg-icons/faSpinner';
import {CorePortalExistingAttachmentsModalComponent, ModelValid} from '@nexnox-web/core-portal';
import {
  ApiNotificationService,
  AttachmentForTechDto,
  AttachmentTypeForTech,
  CoreSharedFileService,
  CoreSharedImageService,
  CoreSharedModalService,
  CoreSharedSortableListItem,
  FileAttachmentForTechDto,
  FileDto
} from '@nexnox-web/core-shared';
import {CORE_STORE_TENANT_ID_SELECTOR} from '@nexnox-web/core-store';
import {MemoizedSelector, select, Store} from '@ngrx/store';
import {BehaviorSubject, Observable} from 'rxjs';
import {exhaustMap, map, take} from 'rxjs/operators';

@Component({
  selector: 'nexnox-web-tech-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorePortalAttachmentsComponent implements OnInit, ModelValid {
  @Input() public cardboxTitle = 'tech-portal.subtitles.attachments';
  @Input() public attachments$: Observable<AttachmentForTechDto[]>;
  @Input() public attachmentsLoading$: Observable<boolean>;
  @Input() public readonly: boolean;
  @Input() public loading: boolean;
  @Input() public withCardbox = true;
  @Input() public canAddExisting = false;
  @Input() public existingItems$: Observable<CoreSharedSortableListItem[]>;

  @ViewChild('fileInput') public fileInput: ElementRef<HTMLInputElement>;

  @Output() public attachmentsChange: EventEmitter<AttachmentForTechDto[]> = new EventEmitter<AttachmentForTechDto[]>();

  public items$: Observable<CoreSharedSortableListItem[]>;
  public attachmentsString$: Observable<string>;
  public isInProgress$: Observable<boolean>;

  public selectedFileSubject: BehaviorSubject<File> = new BehaviorSubject<File>(null);
  public selectedFileAttachmentSubject: BehaviorSubject<FileAttachmentForTechDto> =
    new BehaviorSubject<FileAttachmentForTechDto>(null);
  public loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public progressSubject: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  public faSpinner = faSpinner;

  constructor(
    private fileService: CoreSharedFileService,
    private imageService: CoreSharedImageService,
    @Inject(CORE_STORE_TENANT_ID_SELECTOR) private tenantIdSelector: MemoizedSelector<any, number>,
    private store: Store<any>,
    private apiNotificationService: ApiNotificationService,
    private modalService: CoreSharedModalService
  ) {
  }

  /* istanbul ignore next */
  public ngOnInit(): void {

    this.items$ = this.attachments$.pipe(
      map(attachments => attachments.map((attachment, index) => this.mapAttachment(attachment, index)))
    );

    this.attachmentsString$ = this.items$.pipe(
      map(items => {
        if (!items?.length) {
          return '';
        }

        return ` (${items.length})`;
      })
    );

    this.isInProgress$ = this.progressSubject.asObservable().pipe(
      map(progress => Boolean(progress))
    );
  }

  public onAddExisting(): void {
    this.modalService.showModal(CorePortalExistingAttachmentsModalComponent, instance => {
      instance.existingItems$ = this.existingItems$;
    }).then(async ({ value: attachments }) => {
      const existingAttachments = await this.attachments$.pipe(take(1)).toPromise();
      const newAttachments = attachments.map(attachment => {
        if (attachment.file) {
          const newAttachment: FileAttachmentForTechDto = {
            file: attachment.file,
            type: AttachmentTypeForTech.File,
            tenantId: attachment.tenantId
          };
          return newAttachment;
        }
      });

      this.attachmentsChange.emit([...existingAttachments, ...newAttachments]);
    }).catch(() => null);
  }

  public onUpload(attachments: AttachmentForTechDto[]): void {
    const fileAttachment = this.selectedFileAttachmentSubject.getValue();
    this.attachmentsChange.emit([...attachments, fileAttachment]);
    this.onReset();
  }

  public onItemsChange(items: CoreSharedSortableListItem[]): void {
    this.attachmentsChange.emit(items.map(item => {
      const { member, ...data } = item.getExternalData();

      return data;
    }));
  }

  public onUploadFile(files: FileList): void {
    if (!files.length) {
      return;
    }

    const selectedFile = files[0];
    this.selectedFileSubject.next(selectedFile);

    this.loadingSubject.next(true);
    let observableToSubscribe: Observable<HttpEvent<FileDto>>;

    if (selectedFile.type.includes('image')) {
      observableToSubscribe = this.store.pipe(select(this.tenantIdSelector), take(1)).pipe(
        exhaustMap(tenantId => this.imageService.uploadImage(selectedFile, tenantId))
      );
    } else {
      observableToSubscribe = this.store.pipe(select(this.tenantIdSelector), take(1)).pipe(
        exhaustMap(tenantId => this.fileService.uploadFile(selectedFile, tenantId))
      );
    }

    observableToSubscribe.subscribe(
      async fileEvent => {
        if (fileEvent.type === HttpEventType.Response) {
          const file = fileEvent.body;
          const fileAttachment = {
            file,
            type: AttachmentTypeForTech.File,
            tenantId: file.tenantId
          } as FileAttachmentForTechDto;
          const attachments = await this.attachments$.pipe(take(1)).toPromise();
          this.selectedFileAttachmentSubject.next(fileAttachment);
          this.onUpload(attachments);
        }

        if (fileEvent.type === HttpEventType.UploadProgress) {
          this.progressSubject.next(fileEvent.loaded / fileEvent.total);
        }
      },
      error => {
        this.apiNotificationService.handleApiError(error);
        this.onResetFileUpload();
      },
      () => this.onResetFileUpload()
    );
  }

  public onReset(): void {
    this.onResetFileUpload();
    this.selectedFileAttachmentSubject.next(null);

    if (this.fileInput?.nativeElement) {
      this.fileInput.nativeElement.value = '';
    }
  }

  public onResetFileUpload(): void {
    this.selectedFileSubject.next(null);
    this.loadingSubject.next(false);
    this.progressSubject.next(null);
  }

  public isModelValid(): boolean {
    return true;
  }

  public isOwnModelValid(): boolean {
    return true;
  }

  public getFileImage(file: FileDto): IconDefinition {
    if (file.mimeType.includes('image')) {
      return faFileImage;
    } else if (file.mimeType.includes('pdf')) {
      return faFilePdf;
    }

    return faFile;
  }

  public isImage(file: FileDto): boolean {
    return file.mimeType.includes('image');
  }

  private mapAttachment(attachment: AttachmentForTechDto, index: number): CoreSharedSortableListItem {
    let newMember;

    switch (attachment.type) {
      case AttachmentTypeForTech.File: {
        const fileAttachment: FileAttachmentForTechDto = attachment as FileAttachmentForTechDto;
        return {
          title: fileAttachment.file?.name,
          position: index,
          deletable: true,
          getExternalData: () => ({ ...fileAttachment, member: newMember })
        };
      }
      default: {
        return {
          title: attachment.attachmentId.toString(),
          position: index,
          deletable: true,
          getExternalData: () => ({ ...attachment, member: newMember })
        };
      }
    }
  }
}
