<p-confirmPopup key="edit-parent"></p-confirmPopup>

<nexnox-web-tabs #tabsComponent
                 [isTransparent]="true"
                 startTab="entityEdit">
  <!-- Entity Edit Tab -->
  <ng-template nexnoxWebTabTemplate
               tabId="entityEdit"
               [heading]="title | translate"
               [hasError]="!isOwnModelValid() ?? false"
               (selectTab)="onNavigateToTab(['.'])">
    <nexnox-web-entity-edit [entityEditBaseComponent]="this"
                            [inheritance]="true"
                            displayKey="name"
                            [titleFieldFn]="titleFieldFn"
                            [useTitle]="true"
                            [customTitle]="creating ? title : (customTitle$ | async)"
                            [tabsComponent]="tabsComponent">

      <div *ngIf="model && !creating" class="d-flex w-100 justify-content-end text-muted p-2">
                <span [title]="model.lastModified | nexnoxWebBsDate:'L LT' | async">
                    {{ 'core-shared.shared.time-phrases.modified' | translate }} {{ model.lastModified | nexnoxWebDayjsRelative | async }}
                </span>
        ,&nbsp;
        <span [title]="model.createdAt | nexnoxWebBsDate:'L LT' | async">
                    {{ 'core-shared.shared.time-phrases.created' | translate }} {{ model.createdAt | nexnoxWebDayjsRelative | async }}
                </span>
      </div>
    </nexnox-web-entity-edit>
  </ng-template>

  <!-- Children Tab -->
  <ng-template *ngIf="!creating"
               nexnoxWebTabTemplate
               tabId="children"
               [heading]="'resources.subtitles.resource-children' | translate"
               [lazy]="true"
               (selectTab)="onNavigateToTab(['children'])">
    <nexnox-web-resources-resource-list [isEmbedded]="true"
                                        [parentResource]="model"
                                        [getEntityOnModeChange]="false"
                                        [canCreate]="false"
                                        componentId="ResourceDetailListChildrenComponent">
    </nexnox-web-resources-resource-list>
  </ng-template>

  <!-- Editor Suggestions Templates Tab -->
  <ng-template nexnoxWebTabTemplate
               tabId="editor-suggestions"
               [heading]="'resources.subtitles.editor-suggestions' | translate"
               (selectTab)="onNavigateToTab(['editor-suggestions'])">
    <nexnox-web-resources-editor-suggestions #editorSuggestionsComponent
                                             [primarySuggestions$]="primaryEditorSuggestions$"
                                             [alternativeSuggestions$]="alternativeEditorSuggestions$"
                                             [resourceSubject]="resourceSubject"
                                             [stereotypeId$]="selectedStereotypeId$"
                                             [loading]="loading"
                                             [readonly]="readonly"
                                             (editorSuggestionsChanged)="onEditorSuggestionsChanged($event)">
    </nexnox-web-resources-editor-suggestions>
  </ng-template>

  <!-- Escalation Rules Tab -->
  <ng-template nexnoxWebTabTemplate
               tabId="escalation-rules"
               [heading]="'resources.subtitles.escalation-rules' | translate"
               [hasError]="escalationRulesComponent ? !escalationRulesComponent.isModelValid() : false"
               (selectTab)="onNavigateToTab(['escalation-rules'])">
    <nexnox-web-resources-escalation-rules #escalationRulesComponent
                                           [rules$]="escalationRules$"
                                           [loading]="loading"
                                           [readonly]="readonly"
                                           (rulesChange)="onEscalationRulesChange($event)">
    </nexnox-web-resources-escalation-rules>
  </ng-template>

  <!-- Links -->
  <ng-template nexnoxWebTabTemplate
               tabId="links"
               [heading]="('tech-portal.subtitles.links' | translate) + (linksComponent ? (linksComponent.linksString$ | async) : '')"
               [hasError]="linksComponent ? !linksComponent.isModelValid() : false"
               (selectTab)="onNavigateToTab(['links'])">
    <nexnox-web-tech-links #linksComponent
                           [links$]="links$"
                           [loading]="loading"
                           [readonly]="readonly"
                           [entityId]="model?.resourceId"
                           [type]="linkedElementTypes.Resource"
                           (linksChange)="onLinksChange($event)">
    </nexnox-web-tech-links>
  </ng-template>

  <!-- Attachments -->
  <ng-template nexnoxWebTabTemplate
               tabId="attachments"
               [heading]="('tech-portal.subtitles.attachments' | translate) + (attachmentsComponent ? (attachmentsComponent.attachmentsString$ | async) : '')"
               [hasError]="attachmentsComponent ? !attachmentsComponent.isModelValid() : false"
               (selectTab)="onNavigateToTab(['attachments'])">
    <nexnox-web-tech-attachments #attachmentsComponent
                                 [attachments$]="attachments$"
                                 [loading]="loading"
                                 [readonly]="readonly"
                                 (attachmentsChange)="onAttachmentsChange($event)">
    </nexnox-web-tech-attachments>
  </ng-template>

  <!-- Tickets -->
  <ng-template *ngIf="!creating"
               nexnoxWebTabTemplate
               tabId="tickets"
               [heading]="'tickets.title' | translate"
               [lazy]="true"
               (selectTab)="onNavigateToTab(['tickets'])">
    <nexnox-web-resources-resource-tickets *ngIf="model"
                                           [isEmbedded]="true"
                                           [parentIds]="[model.resourceId]"
                                           [descendantId]="model.resourceId">
    </nexnox-web-resources-resource-tickets>
  </ng-template>

  <!-- Missions -->
  <ng-template *ngIf="!creating"
               nexnoxWebTabTemplate
               tabId="missions"
               [heading]="'missions.title' | translate"
               [lazy]="true"
               (selectTab)="onNavigateToTab(['missions'])">
    <nexnox-web-resources-resource-missions *ngIf="model"
                                            [isEmbedded]="true"
                                            [parentIds]="[model.resourceId]"
                                            [descendantId]="model.resourceId">
    </nexnox-web-resources-resource-missions>
  </ng-template>

  <!-- Issues -->
  <ng-template *ngIf="!creating"
               nexnoxWebTabTemplate
               tabId="issues"
               [heading]="'issues.title' | translate"
               [lazy]="true"
               (selectTab)="onNavigateToTab(['issues'])">
    <nexnox-web-resources-resource-issues *ngIf="model"
                                          [isEmbedded]="true"
                                          [parentIds]="[model.resourceId]"
                                          [descendantId]="model.resourceId">
    </nexnox-web-resources-resource-issues>
  </ng-template>

  <!-- Control articles -->
  <ng-template *ngIf="!creating && (createControlPointPermission$ | async)"
               nexnoxWebTabTemplate
               tabId="control-points"
               [heading]="'tech-portal.ccp.control-points.subtitles.list' | translate"
               [lazy]="true"
               (selectTab)="onNavigateToTab(['control-points'])">
    <nexnox-web-resources-resource-control-points *ngIf="model"
                                                  [isEmbedded]="true"
                                                  [canCreate]="false"
                                                  [parentIds]="[model.resourceId]">
    </nexnox-web-resources-resource-control-points>
  </ng-template>

  <!-- Connected -->
  <ng-template *ngIf="!creating && (displayConnectedDefinitionPermission$ | async)"
               nexnoxWebTabTemplate
               tabId="connected"
               [heading]="'tech-portal.connected.title' | translate"
               [lazy]="true"
               (selectTab)="onNavigateToTab(['connected'])">
    <nexnox-web-resources-resource-connected *ngIf="model"
                                             [parentIds]="[model.resourceId]">
    </nexnox-web-resources-resource-connected>
  </ng-template>

  <!-- Audit -->
  <ng-template *ngIf="!creating && (readAuditPermission$ | async)"
               nexnoxWebTabTemplate
               tabId="audit"
               [heading]="'core-portal.audit.title' | translate"
               [lazy]="true"
               (selectTab)="onNavigateToTab(['audit'])">
    <nexnox-web-resources-resource-audit [isEmbedded]="true"
                                         [parentIds]="[entityTypes.Resource, model.resourceId]">
    </nexnox-web-resources-resource-audit>
  </ng-template>

  <!-- Knowledge Articles -->
  <ng-template *ngIf="!creating"
               nexnoxWebTabTemplate
               tabId="knowledge-articles"
               [heading]="'orga-portal.knowledge.subtitles.article-list' | translate"
               [lazy]="true"
               (selectTab)="onNavigateToTab(['knowledge-articles'])">
    <nexnox-web-resources-resource-articles [loading]="loading"
                                            [readonly]="readonly"
                                            [resourceSubject]="resourceSubject"
                                            [articlesSubject]="articlesSubject"
                                            (articlesChange)="onArticlesChange($event)">
    </nexnox-web-resources-resource-articles>
  </ng-template>

  <!-- Issue Templates -->
  <ng-template *ngIf="!creating"
               nexnoxWebTabTemplate
               tabId="issue-templates"
               [heading]="'core-portal.settings.issue-template.titles.list' | translate"
               [lazy]="true"
               (selectTab)="onNavigateToTab(['issue-templates'])">
    <nexnox-web-resources-resource-issue-templates [loading]="loading"
                                                   [readonly]="readonly"
                                                   [resourceSubject]="resourceSubject"
                                                   [issueTemplatesSubject]="issueTemplatesSubject"
                                                   (issueTemplatesChange)="onIssueTemplatesChange($event)">
    </nexnox-web-resources-resource-issue-templates>
  </ng-template>

  <!-- Quick Access -->
  <ng-template *ngIf="!creating && (displayQuickAccessConfigPermission$ | async)"
               nexnoxWebTabTemplate
               tabId="quick-access"
               [heading]="'resources.subtitles.quick-access' | translate"
               [hasError]="quickAccessComponent ? !quickAccessComponent.isModelValid() : false"
               [lazy]="true"
               (selectTab)="onNavigateToTab(['quick-access'])">
    <nexnox-web-resources-resource-quick-access #quickAccessComponent
                                                [loading]="loading"
                                                [readonly]="readonly"
                                                [resourceSubject]="resourceSubject"
                                                (quickAccessChange)="onQuickAccessChange($event)">
    </nexnox-web-resources-resource-quick-access>
  </ng-template>

</nexnox-web-tabs>

<!-- Location Templates -->
<ng-template #locationSelectLabelTitleTemplate
             let-item="item">
  <nexnox-web-master-data-locations-location-location-number [location]="item"
                                                             [label]="true">
  </nexnox-web-master-data-locations-location-location-number>
</ng-template>

<ng-template #locationSelectOptionTitleTemplate
             let-item="item"
             let-searchTerm="searchTerm">
  <nexnox-web-master-data-locations-location-location-number [location]="item"
                                                             [searchTerm]="searchTerm"
                                                             [label]="false">
  </nexnox-web-master-data-locations-location-location-number>
</ng-template>

<!-- Resource Templates -->
<ng-template #resourceSelectLabelTitleTemplate
             let-item="item">
  <nexnox-web-resources-resource-inventory-number [resource]="item"
                                                  [label]="true">
  </nexnox-web-resources-resource-inventory-number>
</ng-template>

<ng-template #resourceSelectOptionTitleTemplate
             let-item="item"
             let-searchTerm="searchTerm">
  <nexnox-web-resources-resource-inventory-number [resource]="item"
                                                  [searchTerm]="searchTerm"
                                                  [label]="false">
  </nexnox-web-resources-resource-inventory-number>
</ng-template>

<ng-template #resourceSelectOptionTemplate
             let-item="item">
  <br>
  <nexnox-web-resources-resource-path [resource]="item"></nexnox-web-resources-resource-path>
</ng-template>

<ng-template #locationGroupDescriptionTemplate let-item="item">
  <span class="small mt-1">{{ 'resources.descriptions.virtual-resource-description' | translate }}</span>
</ng-template>
