import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { FieldType, FormlyTemplateOptions } from '@ngx-formly/core';
import { ApiNotificationService, CoreSharedFileService, CoreSharedImageService, FileDto } from '@nexnox-web/core-shared';
import { BehaviorSubject, Observable } from 'rxjs';
import { MemoizedSelector, select, Store } from '@ngrx/store';
import { CORE_STORE_TENANT_ID_SELECTOR } from '@nexnox-web/core-store';
import { exhaustMap, take } from 'rxjs/operators';
import { HttpEventType } from '@angular/common/http';
import { at, isArray } from 'lodash';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';

export interface CorePortalFormlyFileUploadTyping {
  accept?: string;
  image?: boolean;
  overrideFileType?: string;
}

interface FormlyFileUploadTemplateOptions extends FormlyTemplateOptions {
  corePortalFileUpload: CorePortalFormlyFileUploadTyping;
}

const MAX_FILE_SIZE = 10 * 1024 * 1024;

@Component({
  selector: 'nexnox-web-formly-file-upload',
  styleUrls: ['./formly-file-upload.component.scss'],
  templateUrl: './formly-file-upload.component.html'
})
export class FormlyFileUploadComponent extends FieldType {
  @ViewChild('fileInput') public fileInput: ElementRef<HTMLInputElement>;

  /* istanbul ignore next */
  public get modelAtKey(): FileDto {
    const value = at(this.model, isArray(this.key) ? this.key : [this.key]);
    return value?.length ? value[0] : null;
  }

  public progress$: Observable<number>;

  public faExternalLinkAlt = faExternalLinkAlt;
  public faTimes = faTimes;

  private progressSubject: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  constructor(
    private fileService: CoreSharedFileService,
    private imageService: CoreSharedImageService,
    private apiNotificationService: ApiNotificationService,
    private store: Store<any>,
    @Inject(CORE_STORE_TENANT_ID_SELECTOR) private tenantIdSelector: MemoizedSelector<any, number>
  ) {
    super();

    this.progress$ = this.progressSubject.asObservable();
  }

  public readonly to: FormlyFileUploadTemplateOptions;

  public onUpload(files: FileList): void {
    if (!files.length) {
      return;
    }

    const selectedFile = files[0];

    if (selectedFile.size > MAX_FILE_SIZE) {
      this.apiNotificationService.showTranslatedError('core-portal.core.error.file-size');
      return;
    }

    this.store.pipe(
      select(this.tenantIdSelector),
      take(1),
      exhaustMap(tenantId => {
        if (this.to.corePortalFileUpload?.image) {
          return this.imageService.uploadImage(selectedFile, tenantId);
        } else {
          return this.fileService.uploadFile(selectedFile, tenantId, this.to.corePortalFileUpload?.overrideFileType);
        }
      })
    ).subscribe(fileEvent => {
      if (fileEvent.type === HttpEventType.Response) {
        this.formControl.setValue(fileEvent.body);
        this.formControl.markAsTouched();
        this.formControl.markAsDirty();
      }

      if (fileEvent.type === HttpEventType.UploadProgress) {
        this.progressSubject.next(fileEvent.loaded / fileEvent.total);
      }
    }, error => {
      this.apiNotificationService.handleApiError(error);
      this.onReset();
    }, () => this.onReset());
  }

  public onDownload(file: FileDto): void {
    window.open(file.uri);
  }

  public onClear(): void {
    this.onReset();
    this.formControl.setValue(null);
  }

  public onReset(): void {
    if (this.fileInput?.nativeElement) {
      this.fileInput.nativeElement.value = '';
    }

    this.progressSubject.next(null);
  }
}
