<ng-container *ngIf="(loading$ | async) || !loading else loadingTemplate">
  <div class="solution-item px-2"
       *ngIf="item$ | async as item"
       [class.solution-item--message]="isCollapsableItem$ | async">

    <div class="solution-item__icon">
      <div class="solution-item__icon__circle">
        <fa-icon [icon]="getSolutionItemIcon(item)"
                 size="lg">
        </fa-icon>
      </div>
      <div class="solution-item__icon__vertical-line"
           [class.d-none]="isLastItem">
      </div>
    </div>

    <div class="solution-item__left-line">
      <div></div>
    </div>

    <div class="solution-item__content mb-md-4 mb-sm-0"
         [class.solution-item__content--is-solution]="item.isSolution">

      <div class="solution-item__content__header">
        <div class="solution-item__content__header__title"
             (click)="toggleCollapsed()"
             [class.solution-item--pointer]="isCollapsableItem$ | async">
          <div [class.solution-item__content__header__title--ellipsis]="isCollapsableItem$ | async">
            <nexnox-web-solutions-solution-item-title [item$]="item$"
                                                      [isCollapsed]="isCollapsed">
            </nexnox-web-solutions-solution-item-title>
          </div>
          <div>
            <span class="solution-item__content__header__title__date">
              {{ createdAt$ | async | nexnoxWebBsDate:'dd L LT' | async }}
            </span>
            <span *ngIf="isCollapsed"
                  class="solution-item__content__header__title__info">
              {{ titleInfo$ | async }}
            </span>
          </div>
        </div>
        <div class="solution-item__content__header__action-btns ml-2"
             *ngIf="isCollapsableItem$ | async">

          <div class="solution-item__content__header__action-btns__attachment mr-3 position-relative"
               *ngIf="$any(item?.note?.attachments).length">
            <div class="solution-item__content__header__action-btns__attachment__icon ml-1">
              <fa-icon [icon]="faPaperClip"></fa-icon>
            </div>
            <div class="solution-item__content__header__action-btns__attachment__indicator">
              <div>
                {{ $any(item?.note?.attachments).length }}
              </div>
            </div>
          </div>

          <div>
            <nexnox-web-cardbox-actions [actions]="headerActions"
                                        [data]="item"
                                        [useNew]="true">
            </nexnox-web-cardbox-actions>
          </div>
        </div>
      </div>

      <div class="solution-item__content__message pb-2 position-relative"
           *ngIf="isCollapsableItem$ | async"
           [class.d-none]="isCollapsed">

        <div class="solution-item__content__message__detail"
             *ngIf="mailTo$ | async as mailTo">
          <strong>{{ 'solutions.descriptions.receiver' | translate }}: </strong><span>{{ mailTo }}</span>
        </div>

        <div class="solution-item__content__message__detail"
             *ngIf="mailCc$ | async as mailCc">
          <strong>{{ 'solutions.descriptions.cc' | translate }}: </strong><span>{{ mailCc }}</span>
        </div>

        <div class="solution-item__content__message__detail"
             *ngIf="mailFrom$ | async as mailFrom">
          <strong>{{ 'solutions.descriptions.sender' | translate }}: </strong><span>{{ mailFrom }}</span>
        </div>

        <div class="solution-item__content__message__detail"
             *ngIf="mailSubject$ | async as mailSubject">
          <strong>{{ 'solutions.descriptions.subject' | translate }}: </strong><span>{{ mailSubject }}</span>
        </div>

        <div class="solution-item__content__message__detail"
             *ngIf="phoneNumber$ | async as phoneNumber">
          <strong>{{ 'solutions.descriptions.solution-item-phone-caller' | translate: {phoneNumber:''} }}</strong><span>{{ phoneNumber }}</span>
        </div>

        <div class="solution-item__content__message__description mt-3"
             *ngIf="isMessage$ | async"
             [innerHTML]="description$ | async">
        </div>

        <div class="solution-item__content__message__description mt-3" *ngIf="isChangedStatusWithReason$ | async">
          <span><strong>{{ 'solutions.descriptions.solution-item-reason' | translate }}</strong>: </span><span [innerHTML]="item.note.description"></span>
        </div>

        <div *ngFor="let attachment of $any(item.note?.attachments)">
          <a class="solution-item__content__message__attachment mt-4"
             [href]="attachment.file.uri"
             target="_blank">
            <div class="solution-item__content__message__attachment__thumbnail">
              <fa-icon *ngIf="!attachment.isImage"
                       [icon]="attachment?.fileIcon"
                       size="lg"
                       [fixedWidth]="true">
              </fa-icon>
              <p-image *ngIf="attachment.isImage"
                       [src]="attachment.file.uri"
                       [alt]="attachment.file.name">
              </p-image>
            </div>
            <div class="solution-item__content__message__attachment__name ml-2">
              {{ attachment.file?.name }}
            </div>
          </a>
        </div>

        <div class="solution-item__content__message__reply-button pt-1"
             *ngIf="isShowReplyAction$ | async">
          <button type="button"
                  pButton
                  [icon]="faReply | nexnoxWebFaIconStringPipe"
                  [label]="'solutions.actions.reply-to-mail' | translate"
                  class="p-button-text p-button-secondary"
                  (click)="onReply(item)">
          </button>
        </div>

      </div>
    </div>

    <div class="solution-item__right-line"
         *ngIf="item.createdByName || (memberReference$ | async)">
      <div></div>
    </div>

    <div class="solution-item__info pl-md-2 pb-md-0 pt-md-0 pt-2 pb-4">
      <div class="solution-item__info__name">{{ item.note?.chatMessage?.sender?.name ?? item.createdByName ?? '' }}</div>
      <div class="solution-item__info__in ml-sm-1 ml-md-0">
        <ng-container *ngIf="memberReference$ | async as memberReference">
          <ng-container *ngIf="memberReference.name">
            <span>
              {{ 'solutions.descriptions.in' | translate }}
              {{ 'solutions.solution-members.' + memberReference.type | translate }}:
            </span>
            <a [nexnoxWebTenantRouterLink]="memberReference.link"
               [module]="memberReference.module"
               target="_blank">
              {{ memberReference.name }}
            </a>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #loadingTemplate>
  <div class="solution-item px-2">
    <div class="solution-item__icon">
      <div class="solution-item__icon__circle">
        <nexnox-web-loading-icon [size]="'lg'"></nexnox-web-loading-icon>
      </div>
      <div class="solution-item__icon__vertical-line"
           [class.d-none]="true">
      </div>
    </div>

    <div class="solution-item__left-line">
      <div></div>
    </div>

    <div class="solution-item__content solution-item__content mb-md-4 mb-sm-0">

      <div class="solution-item__content__header">
        <div class="solution-item__content__header__title">
          <div class="mb-1">
            <ngx-skeleton-loader [theme]="{
              display: 'flex',
              width: '200px',
              height: '16.9px',
              'margin-bottom': '0'
            }"></ngx-skeleton-loader>
          </div>
          <ngx-skeleton-loader [theme]="{
            display: 'flex',
            width: '100px',
            height: '12px',
            'margin-bottom': '0'
          }"></ngx-skeleton-loader>
        </div>
      </div>
    </div>
  </div>
</ng-template>
