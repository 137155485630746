import {ChangeDetectionStrategy, Component, Injector, OnInit} from '@angular/core';
import {
  ActionButton,
  CorePortalEntityOverviewBaseComponent,
  DatatableActionButton,
  DeleteEntityModel
} from '@nexnox-web/core-portal';
import {
  AppEntityType,
  AppPermissions,
  ControllerOperationId,
  FilterOperators,
  FilterTypes,
  Mappers,
  ResourceTaskDto
} from '@nexnox-web/core-shared';
import {resourceTaskListStore} from '../../store';
import {merge, Subject, withLatestFrom} from "rxjs";
import {delay, map, mergeMap} from "rxjs/operators";
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons/faTimesCircle";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";

@Component({
  selector: 'nexnox-web-resource-tasks-resource-task-list',
  templateUrl: './resource-task-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourceTaskListComponent extends CorePortalEntityOverviewBaseComponent<ResourceTaskDto> implements OnInit {
  public title = 'resource-tasks.subtitles.resource-task-list';
  public createTitle = 'resource-tasks.subtitles.resource-task-create';
  public idProperty = 'resourceTaskId';
  public displayProperty = 'title';
  public datatableConfigName = 'ResourceTaskDefault';
  public componentId = 'ResourceTaskListComponent';

  public enableViews = true;
  public pageOperation = ControllerOperationId.ResourceTaskControllerList;

  constructor(
    protected injector: Injector
  ) {
    super(injector, resourceTaskListStore, Mappers.ResourceTaskListDto.serializedName, AppEntityType.None);
  }

  public ngOnInit(): void {
    this.defaultFilter = [{
      property: 'isArchived',
      operator: FilterOperators.Equal,
      type: FilterTypes.DataTransferObject,
      value: 'false'
    }];

    super.ngOnInit();
  }

  /* istanbul ignore next */
  public getRowActionButtons(): DatatableActionButton[] {
    return this.getDefaultRowActionButtons(
      'resource-tasks.actions.edit-resource-task',
      (row: ResourceTaskDto) => `/resource-tasks/${row.resourceTaskId}`,
      [AppPermissions.UpdateResourceTask],
      {
        module: 'inventory'
      }
    );
  }

  public getDeleteEntityModel(): DeleteEntityModel {
    return {
      titleKey: 'resource-tasks.actions.delete-resource-task',
      descriptionKey: 'resource-tasks.descriptions.delete-resource-task',
      confirmKey: 'resource-tasks.actions.delete-resource-task',
      deletePermission: AppPermissions.DeleteResourceTask
    };
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons('resource-tasks.actions.create-resource-task', AppPermissions.CreateResourceTask);
  }
}
