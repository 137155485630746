import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CORE_SHARED_ENVIRONMENT } from '../../tokens';
import { Environment } from '../../models';

export enum CoreSharedMailType {
  BUG_REPORT
}

export interface CoreSharedMailModel {
  type: CoreSharedMailType;
  subject?: string;
  body?: string;
  traceId?: string;
}

@Injectable()
export class CoreSharedMailService {
  constructor(
    @Inject(CORE_SHARED_ENVIRONMENT) private environment: Environment,
    private translate: TranslateService
  ) {
  }

  public send(mail: CoreSharedMailModel): void {
    switch (mail.type) {
      case CoreSharedMailType.BUG_REPORT:
        this.sendBugReport(mail);
        break;
    }
  }

  private sendBugReport(mail: CoreSharedMailModel, traceId?: string): void {
    this.sendMail(
      this.environment.email.bugReport,
      mail.subject ?? this.translate.instant('core-shared.shared.email.bug-report.subject', {
        title: this.environment.title
      }),
      mail.body ?? this.translate.instant('core-shared.shared.email.bug-report.body', {
        traceId: mail.traceId ?? this.translate.instant('core-shared.shared.email.bug-report.no-trace-id')
      })
    );
  }

  private sendMail(receiver: string, subject: string, body: string): void {
    window.open(`mailto:${receiver}?subject=${subject}&body=${body}`);
  }
}
