export * from './lib/issues.module';
export {
  TechPortalFeatureIssueListComponent
} from './lib/containers';
export {
  TechPortalFeatureIssueListService
} from './lib/store';
export {
  IssueDetailSidebarComponent
} from './lib/sidebars';
