<nexnox-web-entity-overview #overviewComponent
                            [useTabs]="true"
                            [tabHeading]="'core-shared.shared.select.all' | translate"
                            [tabCommands]="['.']"
                            [entityOverviewBaseComponent]="this"
                            [disableCreate]="true"
                            [detailFn]="detailFn"
                            (selectInitialTab)="onNavigateToTab($event)">
  <!-- Message Issues -->
  <ng-template *ngIf="shouldShowMessageIssuesTab$ | async"
               nexnoxWebTabTemplate
               tabId="message"
               [heading]="'issues.subtitles.message-issue-list' | translate"
               [lazy]="true"
               (selectTab)="onNavigateToTab(['message'])">
    <nexnox-web-issues-message-issue-list></nexnox-web-issues-message-issue-list>
  </ng-template>

  <!-- Mission Issues -->
  <ng-template *ngIf="shouldShowMissionIssuesTab$ | async"
               nexnoxWebTabTemplate
               tabId="mission"
               [heading]="'issues.subtitles.mission-issue-list' | translate"
               [lazy]="true"
               (selectTab)="onNavigateToTab(['mission'])">
    <nexnox-web-issues-mission-issue-list></nexnox-web-issues-mission-issue-list>
  </ng-template>

  <!-- Ticket Issues -->
  <ng-template *ngIf="shouldShowTicketIssuesTab$ | async"
               nexnoxWebTabTemplate
               tabId="ticket"
               [heading]="'issues.subtitles.ticket-issue-list' | translate"
               [lazy]="true"
               (selectTab)="onNavigateToTab(['ticket'])">
    <nexnox-web-issues-ticket-issue-list></nexnox-web-issues-ticket-issue-list>
  </ng-template>

  <!-- Winterhalter Service Call Issues -->
  <ng-template *ngIf="shouldShowWinterhalterServiceCallIssuesTab$ | async"
               nexnoxWebTabTemplate
               tabId="winterhalter-service-call"
               [heading]="'issues.subtitles.winterhalter-service-call-issue-list' | translate"
               [lazy]="true"
               (selectTab)="onNavigateToTab(['winterhalter-service-call'])">
    <nexnox-web-issues-winterhalter-service-call-issue-list></nexnox-web-issues-winterhalter-service-call-issue-list>
  </ng-template>

  <!-- Winterhalter Product Order Issues -->
  <ng-template *ngIf="shouldShowWinterhalterProductOrderIssuesTab$ | async"
               nexnoxWebTabTemplate
               tabId="winterhalter-product-order"
               [heading]="'issues.subtitles.winterhalter-product-order-issue-list' | translate"
               [lazy]="true"
               (selectTab)="onNavigateToTab(['winterhalter-product-order'])">
    <nexnox-web-issues-winterhalter-product-order-issue-list></nexnox-web-issues-winterhalter-product-order-issue-list>
  </ng-template>
</nexnox-web-entity-overview>
