import {ChangeDetectionStrategy, Component, OnInit, ViewChild} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {
  authStore,
  CorePortalEntitySelectComponent,
  CorePortalEntitySelectLayout,
  CorePortalEntitySelectOptions,
  CorePortalPageTitleService,
  CorePortalPermissionService,
  CorePortalTenantLocalStorageService,
  headerStore
} from '@nexnox-web/core-portal';
import {
  ApiNotificationService,
  AppPermissions,
  DashboardDto,
  DashboardItemDto,
  DashboardItemTypes,
  LocalStorageKeys,
  UnsubscribeHelper
} from '@nexnox-web/core-shared';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {CorePortalDashboardService} from '@nexnox-web/core-portal/features/settings/features/dashboards';
import {distinctUntilChanged, map, skip, take} from 'rxjs/operators';
import produce from 'immer';
import {cloneDeep, sortBy, uniqWith} from 'lodash';
import {faTimes} from '@fortawesome/free-solid-svg-icons/faTimes';
import {dashboardStore} from '../../store';
import {DashboardValueItem} from '../../store/stores/dashboard/dashboard-value-item.model';

@Component({
  selector: 'nexnox-web-dashboard',
  templateUrl: './dashboard.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardComponent extends UnsubscribeHelper implements OnInit {
  @ViewChild('entitySelectComponent') public entitySelectComponent: CorePortalEntitySelectComponent;

  public dashboardItems$: Observable<DashboardItemDto[]>;

  public selectedDashboardSubject: BehaviorSubject<DashboardDto> = new BehaviorSubject<DashboardDto>(null);
  public loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public itemsLoadedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public itemsAvailableSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public dashboardSelectOptionsSubject: BehaviorSubject<CorePortalEntitySelectOptions> =
    new BehaviorSubject<CorePortalEntitySelectOptions>(null);

  public itemTypes = DashboardItemTypes;
  public faTimes = faTimes;

  public readDashboardPermission$: Observable<boolean>;

  constructor(
    private store: Store<any>,
    private apiNotificationService: ApiNotificationService,
    private dashboardService: CorePortalDashboardService,
    private tenantLocalStorageService: CorePortalTenantLocalStorageService,
    private permissionService: CorePortalPermissionService,
    private pageTitleService: CorePortalPageTitleService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.store.dispatch(headerStore.actions.setTitle({ title: 'dashboard.title' }));
    this.pageTitleService.setPageTitle('dashboard.title');

    this.subscribe(this.store.pipe(
      select(authStore.selectors.selectActiveTenant),
      take(1)
    ), async activeTenant => {
      let selectedDashboardStorage: DashboardDto = await this.tenantLocalStorageService.get(LocalStorageKeys.SELECTED_DASHBOARD);
      if (selectedDashboardStorage) {
        if (selectedDashboardStorage.tenantId === activeTenant?.tenantId) {
          this.onSelectDashboard(selectedDashboardStorage, false);
        } else {
          await this.tenantLocalStorageService.remove(LocalStorageKeys.SELECTED_DASHBOARD);
          selectedDashboardStorage = null;
        }
      }

      this.dashboardSelectOptionsSubject.next({
        idKey: 'dashboardId',
        displayKey: 'name',
        entityService: this.dashboardService,
        wholeObject: true,
        skipGetOne: true,
        clearable: false,
        layout: CorePortalEntitySelectLayout.HEADLINE,
        firstToDefault: !selectedDashboardStorage
      });
    });

    /* istanbul ignore next */
    this.dashboardItems$ = this.selectedDashboardSubject.asObservable().pipe(
      map(selectedDashboard => selectedDashboard?.items ?? []),
      map(items => sortBy(items, item => item.position))
    );

    this.readDashboardPermission$ = this.permissionService.hasPermission$(AppPermissions.ReadDashboard);

    this.subscribe(this.store.pipe(
      select(authStore.selectors.selectActiveTenant),
      skip(1)
    ), /* istanbul ignore next */ async () => {
      this.selectedDashboardSubject.next(null);
      await this.tenantLocalStorageService.remove(LocalStorageKeys.SELECTED_DASHBOARD);
      const refreshSubject = new Subject<void>();
      this.dashboardSelectOptionsSubject.next({
        ...this.dashboardSelectOptionsSubject.getValue(),
        firstToDefault: true,
        refresh$: refreshSubject.asObservable()
      });
      refreshSubject.next();
    });

    this.subscribe(this.selectedDashboardSubject.asObservable().pipe(
      distinctUntilChanged((a, b) => a?.dashboardId === b?.dashboardId),
      skip(2)
    ), value => this.store.dispatch(dashboardStore.actions.clear()));
  }

  public onSelectDashboard(dashboard: DashboardDto, userAction: boolean = true): void {
    this.selectedDashboardSubject.next(dashboard);

    this.loadingSubject.next(true);
    this.dashboardService
      .getOne<DashboardDto>(dashboard.dashboardId)
      .toPromise()
      .then(async apiDashboard => {
        this.selectedDashboardSubject.next(apiDashboard);
        this.loadingSubject.next(false);
        await this.tenantLocalStorageService.set(LocalStorageKeys.SELECTED_DASHBOARD, apiDashboard);
      })
      .catch(async error => {
        if (userAction) this.apiNotificationService.handleApiError(error);
        this.loadingSubject.next(false);
        this.selectedDashboardSubject.next(null);
        await this.tenantLocalStorageService.remove(LocalStorageKeys.SELECTED_DASHBOARD);
        const refreshSubject = new Subject<void>();
        this.dashboardSelectOptionsSubject.next({
          ...this.dashboardSelectOptionsSubject.getValue(),
          firstToDefault: true,
          refresh$: refreshSubject.asObservable()
        });
        refreshSubject.next();
        refreshSubject.complete();
      });
  }

  public onLoadedChange(loaded: boolean): void {
    this.itemsLoadedSubject.next(loaded);
  }

  public onAvailableChange(available: number): void {
    this.itemsAvailableSubject.next(available > 0);
  }

  public async onUpdateDashboardItem(item: DashboardItemDto): Promise<void> {
    const newDashboard = produce(this.selectedDashboardSubject.getValue(), draft => {
      const items = cloneDeep(draft.items);
      items[items.findIndex(x => x.position === item.position)] = item;
      draft.items = items;
    });
    this.selectedDashboardSubject.next(newDashboard);
    await this.tenantLocalStorageService.set(LocalStorageKeys.SELECTED_DASHBOARD, this.selectedDashboardSubject.getValue());
  }

  public async onDashboardLoadPage(payload: DashboardValueItem): Promise<void> {
    let items = await this.store.pipe(select(dashboardStore.selectors.selectItems), take(1)).toPromise();
    items = uniqWith([payload, ...items], (a, b) => a.position === b.position);
    this.store.dispatch(dashboardStore.actions.set({ items }));
  }

  /* istanbul ignore next */
  public trackItemsBy(index: number, item: DashboardItemDto): number {
    return item.position;
  }
}
